import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, Typography, styled } from '@mui/material';
import { FC } from 'react';

interface IConfirmDialogProps {
  message: string;
  showCloseButton?: boolean;
}

interface IConfirmDialogStyles {
  showCloseButton?: boolean;
};

export const ConfirmDialog: FC<IConfirmDialogProps> = ({ message, showCloseButton }) => {
  return (
    <StyledConfirmDialog className={classes.confirmDialogContainer}>
      <div className={classes.confirmDialogHeader}>
        <Typography className={classes.headerTitle}>{message}</Typography>
        {showCloseButton && (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={() => {
              const element = document.querySelector('.MuiBackdrop-root') as HTMLElement;
              if (element) {
                element.click();
              }
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        )}
      </div>
      <Divider className={classes.divider} />
    </StyledConfirmDialog>
  );
};

const PREFIX = 'ConfirmDialog';

const classes = {
  confirmDialogContainer: `${PREFIX}-confirmDialogContainer`,
  confirmDialogHeader: `${PREFIX}-confirmDialogHeader`,
  headerTitle: `${PREFIX}-headerTitle`,
  divider: `${PREFIX}-divider`,
  closeButton: `${PREFIX}-closeButton`
};

const StyledConfirmDialog = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showCloseButton',
})<IConfirmDialogStyles>(({ theme, showCloseButton }) => {
  return {
    [`&.${classes.confirmDialogContainer}`]: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: (showCloseButton ? '1rem' : undefined),
    },
    [`& .${classes.confirmDialogHeader}`]: {
      width: '100%',
      padding: 16,
    },
    [`& .${classes.headerTitle}`]: {
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    [`& .${classes.divider}`]: {
      borderColor: theme.palette.secondary.main,
      width: '100%',
    },
    [`& .${classes.closeButton}`]: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  };
});