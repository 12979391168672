import { Box, Stack, FormLabel, styled } from '@mui/material';
import { Card, CardTitle, Loader, Wysiwyg } from '../../components';
import { FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { getServiceTypeTermsAndConditions, getCustomerAgreement } from '../../fetch';
import { IOneTimeServiceDetail } from '../../models';

interface IEstimateTermsConditions {
  hasEstimateAgreementBeenSigned: boolean;
  setFieldValue: (key: string, val: any) => void;
  values: any;
  ots?: IOneTimeServiceDetail | null;
}

export const EstimateTermsConditions: FC<IEstimateTermsConditions> = ({
  hasEstimateAgreementBeenSigned,
  setFieldValue,
  values,
  ots,
}) => {
  const [isLoadingTermsAndConditions, setIsLoadingTermsAndConditions] = useState(false);
  const [standardTerms, setStandardTerms] = useState<string>('');
  const fetchTerms = async () => {
    try {
      setIsLoadingTermsAndConditions(true);
      let termsAndConditions = '';
      if (values.customAgreementId || ots?.customAgreementId) {
        termsAndConditions = await getCustomerAgreement(
          values.customAgreementId || ots?.customAgreementId
        );
      } else {
        termsAndConditions = await getServiceTypeTermsAndConditions(
          values.serviceTypeId || ots?.serviceTypeId
        );
      }
      setStandardTerms(termsAndConditions ?? '');
    } finally {
      setIsLoadingTermsAndConditions(false);
    }
  };

  useEffect(() => {
    if (
      values.serviceTypeId ||
      values.customAgreementId ||
      ots?.serviceTypeId ||
      ots?.customAgreementId
    ) {
      fetchTerms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.serviceTypeId, values.customAgreementId, ots]);
  return (
    <Card>
      <CardTitle title="Terms & Conditions" withExpand overrideExpand={false}>
        {isLoadingTermsAndConditions && <Loader type="overlay" position="centered" />}
        <StyledWrapper gap={2}>
          <Box>
            <FormLabel>Standard</FormLabel>
            <ReactQuill
              style={{ height: '240px' }}
              value={standardTerms ?? ''}
              theme="snow"
              readOnly
              modules={{
                toolbar: false,
              }}
            />
          </Box>
          <Wysiwyg
            label="Additional"
            value={values.additionalTermsAndConditions}
            readOnly={hasEstimateAgreementBeenSigned}
            onChange={val => setFieldValue('additionalTermsAndConditions', val)}
          />
        </StyledWrapper>
      </CardTitle>
    </Card>
  );
};

const StyledWrapper = styled(Stack)(({ theme }) => ({
  '& .ql-container': {
    height: '240px',
  },
}));
