import { Typography, Grid, styled } from '@mui/material';
import { FC } from 'react';
import ReactPlayer from 'react-player';
import { Card } from '../card';

interface IVideoDisplay {
  videos?: { title: string; url: string }[];
}

export const VideoDisplay: FC<IVideoDisplay> = ({
  videos = [
    { url: 'https://vimeo.com/347804019', title: 'Route Maps' },
    { url: 'https://vimeo.com/348101175', title: 'Routes' },
    { url: 'https://vimeo.com/355063700', title: 'Route Suggestions' },
  ],
}) => {
  return (
    <Card
      cardTitleProps={{
        title: 'Instructional Videos',
      }}
    >
      <Grid container spacing={2}>
        {videos &&
          videos.map(video => (
            <StyledVideo item key={video.url} xs={12} md={4}>
              <Typography className={classes.title} variant={'h6'}>
                {video.title}
              </Typography>
              <div className={classes.playerWrapper}>
                <ReactPlayer
                  controls={true}
                  className={classes.reactPlayer}
                  width="100%"
                  height="100%"
                  url={video.url}
                  config={{
                    vimeo: {
                      playerOptions: {
                        url: video.url,
                      },
                      title: video.title,
                    },
                  }}
                />
              </div>
            </StyledVideo>
          ))}
      </Grid>
    </Card>
  );
};

const PREFIX = 'VideoDisplay';

const classes = {
  title: `${PREFIX}-title`,
  playerWrapper: `${PREFIX}-playerWrapper`,
  reactPlayer: `${PREFIX}-reactPlayer`
};

const StyledVideo = styled(Grid)(({ theme }) => ({
  [`& .${classes.title}`]: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
  },

  [`& .${classes.playerWrapper}`]: {
    width: 'auto',
    height: 'auto',
    marginBottom: '1rem',
    padding: theme.spacing(1),
    borderRadius: '5px',
    border: `1px solid ${theme.palette.primary.main}`,
  },

  // I'm using 56.25% is to preserve the 16:9 aspect ratio, (9/ 16 * 100% = 56.25%) for the embedded videos https://css-tricks.com/aspect-ratio-boxes/
  [`& .${classes.reactPlayer}`]: {
    paddingTop: '56.25%',
    position: 'relative',
    '& > div': {
      top: 0,
      position: 'absolute',
    },
  }
}));