import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IPrimaryIcon {
  icon: IconProp;
  iconTitle?: string;
}

export const PrimaryIcon: FC<IPrimaryIcon> = ({ icon, iconTitle }) => {

  return <StyledFAIcon className={classes.icon} icon={icon} title={iconTitle} />;
};

const PREFIX = 'PrimaryIcon';

const classes = {
  icon: `${PREFIX}-icon`
};

const StyledFAIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  [`&.${classes.icon}`]: {
    borderRadius: '50%',
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    fontSize: '14px',
    marginRight: '5px',
    backgroundColor: '#C8eaf3',
  }
}));