import { Loader } from '../../components';
import { FC, useEffect, useState } from 'react';
import { Box, Button, Theme, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/styles';
const { BING_MAP_KEY } = require('../../buildSettings.json');

declare let window: {
  bingAPIReady?: () => void;
  BingMap: Microsoft.Maps.Map;
  activePushPin?: Microsoft.Maps.Pushpin;
};

const loadBingApi = (
  resetLoading: () => void,
  setPushPinLocation: (location: Microsoft.Maps.Location | null) => void,
  pushPinLocation: any,
  theme: Theme,
  mapCenter?: number[]
) => {
  let url = `https://www.bing.com/api/maps/mapcontrol?callback=bingAPIReady&key=${BING_MAP_KEY}`;
  if (!document.getElementById('BingMaps-pin')) {
    const script = document.createElement('script');
    script.id = 'BingMaps';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = url;
    document.body.appendChild(script);
    // eslint-disable-next-line
    window.bingAPIReady = () => {
      window.BingMap = new Microsoft.Maps.Map('#pin-map', {
        mapTypeId: Microsoft.Maps.MapTypeId.grayscale,
        center: mapCenter ? new Microsoft.Maps.Location(mapCenter?.[0], mapCenter?.[1]) : undefined,
        padding: 0,
        zoom: 10,
      });
      if (pushPinLocation) {
        window.activePushPin = new Microsoft.Maps.Pushpin(pushPinLocation, {
          color: theme.palette.secondary.main,
          enableHoverStyle: true,
          enableClickedStyle: true,
          draggable: true,
        });
        window.BingMap.entities.push(window.activePushPin);
        Microsoft.Maps.Events.addHandler(
          window.activePushPin,
          'dragend',
          (e: Microsoft.Maps.IMouseEventArgs) => {
            setPushPinLocation(e.location);
          }
        );
        setPushPinLocation(window.activePushPin.getLocation());
      }
      Microsoft.Maps.Events.addHandler(
        window.BingMap,
        'click',
        (e: Microsoft.Maps.IMouseEventArgs) => {
          if (!window.activePushPin) {
            addPushPin(e.location);
          }
        }
      );

      const addPushPin = (location: Microsoft.Maps.Location) => {
        window.activePushPin = new Microsoft.Maps.Pushpin(location, {
          color: theme.palette.secondary.main,
          enableHoverStyle: true,
          enableClickedStyle: true,
          draggable: true,
        });
        window.BingMap.entities.push(window.activePushPin);
        Microsoft.Maps.Events.addHandler(
          window.activePushPin,
          'dragend',
          (e: Microsoft.Maps.IMouseEventArgs) => {
            setPushPinLocation(e.location);
          }
        );
        setPushPinLocation(location);
      };

      resetLoading();
    };
  }
};

interface IPinMap {
  pushPinLocation: Microsoft.Maps.Location | null;
  setPushPinLocation: (val: Microsoft.Maps.Location | null) => void;
  mapCenter?: number[];
}

export const PinMap: FC<IPinMap> = ({ pushPinLocation, setPushPinLocation, mapCenter }) => {
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    // reset on load to clear it out from the window
    window.activePushPin = undefined;

    setIsLoadingMap(true);
    loadBingApi(
      () => {
        setIsLoadingMap(false);
      },
      (pushPin: Microsoft.Maps.Location | null) => {
        setPushPinLocation(pushPin);
      },
      pushPinLocation,
      theme,
      mapCenter
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <Box display="flex" alignItems="center" mt={0.5} mb={0.5}>
        <Typography sx={{ flex: 1 }} variant="caption">
          Manually select location by clicking on map to place pin and drag to reposition
        </Typography>

        {pushPinLocation && (
          <Button
            color="primary"
            variant="text"
            size="small"
            startIcon={<FontAwesomeIcon icon={faClose} />}
            onClick={() => {
              window.BingMap.entities.removeAt(0);
              setPushPinLocation(null);
              window.activePushPin = undefined;
            }}
          >
            Clear Pin
          </Button>
        )}
      </Box>
      <Box height={theme => theme.spacing(60)} position="relative">
        <Box sx={{ display: 'block' }} id="pin-map" />
        {isLoadingMap && <Loader position="centered" type="overlay" />}
      </Box>
    </Box>
  );
};
