import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';
import { Switch } from '../../../components';

export const StyleGuideSwitch = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Switch">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Switch is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-switch/" target="_blank">
              Mui Switch
            </Link>{' '}
            component and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has built-in styles, and Formik props required for use with a Formik form and form
            validation.
          </Typography>
          <Typography mb={1}>
            Switches should only be used to depict whether an option is On or Off. More complicated
            scenarios have proven to be too confusing for the typical user and should be avoided.
            For multiple options, checkboxes or radio buttons should be used instead.
          </Typography>
          <Typography mb={1}>
            It's also worth noting that the label shouldn't drastically change when toggling the
            switch, as this also causes unnecessary confusion.
          </Typography>
          <code>
            Props:
            <br />- name: string
            <br />- label?: string | ReactElement
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ handleBlur }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <Switch name="testField" label="Active" />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;Switch name="testField" label="Active" /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
