import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, FC } from 'react';
import { Button } from '@mui/material';
import { CardTitle, Card } from '../../components';
import { ServiceLogDetails } from './service-log-details';

interface IServiceLog {
  serviceId: string | number;
  isModal?: boolean;
  isExpanded?: boolean;
}

export const ServiceLog: FC<IServiceLog> = ({ serviceId, isModal, isExpanded }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Card className="print--avoid-break">
      <CardTitle
        withExpand
        overrideExpand={isExpanded}
        initialExpand={false}
        title="Service Log"
        mobileWrap
        action={
          <>
            {!isModal && (
              <Button
                color="secondary"
                startIcon={<FontAwesomeIcon icon={faPlusCircle} size="lg" />}
                size="small"
                onClick={() => setModalOpen(true)}
              >
                Add Service Log
              </Button>
            )}
          </>
        }
        unmountOnExit
      >
        <ServiceLogDetails
          serviceId={serviceId}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        />
      </CardTitle>
    </Card>
  );
};
