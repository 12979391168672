import React, { useEffect, useState, FC } from 'react';
import { isAndroid, isIOS, isMobileSafari, isEdge } from 'react-device-detect';
import * as localForage from 'localforage';
// components
import { Modal } from '../modal';
import { Grid, Typography, styled } from '@mui/material';
// image
import Share from '../../assets/apple-share-blue-image.png';
import { MoreVert, MoreHoriz } from '@mui/icons-material';

interface IInstallPrompt {
  name?: string;
  open?: boolean;
  setOpen?: (val: boolean) => void;
}

export const InstallPrompt: FC<IInstallPrompt> = ({ name = 'PCP App', open, setOpen }) => {
  const [isOpen, showModal] = useState<boolean>(false);

  const setShareIcon = () => {
    if (isMobileSafari) {
      return <img className={classes.shareImg} alt="share-icon" src={Share} />;
    }
    if ((isIOS || isAndroid) && isEdge) {
      return <MoreHoriz className={classes.shareIcon} />;
    }
    return <MoreVert className={classes.shareIcon} />;
  };
  const setShareText = () => {
    if ((isIOS || isAndroid) && isEdge) {
      return 'Add to Phone';
    }
    return 'Add to Home Screen';
  };
  const shareIcon = setShareIcon();
  const shareText = setShareText();

  const checkToShowModal = async () => {
    const { navigator }: any = window;
    const showAddToHomeScreen = await localForage.getItem('showAddToHomeScreen');
    if (
      (!showAddToHomeScreen || showAddToHomeScreen !== 'false') &&
      !navigator.standalone &&
      (isIOS || isAndroid)
    ) {
      showModal(true);
    }
  };
  useEffect(() => {
    if (open === undefined) {
      checkToShowModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!open) {
      checkToShowModal();
    }
  }, [open]);
  return (
    <StyledModal
      maxWidth="sm"
      open={isOpen}
      onClose={async () => {
        await localForage.setItem('showAddToHomeScreen', 'false');
        showModal(false);
        if (!!setOpen) setOpen(false);
      }}
      title={`Add shortcut to ${name}`}
    >
      <Grid container pt={1}>
        <Grid item flexDirection={'column'}>
          <Typography data-testid="Prompt.Text" component="h1" className={classes.subHeading}>
            Add a shortcut to this application on your home screen for quick and easy access in the
            future.
          </Typography>
          <div className={classes.subText}>Just Tap {shareIcon}</div>
        </Grid>
        <Grid item width="100%">
          <div className={classes.subText}>then '{shareText}'</div>
        </Grid>
      </Grid>
    </StyledModal>
  );
};


const PREFIX = 'InstallPrompt';

const classes = {
  subHeading: `${PREFIX}-subHeading`,
  subText: `${PREFIX}-subText`,
  shareImg: `${PREFIX}-shareImg`,
  shareIcon: `${PREFIX}-shareIcon`,
  imageWrap: `${PREFIX}-imageWrap`
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.subHeading}`]: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.subText}`]: {
    display: 'flex',
    background: '#f4f4f4',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    textAlign: 'center',
  },

  [`& .${classes.shareImg}`]: {
    fontSize: 14,
    height: 30,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },

  [`& .${classes.shareIcon}`]: {
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },

  [`& .${classes.imageWrap}`]: {
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 100,
    marginBottom: theme.spacing(2),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  }
}));