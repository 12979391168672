import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { Signature } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuideSignature = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [sigPadInstance, setSigPadInstance] = useState<SignaturePad | null>(null);

  return (
    <StyleGuidePage title="Signature">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Signature component is used primarily for the external agreement signing process
            related to One Time Services or external payment agreements. It is built upon the
            SignaturePad component from{' '}
            <Link href="https://www.npmjs.com/package/react-signature-canvas" target="_blank">
              react-signature-canvas
            </Link>{' '}
            node package.
          </Typography>
          <Typography mb={1}>
            It has functionality to clear the signature pad on resize, as well as a Clear button
            once it detects a signature on the canvas. More detail in the link above to the node
            package but the main functionality we use it for in the app is for capturing a signaure
            and sending that signature as an image to be displayed on a contract.
          </Typography>
          <Typography mb={1}>
            For more examples of this component being used, see the signature-card.tsx and
            contract-page-details.tsx files in the /UI/src/pages/external/agreements folder in the
            app project.
          </Typography>
          <code>
            Props:
            <br />- setSigPadInstance: Dispatch{'<'}SetStateAction{'<'}any{'>'}
            {'>'}
            <br />- sigPadInstance: any
            <br />- hasSubmitted: boolean
            <br />- setIsSigned: Dispatch{'<'}SetStateAction{'<'}boolean{'>'}
            {'>'}
            <br />- isSigned: boolean
            <br />- clearSigPadOnResize?: boolean
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={1} p={0.5}>
            <Signature
              hasSubmitted={false}
              setSigPadInstance={setSigPadInstance}
              sigPadInstance={sigPadInstance}
              setIsSigned={setIsSigned}
              isSigned={isSigned}
              // when the window is resized, clear the signature pad when its visible in the UI otherwise keep the signature
              clearSigPadOnResize={true}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Signature
            <br />
            &nbsp;&nbsp;&nbsp;hasSubmitted=&#123;hasSubmitted&#125;
            <br />
            &nbsp;&nbsp;&nbsp;setSigPadInstance=&#123;setSigPadInstance&#125;
            <br />
            &nbsp;&nbsp;&nbsp;sigPadInstance=&#123;sigPadInstance&#125;
            <br />
            &nbsp;&nbsp;&nbsp;setIsSigned=&#123;setIsSigned&#125;
            <br />
            &nbsp;&nbsp;&nbsp;isSigned=&#123;isSigned&#125;
            <br />
            &nbsp;&nbsp;&nbsp;clearSigPadOnResize=&#123;true&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
