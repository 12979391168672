import { FC, useContext, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../context';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { deepEqual } from 'fast-equals';
// Components
import {
  Modal,
  Page,
  Loader,
  CardTitle,
  ConfirmPrompt,
  SaveButton,
  Card,
  CancelIcon,
} from '../../../components';
import { Box, Fade, Button } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import {
  formatDate,
  removeOfficeTimeOffset,
  formatRawDateTime,
  formatRawDate,
} from '../../../helpers';
import { updateTask, createTask, getSingleTask } from '../../../fetch';
import { ITaskDetail, ITaskPost } from '../../../models';
import { defaultUnsavedChangesMessage } from '../../../constants';
import { TaskDetails } from './task-details';

interface ITasksDetailPage {
  isModal?: boolean;
  isModalOpen?: boolean;
  handleModalClose?: () => void;
  currentTaskId?: string;
}

const Schema = Yup.object().shape({
  taskDefId: Yup.string().required('Required'),
  date: Yup.string().required('Required'),
  time: Yup.string(),
  priorityType: Yup.string().required('Required'),
  userId: Yup.string().required('Required'),
  accountId: Yup.string().nullable(),
  isCompleted: Yup.boolean(),
  whenCompleted: Yup.string().nullable(),
  completionNotes: Yup.string(),
  comments: Yup.string(),
  estimatedMinutes: Yup.number(),
  estimatedHours: Yup.number(),
  specifyTime: Yup.boolean(),
  siteId: Yup.string().nullable(),
  scheduledServiceId: Yup.string().nullable(),
});

export const TasksDetailPage: FC<ITasksDetailPage> = ({
  isModal,
  isModalOpen,
  handleModalClose,
  currentTaskId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, isSuperAdmin } = useContext(UserContext);
  // editing a task
  const { taskId: paramsTaskId }: { taskId: string } = useParams();
  const taskId = paramsTaskId ?? currentTaskId;
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const accountId = params.get('accountId');
  const siteId = params.get('siteId');
  const scheduledServiceId = params.get('scheduledServiceId');
  const redirect = params.get('redirect');
  // used for created a new task
  const isNewTask = taskId === 'new';
  const {
    isLoading,
    data: currentTask,
    refetch: fetchTask,
  } = useQuery<ITaskDetail, Error>(['getSingleTask'], () => getSingleTask(taskId), {
    enabled: taskId !== 'new',
    onError: (err: any) => {
      if (err?.Status === 404) {
        enqueueSnackbar('Redirecting...', {
          variant: 'info',
        });
        enqueueSnackbar(err.Detail, {
          variant: 'error',
        });
        return setTimeout(function () {
          history.push(redirect ?? '/scheduling/tasks');
        }, 3000);
      } else {
        enqueueSnackbar(`Error loading scheduled task, please try again.`, {
          variant: 'error',
        });
      }
    },
  });
  const hasNoTimeSelected = useMemo(() => {
    return currentTask?.taskDate?.split('T')?.[1]?.slice(0, -6)?.includes('00:00:00')
      ? true
      : false;
  }, [currentTask]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          taskDefId: currentTask?.taskDefId ?? '',
          priorityType: currentTask?.priorityType ?? 'Medium',
          userId: isNewTask && !isSuperAdmin ? user?.userId ?? '' : currentTask?.userId ?? '',
          accountId: currentTask?.accountId ?? accountId ?? '',
          isCompleted: currentTask?.isCompleted ?? false,
          whenCompleted: currentTask?.whenCompleted
            ? removeOfficeTimeOffset(currentTask?.whenCompleted)
            : '',
          date: currentTask?.taskDate ? formatDate(currentTask.taskDate) : new Date(),
          time: !hasNoTimeSelected ? removeOfficeTimeOffset(currentTask?.taskDate) : new Date(),
          completionNotes: currentTask?.completionNotes ?? '',
          comments: currentTask?.comments ?? '',
          estimatedMinutes: currentTask?.estimatedMinutes ?? 0,
          estimatedHours: currentTask?.estimatedHours ?? 0,
          specifyTime: currentTask?.taskDate
            ? // if the date includes 00:00:00 it means we have no time
              !hasNoTimeSelected
            : false,
          siteId: currentTask?.siteId ?? '',
          scheduledServiceId: currentTask?.scheduledServiceId ?? scheduledServiceId ?? '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          try {
            const data: ITaskPost = {
              accountId: ((values.accountId ?? accountId) as string) ?? null,
              estimatedHours: values?.estimatedHours ? Number(values?.estimatedHours) : 0,
              estimatedMinutes: values?.estimatedMinutes ? Number(values?.estimatedMinutes) : 0,
              officeId: user?.officeId,
              whenScheduledDate: values.specifyTime
                ? // API will automatically stamp this with the office's timezone when receiveing Zulu
                  formatRawDateTime(values.date! as Date, values.time! as Date)
                : formatRawDate(values.date! as Date),
              taskDefId: values.taskDefId,
              priorityType: values.priorityType,
              userId: values.userId,
              isCompleted: values.isCompleted,
              comments: values.comments,
              whenCompleted:
                values.isCompleted && values.whenCompleted
                  ? formatRawDateTime(
                      new Date(values.whenCompleted!),
                      new Date(values.whenCompleted!)
                    )
                  : '',
              completionNotes: values.completionNotes,
              siteId: ((values.siteId ?? siteId) as string) ?? null,
              scheduledServiceId:
                ((values.scheduledServiceId ?? scheduledServiceId) as string) ?? null,
            };
            currentTask
              ? await updateTask(currentTask?.scheduledTaskId, data)
              : await createTask(data);
            enqueueSnackbar(
              currentTask ? 'Successfully updated task!' : 'Successfully created task!',
              {
                variant: 'success',
              }
            );
            if (currentTask) {
              await fetchTask();
            } else {
              actions.resetForm({
                values,
              });
            }
            if (isModal && handleModalClose) {
              return handleModalClose();
            }
            history.push(redirect ?? '/scheduling/tasks');
          } catch (error: any) {
            if (error?.Detail.includes('Task Definition does not exist.')) {
              document.getElementById('task-def-id')?.focus();
              return enqueueSnackbar(`${error?.Detail} Update the task type above and retry`, {
                variant: 'error',
                autoHideDuration: 8000,
              });
            }
            enqueueSnackbar(error?.Detail ?? 'Error saving task, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ isSubmitting, values, handleSubmit, dirty, isValid, initialValues }) => {
          // the diff between initialValues and values is hard to compare with wysiwyg editors so we remove them from the diff check
          // and we won't show the prompt if they leave the page with unsaved changes when they change the description or completion notes
          let { completionNotes: valueNotes, comments: valueComments, ...restValues } = values;
          let {
            completionNotes: initialNotes,
            comments: initialComments,
            ...restInitialValues
          } = initialValues;

          const children = (
            <Card>
              <CardTitle title="Details" />
              {(isSubmitting || isLoading) && <Loader type="overlay" position="centered" />}
              <Form onSubmit={handleSubmit} autoComplete="none">
                {(currentTask || isNewTask) && (
                  <TaskDetails
                    currentTask={currentTask ?? null}
                    accountIdParam={accountId}
                    siteIdParam={siteId}
                  />
                )}
                <Box mt={1} display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                  <Button
                    type="button"
                    color="inherit"
                    onClick={() => {
                      if (isModal && handleModalClose) {
                        return handleModalClose();
                      }
                      history.push(redirect ?? '/scheduling/tasks');
                    }}
                    startIcon={<CancelIcon />}
                  >
                    Cancel
                  </Button>
                  <SaveButton
                    disabled={
                      !dirty ||
                      !isValid ||
                      isSubmitting ||
                      (values.specifyTime &&
                        (values?.time?.toString() === 'Invalid Date' || !values.time))
                    }
                  />
                </Box>
              </Form>
            </Card>
          );

          return isModal ? (
            <Modal open={isModalOpen!} onClose={handleModalClose} maxWidth="lg" title="Edit Task">
              <Fade in={isModalOpen!}>
                <Box mt={2} mb={4}>
                  {children}
                </Box>
              </Fade>
            </Modal>
          ) : (
            <Page
              title={isNewTask ? 'New Task' : 'Edit Task'}
              breadcrumb={{
                text: 'Tasks',
                title: 'Back to Tasks',
                link: redirect?.includes(`/scheduling/tasks`) ? redirect : `/scheduling/tasks`,
              }}
            >
              <ConfirmPrompt
                when={!deepEqual(restValues, restInitialValues)}
                message={defaultUnsavedChangesMessage}
              />
              {children}
            </Page>
          );
        }}
      </Formik>
    </>
  );
};
