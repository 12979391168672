import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  useMediaQuery,
  styled
} from '@mui/material';
import { FC } from 'react';

interface ICardButtonProps {
  children?: any;
  avatar?: JSX.Element;
  icon?: IconProp;
  title: string | undefined;
  subheader?: string | null;
  onClick?: () => void;
  fullHeight?: boolean;
  alignCenter?: boolean;
  iconSize?: SizeProp;
  iconMobileSize?: SizeProp;
}

interface ICardButtonStyleProps {
  fullHeight?: boolean;
  alignCenter?: boolean;
  children?: any;
};

export const CardButton: FC<ICardButtonProps> = ({
  children,
  avatar,
  icon,
  title,
  subheader,
  onClick,
  fullHeight = true,
  alignCenter = false,
  iconSize = 'xl',
  iconMobileSize = 'lg',
}) => {
  const isSmMobile = useMediaQuery(`(max-width: 567px)`);
  return (
    <StyledCard alignCenter={alignCenter} fullHeight={fullHeight}>
      <CardActionArea onClick={onClick} sx={{ height: '100%' }} disableRipple>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            (avatar || icon) && (
              <Avatar
                sx={{
                  bgcolor: theme => theme.palette.secondary.main,
                  width: isSmMobile ? theme => theme.spacing(5) : theme => theme.spacing(6),
                  height: isSmMobile ? theme => theme.spacing(5) : theme => theme.spacing(6),
                  color: theme => theme.palette.common.white,
                }}
              >
                {avatar}
                {icon && (
                  <FontAwesomeIcon icon={icon} size={isSmMobile ? iconMobileSize : iconSize} />
                )}
              </Avatar>
            )
          }
          title={title}
          subheader={subheader}
          titleTypographyProps={{
            variant: 'h6',
            fontWeight: 'bold',
            color: theme => theme.palette.secondary.main,
          }}
        />
        {children && (
          <CardContent
            sx={{
              backgroundColor: theme => theme.palette.grey[100],
            }}
          >
            {children}
          </CardContent>
        )}
      </CardActionArea>
    </StyledCard>
  );
};

const PREFIX = 'CardButton';

const classes = {
  card: `${PREFIX}-card`,
  cardHeader: `${PREFIX}-cardHeader`
};

const StyledCard = styled(Card, {
  shouldForwardProp:
    prop => prop !== 'fullHeight' && prop !== 'alignCenter',
})<ICardButtonStyleProps>(({ theme, fullHeight, children, alignCenter }) => ({
  '&.MuiCard-root': {
    height: fullHeight ? '100%' : 'auto',
    border: '1px solid transparent',
    '&&:hover, &&:focus, &&:active': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '&& .MuiCardContent-root': {
      backgroundColor: theme.palette.grey[900],
    },
  },

  [`& .${classes.cardHeader}`]: {
    backgroundColor: theme.palette.common.white,
    height: (fullHeight && !Boolean(children) ? '100%' : 'auto'),
    alignItems: (alignCenter ? 'center' : 'flex-start'),
  }
}));