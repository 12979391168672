import { useState } from 'react';
import { Page } from '../../components';
import { PostPaymentPageDetails } from './post-payment-page-details';
import { IBreadcrumb } from '../../models';

export const PostPaymentPage = () => {
  const [pageBreadCrumb, setPageBreadCrumb] = useState<IBreadcrumb | undefined>(undefined);
  return (
    <Page
      title="Transaction Batch"
      breadcrumb={pageBreadCrumb ? pageBreadCrumb : {
        text: 'Payments',
        title: 'Back to Payments',
        link: `/payments`,
      }}
    >
      <PostPaymentPageDetails setPageBreadCrumb={setPageBreadCrumb} />
    </Page>
  );
};
