import { useFormik } from 'formik';
import { FC, useContext } from 'react';
import * as yup from 'yup';
import {
  Loader,
  Modal,
  ModalSaveSection,
  RatesAutoComplete,
  ToggleButtonGroup,
} from '../../components';
import { Alert, Box, Fade, ToggleButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from '../../hooks';
import { UserContext } from '../../context';
import { applyBulkAccountRates } from '../../fetch';

interface ICustomersApplyRateCodesModal {
  open: boolean;
  onClose: (val?: boolean) => void;
  customViewId: string;
  totalCustomers: number;
  filterLetter?: string;
  searchValue?: string;
}

const CustomersApplyRateCodesSchema = yup.object().shape({
  actionType: yup.string().required('Required'),
  rateIds: yup.array().min(1, 'Minimum of 1 rate required'),
});

export const CustomersApplyRateCodesModal: FC<ICustomersApplyRateCodesModal> = ({
  open,
  onClose,
  customViewId,
  totalCustomers,
  filterLetter,
  searchValue,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { user } = useContext(UserContext);
  const {
    resetForm,
    isSubmitting,
    handleSubmit,
    values,
    dirty,
    isValid,
    setFieldValue,
    handleChange,
    errors,
  } = useFormik<any>({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: { actionType: 'Add', rateIds: [] },
    validationSchema: CustomersApplyRateCodesSchema,
    onSubmit: async values => {
      try {
        const result = await confirm(
          `Are you sure you want to edit the existing ${totalCustomers} customers with the rate codes?`
        );
        if (result) {
          const data = {
            action: values.actionType,
            rateIds: values.rateIds,
            customViewId,
            officeId: user?.officeId ?? '',
            ...(!!filterLetter && { filterLetter }),
            ...(!!searchValue && { searchValue }),
          };
          await applyBulkAccountRates(data);
          enqueueSnackbar('Customer rates were successfully updated!', {
            variant: 'success',
          });
          handleClose(true);
        } else {
          return;
        }
      } catch (error: any) {
        enqueueSnackbar(
          error?.data?.Detail ??
            'An error occurred while updating customer rates, please try again.',
          {
            variant: 'error',
          }
        );
      }
    },
  });

  const handleClose = (reset?: boolean) => {
    onClose(reset);
    resetForm();
  };

  const isSaveDisabled = !dirty || isSubmitting || !isValid;
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="sm"
      title="Apply Rates to Customers"
    >
      {isSubmitting && <Loader type="fullscreen" position="centered" />}
      <Fade in={open}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box mt={2}>
            <Alert severity="info">
              Use the form below to add or remove multiple rates for all customers in the current
              custom view selected.
            </Alert>
            <Box mb={2} mt={2} display="flex" sx={{ flexFlow: 'column wrap' }}>
              <ToggleButtonGroup
                fullWidth
                exclusive
                size="small"
                id="actionType"
                label="Action Type"
                value={values.actionType}
                onChange={(e: React.MouseEvent<HTMLElement>, newValue: string) => {
                  setFieldValue('actionType', newValue);
                  handleChange(e);
                }}
                aria-label="Apply Rate Action Type"
              >
                <ToggleButton value="Add" aria-label="Add Rate(s)">
                  <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{ marginRight: '.5rem' }} />
                  Add
                </ToggleButton>
                <ToggleButton value="Remove" aria-label="Remove Rate(s)">
                  <FontAwesomeIcon icon={faTrash} size="lg" style={{ marginRight: '.5rem' }} />
                  Remove
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box mb={2}>
              <RatesAutoComplete
                handleChange={val => setFieldValue('rateIds', val)}
                apiFilters={{
                  officeId: user?.officeId ?? '',
                  sortBy: 'Description',
                  sortDirection: 'Asc',
                  perPage: -1,
                }}
                error={!!errors.rateIds}
                helperText={!!errors?.rateIds ? errors?.rateIds.toString() : ''}
              />
            </Box>
          </Box>
          <ModalSaveSection
            handleCancel={() => {
              handleClose();
            }}
            isSaveDisabled={isSaveDisabled}
            submitLabel={`${values.actionType === 'Remove' ? values.actionType : 'Apply'} Rates`}
          />
        </form>
      </Fade>
    </Modal>
  );
};
