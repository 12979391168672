import { LogLevel } from '@azure/msal-browser';
import { appInsights } from './appinsights';
const {
  REACT_APP_MSAL_CLIENT_ID,
  REACT_APP_MSAL_TENANT_ID,
  REACT_APP_MSAL_SCOPES,
  // Dev Note: Uncomment this when the 'Central Auth' feature is desired
  // REACT_APP_MSAL_SCOPES_AuthZ,
  REACT_APP_MSAL_REDIRECT,
  REACT_APP_MSAL_AUTHORITY_DOMAIN,
  REACT_APP_MSAL_AUTHORITY,
} = require('../buildSettings.json');

const trackMsal = (data: any) => {
  appInsights.trackEvent({ name: 'MSAL Logging', properties: { ...data } });
};

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNIN',
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${REACT_APP_MSAL_AUTHORITY_DOMAIN}/${REACT_APP_MSAL_AUTHORITY}/B2C_1A_SIGNIN`,
    },
  },
  authorityDomain: `${REACT_APP_MSAL_AUTHORITY_DOMAIN}`,
};

export const msalConfig = {
  auth: {
    clientId: REACT_APP_MSAL_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: REACT_APP_MSAL_REDIRECT,
    // Case: Someone logs out as one type of user and logs in as another type.
    // They should be redirected to the home page, since that will then redirect them to the proper dashboard.
    postLogoutRedirectUri: REACT_APP_MSAL_REDIRECT,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          console.error('containsPii');
          return;
        }
        switch (level) {
          case LogLevel.Error:
          case LogLevel.Info:
          case LogLevel.Verbose:
          case LogLevel.Warning:
            trackMsal({ level, message });
            return;
        }
      },
    },
  },
};

// Coordinates and required scopes for your web API
export const apiConfig = {
  resourceUri: 'https://localhost:44351/api/profile',
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    `https://${REACT_APP_MSAL_AUTHORITY}/${REACT_APP_MSAL_TENANT_ID}/User.Read`,
    `https://${REACT_APP_MSAL_AUTHORITY}/${REACT_APP_MSAL_TENANT_ID}/openid`,
  ],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
  scopes: [REACT_APP_MSAL_SCOPES],
};

// Dev Note: Uncomment this when the 'Central Auth' feature is desired
// // Add here scopes for access token to be used for central authZ.
// export const authorizationRequest = {
//   scopes: !!REACT_APP_MSAL_SCOPES_AuthZ ? [REACT_APP_MSAL_SCOPES_AuthZ] : [],
// };
