import { TextField, styled } from '@mui/material';
import { FC } from 'react';

interface IAuthInputProps {
  inputKey: string;
  values: any;
  touched: any;
  errors: any;
  label: string;
  shrink?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  id: string;
  type?: string;
}

export const AuthInput: FC<IAuthInputProps> = ({
  inputKey,
  touched,
  errors,
  values,
  label,
  shrink,
  onChange,
  onBlur,
  id,
  type,
}) => {

  return (
    <StyledTextField
      error={touched?.[inputKey] && errors && errors?.[inputKey] ? true : false}
      fullWidth={true}
      helperText={touched?.[inputKey] && errors && errors?.[inputKey]}
      id={`${id}-${inputKey}`}
      label={label}
      margin="none"
      required={true}
      name={inputKey}
      type={type ?? 'text'}
      value={values?.[inputKey]}
      className={classes.borderRadius}
      onChange={onChange}
      onBlur={onBlur}
      InputLabelProps={{
        shrink,
      }}
    />
  );
};

const PREFIX = 'AuthInput';

const classes = {
  borderRadius: `${PREFIX}-borderRadius`
};

const StyledTextField = styled(TextField)(({ theme }) => {
  return {
    [`&.${classes.borderRadius}`]: {
      borderRadius: '30px',
      '& .MuiOutlinedInput-root': {
        borderRadius: '30px',
        overflow: 'hidden',
        '&:focus, &:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
          },
        },
        '&.Mui-error': {
          '&:focus, &:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
          },
        },
      },

      '& .MuiOutlinedInput-root legend': {
        marginLeft: theme.spacing(2),
      },
      '& .MuiInputLabel-shrink, && .MuiInputLabel-root': {
        marginLeft: theme.spacing(2),
      },
      '& .MuiFormHelperText-root': {
        marginLeft: theme.spacing(4),
      },
      '& .MuiOutlinedInput-input': {
        textIndent: '16px',
      },
    },
  };
});