import { FC } from 'react';
import { Page } from '../../../components';
import { HealthChecksDetail } from './health-checks-detail';

export const HealthChecksPage: FC = () => {
  return (
    <Page title="Health Checks">
      <HealthChecksDetail />
    </Page>
  );
};
