import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { Wysiwyg } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';

export const StyleGuideWYSIWYG = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="WYSIWYG">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The WYSIWYG component is a custom build upon the{' '}
            <Link href="https://quilljs.com/" target="_blank">
              QuillJS editor
            </Link>
            . It has a built-in styles with a custom theme, default toolbar options, and allows for
            inserting links.
          </Typography>
          <Typography mb={1}>
            This particular component is meant to be used for fields that require formatting text,
            adding/editing links to text, or rendering complex markup, such as HTML or headings. For
            regular textarea/multi-line inputs, use{' '}
            <Link href="https://mui.com/material-ui/react-text-field/#multiline" target="_blank">
              MUI Text Fields
            </Link>{' '}
            with the multiline prop set.
          </Typography>
          <code>
            Props:
            <br />- className?: string
            <br />- classNameWrapper?: string
            <br />- onChange: (html: string) =&gt; void
            <br />- onBlur?: () =&gt; void
            <br />- readOnly?: boolean
            <br />- value: string
            <br />- editorFormats?: Array&lt;string&gt;
            <br />- editorOptions?: Array&lt;any&gt;
            <br />- placeholder?: string
            <br />- label?: string
            <br />- required?: boolean
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField:
            '<p>This is a <a href="/" target="_blank" title="Test Link"><strong>Test</strong></a> message.</p>',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                  <Box mb={1} p={0.5}>
                    <Wysiwyg
                      label="Test Editor"
                      required
                      value={values.testField}
                      onChange={val => setFieldValue('testField', val)}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;Wysiwyg <br />
                    &nbsp;&nbsp;&nbsp;required <br />
                    &nbsp;&nbsp;&nbsp;label="Test Editor" <br />
                    &nbsp;&nbsp;&nbsp;value=&#123;values.testField&#125; <br />
                    &nbsp;&nbsp;&nbsp;onChange=&#123;val =&gt; setFieldValue('testField', val)
                    &#125; <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
