import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { AutocompleteAsync } from '../../../components';
import { getNews } from '../../../fetch';
import { INews, IResponse } from '../../../models';
import { Form, Formik } from 'formik';
import { StyleGuideMultiselectAlert } from '../multiselect-alert';

export const StyleGuideAutocompleteAsync = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  const [exampleItems, setExampleItems] = useState<INews[]>([]);

  return (
    <StyleGuidePage title="Autocomplete Async">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Autocomplete Async is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-autocomplete/" target="_blank">
              Mui Autocomplete
            </Link>{' '}
            component and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has built-in styles, props for making an API call to get options, and Formik props
            required for use with a Formik form and form validation.
          </Typography>
          <Typography mb={1}>
            Async Autocomplete fields help reduce page/component clutter by grouping non-dependent
            calls and loading states for autocomplete dropdown options within the field itself. For
            example, if an autocomplete has no need to be updated based on the selection from
            another filter/field, use Async Autcomplete component to bundle up GET calls and loading
            states.
          </Typography>
          <StyleGuideMultiselectAlert showMore={false} />
          <code>
            Props:
            <br />- name: string
            <br />- apiRequest: () =&gt; Promise&lt;any&gt;
            <br />- transformResponse: (response: any) =&gt; string[]
            <br />- onChange?: any
            <br />- handleOptions?: (options: any[]) =&gt; void
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '',
          testField2: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ handleBlur }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <AutocompleteAsync
                      name="testField"
                      label="Example"
                      apiRequest={() =>
                        getNews({
                          perPage: 30,
                          page: 1,
                          sortBy: 'siteName',
                          sortDirection: 'asc',
                        })
                      }
                      transformResponse={(response: IResponse<INews[]>) => {
                        return response.records.map(
                          item => `Option ${item.newsItemId.toString().slice(0, 4)}`
                        );
                      }}
                      onBlur={e => {
                        handleBlur(e); // Formik handleBlur
                      }}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;AutocompleteAsync
                    <br />
                    &nbsp;&nbsp;&nbsp;name="testField"
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Example"
                    <br />
                    &nbsp;&nbsp;&nbsp;apiRequest=&#123;() =&gt; <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;getNews(&#123; perPage: 30, page: 1, sortBy:
                    'siteName', sortDirection: 'asc', &#125;) <br />
                    &nbsp;&nbsp;&nbsp;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;transformResponse=&#123;(response: IResponse&lt;INews[]&gt;)
                    =&gt; &#123; <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return response.records.map( item =&gt;
                    `Option $&#123;item.newsItemId.toString().slice(0, 4)&#125;` ); <br />
                    &nbsp;&nbsp;&nbsp;&#125;&#125; <br />
                    &nbsp;&nbsp;&nbsp;onBlur=&#123;e =&gt; &#123; handleBlur(e); // Formik
                    handleBlur &#125;&#125; <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <AutocompleteAsync
                      name="testField2"
                      label="Example 2"
                      apiRequest={() =>
                        getNews({
                          perPage: 30,
                          page: 1,
                          sortBy: 'siteName',
                          sortDirection: 'asc',
                        })
                      }
                      transformResponse={(response: IResponse<INews[]>) => {
                        return response.records.map(
                          item => `Option ${item.newsItemId.toString().slice(0, 4)}`
                        );
                      }}
                      handleOptions={data => {
                        setExampleItems(data);
                        console.log(exampleItems);
                      }}
                      onBlur={e => {
                        handleBlur(e); // Formik handleBlur
                      }}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;AutocompleteAsync
                    <br />
                    &nbsp;&nbsp;&nbsp;name="testField2"
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Example 2"
                    <br />
                    &nbsp;&nbsp;&nbsp;apiRequest=&#123;() =&gt; <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;getNews(&#123; perPage: 30, page: 1, sortBy:
                    'siteName', sortDirection: 'asc', &#125;) <br />
                    &nbsp;&nbsp;&nbsp;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;transformResponse=&#123;(response: IResponse&lt;INews[]&gt;)
                    =&gt; &#123; <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return response.records.map( item =&gt;
                    `Option $&#123;item.newsItemId.toString().slice(0, 4)&#125;` ); <br />
                    &nbsp;&nbsp;&nbsp;&#125;&#125; <br />
                    &nbsp;&nbsp;&nbsp;handleOptions=&#123;data =&gt; &#123; <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setExampleItems(data); <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;console.log(exampleItems); <br />
                    &nbsp;&nbsp;&nbsp;&#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;onBlur=&#123;e =&gt; &#123; handleBlur(e); // Formik
                    handleBlur &#125;&#125; <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
