import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
 
declare global {
  interface Window {
    document360Widget: any;
  }
}

interface LocalProps {
  apiKeyWidget: string;
}

export const Document360Widget = (props: LocalProps) => {
  const { v2HelpWidget } = useFlags();
  useEffect(() => {
    if (v2HelpWidget === true) {
      // @ts-ignore
      (function (w: any, d: any, s: any, o: any, f: any, js: any, fjs: any) {
        w['JS-Widget'] = o;
        w[o] =
          w[o] ||
          function () {
            (w[o].q = (w[o].q || [])).push(arguments);
          };
        // @ts-ignore
        (js = d.createElement(s));
        (fjs = d.getElementsByTagName(s)[0]); // eslint-disable-line
        js.id = o;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      })(
        window,
        document,
        'script',
        'document360Widget',
        'https://cdn.us.document360.io/static/js/widget.js'
      );
      window.document360Widget('init', {
        apiKey: props.apiKeyWidget,
      });
    } else {
      document.getElementById('document360-widget-iframe')?.remove();
    }
  }, [v2HelpWidget, props.apiKeyWidget]);
  return null;
};