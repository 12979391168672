import React, { createContext, FC } from 'react';
import appLogo from '../styles/images/360/pool360-white-logo-web-sm.png';
import appColorLogo from '../styles/images/360/pool360-color-logo-web-sm.png';
import iconLogo from '../styles/images/360/360-icon.png';
import logoPrint from '../styles/images/360/pool360-color-logo-print.jpg';
import logoLogin from '../assets/logo/360-login-logo.png';

interface IBrandingContext {
  appLongName: string;
  appShortName: string;
  fullLogo: string;
  fullColorLogo: string;
  logoIcon: string;
  printLogo: string;
  loginLogo: string;
  LEGAL_NAME: string;
  LEGAL_APP_NAME: string;
  SUPPORT_EMAIL: string;
  isPoolService: boolean;
  agreementName: string;
  defaultEstimateEmailSubject: string;
  defaultEstimateEmailBody: string;
  copyRight: string;
  authorizeName: string;
}

interface IBrandingContextHandlerProps {
  children: React.ReactNode;
}

export const BrandingContext = createContext<IBrandingContext>({
  appLongName: '',
  appShortName: '',
  fullLogo: '',
  fullColorLogo: '',
  logoIcon: '',
  printLogo: '',
  loginLogo: '',
  LEGAL_NAME: '',
  LEGAL_APP_NAME: '',
  SUPPORT_EMAIL: '',
  isPoolService: true,
  agreementName: '',
  defaultEstimateEmailSubject: '',
  defaultEstimateEmailBody: '',
  copyRight: '',
  authorizeName: '',
});

export const BrandingContextHandler: FC<IBrandingContextHandlerProps> = ({ children }) => {
  return (
    <BrandingContext.Provider
      value={{
        appLongName: 'pool360 | poolservice',
        appShortName: 'poolservice',
        copyRight: 'Pool Corporation',
        fullLogo: appLogo,
        fullColorLogo: appColorLogo,
        logoIcon: iconLogo,
        printLogo: logoPrint,
        loginLogo: logoLogin,
        LEGAL_NAME: 'Pinch A Penny, LLC.',
        LEGAL_APP_NAME: 'PoolCarePRO',
        SUPPORT_EMAIL: 'PCPSupport@pinchapenny.com',
        isPoolService: true,
        agreementName: 'Pool 360',
        authorizeName: '',
        // [AgreementName] will be replaced by the actual value upon use
        defaultEstimateEmailSubject: 'Your Service Agreement',
        // [ServiceType] will be replaced by the actual value upon use
        defaultEstimateEmailBody: `Thank you for choosing [AgreementName] for your [ServiceType] needs.  Please review and sign the attached agreement.`,
      }}
    >
      {children}
    </BrandingContext.Provider>
  );
};
