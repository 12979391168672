import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {
  CardTitle,
  FilterForm,
  GridDataFetcher,
  useDataGrid,
  useFilters,
  ServerSideDataGrid,
  CardFiltersLayout,
  Card,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { IFilterLayout, INews } from '../../../models';
import { deleteNewsItem, getNews, getNewsFilters } from '../../../fetch';
import {
  dateColFormatter,
  formatDate,
  formatShortFriendlyDate,
  hyphenSeparateTwoInputs,
  truncate,
} from '../../../helpers';
import { dateColMinWidth } from '../../../constants/data-grid';
import {
  faFilter,
  faFilterCircleXmark,
  faPlusCircle,
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { AddEditNewsItemModal } from './add-edit-news-item-modal';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { useLocation } from 'react-router-dom';

const filtersLayout: Record<string, IFilterLayout> = {
  Category: {
    sortOrder: 0,
    xs: 12,
    md: 6,
  },
  SubjectSearch: {
    sortOrder: 1,
    xs: 12,
    sm: 6,
  },
  MessageSearch: {
    sortOrder: 2,
    xs: 12,
    sm: 6,
  },
  DisplayDate: {
    sortOrder: 3,
    xs: 12,
    md: 6,
  },
  CreatedDate: {
    sortOrder: 4,
    xs: 12,
    md: 6,
  },
  TakeDownDate: {
    sortOrder: 5,
    xs: 12,
    lg: 6,
  },
};

export const NewsManagementTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isSuperAdmin, user } = useContext(UserContext);
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentNewsItem, setCurrentNewsItem] = useState<INews | null>(null);
  const confirm = useConfirm();
  const location = useLocation();

  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset,
  } = useFilters({
    filterFetcher: useCallback(() => getNewsFilters(), []),
  });

  const dataFetcher: GridDataFetcher<INews> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      if (!filtersInitialized) {
        return {
          continueLoading: true,
        };
      }

      try {
        const res = await getNews({
          sortDirection: sortDirection || 'desc',
          sortBy: sortColumn || 'displayDate',
          perPage,
          page: page + 1,
          category: appliedFilters?.Category?.[0] ?? undefined,
          subjectSearch: appliedFilters?.SubjectSearch?.[0] ?? undefined,
          messageSearch: appliedFilters?.MessageSearch?.[0] ?? undefined,
          displayDateStartDate: appliedFilters?.DisplayDate?.[0] ?? undefined,
          displayDateEndDate: appliedFilters?.DisplayDate?.[1] ?? undefined,
          takeDownDateStartDate: appliedFilters?.TakeDownDate?.[0] ?? undefined,
          takeDownDateEndDate: appliedFilters?.TakeDownDate?.[1] ?? undefined,
          createdDateStartDate: appliedFilters?.CreatedDate?.[0] ?? undefined,
          createdDateEndDate: appliedFilters?.CreatedDate?.[1] ?? undefined,
          officeId: location.pathname.includes('admin') ? undefined : user?.officeId!,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading news, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersInitialized, appliedFilters]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<INews>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'news-management-grid',
      sortColumn: 'displayDate',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  const handleDelete = async (newsItem: INews) => {
    try {
      const result = await confirm('Are you sure you want to delete this news item?');
      if (result) {
        setDeleting(true);
        await deleteNewsItem(newsItem.newsItemId);
        await refetch();
        enqueueSnackbar(`News Item Deleted!`, {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error deleting news item, please try again.`, {
        variant: 'error',
      });
    } finally {
      setDeleting(false);
    }
  };

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'category',
        headerName: 'Category',
        type: 'string',
        disableColumnMenu: true,
      },
      {
        field: 'subject',
        headerName: 'Subject',
        type: 'string',
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<INews>) => {
          const { row: newsItem } = params;
          if (newsItem.subject.length > 15) {
            return (
              <Tooltip
                key={`${newsItem.newsItemId}-subject`}
                placement="bottom"
                title={newsItem.subject}
              >
                <span>{truncate(newsItem.subject, 15)}</span>
              </Tooltip>
            );
          }
          return <>{newsItem.subject}</>;
        },
      },
      isSuperAdmin && {
        field: 'stores',
        headerName: 'Offices',
        type: 'string',
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<INews>) => {
          const { row: newsItem } = params;
          const hasOffices = newsItem.offices && newsItem.offices.length > 0;
          const hasMultipleOffices = newsItem.offices && newsItem.offices.length > 1;
          const firstOfficeName =
            (newsItem.offices && newsItem.offices[0] && newsItem.offices[0].officeName) ?? '';
          const offices =
            newsItem?.offices?.map(office => {
              return office.officeName;
            }) ?? [];
          if (!hasOffices) {
            return 'All Offices';
          }
          if (hasOffices && (hasMultipleOffices || firstOfficeName.length > 15)) {
            return (
              <Tooltip
                key={`${newsItem.newsItemId}-offices`}
                placement="bottom"
                title={offices?.join(', ')}
              >
                <span>{truncate(offices?.join(', '), 15)}</span>
              </Tooltip>
            );
          }
          return <>{offices?.join(', ')}</>;
        },
      },
      {
        field: 'message',
        headerName: 'Message',
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<INews>) => {
          const { row: newsItem } = params;
          const message = newsItem.message.replace(/<\/?[^>]+(>|$)/g, '');
          if (message.length > 15) {
            return (
              <Tooltip key={`${newsItem.newsItemId}-message`} placement="bottom" title={message}>
                <span>{truncate(message, 15)}</span>
              </Tooltip>
            );
          }
          return <>{message}</>;
        },
      },
      {
        field: 'displayDate',
        headerName: 'Display Date',
        valueFormatter: dateColFormatter,
        minWidth: dateColMinWidth,
        type: 'string',
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<INews>) => {
          return (
            <>{params.row.displayDate ? formatShortFriendlyDate(params.row.displayDate) : ''}</>
          );
        },
      },
      {
        field: 'takedownDate',
        headerName: 'Takedown Date',
        valueFormatter: dateColFormatter,
        minWidth: dateColMinWidth,
        type: 'string',
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<INews>) => {
          return (
            <>{params.row.takeDownDate ? formatShortFriendlyDate(params.row.takeDownDate) : ''}</>
          );
        },
      },
      {
        field: 'dateCreated',
        headerName: 'Date Created',
        valueFormatter: dateColFormatter,
        minWidth: dateColMinWidth,
        type: 'string',
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<INews>) => {
          return <>{params.row.createdDate ? formatDate(params.row.createdDate) : ''}</>;
        },
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        align: 'center',
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<INews>) => {
          const hasMultipleOffices = params.row.offices && params.row.offices.length > 1;
          const isAllOffices = params.row.offices && params.row.offices.length === 0;
          if (!isSuperAdmin && (hasMultipleOffices || isAllOffices)) {
            return null;
          }
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit News Item"
                onClick={() => {
                  showModal(true);
                  setCurrentNewsItem(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete News Item"
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ].filter(Boolean) as GridColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isDeleting]);

  return (
    <>
      <Card>
        <CardTitle
          title="News"
          action={
            <>
              <Button
                onClick={onFilterToggle}
                startIcon={
                  <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                }
                className={clsx('print--none')}
                color="secondary"
                size="small"
                disabled={isLoading}
              >
                Filters
              </Button>
              <Button
                onClick={() => showModal(true)}
                color="secondary"
                size="small"
                disabled={isLoading}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add News
              </Button>
            </>
          }
        />
        <CardFiltersLayout isOpen={isShowingFilters}>
          <FilterForm
            filters={filters}
            values={filterValues}
            filterLayouts={filtersLayout}
            defaultLayout={{ xs: 12 }}
            onSubmit={values => {
              onPageChange(0);
              onSubmitFilters(values);
            }}
            onChange={onFiltersChange}
            isSubmitting={isLoading}
            onReset={onReset}
          />
        </CardFiltersLayout>
        <ServerSideDataGrid
          autoHeight
          getRowId={(row: INews) => row.newsItemId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
          mobileBreakPoint={1340}
          mobileProps={{
            mobileCustomDefaultAccessor: (newsItem: INews) => {
              return hyphenSeparateTwoInputs(newsItem.category, newsItem.subject);
            },
            showHandleActions: true,
          }}
        />
      </Card>
      <AddEditNewsItemModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentNewsItem(null);
        }}
        currentNewsItem={currentNewsItem}
        fetchNewsItems={() => refetch()}
      />
    </>
  );
};
