import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Modal,
  ModalSaveSection,
  SelectAsyncInput,
  Loader,
} from '../../../components';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  IOffice,
  IPaginatedResponse,
  ISuperAdminUser,
  IUserGroup,
  IResponse,
} from '../../../models';
import { downgradeSuperAdminUser, getSuperAdmins } from '../../../fetch/super-admin';
import { Box, Fade, IconButton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../../context';
import { getOffices, getUserGroups } from '../../../fetch';
import { useConfirm } from '../../../hooks';
import { unCamelCase } from '../../../helpers';

export const SuperAdminUsersTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<ISuperAdminUser | null>(null);
  const [selectedUserGroup, setSelectedUserGroup] = useState<string>('');
  const [selectedOffice, setSelectedOffice] = useState<string>('');
  const [isDowngradeLoading, setIsDowngradeLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const confirm = useConfirm();

  const dataFetcher: GridDataFetcher<ISuperAdminUser> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const res = await getSuperAdmins({
          sortDirection: sortDirection || 'asc',
          sortBy: sortColumn || 'userName',
          perPage,
          page: page + 1,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading super admins, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: fetchSuperAdmins,
  } = useDataGrid<ISuperAdminUser>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'super-admins-management-grid',
      sortColumn: 'userName',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'userName',
        headerName: 'User',
        type: 'string',
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'loginName',
        headerName: 'Login Name',
        type: 'string',
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        maxWidth: 100,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ISuperAdminUser>) => {
          if (params.row.userId === user?.userId) {
            return <></>;
          }
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Downgrade user"
                onClick={() => {
                  setIsShowingModal(true);
                  setCurrentUser(params.row);
                }}
              >
                <FontAwesomeIcon icon={faDownload} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: ISuperAdminUser) => row.userId}
        rows={rows}
        columns={columns}
        loading={isLoading}
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        sortModel={sortModel}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        hasMobileLayout
      />
      <Modal
        open={isShowingModal}
        onClose={() => {
          setIsShowingModal(false);
          setCurrentUser(null);
          setSelectedUserGroup('');
          setSelectedOffice('');
        }}
        maxWidth="sm"
        title={`Downgrade ${
          currentUser?.userName ? currentUser?.userName : 'User'
        } from Super Admin`}
      >
        <Fade in={isShowingModal}>
          <div>
            {isDowngradeLoading && <Loader type="overlay" position="centered" />}
            <Stack gap={2} sx={{ mt: 1 }}>
              <SelectAsyncInput
                label="Office"
                required
                name="office"
                value={selectedOffice}
                handleChange={value => {
                  setSelectedOffice(value);
                }}
                apiRequest={() => {
                  return getOffices({
                    perPage: -1,
                    sortBy: 'officeName',
                  });
                }}
                transformResponse={(res: IResponse<IOffice[]>) => {
                  return res.records.map((office: IOffice) => ({
                    label: office.officeName,
                    value: office.officeId,
                  }));
                }}
                hasClear={false}
              />
              {selectedOffice && (
                <SelectAsyncInput
                  label="User Group"
                  required
                  name="user-group"
                  value={selectedUserGroup}
                  handleChange={value => {
                    setSelectedUserGroup(value);
                  }}
                  disabled={!selectedOffice}
                  apiRequest={() => {
                    return getUserGroups({
                      perPage: -1,
                      officeId: selectedOffice,
                    });
                  }}
                  transformResponse={(res: IPaginatedResponse<IUserGroup>) => {
                    const data = [
                      ...(res.data ?? []).map(r => ({
                        label: unCamelCase(r.userGroupName),
                        value: r.userGroupId,
                      })),
                    ];
                    return data;
                  }}
                  hasClear={false}
                />
              )}
            </Stack>
            <ModalSaveSection
              handleCancel={() => {
                setIsShowingModal(false);
                setCurrentUser(null);
                setSelectedUserGroup('');
                setSelectedOffice('');
              }}
              isSaveDisabled={!selectedUserGroup || !selectedOffice}
              handleSave={async () => {
                const result = await confirm(
                  'Are you sure you want to downgrade this super admin?'
                );
                if (!result) {
                  return;
                }
                setIsDowngradeLoading(true);
                try {
                  await downgradeSuperAdminUser(currentUser?.userId!, {
                    officeId: selectedOffice,
                    userGroupId: selectedUserGroup,
                  });
                  setIsShowingModal(false);
                  setCurrentUser(null);
                  setSelectedUserGroup('');
                  setSelectedOffice('');
                  fetchSuperAdmins();
                  enqueueSnackbar('User has been downgraded', {
                    variant: 'success',
                  });
                } catch (error: any) {
                  enqueueSnackbar(error?.Detail ?? `Error downgrading user, please try again.`, {
                    variant: 'error',
                  });
                } finally {
                  setIsDowngradeLoading(false);
                }
              }}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};
