import { FC } from 'react';
import { Page } from '../../../components';
import { UsersDetail } from './users-detail';

export const Users: FC = () => {
  return (
    <Page title="Manage Users">
      <UsersDetail />
    </Page>
  );
};
