import { Typography, Box, Stack, styled } from '@mui/material';
import { FC, useContext } from 'react';
import { BrandingContext } from '../../context/branding-context';

interface IPageTitle {
  title: string;
  subTitle?: string;
}

export const PublicPageTitle: FC<IPageTitle> = ({ title, subTitle }) => {
  const { appLongName, loginLogo } = useContext(BrandingContext);

  return (
    <StyledBox
      display="flex"
      gap={2}
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
    >
      <Box>
        <img src={loginLogo} alt={`${appLongName} Logo`} className={classes.logo} />
      </Box>
      <Stack gap={1}>
        <Typography className={classes.header} variant="h2">
          {title}
        </Typography>
        {subTitle && (
          <Typography className={classes.subHeader} variant="body1">
            {subTitle}
          </Typography>
        )}
      </Stack>
    </StyledBox>
  );
};


const PREFIX = 'PublicPageTitle';

const classes = {
  logo: `${PREFIX}-logo`,
  header: `${PREFIX}-header`,
  subHeader: `${PREFIX}-subHeader`,
  pageTitle: `${PREFIX}-pageTitle`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.logo}`]: {
    width: '40px',
    [theme.breakpoints.up('lg')]: {
      width: '50px',
      position: 'relative',
      top: '.25rem',
    },
  },

  [`& .${classes.header}`]: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    fontSize: '2rem',
    whiteSpace: 'normal',
    minWidth: '1px',

    [theme.breakpoints.up('lg')]: {
      whiteSpace: 'nowrap',
      fontSize: '3rem',
    },
  },

  [`& .${classes.subHeader}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.pageTitle}`]: {
    marginBottom: theme.spacing(2),
    position: 'relative',
    zIndex: 1,
  }
}));