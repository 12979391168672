import { FC, useEffect, useState, useContext, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Fade,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { ExternalLink, Link, Modal, Loader } from '../../components';
import { ILeadDetail } from '../../models';
import { reassignLead } from '../../fetch';
import { UserContext } from '../../context';
import {
  formatShortFriendlyDateWithTime,
  getAddressMapLink,
  getCustomerDetailRoute,
} from '../../helpers';
import { faCopy, faLocationDot, faRefresh, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { getServiceTypeIcon } from './constants';
import { useHistory } from 'react-router-dom';

interface ILeadDetailHeaderProps {
  lead: ILeadDetail | null;
  handleCopyClick: (val: string) => void;
  isCopied: {
    [val: string]: boolean;
  };
}

export const LeadDetailHeader: FC<ILeadDetailHeaderProps> = ({
  lead,
  handleCopyClick,
  isCopied,
}) => {
  const isSmMobile = useMediaQuery(`(max-width: 567px)`);
  const [isShowingReassignModal, showReassignModal] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const { values } = useFormikContext<any>();
  const formatAddressText = (
    street?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null
  ) => {
    // Conditionally display separators based on available values
    // Formatted to display address on a single line
    // Returns a string for copy purposes
    const streetSeparator = !!street && (!!city || !!state || !!postalCode) ? `, ` : '';
    const formattedStreet = !!street ? `${street}${streetSeparator}` : '';
    const citySeparator = !!state || !!postalCode ? `, ` : '';
    const formattedCity = !!city ? `${city}${citySeparator}` : '';
    const formattedState = !!state ? `${state} ` : '';
    const formattedPostalCode = !!postalCode ? postalCode : '';

    return `${formattedStreet}${formattedCity}${formattedState}${formattedPostalCode}`;
  };
  const [leadAddress, setLeadAddress] = useState<JSX.Element | null>(null);
  const renderAddress = () => {
    const hasAddress =
      !!values?.addressModel?.address ||
      !!values?.addressModel?.city ||
      !!values?.addressModel?.state ||
      !!values?.addressModel?.postalCode;

    // If any kind of address info to display, build link. Else don't show anything
    if (hasAddress) {
      const addressText = formatAddressText(
        values.addressModel.address,
        values.addressModel.city,
        values.addressModel.state,
        values.addressModel.postalCode
      );

      const addressLink = getAddressMapLink(
        values.addressModel.address,
        values.addressModel.city,
        values.addressModel.state,
        values.addressModel.postalCode
      );
      return (
        <>
          <ExternalLink
            type="secondary"
            target="_blank"
            to={addressLink}
            title="View Map on Bing Maps"
          >
            <FontAwesomeIcon icon={faLocationDot} size="lg" style={{ marginRight: '.5rem' }} />
            {addressText}
          </ExternalLink>
          <IconButton
            disabled={isCopied[addressText]}
            onClick={() => handleCopyClick(addressText)}
            sx={{ marginLeft: '0.5rem' }}
            title="Copy Address"
            color="secondary"
            size="medium"
            className="print--none"
          >
            <FontAwesomeIcon icon={faCopy} />
          </IconButton>
        </>
      );
    } else {
      return null;
    }
  };

  const accountId = useMemo(() => lead?.customer?.accountId ?? lead?.potentialAccountId, [lead]);
  const showReassignButton = useMemo(
    // lead has to be in the "New" status statusBar needs to be "Lead"
    // we need a value for syncLeadId in order for the API call to be successful
    () => lead?.statusBarStatus === 'Lead' && lead?.status === 'New' && lead?.syncLeadId !== null,
    [lead]
  );

  useEffect(() => {
    // Update link/address text based on entered values from form
    setLeadAddress(renderAddress());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.addressModel]);

  return (
    <>
      <Grid container spacing={{ xs: 1.5, sm: 3 }} display="flex">
        <Grid item>
          <Avatar
            sx={{
              bgcolor: theme => theme.palette.primary.main,
              width: isSmMobile ? theme => theme.spacing(5) : theme => theme.spacing(9),
              height: isSmMobile ? theme => theme.spacing(5) : theme => theme.spacing(9),
            }}
          >
            {lead?.serviceTypeValue ? (
              <FontAwesomeIcon
                icon={getServiceTypeIcon(lead.serviceType)}
                size={isSmMobile ? '1x' : '2xl'}
              />
            ) : undefined}
          </Avatar>
        </Grid>
        <Grid item flex={1}>
          <Grid
            container
            justifyContent={{ lg: 'space-between' }}
            spacing={1}
            flexWrap={{ lg: 'nowrap' }}
            display="flex"
          >
            <Grid item xs={12} md={7} flex={{ xs: undefined, lg: 1 }}>
              <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold' }}>
                {values?.customerFirstName && values?.customerLastName
                  ? `${values.customerFirstName} ${values.customerLastName}`
                  : lead?.customerName}
              </Typography>
              <Typography variant="subtitle1" component="p">
                {lead?.serviceType}
              </Typography>
              {!!leadAddress && (
                <Typography
                  variant="subtitle1"
                  component="address"
                  display={isSmMobile ? 'inline-block' : 'flex'}
                  alignItems="center"
                  flexWrap={isSmMobile ? 'wrap' : 'nowrap'}
                >
                  {leadAddress}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={5} lg={'auto'} textAlign={{ xs: 'left', md: 'right' }}>
              {lead?.date && (
                <Typography variant="subtitle1" component="p">
                  Submitted: {formatShortFriendlyDateWithTime(lead.date)}
                </Typography>
              )}

              {!!accountId && (
                <Typography variant="subtitle1" component="p">
                  <Link to={`${getCustomerDetailRoute(accountId)}`} type="secondary">
                    <FontAwesomeIcon icon={faUser} size="lg" style={{ marginRight: '.5rem' }} />
                    View Customer Record
                  </Link>
                </Typography>
              )}
              {showReassignButton && (
                <Box mt={1}>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setReason('');
                      showReassignModal(true);
                    }}
                    startIcon={<FontAwesomeIcon icon={faRefresh} />}
                  >
                    Reassign
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{
          marginTop: theme => theme.spacing(2),
          borderColor: theme => theme.palette.dividers.grey,
        }}
      />
      <Modal
        open={isShowingReassignModal}
        onClose={() => {
          showReassignModal(false);
        }}
        maxWidth="sm"
        title="Reassign Lead to Another Office"
      >
        <Fade in={isShowingReassignModal}>
          <form
            onSubmit={async e => {
              e.preventDefault();
              e.stopPropagation();
              try {
                setIsSubmitting(true);
                await reassignLead(lead?.leadId!, {
                  officeId: user?.officeId!,
                  note: reason,
                });
                enqueueSnackbar(`Successfully reassigned lead!`, {
                  variant: 'success',
                });
                history.push('/leads');
              } catch (e: any) {
                enqueueSnackbar(e?.Detail ?? `Error, failed to reassign lead, please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          >
            {isSubmitting && <Loader type="overlay" position="centered" />}
            <Box mt={1}>
              <Typography gutterBottom>
                Submitting this form will submit a request to Pinch A Penny corporate to re-assign
                this lead to another office.
              </Typography>

              <Box mt={1.5}>
                <TextField
                  rows={3}
                  fullWidth
                  multiline
                  label="Reason/Comments"
                  required
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </Box>
              <Box
                margin="1rem 0"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap={1}
              >
                <Button
                  type="button"
                  color="inherit"
                  onClick={() => {
                    showReassignModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button disabled={!reason} type="submit" color="primary">
                  Reassign
                </Button>
              </Box>
            </Box>
          </form>
        </Fade>
      </Modal>
    </>
  );
};
