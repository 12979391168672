import { axios } from '../helpers';
import qs from 'querystring';
import { ISuperAdminUser, IResponse, ISuperAdminUserData } from '../models';

export const getSuperAdmins = async (filters?: {
  search?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
}): Promise<IResponse<ISuperAdminUser[]>> => {
  try {
    const { data } = await axios.get(`/api/users/super-admin`, {
      params: {
        ...filters,
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getUserUpgradeData = async (userId: string): Promise<ISuperAdminUserData> => {
  try {
    const { data } = await axios.get(`/api/users/${userId}/user-upgrade-data`);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const upgradeUserToSuperAdmin = async (
  currentUserId: string,
  payload: {
    newUserId: string | null;
  }
): Promise<string> => {
  try {
    const { data } = await axios.post(
      `/api/users/${currentUserId}/upgrade-to-super-admin`,
      payload
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createSuperAdmin = async (payload: {
  azureObjectId: string;
  userName: string;
  loginName: string;
  areaCode?: string;
  phoneNumber?: string;
}): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/users/super-admin`, payload);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const downgradeSuperAdminUser = async (
  currentUserId: string,
  payload: {
    officeId: string;
    userGroupId: string;
  }
): Promise<string> => {
  try {
    const { data } = await axios.put(
      `/api/users/${currentUserId}/downgrade-from-super-admin`,
      payload
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
