import { FC } from 'react';
import clsx from 'clsx';
import { Button, styled } from '@mui/material';
import { SaveButton } from '../../button';
import { CancelIcon } from '../../icon';

interface SaveAndCancelStripProps {
  allowSave: boolean;
  allowCancel: boolean;
  onSave: () => unknown;
  onCancel: () => unknown;
  changeCount: number;
}

export const SaveAndCancelStrip: FC<SaveAndCancelStripProps> = ({
  allowCancel = true,
  allowSave = true,
  onSave,
  onCancel,
  changeCount,
}) => {

  let saveLabel = 'Save';

  if (changeCount) {
    if (changeCount === 1) {
      saveLabel = 'Save (1 CHANGE)';
    } else {
      saveLabel = `Save (${changeCount} CHANGES)`;
    }
  }
  return (
    <SaveAndCancelWrapper className={clsx(classes.saveAndCancelStripContainer, 'print--none')}>
      <Button color="inherit" onClick={onCancel} disabled={!allowCancel} startIcon={<CancelIcon />}>
        Cancel
      </Button>
      <SaveButton handleSave={onSave} disabled={!allowSave} text={saveLabel} />
    </SaveAndCancelWrapper>
  );
};

const PREFIX = 'SaveAndCancelStrip';

const classes = {
  saveAndCancelStripContainer: `${PREFIX}-saveAndCancelStripContainer`
};

const SaveAndCancelWrapper = styled('div')(({ theme }) => ({
  [`&.${classes.saveAndCancelStripContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    padding: 10,
    backgroundColor: theme.palette.grey[500],
    position: 'sticky',
    bottom: 0,
    zIndex: 10,
    '@media print': {
      display: 'none',
    },
  }
}));