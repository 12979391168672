import { useState, useContext, FC, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Loader, Card, GridDataFetcher, useDataGrid } from '../../components';
import { IBillingLog } from '../../models';
import { getBillingLogs, generateReport, deleteTransactionBatch } from '../../fetch';
import { downloadFile } from '../../helpers';
import { UserContext } from '../../context/user';
import { useConfirm } from '../../hooks';
import { BillingLogDataGrid } from './billing-log-data-grid';

interface IBillingLogProps {
  refresh: boolean;
  setRefresh: (val: boolean) => void;
}

const REPORT_ID = '61662720-8037-4F80-920E-289203F2DCA0';
const REPORT_PARAMETER_ID = 'BD83E4B8-1BFA-4936-8A7C-30F7A4005D8F';

export const BillingLog: FC<IBillingLogProps> = ({ refresh, setRefresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [isDownloading, setDownloading] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<IBillingLog> = useCallback(
    async ({
      perPage,
      sortColumn,
      sortDirection,
      beforeItemId,
      afterItemId,
      isFirstPage,
      isLastPage,
    }) => {
      const params = {
        sortDirection: sortDirection || 'Desc',
        sortBy: sortColumn === 'whenCreated' ? 'whenPosted' : sortColumn,
        perPage,
        officeId: user?.officeId as string,
      };
      if (beforeItemId) {
        //@ts-ignore
        params.before = beforeItemId;
      }
      if (afterItemId) {
        // @ts-ignore
        params.after = afterItemId;
      }
      try {
        const res = await getBillingLogs({
          ...params,
          first: isFirstPage ?? false,
          last: isLastPage ?? false,
        });
        return {
          rows: res.data,
          rowCount: res.totalCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading billing logs, please try again.`, {
          variant: 'error',
        });
        throw error;
      } finally {
        setRefresh(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageSizeChange,
    onSortModelChange,
    refetch: fetchBillingLogs,
    onKeysetPageChange,
  } = useDataGrid<IBillingLog>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      sortColumn: 'whenCreated',
      sortDirection: 'desc',
      gridKeyName: 'billing-log-grid',
    },
    keysetPagingKey: 'transactionBatchId',
    dataFetcher,
  });

  const handleDelete = async (transactionBatchId: string) => {
    const result = await confirm(
      'Are you sure you want to delete this billing cycle? All invoices and transactions associated with this cycle will be deleted!'
    );
    if (result) {
      try {
        setDeleting(true);
        await deleteTransactionBatch(transactionBatchId);
        enqueueSnackbar('Billing log deleted!', {
          variant: 'success',
        });
        fetchBillingLogs();
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? 'An error occurred while deleting your billing log', {
          variant: 'error',
        });
      } finally {
        setDeleting(false);
      }
    }
  };

  const handleDownload = async (transactionBatchId: string, reference: string) => {
    try {
      setDownloading(true);
      const res = await generateReport(REPORT_ID, {
        parameters: [
          {
            reportParameterId: REPORT_PARAMETER_ID,
            value: transactionBatchId,
          },
        ],
      });

      if (res && res.type.includes('application/pdf')) {
        downloadFile(res, `${reference} log.pdf`);
        fetchBillingLogs();
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'An error occurred while printing your report', {
        variant: 'error',
      });
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Card
      cardTitleProps={{
        title: 'Billing Log',
      }}
    >
      {(isDownloading || isDeleting) && <Loader position="centered" type="overlay" />}
      <BillingLogDataGrid
        rows={rows}
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        loading={isLoading}
        onPageChange={onKeysetPageChange}
        onPageSizeChange={onPageSizeChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        refetch={fetchBillingLogs}
        handleDownload={handleDownload}
        handleDelete={handleDelete}
      />
    </Card>
  );
};
