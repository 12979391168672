import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useContext, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { UserContext } from '../../../context';
import { ConfirmPrompt, Loader, SaveButton, Select, TextField } from '../../../components';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { deepEqual } from 'fast-equals';
import { defaultUnsavedChangesMessage } from '../../../constants';
import { useQuery } from 'react-query';
import {
  EAccountProvider,
  IAccountingProviderSettings,
  IAccountingProviderSettingsAccount,
  IResponse,
  EAccountProviderEntryType,
} from '../../../models';
import {
  getAccountingProviderSettings,
  getAccountingProviderSettingsAccounts,
  storeAccountingProviderSettings,
} from '../../../fetch';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IQuickBooksOptionsFormProps {}

const Schema = Yup.object().shape({
  entryType: Yup.string().required('Required'),
  accountsReceivableAccountId: Yup.string().required('Required'),
  undepositedFundsAccountId: Yup.string().required('Required'),
  unearnedIncomeAccountId: Yup.string().required('Required'),
  journalEntryPrefix: Yup.string()
    .max(10, 'Max character limit of 10')
    .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed')
    .required('Required'),
});

export const QuickBooksOptionsForm: FC<IQuickBooksOptionsFormProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, accountingProvider, reloadAccountingProvider } = useContext(UserContext);
  const featureFlags = useFlags();
  const countSettingsRef = useRef(0);
  const countProviderRef = useRef(0);

  const {
    isLoading: isLoadingAccountingProviderSettings,
    data: currentAccountingProviderSettings,
    refetch: refetchAccountingProviderSettings,
  } = useQuery<IAccountingProviderSettings, Error>(
    ['getAccountingProviderSettings', user],
    () => {
      countSettingsRef.current += 1;
      return getAccountingProviderSettings(user?.officeId!);
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: accountingProvider?.provider === EAccountProvider.QuickBooks ? true : false,
      // on initial connection if we have a valid connection but no ids, refetch every second until we have ids
      refetchInterval: data => {
        if (data?.accountsReceivableAccountId === null && countSettingsRef.current < 3) {
          return 1000;
        }
        return false;
      },
    }
  );

  const { isLoading: isLoadingAccountingProviderAccounts, data: accountingProviderAccounts } =
    useQuery<IAccountingProviderSettingsAccount[], Error>(
      ['getAccountingProviderSettingsAccounts', user],
      async () => {
        countProviderRef.current += 1;
        const res: IResponse<IAccountingProviderSettingsAccount[]> =
          await getAccountingProviderSettingsAccounts(user?.officeId!, { perPage: -1 });
        return res?.records ?? [];
      },
      {
        notifyOnChangeProps: 'tracked',
        enabled: accountingProvider?.provider === EAccountProvider.QuickBooks ? true : false,
        // on initial connection if we have a valid connection but no accounts, refetch every second until we have accounts
        refetchInterval: data => {
          if (data?.length === 0 && countProviderRef.current < 3) {
            return 1000;
          }
          return false;
        },
      }
    );

  const isLoading = useMemo(
    () => isLoadingAccountingProviderAccounts || isLoadingAccountingProviderSettings,
    [isLoadingAccountingProviderAccounts, isLoadingAccountingProviderSettings]
  );
  const accountsReceivableData: IAccountingProviderSettingsAccount[] = useMemo(
    () =>
      accountingProviderAccounts?.filter(account => account.accountType === 'AccountsReceivable') ??
      [],
    [accountingProviderAccounts]
  );
  const undepositedFundsData: IAccountingProviderSettingsAccount[] = useMemo(
    () =>
      accountingProviderAccounts?.filter(account => account.accountType === 'UndepositedFunds') ??
      [],
    [accountingProviderAccounts]
  );
  const unearnedIncomeAccountData: IAccountingProviderSettingsAccount[] = useMemo(
    () => accountingProviderAccounts?.filter(account => account.accountType === 'Liability') ?? [],
    [accountingProviderAccounts]
  );
  const setDefaultEntryType = () => {
    if (!featureFlags.quickbooksInvoicing) {
      // If flag is off and invoicing is selected, clear selection to enforce validation. Otherwise default to JournalEntry
      return currentAccountingProviderSettings?.entryType === EAccountProviderEntryType.Invoice
        ? ''
        : EAccountProviderEntryType.JournalEntry;
    }
    if (currentAccountingProviderSettings) {
      return currentAccountingProviderSettings?.entryType;
    }
    return accountingProvider?.provider === EAccountProvider.QuickBooks
      ? EAccountProviderEntryType.Invoice
      : EAccountProviderEntryType.JournalEntry;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          entryType: setDefaultEntryType(),
          accountsReceivableAccountId:
            currentAccountingProviderSettings?.accountsReceivableAccountId ??
            accountsReceivableData[0]?.accountId ??
            '',
          undepositedFundsAccountId:
            currentAccountingProviderSettings?.undepositedFundsAccountId ??
            undepositedFundsData[0]?.accountId ??
            '',
          unearnedIncomeAccountId:
            currentAccountingProviderSettings?.unearnedIncomeAccountId ??
            unearnedIncomeAccountData[0]?.accountId ??
            '',
          journalEntryPrefix: currentAccountingProviderSettings?.journalEntryPrefix ?? 'PC',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          try {
            await storeAccountingProviderSettings(user?.officeId!, values);
            enqueueSnackbar('Successfully updated QuickBooks options!', {
              variant: 'success',
            });
            actions.resetForm({
              values,
            });
            reloadAccountingProvider();
            await refetchAccountingProviderSettings();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving QuickBooks options, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          isSubmitting,
          values,
          handleSubmit,
          isValid,
          initialValues,
          setFieldValue,
          errors,
        }) => {
          const isSaveDisabled = !isValid || isSubmitting || isLoading;
          return (
            <>
              <ConfirmPrompt
                when={!deepEqual(initialValues, values)}
                message={defaultUnsavedChangesMessage}
              />
              {(isSubmitting || isLoading) && <Loader type="overlay" position="centered" />}
              <Form onSubmit={handleSubmit} autoComplete="none">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      size="small"
                      fullWidth
                      required
                      error={!!errors?.entryType}
                    >
                      <FormLabel component="legend">Entry Type</FormLabel>
                      <RadioGroup
                        aria-label="Entry Type"
                        name="entryType"
                        value={values.entryType}
                        onChange={(_, val) => setFieldValue('entryType', val as any)}
                      >
                        <Stack gap={1} direction={{ xs: 'column', sm: 'row' }}>
                          <FormControlLabel
                            value="JournalEntry"
                            control={<Radio />}
                            label="Journaling"
                          />
                          {featureFlags.quickbooksInvoicing && (
                            <FormControlLabel
                              value="Invoice"
                              control={<Radio />}
                              label="Invoicing"
                            />
                          )}
                        </Stack>
                      </RadioGroup>
                      {errors?.entryType && (
                        <FormHelperText error style={{ marginLeft: 0 }}>
                          {errors?.entryType}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {values.entryType === EAccountProviderEntryType.JournalEntry && (
                    <Grid item xs={12} sm={6} lg={4} xl={3}>
                      <Select
                        disabled={isLoading}
                        autoComplete="nope"
                        label={isLoading ? 'Loading...' : 'Accounts Receivable Account'}
                        required
                        name="accountsReceivableAccountId"
                      >
                        {accountsReceivableData.map(account => (
                          <MenuItem
                            key={`accountsReceivableAccountId-${account.accountId}`}
                            value={account.accountId}
                          >
                            {account.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}
                  {values.entryType === EAccountProviderEntryType.JournalEntry && (
                    <Grid item xs={12} sm={6} lg={4} xl={3}>
                      <Select
                        disabled={isLoading}
                        autoComplete="nope"
                        label={isLoading ? 'Loading...' : 'Undeposited Funds Account'}
                        required
                        name="undepositedFundsAccountId"
                      >
                        {undepositedFundsData.map(account => (
                          <MenuItem
                            key={`undepositedFundsAccountId-${account.accountId}`}
                            value={account.accountId}
                          >
                            {account.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}
                  {values.entryType === EAccountProviderEntryType.JournalEntry && (
                    <Grid item xs={12} sm={6} lg={4} xl={3}>
                      <Select
                        disabled={isLoading}
                        autoComplete="nope"
                        label={isLoading ? 'Loading...' : 'Deposits/Prepayments'}
                        required
                        name="unearnedIncomeAccountId"
                      >
                        {unearnedIncomeAccountData.map(account => (
                          <MenuItem
                            key={`unearnedIncomeAccountId-${account.accountId}`}
                            value={account.accountId}
                          >
                            {account.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <TextField required name="journalEntryPrefix" label="Journal Entry Prefix" />
                  </Grid>
                </Grid>
                <Box marginTop="1rem" display={'flex'} justifyContent={'flex-end'}>
                  <SaveButton disabled={isSaveDisabled} />
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
