import { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Page } from '../../components';
import { IAccountDetail } from '../../models';
import { getAccount } from '../../fetch';
import { EstimateDetail } from './estimate-detail';
import { getCustomerDetailRoute } from '../../helpers';

interface IAddEditEstimateProps {}

export const AddEditEstimate: FC<IAddEditEstimateProps> = () => {
  const history = useHistory();
  const { accountId, estimateId }: { accountId: string; estimateId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [currentCustomer, setCurrentCustomer] = useState<IAccountDetail | null>(null);
  const isNewEstimate = estimateId === 'new';
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  const searchParams = new URLSearchParams(window.location.search);
  const redirectUrl = searchParams.get('redirect');
  const repairId = searchParams.get('repairId');
  const [estimateNumber, setEstimateNumber] = useState<string | null | undefined>(null);

  const fetchCurrentCustomer = async () => {
    setIsCustomerLoading(true);
    try {
      const res = await getAccount(accountId);
      setCurrentCustomer(res);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'Account not found, redirecting...', {
        variant: 'info',
      });
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } finally {
      setIsCustomerLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      title={
        isCustomerLoading
          ? 'Loading...'
          : isNewEstimate
          ? 'New Estimate'
          : estimateNumber === null
          ? 'Loading...'
          : `Estimate ${estimateNumber}`
      }
      breadcrumb={
        isCustomerLoading
          ? undefined
          : {
              text:
                currentCustomer?.name ||
                `${currentCustomer?.firstName} ${currentCustomer?.lastName}`,
              title: 'Back to customer details',
              link: `${getCustomerDetailRoute(accountId)}`,
            }
      }
    >
      <EstimateDetail
        isCustomerLoading={isCustomerLoading}
        currentCustomer={currentCustomer}
        redirect={redirectUrl ?? `/customers/${accountId}/estimates`}
        accountId={accountId}
        repairId={repairId}
        setEstimateNumber={setEstimateNumber}
      />
    </Page>
  );
};
