import { useState, useContext, useEffect } from 'react';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Button } from '@mui/material';
import {
  Loader,
  SelectAsync,
  TextField,
  SaveButton,
  Card,
  CancelIcon,
  AmountInput,
} from '../../../components';
import { UserContext } from '../../../context';
import {
  IResponse,
  IListUser,
  ICommissionRulePost,
  ICommissionType,
  ICommissionRuleDetail,
  IServiceDefinition,
  IDropdownResponse,
} from '../../../models';
import {
  getUsers,
  getCommissionTypes,
  getCommissionRule,
  deleteCommissionRule,
  putCommissionRule,
  postCommissionRule,
  getCommissionRulePayToTypes,
  getServiceDefinitions,
} from '../../../fetch';
import { useConfirm } from '../../../hooks';
import { convertToNumber, formatMoney } from '../../../helpers';
import { BrandingContext } from '../../../context/branding-context';

const FORM_VALIDATION = Yup.object().shape({
  commissionTypeId: Yup.string().required('Required'),
  serviceDefId: Yup.string().required('Required'),
  payTo: Yup.string().required('Required'),
  userid: Yup.string(),
  reason: Yup.string().max(500, 'Max 500 characters'),
  amount: Yup.string()
    .test('min', 'Must be greater than 0', val => (val && convertToNumber(val) > 0 ? true : false))
    .required('Required'),
});

export const CommissionRulesDetail = () => {
  const { isPoolService } = useContext(BrandingContext);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [commissionRule, setCommissionRule] = useState<ICommissionRuleDetail | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { commissionRuleId }: { commissionRuleId: string } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const { user } = useContext(UserContext);

  const history = useHistory();
  const isNewCommissionRule = commissionRuleId === 'new';

  const handleDelete = async () => {
    const result = await confirm('Are you sure you want to delete this commission rule?');
    if (!result) return;

    try {
      setIsDeleting(true);
      await deleteCommissionRule(commissionRuleId);
      enqueueSnackbar(`Commission Rule Deleted!`, {
        variant: 'success',
      });
      redirect && history.push(redirect);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting commission rule, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const fetchCommission = async (newCommissionId?: string) => {
    try {
      setIsLoading(true);
      const response = await getCommissionRule(newCommissionId ?? commissionRuleId);
      setCommissionRule(response);
    } catch (err: any) {
      enqueueSnackbar(
        err?.response?.data?.Detail || `Error loading commission. Please try again.`,
        {
          variant: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isNewCommissionRule) {
      fetchCommission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Formik
        initialValues={{
          commissionTypeId:
            // default to "Commision" commision type when creating a new one
            commissionRule?.commissionTypeId
              ? commissionRule?.commissionTypeId
              : isPoolService
              ? 'e2bde5a0-f3af-ee11-bea0-000d3a4e725b' // pool service "Commision" commision type id
              : '729d32e8-bfa3-4d71-8355-2c8cf17c5f1e', // pinch service "Commision" commision type id
          serviceDefId: commissionRule?.serviceDefId ?? '',
          payTo: commissionRule?.payTo ?? '',
          userId: commissionRule?.userId === null ? 'Any' : commissionRule?.userId ?? '',
          reason: commissionRule?.reason ?? '',
          amount: commissionRule?.amount ? formatMoney(commissionRule.amount) : '',
        }}
        enableReinitialize={true}
        validationSchema={FORM_VALIDATION}
        onSubmit={async values => {
          try {
            const payload: ICommissionRulePost = {
              amount: convertToNumber(values.amount),
              reason: values.reason ?? '',
              commissionTypeId: values.commissionTypeId ?? '',
              userId: values.userId === 'Any' ? null : values.userId ?? '',
              officeId: user?.officeId ?? '',
              serviceDefId: values.serviceDefId ?? '',
              payTo: values.payTo ?? '',
              serviceCommissionRuleId: commissionRule?.serviceCommissionRuleId ?? null,
            };
            if (!isNewCommissionRule && commissionRule?.serviceCommissionRuleId) {
              await putCommissionRule(commissionRule?.serviceCommissionRuleId!, {
                ...payload,
              });
            } else {
              await postCommissionRule({ ...payload });
            }
            enqueueSnackbar('Commission Rule saved!', { variant: 'success' });
            redirect && history.push(redirect);
          } catch (err: any) {
            enqueueSnackbar(err?.Detail ?? `Error saving commission rule. Please try again.`, {
              variant: 'error',
            });
          }
        }}
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          values,
          resetForm,
          setFieldValue,
          errors,
          handleBlur,
          touched,
        }) => {
          return (
            <Form style={{ height: '100%' }}>
              {(isDeleting || isSubmitting || isLoading) && (
                <Loader position="centered" type="overlay" />
              )}
              <>
                <Box>
                  <Card
                    cardTitleProps={{
                      title: `${isNewCommissionRule ? 'Add' : 'Edit'} Commission Rule Details`,
                    }}
                  >
                    <Prompt
                      when={dirty && !isSubmitting}
                      message="You have unsaved changes, are you sure you want to leave?"
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="commissionTypeId"
                          label="Commission Type"
                          required
                          apiRequest={() =>
                            getCommissionTypes({
                              perPage: -1,
                              officeId: user?.officeId as string,
                            })
                          }
                          transformResponse={(response: IResponse<ICommissionType[]>) => {
                            return response.records.map(record => ({
                              label: record.description,
                              value: record.commissionTypeId,
                            }));
                          }}
                          hasClear={values.commissionTypeId ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="serviceDefId"
                          label="Service Type"
                          required
                          apiRequest={() => getServiceDefinitions()}
                          transformResponse={(response: IResponse<IServiceDefinition[]>) => {
                            return response.records.map((record, index) => ({
                              label: record.description,
                              value: record.serviceDefId,
                            }));
                          }}
                          hasClear={values.serviceDefId ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="payTo"
                          label="Pay To"
                          required
                          apiRequest={() => getCommissionRulePayToTypes()}
                          transformResponse={(response: IDropdownResponse[]) => {
                            return response.map(record => ({
                              label: record.description,
                              value: record.value,
                            }));
                          }}
                          hasClear={values.payTo ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="userId"
                          label="Person"
                          required
                          apiRequest={() => getUsers({ perPage: -1, officeId: user?.officeId })}
                          transformResponse={(response: IResponse<IListUser[]>) => {
                            return [
                              {
                                label: 'Any',
                                value: 'Any',
                              },
                              ...response.records.map(record => ({
                                label: `${record.userName} ${
                                  record.isDisabled ? '(Disabled)' : ''
                                }`,
                                value: record.userId,
                                disabled: record.isDisabled,
                              })),
                            ];
                          }}
                          hasClear={values.userId ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AmountInput
                          name="amount"
                          label="Amount"
                          value={values.amount}
                          onChange={(val: string) => {
                            setFieldValue('amount', val);
                          }}
                          onBlur={handleBlur}
                          error={touched.amount && Boolean(errors.amount)}
                          helperText={touched.amount && errors.amount ? errors.amount : ''}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField name="reason" label="Reason" multiline minRows={4} />
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={{
                    xs: 'space-between',
                  }}
                  className="print--none"
                  mt={2}
                >
                  <Box flex={1}>
                    {commissionRuleId !== 'new' && (
                      <Button
                        color="error"
                        type="button"
                        onClick={handleDelete}
                        title="Delete Commission Rule"
                        startIcon={<FontAwesomeIcon icon={faTrash} size="lg" />}
                      >
                        Delete
                      </Button>
                    )}
                  </Box>
                  <Box display="flex" gap={1}>
                    <Button
                      color="inherit"
                      onClick={() => {
                        if (!dirty && redirect) {
                          history.push(redirect);
                        } else {
                          resetForm();
                          redirect && history.push(redirect);
                        }
                      }}
                      startIcon={<CancelIcon />}
                    >
                      Cancel
                    </Button>
                    <SaveButton disabled={!dirty || !isValid} />
                  </Box>
                </Box>
              </>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
