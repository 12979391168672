import React, { FC, Fragment, useState } from 'react';
import {
  AmountInput,
  CardButton,
  Loader,
  Modal,
  ModalSaveSection,
  MultipleEmailInput,
  ScheduledServiceAutocomplete,
  SelectAsyncInput,
} from '../../../components';
import { Grid, Box, Fade, Stack, TextField } from '@mui/material';
import {
  getAccounts,
  getOffices,
  getTransactions,
  getUsers,
  getEmailTemplates,
  queueWelcomeEmail,
  queueNewUserLoginEmail,
  queuePaymentDeclinedEmail,
  queuePaymentReceiptEmail,
  queueServiceCompletionEmail,
} from '../../../fetch';
import {
  IAccount,
  IEmailTemplate,
  IListUser,
  IOffice,
  IResponse,
  ITransaction,
} from '../../../models';
import { faMailForward, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import { convertToNumber, formatDate } from '../../../helpers';
import { useSnackbar } from 'notistack';
import { usePreviousValue } from '../../../hooks';

export const QueueEmailsDetails: FC = () => {
  const [currentData, setCurrentData] = useState<EmailQueueData | null>(null);
  const oldData: EmailQueueData | null = usePreviousValue(currentData);
  return (
    <>
      <Grid container spacing={2}>
        {data.map(item => {
          return (
            <Fragment key={item.id}>
              <Grid key={item.id} item xs={12} sm={6} md={4}>
                <CardButton
                  title={item.title}
                  alignCenter
                  onClick={() => {
                    setCurrentData(item);
                  }}
                  icon={faVoicemail}
                />
              </Grid>
              <EmailModal
                isOpen={currentData?.title === item.title}
                title={item.title}
                onClose={() => setCurrentData(null)}
                data={currentData}
                children={
                  <>
                    <Stack spacing={2}>
                      {currentData?.fields.map(field => {
                        return (
                          <Fragment key={field.field}>
                            {field.type === 'multiple' ? (
                              <MultipleEmailInput
                                fullWidth
                                size="small"
                                placeholder="test@example.com"
                                label="Email(s)"
                                value={field.value as string[]}
                                onChange={(_e, newEmailsValue) => {
                                  if (currentData) {
                                    setCurrentData({
                                      ...currentData,
                                      fields: currentData.fields.map(f => {
                                        if (f.field === field.field) {
                                          return {
                                            ...f,
                                            value: newEmailsValue,
                                          };
                                        }
                                        return f;
                                      }),
                                    });
                                  }
                                }}
                              />
                            ) : field.type === 'select' && field.label === 'Office' ? (
                              <SelectAsyncInput
                                label="Office"
                                required
                                name="office"
                                value={field.value}
                                handleChange={value => {
                                  if (currentData) {
                                    setCurrentData({
                                      ...currentData,
                                      fields: currentData.fields.map(f => {
                                        if (f.field === field.field) {
                                          return {
                                            ...f,
                                            value,
                                          };
                                        }
                                        return f;
                                      }),
                                    });
                                  }
                                }}
                                apiRequest={() => {
                                  return getOffices({
                                    perPage: -1,
                                    sortBy: 'officeName',
                                  });
                                }}
                                transformResponse={(res: IResponse<IOffice[]>) => {
                                  return res.records.map((office: IOffice) => ({
                                    label: office.officeName,
                                    value: office.officeId,
                                  }));
                                }}
                                hasClear={false}
                              />
                            ) : field.type === 'select' && field.label === 'User' ? (
                              <SelectAsyncInput
                                label="User"
                                required
                                name="userId"
                                value={field.value}
                                handleChange={value => {
                                  if (currentData) {
                                    setCurrentData({
                                      ...currentData,
                                      fields: currentData.fields.map(f => {
                                        if (f.field === field.field) {
                                          return {
                                            ...f,
                                            value,
                                          };
                                        }
                                        return f;
                                      }),
                                    });
                                  }
                                }}
                                apiRequest={() => {
                                  return getUsers({
                                    perPage: -1,
                                    sortBy: 'userName',
                                  });
                                }}
                                transformResponse={(res: IResponse<IListUser[]>) => {
                                  return res.records.map((user: IListUser) => ({
                                    label: user.userName,
                                    value: user.userId,
                                  }));
                                }}
                                hasClear={false}
                              />
                            ) : field.type === 'select' && field.label === 'Customer' ? (
                              <SelectAsyncInput
                                label="Customer"
                                required
                                name="accountId"
                                value={field.value}
                                handleChange={value => {
                                  if (currentData) {
                                    setCurrentData({
                                      ...currentData,
                                      fields: currentData.fields.map(f => {
                                        if (f.field === field.field) {
                                          return {
                                            ...f,
                                            value,
                                          };
                                        }
                                        if (f.field === 'transactionId') {
                                          return {
                                            ...f,
                                            value: '',
                                          };
                                        }
                                        return f;
                                      }),
                                    });
                                  }
                                }}
                                apiRequest={() => {
                                  return getAccounts({
                                    perPage: -1,
                                    sortBy: 'name',
                                  });
                                }}
                                transformResponse={(res: IResponse<IAccount[]>) => {
                                  return res.records.map((user: IAccount) => ({
                                    label: user.accountName,
                                    value: user.accountId,
                                  }));
                                }}
                                hasClear={false}
                              />
                            ) : field.type === 'select' &&
                              field.label === 'Transaction' &&
                              !!currentData?.fields[0].value ? (
                              <SelectAsyncInput
                                label="Transaction"
                                required
                                name="transactionId"
                                value={field.value}
                                handleChange={value => {
                                  if (currentData) {
                                    setCurrentData({
                                      ...currentData,
                                      fields: currentData.fields.map(f => {
                                        if (f.field === field.field) {
                                          return {
                                            ...f,
                                            value,
                                          };
                                        }
                                        return f;
                                      }),
                                    });
                                  }
                                }}
                                apiRequest={() => {
                                  return getTransactions({
                                    perPage: -1,
                                    accountId: currentData?.fields?.[0]?.value ?? undefined,
                                  });
                                }}
                                transformResponse={(res: IResponse<ITransaction[]>) => {
                                  return res.records.map((item: ITransaction) => ({
                                    label: `${formatDate(item.whenPosted)} - ${item.reference}`,
                                    value: item.transactionId,
                                  }));
                                }}
                                hasClear={false}
                                // if the account changes, refetch the data
                                shouldRefetch={
                                  currentData?.fields[0].value !== oldData?.fields?.[0].value
                                }
                              />
                            ) : field.type === 'select' && field.label === 'Service' ? (
                              <ScheduledServiceAutocomplete
                                isRequired={false}
                                handleChange={value => {
                                  if (currentData) {
                                    setCurrentData({
                                      ...currentData,
                                      fields: currentData.fields.map(f => {
                                        if (f.field === field.field) {
                                          return {
                                            ...f,
                                            value,
                                          };
                                        }
                                        return f;
                                      }),
                                    });
                                  }
                                }}
                                value={field.value}
                                shouldFetch
                                showSiteName
                              />
                            ) : field.type === 'select' && field.label === 'Email Template' ? (
                              <SelectAsyncInput
                                label="Email Template"
                                required
                                name="emailTemplateId"
                                value={field.value}
                                handleChange={value => {
                                  if (currentData) {
                                    setCurrentData({
                                      ...currentData,
                                      fields: currentData.fields.map(f => {
                                        if (f.field === field.field) {
                                          return {
                                            ...f,
                                            value,
                                          };
                                        }
                                        return f;
                                      }),
                                    });
                                  }
                                }}
                                apiRequest={() => {
                                  return getEmailTemplates({
                                    perPage: -1,
                                    sortBy: 'subject',
                                  });
                                }}
                                transformResponse={(res: IResponse<IEmailTemplate[]>) => {
                                  return res.records.map((item: IEmailTemplate) => ({
                                    label: item.emailTemplateName,
                                    value: item.emailTemplateId,
                                  }));
                                }}
                                hasClear={false}
                              />
                            ) : field.label === 'Amount Charged' ? (
                              <AmountInput
                                label="Amount Charged"
                                value={field.value}
                                name="amountCharged"
                                required={false}
                                onChange={value => {
                                  if (currentData) {
                                    setCurrentData({
                                      ...currentData,
                                      fields: currentData.fields.map(f => {
                                        if (f.field === field.field) {
                                          return {
                                            ...f,
                                            value,
                                          };
                                        }
                                        return f;
                                      }),
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <>
                                {field.label !== 'Transaction' && (
                                  <TextField
                                    label={field.label}
                                    value={field.value}
                                    size="small"
                                    onChange={e => {
                                      if (currentData) {
                                        setCurrentData({
                                          ...currentData,
                                          fields: currentData.fields.map(f => {
                                            if (f.field === field.field) {
                                              return {
                                                ...f,
                                                value: e.target.value,
                                              };
                                            }
                                            return f;
                                          }),
                                        });
                                      }
                                    }}
                                    fullWidth
                                    type={field.type === 'password' ? 'password' : 'text'}
                                  />
                                )}
                              </>
                            )}
                          </Fragment>
                        );
                      })}
                    </Stack>
                  </>
                }
              />
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};

const EmailModal = ({
  isOpen,
  children,
  title,
  onClose,
  data,
}: {
  isOpen: boolean;
  children: any;
  title: string;
  onClose: () => void;
  data: EmailQueueData | null;
}) => {
  const [isSending, setIsSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Modal open={isOpen} maxWidth="sm" title={`Send ${title} Email`} onClose={onClose}>
      <Fade in={isOpen}>
        <Box mt={2}>
          {isSending && <Loader type="overlay" />}
          {children}
          <ModalSaveSection
            handleCancel={onClose}
            submitIcon={faMailForward}
            submitLabel="Queue Email"
            handleSave={async () => {
              setIsSending(true);
              try {
                if (title === 'Service Completion') {
                  await queueServiceCompletionEmail(
                    {
                      scheduledServiceId: data?.fields?.[0]?.value ?? '',
                      emailTemplateId: data?.fields?.[1]?.value ?? '',
                    },
                    data?.fields?.[2]?.value as string[]
                  );
                } else if (title === 'Payment Receipt') {
                  await queuePaymentReceiptEmail({
                    transactionId: data?.fields?.[1]?.value ?? '',
                  });
                } else if (title === 'Payment Declined') {
                  await queuePaymentDeclinedEmail({
                    accountId: data?.fields?.[0]?.value ?? '',
                    transactionId: data?.fields?.[1]?.value ?? '',
                    cardLastFour: data?.fields?.[2]?.value ?? '',
                    amountCharged: convertToNumber(data?.fields?.[3]?.value),
                    reason: data?.fields?.[4]?.value ?? '',
                  });
                } else if (title === 'New User Login') {
                  await queueNewUserLoginEmail({
                    userId: data?.fields?.[0]?.value ?? '',
                    temporaryPassword: data?.fields?.[1]?.value ?? '',
                  });
                } else {
                  await queueWelcomeEmail({
                    firstName: data?.fields?.[0]?.value ?? '',
                    lastName: data?.fields?.[1]?.value ?? '',
                    email: data?.fields?.[2]?.value ?? '',
                    password: '',
                    officeId: data?.fields?.[3]?.value ?? '',
                  });
                }
                onClose();
                enqueueSnackbar('Email successfully queued for sending.', {
                  variant: 'success',
                });
              } catch (error: any) {
                enqueueSnackbar(error?.Detail ?? `Error queuing the email, please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsSending(false);
              }
            }}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

let id = 0;

interface EmailQueueData {
  id: number;
  title: string;
  fields: {
    label: string;
    field: string;
    value: any;
    type: string;
  }[];
}

const data: EmailQueueData[] = [
  {
    id: id++,
    title: 'Welcome',
    fields: [
      {
        label: 'First Name',
        field: 'firstName',
        value: '',
        type: 'text',
      },
      {
        label: 'Last Name',
        field: 'lastName',
        value: '',
        type: 'text',
      },
      {
        label: 'Email',
        field: 'email',
        value: '',
        type: 'email',
      },
      {
        label: 'Office',
        field: 'officeId',
        value: '',
        type: 'select',
      },
    ],
  },
  {
    id: id++,
    title: 'New User Login',
    fields: [
      {
        label: 'User',
        field: 'userId',
        value: '',
        type: 'select',
      },
      {
        label: 'Temporary Password',
        field: 'temporaryPassword',
        value: '',
        type: 'text',
      },
    ],
  },
  {
    id: id++,
    title: 'Payment Declined',
    fields: [
      {
        label: 'Customer',
        field: 'accountId',
        value: '',
        type: 'select',
      },
      {
        label: 'Transaction',
        field: 'transactionId',
        value: '',
        type: 'select',
      },
      {
        label: 'Last Four of Card',
        field: 'cardLastFour',
        value: '',
        type: 'number',
      },
      {
        label: 'Amount Charged',
        field: 'amountCharged',
        value: '',
        type: 'text',
      },
      {
        label: 'Reason',
        field: 'reason',
        value: '',
        type: 'text',
      },
    ],
  },
  {
    id: id++,
    title: 'Payment Receipt',
    fields: [
      {
        label: 'Customer',
        field: 'accountId',
        value: '',
        type: 'select',
      },
      {
        label: 'Transaction',
        field: 'transactionId',
        value: '',
        type: 'select',
      },
    ],
  },
  {
    id: id++,
    title: 'Service Completion',
    fields: [
      {
        label: 'Service',
        field: 'scheduledServiceId',
        value: '',
        type: 'select',
      },
      {
        label: 'Email Template',
        field: 'emailTemplateId',
        value: '',
        type: 'select',
      },
      {
        label: 'Email Address(es)',
        field: 'toAddress',
        value: [],
        type: 'multiple',
      },
    ],
  },
];
