import { FC } from 'react';
import { Grid, styled } from '@mui/material';
import { ISitePhoto } from '../../models';

interface IIMageGridProps {
  openModal: (isOpen: boolean) => void;
  setSource: (source: string) => void;
  images: ISitePhoto[];
}

export const ImageGrid: FC<IIMageGridProps> = ({ images, setSource, openModal }) => {
  return (
    <StyledGrid>
      {images.map(image => (
        <div key={image.sitePhotoId}>
          <Img
            src={image.urlPath}
            alt={image.title}
            onClick={() => {
              openModal(true);
              setSource(image.urlPath);
            }}
          />
          {image.title}
        </div>
      ))}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
`;

const Img = styled('img')`
  width: 100%;
  height: 70%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`;
