import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { poolServiceFeatureFlags } from './constants';
let buildConfig = require('../src/buildSettings.json');

export const createLDProvider = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: `${buildConfig.REACT_APP_POOL_LD_CLIENT_SIDE_KEY}`,
    flags: poolServiceFeatureFlags,
  });

  return LDProvider;
};
