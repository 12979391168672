import { Close, Visibility, PhotoCameraRounded } from '@mui/icons-material';
import clsx from 'clsx';
import { Box, Button, FormLabel, Grid, Stack, Typography, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ImageModal } from './image-modal';
import { useConfirm } from '../../hooks';

interface IImageUpload {
  handleFileChange: (val: string, file?: File) => void;
  image?: string;
  disabled?: boolean;
  isRequired?: boolean;
  showSource?: boolean;
  labelClassName?: string;
  imageKey?: string;
  showLabel?: boolean;
  canRemoveImage?: boolean;
  canOverride?: boolean;
  helpText?: string;
  useImagePreview?: boolean;
}

export const ImageUpload: FC<IImageUpload> = ({
  imageKey,
  handleFileChange,
  image,
  disabled = false,
  isRequired = false,
  showSource = true,
  showLabel = true,
  labelClassName,
  canRemoveImage = true,
  canOverride,
  helpText,
  useImagePreview,
}) => {

  const confirm = useConfirm();
  const [source, setSource] = useState<string>('');
  const [isShowingImage, showImage] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('image.png');

  useEffect(() => {
    setSource(image as string);
  }, [image]);

  const createBase64Image = async (file: File) => {
    const reader = new FileReader();
    return new Promise(function (resolve, reject) {
      reader.onload = function (event) {
        resolve(event?.target?.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCapture = async (target: any) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const base64 = await createBase64Image(target.files[0]);
        const newUrl = URL.createObjectURL(file);
        handleFileChange(base64 as string, file);
        setSource(newUrl);
        setFileName(file.name);
      }
    }
  };

  return (
    <StyledGrid container className={classes.root}>
      <Grid item xs={12}>
        {showLabel && (
          <FormLabel required={isRequired} className={clsx(classes.heading, labelClassName)}>
            Upload Image
          </FormLabel>
        )}
        {isRequired && !source && (
          <Typography variant="caption" className={classes.imageHelpText}>
            Uploading an image is required.
          </Typography>
        )}

        {source && showSource && (
          <>
            {useImagePreview && (
              <Stack>
                <Box className={classes.imgBox}>
                  <img
                    src={source}
                    alt="Preview"
                    className={classes.img}
                    onClick={() => showImage(true)}
                  />
                </Box>
              </Stack>
            )}
            {!useImagePreview && (
              <>
                <Box display="flex">
                  <Button
                    startIcon={<Visibility />}
                    color="primary"
                    onClick={() => showImage(true)}
                  >
                    {fileName}
                  </Button>
                  {canRemoveImage && (
                    <Button
                      onClick={async () => {
                        const result = await confirm('Are you sure you want to remove this image?');
                        if (result) {
                          setSource('');
                          handleFileChange('');
                        }
                      }}
                      color="error"
                      startIcon={<Close />}
                    >
                      Remove Image
                    </Button>
                  )}
                </Box>
                <Box marginTop={1}>
                  <Typography component="p" variant="caption">
                    Click the file name to view a large view.
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
        <input
          accept="image/*"
          disabled={disabled}
          className={classes.input}
          id={`icon-button-file-${imageKey || ''}`}
          type="file"
          onChange={e => {
            handleCapture(e.target);
            // reset the input value so you can re-upload the same photo if you want to
            e.target.value = '';
          }}
        />

        {(canOverride || !source || !showSource) && (
          <>
            <Box marginLeft={showLabel ? '1rem' : 0}>
              <label htmlFor={`icon-button-file-${imageKey || ''}`}>
                <Button
                  color="primary"
                  disabled={disabled}
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  startIcon={<PhotoCameraRounded />}
                >
                  {source ? 'Change Image' : 'Upload Image'}
                </Button>
              </label>
            </Box>
            {helpText && <Typography variant="caption">{helpText}</Typography>}
          </>
        )}
      </Grid>
      <ImageModal isOpen={isShowingImage} source={source} onClose={() => showImage(false)} />
    </StyledGrid>
  );
};

const PREFIX = 'ImageUpload';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  imgBox: `${PREFIX}-imgBox`,
  img: `${PREFIX}-img`,
  input: `${PREFIX}-input`,
  imageHelpText: `${PREFIX}-imageHelpText`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.heading}`]: {
    marginTop: theme.spacing(1),
    display: 'block',
  },

  [`& .${classes.imgBox}`]: {
    maxWidth: '100%',
  },

  [`& .${classes.img}`]: {
    height: 'inherit',
    maxWidth: 'inherit',
    padding: theme.spacing(1),
  },

  [`& .${classes.input}`]: {
    display: 'none',
  },

  [`& .${classes.imageHelpText}`]: {
    color: theme.palette.error.main,
  }
}));