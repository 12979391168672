import { FC, useState } from 'react';
import { Grid, Box, Fade, Stack, Alert } from '@mui/material';
import { migrateToTechs, getOffices } from '../../../fetch';
import { faToolbox } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { CardButton, Loader, Modal, ModalSaveSection, SelectAsyncInput } from '../../../components';
import { IOffice, IResponse, ISelectOption } from '../../../models';

export const TechDetails: FC = () => {
  const [isMigrateTechsModalOpen, setMigrateTechsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOffices, setSelectedOffices] = useState<ISelectOption[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CardButton
            title="Migrate Technicians"
            alignCenter
            onClick={() => {
              setMigrateTechsModal(true);
            }}
            icon={faToolbox}
          />
        </Grid>
      </Grid>
      <Modal
        open={isMigrateTechsModalOpen}
        maxWidth="sm"
        title="Migrate to Technicians"
        onClose={() => {
          setMigrateTechsModal(false);
        }}
      >
        <Fade in={isMigrateTechsModalOpen}>
          <Box mt={2}>
            <Box mb={2}>
              <Alert severity="info">Reprocessess user migration to technicians for offices.</Alert>
            </Box>
            {isLoading && <Loader type="overlay" />}
            <Stack spacing={2}>
              <SelectAsyncInput
                label="Office"
                required
                name="office"
                value={selectedOffices}
                multiple
                handleChange={value => {
                  setSelectedOffices(value);
                }}
                apiRequest={() => {
                  return getOffices({
                    perPage: -1,
                    sortBy: 'officeName',
                  });
                }}
                transformResponse={(res: IResponse<IOffice[]>) => {
                  return res.records.map((office: IOffice) => ({
                    label: office.officeName,
                    value: office.officeId,
                  }));
                }}
                hasClear={true}
                onClear={() => setSelectedOffices([])}
              />
            </Stack>
            <ModalSaveSection
              handleCancel={() => {
                setMigrateTechsModal(false);
              }}
              handleSave={async () => {
                setIsLoading(true);
                try {
                  await migrateToTechs({
                    officeIds: selectedOffices.map(office => office.value),
                  });
                  setMigrateTechsModal(false);
                  enqueueSnackbar(`Techs migrated!`, {
                    variant: 'success',
                  });
                } catch (error: any) {
                  enqueueSnackbar(error?.Detail ?? `Error migrating to techs, please try again.`, {
                    variant: 'error',
                  });
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
