import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Grid,
  FormHelperText,
  Box,
  styled,
} from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { DateTimePicker, AmountInput } from '../../components';
import { getSingleTranCode, getUser } from '../../fetch';
import { IListUser } from '../../models';
import { useUnload } from '../../hooks';
import { useQuery } from 'react-query';

interface IBillingCommissionInfoDetails {
  handleBlur: any;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  values: any;
  isLoadingUsers: boolean;
  users: IListUser[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  dirty: boolean;
  isRecurring: boolean;
}
export const BillingCommissionInfoDetails: FC<IBillingCommissionInfoDetails> = ({
  errors,
  touched,
  values,
  handleBlur,
  isLoadingUsers,
  users,
  setFieldValue,
  dirty,
  isRecurring,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tranCode, setTranCode] = useState<string>('');

  const { isLoading: isLoadingCreatedBySuperAdmin, data: createdBySuperAdminUser } = useQuery(
    ['fetch-created-by-sa-user', values.createdByUserId],
    async () => getUser(values.createdByUserId),
    {
      enabled: !!values.createdByUserId,
    }
  );

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, dirty);

  const fetchTranCodes = async () => {
    try {
      const res = await getSingleTranCode(values.transactionId);
      setTranCode(res.code);
    } catch (error) {
      enqueueSnackbar(`Error loading transaction code, please try again.`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (values.transactionId) {
      fetchTranCodes();
    }

    if (values.transactionId === null) {
      setTranCode('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.transactionId]);

  return (
    <Wrapper>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <AmountInput
              name="serviceAmount"
              label="Service Amount"
              value={values.serviceAmount}
              onChange={val => {
                setFieldValue('serviceAmount', val);
              }}
              error={!!errors.serviceAmount && !!touched.serviceAmount}
              disabled={!values.override}
              required={false}
            />
            {errors.serviceAmount && touched.serviceAmount && (
              <FormHelperText sx={{ color: theme => theme.palette.error.main }}>
                {errors.serviceAmount}
              </FormHelperText>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.override}
                  onChange={e => {
                    setFieldValue('override', !values.override);
                  }}
                />
              }
              classes={{
                root: classes.label,
              }}
              label={isRecurring ? 'Manually Edit the Service Amount' : 'Override'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="Date Created"
              value={values.dateCreated}
              onChange={(date: any) => {
                setFieldValue('dateCreated', date);
              }}
              disabled
              error={!!errors.status}
              textFieldClass={classes.dateCreated}
            />
            {errors.dateCreated && touched.dateCreated && (
              <FormHelperText sx={{ color: theme => theme.palette.error.main }}>
                {errors.dateCreated}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder={'Transaction Code'}
              label={'Transaction Code'}
              name={'transactionCode'}
              InputProps={{
                readOnly: true,
              }}
              disabled
              size="small"
              value={tranCode}
              error={!!errors.transactionCode && !!touched.transactionCode}
              helperText={errors.createdBy && touched.createdBy && errors.createdBy}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Created By"
              select
              size="small"
              onChange={(e: any) => {
                setFieldValue('createdBy', e.target.value);
              }}
              disabled
              onBlur={handleBlur}
              value={values.createdByUserId ?? values.createdBy}
            >
              <MenuItem value={undefined}>Service</MenuItem>
              {values.createdByUserName === 'Pool360 Support' && (
                <MenuItem value={values.createdByUserId}>
                  {isLoadingCreatedBySuperAdmin
                    ? 'Loading...'
                    : `${values.createdByUserName} - ${createdBySuperAdminUser?.userName}`}
                </MenuItem>
              )}
              {!isLoadingUsers &&
                users.map(user => (
                  <MenuItem key={`key-${user.userId}`} value={user.userId}>
                    {user.userName}
                  </MenuItem>
                ))}
            </TextField>
            {errors.createdBy && touched.createdBy && (
              <FormHelperText sx={{ color: theme => theme.palette.error.main }}>
                {errors.createdBy}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

const PREFIX = 'BillingCommissionInfo';

const classes = {
  dateCreated: `${PREFIX}-dateCreated`,
  label: `${PREFIX}-label`,
};

const Wrapper = styled('div')(({ theme }) => ({
  [`& .${classes.dateCreated}`]: {
    '& .MuiInputBase-root': {
      paddingBottom: '2px',
    },
  },

  [`& .${classes.label}`]: {
    '&& span': {
      fontSize: 14,
    },
  },
}));
