import { SvgIcon } from '@mui/material';

export const QuickBooksIcon = ({ ...props }) => {
  return (
    <SvgIcon color="inherit" {...props}>
      <svg
        width="66"
        height="60"
        viewBox="0 0 66 60"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Shape</title>
        <path d="M.5 30c0 9.665 7.835 17.5 17.5 17.5h2.5V41H18c-6.075 0-11-4.925-11-11s4.925-11 11-11h6v34c0 1.724.685 3.377 1.904 4.596 1.22 1.22 2.872 1.904 4.596 1.904v-47H18C8.335 12.5.5 20.335.5 30zM48 12.5h-2.5V19H48c6.075 0 11 4.925 11 11s-4.925 11-11 11h-6V7C42 3.41 39.09.5 35.5.5v47H48c6.252 0 12.03-3.335 15.155-8.75 3.127-5.415 3.127-12.085 0-17.5C60.03 15.835 54.252 12.5 48 12.5z" />
      </svg>
    </SvgIcon>
  );
};
