import { FC } from 'react';
import { Page } from '../../../components';
import { Box } from '@mui/material';
import { AuthDetails } from './auth-details';

export const AuthPage: FC = () => {
  return (
    <Page title="Authentication">
      <Box mt={2}>
        <AuthDetails />
      </Box>
    </Page>
  );
};
