import { axios } from '../helpers';
import qs from 'querystring';
import {
  IPaymentsPost,
  IResponse,
  IRecurringPayment,
  IRecurringPaymentPost,
  IRecurringPaymentPut,
  IErrorResponse,
  IProcessorInfo,
  IRecurringPaymentAdyenPost,
  IRecurringPaymentEmailPost,
  IExternalRecurringPaymentData,
  IExternalRecurringPaymentPost,
} from '../models';
import { userSignal } from '../signals';

export const createPaymentCapture = async (
  post: IPaymentsPost,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/payments/capture`, {
      ...post,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRecurringPayments = async (
  accountId: string,
  filters?: {
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number | string;
  },
  officeId?: string | null
): Promise<IResponse<IRecurringPayment[]>> => {
  try {
    const { data } = await axios.get(`/api/accounts/${accountId}/recurring-payments`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRecurringPaymentProcessorInfo = async (
  recurringPaymentId: string,
  officeId?: string | null
): Promise<IProcessorInfo> => {
  try {
    const { data } = await axios.get(
      `/api/recurring-payments/${recurringPaymentId}/processor-info`,
      {
        params: {
          officeId: officeId ?? userSignal.value?.officeId ?? '',
        },
        paramsSerializer: params => qs.stringify(params),
      }
    );

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postOneTimeRecurringPayment = async (
  recurringPaymentId: string,
  amount: number,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/recurring-payments/${recurringPaymentId}/post-once`, {
      amount,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postRecurringPayment = async (
  accountId: string | number,
  payload: IRecurringPaymentPost | IRecurringPaymentAdyenPost,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.post(`/api/accounts/${accountId}/recurring-payments`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const putRecurringPayments = async (
  paymentId: string | number,
  payload: IRecurringPaymentPut,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.put(`/api/recurring-payments/${paymentId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteRecurringPayment = async (paymentId: string | number): Promise<void> => {
  try {
    const { data } = await axios.delete(`/api/recurring-payments/${paymentId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendRecurringPaymentEmail = async (
  accountId: string,
  payload: IRecurringPaymentEmailPost,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.post(`/api/accounts/${accountId}/recurring-payments/collect`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getExternalRecurringPaymentData = async (
  token: string | null,
  recurringPaymentId: string | undefined,
  officeId?: string | null
): Promise<IExternalRecurringPaymentData> => {
  try {
    const { data } = await axios.get(`/api/recurring-payments/${recurringPaymentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postExternalRecurringPayment = async (
  token: string,
  recurringPaymentId: string,
  payload: IExternalRecurringPaymentPost,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.put(
      `/api/recurring-payments/${recurringPaymentId}/card-details`,
      {
        ...payload,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postRecurringPaymentSignature = async (
  token: string,
  recurringPaymentId: string,
  payload: FormData
) => {
  try {
    const { data } = await axios.put(
      `/api/recurring-payments/${recurringPaymentId}/signature`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
