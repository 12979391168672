import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, IBillingGroup } from '../models';
import { userSignal } from '../signals';

export const getAllBillingGroups = async ({
  ...otherParams
}: {
  officeId?: string | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IResponse<IBillingGroup[]>> => {
  try {
    const { data } = await axios.get(`api/billing-groups`, {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleBillingGroup = async (
  id: string | number,
  officeId?: string | null
): Promise<IBillingGroup> => {
  try {
    const { data } = await axios.get(`/api/billing-groups/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createBillingGroup = async (
  body: IBillingGroup,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/billing-groups`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateBillingGroup = async (
  body: IBillingGroup,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/billing-groups/${body.billingGroupId}`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteBillingGroup = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/billing-groups/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateDefaultBillingGroup = async (
  id: string,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/billing-groups/${id}/import-default`, {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
