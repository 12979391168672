import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Grid, FormControl, Checkbox, FormControlLabel } from '@mui/material';
// fetch
import { updateTerminationType, createTerminationType } from '../../../fetch';

import { ITerminationType } from '../../../models';

interface IAddEditTerminationTypeModal {
  open: boolean;
  onClose: () => void;
  currentTerminationType?: ITerminationType | null;
  fetchTerminationTypes: () => void;
}

const Schema = Yup.object().shape({
  terminationTypeId: Yup.string(),
  tbid: Yup.number(),
  code: Yup.string().required('Required').max(10),
  description: Yup.string().required('Required').max(18),
});

export const AddEditTerminationTypeModal: FC<IAddEditTerminationTypeModal> = ({
  open,
  onClose,
  currentTerminationType,
  fetchTerminationTypes,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          code: currentTerminationType?.code ?? '',
          description: currentTerminationType?.description ?? '',
          tbid: currentTerminationType?.tbid ?? 0,
          terminationTypeId: currentTerminationType?.terminationTypeId ?? '',
          officeId: user?.officeId ?? '',
          infoRequired: currentTerminationType?.infoRequired ?? false,
          isDeleted: currentTerminationType?.isDeleted ?? false,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            ...values,
          };
          try {
            currentTerminationType
              ? await updateTerminationType(data)
              : await createTerminationType(data);
            enqueueSnackbar(
              currentTerminationType
                ? 'Successfully updated Termination Type!'
                : 'Successfully created Termination Type!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchTerminationTypes();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving Termination Type, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid, handleChange, values }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
              title={currentTerminationType ? 'Edit Termination Type' : 'Add Termination Type'}
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="infoRequired"
                                checked={values.infoRequired}
                                onChange={handleChange}
                              />
                            }
                            label="Info Required"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
