import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
  SaveButton,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { IQuickBooksAccount, ITranCode, ITranCodesQBAccount } from '../../../models';
import {
  getAccountingProviderAccounts,
  getAllTranCodes,
  updateTranCodeQBAccounts,
} from '../../../fetch';
import { UserContext } from '../../../context';
import { useQuery } from 'react-query';

interface IQBTranCodesTableProps {}

export const QBTranCodesTable: FC<IQBTranCodesTableProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, office } = useContext(UserContext);

  const [quickBooksAccounts, setQuickBooksAccounts] = useState<IQuickBooksAccount[]>([]);
  const [selectedQBTranCodeAccounts, setSelectedQBTranCodeAccounts] = useState<
    ITranCodesQBAccount[]
  >([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { isLoading: isLoadingQBAccounts } = useQuery<Promise<any>, Error>(
    ['getQuickBooksAccounts'],
    async () => await getAccountingProviderAccounts(office?.officeId!),
    {
      enabled: !!office?.officeId,
      onSuccess: d => {
        setQuickBooksAccounts(d as unknown as IQuickBooksAccount[]);
      },
    }
  );

  const dataFetcher: GridDataFetcher<ITranCode> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: 'isCredit',
          sortDirection: sortDirection || 'asc',
          thenSortBy: sortColumn,
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };

        const res = await getAllTranCodes(options);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading transaction codes, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    [enqueueSnackbar, user?.officeId]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchTranCodes,
  } = useDataGrid<ITranCode>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'tran-codes-grid',
      sortColumn: 'description',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const handleQBAccountChange = (row: ITranCode, accountId: string) => {
    let account = quickBooksAccounts?.find(account => account.accountId === accountId) ?? null;
    if (selectedQBTranCodeAccounts?.find(t => t.tranCodeId === row.tranCodeId)) {
      setSelectedQBTranCodeAccounts?.(
        selectedQBTranCodeAccounts?.map(t => {
          if (t.tranCodeId === row.tranCodeId) {
            return {
              ...t,
              quickBooksAccount: {
                quickBooksAccountTranCodeId: t.quickBooksAccount?.quickBooksAccountTranCodeId ?? '',
                accountId: account?.accountId ?? '',
                accountName: account?.accountName ?? '',
                accountType: account?.accountType ?? '',
              },
            };
          }
          return t;
        })
      );
    } else {
      setSelectedQBTranCodeAccounts?.([
        ...selectedQBTranCodeAccounts!,
        {
          tranCodeId: row.tranCodeId,
          quickBooksAccount: {
            quickBooksAccountTranCodeId: row.quickBooksAccount?.quickBooksAccountTranCodeId ?? '',
            accountId: account?.accountId ?? '',
            accountName: account?.accountName ?? '',
            accountType: account?.accountType ?? '',
          },
        },
      ]);
    }
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await updateTranCodeQBAccounts({ tranCodes: selectedQBTranCodeAccounts });
      enqueueSnackbar('Transaction codes saved successfully.', {
        variant: 'success',
      });
      setSelectedQBTranCodeAccounts([]);
      await refetchTranCodes();
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'Error saving QuickBooks options, please try again.', {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'code',
        headerName: 'Code',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'quickBooksAccount',
        headerName: 'QB Account',
        sortable: false,
        flex: 1.5,
        renderCell: (params: GridRenderCellParams<ITranCode>) => {
          const row = params.row;
          const accountName = row.quickBooksAccount?.accountName;
          const value =
            selectedQBTranCodeAccounts?.find(t => t.tranCodeId === row.tranCodeId)
              ?.quickBooksAccount?.accountId ??
            row.quickBooksAccount?.accountId ??
            '';
          const renderPlaceholderText = () => {
            if (row.isCredit) {
              return 'Undeposited Funds';
            }
            if (isLoadingQBAccounts) {
              return 'Loading...';
            }
            if (!value) {
              return 'Select QB Account';
            }
            return '';
          };
          return (
            <FormControl fullWidth size="small">
              <InputLabel
                id={`accountingProviderAccount-${accountName}`}
                sx={{
                  color: `rgba(0, 0, 0, 0.4)`,
                  backgroundColor: theme => theme.palette.common.white,
                }}
              >
                {renderPlaceholderText()}
              </InputLabel>
              <Select
                disabled={isLoadingQBAccounts || isLoading || row.isCredit}
                autoComplete="nope"
                name={`accountingProviderAccount-${accountName}`}
                fullWidth
                value={value}
                onChange={e => handleQBAccountChange(row, e.target.value)}
                sx={{ '& .MuiSelect-select': { paddingBlock: 1 } }}
              >
                {quickBooksAccounts?.map(account => (
                  <MenuItem
                    key={`accountingProviderAccount-${account.accountId}`}
                    value={account.accountId}
                  >
                    {account.accountTypeDisplayName} - {account.accountName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      },
    ].filter(Boolean) as GridColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchTranCodes, selectedQBTranCodeAccounts]);

  return (
    <>
      <Card>
        <CardTitle title="Transaction Codes" mobileWrap />
        <ServerSideDataGrid
          autoHeight
          getRowHeight={() => 55}
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: ITranCode) => row.tranCodeId}
          rows={rows}
          columns={columns}
          loading={isLoading || isSaving}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
        />
        <Box display={'flex'} justifyContent={'flex-end'}>
          <SaveButton
            disabled={selectedQBTranCodeAccounts.length < 1 || isSaving}
            handleSave={handleSave}
          />
        </Box>
      </Card>
    </>
  );
};
