import { FC, useContext, useState } from 'react';
import { SearchResultsGrid } from './search-results-grid';
import { ISearchWaterTestAccountResult, ISearchResults, ISearchResultsPage } from '../../models';
import { getWaterTestAccountSearchResults, getWaterTestAccountId } from '../../fetch';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from '../../components';
import { useHistory } from 'react-router-dom';
import { SearchContext } from '../../context';
import { formatInputPhoneNumber, getCustomerDetailRoute, stringNumNull } from '../../helpers';
import { Typography } from '@mui/material';
import { CustomerWaterTestCustomerColumn } from './water-test-custom-column';

interface IWaterTestCustomerSearchResults {
  results: ISearchResults;
  page: ISearchResultsPage[];
  perPage: ISearchResultsPage[];
  redirect: string;
}

export const WaterTestCustomerSearchResults: FC<IWaterTestCustomerSearchResults> = ({
  results,
  page,
  perPage,
  redirect,
}) => {
  const history = useHistory();
  const [isCheckingWaterTestAccount, setIsCheckingWaterTestAccount] = useState<boolean>(false);
  const { setWaterTestAccount } = useContext(SearchContext);
  const checkForExistingCustmer = async (id: stringNumNull) => {
    try {
      setIsCheckingWaterTestAccount(true);
      if (!!id) {
        const res = await getWaterTestAccountId(id);
        return !!res ? res : false;
      }
      return false;
    } catch (error) {
      return false;
    } finally {
      setIsCheckingWaterTestAccount(false);
    }
  };
  return (
    <SearchResultsGrid
      labelContext="Pool 360 Water Test Customers"
      searchType="waterTestAccounts"
      rowId="customerId"
      isParentLoading={isCheckingWaterTestAccount}
      totalCount={results?.waterTestAccounts?.totalRecordCount}
      gridResults={results?.waterTestAccounts?.records}
      getApiRequest={getWaterTestAccountSearchResults}
      pageNumber={page?.find(type => type.type === 'waterTestAccounts')?.page}
      pageSize={perPage?.find(type => type.type === 'waterTestAccounts')?.perPage}
      columns={[
        {
          headerName: 'Name',
          field: 'name',
          disableColumnMenu: true,
          sortable: false,
          flex: 1.5,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ISearchWaterTestAccountResult>) => {
            const { row: original } = params;
            const linkTo = `/customers/new${redirect ? `?${redirect}` : ''}`;

            return (
              <Link
                to={linkTo}
                onClick={async event => {
                  event.preventDefault();
                  const id = await checkForExistingCustmer(original.customerId);
                  if (!!id) {
                    history.push(`${getCustomerDetailRoute(id)}${redirect ? `?${redirect}` : ''}`);
                    setWaterTestAccount(null);
                  } else {
                    history.push(linkTo);
                    setWaterTestAccount(original);
                  }
                }}
              >
                {original.name ? original.name : `${original.firstName} ${original.lastName}`}
              </Link>
            );
          },
        },
        {
          field: 'icon',
          headerName: '',
          type: 'number',
          renderCell: (params: GridRenderCellParams<ISearchWaterTestAccountResult>) => {
            if (params.row.customerId) {
              return <CustomerWaterTestCustomerColumn params={params} />;
            }
            return <></>;
          },
          disableColumnMenu: true,
          sortable: false,
          align: 'left',
        },
        {
          headerName: 'Status',
          field: 'status',
          disableColumnMenu: true,
          sortable: false,
          width: 80,
          renderCell: (params: GridRenderCellParams<ISearchWaterTestAccountResult>) => {
            const { row: original } = params;
            return (
              <Typography
                sx={
                  original.status === 'Active'
                    ? { color: '#41D090', fontWeight: 'bold', fontSize: '14px' }
                    : { fontWeight: 'bold', fontSize: '14px' }
                }
              >
                {original.status}
              </Typography>
            );
          },
        },
        {
          headerName: 'Primary Address',
          field: 'addressStreet1',
          disableColumnMenu: true,
          sortable: false,
          flex: 1.5,
          minWidth: 150,
        },
        {
          headerName: 'Primary Phone',
          field: 'phoneNumbers',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ISearchWaterTestAccountResult>) => {
            const { row: original } = params;
            let primaryPhone = original?.phoneNumbers?.find(p => p.phoneNumberType === 'Home');
            const formatNumber = () => {
              const areaCode = primaryPhone?.areaCode ?? '';
              const phoneNumber = primaryPhone?.phoneNumber ?? '';
              const formattedNumber = `${areaCode}${phoneNumber}`;
              return !!primaryPhone ? formatInputPhoneNumber(formattedNumber) : '-';
            };
            return <>{formatNumber()}</>;
          },
        },
        {
          headerName: 'Email',
          field: 'email',
          disableColumnMenu: true,
          sortable: false,
          flex: 1.5,
          minWidth: 150,
        },
      ]}
    />
  );
};
