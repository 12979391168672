import { Typography, Box, styled } from '@mui/material';
import { FC } from 'react';
import WavePattern from '../../styles/images/waves.svg';
import clsx from 'clsx';

interface IPageTitle {
  title?: string;
  marginOff?: boolean;
  centerHeader?: boolean;
  button?: JSX.Element;
  style?: any;
  hideOnPrint?: boolean;
  titleClass?: string;
  titleAdornmentClass?: string;
}

export const PageTitle: FC<IPageTitle> = ({
  centerHeader,
  title,
  marginOff = false,
  button,
  hideOnPrint = false,
  titleClass,
  titleAdornmentClass,
  ...props
}) => {


  return (
    <TitleWrapper
      className={clsx(classes.headerContainer, hideOnPrint ? 'print--none' : null)}
      data-testid="page-title-wrapper"
      {...props}
    >
      <Typography className={clsx(classes.header, titleClass)} variant="h1">
        {title}{' '}
      </Typography>
      <div className={clsx(classes.wavesContainer, titleAdornmentClass)} />
      {button && (
        <Box
          width={{
            xs: '100%',
            sm: 'auto',
          }}
          display="flex"
          justifyContent="flex-end"
          flex={1}
          whiteSpace="nowrap"
          ml={1}
        >
          {button}
        </Box>
      )}
    </TitleWrapper>
  );
};

const PREFIX = 'PageTitle';

const classes = {
  headerContainer: `${PREFIX}-headerContainer`,
  header: `${PREFIX}-header`,
  centerHeader: `${PREFIX}-centerHeader`,
  wavesContainer: `${PREFIX}-wavesContainer`,
  waves: `${PREFIX}-waves`
};

const TitleWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hideOnPrint'
})<IPageTitle>(({ theme, hideOnPrint }) => ({
  [`&.${classes.headerContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
    '@media print': {
      display: hideOnPrint ? 'none' : 'block',
    },
  },

  [`& .${classes.header}`]: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    whiteSpace: 'nowrap',
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      minWidth: '1px',
      whiteSpace: 'normal',
    },
    '@media print': {
      whiteSpace: 'normal',
    },
  },

  [`& .${classes.centerHeader}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.wavesContainer}`]: {
    background: `url(${WavePattern})`,
    backgroundRepeat: 'repeat-x',
    marginLeft: theme.spacing(2),
    width: '60%',
    height: '3rem',
    backgroundPosition: 'center left',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '@media print': {
      display: 'none',
    },
  },

  [`& .${classes.waves}`]: {
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    maxWidth: '100%',
    padding: theme.spacing(2),
    flexGrow: 0,
    flexShrink: 0,
  }
}));