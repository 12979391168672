import { TextField as MuiTextField, Chip, Autocomplete, styled } from '@mui/material';
import { useState, useMemo, FC } from 'react';
import { useQuery } from 'react-query';
import { IAccountRate } from '../../models';
import { getRates } from '../../fetch';

interface IRatesAutoComplete {
  apiFilters: any;
  handleChange: (val: string[]) => void;
  error?: boolean;
  helperText?: string | null;
}

export const RatesAutoComplete: FC<IRatesAutoComplete> = ({
  apiFilters,
  handleChange,
  error,
  helperText,
}) => {
  const { isLoading: isLoadingRates, data: rates } = useQuery(['rates-autocomplete'], async () =>
    getRates(apiFilters)
  );
  const options = useMemo(() => rates?.records || [], [rates]);
  const [selectedOptions, setSelectedOptions] = useState<IAccountRate[]>([]);
  const handleDelete = (option: IAccountRate) => {
    setSelectedOptions(prev => prev.filter(t => t.rateId !== option.rateId));
    handleChange(selectedOptions.filter(t => t.rateId !== option.rateId).map(o => o.rateId));
  };
  return (
    <Autocomplete
      value={selectedOptions}
      multiple
      onChange={(event, newValue: IAccountRate[]) => {
        if (newValue && newValue?.length) {
          setSelectedOptions(newValue);
          handleChange(newValue.map(o => o.rateId));
        } else {
          setSelectedOptions([]);
          handleChange([]);
        }
      }}
      id="rateIds"
      options={options}
      getOptionLabel={(o: IAccountRate) => {
        return `${o.code} - ${o.description}`;
      }}
      renderOption={(props, o: IAccountRate) => {
        return (
          <li {...props} key={o.rateId}>
            {o.code} - {o.description}
          </li>
        );
      }}
      renderTags={tags =>
        tags.map(tag => (
          <StyledChip
            key={tag.rateId}
            label={`${tag.code} - ${tag.description}`}
            variant="outlined"
            onDelete={() => {
              handleDelete(tag);
            }}
          />
        ))
      }
      disabled={isLoadingRates}
      renderInput={params => {
        return (
          <MuiTextField
            {...params}
            size="medium"
            name="rateIds"
            autoComplete="none"
            disabled={isLoadingRates}
            label={isLoadingRates ? 'Loading...' : 'Rates'}
            variant="outlined"
            placeholder="Select Rate(s)"
            error={error}
            helperText={helperText}
          />
        );
      }}
    />
  );
};

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette.primary.main,
  borderRight: 'none',
  textOverflow: 'ellipsis',
  maxWidth: '175px',

  '&& .MuiChip-deleteIcon': {
    fontSize: '30px',
    marginRight: '-14px',
    color: theme.palette.primary.main,
  },
}));
