import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { CardTitle, Loader, SaveButton, Card, PasswordSecurity, ResetIcon } from '../../components';
import { Stack, Box, Grid, Typography, Button, TextField } from '@mui/material';
import { passwordRegex } from '../../helpers';
import { changePassword } from '../../fetch';

const Schema = Yup.object().shape({
  currentPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .required('Required')
    .min(12, 'Password must be at least 12 characters')
    .max(60, 'Password cannot be more than 60 characters')
    .matches(passwordRegex, {
      message: 'Invalid password',
    }),
  confirmPassword: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 12 characters')
    .max(60, 'Password cannot be more than 60 characters')
    .matches(passwordRegex, {
      message: 'Invalid Password',
    })
    .oneOf([Yup.ref('newPassword'), null], `Passwords don't match`),

  passwordHint: Yup.string().required('Required'),
});

export const ManagePasswordForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        passwordHint: '',
      }}
      validationSchema={Schema}
      onSubmit={async (values, actions) => {
        try {
          await changePassword({
            currentPassword: values.currentPassword,
            newPassword: values.confirmPassword,
            passwordHint: values.passwordHint,
          });
          enqueueSnackbar('Password Changed!', {
            variant: 'success',
          });
          actions.resetForm();
        } catch (error: any) {
          enqueueSnackbar(error?.Detail || 'Error changing password, please try again', {
            variant: 'error',
          });
        }
      }}
    >
      {({
        isSubmitting,
        dirty,
        errors,
        isValid,
        touched,
        handleBlur,
        handleSubmit,
        values,
        setFieldValue,
        resetForm,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Card>
              {isSubmitting && <Loader position="centered" type="overlay" />}
              <CardTitle title="Change Password" />
              <Typography sx={{ marginBottom: '1.5rem' }}>
                Please enter and confirm a new password below.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Stack gap={2}>
                    <TextField
                      autoComplete="nope"
                      label="Current Password"
                      name="currentPassword"
                      type="password"
                      size="small"
                      value={values.currentPassword}
                      onChange={e => setFieldValue('currentPassword', e.target.value)}
                      onBlur={handleBlur}
                      error={touched.currentPassword && errors.currentPassword ? true : false}
                      helperText={touched.currentPassword && errors.currentPassword}
                      required
                    />
                    <TextField
                      autoComplete="nope"
                      label="New Password"
                      name="newPassword"
                      type="password"
                      size="small"
                      value={values.newPassword}
                      onChange={e => setFieldValue('newPassword', e.target.value)}
                      onBlur={handleBlur}
                      error={touched.newPassword && errors.newPassword ? true : false}
                      helperText={touched.newPassword && errors.newPassword}
                      required
                    />
                    <TextField
                      autoComplete="nope"
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      size="small"
                      value={values.confirmPassword}
                      onChange={e => setFieldValue('confirmPassword', e.target.value)}
                      onBlur={handleBlur}
                      error={touched.confirmPassword && errors.confirmPassword ? true : false}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      required
                    />
                    <TextField
                      autoComplete="nope"
                      label="Password Hint"
                      name="passwordHint"
                      size="small"
                      value={values.passwordHint}
                      onChange={e => setFieldValue('passwordHint', e.target.value)}
                      onBlur={handleBlur}
                      error={touched.passwordHint && errors.passwordHint ? true : false}
                      helperText={touched.passwordHint && errors.passwordHint}
                      required
                    />
                  </Stack>
                  {/* mercuryWorks */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PasswordSecurity />
                </Grid>
              </Grid>
              <Box margin="1rem 0 0" display="flex" alignItems="center" justifyContent="flex-end">
                <Box marginRight="0.5rem">
                  <Button
                    type="button"
                    color="inherit"
                    onClick={() => resetForm()}
                    disabled={!dirty || isSubmitting}
                    startIcon={<ResetIcon />}
                  >
                    Reset
                  </Button>
                </Box>
                <SaveButton disabled={!dirty || isSubmitting || !isValid} />
              </Box>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};
