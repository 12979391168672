import { axios } from '../helpers';
import qs from 'querystring';
import {
  ITransaction,
  IResponse,
  IInvoice,
  IInvoiceDetail,
  IInvoicePut,
  ILateFeeOption,
  ILateFeePostOptions,
  IPostMonthlyCharges,
  IInvoiceLineItem,
  IInvoiceLineItemPost,
  IInvoiceLineItemDetail,
  IErrorResponse,
  IInitialEmailFormValues,
  IInvoicePost,
  ITransactionBatchListModel,
  IGetFiltersResponse,
  ITransactionBatch,
  ITransactionDetail,
  IUpdateBatchTransaction,
  ICreateBatchTransaction,
  IPostDuplicateMonthlyCharges,
  IInvoiceLaborCharges,
  ICanFinalize,
  IExternalInvoicePaymentData,
  IExternalInvoicePaymentPost,
  ITransactionAccountingStatus,
} from '../models';
import { userSignal } from '../signals';

export const getTransactions = async (filters?: {
  accountId?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  siteId?: string;
  userId?: string;
  tranCode?: string;
  transactionBatchId?: string;
  repairId?: string;
  officeId?: string | null;
}): Promise<IResponse<ITransaction[]>> => {
  try {
    const { data } = await axios.get(`/api/transactions`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTransactionDetail = async (
  id: string | number,
  officeId?: string | null
): Promise<ITransactionDetail> => {
  try {
    const { data } = await axios.get(`/api/transactions/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getInvoices = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  transactionType?: string;
  officeId?: string | null;
  isProcessed?: boolean;
}): Promise<IResponse<IInvoice[]>> => {
  try {
    const { data } = await axios.get(`/api/transactions/invoices`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getInvoice = async (
  transactionId: string | number,
  officeId?: string | null
): Promise<IInvoiceDetail> => {
  try {
    const { data } = await axios.get(`/api/transactions/invoices/${transactionId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createInvoice = async (
  payload: IInvoicePost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post<string>('/api/transactions/invoices', {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const putInvoice = async (
  payload: IInvoicePut,
  transactionId: string | number,
  officeId?: string | null
): Promise<IInvoicePut> => {
  try {
    const { data } = await axios.put(`/api/transactions/invoices/${transactionId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const putInvoiceReference = async (
  payload: {
    description: string;
  },
  transactionId: string | number,
  officeId?: string | null
): Promise<IInvoicePut> => {
  try {
    const { data } = await axios.put(`/api/transactions/${transactionId}/reference`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteInvoice = async (invoiceId: string): Promise<void> => {
  try {
    await axios.delete(`/api/transactions/invoices/${invoiceId}`);
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getLateFeeOptions = async (officeId?: string | null): Promise<ILateFeeOption[]> => {
  try {
    const { data } = await axios.get('/api/transactions/late-fee-options');
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postLateFees = async (
  payload: ILateFeePostOptions,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.post('/api/transactions/late-fees', {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postMonthlyCharges = async (
  payload: IPostMonthlyCharges,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.post('/api/transactions/monthly-charges', {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postDuplicateMonthlyCharges = async (
  payload: IPostDuplicateMonthlyCharges,
  officeId?: string | null
): Promise<boolean> => {
  try {
    const res = await axios.post('/api/transactions/monthly-charges-duplicate', {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return res.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getInvoiceLines = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  transactionId: string;
}): Promise<IResponse<IInvoiceLineItem[]>> => {
  try {
    const { data } = await axios.get(
      `/api/transactions/invoices/${filters?.transactionId}/line-items`,
      {
        params: filters,
        paramsSerializer: params => qs.stringify(params),
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getInvoiceLineItem = async (
  transactionId: string,
  transactionDetailId: string,
  officeId?: string | null
): Promise<IInvoiceLineItemDetail> => {
  try {
    const { data } = await axios.get(
      `/api/transactions/invoices/${transactionId}/line-items/${transactionDetailId}`
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createInvoiceLineItem = async (
  transactionId: string,
  payload: IInvoiceLineItemPost,
  officeId?: string | null
) => {
  try {
    await axios.post(`/api/transactions/invoices/${transactionId}/line-items`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateInvoiceLineItem = async (
  transactionId: string,
  transactionDetailId: string,
  payload: IInvoiceLineItemPost,
  officeId?: string | null
) => {
  try {
    await axios.put(
      `/api/transactions/invoices/${transactionId}/line-items/${transactionDetailId}`,
      {
        ...payload,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteInvoiceLineItem = async (transactionId: string, transactionDetailId: string) => {
  try {
    await axios.delete(
      `/api/transactions/invoices/${transactionId}/line-items/${transactionDetailId}`
    );
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getInvoiceLineItemTotal = async (
  transactionId: string,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.get(`/api/transactions/${transactionId}/total`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const sendInvoiceEmail = async (
  transactionId: string,
  payload: IInitialEmailFormValues,
  officeId?: string | null
): Promise<void> => {
  try {
    await axios.post(`/api/transactions/${transactionId}/email`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postFinalizeInvoiceAutopay = async (
  transactionId: string | number,
  actualLaborCharges?: IInvoiceLaborCharges[]
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.put(`/api/transactions/${transactionId}/finalize-and-autopay`, {
      actualLaborCharges,
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const canFinalizeInvoice = async (
  transactionId: string | number,
  officeId?: string | null
): Promise<ICanFinalize> => {
  try {
    const { data } = await axios.get(`/api/transactions/${transactionId}/can-finalize`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postFinalizeInvoice = async (
  transactionId: string | number,
  actualLaborCharges?: IInvoiceLaborCharges[]
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.post(`/api/transactions/invoices/${transactionId}/finalize`, {
      actualLaborCharges,
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postRecallInvoice = async (
  transactionId: string | number,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/transactions/invoices/${transactionId}/recall`, {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getNextInvoiceNumber = async (officeId?: string | null): Promise<number> => {
  try {
    const { data } = await axios.get<number>('/api/transactions/invoices/next-available', {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateTransactionSuppress = async (
  transactionId: string | number,
  isSuppressed?: boolean,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(
      `/api/transactions/${transactionId}/suppress?isSuppressed=${isSuppressed}`,
      {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getTransactionBatches = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
  whenCreatedStartDate?: string;
  whenCreatedEndDate?: string;
  status?: string;
  reference?: string;
  userId?: string;
}): Promise<IResponse<ITransactionBatchListModel[]>> => {
  try {
    const { data } = await axios.get(`/api/transactions/batches`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTransactionBatchFilters = async (officeId?: string | null) => {
  try {
    const { data } = await axios.get<IGetFiltersResponse>('/api/transactions/batches/filters', {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    const { filters } = data;
    return filters;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTransactionBatch = async (
  batchId: string,
  officeId?: string | null
): Promise<ITransactionBatch> => {
  try {
    const { data } = await axios.get(`/api/transactions/batches/${batchId}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteTransactionBatch = async (id: string) => {
  try {
    const { data } = await axios.delete(`/api/transactions/batches/${id}`);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getTotalTransactionAmountByAccount = async (
  accountId: string,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.get(`/api/accounts/${accountId}/balance`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBatchTransactionTotal = async (
  batchId: string,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.get(`/api/transactions/batches/${batchId}/total`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createTransactionBatch = async (post: {
  officeId?: string | null;
}): Promise<number> => {
  try {
    const { data } = await axios.post(`/api/transactions/batches`, {
      ...post,
      officeId: post.officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const closeTransactionBatch = async (
  transactionBatchId: string,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.post(`/api/transactions/batches/${transactionBatchId}/close`, {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createBatchTransaction = async ({
  transactionBatchId,
  accountId,
  checkNumber,
  amount,
  officeId,
}: ICreateBatchTransaction): Promise<number> => {
  try {
    const { data } = await axios.post(`/api/transactions/batches/${transactionBatchId}`, {
      accountId,
      checkNumber,
      amount,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateBatchTransaction = async ({
  transactionId,
  checkNumber,
  amount,
  officeId,
}: IUpdateBatchTransaction): Promise<number> => {
  try {
    const { data } = await axios.put(`/api/transactions/batches/transactions/${transactionId}`, {
      checkNumber,
      amount,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const refundTransaction = async (
  transactionId: string,
  post: {
    transactionRefundType: 'Full' | 'Partial';
    officeId: string;
    partialAmountToRefund?: number;
  },
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.post(`/api/transactions/${transactionId}/refund`, {
      ...post,
      officeId: post.officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const voidTransaction = async (
  transactionId: string,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.put(`/api/transactions/${transactionId}/void`, {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const refundSecurityDeposit = async (
  transactionId: string,
  post: {
    transactionRefundType: 'Full' | 'Partial';
    officeId: string;
    partialAmountToRefund?: number;
  }
): Promise<number> => {
  try {
    const { data } = await axios.post(
      `/api/transactions/line-items/${transactionId}/security-deposit/refund?officeId=${post.officeId}`,
      {
        ...post,
        officeId: post.officeId ?? userSignal.value?.officeId ?? '',
      }
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
export const voidSecurityDeposit = async (
  transactionDetailId: string,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.put(
      `/api/transactions/line-items/${transactionDetailId}/security-deposit/void?officeId=${officeId}`
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getExternalInvoicePaymentData = async (
  token: string | null,
  transactionId: string | undefined,
  officeId?: string | null
): Promise<IExternalInvoicePaymentData> => {
  try {
    const { data } = await axios.get(`/api/transactions/invoice/${transactionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const postExternalInvoicePayment = async (
  token: string,
  transactionId: string,
  payload: IExternalInvoicePaymentPost,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.post(
      `/api/transactions/invoice/${transactionId}/pay`,
      {
        ...payload,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

// this call is only used in pool service at the moment
export const getTransactionAccountingStatus = async (
  transactionId: string,
  officeId?: string | null
): Promise<ITransactionAccountingStatus> => {
  try {
    const { data } = await axios.get(`/api/transactions/${transactionId}/accounting-status`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
