import { FC, Fragment, useState, useEffect } from 'react';
import { Box, MenuItem, Stack, IconButton, Alert, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FieldArray, useFormikContext, ArrayHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { TextField, Select } from '../../../components/formikMui';
import { Card } from '../../../components';
import { GroupingCheckbox, GroupingButton } from './';
import { ISearchCondition, ICustomViewFormValues } from '../../../models/custom-view';
import { INITIAL_SEARCH_CONDITIONS, AND_OR } from '../constants';
import { ILookupModel } from '../../../models/util';
import { getComparators } from '../../../fetch/custom-views';

interface IGroupingIndicatorLookup {
  [key: number]: string;
}

interface ISearchConditionsProps {
  columnEnums: ILookupModel[];
}

export const getGroupIndicator = (groupId: number) => {
  const groupIndicatorLookup: IGroupingIndicatorLookup = {};
  let currentGroupIndicator: string = '';

  if (!groupId) return '';

  const nextString = (str: string) => {
    if (!str) return 'A';
    let tail = '';
    let i = str.length - 1;
    let char = str[i];

    while (char === 'Z' && i > 0) {
      i--;
      char = str[i];
      tail = 'A' + tail;
    }

    if (char === 'Z') return 'AA' + tail;

    return str.slice(0, i) + String.fromCharCode(char.charCodeAt(0) + 1) + tail;
  };

  if (!groupIndicatorLookup[groupId]) {
    currentGroupIndicator = nextString(currentGroupIndicator);
    groupIndicatorLookup[groupId] = currentGroupIndicator;
  }

  return groupIndicatorLookup[groupId];
};

export const SearchConditions: FC<ISearchConditionsProps> = ({ columnEnums }) => {
  const { values, setFieldValue } = useFormikContext<ICustomViewFormValues>();
  const [comparators, setComparators] = useState<ILookupModel[]>([]);
  const [rowsAllowedToGroup, setRowsAllowedToGroup] = useState<number[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        const response = await getComparators();
        response.data.sort((a, b) => (a.description > b.description ? 1 : -1));
        setComparators(response.data);
      } catch (error) {
        enqueueSnackbar(`Error loading comparators. Please try again.`, { variant: 'error' });
      }
    })();
  }, [enqueueSnackbar]);

  useEffect(() => {
    let allowedIndexes: number[] = [];
    values.searchConditions.forEach((condition, index) => {
      // Allow selected row to be unselected
      if (condition.isPending) {
        allowedIndexes.push(index);
      }
      // Allow row that occurs after selected row to be selected
      if (condition.isPending && values.searchConditions[index + 1]) {
        allowedIndexes.push(index + 1);
      }
      // Allow row that occurs before selected row to be selected
      if (condition.isPending && values.searchConditions[index - 1]) {
        allowedIndexes.push(index - 1);
      }
    });

    // Do not allow a row to be deselected if it is surrounded by selected rows
    values.searchConditions.forEach((condition, index) => {
      if (
        condition.isPending &&
        values.searchConditions[index - 1]?.isPending &&
        values.searchConditions[index + 1]?.isPending
      ) {
        allowedIndexes = allowedIndexes.filter(allowed => allowed !== index);
      }
    });
    setRowsAllowedToGroup(allowedIndexes);
  }, [values.searchConditions]);

  const handleDelete = (index: number, groupId: number, arrayHelpers: ArrayHelpers) => {
    // If row is inside a group
    if (groupId > 0) {
      const siblings = values.searchConditions.filter(condition => condition.groupId === groupId);

      // Do now allow a group with orphans
      if (siblings.length <= 2) {
        values.searchConditions.forEach((condition, index) => {
          if (condition.groupId === groupId) {
            setFieldValue(`searchConditions.${index}.isGrouped`, false);
            setFieldValue(`searchConditions.${index}.groupId`, 0);
          }
        });
      }
    }

    arrayHelpers.remove(index);
  };

  return (
    <Card
      cardTitleProps={{
        title: 'Search Conditions',
      }}
    >
      {values.searchConditions.length > 0 && (
        <Box mb={3}>
          <Alert variant="outlined" severity="info">
            All fields are required. When multiple conditions are added, you can group them by
            selecting sequential checkboxes and clicking the Group Conditionals button.
          </Alert>
        </Box>
      )}
      <FieldArray
        name="searchConditions"
        render={arrayHelpers => (
          <>
            {values.searchConditions.map((searchCondition: ISearchCondition, index, conditions) => {
              return (
                <Fragment key={`${index}`}>
                  {conditions.length > 1 && index === 0 && <GroupingButton />}
                  <Stack
                    key={index}
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={{ xs: 2, md: 1 }}
                    pt={{ xs: 1, md: 2 }}
                    pb={{ xs: 1, md: 2 }}
                    mt={conditions.length > 1 && index === 0 ? 2 : 0}
                    sx={{
                      borderBottom: theme => `1px solid ${theme.palette.dividers.grey}`,
                      borderTop: theme =>
                        conditions.length > 1 && index === 0
                          ? `1px solid ${theme.palette.dividers.grey}`
                          : 'none',
                    }}
                  >
                    {conditions.length > 1 && (
                      <GroupingCheckbox
                        index={index}
                        groupingIndicator={getGroupIndicator(searchCondition.groupId)}
                        disabled={
                          !rowsAllowedToGroup.includes(index) && rowsAllowedToGroup.length > 0
                        }
                      />
                    )}
                    {index === 0 ? (
                      <TextField
                        disabled
                        name={`searchConditions.${index}.operator`}
                        label="And/Or"
                        value="None"
                        error={false}
                        helperText=""
                      />
                    ) : (
                      <Select name={`searchConditions.${index}.operator`} required label="And/Or">
                        {AND_OR.map(operator => (
                          <MenuItem key={`operator-${operator.value}`} value={operator.value}>
                            {operator.description}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    <Select name={`searchConditions.${index}.columnName`} required label="Field">
                      {columnEnums.map(columnEnum => (
                        <MenuItem key={`columnName-${columnEnum.value}`} value={columnEnum.value}>
                          {columnEnum.description}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      name={`searchConditions.${index}.comparator`}
                      required
                      label="Comparator"
                    >
                      {comparators.map(comparator => (
                        <MenuItem key={`comparator-${comparator.value}`} value={comparator.value}>
                          {comparator.description}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      name={`searchConditions.${index}.compareValue`}
                      required
                      label="Value"
                    />
                    <Stack sx={{ alignItems: { xs: 'flex-end', md: 'center' } }}>
                      <IconButton
                        color="error"
                        title="Delete Condition"
                        onClick={() => handleDelete(index, searchCondition.groupId, arrayHelpers)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Fragment>
              );
            })}
            <Box mt={2}>
              <Button
                color="primary"
                onClick={() =>
                  arrayHelpers.push({
                    ...INITIAL_SEARCH_CONDITIONS,
                    operator: values.searchConditions.length === 0 ? 'NONE' : 'AND',
                  })
                }
                data-testid="add-condition"
                startIcon={<FontAwesomeIcon icon={faPlusCircle} size="lg" />}
              >
                Add {values.searchConditions?.length > 0 ? 'Another' : ''} Condition
              </Button>
            </Box>
          </>
        )}
      />
    </Card>
  );
};
