import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps,
  Divider,
  IconButton,
  Typography,
  Button,
  Box,
  styled,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { FC, ReactNode } from 'react';

export interface IModal {
  actions?: JSX.Element;
  children: ReactNode;
  customButtonHandler?: Function;
  customButtonIcon?: JSX.Element;
  DialogContentProps?: DialogContentProps;
  DialogTitleProps?: DialogTitleProps;
  /** Defaults to true. */
  fullWidth?: boolean;
  /** Defaults to false */
  isLoading?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  noPaddingContent?: boolean;
  hasNoContentPadding?: boolean;
  subtitle?: string;
  title?: string;
  showCloseButton?: boolean;
  customMouseLeave?: () => void;
  customButtonText?: string;
  fullHeight?: boolean;
}

interface IStyledDialog extends DialogProps {
  noPaddingContent?: boolean;
  hasNoContentPadding?: boolean;
}

/**
 * @usage
 * ```TypeScript
 * const Component: FC = () => {
 *   const [isModalOpen, setIsModalOpen] = useState(false);
 *   return (
 *     <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
 *       ...
 *     </Modal>
 *   );
 * ```
 * }
 */
export const Modal: FC<IModal & DialogProps> = ({
  actions,
  children,
  customButtonHandler,
  customButtonIcon,
  DialogContentProps,
  DialogTitleProps,
  fullWidth = true,
  isLoading = false,
  maxWidth,
  noPaddingContent,
  subtitle,
  title,
  showCloseButton = true,
  customMouseLeave,
  hasNoContentPadding,
  customButtonText,
  fullHeight,
  ...dialogProps
}) => {
  return (
    <StyledDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth ?? 'lg'}
      {...dialogProps}
      PaperProps={{
        style: { height: fullHeight ? '100%' : undefined, ...dialogProps?.PaperProps?.style },
      }}
      data-testid="Modal"
      noPaddingContent={noPaddingContent}
      hasNoContentPadding={hasNoContentPadding}
    >
      {/* CIRCLE LOADER */}
      <Backdrop open={isLoading} data-testid="Modal.Loading">
        <CircularProgress color="primary" />
      </Backdrop>

      {/* MODAL CONTENT */}
      {!isLoading && (
        <div className={classes.dialogContainer} onMouseLeave={customMouseLeave}>
          {title && (
            <MuiDialogTitle
              {...DialogTitleProps}
              sx={{
                paddingBottom: noPaddingContent ? 0 : '0.5rem',
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}
              data-testid="Modal.Title"
            >
              <Box component="p" sx={{ margin: 0 }} flex={1}>
                {title}
              </Box>
              {customButtonHandler && (
                <Button
                  onClick={() => customButtonHandler()}
                  data-testid="Modal.Icon"
                  startIcon={customButtonIcon}
                  variant="text"
                  color="secondary"
                >
                  {customButtonText}
                </Button>
              )}
              {showCloseButton && (
                <IconButton
                  aria-label="Close"
                  className={classes.close}
                  onClick={() => {
                    dialogProps?.onClose?.({}, 'backdropClick');
                  }}
                  size="large"
                  data-testid="Modal.Buttons.Close"
                >
                  <Close />
                </IconButton>
              )}
            </MuiDialogTitle>
          )}
          {title && <Divider data-testid="Modal.Divider" />}

          <MuiDialogContent
            classes={{ root: classes.dialogContent }}
            dividers={false}
            {...DialogContentProps}
          >
            {subtitle && (
              <Typography className={classes.subtitle} data-testid="Modal.Subtitle" variant="h4">
                {subtitle}
              </Typography>
            )}
            {children}
          </MuiDialogContent>

          {actions && <DialogActions data-testid="Modal.Actions">{actions}</DialogActions>}
        </div>
      )}
    </StyledDialog>
  );
};

const PREFIX = 'Modal';

const classes = {
  dialogContainer: `${PREFIX}-dialogContainer`,
  close: `${PREFIX}-close`,
  dialogContent: `${PREFIX}-dialogContent`,
  subtitle: `${PREFIX}-subtitle`,
};

const StyledDialog = styled(Dialog, {
  shouldForwardProp: prop => prop !== 'noPaddingContent' && prop !== 'hasNoContentPadding',
})<IStyledDialog>(({ theme, noPaddingContent, hasNoContentPadding }) => {
  return {
    [`& .${classes.dialogContainer}`]: {
      padding: noPaddingContent ? 0 : 12,
    },
    [`& .${classes.close}`]: {
      position: 'absolute',
      right: theme.spacing(0.25),
      top: theme.spacing(0.25),
      color: theme.palette.primary.main,
      padding: theme.spacing(1),
    },
    [`& .${classes.dialogContent}`]: {
      // minHeight is to give <Loader /> component top and bottom space, if shown with no content to overlay
      minHeight: theme.spacing(4),
      padding: noPaddingContent || hasNoContentPadding ? 0 : theme.spacing(0.5, 1.5, 1.5),
    },
    [`& .${classes.subtitle}`]: {
      color: theme.palette.secondary.main,
      fontSize: 28,
      lineHeight: 1.3,
      margin: theme.spacing(0, 0, 1),
    },
  };
});
