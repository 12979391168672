import { FC } from 'react';
import { RadioGroup, TextField, Card } from '../../../components';
import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { IPoolEquipment } from '../../../models';

interface ISafetyChecklistFormProps {
  handleFormChange: (val?: any, isDirty?: boolean) => void;
  readOnly?: boolean;
}

const RADIO_ROWS = [
  {
    name: 'dualMainDrain',
    label: 'Dual Main Drain',
    options: [
      {
        label: 'Yes',
        value: 'Yes',
      },
      {
        label: 'No',
        value: 'No',
      },
    ],
  },
  {
    name: 'drainCover',
    label: 'Approved Drain Covers in Pool and/or Spa',
    options: [
      {
        label: 'Yes',
        value: 'Yes',
      },
      {
        label: 'No',
        value: 'No',
      },
      {
        label: 'Uncertain',
        value: 'Uncertain',
      },
    ],
  },
  {
    name: 'suctionSafetyLock',
    label: 'If dedicated suction line, is there a safety lock',
    options: [
      {
        label: 'Yes',
        value: 'Yes',
      },
      {
        label: 'No',
        value: 'No',
      },
      {
        label: 'Not Applicable',
        value: 'NotApplicable',
      },
    ],
  },
  {
    name: 'secondaryBarrier',
    label: 'Secondary barrier fence or safety cover',
    options: [
      {
        label: 'Yes',
        value: 'Yes',
      },
      {
        label: 'No',
        value: 'No',
      },
    ],
  },
  {
    name: 'safetyVacuumRelease',
    label: 'Safety Vacuum Release System',
    options: [
      {
        label: 'Yes',
        value: 'Yes',
      },
      {
        label: 'No',
        value: 'No',
      },
    ],
  },
  {
    name: 'boards',
    label: 'Slides and/or Diving Boards?',
    options: [
      {
        label: 'Yes',
        value: 'Yes',
      },
      {
        label: 'No',
        value: 'No',
      },
    ],
  },
];

export const SafetyChecklistForm: FC<ISafetyChecklistFormProps> = ({
  handleFormChange,
  readOnly,
}) => {
  // REMINDER: When adding fields, ensure that you update the SafetyChecklistDisplay component as well
  const { handleBlur, setFieldValue, values } = useFormikContext<IPoolEquipment>();
  return (
    <Box mt={2}>
      <Card>
        {RADIO_ROWS.map(row => (
          <Box my={3} pl={1} key={row.name}>
            <FormControl fullWidth>
              <Stack
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
                gap={{ xs: 0, md: 2 }}
              >
                <Box
                  mr={3}
                  width={{ xs: 'auto', md: '240px' }}
                  textAlign={{ xs: 'left', md: 'right' }}
                >
                  <FormLabel>{row.label}</FormLabel>
                </Box>
                <RadioGroup
                  name={row.name}
                  row
                  onChange={(_, val) => {
                    setFieldValue(row.name, val);
                    handleFormChange({ ...values, [row.name]: val }, true);
                  }}
                >
                  {row.options.map(option => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      disabled={readOnly}
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">{option.label}</Typography>}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            </FormControl>
          </Box>
        ))}
        <Box my={3} pl={1}>
          <FormControl fullWidth>
            <Stack
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
              gap={{ xs: 0, md: 2 }}
            >
              <Box
                mr={3}
                width={{ xs: 'auto', md: '240px' }}
                textAlign={{ xs: 'left', md: 'right' }}
              >
                <FormLabel htmlFor="additionalRecommendations">
                  Additional Recommendations and/or Requirements
                </FormLabel>
              </Box>
              <Box flex={1} width={{ xs: '100%', md: 'auto' }} mt={1}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="additionalRecommendations"
                  onBlur={e => {
                    handleBlur(e);
                    handleFormChange();
                  }}
                  inputProps={{
                    readOnly,
                    maxLength: 200,
                  }}
                />
              </Box>
            </Stack>
          </FormControl>
        </Box>
      </Card>
    </Box>
  );
};
