import { TextField as MuiTextField, Chip, Autocomplete, styled } from '@mui/material';
import { useState, useMemo, FC } from 'react';
import { useQuery } from 'react-query';
import { IOffice, IResponse } from '../../../models';
import { getOffices } from '../../../fetch';

interface IStoresAutocomplete {
  values: any;
  setFieldValue: (key: string, val: any) => void;
}

export const StoresAutocomplete: FC<IStoresAutocomplete> = ({ values, setFieldValue }) => {
  const { isLoading: isLoadingStores, data: storesData } = useQuery<IResponse<IOffice[]>, Error>(
    ['getOffices'],
    () =>
      getOffices({
        perPage: '2147483647', // get all records,
        sortBy: 'OfficeName',
      }),
    {
      onSuccess: (data: IResponse<IOffice[]>) => {
        // Set default value based on current user officeId
        if (values.officeIds?.length > 0) {
          const selectedStores = [
            {
              officeId: '-1',
              storeId: -1,
              code: '-1',
              officeName: 'All',
              inactive: true,
            },
            ...data.records,
          ]
            .map(store => {
              if (values.officeIds.includes(store.officeId)) {
                return store;
              }
              return null;
            })
            .filter(Boolean);
          setSelectedStores(selectedStores as IOffice[]);
        }
      },
    }
  );
  const stores = useMemo(() => storesData?.records || [], [storesData]);
  const [selectedStores, setSelectedStores] = useState<IOffice[]>([]);
  const handleDelete = (office: IOffice) => {
    setSelectedStores(prev => prev.filter(t => t.officeId !== office.officeId));
    setFieldValue(
      'officeIds',
      selectedStores.filter(t => t.officeId !== office.officeId).map(office => office.officeId)
    );
  };

  return (
    <Autocomplete
      value={selectedStores}
      multiple
      onChange={(event, newValue: IOffice[]) => {
        if (newValue && newValue?.length) {
          const hasAllLastSelected = newValue?.[newValue.length - 1].officeId === '-1';
          setSelectedStores(newValue);
          if (hasAllLastSelected) {
            setFieldValue('officeIds', ['-1']);
          } else {
            setFieldValue(
              'officeIds',
              newValue.filter(o => o.officeId !== '-1').map(o => o.officeId)
            );
          }
        } else {
          setSelectedStores([]);
          setFieldValue('officeIds', []);
        }
      }}
      id="officeIds"
      options={[
        {
          officeId: '-1',
          storeId: -1,
          code: '-1',
          officeName: 'All',
          inactive: true,
          adminEmail: '',
          address: null,
          canEditAccountCode: false,
        },
        ...stores,
      ]}
      getOptionLabel={(office: IOffice) => {
        return office.officeName;
      }}
      renderOption={(props, option: IOffice) => {
        return (
          <li {...props} key={option.officeId}>
            {option.officeName}
          </li>
        );
      }}
      renderTags={tags =>
        tags.map(tag => (
          <StyledChip
            key={tag.officeId}
            label={tag.officeName}
            variant="outlined"
            onDelete={() => {
              handleDelete(tag);
            }}
          />
        ))
      }
      disabled={isLoadingStores}
      renderInput={params => {
        return (
          <MuiTextField
            {...params}
            size="medium"
            name="officeIds"
            autoComplete="none"
            disabled={isLoadingStores}
            label="Offices"
            variant="outlined"
            placeholder="Add Office"
          />
        );
      }}
    />
  );
};

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette.primary.main,
  borderRight: 'none',
  textOverflow: 'ellipsis',
  maxWidth: '175px',

  '&& .MuiChip-deleteIcon': {
    fontSize: '30px',
    marginRight: '-14px',
    color: theme.palette.primary.main,
  },
}));
