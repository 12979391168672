import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';
import { SelectChip } from '../../../components';

export const StyleGuideSelectChip = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Select Chip">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Select Chip is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-chip/" target="_blank">
              Mui Chip
            </Link>{' '}
            component, the{' '}
            <Link href="https://github.com/atlassian/react-beautiful-dnd" target="_blank">
              react-beautiful-dnd
            </Link>{' '}
            library, and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has built-in styles, drag-and-drop functionality, and Formik props required for use
            with a Formik form and form validation.
          </Typography>
          <Typography mb={1}>
            This particular component is useful for selecting multiple options from a list and
            implementing a custom order.
          </Typography>
          <code>
            Props:
            <br />- name: string
            <br />- options: ILookupModel[]
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: [],
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ handleBlur }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <SelectChip
                      name="testField"
                      label="Select"
                      options={[
                        { description: 'Option 1', value: 'option1', shorthand: '' },
                        { description: 'Option 2', value: 'option2', shorthand: '' },
                        { description: 'Option 3', value: 'option3', shorthand: '' },
                        { description: 'Option 4', value: 'option4', shorthand: '' },
                        { description: 'Option 5', value: 'option5', shorthand: '' },
                      ]}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;SelectChip name="testField" label="Select" options=&#123;options&#125; /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
