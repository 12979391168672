import React, { useCallback, useContext, useEffect, useState, Fragment } from 'react';
import { ExternalLink, Link } from '../../components';
import { ISetupPageRoute, setupPageRoutes } from './setup-page-routes';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import { convertStringToArray, groupBy, hasNavPermissions } from '../../helpers';
import { Typography, styled } from '@mui/material';
import { UserContext } from '../../context';
import { Paths } from '../../constants';

interface ISetupPageNav {
  onNavLinkClick?: (link: string) => void;
}

const SetupPageNav = ({ onNavLinkClick }: ISetupPageNav) => {
  const featureFlags = useFlags();

  const { pathname } = useLocation();
  const { user, isSuperAdmin, isStoreAdmin } = useContext(UserContext);
  const [selectedLink, setSelectedLink] = useState<string | undefined>(undefined);
  const groups = groupBy(setupPageRoutes, 'parent');

  useEffect(() => {
    setSelectedLink(pathname);
  }, [pathname]);

  const isRouteActive = useCallback(
    (route: ISetupPageRoute) =>
      !route.featureFlag ||
      (route.featureFlag &&
        convertStringToArray(route.featureFlag).every((item: string) => {
          // if the route is TechAvailability, we want to show it if the feature flag,  "Technician Management" is not enabled
          if (route.title === Paths.techAvailability.label) {
            return !featureFlags[item];
          }
          return featureFlags[item];
        })),
    [featureFlags]
  );

  const activeGroupNames = Object.keys(groups).filter((groupName: any) => {
    return groups[groupName].some(isRouteActive);
  });

  const getActiveLinks = useCallback(
    (groupName: string) => groups[groupName].filter(isRouteActive),
    [groups, isRouteActive]
  );

  const filterQuickBooks = (route: ISetupPageRoute) => {
    return isStoreAdmin || isSuperAdmin ? route : route.title !== Paths.quickbooks.label;
  };

  const hasSubPagesWithPermissions = (groupName: string): boolean => {
    const activeLinks = getActiveLinks(groupName)
      .filter((route: ISetupPageRoute) => !!route.parent)
      .filter((route: ISetupPageRoute) =>
        hasNavPermissions(
          {
            ...route,
            permissions: route.permission ? [route.permission] : [],
          },
          user!
        )
      );

    return activeLinks.length > 0;
  };
  return (
    <StyledList>
      {activeGroupNames.map((groupName: string, index: number) => (
        <Fragment key={`${groupName}-${index}`}>
          {hasSubPagesWithPermissions(groupName) && (
            <li className={clsx(classes.linkGroup)}>
              <span>
                <h3 className={clsx(classes.heading)}>{groupName}</h3>
              </span>
              <>
                {getActiveLinks(groupName)
                  .filter((route: ISetupPageRoute) => !!route.parent)
                  .filter((route: ISetupPageRoute) =>
                    hasNavPermissions(
                      {
                        ...route,
                        permissions: route.permission ? [route.permission] : [],
                      },
                      user!
                    )
                  )
                  .filter(filterQuickBooks)
                  .map((route: ISetupPageRoute, index: number) => (
                    <Fragment key={`${route.title}-${index}`}>
                      <Typography
                        onClick={() => onNavLinkClick?.(route.path)}
                        className={`${clsx(classes.item)} ${
                          selectedLink?.includes(route.path)
                            ? clsx(classes.active)
                            : clsx(classes.inactive)
                        }`}
                      >
                        {route.isExternal ? (
                          <ExternalLink className={clsx(classes.link)} to={`${route.path}`}>
                            {route.title}
                          </ExternalLink>
                        ) : (
                          <Link className={clsx(classes.link)} to={`${route.path}`}>
                            {route.title}
                          </Link>
                        )}
                      </Typography>
                    </Fragment>
                  ))}
              </>
            </li>
          )}
        </Fragment>
      ))}
    </StyledList>
  );
};

const PREFIX = 'SetupPageNav';

const classes = {
  link: `${PREFIX}-link`,
  linkGroup: `${PREFIX}-linkGroup`,
  item: `${PREFIX}-item`,
  active: `${PREFIX}-active`,
  heading: `${PREFIX}-heading`,
  inactive: `${PREFIX}-inactive`,
};

const StyledList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  listStyleType: 'none',
  padding: 2,
  '&& a': {
    color: theme.palette.grey['600'],
    fontSize: theme.typography.caption.fontSize,
  },
  [`& .${classes.link}`]: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },

  [`& .${classes.linkGroup}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    margin: `10px 0px`,
  },

  [`& .${classes.item}`]: {
    padding: '4px 0px 4px 6px',
    height: 'auto',
    lineHeight: 1,
  },

  [`& .${classes.active}`]: {
    fontWeight: 'bold',
    transition: 'all .25s ease-in-out',
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.body1.fontSize,
    margin: 0,
  },

  [`& .${classes.inactive}`]: {
    fontWeight: 'initial',
    transition: 'all .25s ease-in-out',
    borderLeft: '2px solid transparent',
  },
}));

export default SetupPageNav;
