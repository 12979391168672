import { WORK_ORDER_REPORT_IDS } from '../models';

export const getWorkOrderReportIdByReportLayout = (reportLayout: string) => {
  if (reportLayout === 'Start up') {
    return WORK_ORDER_REPORT_IDS.StartUp;
  } else if (reportLayout === 'Open/Close') {
    return WORK_ORDER_REPORT_IDS.OpenClose;
  } else if (reportLayout === 'Repair') {
    return WORK_ORDER_REPORT_IDS.Repair;
  } else if (reportLayout === 'Service') {
    return WORK_ORDER_REPORT_IDS.Service;
  } else {
    return WORK_ORDER_REPORT_IDS.Service;
  }
};
