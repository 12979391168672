import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import uniqBy from 'lodash/uniqBy';
import toLower from 'lodash/toLower';
import { FormikErrors } from 'formik';

interface StringInputProps
  extends Omit<
    AutocompleteProps<string, true, true, true>,
    'renderInput' | 'onChange' | 'value' | 'freeSolo' | 'options' | 'renderTags' | 'open'
  > {
  value: string[];
  onChange?: (event: React.ChangeEvent<{}>, newValue: string[]) => void;
  label?: string;
  placeholder?: string;
  variant?: TextFieldProps['variant'];
  required?: boolean;
  helperText?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  hasError?: boolean;
  name?: string;
  validateString?: (val: string) => boolean;
}

export const MultipleStringInput: React.FC<StringInputProps> = ({
  value = [],
  onChange,
  label,
  placeholder,
  variant = 'outlined',
  required,
  helperText,
  hasError,
  name,
  validateString,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);

  const handleInputChange = (e: React.SyntheticEvent<Element>, newInputValue: string) => {
    const event = e as React.KeyboardEvent<HTMLInputElement>;
    if (event.key === 'Enter') {
      return;
    }
    if (!newInputValue.trim()) {
      setError(false);
    }
    setInputValue(newInputValue);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter':
      case ',':
      case ';':
        event.preventDefault();
        if (!inputValue) {
          return;
        }
        if ((validateString && validateString(inputValue)) || validateString === undefined) {
          const newValues = [...value, inputValue.replaceAll(',', '').replaceAll(';', '')];
          const uniqueValues = uniqBy(newValues, toLower);
          onChange?.(event, uniqueValues);
          setInputValue('');
          setError(false);
        } else {
          setError(true);
        }
        break;
      default:
    }
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'createOption') {
      if ((validateString && validateString(inputValue)) || validateString === undefined) {
        setError(false);
        setInputValue('');
      } else {
        setError(true);
        return;
      }
    }
    onChange?.(event, uniqBy(value, toLower));
  };

  const handleBlur = () => {
    if (!inputValue.trim()) {
      return;
    }
    if ((validateString && validateString(inputValue)) || validateString === undefined) {
      setError(false);
      setInputValue('');
      onChange?.({} as React.ChangeEvent, uniqBy([...value, inputValue], toLower));
    } else {
      setError(true);
      return;
    }
  };

  return (
    <Autocomplete<string, true, true, true>
      open={false}
      multiple
      freeSolo
      options={value || []}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      {...props}
      renderInput={(params: TextFieldProps) => (
        <TextField
          {...params}
          name={name}
          error={error || hasError}
          helperText={error ? 'Invalid format' : helperText ?? ''}
          label={label}
          variant={variant}
          placeholder={placeholder}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          data-testid="MultipleStringInput"
          required={required}
          inputProps={{
            ...params.inputProps,
            required: required ? value.length === 0 : false,
          }}
        />
      )}
    />
  );
};
