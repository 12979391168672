import { axios } from '../helpers';
import {
  IHealthChecks,
  IPaginatedResponse,
  IOutboxMessage,
  IDropdownResponse,
  IQueueLoginEmail,
  IQueuePaymentDeclinedEmail,
  IQueuePaymentReceiptEmail,
  IQueueWelcomeEmail,
  IQueueServiceCompletionEmail,
  ISearchIndexStatus,
} from '../models';
import qs from 'querystring';

export const getAllHealthChecks = async (): Promise<IHealthChecks> => {
  try {
    const { data } = await axios.get('/api/health');
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getOutboxMessages = async (filters: {
  first?: boolean;
  last?: boolean;
  before?: string;
  after?: string;
  perPage?: number;
  sortDirection?: string;
  sortBy?: string;
  statuses?: string[];
  processedOnEndDate?: string;
  processedOnStartDate?: string;
  occurredOnEndDate?: string;
  occurredOnStartDate?: string;
}): Promise<IPaginatedResponse<IOutboxMessage[]>> => {
  try {
    const { data } = await axios.get('/api/outbox-messages', {
      params: filters,
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getOutboxMessagesStatuses = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get('/api/outbox-messages/status');
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const requeueOutboxMessage = async (messageId: string): Promise<any> => {
  try {
    const { data } = await axios.put(`/api/outbox-messages/${messageId}/requeue`);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const queueNewUserLoginEmail = async (queryParams: IQueueLoginEmail): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/developers/QueueNewUserLoginEmail`, undefined, {
      params: queryParams,
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const queuePaymentDeclinedEmail = async (
  queryParams: IQueuePaymentDeclinedEmail
): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/developers/QueuePaymentDeclinedEmail`, undefined, {
      params: queryParams,
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const queuePaymentReceiptEmail = async (
  queryParams: IQueuePaymentReceiptEmail
): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/developers/QueuePaymentReceiptEmail`, undefined, {
      params: queryParams,
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const queueWelcomeEmail = async (queryParams: IQueueWelcomeEmail): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/developers/QueueWelcomeEmail`, undefined, {
      params: queryParams,
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const queueServiceCompletionEmail = async (
  queryParams: IQueueServiceCompletionEmail,
  toAddress: string[]
): Promise<any> => {
  try {
    const { data } = await axios.post(
      `/api/developers/QueueServiceCompletionEmail`,
      {
        toAddress,
      },
      {
        params: queryParams,
        paramsSerializer: (params: any) => qs.stringify(params),
      }
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createSearchIndexes = async (params: {
  removeExistingIndexes: boolean;
  removeExistingIndexers: boolean;
  index:
    | 'Accounts'
    | 'Inventory'
    | 'Sites'
    | 'WaterTestAccounts'
    | 'Repairs'
    | 'ScheduledServices'
    | 'Tasks';
}): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/search/indexes`, undefined, {
      params,
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteSearchIndex = async (index: string): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/search/indexes`, {
      params: { index },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteSearchIndexDocuments = async (index: string, keys: string[]): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/search/${index}/documents`, {
      params: { keys },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getSearchIndexStatus = async (index: string): Promise<ISearchIndexStatus[]> => {
  try {
    const { data } = await axios.get(`/api/search/status`, {
      params: { index },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const sendSmsMessage = async (payload: { to: string; message: string }): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/developers/SendSms`, payload);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const queueSmsMessage = async (payload: { to: string; message: string }): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/developers/QueueSms`, payload);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const migrateToTechs = async (payload: { officeIds: string[] }): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/developers/QueueSms`, payload);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
