import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// Components
import { Modal, Loader, ModalSaveSection } from '../../../components';
import {
  Fade,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { updateOffice } from '../../../fetch';
import { IOfficeDetail } from '../../../models';
import { useConfirm } from '../../../hooks';
import { defaultUnsavedChangesMessage } from '../../../constants';

interface IEditStoreModal {
  open: boolean;
  onClose: () => void;
  currentOffice: IOfficeDetail | null;
}

const Schema = Yup.object().shape({
  isCustomerContractRequired: Yup.boolean(),
});

export const EditStoreModal: FC<IEditStoreModal> = ({ open, onClose, currentOffice }) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          isCustomerContractRequired: currentOffice?.isCustomerContractRequired ?? null,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          try {
            await updateOffice(currentOffice?.officeId as string, {
              isCustomerContractRequired: values.isCustomerContractRequired as boolean,
              name: currentOffice?.officeName ?? '',
            });
            enqueueSnackbar('Successfully updated office!', {
              variant: 'success',
            });
            onClose();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving office, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid, values, setFieldValue }) => {
          const handleClose = async () => {
            if (dirty) {
              const result = await confirm(defaultUnsavedChangesMessage);
              if (result) {
                onClose();
                return resetForm();
              }
              return;
            }
            onClose();
            resetForm();
          };
          return (
            <Modal
              open={open}
              onClose={handleClose}
              maxWidth="sm"
              title={`Edit Office ${currentOffice?.officeName}`}
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl required>
                          <FormLabel>Do you require customer contracts?</FormLabel>
                          <RadioGroup
                            name="isCustomerContractRequired"
                            sx={{ flexDirection: 'row' }}
                            value={
                              values.isCustomerContractRequired === null
                                ? null
                                : values.isCustomerContractRequired
                                ? 'Yes'
                                : 'No'
                            }
                            onChange={e =>
                              setFieldValue(
                                'isCustomerContractRequired',
                                e.target.value === 'Yes' ? true : false
                              )
                            }
                          >
                            <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                            <FormControlLabel value={'No'} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <ModalSaveSection
                    handleCancel={handleClose}
                    isSaveDisabled={!dirty || !isValid}
                  />
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
