import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { TimePicker } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';

export const StyleGuideTimePicker = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Time Picker">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Time Picker component is built upon the{' '}
            <Link href="https://mui.com/x/react-date-pickers/time-picker/" target="_blank">
              Mui Time Picker
            </Link>{' '}
            component. It has a built-in styles, and a time picker.
          </Typography>
          <code>
            Props:
            <br />- name?: string
            <br />- error?: boolean
            <br />- textFieldClass?: string
            <br />- textFieldHelperText?: string
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ setFieldValue, errors, values, touched }) => {
          const isInvalidDate = () => {
            return !!touched?.testField && (!!errors?.testField || !values.testField);
          };
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <TimePicker
                      label="Example Time"
                      name="testField"
                      value={values.testField ? new Date(values.testField) : null}
                      onChange={(date: any) => {
                        setFieldValue('testField', date);
                      }}
                      error={isInvalidDate()}
                      textFieldHelperText={isInvalidDate() ? 'Invalid Time' : ''}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;TimePicker
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Example Time"
                    <br />
                    &nbsp;&nbsp;&nbsp;name="testField"
                    <br />
                    &nbsp;&nbsp;&nbsp;value=&#123;values.testField ? new Date(values.testField) :
                    null&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;onChange=&#123;(date: any) =&gt; &#123;
                    setFieldValue('testField', date); &#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;error=&#123;isInvalidDate()&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;textFieldHelperText=&#123;isInvalidDate() ? 'Invalid Time' :
                    ''&#125; <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
