import { faFloppyDisk, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Alert, Box, Button, Fade, Grid, Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { CancelIcon, FloatingToolbar, Loader, Modal } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { useConfirm } from '../../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyleGuidePaths } from '../../../constants';

export const StyleGuideCardFloatingToolbar = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  const confirm = useConfirm();
  const [open, setOpen] = useState<boolean>(false);
  const [showModalLoading, setShowModalLoading] = useState<boolean>(false);

  const handleClose = async () => {
    const result = await confirm('Are you sure you want to exit?');
    if (result) {
      setOpen(false);
    } else {
      return;
    }
  };

  return (
    <StyleGuidePage title="Floating Toolbar">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Floating Toolbar component is a custom built component that is used for rendering
            sticky buttons fixed to the bottom-right of the screen. The primary purpose of this
            component is meant for tall pages that house elaborate forms so that the Save and Cancel
            buttons are in easy reach, no matter how tall the page gets. The most common buttons
            displayed within a Floating Toolbar instance are Cancel and Save, in that order.
          </Typography>
          <Typography mb={1}>
            The <em>isModal</em> prop, allows for this floating toolbar to be used inside a modal
            component and still remain visible. At the moment, the only modal size designed for use
            with the floating toolbar is <em>lg</em>.
          </Typography>
          <Typography mb={1}>
            NOTE: Due to the fact that the Cancel button styling lacks borders, it is important to
            have that button come first, if there are more than 2 buttons in the floating toolbar.
            For proper spacing purposes.
          </Typography>
          <Typography mb={1}>
            See <Link href={StyleGuidePaths.uiUxPatterns.url}>UI/UX patterns</Link> for more details
            and button implementation guidance (i.e. consistent icons, colors, size, etc.).
          </Typography>
          <code>
            Props:
            <br />- children: ReactNode
            <br />- isModal?: boolean
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Alert severity="info">See bottom right of the screen for example with 3 buttons</Alert>
          </Box>
          <Box mb={1}>
            <FloatingToolbar>
              <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
                <Button color="inherit" startIcon={<CancelIcon />}>
                  Cancel
                </Button>
                <Button color="secondary" startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}>
                  Save
                </Button>
                <Button color="error" startIcon={<FontAwesomeIcon icon={faTrash} />}>
                  Delete
                </Button>
              </Stack>
            </FloatingToolbar>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;FloatingToolbar&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Stack flexDirection="row" justifyContent="flex-end"
            gap=&#123;1&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button color="cancel"&gt;Cancel&lt;/Button&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button color="secondary"
            startIcon=&#123;faFloppyDisk&#125;&gt;Save&lt;/Button&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button color="error"
            startIcon=&#123;faTrash&#125;&gt;Delete&lt;/Button&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Stack&gt;
            <br />
            &lt;/FloatingToolbar&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(true);
                setShowModalLoading(true);
                setTimeout(() => {
                  setShowModalLoading(false);
                }, 5000);
              }}
            >
              Open Modal
            </Button>
            <Modal
              title="Example Lg Modal"
              open={open}
              onClose={() => {
                handleClose();
              }}
              maxWidth="lg"
            >
              {showModalLoading && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Box mt={1}>
                  <Typography>This can be a section of content or form fields</Typography>
                  <Box mt={1} height={theme => theme.spacing(200)} p={1} bgcolor="grey.200">
                    This is a tall block to help create vertical scrolling and showcase the modal
                    instance of the floating toolbar.
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={{
                      xs: 'column',
                      sm: 'row',
                    }}
                    justifyContent={{
                      xs: 'center',
                      sm: 'flex-end',
                    }}
                    className="print--none"
                  >
                    <FloatingToolbar isModal>
                      <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
                        <Button
                          color="inherit"
                          onClick={() => {
                            handleClose();
                          }}
                          startIcon={<CancelIcon />}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="secondary"
                          startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
                          onClick={() => handleClose()}
                        >
                          Save
                        </Button>
                      </Stack>
                    </FloatingToolbar>
                  </Box>
                </Box>
              </Fade>
            </Modal>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Modal title="Example Lg Modal" open=&#123;open&#125; onClose=&#123;() =&gt; &#123;
            handleClose(); &#125;&#125; maxWidth="lg" &gt; <br />
            &nbsp;&nbsp;&nbsp;&#123;showModalLoading && &lt;Loader type="overlay"
            position="centered" /&gt;&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Fade in=&#123;open&#125;&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box mt=&#123;1&#125;&gt; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Typography&gt;This can be a
            section of content or form fields&lt;/Typography&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Box display="flex"
            alignItems="center" flexDirection=&#123;&#123; xs: 'column', sm: 'row', &#125;&#125;
            justifyContent=&#123;&#123; xs: 'center', sm: 'flex-end', &#125;&#125;
            className="print--none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FloatingToolbar isModal&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Stack
            flexDirection="row" justifyContent="flex-end" gap=&#123;1&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button
            color="inherit" onClick=&#123;() =&gt; &#123; handleClose(); &#125;&#125; &gt; Cancel
            &lt;/Button&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Button
            color="secondary" startIcon=&#123;faFloppyDisk&#125; onClick=&#123;() =&gt;
            handleClose()&#125; &gt; Save &lt;/Button&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Stack&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/FloatingToolbar&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Box&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Fade&gt; <br />
            &lt;/Modal&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
