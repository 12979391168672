import { useFormikContext } from 'formik';
import { Card, ImageUpload, TextField } from '../../../components';
import { IOfficeDetail } from '../../../models';
import { FC } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';

interface IEditOfficeDetailsCard {
  currentOffice?: IOfficeDetail;
}

export const EditOfficeDetailsCard: FC<IEditOfficeDetailsCard> = ({ currentOffice }) => {
  const { values, setFieldValue } = useFormikContext<IOfficeDetail>();
  return (
    <Card
      cardTitleProps={{
        title: `Edit Office Details`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={7}>
          <Stack spacing={2}>
            <TextField label="Office Name" name="officeName" required />
            <FormControl required>
              <FormLabel>Do you require customer contracts?</FormLabel>
              <RadioGroup
                name="isCustomerContractRequired"
                sx={{ flexDirection: 'row' }}
                value={
                  values.isCustomerContractRequired === null
                    ? null
                    : values.isCustomerContractRequired
                    ? 'Yes'
                    : 'No'
                }
                onChange={e =>
                  setFieldValue(
                    'isCustomerContractRequired',
                    e.target.value === 'Yes' ? true : false
                  )
                }
              >
                <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                <FormControlLabel value={'No'} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <FormLabel>Office Logo</FormLabel>
          <ImageUpload
            imageKey={`office-logo`}
            showLabel={false}
            handleFileChange={(val, file) => {
              setFieldValue('officeLogoUrl', val);
              setFieldValue('officeLogoFile', file);
            }}
            useImagePreview
            image={values.officeLogo}
            canOverride
            helpText="Upload a new logo for the office. The logo should be a PNG file with a transparent background."
          />
        </Grid>
      </Grid>
    </Card>
  );
};
