import { FC, useMemo, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { IAccountExportSummary, IAccountExportSummaryDetailModel } from '../../../models';
import { formatMoney, downloadFile, formatRawDate, hyphenSeparateTwoInputs } from '../../../helpers';
import { SimpleDataGrid, Card } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { generateAccountingExport } from '../../../fetch';
import { useSnackbar } from 'notistack';
import { Alert, Button, Divider, Box } from '@mui/material';

interface IAccountingExportTableProps {
  accountingExportData: IAccountExportSummary | null;
  isSubmitting: boolean;
  dates: {
    startDate: Date | string;
    endDate: Date | string;
  };
  setRefetchExportBatches: (val: boolean) => void;
  handleReset: () => void;
}

export const AccountingExportTable: FC<IAccountingExportTableProps> = ({
  accountingExportData,
  isSubmitting,
  setRefetchExportBatches,
  dates,
  handleReset,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isExporting, setIsExporting] = useState(false);
  const handleAccountingExport = async () => {
    const { startDate, endDate } = dates;
    try {
      setIsExporting(true);
      const res = await generateAccountingExport(
        (startDate as Date).toISOString(),
        (endDate as Date).toISOString()
      );

      if (res && res.type.includes('text/csv')) {
        setRefetchExportBatches(true);
        handleReset();
        return downloadFile(
          res,
          `AccountingExport_${formatRawDate(startDate as Date)}_${formatRawDate(
            endDate as Date
          )}.csv`
        );
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'An error occurred while exporting', {
        variant: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };
  const dataColumns = useMemo(
    () => [
      {
        field: 'transactionType',
        headerName: 'Type',
        flex: 1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'totalCount',
        headerName: 'Count',
        flex: 1,
        type: 'number',
        sortable: false,
        filterable: false,
      },
      {
        field: 'debtTotal',
        headerName: 'Debits',
        flex: 1,
        type: 'number',
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<IAccountExportSummaryDetailModel>) => {
          const { row: detail } = params;
          return <>{formatMoney(detail.debitTotal)}</>;
        },
      },
      {
        field: 'creditTotal',
        headerName: 'Credits',
        flex: 1,
        type: 'number',
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<IAccountExportSummaryDetailModel>) => {
          const { row: detail } = params;
          return <>{formatMoney(detail.creditTotal)}</>;
        },
      },
    ],
    []
  );
  return (
    <Card
      cardTitleProps={{
        title: 'Accounting Export Summary',
      }}
    >
      {accountingExportData && accountingExportData?.unpostedTransactionCount > 0 && (
        <Alert severity="info" sx={{ mb: 1 }}>
          {accountingExportData?.unpostedTransactionCount} Unposted transactions found within date
          range.
        </Alert>
      )}
      {accountingExportData && accountingExportData?.previouslyBatchedTransactionCount > 0 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {accountingExportData?.previouslyBatchedTransactionCount} Transactions in selected range
          were previously exported and are not included!
        </Alert>
      )}
      <Box mb={2}>
        <SimpleDataGrid
          loading={isSubmitting}
          columns={dataColumns}
          rows={accountingExportData?.transactions ?? []}
          getRowId={row => row.transactionType}
          rowHeight={40}
          disableRowSelectionOnClick={true}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination={undefined}
          disableColumnSelector={true}
          hasMobileLayout
          mobileProps={{
            mobileCustomDefaultAccessor: (row: IAccountExportSummaryDetailModel) => {
              return hyphenSeparateTwoInputs(row.transactionType, row.totalCount.toString());
            }
          }}
        />
      </Box>
      <Divider />
      <Box
        marginTop="1rem"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection={{
            xs: 'column',
            sm: 'row',
          }}
          width={{
            xs: '100%',
            sm: 'auto',
          }}
          justifyContent={{ xs: 'space-between', md: 'center' }}
          gap={1}
        >
          <Button
            color="primary"
            size="small"
            variant="outlined"
            disabled={
              isExporting || isSubmitting || accountingExportData?.transactions.length === 0
            }
            onClick={() => handleAccountingExport()}
            startIcon={
              isExporting ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : (
                <FontAwesomeIcon icon={faFileExport} />
              )
            }
          >
            {isExporting ? 'Exporting...' : 'Export'}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
