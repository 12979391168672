import { FC, useState } from 'react';
import { Grid, Box, Fade, Stack, TextField } from '@mui/material';
import { sendSmsMessage, queueSmsMessage } from '../../../fetch';
import { faMailForward, faMessage, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { CardButton, Loader, Modal, ModalSaveSection } from '../../../components';
import { formatInputPhoneNumber } from '../../../helpers';

export const SmsDetails: FC = () => {
  const [isSmsSendModalOpen, setSmsSendModalOpen] = useState(false);
  const [isSmsQueueModalOpen, setSmsQueueModalOpen] = useState(false);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CardButton
            title="Send SMS"
            alignCenter
            onClick={() => {
              setSmsSendModalOpen(true);
            }}
            icon={faMessage}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardButton
            title="Queue SMS"
            alignCenter
            onClick={() => {
              setSmsQueueModalOpen(true);
            }}
            icon={faStickyNote}
          />
        </Grid>
      </Grid>
      <SmsModal
        isOpen={isSmsSendModalOpen || isSmsQueueModalOpen}
        onClose={() => {
          setSmsSendModalOpen(false);
          setSmsQueueModalOpen(false);
        }}
        type={isSmsQueueModalOpen ? 'queue' : 'send'}
      />
    </>
  );
};

const SmsModal = ({
  isOpen,
  onClose,
  type,
}: {
  isOpen: boolean;
  onClose: () => void;
  type: string;
}) => {
  const [isSending, setIsSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [to, setTo] = useState('');
  const [message, setMessage] = useState('');

  return (
    <Modal
      open={isOpen}
      maxWidth="sm"
      title={`${type === 'send' ? 'Send' : 'Queue'} SMS message`}
      onClose={() => {
        setTo('');
        setMessage('');
        onClose();
      }}
    >
      <Fade in={isOpen}>
        <Box mt={2}>
          {isSending && <Loader type="overlay" />}
          <Stack spacing={2}>
            <TextField
              label="To"
              fullWidth
              variant="outlined"
              disabled={isSending}
              value={to}
              size="small"
              onChange={e => setTo(e.target.value)}
              type="text"
              placeholder="Enter phone number"
              onBlur={e => {
                setTo(formatInputPhoneNumber(e.target.value) ?? '');
              }}
            />
            <TextField
              label="Message"
              fullWidth
              variant="outlined"
              disabled={isSending}
              value={message}
              size="small"
              onChange={e => setMessage(e.target.value)}
            />
          </Stack>
          <ModalSaveSection
            handleCancel={() => {
              setTo('');
              setMessage('');
              onClose();
            }}
            submitIcon={faMailForward}
            submitLabel="Send"
            handleSave={async () => {
              setIsSending(true);
              try {
                await (type === 'send'
                  ? sendSmsMessage({
                      to,
                      message,
                    })
                  : queueSmsMessage({
                      to,
                      message,
                    }));
                onClose();
                setTo('');
                setMessage('');
                enqueueSnackbar(`Sms successfully ${type === 'send' ? 'sent' : 'queued'}.`, {
                  variant: 'success',
                });
              } catch (error: any) {
                enqueueSnackbar(
                  error?.Detail ??
                    `Error ${type === 'send' ? 'sending' : 'queuing'} the sms, please try again.`,
                  {
                    variant: 'error',
                  }
                );
              } finally {
                setIsSending(false);
              }
            }}
          />
        </Box>
      </Fade>
    </Modal>
  );
};
