import { FC, useContext, useMemo, useState } from 'react';
import {
  ExternalLink,
  Link,
  ServerSideDataGrid,
  ServerSideDataGridProps,
  TableActionsMenu,
} from '../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { formatDate, formatMoney, hyphenSeparateTwoInputs } from '../../helpers';
import { Alert, ListItem, ListItemButton, Tooltip } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSnackbar } from 'notistack';
import { IEstimate } from '../../models';
import { setEstimateOverrideStatus } from '../../fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileContract, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmCheckboxModal } from '../../components/confirm/ConfirmCheckboxModal';
import { SearchParamsContext } from '../../context';

interface EstimatesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IEstimate[];
  refetch: () => Promise<void>;
  handleDelete: (estimateId: string | number) => Promise<void>;
  redirect?: string;
  isOTSEstimates?: boolean;
  accountId?: string;
  isEditable?: boolean;
  hideGridFooter?: boolean;
  columnSorting?: boolean;
  refetchRepairStatus?: () => void;
  onNavigation?: (route: string) => unknown;
}

export const EstimatesDataGrid: FC<EstimatesDataGridProps> = ({
  rows,
  refetch,
  handleDelete,
  redirect,
  isOTSEstimates,
  accountId,
  isEditable,
  hideGridFooter = false,
  columnSorting,
  refetchRepairStatus,
  onNavigation,
  ...gridProps
}) => {
  const { queryParams } = useContext(SearchParamsContext);
  const { v2Ots } = useFlags();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedEstimate, setSelectedEstimate] = useState<IEstimate | null>(null);
  const [isUpdatingAgreement, setIsUpdatingAgreement] = useState(false);

  const getEstimateLink = (estimate: IEstimate) => {
    return `/estimates/${estimate.estimateId}${accountId ? `/${accountId}` : ''}${
      redirect ? `?redirect=${redirect}` : ''
    }`;
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'whenCreated',
        headerName: 'Created',
        filterable: false,
        sortable: gridProps.loading ? false : columnSorting ?? true,
        disableColumnMenu: true,
        width: 115,
        renderCell: (params: GridRenderCellParams<IEstimate>) => {
          const { row: estimate } = params;
          return (
            <Link
              onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                if (onNavigation) {
                  event.preventDefault();
                  onNavigation(getEstimateLink(estimate));
                }
              }}
              to={getEstimateLink(estimate)}
            >
              {estimate.whenCreated ? formatDate(estimate.whenCreated) : ''}
            </Link>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        filterable: true,
        sortable: gridProps.loading ? false : columnSorting ?? true,
        disableColumnMenu: true,
        maxWidth: 150,
      },
      isOTSEstimates && {
        field: 'details',
        headerName: 'Agreement Status',
        minWidth: 180,
        flex: 1,
        filterable: false,
        sortable: gridProps.loading ? false : columnSorting ?? true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IEstimate>) => {
          const { row: estimate } = params;
          const childComponent = (
            <Tooltip placement="bottom" title="View Agreement">
              <span>
                {estimate?.hasApprovalOverride
                  ? `Override ${formatDate(estimate.approvalDate)}`
                  : estimate.agreementStatus}
              </span>
            </Tooltip>
          );
          return estimate.repairAgreementLink ? (
            <ExternalLink to={`${estimate.repairAgreementLink}`} target="_blank">
              {childComponent}
            </ExternalLink>
          ) : (
            <span>{estimate.agreementStatus}</span>
          );
        },
      },
      !isOTSEstimates &&
        v2Ots && {
          field: 'repairId',
          headerName: 'OTS',
          flex: 1,
          filterable: false,
          sortable: gridProps.loading ? false : columnSorting ?? true,
          disableColumnMenu: true,
          maxWidth: 100,
          renderCell: (params: GridRenderCellParams<IEstimate>) => {
            const { row: estimate } = params;
            return estimate?.repairId ? (
              <Link
                to={`/services/ots/${estimate.repairId}${
                  redirect
                    ? `?redirect=${encodeURIComponent(
                        `${redirect}${queryParams ? `?${queryParams}` : ''}`
                      )}`
                    : ''
                }`}
              >
                OTS
              </Link>
            ) : (
              <></>
            );
          },
        },
      !isOTSEstimates && {
        field: 'details',
        headerName: 'Details',
        flex: 1,
        filterable: false,
        sortable: gridProps.loading ? false : columnSorting ?? true,
        disableColumnMenu: true,
      },
      !isOTSEstimates && {
        field: 'createdBy',
        headerName: 'Created By',
        width: 250,
        filterable: false,
        sortable: gridProps.loading ? false : columnSorting ?? true,
        disableColumnMenu: true,
      },
      {
        field: 'total',
        headerName: 'Total',
        width: 100,
        filterable: false,
        sortable: gridProps.loading ? false : columnSorting ?? true,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IEstimate>) => {
          const { row: estimate } = params;
          return <>{estimate.total ? formatMoney(estimate.total) : '$0.00'}</>;
        },
      },
      isEditable && {
        field: 'actions',
        headerName: '',
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        width: 30,
        align: 'right',
        renderCell: (params: GridRenderCellParams<IEstimate>) => {
          const { row: estimate } = params;
          return (
            <TableActionsMenu labelContext="OTS" id={`action-menu-${estimate.estimateId}`}>
              {(estimate?.agreementStatus?.includes('Requested') ||
                (estimate?.agreementStatus?.includes('Created') &&
                  !estimate?.agreementStatus?.includes('Not Created')) ||
                estimate?.hasApprovalOverride) && (
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ color: theme => theme.palette.secondary.main }}
                    onClick={() => setSelectedEstimate(estimate)}
                  >
                    <FontAwesomeIcon icon={faFileContract} style={{ marginRight: '.5rem' }} />
                    {estimate?.hasApprovalOverride ? 'Reset Override' : 'Override Agreement'}
                  </ListItemButton>
                </ListItem>
              )}
              <Link
                onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                  if (onNavigation) {
                    event.preventDefault();
                    onNavigation(getEstimateLink(estimate));
                  }
                }}
                to={getEstimateLink(estimate)}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                    Edit Estimate
                  </ListItemButton>
                </ListItem>
              </Link>
              {!estimate.agreementStatus?.includes('Signed') && (
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ color: theme => theme.palette.error.main }}
                    onClick={() => handleDelete(estimate.estimateId)}
                  >
                    <FontAwesomeIcon icon={faTrash} style={{ marginRight: '.5rem' }} />
                    Delete Estimate
                  </ListItemButton>
                </ListItem>
              )}
            </TableActionsMenu>
          );
        },
      },
    ].filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, gridProps.loading]);
  const hasCCRequired = useMemo(() => {
    return selectedEstimate?.isCreditCardRequired ? true : false;
  }, [selectedEstimate]);

  const hasDepositRequired = useMemo(() => {
    return selectedEstimate?.isSecurityDepositRequired ? true : false;
  }, [selectedEstimate]);

  return (
    <>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: IEstimate) => row.estimateId!}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        columnHeaderHeight={36}
        hideFooter={hideGridFooter}
        hasMobileLayout
        mobileProps={{
          mobileCustomDefaultAccessor: (val: IEstimate) =>
            hyphenSeparateTwoInputs(
              formatDate(val?.whenCreated) ?? '',
              formatMoney(val?.total) ?? '$0.00'
            ),
          truncateAccordionLabel: true,
          showHandleActions: true,
        }}
        {...gridProps}
      />
      <ConfirmCheckboxModal
        isOpen={!!selectedEstimate}
        handleClose={(isSubmitCall?: boolean) => {
          if (!isSubmitCall) {
            setSelectedEstimate(null);
          }
        }}
        title={
          selectedEstimate?.hasApprovalOverride
            ? 'Reset Override Signing of Customer Agreement'
            : 'Override Signing of Customer Agreement'
        }
        isLoading={isUpdatingAgreement}
        handleSubmit={async () => {
          try {
            setIsUpdatingAgreement(true);
            await setEstimateOverrideStatus(
              selectedEstimate?.estimateId!,
              selectedEstimate?.hasApprovalOverride ? 'RESET' : 'OVERRIDE'
            );
            if (selectedEstimate?.hasApprovalOverride) {
              enqueueSnackbar('Success, customer agreement reset!', {
                variant: 'success',
              });
            } else {
              enqueueSnackbar('Success, customer agreement overridden!', {
                variant: 'success',
              });
            }
            setSelectedEstimate(null);
            refetch();
            refetchRepairStatus?.();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error, please try again', {
              variant: 'error',
            });
          } finally {
            setIsUpdatingAgreement(false);
          }
        }}
        customMessage={
          hasDepositRequired ? (
            <Alert severity="error">{'Cannot override when a deposit is required'}</Alert>
          ) : undefined
        }
        checkboxLabel={
          hasDepositRequired
            ? undefined
            : selectedEstimate?.hasApprovalOverride
            ? 'Are you sure you want to reset the Agreement Override and set back to Pending?'
            : 'I confirm that the customer has approved the work described in this service by other means, and they are ready to proceed with the work.'
        }
        additionalCheckboxLabel={
          hasCCRequired && !selectedEstimate?.hasApprovalOverride
            ? 'Bypass "Credit Card Required" flag for the estimate?'
            : undefined
        }
        isSaveDisabled={hasDepositRequired}
      />
    </>
  );
};
