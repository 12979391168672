import { Box, Checkbox, FormControlLabel, Grid, Typography, styled } from '@mui/material';
import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Loader } from '../../components';
import { getScheduledServiceChecklist } from '../../fetch';
import { formatDate } from '../../helpers';
import { IServiceSuggestionChecklist } from '../../models';
import { useUnload } from '../../hooks';

interface IChecklistDetailsInfo {
  serviceId: string | number;
  serviceDefId: string | number;
  setChecklistInfo: Dispatch<SetStateAction<IServiceSuggestionChecklist[]>>;
  checklistInfo: IServiceSuggestionChecklist[];
  dirty: boolean;
  readonly?: boolean;
  showLoading?: boolean;
}

export const ChecklistInfoDetails: FC<IChecklistDetailsInfo> = ({
  serviceId,
  serviceDefId,
  checklistInfo,
  setChecklistInfo,
  dirty,
  readonly,
  showLoading = true,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, dirty);

  const getChecklistInfo = async () => {
    try {
      setLoading(true);
      const res = await getScheduledServiceChecklist(serviceId, serviceDefId);
      setChecklistInfo(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getChecklistInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDefId]);

  return (
    <Wrapper>
      {isLoading && showLoading && (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          height="10rem"
          justifyContent="center"
        >
          <Loader position="centered" />
        </Box>
      )}
      {!isLoading && checklistInfo?.length > 0 && (
        <Box display="flex">
          <Grid
            sx={{
              padding: theme => theme.spacing(1),
            }}
            container
            spacing={1}
            alignItems="center"
          >
            {checklistInfo.map((info: IServiceSuggestionChecklist, index) => {
              return (
                <Fragment key={`${index}`}>
                  <Grid item xs={12} sm={4} className="col-print-7">
                    <FormControlLabel
                      control={
                        <>
                          <Checkbox
                            className="print--none"
                            checked={info.isCompleted}
                            id={`${index}-checkbox`}
                            onChange={(_, checked: boolean) => {
                              const copy = JSON.parse(JSON.stringify(checklistInfo));
                              copy[index] = {
                                ...copy[index],
                                isCompleted: checked,
                              };
                              setChecklistInfo(copy);
                            }}
                            disabled={readonly || info.isDeleted}
                          />
                          <div style={{ padding: '4px' }} className="print--only">
                            {info.isCompleted ? 'x' : 'o'}
                          </div>
                        </>
                      }
                      classes={{
                        root: classes.label,
                      }}
                      label={info.description}
                      disabled={info.isDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} className="col-print-5">
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontStyle: 'italic',
                        opacity: info.isDeleted ? 0.26 : 1,
                      }}
                    >
                      Previous:{' '}
                      {info?.whenPreviouslyCompleted
                        ? formatDate(info?.whenPreviouslyCompleted)
                        : ''}
                    </Typography>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </Box>
      )}
      {!isLoading && checklistInfo?.length === 0 && (
        <Box
          className="print--avoid-break"
          display="block"
          alignItems="center"
          flexDirection="column"
          height="5rem"
          justifyContent="center"
        >
          <Typography>No Checklist Information</Typography>
        </Box>
      )}
    </Wrapper>
  );
};

const PREFIX = 'ChecklistInfo';

const classes = {
  label: `${PREFIX}-label`,
};

const Wrapper = styled('div')(({ theme }) => ({
  [`& .${classes.label}`]: {
    '&& span': {
      fontSize: 14,
    },
  },
}));
