import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import { CardTitle, Loader, Card } from '../../components';
import { LeadNoteCard } from './lead-detail-note-card';
import { ILeadNote } from '../../models';
import { dateSortDesc } from '../../helpers';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { getSingleLeadNotes } from '../../fetch/leads';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const LeadDetailNotes = () => {
  const { leadId }: { leadId: string } = useParams();

  const [notes, setNotes] = useState<ILeadNote[]>([]);
  const [latestLeadNoteId, setLatestLeadNoteId] = useState<number | null>(null);

  const { isLoading: isLoadingNotes, refetch: refetchNotes } = useQuery<ILeadNote[], Error>(
    ['lead-notes'],
    () => getSingleLeadNotes(leadId),
    {
      onSuccess: data => {
        setNotes(data);
      },
    }
  );
  return (
    <Card>
      <CardTitle
        title="Notes"
        mobileWrap
        action={
          <>
            <Button
              color="secondary"
              size="small"
              onClick={() => {
                const dummyId = notes.length ? -notes.length : -1;
                setNotes([
                  ...notes,
                  {
                    note: '',
                    createdBy: '',
                    leadNoteId: dummyId,
                    createdDate: '',
                  },
                ]);
                setLatestLeadNoteId(dummyId);
              }}
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              Add Note
            </Button>
          </>
        }
      />
      {isLoadingNotes && <Loader type="overlay" position="centered" />}
      {!isLoadingNotes && (!notes || notes?.length === 0) && (
        <Typography>There are no notes to display.</Typography>
      )}
      {!isLoadingNotes && notes?.length > 0 && (
        <Grid container spacing={2}>
          {dateSortDesc(notes, 'createdDate').map((note, index) => {
            return (
              <Grid item xs={12} md={6} key={`${index}`}>
                <LeadNoteCard
                  note={note}
                  leadId={leadId}
                  refetchNotes={refetchNotes}
                  isInEditMode={note.leadNoteId === latestLeadNoteId}
                  setLatestLeadNoteId={setLatestLeadNoteId}
                  setNotes={setNotes}
                  notes={notes}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Card>
  );
};
