import { useContext, useEffect, useState } from 'react';
import { IFilter } from '../../../models';
import { createFilterValueLookupTable, getCleanFilterValues } from '../utils';
import { ESearchParams, SearchParamsContext } from '../../../context';
import { BrandingContext } from '../../../context/branding-context';

export type FilterFetcher = () => Promise<IFilter[]>;

interface UseFilterOptions {
  filterFetcher: FilterFetcher;
  shouldUseSearchParams?: boolean;
}

export const useFilters = ({ filterFetcher, shouldUseSearchParams }: UseFilterOptions) => {
  const { isPoolService } = useContext(BrandingContext);
  const {
    setSearchParams,
    hasSearchParamValues,
    paramAccountId,
    paramCreatedBy,
    paramWhenCreatedStart,
    paramWhenCreatedEnd,
    paramAgreementId,
    paramServiceTypeId,
    paramSuppressZeroDollar,
    paramEstimateType,
    paramStatus,
    paramSiteId,
    paramAgreementStatus,
    paramInvoiceStatus,
    paramProgress,
    paramVisitStatus,
    paramFilterLetter,
    paramSearchValue,
    paramCustomView,
    paramUserId,
    paramPaymentReference,
    paramCommissionTypeId,
    paramDateType,
    paramServiceDefId,
    paramStartDate,
    paramEndDate,
    paramName,
    paramCode,
    paramInactive,
    paramStatuses,
  } = useContext(SearchParamsContext);

  const [isLoading, setLoading] = useState(false);
  const [isShowingFilters, setIsShowingFilters] = useState(
    hasSearchParamValues && shouldUseSearchParams ? true : false
  );
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<Record<string, string[]>>({});
  const [filterValues, setFilterValues] = useState<Record<string, string[]>>({});

  useEffect(() => {
    if (hasSearchParamValues && shouldUseSearchParams) {
      setIsShowingFilters(true);
    }
  }, [hasSearchParamValues, shouldUseSearchParams]);

  useEffect(() => {
    let data = {};

    if (paramAgreementStatus) {
      data = {
        ...data,
        AgreementStatus: [paramAgreementStatus],
      };
    }
    if (paramInvoiceStatus) {
      data = {
        ...data,
        InvoiceStatus: [paramInvoiceStatus],
      };
    }
    if (paramProgress) {
      data = {
        ...data,
        Progress: [paramProgress],
      };
    }
    if (paramCommissionTypeId) {
      data = {
        ...data,
        CommissionTypeId: [paramCommissionTypeId],
      };
    }
    if (paramDateType) {
      data = {
        ...data,
        DateType: [paramDateType],
      };
    }
    if (paramVisitStatus) {
      data = {
        ...data,
        VisitStatus: [paramVisitStatus],
      };
    }
    if (paramSiteId) {
      data = {
        ...data,
        SiteId: [paramSiteId],
      };
    }
    if (paramStatuses) {
      data = {
        ...data,
        Statuses: [paramStatuses],
      };
    }
    if (paramAccountId) {
      data = {
        ...data,
        AccountId: [paramAccountId],
      };
    }
    if (paramCreatedBy) {
      data = {
        ...data,
        CreatedByUserId: [paramCreatedBy],
      };
    }
    if (paramAgreementId) {
      data = {
        ...data,
        CustomAgreementId: [paramAgreementId],
      };
    }
    if (paramEstimateType) {
      data = {
        ...data,
        EstimateType: [paramEstimateType],
      };
    }
    if (paramServiceTypeId) {
      data = {
        ...data,
        ServiceTypeId: [paramServiceTypeId],
      };
    }
    if (paramStatus) {
      data = {
        ...data,
        Status: [paramStatus],
      };
    }
    if (paramSuppressZeroDollar) {
      data = {
        ...data,
        SuppressZeroDollarEstimates: [paramSuppressZeroDollar],
      };
    }
    if (paramWhenCreatedStart || paramWhenCreatedEnd) {
      data = {
        ...data,
        WhenCreated: [paramWhenCreatedStart ?? '', paramWhenCreatedEnd ?? ''],
      };
    }
    if (paramFilterLetter) {
      data = {
        ...data,
        FilterLetter: [paramFilterLetter],
      };
    }
    if (paramSearchValue) {
      data = {
        ...data,
        SearchValue: [paramSearchValue],
      };
    }
    if (paramCustomView) {
      data = {
        ...data,
        CustomView: [paramCustomView],
      };
    }
    if (paramUserId) {
      data = {
        ...data,
        UserId: [paramUserId],
      };
    }
    if (paramPaymentReference) {
      data = {
        ...data,
        Reference: [paramPaymentReference],
      };
    }
    if (paramServiceDefId) {
      data = {
        ...data,
        ServiceDefId: [paramServiceDefId],
      };
    }
    if (paramStartDate) {
      data = {
        ...data,
        StartDate: [paramStartDate],
      };
    }
    if (paramEndDate) {
      data = {
        ...data,
        EndDate: [paramEndDate],
      };
    }
    if (paramName) {
      data = {
        ...data,
        Name: [paramName],
      };
    }
    if (paramCode) {
      data = {
        ...data,
        Code: [paramCode],
      };
    }
    if (paramInactive) {
      data = {
        ...data,
        Inactive: [paramInactive],
      };
    }
    if (isPoolService) {
      setAppliedFilters(data);
      setFilterValues(data);
    }
  }, [
    hasSearchParamValues,
    paramAccountId,
    paramCreatedBy,
    paramWhenCreatedStart,
    paramWhenCreatedEnd,
    paramAgreementId,
    paramServiceTypeId,
    paramSuppressZeroDollar,
    paramEstimateType,
    paramStatus,
    paramSiteId,
    paramAgreementStatus,
    paramInvoiceStatus,
    paramProgress,
    paramVisitStatus,
    paramFilterLetter,
    paramSearchValue,
    paramCustomView,
    paramUserId,
    paramPaymentReference,
    paramCommissionTypeId,
    paramDateType,
    isPoolService,
    paramServiceDefId,
    paramStartDate,
    paramEndDate,
    paramName,
    paramCode,
    paramStatuses,
    paramInactive,
  ]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const loadedFilters = await filterFetcher();
        setFilters(loadedFilters);
        const initialValues = createFilterValueLookupTable(loadedFilters);
        if (!shouldUseSearchParams) {
          setAppliedFilters(getCleanFilterValues(initialValues));
          setFilterValues(initialValues);
        }
        setFiltersInitialized(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFetcher]);

  const onSubmit = (values: Record<string, string[]>, shouldUseSearchParams: boolean = false) => {
    if (shouldUseSearchParams) {
      setSearchParams({
        // ots
        [ESearchParams.siteId]: values?.SiteId?.[0],
        [ESearchParams.agreementStatus]: values?.AgreementStatus?.[0],
        [ESearchParams.invoiceStatus]: values?.InvoiceStatus?.[0],
        [ESearchParams.progress]: values?.Progress?.[0],
        [ESearchParams.visitStatus]: values?.VisitStatus?.[0],
        [ESearchParams.statuses]: values?.Statuses?.[0],
        // estimates
        [ESearchParams.accountId]: values?.AccountId?.[0],
        [ESearchParams.createdBy]: values?.CreatedByUserId?.[0],
        [ESearchParams.agreementId]: values?.CustomAgreementId?.[0],
        [ESearchParams.estimateType]: values?.EstimateType?.[0],
        [ESearchParams.serviceTypeId]: values?.ServiceTypeId?.[0],
        [ESearchParams.status]: values?.Status?.[0],
        [ESearchParams.suppressZeroDollar]: values?.SuppressZeroDollarEstimates?.[0],
        [ESearchParams.whenCreatedStart]: values?.WhenCreated?.[0],
        [ESearchParams.whenCreatedEnd]: values?.WhenCreated?.[1],
        // customers
        [ESearchParams.filterLetter]: values?.FilterLetter?.[0],
        [ESearchParams.searchValue]: values?.SearchValue?.[0],
        [ESearchParams.customView]: values?.CustomView?.[0],
        // payments/commissions
        [ESearchParams.userId]: values?.UserId?.[0],
        [ESearchParams.paymentReference]: values?.Reference?.[0],
        // commissions
        [ESearchParams.commissionTypeId]: values?.CommissionTypeId?.[0],
        [ESearchParams.dateType]: values?.DateType?.[0],
        [ESearchParams.serviceDefId]: values?.ServiceDefId?.[0],
        [ESearchParams.startDate]: values?.StartDate?.[0],
        [ESearchParams.endDate]: values?.EndDate?.[0],
        // store management
        [ESearchParams.code]: values?.Code?.[0],
        [ESearchParams.name]: values?.Name?.[0],
        [ESearchParams.inactive]: values?.Inactive?.[0],
      });
    } else {
      setAppliedFilters(values);
    }
  };

  const onFilterToggle = () => setIsShowingFilters(!isShowingFilters);

  const onChange = (values: Record<string, string[]>) => {
    setFilterValues(values);
  };

  const onReset = (values: Record<string, string[]>, shouldUseSearchParams: boolean = false) => {
    setFilterValues(values);
    if (shouldUseSearchParams) {
      setSearchParams({
        [ESearchParams.accountId]: null,
        [ESearchParams.createdBy]: null,
        [ESearchParams.agreementId]: null,
        [ESearchParams.estimateType]: null,
        [ESearchParams.serviceTypeId]: null,
        [ESearchParams.serviceDefId]: null,
        [ESearchParams.status]: null,
        [ESearchParams.suppressZeroDollar]: null,
        [ESearchParams.whenCreatedStart]: null,
        [ESearchParams.whenCreatedEnd]: null,
        [ESearchParams.filterLetter]: null,
        [ESearchParams.searchValue]: null,
        [ESearchParams.customView]: null,
        [ESearchParams.userId]: null,
        [ESearchParams.paymentReference]: null,
        [ESearchParams.commissionTypeId]: null,
        [ESearchParams.dateType]: null,
        [ESearchParams.startDate]: null,
        [ESearchParams.endDate]: null,
        [ESearchParams.code]: null,
        [ESearchParams.name]: null,
        [ESearchParams.inactive]: null,
      });
    } else {
      setAppliedFilters({});
    }
  };

  return {
    isLoading,
    isShowingFilters,
    filters,
    filtersInitialized,
    appliedFilters,
    filterValues,
    onChange,
    onSubmit,
    onReset,
    onFilterToggle,
    setFiltersInitialized,
  };
};
