import { Box, SxProps, styled } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';

interface ILabelProps {
  children?: any;
  className?: string;
  sx?: SxProps;
  id?: string;
  wrap?: boolean;
  labelFor?: string;
}

export const Label: FC<ILabelProps> = ({ children, className, sx, id, wrap = false, labelFor }) => {

  return (
    <StyledBox
      id={id}
      component="label"
      className={clsx(classes.label, className)}
      marginRight="1rem"
      sx={sx}
      htmlFor={labelFor ?? ''}
      data-testid="label"
      wrap={wrap}
    >
      {children}
    </StyledBox>
  );
};


const PREFIX = 'Label';

const classes = {
  label: `${PREFIX}-label`
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'wrap'
})<{ wrap: boolean, htmlFor: string }>(({ theme, wrap }) => ({
  [`&.${classes.label}`]: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    whiteSpace: wrap ? 'normal' : 'nowrap',
    fontSize: 14,
  }
}));