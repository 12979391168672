import { DayHours, ProcessedEvent, EventRendererProps } from '@aldabil/react-scheduler/types';

export type EventType = 'RepairVisit' | 'ScheduledService' | 'ScheduledTask';

export interface GetCalendarRequest {
  startDate?: Date;
  endDate?: Date;
  officeId?: string;
  userIds?: string[];
  accountIds?: string[];
}

export interface ICalendarDateRange {
  startDate: Date;
  endDate: Date;
}

export interface IUserInformation {
  userId: string;
  username: string;
  hasAvatar: boolean;
  avatarUrl: string;
  backgroundColor: string;
  foregroundColor: string;
}

export interface ICalendarAddress {
  addressId: string;
  addressName: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface ICalendarEvent {
  index: number;
  eventId: string;
  description: string;
  siteName?: string;
  address?: ICalendarAddress;
  eventType: EventType;
  startTime?: string;
  endTime?: string;
  showBrushIcon: boolean;
  showBeakerIcon: boolean;
  showWrenchIcon: boolean;
  accountId?: string;
  accountName?: string;
  siteId?: string;
  recurringServiceId?: string;
  version: string;
}

export interface ICalendarItemUser {
  userInformation: IUserInformation;
  scheduledServiceCount: number;
  repairCount: number;
  scheduledTaskCount: number;
  events: ICalendarEvent[];
}

export interface ICalendarItem {
  calendarDate: string;
  users: ICalendarItemUser[];
}

export interface IEmployeeStatsDetail {
  calendarItem: ICalendarItem;
  calendarItemUser: ICalendarItemUser;
  userInformation: IUserInformation;
}

export interface ICalendarEventDetail {
  calendarItem: ICalendarItem;
  calendarItemUser: ICalendarItemUser;
  userInformation: IUserInformation;
  event: ICalendarEvent;
  hasChanged: boolean;
}

export interface IWeekdayInfo {
  weekdayLabel: string;
}

export interface IDayInfo extends IWeekdayInfo {
  calendarDate: string;
  date: Date;
  dateLabel: string;
  isCurrentDay: boolean;
}

export interface ICalendarEventUpdate {
  eventId: string;
  eventType: EventType;
  originalEventDate?: string;
  eventDate?: string;
  startTime?: Date;
  endTime?: Date;
  index?: number;
  version: string;
}

export interface FinalOrderEvent {
  eventId: string;
  eventType: EventType;
  index: number;
}

export interface FinalOrderUser {
  userId: string;
  events: FinalOrderEvent[];
}

export interface FinalOrderCalendarItem {
  calendarDate: string;
  users: FinalOrderUser[];
}

export interface IUpdateCalendarRequest {
  changes: ICalendarEventUpdate[];
  finalOrder: FinalOrderCalendarItem[];
}

export interface IScheduledWorkDragChangeDetails {
  scheduledWork: ICalendarEventDetail;
  startTime: Date;
  endTime: Date;
}

export type ICalendarChanges = Record<string, ICalendarEventUpdate>;

export interface ICalendarEventInitialState {
  index?: number;
  calendarDate?: string;
  startTime?: string;
  endTime?: string;
}

export type IInitialCalendarState = Record<string, ICalendarEventInitialState>;

export interface ScheduledWorkCalendarEvent extends ProcessedEvent {
  scheduledWork: ICalendarEventDetail;
}

export interface ScheduledRendererWorkCalendarEvent extends EventRendererProps {
  scheduledWork: ICalendarEventDetail;
}

export enum IWeekday {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum ICalendarMode {
  Summary = 'summary',
  DetailedWork = 'detailed_work',
}

export interface ICalendarModeOption {
  label: string;
  value: ICalendarMode;
}

export enum ICalendarView {
  Month = 'month',
  Week = 'week',
  WorkWeek = 'work_week',
  Day = 'day',
  DateRange = 'date_range',
}

export interface ICalendarViewOption {
  label: string;
  value: ICalendarView;
}

export interface IDayViewSettings {
  startHour: DayHours;
  endHour: DayHours;
  step: number;
}

export interface IMonthViewSettings {
  weekdays: IWeekday[];
  weekStartOn: IWeekday;
  startHour: DayHours;
  endHour: DayHours;
}

export interface IWeekViewSettings {
  weekdays: IWeekday[];
  weekStartOn: IWeekday;
  startHour: DayHours;
  endHour: DayHours;
  step: number;
}

export interface ICalendarViewSettings {
  day?: IDayViewSettings;
  workWeek?: IWeekViewSettings;
  week?: IWeekViewSettings;
  month?: IMonthViewSettings;
}

export interface ITimelessEvent<T = unknown> {
  username: string;
  id: string;
  index?: number;
  date: Date;
  data: T;
}

export interface ITimelessEventChange<T = unknown> {
  event: ITimelessEvent<T>;
  targetCalendarDate: Date;
  targetIndex: number;
}
