import { axios } from '../helpers';
import { userSignal } from '../signals';
import qs from 'querystring';

export const autoExtendRecurringServices = async (payload: {
  accountId: string;
  recurringServiceId: string;
  userId: string;
  daysForwardToReschedule: number;
  officeId?: string;
}): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/azure-functions/auto-extend-recurring-services`, {
      ...payload,
      officeId: payload.officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const processRecurringPayments = async (payload: {
  accountId: string;
  officeId?: string;
  recurringPaymentId: string;
}): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/azure-functions/process-recurring-payments`, {
      ...payload,
      officeId: payload.officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const processOutboxMessages = async (outboxMessageId: string): Promise<string> => {
  try {
    const { data } = await axios.post(
      `/api/azure-functions/local/process-outbox-messages`,
      undefined,
      {
        params: { outboxMessageId },
        paramsSerializer: params => qs.stringify(params),
      }
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const processInboxMessages = async (inboxMessageId: string): Promise<string> => {
  try {
    const { data } = await axios.post(
      `/api/azure-functions/local/process-inbox-messages`,
      undefined,
      {
        params: { inboxMessageId },
        paramsSerializer: params => qs.stringify(params),
      }
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
