import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Button, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
} from '../../../components';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IPaymentSetup, PaymentProcessor } from '../../../models';
import { deletePaymentSetup, getAllPaymentSetups } from '../../../fetch';
import { faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AddEditPaymentSetupModal } from './add-edit-payment-setup-modal';
import { UserContext } from '../../../context';
import { useConfirm } from '../../../hooks';

export const PaymentSetupsTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [currentPaymentSetup, setCurrentPaymentSetup] = useState<IPaymentSetup | null>(null);
  const [isPaymentSetupExists, setPaymentSetupExists] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const { user, setAdyenDetails, updatePaymentProcessorData } = useContext(UserContext);
  const confirm = useConfirm();

  const dataFetcher: GridDataFetcher<IPaymentSetup> = useCallback(
    async ({ perPage, sortDirection, page }) => {
      try {
        const options: any = {
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };

        const res = await getAllPaymentSetups(options);

        setPaymentSetupExists(res.totalRecordCount > 0);

        if (res.totalRecordCount > 1) {
          enqueueSnackbar('This office has multiple payment setups defined.', {
            variant: 'error',
          });
        }

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading payment setup info, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    [enqueueSnackbar, user?.officeId]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<IPaymentSetup>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'payment-setups-grid',
      sortDirection: 'asc',
    },
    dataFetcher,
  });
  const handleEdit = (item: IPaymentSetup) => {
    setCurrentPaymentSetup(item);
    showModal(true);
  };
  const handleDelete = async (item: IPaymentSetup) => {
    try {
      const result = await confirm('Are you sure you want to delete this payment setup?');
      if (result) {
        setDeleting(true);
        await deletePaymentSetup(item.systemSettingId);
        await refetch();
        updatePaymentProcessorData();
        setAdyenDetails?.(undefined);
        enqueueSnackbar(`Payment Setup Deleted!`, {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error deleting payment setup, please try again.`, {
        variant: 'error',
      });
    } finally {
      setDeleting(false);
    }
  };
  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'paymentProcessor',
        headerName: 'Processor',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<IPaymentSetup>) => {
          const { row: setup } = params;
          return setup.paymentProcessor ?? '';
        },
      },
      {
        field: 'apiKey',
        headerName: 'API Key',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 2,
        renderCell: (params: GridRenderCellParams<IPaymentSetup>) => {
          const { row: setup } = params;
          if (setup.paymentProcessor === PaymentProcessor.Adyen) {
            return setup?.adyenConfig?.apiKey ?? '';
          } else {
            return setup?.authNetConfig?.apiLoginId ?? '';
          }
        },
      },
      {
        field: 'secondaryKey',
        headerName: 'Secondary Key',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 2,
        renderCell: (params: GridRenderCellParams<IPaymentSetup>) => {
          const { row: setup } = params;
          if (setup.paymentProcessor === PaymentProcessor.Adyen) {
            return setup?.adyenConfig?.hmacKey ?? '';
          } else {
            return setup?.authNetConfig?.transactionKey ?? '';
          }
        },
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'center',
        flex: 1,
        minWidth: 120,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams<IPaymentSetup>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Payment Setup"
                onClick={() => {
                  handleEdit(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Payment Setup"
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isDeleting]);

  return (
    <>
      <Card>
        <CardTitle
          title="Payment Setup"
          mobileWrap
          action={
            <>
              <Button
                onClick={() => showModal(true)}
                color="secondary"
                size="small"
                disabled={isLoading || isPaymentSetupExists}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add Payment Setup
              </Button>
            </>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: IPaymentSetup) => row.systemSettingId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
          hideFooter
          mobileProps={{
            handleEdit: (val: IPaymentSetup) => {
              handleEdit(val);
            },
            handleDelete: (val: IPaymentSetup) => {
              handleDelete(val);
            },
          }}
        />
      </Card>
      <AddEditPaymentSetupModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentPaymentSetup(null);
        }}
        currentPaymentSetupId={currentPaymentSetup?.systemSettingId ?? null}
        fetchPaymentSetups={() => refetch()}
      />
    </>
  );
};
