import { FC, useContext, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// Components
import {
  Modal,
  Loader,
  TextField,
  ModalSaveSection,
  SelectAsync,
  AmountInput,
} from '../../../components';
import {
  Box,
  Fade,
  Typography,
  Grid,
  Divider,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
// fetch
import {
  updateTreatmentType,
  createTreatmentType,
  getAllTranCodes,
  getInventoryItems,
  getTreatmentUnitOptions,
} from '../../../fetch';

import {
  IResponse,
  ITreatmentType,
  ILookupModel,
  IInventoryItem,
  ITranCode,
} from '../../../models';
import { convertToNumber, formatMoney } from '../../../helpers';

interface IAddEditTreatmentTypeModal {
  open: boolean;
  onClose: () => void;
  currentTreatmentType?: ITreatmentType | null;
  fetchTreatmentTypes: () => void;
}

const Schema = Yup.object().shape({
  treatmentTypeId: Yup.string(),
  code: Yup.string().required('Required').max(10),
  description: Yup.string().required('Required').max(100),
  unitDescription: Yup.string().required('Required'),
  highestToAdd: Yup.number().required('Required'),
  unitIncrement: Yup.number().required('Required'),
  pricePerUnit: Yup.string().required('Required'),
});

export const AddEditTreatmentTypeModal: FC<IAddEditTreatmentTypeModal> = ({
  open,
  onClose,
  currentTreatmentType,
  fetchTreatmentTypes,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const parameters: any = {
    officeId: user?.officeId as string,
    perPage: -1,
  };
  const [unitOptions, setUnitOptions] = useState<ILookupModel[]>([]);

  useEffect(() => {
    async function fetchData() {
      const options: any = {
        officeId: user?.officeId as string,
        perPage: -1,
      };
      const data = await getTreatmentUnitOptions(options);
      setUnitOptions(data);
    }
    if (unitOptions.length === 0) {
      fetchData();
    }
  }, [unitOptions.length, user?.officeId]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          treatmentTypeId: currentTreatmentType?.treatmentTypeId ?? '',
          code: currentTreatmentType?.code ?? '',
          description: currentTreatmentType?.description ?? '',
          officeId: user?.officeId ?? '',
          unitDescription: currentTreatmentType?.unitDescription ?? '',
          highestToAdd: currentTreatmentType?.highestToAdd ?? 0,
          increase: currentTreatmentType?.unitIncrement ?? '',
          price: currentTreatmentType?.pricePerUnit
            ? formatMoney(currentTreatmentType?.pricePerUnit)
            : '$0.00',
          tranCodeId: currentTreatmentType?.tranCodeId ?? '',
          inventoryItemId: currentTreatmentType?.inventoryItemId ?? '',
          lowestToAdd: currentTreatmentType?.lowestToAdd ?? 0,
          unitIncrement: currentTreatmentType?.unitIncrement ?? 0,
          usualUnitsToAdd: currentTreatmentType?.usualUnitsToAdd ?? 0,
          pricePerUnit: currentTreatmentType?.pricePerUnit
            ? formatMoney(currentTreatmentType.pricePerUnit)
            : '$0.00',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            ...values,
            pricePerUnit: values.pricePerUnit ? convertToNumber(values.pricePerUnit) : 0,
          };
          try {
            currentTreatmentType
              ? await updateTreatmentType(data)
              : await createTreatmentType(data);
            enqueueSnackbar(
              currentTreatmentType
                ? 'Successfully updated Treatment Type!'
                : 'Successfully created Treatment Type!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchTreatmentTypes();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving Treatment Type, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid, values, setFieldValue }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentTreatmentType ? 'Edit Treatment Type' : 'Add Treatment Type'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel sx={{ backgroundColor: theme => theme.palette.common.white }}>
                            Unit Description*
                          </InputLabel>
                          <Select
                            name="unitDescription"
                            label="Unit Description"
                            value={values.unitDescription}
                            required
                            onChange={e => {
                              setFieldValue('unitDescription', e.target.value);
                            }}
                          >
                            {unitOptions.map(option => (
                              <MenuItem key={option.description} value={option.description}>
                                {option.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="highestToAdd" label="Limit" type="number" required />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField name="unitIncrement" label="Increase" type="number" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AmountInput
                          name="pricePerUnit"
                          label="Price"
                          required
                          value={values.pricePerUnit}
                          onChange={val => setFieldValue('pricePerUnit', val)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="tranCodeId"
                          label="Tran Code"
                          apiRequest={() => getAllTranCodes(parameters)}
                          transformResponse={(response: IResponse<ITranCode[]>) => {
                            return response.records.map(record => ({
                              label: record.description,
                              value: record.tranCodeId,
                            }));
                          }}
                          hasClear
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          name="inventoryItemId"
                          label="Inventory Items"
                          apiRequest={() => getInventoryItems(parameters)}
                          transformResponse={(response: IResponse<IInventoryItem[]>) => {
                            return response.records.map(record => ({
                              label: record.description,
                              value: record.inventoryItemId,
                            }));
                          }}
                          hasClear
                        />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
