import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { DisplayGroup, ServerSideDataGrid, ServerSideDataGridProps } from '../../components';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, IconButton, Stack, useMediaQuery } from '@mui/material';
import { IEstimateForm, IEstimateLineItem } from '../../models';
import { formatMoney } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

interface EstimateLineItemsDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IEstimateLineItem[];
  refetch: () => Promise<void>;
  handleEdit: (lineItem: IEstimateLineItem) => void;
  handleDelete: (lineItem: IEstimateLineItem) => Promise<void>;
  redirect?: string;
  estimate: IEstimateForm;
}

export const EstimateLineItemsDataGrid: FC<EstimateLineItemsDataGridProps> = ({
  rows,
  refetch,
  redirect,
  handleEdit,
  handleDelete,
  estimate,
  ...gridProps
}) => {
  const isMobile = useMediaQuery(`(max-width: 960px)`);

  const lineItemsTotalAmount = useMemo(() => {
    if (rows && rows.length > 0) {
      const lineItemsAmount = rows.map(v => v.amount ?? 0);
      const amountSum = lineItemsAmount.reduce((a, b) => a + b, 0);
      return amountSum;
    }
    return 0;
  }, [rows]);
  const taxAmount = useMemo(() => {
    return rows.filter(item => item.tranCodeDescription === 'Tax')?.[0]?.amount ?? 0;
  }, [rows]);
  const subTotal = useMemo(() => {
    return rows
      .filter(item => item.tranCodeDescription !== 'Tax')
      .map(item => item.amount || 0)
      .reduce((a, b) => a + b, 0);
  }, [rows]);
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'sortOrder',
        headerName: 'Line',
        maxWidth: 75,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'tranCodeDescription',
        headerName: 'Tran Code',
        minWidth: 125,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'lookupCode',
        headerName: 'Lookup Code',
        minWidth: 150,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'details',
        headerName: 'Details',
        flex: 1,
        dynamicHeight: true,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        type: 'string',
        valueGetter: (params: GridValueGetterParams<IEstimateLineItem>) => {
          const { row } = params;

          if (!row.details) {
            return '';
          }

          return `${row.details}${
            row.laborFeeType === 'Variable'
              ? ` (Variable - based on ${row.estimatedLaborDuration} hours) *`
              : ''
          }`;
        },
      },
      {
        field: 'serialNumber',
        headerName: 'Serial #',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'rate',
        headerName: 'Price',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IEstimateLineItem>) => {
          const { row: lineItem } = params;
          if (typeof lineItem.quantity === 'string') {
            return <></>;
          }
          return <>{lineItem.rate ? formatMoney(lineItem.rate) : '$0.00'}</>;
        },
      },
      {
        field: 'quantity',
        headerName: 'Qty',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 134,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IEstimateLineItem>) => {
          const { row: lineItem } = params;
          return <>{lineItem.amount ? formatMoney(lineItem.amount) : '$0.00'}</>;
        },
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        renderCell: (params: GridRenderCellParams<IEstimateLineItem>) => {
          const { row: lineItem } = params;
          if (typeof lineItem.quantity === 'string') {
            return <></>;
          }
          return (
            <Box>
              <IconButton
                color={estimate?.hasEstimateAgreementBeenSigned ? 'secondary' : 'primary'}
                title={`${
                  estimate?.hasEstimateAgreementBeenSigned ? 'View' : 'Edit'
                } Estimate Material`}
                sx={{ marginRight: theme => theme.spacing(1) }}
                onClick={() => handleEdit(lineItem)}
              >
                <FontAwesomeIcon
                  icon={estimate?.hasEstimateAgreementBeenSigned ? faEye : faEdit}
                  size="sm"
                />
              </IconButton>
              {!estimate?.hasEstimateAgreementBeenSigned && (
                <IconButton
                  color="error"
                  title="Delete Material"
                  onClick={() => handleDelete(lineItem)}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, estimate]);

  const additionalColumns = [
    {
      estimateLineItemId: 'Subtotal',
      quantity: 'Subtotal',
      amount: subTotal,
    },
    {
      estimateLineItemId: 'Sales Tax',
      quantity: `Sales Tax (${
        rows.filter(item => item.tranCodeDescription === 'Tax')?.[0]?.rate ?? 0
      }%)`,
      amount: taxAmount,
    },
    {
      estimateLineItemId: 'Grand Total',
      quantity: 'Grand Total',
      amount: lineItemsTotalAmount,
    },
  ];

  return (
    <Wrapper>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: IEstimateLineItem) => row.estimateLineItemId!}
        rows={
          rows.length > 0
            ? [
                ...rows.filter(item => item.tranCodeDescription !== 'Tax'),
                ...(!isMobile ? additionalColumns : []),
              ].filter(Boolean)
            : []
        }
        columns={columns}
        disableRowSelectionOnClick
        columnHeaderHeight={36}
        getRowClassName={params => {
          if (
            params.row.estimateLineItemId === 'Subtotal' ||
            params.row.estimateLineItemId === 'Sales Tax' ||
            params.row.estimateLineItemId === 'Grand Total'
          ) {
            return classes.calcRow;
          }
          return '';
        }}
        getCellClassName={params => {
          if (
            params.row.estimateLineItemId === 'Subtotal' ||
            params.row.estimateLineItemId === 'Sales Tax' ||
            params.row.estimateLineItemId === 'Grand Total'
          ) {
            return classes.calcRow;
          }
          return '';
        }}
        // turning off footer for this as we don't want to show pagination (per client) and have awkward white space if we just turn off pagination
        hideFooter={true}
        hasMobileLayout
        mobileProps={{
          mobileDefaultAccessor: 'details',
          handleEdit: (val: IEstimateLineItem) => {
            handleEdit(val);
          },
          handleDelete: (val: IEstimateLineItem) => {
            handleDelete(val);
          },
        }}
        {...gridProps}
      />
      {isMobile && (
        <Stack mt={1} mb={1}>
          <DisplayGroup isInline label="Subtotal:" labelId="subTotal">
            {formatMoney(subTotal)}
          </DisplayGroup>
          <DisplayGroup
            isInline
            label={`Sales Tax (${
              rows.filter(item => item.tranCodeDescription === 'Tax')?.[0]?.rate ?? 0
            }%):`}
            labelId="tax"
          >
            {formatMoney(taxAmount)}
          </DisplayGroup>
          <DisplayGroup isInline label="Grand Total:" labelId="total">
            {formatMoney(lineItemsTotalAmount)}
          </DisplayGroup>
        </Stack>
      )}
    </Wrapper>
  );
};

const PREFIX = 'EstimateLineItemsDataGrid';

const classes = {
  calcRow: `${PREFIX}-calcRow`,
};

const Wrapper = styled('div')(({ theme }) => ({
  [`& .${classes.calcRow}`]: {
    backgroundColor: `${theme.palette.common.white} !important`,
    borderBottom: `0px !important`,
    minHeight: '28px !important',
    maxHeight: '28px !important',
  },
}));
