import { IAccountAddress } from './accounts';

export interface IPaymentsPost {
  accountId: string;
  creditCardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  cvv: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  amount: number;
  invoiceNumber: string;
  notes: string;
}

export interface IRecurringPayment {
  recurringPaymentId: string;
  paymentLastFour: string;
  expDate: string;
  dayOfMonth: number;
  paymentAmount: number;
  payOutstandingBalance: boolean;
  processorDetails: string;
  postOneTimePayment: string;
  status: string;
}

export interface IProcessorInfo {
  account: string;
  cardType: string;
  customerProfileId: string;
  expirationDate: string;
  paymentProfileId: string;
  postalCode: string;
  street: string;
}

export interface IRecurringPaymentPost {
  creditCardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  cvv: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  payOutstandingBalance: boolean;
  dayOfMonth: number;
  paymentAmount: number;
}

export interface IRecurringPaymentAdyenPost {
  payOutstandingBalance: boolean;
  dayOfMonth: number;
  paymentAmount: number;
  reference: string;
}

export interface IRecurringPaymentPut {
  paymentAmount: number;
  dayOfMonth: number;
  payBalance: boolean;
}

export interface IExternalInvoicePaymentPost {
  recurringPaymentId: string;
  creditCardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  cvv: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
}

export interface IRecurringPaymentEmailPost {
  paymentAmount: number;
  payOutstandingBalance: boolean;
  dayOfMonth: number;
  toAddress: string[];
  subject: string;
  body: string;
  recurringServiceIds?: string[];
  payOption?: string;
}

export interface IExternalRecurringPaymentData {
  recurringPaymentId: string;
  linkToTransactionId: string;
  address: IAccountAddress;
  accountName: string;
  accountFirstName: string;
  accountLastName: string;
  officeId: string;
  accountId: string;
  storeNumber: string;
  officeName: string;
  storeAddress1: string;
  storeAddress2: string;
  dayOfMonth: number;
  paymentAmount: number;
  status: string;
  logoUrl: string;
  storePhoneNumber: string;
  whenCreated: string;
  payOutstandingBalance: boolean;
  recurringServices?: IExternalRecurringService[];
  terms: string;
}

export interface IExternalRecurringService {
  recurringServiceId: string;
  siteId: string;
  siteDescription: string;
  initialDate: string;
  endDate: string;
  serviceDefId: string;
  serviceDefDescription: string;
  inActive: boolean;
  genericFrequency: string;
  isChargeForChemicals: boolean;
  defaultAmountToCharge: number;
}

export interface IExternalRecurringPaymentPost {
  creditCardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  cvv: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
}

export enum RecurringPaymentCreationStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
};