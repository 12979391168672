import { FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { FC } from 'react';
import { IParameterInputProps } from '../../../../models';

export const DropdownParameter: FC<IParameterInputProps> = ({ parameter, value, onChange }) => {

  const { parameterName, promptText } = parameter;

  return (
    <StyledFormControl size="small" fullWidth>
      <InputLabel className={classes.selectLabel} shrink={true}>{promptText}</InputLabel>
      <Select name={parameterName} value={value || ''} displayEmpty onChange={(e) => {
        onChange(e.target.value as string, parameter);
      }}>
        {parameter.selectListItems.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

const PREFIX = 'DropdownParameter';

const classes = {
  marginBottom: `${PREFIX}-marginBottom`,
  content: `${PREFIX}-content`,
  selectLabel: `${PREFIX}-selectLabel`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.marginBottom}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.content}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.selectLabel}`]: {
    background: 'white',
  }
}));