import { MoreVert } from '@mui/icons-material';
import { ClickAwayListener, IconButton, Menu } from '@mui/material';
import { FC, useState } from 'react';

interface ITableActionsMenuProps {
  id: string;
  labelContext?: string;
  children: any;
  hasAutoClose?: boolean;
  transformDirection?: 'left' | 'right';
}

export const TableActionsMenu: FC<ITableActionsMenuProps> = ({
  id,
  labelContext,
  children,
  hasAutoClose = true,
  transformDirection = 'left',
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuTrigger = (
    <IconButton
      color="primary"
      id={`${id}-button`}
      title={`View Action Menu for ${labelContext ? labelContext : 'Item'}`}
      aria-controls={open ? id : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      onClick={handleClick}
    >
      <MoreVert />
    </IconButton>
  );
  return (
    <>
      {hasAutoClose ? (
        <ClickAwayListener onClickAway={handleClose}>{menuTrigger}</ClickAwayListener>
      ) : (
        menuTrigger
      )}
      <Menu
        id={id}
        MenuListProps={{
          'aria-labelledby': `${id}-button`,
          sx: {
            padding: 0,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: transformDirection === 'right' ? 'left' : 'right',
        }}
        transformOrigin={{ vertical: 'top', horizontal: transformDirection }}
      >
        <div>{children}</div>
      </Menu>
    </>
  );
};
