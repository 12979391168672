import React, { useContext, useRef } from 'react';
import SetupPageNav from './setup-page-nav';
import { Switch } from 'react-router-dom';
import { ISetupPageRoute, setupPageRoutes } from './setup-page-routes';
import { Card } from '../../components';
import { Grid, styled } from '@mui/material';
import { PrivateRoute } from '../../components/skeleton/PrivateRoute';
import { hasNavPermissions } from '../../helpers';
import { UserContext } from '../../context';
import { Paths } from '../../constants';

const SetupPageDetail = () => {
  const setupContentRef = useRef<HTMLDivElement>(null);
  const { user, isStoreAdmin, isSuperAdmin } = useContext(UserContext);

  const filterQuickBooks = (route: ISetupPageRoute) => {
    // Hide QuickBooks if not store admin or super admin
    return isStoreAdmin || isSuperAdmin ? route : route.title !== Paths.quickbooks.label;
  };

  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={12} md={3} lg={2}>
        <Card className={classes.card}>
          <SetupPageNav
            onNavLinkClick={() => {
              setTimeout(() => {
                setupContentRef?.current?.scrollIntoView();
              }, 200);
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={9} lg={10}>
        <div ref={setupContentRef}>
          <Switch>
            {setupPageRoutes
              .filter(route =>
                hasNavPermissions(
                  {
                    ...route,
                    permissions: route.permission ? [route.permission] : [],
                  },
                  user!
                )
              )
              .filter(filterQuickBooks)
              .map((route: ISetupPageRoute) => (
                <PrivateRoute
                  exact
                  key={route.title}
                  path={route.path}
                  legacyUrl={route.legacyUrl}
                  featureFlag={route.featureFlag}
                  permission={route.permission}
                  reverseFlagCheck={route.title === Paths.techAvailability.label}
                >
                  {route.element}
                </PrivateRoute>
              ))}
          </Switch>
        </div>
      </Grid>
    </StyledGrid>
  );
};

const PREFIX = 'SetupPageDetail';

const classes = {
  card: `${PREFIX}-card`,
  nav: `${PREFIX}-nav`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.card}`]: {
    display: 'flex',
    flex: 1,
    minWidth: 'initial',
    height: 460,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    '@media (min-width: 900px)': {
      maxWidth: 300,
    },
  },
}));

export default SetupPageDetail;
