import { Alert, Box, Grid, styled } from '@mui/material';
import { Card, Loader, NonAuthPage } from '../../../components';
import { useParams } from 'react-router-dom';
import {
  getEstimateAgreementData,
  getEstimateAgreementStatus,
  getOfficePaymentProcessor,
} from '../../../fetch';
import { useQuery } from 'react-query';
import { useState, useContext, useMemo } from 'react';
import { formatDate } from '../../../helpers';
import { IAgreementData, IAgreementStatus, IPaymentProcessorModel } from '../../../models';
import { BrandingContext } from '../../../context/branding-context';
import { theme } from '../../../styles';
import { EstimateAgreement } from './estimate-agreement';
import { ContractPageDetails } from './contract-page-details';
import { AxiosError } from 'axios';
import { userSignal } from '../../../signals';

export interface IAgreementInitialValues {
  name: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cvv: string;
  printedName: string;
  hasConfirmedAgreement: boolean;
  hasConfirmedAuthorization: boolean;
  recurringPaymentId: string;
  selectedTab: string;
  creditCardRequired: boolean;
}

export const ContractPage = () => {
  const { repairId, estimateId }: { repairId?: string; estimateId?: string } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const [error, setError] = useState<string | null>(null);
  const { isPoolService } = useContext(BrandingContext);

  const {
    isLoading: isLoadingEstimateAgreementStatus,
    data: estimateAgreementStatus,
    refetch: fetchEstimateAgreementStatus,
  } = useQuery<IAgreementStatus, Error>(
    ['getEstimateAgreementStatus', estimateId, token],
    () => getEstimateAgreementStatus(token as string, estimateId, repairId),
    {
      onError: (e: any) => {
        setError(e?.Detail ?? 'Error loading service agreement, please refresh and try again.');
      },
    }
  );

  const isLoadingStatus = isLoadingEstimateAgreementStatus;
  const signedDate = estimateAgreementStatus?.signedDate || '';
  const hasSubmitted = useMemo(
    () => estimateAgreementStatus?.isSigned || false,
    [estimateAgreementStatus]
  );
  const hasOverride = estimateAgreementStatus?.signedWithOverride || false;

  const { isLoading: isLoadingAgreement, data: agreementData } = useQuery<
    IAgreementData,
    AxiosError
  >(
    ['getEstimateAgreementData', estimateId, hasSubmitted],
    async () => {
      const res = await getEstimateAgreementData(token!, estimateId, repairId);
      return res;
    },
    {
      onError: (e: any) => {
        setError(e?.Detail ?? 'Error loading service agreement, please refresh and try again.');
      },
    }
  );

  const { isLoading: isLoadingPaymentProcessor, data: paymentProcessorData } = useQuery<
    IPaymentProcessorModel,
    Error
  >(
    ['getOfficePaymentProcessor', agreementData?.officeId],
    () => getOfficePaymentProcessor(agreementData?.officeId!, token),
    {
      enabled: !!agreementData?.officeId || !!userSignal.value?.officeId,
    }
  );

  if (isLoadingStatus) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        minHeight="30rem"
        justifyContent="center"
        sx={{ background: theme => theme.palette.common.white }}
      >
        <Loader position="centered" />
      </Box>
    );
  }
  let title = `Service Agreement`;
  if (hasOverride) {
    title += ` - Overridden on ${signedDate ? formatDate(signedDate) : ''}`;
  } else if (hasSubmitted) {
    title += ` - Signed on ${signedDate ? formatDate(signedDate) : ''}`;
  }
  return (
    <NonAuthPage
      title={title}
      officeName={
        isPoolService && estimateAgreementStatus?.officeName
          ? estimateAgreementStatus?.officeName
          : undefined
      }
      hideHeader={isPoolService}
      innerWrapStyles={{
        [theme.breakpoints.down('sm')]: {
          paddingRight: theme.spacing(2),
        },
      }}
    >
      {!!error || !token ? (
        <Alert severity="error">
          The Service Agreement link no longer appears to be valid. Please contact the office for
          more information. Thank you!
        </Alert>
      ) : (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              order: {
                xs: 2,
                lg: 1,
              },
            }}
          >
            <ContractPageDetails
              fetchEstimateAgreementStatus={fetchEstimateAgreementStatus}
              hasSubmitted={hasSubmitted}
              estimateAgreementStatus={estimateAgreementStatus}
              token={token}
              repairId={repairId}
              estimateId={estimateId}
              agreementData={agreementData!}
              paymentProcessorData={paymentProcessorData}
              isLoadingPaymentProcessor={isLoadingPaymentProcessor}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={hasSubmitted ? 12 : 8}
            sx={{
              order: {
                xs: 1,
                lg: 2,
              },
            }}
          >
            <Card>
              <PDFWrapper>
                <EstimateAgreement
                  isLoadingAgreement={isLoadingAgreement}
                  agreementData={agreementData!}
                  hasSubmitted={hasSubmitted}
                />
              </PDFWrapper>
            </Card>
          </Grid>
        </Grid>
      )}
    </NonAuthPage>
  );
};

const PDFWrapper = styled(Box)(({ theme }) => ({
  '&& .row': {
    marginLeft: 0,
    marginRight: 0,
  },
  '&& .table-wrap': {
    overflow: 'auto',
  },
  '&& #docu-agreement': {
    [theme.breakpoints.down('sm')]: {
      '& h3': {
        fontSize: theme.typography.pxToRem(16),
      },
      '& p': {
        fontSize: theme.typography.pxToRem(12),
      },
    },
  },
}));
