import { useHistory, useParams } from 'react-router-dom';
import { Loader, ModalSaveSection, Page } from '../../../components';
import { Paths } from '../../../constants';
import { Form, Formik } from 'formik';
import { getOffice, updateOffice, updateOfficeLogo } from '../../../fetch';
import { useQuery } from 'react-query';
import { IOfficeDetail } from '../../../models';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { EditOfficeDetailsCard } from './edit-office-details-card';

const Schema = Yup.object().shape({
  isCustomerContractRequired: Yup.boolean().nullable(),
  officeName: Yup.string().required('Required'),
  officeLogo: Yup.string(),
  officeLogoFile: Yup.mixed().nullable(),
});

export const OfficeManagementDetailsPage = () => {
  const { officeId }: { officeId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  const { data: currentOffice, isLoading } = useQuery<IOfficeDetail, Error>(
    ['getOfficeDetail', officeId],
    () => getOffice(officeId),
    {
      enabled: !!officeId,
    }
  );
  return (
    <Page
      title="Edit Office"
      breadcrumb={{
        text: 'Office Management',
        title: 'Back to Office Managment',
        link: redirect ? redirect : Paths.officeManagement.url,
      }}
    >
      {isLoading && <Loader type="overlay" />}
      <Formik
        enableReinitialize={true}
        initialValues={{
          isCustomerContractRequired: currentOffice?.isCustomerContractRequired ?? false,
          officeName: currentOffice?.officeName ?? '',
          officeLogo: currentOffice?.storeBranding?.logoUrl ?? '',
          officeLogoFile: null,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          try {
            if (!!values.officeLogoFile) {
              const formData = new FormData();
              formData.append('StoreLogo', values.officeLogoFile);
              await updateOfficeLogo(currentOffice?.officeId as string, formData);
            }
            await updateOffice(currentOffice?.officeId as string, {
              name: values.officeName,
              isCustomerContractRequired: values.isCustomerContractRequired as boolean,
            });
            enqueueSnackbar('Successfully updated office!', {
              variant: 'success',
            });

            history.push(Paths.officeManagement.url);
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving office, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ handleSubmit, dirty, isValid, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <EditOfficeDetailsCard currentOffice={currentOffice} />
              <ModalSaveSection
                handleCancel={() => history.push(redirect ? redirect : Paths.officeManagement.url)}
                isSaveDisabled={!dirty || !isValid}
              />
            </Form>
          );
        }}
      </Formik>
    </Page>
  );
};
