import { axios } from '../helpers';
import qs from 'querystring';
import {
  IAccountExportPrior,
  IAccountExportSummary,
  IBillingLog,
  IResponse,
  IPaginatedResponse,
} from '../models';
import { userSignal } from '../signals';

export const getBillingLogs = async (filters?: {
  first?: boolean;
  last?: boolean;
  before?: string;
  after?: string;
  sortBy?: string;
  sortDirection?: string;
  perPage?: number;
  officeId?: string | null;
}): Promise<IPaginatedResponse<IBillingLog>> => {
  try {
    const { data } = await axios.get(`/api/billing/logs`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountingExportSummary = async (
  startDate: string,
  endDate: string,
  filters?: {
    officeId?: string | null;
  }
): Promise<IAccountExportSummary> => {
  try {
    const { data } = await axios.get(`/api/billing/accounting-export`, {
      params: {
        ...filters,
        businessStartDate: startDate,
        businessEndDate: endDate,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getPriorExportBatches = async (
  officeId: string,
  filters?: {
    startDate?: string;
    endDate?: string;
    status?: string;
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number | string;
  }
): Promise<IResponse<IAccountExportPrior>> => {
  try {
    const { data } = await axios.get(`/api/billing/${officeId}/accounting-exported-batches`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const voidPriorTransactionBatch = async (
  transactionBatchId: string | number,
  newStatus: string,
  officeId: string
): Promise<any> => {
  try {
    const { data } = await axios.put(
      `/api/billing/${transactionBatchId}/accounting-exported-batches`,
      {
        newStatus,
        officeId,
      }
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
export const generateAccountingExport = async (
  startDate: string,
  endDate: string,
  officeId?: string | null
) => {
  try {
    const res = await axios.post(`/api/billing/accounting-export-csv`, null, {
      params: {
        businessStartDate: startDate,
        businessEndDate: endDate,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
      responseType: 'blob',
    });
    return res.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAccountingExportBatch = async (batchId: string) => {
  try {
    const { data } = await axios.get(`/api/billing/accounting-export-batch/${batchId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const downloadAccountingExportBatch = async (batchId: string): Promise<any> => {
  try {
    const res = await axios.get(`/api/billing/accounting-export-batch/${batchId}/download`, {
      responseType: 'blob',
    });
    return res;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
