import { Box, styled } from '@mui/material';
import { FC, useContext } from 'react';
import clsx from 'clsx';
import { BrandingContext } from '../../context/branding-context';

interface IPrintHeaderProps { }

export const PrintHeader: FC<IPrintHeaderProps> = () => {

  const { printLogo } = useContext(BrandingContext);

  return (
    <StyledBox className={clsx('print--only', classes.printHeader)}>
      <img src={printLogo} alt="Print Logo" />
    </StyledBox>
  );
};

const PREFIX = 'PrintHeader';

const classes = {
  printHeader: `${PREFIX}-printHeader`
};

const StyledBox = styled(Box)(({ theme }) => {
  return {
    [`&.${classes.printHeader}`]: {
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(1.5),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  };
});
