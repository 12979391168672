import { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Typography, ListItem, ListItemButton } from '@mui/material';
import {
  Card,
  GridDataFetcher,
  ServerSideDataGrid,
  TableActionsMenu,
  useDataGrid,
  Loader,
  CardTitle,
  Modal,
  AmountInput,
} from '../../components';
import { useSnackbar } from 'notistack';
import { IRecurringPayment, IProcessorInfo, IAccountDetail, RecurringPaymentCreationStatus } from '../../models';
import { convertToNumber, formatDate, formatMoney } from '../../helpers';
import {
  getRecurringPayments,
  getRecurringPaymentProcessorInfo,
  postOneTimeRecurringPayment,
  deleteRecurringPayment,
} from '../../fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCreditCard,
  faEdit,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { RecurringPaymentModal } from './recurring-payment-modal';
import { useConfirm } from '../../hooks';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { CollectCCModal } from './collect-cc-modal';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IRecurringPayments {
  accountId: string;
  currentCustomer: IAccountDetail;
  setShouldRefetchAgingReport?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const RecurringPayments: FC<IRecurringPayments> = ({
  accountId,
  currentCustomer,
  setShouldRefetchAgingReport,
}) => {
  const { recurringPaymentsCollectCc } = useFlags();
  const { hash } = useLocation();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingProcessorInfo, setIsLoadingProcessorInfo] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isPostingPayment, setIsPostingPayment] = useState<boolean>(false);
  const [isProcessorInfoModalOpen, setIsProcessorInfoModalOpen] = useState<boolean>(false);
  const [isOneTimePaymentModalOpen, setIsOneTimePaymentModalOpen] = useState<boolean>(false);
  const [processorInfo, setProcessorInfo] = useState<IProcessorInfo>();
  const [processorInfoError, setProcessorInfoError] = useState<string>('');
  const [activeRecurringPaymentId, setActiveRecurringPaymentId] = useState<string>('');
  const [oneTimePaymentAmount, setOneTimePaymentAmount] = useState<string>('');
  const [showRecurringPaymentModal, setShowRecurringPaymentModal] = useState<boolean>(false);
  const [currentRecurringPayment, setCurrentRecurringPayment] = useState<IRecurringPayment | null>(
    null
  );
  const [showCollectCCModal, setShowCollectCCModal] = useState<boolean>(false);

  const mapFilters = (column: string | undefined) => {
    if (column === 'paymentLastFour') {
      return 'LastFour';
    }
    if (column === 'paymentAmount') {
      return 'amount';
    }
    return column;
  };

  const dataFetcher: GridDataFetcher<IRecurringPayment> = useCallback(
    async ({ sortColumn, sortDirection, page, perPage }) => {
      try {
        const res = await getRecurringPayments(accountId, {
          perPage: perPage,
          sortBy: mapFilters(sortColumn) || 'lastFour',
          sortDirection: sortDirection || 'asc',
          page: page + 1,
        });
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading recurring payments, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading: isLoadingRecurringPayments,
    refetch: fetchRecurringPayments,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<IRecurringPayment>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'recurring-payments-grid',
      sortColumn: 'paymentLastFour',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  useEffect(() => {
    // Scroll to Recurring Payments after adding a recurring payment
    if (hash.includes('#recurring-payments')) {
      document?.getElementById('recurring-payments')?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, hash]);

  const handleShowProcessorInfo = async (recurringPaymentId: string) => {
    try {
      setIsLoadingProcessorInfo(true);
      setProcessorInfo(undefined);
      setProcessorInfoError('');
      setIsProcessorInfoModalOpen(true);
      const response = await getRecurringPaymentProcessorInfo(recurringPaymentId);
      setProcessorInfo(response);
    } catch (err: any) {
      const errorMessage = err?.response?.data?.Detail;
      if (errorMessage) {
        setProcessorInfoError(errorMessage);
      } else {
        enqueueSnackbar('Error loading processor info. Please refresh the page.', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoadingProcessorInfo(false);
    }
  };

  const handleShowPostOneTimePaymentModal = (recurringPaymentId: string) => {
    setOneTimePaymentAmount('');
    setActiveRecurringPaymentId(recurringPaymentId);
    setIsOneTimePaymentModalOpen(true);
  };

  const handlePostOneTimePayment = async () => {
    try {
      setIsPostingPayment(true);
      await postOneTimeRecurringPayment(
        activeRecurringPaymentId,
        convertToNumber(oneTimePaymentAmount)
      );
      enqueueSnackbar('Payment posted successfully!', { variant: 'success' });
      setIsOneTimePaymentModalOpen(false);
      setActiveRecurringPaymentId('');
      setShouldRefetchAgingReport && setShouldRefetchAgingReport(true);
    } catch (err: any) {
      const errorMessage = err?.response?.data?.Detail;
      if (errorMessage.includes('invalid authentication values')) {
        enqueueSnackbar(
          'Payment could not be posted because your office has invalid Authorize.Net credentials.',
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(errorMessage || 'Error posting payment. Please try again.', {
          variant: 'error',
        });
      }
    } finally {
      setIsPostingPayment(false);
      setShouldRefetchAgingReport && setShouldRefetchAgingReport(undefined);
    }
  };

  const handleCloseOneTimePaymentModal = () => {
    setIsOneTimePaymentModalOpen(false);
    setOneTimePaymentAmount('');
  };
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'paymentLastFour',
        headerName: 'Last Four',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 70,
        valueGetter: (params: GridValueGetterParams<IRecurringPayment>) => {
          const { row: payment } = params;
          if (payment.status === RecurringPaymentCreationStatus.InProgress) {
            return 'Pending';
          }
          return payment.paymentLastFour || null
        },
      },
      {
        field: 'expDate',
        headerName: 'Expires',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<IRecurringPayment>) => {
          const { row: payment } = params;
          return <span>{formatDate(payment.expDate)}</span>;
        },
      },
      {
        field: 'dayOfMonth',
        headerName: 'Day of Month',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<IRecurringPayment>) => {
          const { row: payment } = params;
          return <>{payment.dayOfMonth === 0 ? 'Last' : payment.dayOfMonth}</>;
        },
      },
      {
        field: 'paymentAmount',
        headerName: 'Pay Amount',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<IRecurringPayment>) => {
          const { row: payment } = params;
          if (payment.payOutstandingBalance) {
            return <></>;
          }
          return <>{formatMoney(payment.paymentAmount)}</>;
        },
      },
      {
        field: 'payOutstandingBalance',
        headerName: 'Pay Balance',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<IRecurringPayment>) => {
          const { row: payment } = params;
          return payment.payOutstandingBalance ? (
            <Typography component="span" sx={{ color: theme => theme.palette.primary.main }}>
              <FontAwesomeIcon icon={faCheckCircle} size="xl" />
            </Typography>
          ) : (
            <></>
          );
        },
      },
      {
        field: 'processorDetails',
        headerName: 'Processor Details',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IRecurringPayment>) => {
          const { row: payment } = params;
          return (
            <Button
              onClick={() => handleShowProcessorInfo(payment.recurringPaymentId)}
              variant="text"
            >
              Show
            </Button>
          );
        },
      },
      {
        field: 'postOneTimePayment',
        headerName: 'Post One-time Payment',
        disableColumnMenu: true,
        flex: 1.5,
        minWidth: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IRecurringPayment>) => {
          const { row: payment } = params;
          return payment.status === RecurringPaymentCreationStatus.Completed && (
            <Button
              onClick={() => handleShowPostOneTimePaymentModal(payment.recurringPaymentId)}
              variant="text"
            >
              Post
            </Button>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        width: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IRecurringPayment>) => {
          const { row: payment } = params;
          return (
            <TableActionsMenu
              labelContext="Recurring Payment"
              id={`action-menu-${payment.recurringPaymentId}`}
            >
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ color: theme => theme.palette.primary.main }}
                  onClick={() => {
                    setCurrentRecurringPayment(payment);
                    setShowRecurringPaymentModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                  Edit Payment
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ color: theme => theme.palette.error.main }}
                  onClick={async () => {
                    try {
                      const result = await confirm(
                        'Are you sure you want to delete this recurring payment?'
                      );
                      if (result) {
                        setDeleting(true);
                        await deleteRecurringPayment(payment.recurringPaymentId);
                        enqueueSnackbar(`Recurring Payment Deleted!`, {
                          variant: 'success',
                        });
                        fetchRecurringPayments();
                      }
                    } catch (error) {
                      enqueueSnackbar(`Error deleting recurring payment, please try again.`, {
                        variant: 'error',
                      });
                    } finally {
                      setDeleting(false);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} style={{ marginRight: '.5rem' }} />
                  Delete Payment
                </ListItemButton>
              </ListItem>
            </TableActionsMenu>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleting, setShowRecurringPaymentModal]);

  return (
    <>
      <Box marginTop="1rem" id="recurring-payments">
        <Card>
          <CardTitle
            title="Recurring Payments"
            action={
              <>
                {recurringPaymentsCollectCc && (
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setShowCollectCCModal(true);
                    }}
                    title="Collect CC on File"
                    startIcon={<FontAwesomeIcon icon={faCreditCard} />}
                  >
                    Request CC for Recurring Payment
                  </Button>
                )}
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setShowRecurringPaymentModal(true);
                  }}
                  title="Add Recurring Payment"
                  startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                >
                  Add Recurring Payment
                </Button>
              </>
            }
          />

          {isDeleting && (
            <Loader
              position="centered"
              title={isDeleting ? 'Deleting...' : 'Saving...'}
              type="overlay"
            />
          )}
          <ServerSideDataGrid
            autoHeight
            getRowId={(row: IRecurringPayment) => row.recurringPaymentId!}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            columnHeaderHeight={36}
            page={page}
            pageSize={perPage}
            sortModel={sortModel}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChange}
            rowCount={recordCount}
            loading={isLoadingRecurringPayments}
            noResultsMessage="There are no recurring payments to display."
          />
        </Card>
        <Modal
          open={isProcessorInfoModalOpen}
          onClose={() => setIsProcessorInfoModalOpen(false)}
          maxWidth="sm"
          title="Processor Info"
        >
          {isLoadingProcessorInfo && (
            <Box my={2}>
              <Loader position="centered" />
            </Box>
          )}
          {!isLoadingProcessorInfo && <Box mt={2}>{processorInfoError}</Box>}
          {!isLoadingProcessorInfo && !processorInfoError && (
            <Box mt={2}>
              <Box mb={2}>
                <Typography variant="h2">On file at processor:</Typography>
              </Box>
              <Box my={1}>Customer Profile ID: {processorInfo?.customerProfileId ?? ''}</Box>
              <Box my={1}>Payment Profile ID: {processorInfo?.paymentProfileId ?? ''}</Box>
              <Box my={1}>Address: {processorInfo?.street ?? ''}</Box>
              <Box my={1}>Postal Code: {processorInfo?.postalCode ?? ''}</Box>
              <Box my={1}>Card Type: {processorInfo?.cardType ?? ''}</Box>
              <Box my={1}>Account: {processorInfo?.account ?? ''}</Box>
            </Box>
          )}
        </Modal>
        <Modal
          open={isOneTimePaymentModalOpen}
          onClose={handleCloseOneTimePaymentModal}
          maxWidth="sm"
          title="Post One-Time Payment"
          actions={
            <>
              <Button
                type="button"
                color="inherit"
                disabled={isPostingPayment}
                onClick={handleCloseOneTimePaymentModal}
              >
                Cancel
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={handlePostOneTimePayment}
                disabled={isPostingPayment || convertToNumber(oneTimePaymentAmount) <= 0}
              >
                Post
              </Button>
            </>
          }
        >
          <Box pt={3} pb={2}>
            {isPostingPayment && (
              <Loader position="centered" title="Posting Payment" type="overlay" />
            )}
            <AmountInput
              value={oneTimePaymentAmount}
              onChange={val => setOneTimePaymentAmount(val)}
              name="oneTimePaymentAmount"
              disabled={isPostingPayment}
              label="Amount"
            />
          </Box>
        </Modal>
      </Box>
      <RecurringPaymentModal
        isOpen={showRecurringPaymentModal}
        currentCustomer={currentCustomer}
        currentRecurringPayment={currentRecurringPayment}
        onClose={(shouldReload?: boolean) => {
          setShowRecurringPaymentModal(false);
          setCurrentRecurringPayment(null);
          if (shouldReload) {
            fetchRecurringPayments();
          }
        }}
      />
      {recurringPaymentsCollectCc && (
        <CollectCCModal
          isOpen={showCollectCCModal}
          onClose={() => setShowCollectCCModal(false)}
          currentCustomer={currentCustomer}
        />
      )}
    </>
  );
};
