import { FC } from 'react';
import { Card as MuiCard, CardContent, SxProps } from '@mui/material';
import { CardTitle, ICardTitleProps } from './CardTitle';

interface ICard {
  cardTitleProps?: ICardTitleProps;
  children: any;
  className?: string;
  sxProps?: SxProps;
  noPaddingBottom?: boolean;
}

export const Card: FC<ICard> = ({
  cardTitleProps,
  children,
  className,
  sxProps,
  noPaddingBottom,
}) => {
  return (
    <MuiCard className={className} sx={{ position: 'relative', ...sxProps }}>
      <CardContent
        sx={
          noPaddingBottom
            ? { paddingBottom: `0px !important` }
            : { paddingBottom: '16px !important' }
        }
      >
        {cardTitleProps && <CardTitle {...(cardTitleProps as ICardTitleProps)} />}
        {children}
      </CardContent>
    </MuiCard>
  );
};
