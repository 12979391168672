import { Formik } from 'formik';
import { FC, useContext } from 'react';
import * as Yup from 'yup';
import { Modal } from '../../../components';
import { InventoryModalDetails } from './inventory-modal-details';
import { IInventoryItem, IInventoryItemForm } from '../../../models';
import { convertToNumber, formatMoney } from '../../../helpers';
import { postInventoryItem, putInventoryItem } from '../../../fetch';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../context';

const Schema = Yup.object().shape({
  itemId: Yup.number()
    .required('Required')
    .test('Is positive?', "Must be greater than '0'", (value: any) => value > 0)
    .test('len', 'Number is too large', (val: any) => val < 2147483647),
  itemLookupCode: Yup.string().required('Required').nullable(),
  manufacturerNumber: Yup.string().max(255, 'Max 255 characters').required('Required').nullable(),
  description: Yup.string().max(255, 'Max 255 characters').required('Required'),
  aliases: Yup.array().of(Yup.string().nullable()).nullable(),
  cost: Yup.string().nullable(),
  price: Yup.string().nullable(),
  saleStartDate: Yup.string().nullable(),
  saleEndDate: Yup.string().nullable(),
  salePrice: Yup.string().nullable(),
  isTaxable: Yup.boolean(),
  inactive: Yup.boolean(),
  isSerialized: Yup.boolean(),
});

interface IInventoryModal {
  isOpen: boolean;
  handleClose: (shouldUpdate?: boolean) => void;
  selectedInventoryItem: IInventoryItem | null;
}

export const InventoryModal: FC<IInventoryModal> = ({
  isOpen,
  handleClose,
  selectedInventoryItem,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  return (
    <Modal
      open={isOpen}
      onClose={() => handleClose()}
      maxWidth="lg"
      title={`${selectedInventoryItem ? 'Edit' : 'Add'} Item`}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          itemId: Number(selectedInventoryItem?.itemId) ?? '',
          poolCommerceInventoryId: selectedInventoryItem?.poolCommerceInventoryId ?? '',
          itemLookupCode: selectedInventoryItem?.itemLookupCode ?? '',
          manufacturerNumber: selectedInventoryItem?.manufacturerNumber ?? '',
          description: selectedInventoryItem?.description ?? '',
          aliases: selectedInventoryItem?.aliases ?? [],
          cost: selectedInventoryItem?.cost ? formatMoney(selectedInventoryItem?.cost, 2) : '',
          price: selectedInventoryItem?.price ? formatMoney(selectedInventoryItem?.price, 2) : '',
          saleStartDate: selectedInventoryItem?.saleStartDate ?? '',
          saleEndDate: selectedInventoryItem?.saleEndDate ?? '',
          salePrice: selectedInventoryItem?.salePrice
            ? formatMoney(selectedInventoryItem?.salePrice, 2)
            : '',
          isTaxable: selectedInventoryItem?.isTaxable ?? false,
          inactive: selectedInventoryItem?.inActive ?? false,
          isSerialized: selectedInventoryItem?.isSerialized ?? false,
        }}
        validationSchema={Schema}
        onSubmit={async values => {
          const payload: IInventoryItemForm = {
            itemId: Number(values.itemId),
            itemLookupCode: values.itemLookupCode,
            manufacturerNumber: values.manufacturerNumber ?? '',
            description: values.description ?? null,
            aliases: values.aliases ?? null,
            cost: values?.cost ? convertToNumber(values?.cost) : 0,
            price: values?.price ? convertToNumber(values?.price) : 0,
            saleStartDate: values?.saleStartDate ?? null,
            saleEndDate: values?.saleEndDate ?? null,
            salePrice: values?.salePrice ? convertToNumber(values?.salePrice) : null,
            isTaxable: values?.isTaxable ?? false,
            inactive: values?.inactive ?? false,
            isSerialized: values?.isSerialized ?? false,
            officeId: selectedInventoryItem?.officeId ?? user?.officeId ?? '',
          };
          try {
            !selectedInventoryItem
              ? await postInventoryItem(payload)
              : await putInventoryItem(Number(selectedInventoryItem?.inventoryId), payload);
            enqueueSnackbar('Inventory item saved!', { variant: 'success' });
            handleClose(true);
          } catch (err: any) {
            enqueueSnackbar(err?.Detail || `Error saving inventory item. Please try again.`, {
              variant: 'error',
            });
          }
        }}
      >
        {() => {
          return <InventoryModalDetails handleClose={handleClose} />;
        }}
      </Formik>
    </Modal>
  );
};
