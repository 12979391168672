import { FC } from 'react';
import { SearchResultsGrid } from './search-results-grid';
import { ISearchAccountResult, ISearchResults, ISearchResultsPage } from '../../models';
import { getAccountSearchResults } from '../../fetch';
import { formatInputPhoneNumber, getCustomerDetailRoute } from '../../helpers';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Typography, Tooltip } from '@mui/material';
import { Link } from '../../components';

interface ICustomerSearchResults {
  results: ISearchResults;
  page: ISearchResultsPage[];
  perPage: ISearchResultsPage[];
}

export const CustomerSearchResults: FC<ICustomerSearchResults> = ({ results, page, perPage }) => {
  return (
    <SearchResultsGrid
      labelContext="Customer"
      searchType="customers"
      rowId="accountId"
      totalCount={results?.accounts?.totalRecordCount}
      gridResults={results?.accounts?.records}
      getApiRequest={getAccountSearchResults}
      pageNumber={page?.find(type => type.type === 'customers')?.page}
      pageSize={perPage?.find(type => type.type === 'customers')?.perPage}
      columns={[
        {
          headerName: 'Name',
          field: 'name',
          disableColumnMenu: true,
          sortable: false,
          flex: 1.5,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ISearchAccountResult>) => {
            const { row: original } = params;
            const childComponent = (
              <Tooltip placement="bottom" title="Edit customer">
                <span>
                  {original.name ? original.name : `${original.firstName} ${original.lastName}`}
                </span>
              </Tooltip>
            );
            return (
              <Link to={`${getCustomerDetailRoute(original.accountId!)}`}>{childComponent}</Link>
            );
          },
        },
        {
          headerName: 'Status',
          field: 'status',
          disableColumnMenu: true,
          sortable: false,
          width: 80,
          renderCell: (params: GridRenderCellParams<ISearchAccountResult>) => {
            const { row: original } = params;
            return (
              <Typography
                sx={
                  original.status === 'Active'
                    ? { color: '#41D090', fontWeight: 'bold', fontSize: '14px' }
                    : { fontWeight: 'bold', fontSize: '14px' }
                }
              >
                {original.status}
              </Typography>
            );
          },
        },
        {
          headerName: 'Account Code',
          field: 'code',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          minWidth: 80,
          maxWidth: 150,
        },
        {
          headerName: 'Primary Address',
          field: 'addressStreet',
          disableColumnMenu: true,
          sortable: false,
          flex: 1.5,
          minWidth: 150,
        },
        {
          headerName: 'Primary Phone',
          field: 'phoneNumbers',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ISearchAccountResult>) => {
            const { row: original } = params;
            const primaryPhone = original?.phoneNumbers?.find(p => p.phoneNumberType === 'Primary');
            const formattedNumber = !!primaryPhone
              ? formatInputPhoneNumber(`${primaryPhone.areaCode}${primaryPhone.phoneNumber}`)
              : '-';
            return <>{formattedNumber}</>;
          },
        },
        {
          headerName: 'Email',
          field: 'emailExact',
          disableColumnMenu: true,
          sortable: false,
          flex: 1.5,
          minWidth: 150,
        },
      ]}
    />
  );
};
