import { GridRenderCellParams } from '@mui/x-data-grid';
import { getPoolCommerceRealTimePricing } from '../../../fetch';
import { FC, useEffect, useState } from 'react';
import { IInventoryItem } from '../../../models';
import { formatMoney } from '../../../helpers';
import { CircleProgress } from '../../../components';

interface ICustomCostCell {
  params: GridRenderCellParams<IInventoryItem>;
}

export const CustomCostCell: FC<ICustomCostCell> = ({ params }) => {
  const [currentCost, setCurrentCost] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchCostData = async () => {
      try {
        setIsLoading(true);
        const res = await getPoolCommerceRealTimePricing({
          productPriceParameters: [
            {
              productId: params.row.poolCommerceInventoryId,
              unitOfMeasure: null,
            },
          ],
        });
        if (res.realTimePricingResults?.[0]?.unitNetPrice) {
          setCurrentCost(res.realTimePricingResults?.[0]?.unitNetPrice);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchCostData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) {
    return <CircleProgress size="small" />;
  }
  return <>{formatMoney(currentCost, 2)}</>;
};
