import {
  Alert,
  Divider,
  Fade,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { formatDate } from '../../../helpers';
import { Modal, Loader, ModalSaveSection, DisplayGroup } from '../../../components';
import { FC, useEffect, useState } from 'react';
import { IListUser, ISuperAdminUserData } from '../../../models';
import { useConfirm } from '../../../hooks';
import { getUsers, upgradeUserToSuperAdmin } from '../../../fetch';
import { useSnackbar } from 'notistack';
import { faClose, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IUsersTableModalData {
  setIsShowingModal: (val: boolean) => void;
  setCurrentUser: (val: any) => void;
  currentUser: IListUser | null;
  isShowingModal: boolean;
  isLoadingData: boolean;
  currentUserUpdateData: ISuperAdminUserData | null;
  fetchUsers: () => void;
}

export const UsersTableModalData: FC<IUsersTableModalData> = ({
  setIsShowingModal,
  setCurrentUser,
  currentUser,
  isShowingModal,
  isLoadingData,
  currentUserUpdateData,
  fetchUsers,
}) => {
  const confirm = useConfirm();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [allUsers, setAllUsers] = useState<IListUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [isUpgradingUser, setIsUpgradingUser] = useState<boolean>(false);

  const fetchAllUsers = async () => {
    try {
      const res = await getUsers({ perPage: -1, excludeSuperAdmins: true });
      setAllUsers(res.records);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllUsers();
  }, []);
  return (
    <Modal
      open={isShowingModal}
      onClose={() => {
        setIsShowingModal(false);
        setCurrentUser(null);
        setSelectedUser('');
      }}
      maxWidth="sm"
      title={currentUser ? `View User ${currentUser.userName} Details` : ''}
    >
      <Fade in={isShowingModal}>
        <div>
          {isLoadingData ? (
            <Loader position="centered" type="overlay" />
          ) : (
            <>
              {isUpgradingUser && <Loader position="centered" type="overlay" />}
              {currentUserUpdateData && currentUserUpdateData?.totalCount! > 0 && (
                <>
                  <Alert severity="info" sx={{ mb: 1, mt: 1 }}>
                    If you want to upgrade this user to super admin, please select a new user to
                    transfer these services.
                  </Alert>
                  <TextField
                    sx={{
                      mb: 1,
                    }}
                    fullWidth
                    select
                    required
                    label="User"
                    size="small"
                    onChange={({ target: { value } }) => {
                      setSelectedUser(value);
                    }}
                    value={selectedUser}
                    SelectProps={{
                      endAdornment: selectedUser && (
                        <InputAdornment
                          position="end"
                          sx={{
                            position: 'absolute',
                            right: 35,
                            cursor: 'pointer',
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faClose}
                            title="Clear"
                            onClick={() => setSelectedUser('')}
                          />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {allUsers?.map((user, index) => {
                      return (
                        <MenuItem key={`${index}`} value={user.userId}>
                          {user.userName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <Divider />
                </>
              )}
              <Stack mt={1}>
                <DetailList
                  title="Maintenance Services"
                  count={currentUserUpdateData?.scheduledServices?.count ?? 0}
                >
                  {currentUserUpdateData?.scheduledServices?.data?.map((service, index) => {
                    return (
                      <ListItemButton
                        key={service.scheduledServiceId}
                        onClick={() => {
                          history.push(`/services/maintenance/${service.scheduledServiceId}`);
                        }}
                      >
                        <ListItemText
                          primary={service.serviceDescription}
                          secondary={formatDate(service.serviceDate)}
                        />
                      </ListItemButton>
                    );
                  })}
                  {currentUserUpdateData?.scheduledServices?.data?.length === 0 && (
                    <ListItem>
                      <ListItemText primary="No maintenance services" />
                    </ListItem>
                  )}
                </DetailList>
                <Divider />
                <DetailList
                  title="Recurring Services"
                  count={currentUserUpdateData?.recurringServices?.count ?? 0}
                >
                  {currentUserUpdateData?.recurringServices?.data?.map((service, index) => {
                    return (
                      <ListItemButton
                        key={service.recurringServiceId}
                        onClick={() => {
                          history.push(
                            `/services/maintenance/recurring/${service.recurringServiceId}`
                          );
                        }}
                      >
                        <ListItemText primary={service.serviceDescription} />
                      </ListItemButton>
                    );
                  })}
                  {currentUserUpdateData?.recurringServices?.data?.length === 0 && (
                    <ListItem>
                      <ListItemText primary="No recurring services" />
                    </ListItem>
                  )}
                </DetailList>
                <Divider />
                <DetailList
                  title="One-Time Services"
                  count={currentUserUpdateData?.repairs?.count ?? 0}
                >
                  {currentUserUpdateData?.repairs?.data?.map((repair, index) => {
                    return (
                      <ListItemButton
                        key={repair.repairId}
                        onClick={() => {
                          history.push(`/services/ots/${repair.repairId}`);
                        }}
                      >
                        <ListItemText primary={repair.description} />
                      </ListItemButton>
                    );
                  })}
                  {currentUserUpdateData?.repairs?.data?.length === 0 && (
                    <ListItem>
                      <ListItemText primary="No one-time services" />
                    </ListItem>
                  )}
                </DetailList>
                <Divider />
                <DetailList
                  title="One-Time Service Visits"
                  count={currentUserUpdateData?.repairVisits?.count ?? 0}
                >
                  {currentUserUpdateData?.repairVisits?.data?.map((repair, index) => {
                    return (
                      <ListItem key={repair.repairVisitId}>
                        <ListItemText primary={formatDate(repair.visitDate)} />
                      </ListItem>
                    );
                  })}
                  {currentUserUpdateData?.repairVisits?.data?.length === 0 && (
                    <ListItem>
                      <ListItemText primary="No one-time service visits" />
                    </ListItem>
                  )}
                </DetailList>
                <Divider />
                <DetailList title="Tasks" count={currentUserUpdateData?.scheduledTasks?.count ?? 0}>
                  {currentUserUpdateData?.scheduledTasks?.data?.map((task, index) => {
                    return (
                      <ListItemButton
                        key={task.scheduledTaskId}
                        onClick={() => {
                          history.push(`/scheduling/tasks/${task.scheduledTaskId}`);
                        }}
                      >
                        <ListItemText
                          primary={task.taskType}
                          secondary={formatDate(task.whenScheduled)}
                        />
                      </ListItemButton>
                    );
                  })}
                  {currentUserUpdateData?.scheduledTasks?.data?.length === 0 && (
                    <ListItem>
                      <ListItemText primary="No tasks" />
                    </ListItem>
                  )}
                </DetailList>
                <Divider />
                <DetailList title="Sites" count={currentUserUpdateData?.sites?.count ?? 0}>
                  {currentUserUpdateData?.sites?.data?.map((site, index) => {
                    return (
                      <ListItemButton
                        key={site.siteId}
                        onClick={() => {
                          history.push(`/customers/${site.accountId}/sites?siteId=${site.siteId}`);
                        }}
                      >
                        <ListItemText primary={site.siteName} />
                      </ListItemButton>
                    );
                  })}
                  {currentUserUpdateData?.sites?.data?.length === 0 && (
                    <ListItem>
                      <ListItemText primary="No sites" />
                    </ListItem>
                  )}
                </DetailList>
              </Stack>
              <ModalSaveSection
                handleCancel={() => {
                  setIsShowingModal(false);
                  setCurrentUser(null);
                  setSelectedUser('');
                }}
                isSaveDisabled={
                  selectedUser
                    ? false
                    : !currentUserUpdateData?.isValidSuperAdminUpgradeWithoutNewUserId
                }
                submitLabel="Upgrade to Super Admin"
                submitIcon={faUpload}
                handleSave={async () => {
                  const result = await confirm(
                    'Are you sure you want to upgrade this user to super admin?'
                  );
                  if (!result) {
                    return;
                  }
                  setIsUpgradingUser(true);
                  try {
                    await upgradeUserToSuperAdmin(currentUser?.userId!, {
                      newUserId: selectedUser.length > 0 ? selectedUser : null,
                    });
                    setIsShowingModal(false);
                    setCurrentUser(null);
                    setSelectedUser('');
                    enqueueSnackbar(
                      `User ${currentUser?.userName} has been successfully upgraded to super admin.`,
                      {
                        variant: 'success',
                      }
                    );
                    fetchUsers();
                  } catch (err: any) {
                    enqueueSnackbar(
                      err?.Detail ?? `Error upgrading user to super admin. Please try again.`,
                      {
                        variant: 'error',
                      }
                    );
                  } finally {
                    setIsUpgradingUser(false);
                  }
                }}
              />
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

const DetailList = ({
  title,
  count,
  children,
}: {
  title: string;
  count: number;
  children: any;
}) => {
  return (
    <>
      <DisplayGroup label={`${title} (${count})`}>
        <List
          disablePadding
          dense
          sx={{
            maxHeight: '10rem',
            overflowY: 'auto',
          }}
        >
          {children}
        </List>
      </DisplayGroup>
    </>
  );
};
