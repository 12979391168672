import { FC } from 'react';
import { PaymentSetupsTable } from './payment-setups-table';
import { AdyenOnboarding, AdyenTransactions } from '../adyen';
import { Stack } from '@mui/material';

export const PaymentSetupsPage: FC = () => {
  return (
    <>
      <Stack gap={2}>
        <PaymentSetupsTable />
        <AdyenOnboarding />
        <AdyenTransactions />
      </Stack>
    </>
  );
};
