import { FC, useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Checkbox, Divider, FormControlLabel, Typography, Box, Tooltip } from '@mui/material';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
  Loader,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { IAccount } from '../../../models';
import { getAccounts } from '../../../fetch';
import { CustomersFilters } from '../../customers/CustomersFilters';
import { useDebounce } from '../../../hooks';

interface IRatesCustomersList {
  rateCode?: string | null;
  hasSelectedAll: boolean;
  setHasSelectedAll: (hasSelectedAll: boolean) => void;
  accountIds: string[];
  setAccountIds: (addedAccountIds: string[]) => void;
}

export const RatesCustomersList: FC<IRatesCustomersList> = ({
  hasSelectedAll,
  setHasSelectedAll,
  accountIds,
  setAccountIds,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [filters, setFilters] = useState<{
    filterLetter?: string;
    officeId?: string;
  } | null>({});
  const [selectedLetterOption, setSelectedLetterOption] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [isLoadingAll, setLoadingAll] = useState(false);

  const dataFetcher: GridDataFetcher<IAccount> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          filterLetter: selectedLetterOption,
          search: searchedValue,
          status: 'Active',
        };

        const res = await getAccounts(options);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading customers, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    [enqueueSnackbar, selectedLetterOption, searchedValue]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    setPage,
    sortColumn,
    sortDirection,
  } = useDataGrid<IAccount>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'rates-customers-grid',
      sortColumn: 'sortOrder',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  useDebounce(
    () => {
      // Bug Fix: When paged to something other than 0, reset pagination on search so that results are displayed
      setPage(0);
      setSearchedValue(searchValue);
    },
    800,
    [searchValue]
  );

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'checkbox',
        headerName: 'Select',
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IAccount>) => {
          const isChecked = accountIds.includes(params.row.accountId);
          return (
            <Checkbox
              checked={isChecked}
              sx={{ padding: 0 }}
              onChange={e => {
                if (isChecked) {
                  setAccountIds(accountIds.filter(id => id !== params.row.accountId));
                } else {
                  setAccountIds([...accountIds, params.row.accountId]);
                }
              }}
            />
          );
        },
      },
      {
        field: 'accountCode',
        headerName: 'Code',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'accountName',
        headerName: 'Customer',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'accountStreet',
        headerName: 'Account Street',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'accountCity',
        headerName: 'Account City',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'accountState',
        headerName: 'Account State',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'rateCode',
        headerName: 'Rate Code',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IAccount>) => {
          const { row: account } = params;
          return (
            <Tooltip
              key={`${account.accountId}-account`}
              placement="bottom"
              title={account.rates.map(r => r.code).join(', ')}
            >
              <span>{account.rateCode}</span>
            </Tooltip>
          );
        },
      },
    ];
  }, [accountIds, setAccountIds]);

  return (
    <>
      <Card>
        <CardTitle title="Select Customers for Rate" marginBottom={10} />
        {isLoadingAll && <Loader position="centered" type="overlay" />}
        <CustomersFilters
          isLoading={isLoading}
          setSelectedLetterOption={setSelectedLetterOption}
          selectedLetterOption={selectedLetterOption}
          isDisabled={isLoading}
          searchValue={searchValue}
          searchedValue={searchedValue}
          setSearchValue={setSearchValue}
          setSearchedValue={setSearchedValue}
          applyFilters={(clearFilters?: boolean) => {
            if (clearFilters) {
              setFilters({
                filterLetter: '',
              });
              setSearchValue('');
              setSearchedValue('');
            } else {
              setPage(0);
              setFilters({
                ...filters,
                filterLetter: selectedLetterOption,
              });
            }
          }}
        />
        <Divider />
        <FormControlLabel
          sx={{ ml: 0.25 }}
          control={
            <Checkbox
              checked={hasSelectedAll}
              onChange={async e => {
                try {
                  if (hasSelectedAll) {
                    setAccountIds([]);
                  } else {
                    setLoadingAll(true);
                    const options = {
                      perPage: -1,
                      status: 'Active',
                      sortBy: sortColumn,
                      sortDirection: sortDirection || 'asc',
                      filterLetter: selectedLetterOption,
                      search: searchedValue,
                    };

                    const res = await getAccounts(options);
                    setAccountIds(res.records.map((r: IAccount) => r.accountId));
                  }
                } catch (error) {
                  enqueueSnackbar(`Error selecting all customers, please try again.`, {
                    variant: 'error',
                  });
                } finally {
                  setLoadingAll(false);
                }
                setHasSelectedAll(!hasSelectedAll);
              }}
            />
          }
          label={
            <Box display="flex" alignItems="center" gap={1}>
              Select All <Typography variant="caption">({accountIds.length} Selected)</Typography>
            </Box>
          }
        />
        <Divider />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: IAccount) => row.accountId}
          rows={rows.map(row => ({ ...row, checkbox: '' }))}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
          mobileProps={{
            mobileDefaultAccessor: 'accountName',
          }}
        />
      </Card>
    </>
  );
};
