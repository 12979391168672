import { OpenInNew } from '@mui/icons-material';
import {
  Alert,
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  Collapse,
  SxProps,
  styled,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { Link, ExpandMoreButton, Loader } from '..';
import { getAgingReportBySite, getAgingReportByAccount } from '../../fetch';
import { IAccountAgingReport } from '../../models';
import { formatMoney, getCustomerDetailRoute } from '../../helpers';
import { UserContext } from '../../context';

interface ICreditLimitAlert {
  siteId?: string;
  accountId?: string;
  isAlwaysShown?: boolean;
  isExpanded?: boolean;
  shouldRefetch?: boolean;
  sx?: SxProps;
  containerShadow?: boolean;
  showLoader?: boolean;
}

interface ICreditLimitAlertStyles {
  showError?: boolean;
}

export const CreditLimitAlert: FC<ICreditLimitAlert> = ({
  siteId,
  accountId,
  isAlwaysShown = false,
  isExpanded,
  shouldRefetch,
  sx,
  containerShadow = false,
  showLoader,
}) => {
  const { hasQBInvoiceEntryType } = useContext(UserContext);
  const [expanded, setExpanded] = useState(true);
  const [isLoadingAgingReport, setIsLoadingAgingReport] = useState(false);
  const [agingReport, setAgingReport] = useState<IAccountAgingReport | null>(null);
  const isDelinquent =
    agingReport &&
    (agingReport?.aging30 > 0 || agingReport?.aging60 > 0 || agingReport?.aging90 > 0)
      ? true
      : false;
  const showError = agingReport?.overCreditLimit || isDelinquent ? true : false;

  const isMobile = useMediaQuery('(max-width: 899px)');

  useEffect(() => {
    if (isExpanded !== null && isExpanded !== undefined) {
      setExpanded(isExpanded as boolean);
    }
  }, [isExpanded]);

  const fetchAgingReport = async () => {
    setIsLoadingAgingReport(true);
    try {
      const res = siteId
        ? await getAgingReportBySite(siteId)
        : accountId
        ? await getAgingReportByAccount(accountId)
        : null;
      setAgingReport(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingAgingReport(false);
    }
  };
  useEffect(() => {
    let mounted = true;
    if ((siteId || accountId) && mounted) {
      fetchAgingReport();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, accountId]);

  // Refetch aging report if needed
  useEffect(() => {
    let mounted = true;
    if ((siteId || accountId) && mounted && shouldRefetch) {
      fetchAgingReport();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  if (showLoader && isLoadingAgingReport) {
    return <Loader type="overlay" position="centered" title="Loading..." />;
  }

  if ((!agingReport?.overCreditLimit && !isAlwaysShown) || isLoadingAgingReport) {
    return null;
  }

  return (
    <StyledCreditLimitAlert
      severity={showError ? 'error' : 'info'}
      classes={{
        message: classes.alertMessage,
        root: classes.alert,
      }}
      action={
        <ExpandMoreButton expand={expanded} setExpanded={setExpanded} className="print--none" />
      }
      sx={containerShadow ? { boxShadow: 1, ...sx } : sx}
      showError={hasQBInvoiceEntryType ? false : showError}
    >
      {hasQBInvoiceEntryType ? (
        <Collapse in={expanded} timeout="auto">
          <Box display="flex" justifyContent="center">
            <Typography align="center" fontWeight="bold" pt={1} pb={1}>
              Enable Pool360 Service Billing to use AR functionality.
            </Typography>
          </Box>
        </Collapse>
      ) : (
        // </Box>
        <>
          {(agingReport?.overCreditLimit || isDelinquent) && (
            <Box>
              <Typography sx={{ fontWeight: 'bold' }} data-testid="Credit.Text">
                {agingReport?.overCreditLimit &&
                  !isDelinquent &&
                  'Warning: Customer is over the credit limit.'}
                {!agingReport?.overCreditLimit &&
                  isDelinquent &&
                  'Warning: Customer has a delinquent balance.'}
                {agingReport?.overCreditLimit &&
                  isDelinquent &&
                  'Warning: Customer is over the credit limit and has a delinquent balance.'}{' '}
                {siteId && (
                  <Link to={`${getCustomerDetailRoute(agingReport?.accountId!)}`}>
                    Go to customer account record. <OpenInNew />
                  </Link>
                )}
              </Typography>
            </Box>
          )}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box marginTop={showError ? '1rem' : '0rem'}>
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={12} md={'auto'} className="col-print-auto">
                  <Item
                    label="Current"
                    value={agingReport?.agingCurrent || 0}
                    color={showError ? 'red' : undefined}
                  />
                </Grid>
                <Grid item xs={12} md={'auto'} className="col-print-auto">
                  <Item
                    label="30+ days"
                    value={agingReport?.aging30 || 0}
                    color={showError ? 'red' : undefined}
                  />
                </Grid>
                <Grid item xs={12} md={'auto'} className="col-print-auto">
                  <Item
                    label="60+ days"
                    value={agingReport?.aging60 || 0}
                    color={showError ? 'red' : undefined}
                  />
                </Grid>
                <Grid item xs={12} md={'auto'} className="col-print-auto">
                  <Item
                    label="90+ days"
                    value={agingReport?.aging90 || 0}
                    color={showError ? 'red' : undefined}
                  />
                </Grid>
                <Grid item xs={12} md={'auto'} className="col-print-auto">
                  <Divider
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    sx={{
                      height: '100% !important',

                      borderWidth: '1px',
                      borderColor: theme => theme.palette.grey[500],
                      color: theme => theme.palette.grey[500],
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={'auto'} className="col-print-auto">
                  <Item
                    isBold
                    label="Total"
                    value={agingReport?.balance || 0}
                    color={showError ? 'red' : undefined}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </>
      )}
    </StyledCreditLimitAlert>
  );
};

const Item = ({
  label,
  value,
  color,
  isBold,
}: {
  label: string;
  value: string | number;
  color?: string;
  isBold?: boolean;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={{ xs: 'space-between', md: 'center' }}
      flexDirection={{ xs: 'row', md: 'column' }}
    >
      <Typography sx={isBold ? { fontWeight: 'bold' } : {}}>{label}</Typography>
      <Divider
        flexItem
        sx={
          color === 'red'
            ? {
                borderColor: theme => theme.palette.error.main,
                color: theme => theme.palette.error.main,
              }
            : {}
        }
      />
      <Typography
        sx={isBold || color === 'red' ? { fontWeight: 'bold' } : {}}
        color={color === 'red' ? 'error' : 'inherit'}
      >
        {formatMoney(value)}
      </Typography>
    </Box>
  );
};

const PREFIX = 'CreditLimitAlert';

const classes = {
  alertMessage: `${PREFIX}-alertMessage`,
  alert: `${PREFIX}-alert`,
};

const StyledCreditLimitAlert = styled(Alert, {
  shouldForwardProp: prop => prop !== 'showError',
})<ICreditLimitAlertStyles>(({ theme, showError }) => ({
  [`&.${classes.alert}`]: {
    flexWrap: 'wrap',
    [theme.breakpoints.up(414)]: {
      flexWrap: 'nowrap',
    },
    '& .MuiAlert-icon': {
      order: 1,
      [theme.breakpoints.up('sm')]: {
        alignItems: showError ? 'flex-start' : 'center',
      },
    },
    '& .MuiAlert-action': {
      marginRight: 0,
      order: 2,
      [theme.breakpoints.up(414)]: { order: 3 },
    },
  },
  [`& .${classes.alertMessage}`]: {
    width: '100%',
    order: 3,
    [theme.breakpoints.up(414)]: { order: 2 },
    [theme.breakpoints.up(1400)]: {
      width: showError ? 'auto' : '60rem',
    },
  },
}));
