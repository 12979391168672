import { FC } from 'react';
import { Grid, Box, Typography, Slider, FormControlLabel, Checkbox, styled } from '@mui/material';

interface ITreatmentSlider {
  label: string;
  value: any;
  max: number;
  measure: string;
  setFieldValue: (val: any) => void;
  id: string;
  step: number;
  isBillable?: boolean;
  setBillable?: (val: boolean) => void;
  showBillable?: boolean;
}

export const TreatmentSlider: FC<ITreatmentSlider> = ({
  label,
  value,
  max,
  measure,
  setFieldValue,
  id,
  step,
  isBillable,
  setBillable,
  showBillable,
}) => {

  return (
    <Grid item xs={12} sx={{ padding: `0.75rem 1.75rem 0 !important` }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography id={`input-slider-${id}`} gutterBottom>
          {label}
        </Typography>
        {showBillable && (
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  setBillable && setBillable(!isBillable ?? false);
                }}
                checked={isBillable ?? false}
              />
            }
            label="Billable"
            value={isBillable ?? false}
          />
        )}
      </Box>
      <StyledSlider
        value={typeof value === 'number' ? value : 0}
        onChange={(e, val) => setFieldValue(val)}
        aria-labelledby={`input-slider-${id}`}
        marks={[
          {
            value: 0,
            label: `0/${measure}`,
          },
          {
            value: max,
            label: `${max}/${measure}`,
          },
        ]}
        min={0}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        getAriaValueText={val => `${val}/${measure}`}
        className={classes.slider}
      />
    </Grid>
  );
};

const PREFIX = 'TreatmentSlider';

const classes = {
  slider: `${PREFIX}-slider`
};

const StyledSlider = styled(Slider)(() => ({
  // styles for the labels on the front and end of the slider
  [`&.${classes.slider}`]: {
    '& span:nth-child(4)': {
      left: `4% !important`,
    },
    '& span:nth-child(6)': {
      left: `97% !important`,
    },
  }
}));