import {
  CardTitle,
  DisplayGroup,
  GridDataFetcher,
  Loader,
  ServerSideDataGrid,
  useDataGrid,
  Card,
} from '../../components';
import { Grid, Divider, Button, Box, Typography, useMediaQuery } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IBreadcrumb, ITransaction } from '../../models';
import { useHistory, useParams } from 'react-router-dom';
import {
  closeTransactionBatch,
  generateReport,
  getBatchTransactionTotal,
  getTransactionBatch,
  getTransactions,
  updateBatchTransaction,
} from '../../fetch';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { UserContext } from '../../context';
import { transactionGridColumns } from './transaction-grid-columns';
import { useConfirm } from '../../hooks';
import { downloadFile, hasCorrectUserPermissions } from '../../helpers';
import { Permissions } from '../../constants';
import { PostPaymentsPageDetailsFields } from './post-payments-page-details-fields';

export interface IPostPaymentPageDetails {
  setPageBreadCrumb?: (val: IBreadcrumb | undefined) => void;
}

export const PostPaymentPageDetails: FC<IPostPaymentPageDetails> = ({ setPageBreadCrumb }) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { transactionBatchId }: { transactionBatchId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [transactionTotal, setTransactionTotal] = useState<number | null>(null);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  const {
    isLoading: isLoadingBatch,
    data: batch,
    refetch: refetchTransactionBatch,
  } = useQuery('transactionBatch', () => getTransactionBatch(transactionBatchId), {
    onError: (d: any) => {
      history.push(redirect ?? '/payments');
      enqueueSnackbar(d?.Detail ?? 'An error occurred while retrieving this transaction', {
        variant: 'error',
      });
    },
  });

  const loadBatchTransactionTotal = async () => {
    const total = await getBatchTransactionTotal(transactionBatchId);
    setTransactionTotal(total);
  };

  useEffect(() => {
    if (!transactionBatchId) {
      return;
    }
    loadBatchTransactionTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionBatchId]);

  const dataFetcher: GridDataFetcher<ITransaction> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const res = await getTransactions({
          transactionBatchId: transactionBatchId,
          sortDirection: sortDirection || 'desc',
          sortBy: sortColumn || 'whenPosted',
          perPage,
          page: page + 1,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading transactions, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactionBatchId]
  );

  const {
    refetch: refetchTransactions,
    isLoading: isLoadingTransactions,
    ...gridProps
  } = useDataGrid<ITransaction>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'post-payments-grid',
      sortColumn: 'whenPosted',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  const handleRowEdit = useCallback(async (newRow: ITransaction) => {
    await updateBatchTransaction({
      transactionId: newRow.transactionId,
      checkNumber: newRow.reference,
      amount: newRow.amount,
    });
    loadBatchTransactionTotal();

    return newRow;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProcessRowUpdateError = useCallback(() => {
    enqueueSnackbar('An error occurred while saving the row data', {
      variant: 'error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseBatch = async () => {
    const result = await confirm('Are you sure you want to close this batch?');
    if (!result) {
      return;
    }
    try {
      setIsClosing(true);
      await closeTransactionBatch(transactionBatchId);
      enqueueSnackbar('Batch was successfully closed', {
        variant: 'success',
      });
      history.push(redirect ?? '/payments');
    } catch (e) {
      enqueueSnackbar('An error occurred while closing this batch', {
        variant: 'error',
      });
    } finally {
      setIsClosing(false);
    }
    refetchTransactionBatch();
  };

  const onReportDownload = async () => {
    try {
      setIsGeneratingReport(true);
      const res = await generateReport('61662720-8037-4F80-920E-289203F2DCA0', {
        parameters: [
          {
            reportParameterId: 'BD83E4B8-1BFA-4936-8A7C-30F7A4005D8F',
            value: transactionBatchId,
          },
        ],
      });
      downloadFile(res, 'test.pdf');
    } catch (e) {
      enqueueSnackbar('An error occurred while generating the report', {
        variant: 'error',
      });
    } finally {
      setIsGeneratingReport(false);
    }
  };

  const isLoading = isLoadingTransactions || isGeneratingReport;
  const isClosed = batch?.batchStatus === 'Closed';
  const isActive = batch?.batchStatus === 'Active';
  const isReadOnly = !hasCorrectUserPermissions(Permissions.EditClosedBatch, user!);

  useEffect(() => {
    if (setPageBreadCrumb) {
      return setPageBreadCrumb({
        text: 'Payments',
        title: 'Back to Payments',
        link: redirect ?? '/payments',
      });
    }
  }, [setPageBreadCrumb, redirect]);

  return (
    <Box marginTop="1rem">
      <Card>
        {(isLoading || isLoadingBatch || isClosing) && (
          <Loader type="overlay" position="centered" title="Loading..." />
        )}
        <CardTitle title="Post Payments" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <DisplayGroup label="Batch" labelId="batchType">
              {batch?.batchType}
            </DisplayGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <DisplayGroup label="Created" labelId="whenCreated">
              {batch?.whenCreated ? format(new Date(batch.whenCreated), 'M/d/yyyy h:mm:ss aa') : ''}{' '}
              {batch && <strong>by</strong>} {batch?.userName}
            </DisplayGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <DisplayGroup label="Status" labelId="batchStatus">
              <Typography
                sx={
                  isActive
                    ? { color: '#41D090', fontWeight: 'bold', fontSize: '14px' }
                    : { fontWeight: 'bold', fontSize: '14px' }
                }
              >
                {batch?.batchStatus}
              </Typography>
            </DisplayGroup>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <PostPaymentsPageDetailsFields
          transactionBatchId={transactionBatchId}
          loadBatchTransactionTotal={loadBatchTransactionTotal}
          refetchTransactions={refetchTransactions}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Box mt={isMobile ? 1 : 0} mb={isMobile ? 1 : 0}>
          <ServerSideDataGrid
            autoHeight
            getRowId={(row: ITransaction) => row.transactionId}
            columns={transactionGridColumns}
            editMode="row"
            processRowUpdate={handleRowEdit}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            hasMobileLayout
            mobileProps={{
              mobileCustomDefaultAccessor: (val: ITransaction) => {
                return val.reference;
              },
            }}
            {...gridProps}
          />
        </Box>
        <Box mb={1}>
          <Divider />
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            {transactionTotal !== null && (
              <DisplayGroup label="Batch Total">
                {transactionTotal >= 0 ? `$${transactionTotal}` : `($${transactionTotal})`}
              </DisplayGroup>
            )}
          </Box>
          <Box mt={1} display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
            {!isClosed && (
              <Button
                type="button"
                color="error"
                disabled={isReadOnly}
                size="small"
                onClick={onCloseBatch}
              >
                Close Batch
              </Button>
            )}
            <Button
              type="button"
              color="secondary"
              size="small"
              disabled={isReadOnly}
              onClick={onReportDownload}
            >
              Batch Report
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
