import {
  Alert,
  Box,
  Grid,
  Link,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  Button,
} from '@mui/material';
import { Stack } from '@mui/system';
import { TableActionsMenu, Card } from '../../../components';
import { handleCopyClick, styleGuideClientName } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

export const StyleGuideGridsOverview = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  return (
    <StyleGuidePage title="Grid Overview">
      <Grid container mb={2}>
        <Grid item xs={12} lg={8}>
          <Box mb={2}>
            <Alert severity="info">
              NOTE: This is a living style guide and is subject to grow and change. The following is
              partial documentation for the initial phase of the Kitchen Sink and will later be
              broken out into different sections with more details and lists of props.
            </Alert>
          </Box>
          <Typography mb={0.5} variant="h5" component="h2" color="primary">
            Implementation Types
          </Typography>
          <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
          <Typography mb={2}>
            In {styleGuideClientName}, there are currently 3 types of grid implementations.{' '}
            <ul className="my-3">
              <li className="mt-3">
                <strong>ServerSideDataGrid</strong>: <em>Special-case (manual filters)</em>. A
                custom table component that is based on the{' '}
                <Link href="https://mui.com/x/react-data-grid/" target="_blank">
                  MUI Data Grid
                </Link>{' '}
                component with built-in branding styles and defaults. This implementation is used
                for grids that have dependent filters (setting a filter affects the state or options
                of another filter).
                <Typography mt={0.5} mb={1.5}>
                  <Button variant="contained" color="primary" href="/customers" id="table-type-1">
                    Example
                  </Button>
                </Typography>
              </li>
              <li>
                <strong>SimpleDataGrid</strong>: A simple version of the ServerSideDataGrid
                component that is based on the{' '}
                <Link href="https://mui.com/x/react-data-grid/" target="_blank">
                  MUI Data Grid
                </Link>{' '}
                component with built-in branding styles and defaults. This implementation is used
                for simple grids that generally don't have filters or (sometimes) sorting with the
                capability of rendering a Mobile Table, if necessary.
                <Typography mt={1} mb={1.5}>
                  For a visual reference, go to the following example, edit a single service, and
                  click the questionmark icon to display a table of suggestions.
                </Typography>
                <Typography mt={0.5} mb={1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/services/maintenance"
                    id="table-type-2"
                  >
                    Example
                  </Button>
                </Typography>
              </li>
              <li className="mt-3">
                <strong>ServerSideDataGrid w/ Filters API</strong>:{' '}
                <em>Common (dynamic filters)</em>. A method of implementation of the
                ServerSideDataGrid component with dynamic filters generated from a filters API
                endpoint, instead of the FE defining individual fields. This implementation is used
                for standard grids that have independent filters (setting a filter doesn't affect
                the state or options of another filter).{' '}
                <Typography mt={0.5} mb={1.5}>
                  <Button variant="contained" color="primary" href="/leads" id="table-type-3">
                    Example
                  </Button>
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography mt={2} mb={0.5} variant="h5" component="h2" color="primary">
            Components & Filter Implementations
          </Typography>
          <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
          <Typography mb={1}>
            A typical grid is usually comprised of at least 2 components: ServerSideDataGrid and a
            FilterForm component. The following is a list of available components (new and
            deprecated) that could be used to make up a grid, as well as example links to specific
            instances and variants for each.
            <ul className="my-3">
              <li>
                <strong>SimpleDataGrid</strong>: For rendering a simple table of data with columns.
                <Typography mt={1} mb={1.5}>
                  For a visual reference, go to the following example, edit a single service, and
                  click the questionmark icon to display a table of suggestions.
                </Typography>
                <Stack mt={0.5} mb={1.5} gap={1} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    href="/services/maintenance"
                    id="simple-data-grid-example"
                  >
                    Example
                  </Button>
                </Stack>
              </li>
              <li className="mt-3">
                <strong>Pagination</strong>: For rendering pagination for a custom list of items
                (i.e. inventory search results) and should be wrapped in conditional logic so that
                it only shows when there are results.
                <Stack mt={0.5} mb={1.5} gap={1} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    href="/services/maintenance"
                    id="pagination-example"
                  >
                    Example
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/kitchen-sink/components/grids/pagination"
                    id="pagination-docs"
                  >
                    Docs
                  </Button>
                </Stack>
              </li>
              <li className="mt-3">
                <strong>TableLegend</strong>: For displaying a legend of icons at the bottom of a
                ServerSideDataGrid component.
                <Stack mt={0.5} mb={1.5} gap={1} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    href="/services/field-reports"
                    id="legend-example"
                  >
                    Example
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/kitchen-sink/components/grids/table-legend"
                    id="legend-docs"
                  >
                    Docs
                  </Button>
                </Stack>
              </li>
              <li className="mt-3">
                <strong>ServerSideDataGrid</strong>: For rendering a table of data with columns and
                built-in pagination.
                <Stack mt={0.5} mb={1.5} gap={1} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    href="/customers"
                    id="datagrid-example"
                  >
                    Example
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/kitchen-sink/components/grids/data-grid"
                    id="datagrid-docs"
                  >
                    Docs
                  </Button>
                </Stack>
              </li>
              <li className="mt-3">
                <strong>Tabs</strong>: For changing the results, columns, and even filters belonging
                to a grid instance.
                <Stack mt={0.5} mb={1.5} gap={1} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    href="/services/maintenance"
                    id="tabs-example"
                  >
                    Example
                  </Button>
                  {/* Uncomment when docs are expanded/created
                   <Button
                    variant="contained"
                    color="primary"
                    href="/kitchen-sink/tabs"
                    id="tabs-docs"
                  >
                    Docs
                  </Button> */}
                </Stack>
              </li>
              <li className="mt-3">
                <strong>Filters - Expand/Collapse</strong>: Implementation where a Filters button
                triggers expand/collapse functionality to show/hide filters. This is a common
                occurance and could likely be converted to a set of components, if there is spare
                time to do so.
                <Typography mt={0.5} mb={1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/services/maintenance"
                    id="collapse-example"
                  >
                    Example
                  </Button>
                </Typography>
              </li>
              <li className="mt-3">
                <strong>Filters - Letter Filter</strong>: Implementation of an alphabet letter
                filter for filtering a grid of results by the first letter of a column value.
                <Typography mt={0.5} mb={1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/customers"
                    id="letter-filter-example"
                  >
                    Example
                  </Button>
                </Typography>
              </li>
              <li className="mt-3">
                <strong>Filters - Custom Views</strong>: Implementation of a dropdown of custom
                views that controls the data and column display for a grid.
                <Typography mt={0.5} mb={1.5}>
                  <Button variant="contained" color="primary" href="/customers" id="views-example">
                    Example
                  </Button>
                </Typography>
              </li>
              <li className="mt-3">
                <strong>Feature - Inline Editing</strong>: Implementation of a grid of data with
                inline editing, either on-load or triggered by an Edit button. The following example
                is of the recurring payments implementation of inline editing.
                <Typography mt={0.5} mb={1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/online-payments/b1f50bad-7319-42b0-96a8-25a29713c2aa?redirect=/customers/b1f50bad-7319-42b0-96a8-25a29713c2aa/transactions"
                    id="views-example"
                  >
                    Example
                  </Button>
                </Typography>
              </li>
              <li className="mt-3">
                <strong>Feature - Row Drag-and-drop</strong>: Implementation of a grid of data with
                the ability to reorder rows via drag-and-drop functionality. The following example
                is of the <em>Customer</em> &gt; <em>Rates</em> implementation of drag-and-drop
                rows.
                <Typography mt={0.5} mb={1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/customers/c097a1aa-300c-4142-97ff-320c53f4a3e6/rates"
                    id="views-example"
                  >
                    Example
                  </Button>
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography mb={0.5} variant="h5" component="h2" color="primary">
            Actions Column
          </Typography>
          <Divider sx={{ marginBottom: theme => theme.spacing(1) }} />
          <Typography mb={2}>
            Based on our standard grid practice, all grids that have links should have an{' '}
            <strong>actions column</strong>.
          </Typography>
          <Typography mb={2}>
            NOTE: To accommodate legacy/power users, certain grids will have inline column links
            (i.e. Site Name value is clickable to go to the Site detail screen, etc.).
          </Typography>
          <Typography mb={2}>
            Action buttons placed in the actions column should use the{' '}
            <Link href="https://mui.com/material-ui/react-button/#icon-button" target="_blank">
              MUI Icon Button
            </Link>{' '}
            component or the MUI Button <strong>text</strong> variant and not the{' '}
            <strong>contained</strong> variant. When using Icon Buttons, action buttons should be
            rendered with title attributes further describing their actions to provide tooltips and
            accommodate accessibiity (i.e. Edit Icon Buttons should have a title attribute of Edit
            or Edit Services, etc.). Action buttons should have appropriate coloring (i.e. Delete
            should be error colored), otherwise they should be <strong>primary</strong> colored.
          </Typography>
          <Typography mb={2}>
            NOTE: Delete is not a standard action on lists/grids in this project and should only be
            added based on legacy functionality. Delete is otherwise presented on detail pages.
          </Typography>
          <Box mb={1}>
            <Card>
              <Box
                textAlign="right"
                display="flex"
                justifyContent="flex-end"
                className="print--none"
              >
                <Stack direction="row" gap={1}>
                  <IconButton color="primary" title="Edit Test Item" onClick={async () => {}}>
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </IconButton>
                  <IconButton color="error" title="Delete Test Item" onClick={async () => {}}>
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </IconButton>
                </Stack>
              </Box>
            </Card>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Box textAlign="right" display="flex" justifyContent="flex-end"
            className="print--none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;Stack direction="row" gap=&#123;1&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;IconButton color="primary" title="Edit Test
            Item" onClick=&#123;async () =&gt; enqueueSnackbar('Edit Action', &#123; variant:
            'info', &#125;) &#125; &gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FontAwesomeIcon
            icon=&#123;faEdit&#125; size="sm" /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/IconButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;IconButton color="error" title="Delete Test
            Item" onClick=&#123;async () =&gt; enqueueSnackbar('Delete Action', &#123; variant:
            'error', &#125;) &#125; &gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FontAwesomeIcon
            icon=&#123;faTrash&#125; size="sm" /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/IconButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/Stack&gt;
            <br />
            &lt;/Box&gt;
          </CodeBlock>
          <Typography mt={2} mb={2}>
            When there are many action buttons and many columns, the actions column should render a
            context menu with a kebab icon. Options in the menu don't need icons if they are all of
            the same type (i.e. All context menu options are of an edit type, so no edit icon is
            rendered).
          </Typography>
          <Box mb={1}>
            <Card>
              <Box
                textAlign="right"
                display="flex"
                justifyContent="flex-end"
                className="print--none"
              >
                <TableActionsMenu id="test-actions">
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>Edit Service</ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>Edit Recurring Service</ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>View Site</ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>Edit Site</ListItemButton>
                    </ListItem>
                  </Link>
                </TableActionsMenu>
              </Box>
            </Card>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Box textAlign="right" display="flex" justifyContent="flex-end"
            className="print--none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;TableActionsMenu id="service-actions"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Edit Service
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Edit Recurring Service
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            View Site
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Edit Site
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/TableActionsMenu&gt;
            <br />
            &lt;/Box&gt;
          </CodeBlock>
          <Typography mt={2} mb={2}>
            Color coding in a context menu should be the same as action buttons in the actions
            column. Delete actions should be last in the context menu and should be proceeded by a
            divider.
          </Typography>
          <Box mb={1}>
            <Card>
              <Box
                textAlign="right"
                display="flex"
                justifyContent="flex-end"
                className="print--none"
              >
                <TableActionsMenu id="test-action-icons">
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                        Edit Service
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                        Edit Recurring Service
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEye} style={{ marginRight: '.5rem' }} />
                        View Site
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link href="#" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                        Edit Site
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Divider sx={{ borderColor: theme => theme.palette.dividers.grey }} />
                  <Link
                    href="#"
                    underline="none"
                    color="error"
                    sx={{ '&&:hover': { color: theme => theme.palette.error.dark } }}
                  >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faTrash} style={{ marginRight: '.5rem' }} />
                        Delete Service
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </TableActionsMenu>
              </Box>
            </Card>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Box textAlign="right" display="flex" justifyContent="flex-end"
            className="print--none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;TableActionsMenu id="service-actions"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faEdit&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;Edit Service
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faEdit&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;Edit Recurring Service
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faEye&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;View Site
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link href="#" underline="none"&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faEdit&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;Edit Site
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Divider sx=&#123;&#123; borderColor: theme =&gt;
            theme.palette.dividers.grey &#125;&#125; /&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;Link href="#" underline="none" color="error" sx=
            &#123;&#123; '&&:hover': &#123; color: theme =&gt; theme.palette.error.dark &#125;
            &#125;&#125;&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItem disablePadding&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &lt;FontAwesomeIcon icon=&#123;faTrash&#125; style=&#123;&#123; marginRight: '.5rem'
            &#125;&#125; /&gt;Delete Service
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItemButton&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ListItem&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/Link&gt;
            <br />
            &nbsp;&nbsp;&nbsp;&lt;/TableActionsMenu&gt;
            <br />
            &lt;/Box&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
