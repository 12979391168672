import { TextField as MuiTextField, Chip, Autocomplete, styled } from '@mui/material';
import { useState, useMemo, FC } from 'react';
import { useQuery } from 'react-query';
import { IRecurringService } from '../../models';
import { getRecurringServices } from '../../fetch';

interface IRecurringServicesAutoComplete {
  apiFilters: any;
  handleChange: (val: string[]) => void;
}

export const RecurringServicesAutoComplete: FC<IRecurringServicesAutoComplete> = ({
  apiFilters,
  handleChange,
}) => {
  const { isLoading: isLoadingRecurringServices, data: recurringServices } = useQuery(
    ['send-email-recurring-services'],
    async () => getRecurringServices(apiFilters)
  );
  const options = useMemo(() => recurringServices?.records || [], [recurringServices]);
  const [selectedOptions, setSelectedOptions] = useState<IRecurringService[]>([]);
  const handleDelete = (option: IRecurringService) => {
    setSelectedOptions(prev =>
      prev.filter(t => t.recurringServiceId !== option.recurringServiceId)
    );
    handleChange(
      selectedOptions
        .filter(t => t.recurringServiceId !== option.recurringServiceId)
        .map(o => o.recurringServiceId)
    );
  };
  return (
    <Autocomplete
      value={selectedOptions}
      multiple
      onChange={(event, newValue: IRecurringService[]) => {
        if (newValue && newValue?.length) {
          setSelectedOptions(newValue);
          handleChange(newValue.map(o => o.recurringServiceId));
        } else {
          setSelectedOptions([]);
          handleChange([]);
        }
      }}
      id="recurringServiceIds"
      options={options}
      getOptionLabel={(o: IRecurringService) => {
        return `${o.siteDescription} - ${o.serviceType} - ${o.frequency}`;
      }}
      renderOption={(props, o: IRecurringService) => {
        return (
          <li {...props} key={o.recurringServiceId}>
            {o.siteDescription} - {o.serviceType} - {o.frequency}
          </li>
        );
      }}
      renderTags={tags =>
        tags.map(tag => (
          <StyledChip
            key={tag.recurringServiceId}
            label={`${tag.siteDescription} - ${tag.serviceType} - ${tag.frequency}`}
            variant="outlined"
            onDelete={() => {
              handleDelete(tag);
            }}
          />
        ))
      }
      disabled={isLoadingRecurringServices}
      renderInput={params => {
        return (
          <MuiTextField
            {...params}
            size="medium"
            name="recurringServiceIds"
            autoComplete="none"
            disabled={isLoadingRecurringServices}
            label={isLoadingRecurringServices ? 'Loading...' : 'Recurring Service'}
            variant="outlined"
            placeholder="Select Recurring Service(s)"
          />
        );
      }}
    />
  );
};

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette.primary.main,
  borderRight: 'none',
  textOverflow: 'ellipsis',
  maxWidth: '175px',

  '&& .MuiChip-deleteIcon': {
    fontSize: '30px',
    marginRight: '-14px',
    color: theme.palette.primary.main,
  },
}));
