import { Autocomplete, Chip, SxProps, TextField, styled } from '@mui/material';
import { FC } from 'react';
import { UserAvatar } from '..';
import { IListUser } from '../../models';
import clsx from 'clsx';
import { IColorSetMap } from '../../models/colors';

interface IUserFilterProps {
  className?: string;
  users: IListUser[];
  selectedUsers: IListUser[];
  onDelete: (user: IListUser) => unknown;
  onChange: (users: IListUser[]) => unknown;
  userColorMap?: IColorSetMap;
  isSmall?: boolean;
  sx?: SxProps;
}

export const UserFilter: FC<IUserFilterProps> = ({
  className,
  users,
  selectedUsers,
  onDelete,
  onChange,
  userColorMap,
  isSmall,
  sx,
}) => {

  return (
    <StyledAutocomplete
      fullWidth
      value={selectedUsers}
      multiple
      disableClearable
      className={clsx(classes.cardBody, className)}
      sx={sx}
      onChange={(_event, newValue: unknown) => {
        let selectedUsers = newValue as IListUser[];
        if (selectedUsers && selectedUsers.length > 0) {
          onChange?.(selectedUsers);
        } else {
          onChange?.([]);
        }
      }}
      options={users.filter(t => !selectedUsers.map(t => t.userId).includes(t.userId))}
      getOptionLabel={(option: unknown) => {
        const user = option as IListUser;
        return user.userName;
      }}
      renderOption={(props, option: unknown) => {
        let o = option as IListUser;
        return (
          <li {...props} key={o.userId}>
            {o.userName}
          </li>
        );
      }}
      renderTags={users =>

        users.map(user => (
          <Chip
            key={(user as IListUser).userId}
            avatar={
              <UserAvatar
                userName={(user as IListUser).userName}
              />
            }
            label={(user as IListUser).userName}
            variant="outlined"
            onDelete={() => onDelete?.(user as IListUser)}
            className={classes.chip}
            classes={{ root: classes.chipRoot }}
            style={{ borderColor: userColorMap?.[(user as IListUser).userId]?.backgroundColor }}
          />
        ))
      }
      renderInput={params => {
        return (
          <TextField
            {...params}
            size={isSmall ? 'small' : undefined}
            fullWidth
            autoComplete="none"
            variant="outlined"
            label="Users"
            placeholder={selectedUsers.length ? '' : 'Select a user'}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'user-select'
            }}
          />
        );
      }}
    />
  );
};


const PREFIX = 'UserFilter';

const classes = {
  header: `${PREFIX}-header`,
  container: `${PREFIX}-container`,
  map: `${PREFIX}-map`,
  card: `${PREFIX}-card`,
  cardBody: `${PREFIX}-cardBody`,
  chip: `${PREFIX}-chip`,
  chipRoot: `${PREFIX}-chipRoot`
};

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .${classes.header}`]: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },

  [`& .${classes.container}`]: {
    padding: 0,
  },

  [`& .${classes.map}`]: {
    marginTop: theme.spacing(4),
  },

  [`& .${classes.card}`]: {},

  [`&.${classes.cardBody}`]: {
    borderRadius: theme.spacing(0.5),
  },

  [`& .${classes.chip}`]: {
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.primary.main,
    borderRight: 'none',
    textOverflow: 'ellipsis',
    maxWidth: '175px',
  },

  [`& .${classes.chipRoot}`]: {
    '& .MuiChip-deleteIcon': {
      fontSize: '30px',
      marginRight: '-14px',
      color: theme.palette.primary.main,
    },
  }
}));