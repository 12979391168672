import { useHistory } from 'react-router-dom';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Stack,
  MenuItem,
  Select,
  Pagination,
  TextField,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SetStateAction, useContext, useState } from 'react';
import { defaultUnsavedChangesMessage, Paths } from '../../../constants';
import { useSnackbar } from 'notistack';
import {
  DatePicker,
  SelectAsync,
  Wysiwyg,
  Card,
  Loader,
  ConfirmPrompt,
  CardTitle,
  PrimaryCard,
  ModalSaveSection as SaveSection,
} from '../../../components';
import {
  getAccountRecipients,
  getAccountRecipientsByServiceDate,
  getAccountRecipientsBySanitizer,
  getAccountRecipientsByRate,
  getAccountRecipientsByServiceType,
  getAccountRecipientsByEquipmentTypeId,
  getAccountRecipientsWithOtsAndNoWeekly,
  getEmailTemplate,
  getEmailTemplates,
  getPoolEquipmentSanitizers,
  getRates,
  getTechnicianUsers,
  sendCommunication,
  listServiceTypes,
  getEquipmentTypes,
} from '../../../fetch';
import {
  IEmailTemplate,
  IRecipient,
  IServiceType,
  IResponse,
  ITechnicianUser,
  IPoolEquipmentSanitizer,
  IAccountRate,
  IEquipmentType,
} from '../../../models';
import { UserContext } from '../../../context';
import { deepEqual } from 'fast-equals';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';

const minBodyTemplateLength = 20 + '<P></P>'.length;
const FORM_VALIDATION = Yup.object().shape({
  subjectTemplate: Yup.string()
    .required('Subject is required')
    .min(5, 'Subject must be at least 5 characters')
    .max(100, 'Subject must be at most 100 characters'),
  bodyTemplate: Yup.string()
    .required('Body is required')
    .min(minBodyTemplateLength, 'Body must be at least 20 characters'),
  audienceId: Yup.number().required('Audience is required'),
  recipients: Yup.array().min(1, 'at least 1 recipient').required('required'),
  technicianId: Yup.string().nullable(),
  serviceDate: Yup.date().nullable(),
});

const styles = {
  errorText: {
    color: 'red',
    size: 'small',
  },
};

export const MassSendPage = () => {
  // Constants
  const LOCAL_DEFAULT_PAGE_SIZE = 50;
  const API_DEFAULT_RECIPIENTS_PER_PAGE = 1000;

  const { user } = useContext(UserContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [currentTemplateBody, setCurrentTemplateBody] = useState<string | null>(null);
  const [currentTemplateSubject, setCurrentTemplateSubject] = useState<string | null>(null);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);

  const [potentialRecipients, setPotentialRecipients] = useState<IRecipient[]>([]);
  const [currentPageOfRecipients, setCurrentPageOfRecipients] = useState<IRecipient[]>([]);
  const [potentialRecipientsCount, setPotentialRecipientsCount] = useState<number>(0);

  const [isLoadingRecipients, setIsLoadingRecipients] = useState(false);

  const [selectedAudienceOption, setSelectedAudienceOption] = useState<number>(0);
  const [accountStatusFilter, setAccountStatusFilter] = useState<number>(1);
  const [accountStatusFilterIsVisible, setAccountStatusFilterIsVisible] = useState(Boolean);

  const [serviceDateFilter, setServiceDateFilter] = useState<Date | null>(null);
  const [serviceDateFilterIsVisible, setServiceDateFilterIsVisible] = useState(Boolean);

  const [technicianFilter, setTechnicianFilter] = useState<string | null>(null);
  const [technicianFilterIsVisible, setTechnicianFilterIsVisible] = useState(Boolean);

  const [sanitizerFilter, setSanitizerFilter] = useState<string>('');
  const [sanitizerFilterIsVisible, setSanitizerFilterIsVisible] = useState(Boolean);

  const [rateFilter, setRateFilter] = useState<string>('');
  const [rateFilterIsVisible, setRateFilterIsVisible] = useState(Boolean);

  const [serviceTypeFilter, setServiceTypeFilter] = useState<string>('');
  const [serviceTypeFilterIsVisible, setServiceTypeFilterIsVisible] = useState(Boolean);

  const [equipmentTypeFilter, setEquipmentTypeFilter] = useState<string>('');
  const [equipmentTypeFilterIsVisible, setEquipmentTypeFilterIsVisible] = useState(Boolean);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize] = useState(LOCAL_DEFAULT_PAGE_SIZE);

  const getAllTechnicians = async () => {
    return getTechnicianUsers({ perPage: 1000 });
  };
  const handleRecipientsPageChange = (event: any, page: number) => {
    changePage(page);
  };

  const changePage = (newPage: number) => {
    setCurrentPage(newPage);
    setCurrentPageOfRecipients(
      potentialRecipients.slice((newPage - 1) * pageSize, newPage * pageSize)
    );
  };

  const setData = (recipients: IRecipient[]) => {
    setPotentialRecipients(recipients);
    setPotentialRecipientsCount(recipients.length);

    let pages = Math.floor(recipients.length / pageSize);
    if (recipients.length % pageSize) {
      pages = pages + 1;
    }
    setTotalPages(pages);
    setCurrentPage(1);
    setCurrentPageOfRecipients(recipients.slice(0, pageSize));
  };

  const loadAllRecipients = async (accountStatus?: number) => {
    const getRecipientsRequest: {
      officeId?: string | null;
      accountStatus?: number;
      perPage?: number | 0;
    } = {
      officeId: user?.officeId as string,
      accountStatus: accountStatus,
      perPage: API_DEFAULT_RECIPIENTS_PER_PAGE,
    };
    setIsLoadingRecipients(true);
    try {
      const recipients = await getAccountRecipients(getRecipientsRequest);
      if (recipients.totalRecordCount > 0) {
        const selections: IRecipient[] = recipients.records.map(item => ({
          ...item,
          isSelected: true,
        }));
        setData(selections);
      }
    } finally {
      setIsLoadingRecipients(false);
    }
  };

  const loadRecipientsByServiceDate = async (serviceDate: Date) => {
    const getRecipientsRequest: {
      officeId?: string | null;
      serviceDate: Date | null;
      perPage?: number | 0;
    } = {
      officeId: user?.officeId as string,
      serviceDate: serviceDate,
      perPage: API_DEFAULT_RECIPIENTS_PER_PAGE,
    };
    setIsLoadingRecipients(true);
    try {
      const recipients = await getAccountRecipientsByServiceDate(getRecipientsRequest);
      if (recipients.totalRecordCount > 0) {
        const selections: IRecipient[] = recipients.records.map(item => ({
          ...item,
          isSelected: true,
        }));
        setData(selections);
      }
    } finally {
      setIsLoadingRecipients(false);
    }
  };

  const loadRecipientsByServiceDateAndTech = async (
    serviceDate: Date | null,
    technicianFilter: string | null
  ) => {
    const getRecipientsRequest: {
      officeId?: string | null;
      serviceDate: Date | null;
      technicianId?: string | null;
      perPage?: number | 0;
    } = {
      officeId: user?.officeId as string,
      serviceDate: serviceDate,
      technicianId: technicianFilter,
      perPage: API_DEFAULT_RECIPIENTS_PER_PAGE,
    };
    setIsLoadingRecipients(true);
    try {
      const recipients = await getAccountRecipientsByServiceDate(getRecipientsRequest);
      if (recipients.totalRecordCount > 0) {
        const selections: IRecipient[] = recipients.records.map(item => ({
          ...item,
          isSelected: true,
        }));
        setData(selections);
      }
    } finally {
      setIsLoadingRecipients(false);
    }
  };

  const loadRecipientsBySanitizer = async (sanitizerFilter: string) => {
    const getRecipientsRequest: {
      officeId?: string | null;
      sanitizerName: string | null;
      perPage?: number | 0;
    } = {
      officeId: user?.officeId as string,
      sanitizerName: sanitizerFilter,
      perPage: API_DEFAULT_RECIPIENTS_PER_PAGE,
    };
    setIsLoadingRecipients(true);
    try {
      const recipients = await getAccountRecipientsBySanitizer(getRecipientsRequest);
      if (recipients.totalRecordCount > 0) {
        const selections: IRecipient[] = recipients.records.map(item => ({
          ...item,
          isSelected: true,
        }));
        setData(selections);
      }
    } finally {
      setIsLoadingRecipients(false);
    }
  };

  const loadRecipientsByRate = async (rateFilter: string) => {
    const getRecipientsRequest: {
      officeId?: string | null;
      rateId: string | null;
      perPage?: number | 0;
    } = {
      officeId: user?.officeId as string,
      rateId: rateFilter,
      perPage: API_DEFAULT_RECIPIENTS_PER_PAGE,
    };
    setIsLoadingRecipients(true);
    try {
      const recipients = await getAccountRecipientsByRate(getRecipientsRequest);
      if (recipients.totalRecordCount > 0) {
        const selections: IRecipient[] = recipients.records.map(item => ({
          ...item,
          isSelected: true,
        }));
        setData(selections);
      }
    } finally {
      setIsLoadingRecipients(false);
    }
  };

  const loadRecipientsByServiceType = async (serviceTypeFilter: string) => {
    const getRecipientsRequest: {
      officeId?: string | null;
      serviceTypeId: string | null;
      perPage?: number | 0;
    } = {
      officeId: user?.officeId as string,
      serviceTypeId: serviceTypeFilter,
      perPage: API_DEFAULT_RECIPIENTS_PER_PAGE,
    };
    setIsLoadingRecipients(true);
    try {
      const recipients = await getAccountRecipientsByServiceType(getRecipientsRequest);
      if (recipients.totalRecordCount > 0) {
        const selections: IRecipient[] = recipients.records.map(item => ({
          ...item,
          isSelected: true,
        }));
        setData(selections);
      }
    } finally {
      setIsLoadingRecipients(false);
    }
  };

  const loadRecipientsByEquipmentType = async (equipmentTypeFilter: string) => {
    const getRecipientsRequest: {
      officeId?: string | null;
      equipmentTypeId: string | null;
      perPage?: number | 0;
    } = {
      officeId: user?.officeId as string,
      equipmentTypeId: equipmentTypeFilter,
      perPage: API_DEFAULT_RECIPIENTS_PER_PAGE,
    };
    setIsLoadingRecipients(true);
    try {
      const recipients = await getAccountRecipientsByEquipmentTypeId(getRecipientsRequest);
      if (recipients.totalRecordCount > 0) {
        const selections: IRecipient[] = recipients.records.map(item => ({
          ...item,
          isSelected: true,
        }));
        setData(selections);
      }
    } finally {
      setIsLoadingRecipients(false);
    }
  };

  const loadRecipientsWithOtsButNoWeekly = async () => {
    const getRecipientsRequest: {
      officeId?: string | null;
      perPage?: number | 0;
    } = {
      officeId: user?.officeId as string,
      perPage: API_DEFAULT_RECIPIENTS_PER_PAGE,
    };
    setIsLoadingRecipients(true);
    try {
      const recipients = await getAccountRecipientsWithOtsAndNoWeekly(getRecipientsRequest);
      if (recipients.totalRecordCount > 0) {
        const selections: IRecipient[] = recipients.records.map(item => ({
          ...item,
          isSelected: true,
        }));
        setData(selections);
      }
    } finally {
      setIsLoadingRecipients(false);
    }
  };

  const clearRecipients = () => {
    setPotentialRecipients([]);
    setCurrentPageOfRecipients([]);
    setPotentialRecipientsCount(0);
  };

  const loadRecipientListByAudienceFilters = async (audienceOption: number) => {
    clearRecipients();
    switch (audienceOption) {
      case 1:
        loadAllRecipients(accountStatusFilter);
        break;
      case 2:
        if (serviceDateFilter === null) {
          enqueueSnackbar('Please select a service date.', {
            variant: 'error',
          });
        } else {
          loadRecipientsByServiceDate(serviceDateFilter);
        }
        break;
      case 3:
        loadRecipientsByServiceDateAndTech(serviceDateFilter, technicianFilter);
        break;
      case 4:
        loadRecipientsBySanitizer(sanitizerFilter);
        break;
      case 5:
        loadRecipientsByRate(rateFilter);
        break;
      case 6:
        loadRecipientsByServiceType(serviceTypeFilter);
        break;
      case 7:
        loadRecipientsByEquipmentType(equipmentTypeFilter);
        break;
      case 8:
        loadRecipientsWithOtsButNoWeekly();
        break;
      default:
        break;
    }
  };

  const handleAudienceChange = (audienceOption: number) => {
    clearRecipients();
    setSelectedAudienceOption(audienceOption);
    switch (audienceOption) {
      case 0:
      case 6:
      case 8:
        setAccountStatusFilterIsVisible(false);
        setServiceDateFilterIsVisible(false);
        setTechnicianFilterIsVisible(false);
        setSanitizerFilterIsVisible(false);
        setRateFilterIsVisible(false);
        setServiceTypeFilterIsVisible(false);
        setEquipmentTypeFilterIsVisible(false);
        setServiceDateFilter(null);
        setTechnicianFilter(null);
        break;
      case 1: //AllAccounts
        setAccountStatusFilterIsVisible(true);
        setServiceDateFilterIsVisible(false);
        setTechnicianFilterIsVisible(false);
        setSanitizerFilterIsVisible(false);
        setRateFilterIsVisible(false);
        setServiceTypeFilterIsVisible(false);
        setEquipmentTypeFilterIsVisible(false);
        setServiceDateFilter(null);
        setTechnicianFilter(null);
        break;
      case 2: //ByServiceDate
        setAccountStatusFilterIsVisible(false);
        setServiceDateFilterIsVisible(true);
        setTechnicianFilterIsVisible(false);
        setSanitizerFilterIsVisible(false);
        setRateFilterIsVisible(false);
        setServiceTypeFilterIsVisible(false);
        setEquipmentTypeFilterIsVisible(false);
        setTechnicianFilter(null);
        break;
      case 3: //ByServiceDateAndTechId
        setAccountStatusFilterIsVisible(false);
        setServiceDateFilterIsVisible(true);
        setTechnicianFilterIsVisible(true);
        setSanitizerFilterIsVisible(false);
        setRateFilterIsVisible(false);
        setServiceTypeFilterIsVisible(false);
        setEquipmentTypeFilterIsVisible(false);
        break;
      case 4: //BySanitizer
        setAccountStatusFilterIsVisible(false);
        setServiceDateFilterIsVisible(false);
        setTechnicianFilterIsVisible(false);
        setSanitizerFilterIsVisible(true);
        setRateFilterIsVisible(false);
        setServiceTypeFilterIsVisible(false);
        setEquipmentTypeFilterIsVisible(false);
        setServiceDateFilter(null);
        setTechnicianFilter(null);
        break;
      case 5: //ByRate
        setAccountStatusFilterIsVisible(false);
        setServiceDateFilterIsVisible(false);
        setTechnicianFilterIsVisible(false);
        setSanitizerFilterIsVisible(false);
        setRateFilterIsVisible(true);
        setServiceTypeFilterIsVisible(false);
        setEquipmentTypeFilterIsVisible(false);
        setServiceDateFilter(null);
        setTechnicianFilter(null);
        break;
      case 7: //ByEquipmentType
        setAccountStatusFilterIsVisible(false);
        setServiceDateFilterIsVisible(false);
        setTechnicianFilterIsVisible(false);
        setSanitizerFilterIsVisible(false);
        setRateFilterIsVisible(false);
        setServiceTypeFilterIsVisible(false);
        setEquipmentTypeFilterIsVisible(true);
        setServiceDateFilter(null);
        setTechnicianFilter(null);
        break;
      default:
        setSelectedAudienceOption(0);
        setAccountStatusFilterIsVisible(false);
        setServiceDateFilterIsVisible(false);
        setTechnicianFilterIsVisible(false);
        setSanitizerFilterIsVisible(false);
        setRateFilterIsVisible(false);
        setServiceTypeFilterIsVisible(false);
        setEquipmentTypeFilterIsVisible(false);
        setServiceDateFilter(null);
        setTechnicianFilter(null);
        break;
    }
  };

  const handleRecipientSelection = (recipient: IRecipient, checked: boolean) => {
    const modifyProperty = (arr: IRecipient[], targetId: string, newProperty: boolean) => {
      const targetObj = arr.find(obj => obj.id === targetId);
      if (targetObj) {
        targetObj.isSelected = newProperty;
      }
    };

    let arr = [...potentialRecipients];
    modifyProperty(arr, recipient.id, checked);
    setPotentialRecipients(arr);
  };

  const loadTemplateDetails = async (emailTemplateId: string) => {
    if (emailTemplateId === 'None') return;
    setIsLoadingTemplate(true);
    try {
      const template = await getEmailTemplate(emailTemplateId!);
      setCurrentTemplateBody(template.emailTemplateBody);
      setCurrentTemplateSubject(template.subject);
    } catch (error) {
      enqueueSnackbar(`Error loading template, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingTemplate(false);
    }
  };

  const getMassEmailTemplates = async () => {
    let data: IResponse<IEmailTemplate[]> = await getEmailTemplates({
      event: 'M',
      officeId: user?.officeId,
      perPage: 50,
    });

    const defaultTemplate: IEmailTemplate = {
      emailTemplateName: 'None',
      emailTemplateId: 'None',
      sortOrder: 0,
      emailTemplateEventDescription: '',
      subject: '',
      emailBccs: [],
    };

    data.records.unshift(defaultTemplate);

    return data;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          audienceId: selectedAudienceOption ?? 0,
          officeId: user?.officeId ?? '',
          bodyTemplate: currentTemplateBody ?? '',
          subjectTemplate: currentTemplateSubject ?? '',
          bccs: '',
          recipients:
            potentialRecipients.filter(item => item.isSelected).map(item => item.id) ?? undefined,
          technicianId: technicianFilter ?? undefined,
          serviceDate: serviceDateFilter ?? undefined,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async values => {
          try {
            setIsUpdating(true);
            await sendCommunication({
              ...values,
            });
            enqueueSnackbar('Email Request was sent successfully!', {
              variant: 'success',
            });
            history.replace('/setup/communication');
          } catch (err: any) {
            enqueueSnackbar(
              err?.Detail || `Error sending communication, please try again, if you dare`,
              { variant: 'error' }
            );
          } finally {
            setIsUpdating(false);
          }
        }}
      >
        {({
          isValid,
          dirty,
          initialValues,
          values,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setTouched,
        }) => {
          return (
            <>
              <ConfirmPrompt
                when={!deepEqual(initialValues, values) && !isSubmitting && !isLoadingTemplate}
                message={defaultUnsavedChangesMessage}
              />
              <Form onSubmit={handleSubmit}>
                <Card>
                  <CardTitle title="Communication Dashboard">
                    {(isLoadingTemplate || isUpdating || isLoadingRecipients) && (
                      <Loader position="centered" type="overlay" />
                    )}

                    <Grid container spacing={2} sx={{ marginTop: '0.5rem' }}>
                      <Grid item xs={12} md={6}>
                        <SelectAsync
                          name="communicationTemplates"
                          label="Templates"
                          required
                          apiRequest={getMassEmailTemplates}
                          transformResponse={(response: IResponse<IEmailTemplate[]>) => {
                            return response.records.map(record => ({
                              label: record.emailTemplateName,
                              value: record.emailTemplateId,
                            }));
                          }}
                          onChange={e => {
                            loadTemplateDetails(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          as={TextField}
                          label="Subject"
                          name="subjectTemplate"
                          required
                          fullWidth
                          size="small"
                          value={values.subjectTemplate}
                          error={touched?.subjectTemplate && !!errors?.subjectTemplate}
                          helperText={touched?.subjectTemplate && errors?.subjectTemplate}
                          onChange={(e: { target: { value: SetStateAction<string | null> } }) => {
                            setFieldValue('subjectTemplate', e.target.value);
                            setCurrentTemplateSubject(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Wysiwyg
                          label="Body"
                          required
                          value={values.bodyTemplate}
                          onChange={val => {
                            setFieldValue('bodyTemplate', val);
                            setCurrentTemplateBody(val);
                          }}
                          onBlur={() => {
                            setTouched({ bodyTemplate: true });
                          }}
                        />
                        {touched?.bodyTemplate && errors?.bodyTemplate && (
                          <Box style={styles.errorText}>{errors?.bodyTemplate}</Box>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel
                            id="recipient-group"
                            sx={{ backgroundColor: theme => theme.palette.common.white }}
                          >
                            Recipient Group
                          </InputLabel>
                          <Select
                            fullWidth
                            size="small"
                            autoComplete="nope"
                            labelId="recipient-group"
                            name="audienceOption"
                            onFocus={() => {
                              setTouched({ bodyTemplate: true });
                            }}
                            onChange={e => {
                              setCurrentTemplateBody(values.bodyTemplate);
                              setCurrentTemplateSubject(values.subjectTemplate);
                              setFieldValue('audienceId', e.target.value);
                              setFieldValue('recipients', []);
                              handleAudienceChange(e.target.value as number);
                            }}
                          >
                            <MenuItem value={1}>Everyone</MenuItem>
                            <MenuItem value={2}>By Service Date</MenuItem>
                            <MenuItem value={3}>By Service Date and Tech</MenuItem>
                            <MenuItem value={4}>By Sanitizer</MenuItem>
                            <MenuItem value={5}>By Rate</MenuItem>
                            {/* <MenuItem value={6}>By Service Type</MenuItem> */}
                            <MenuItem value={7}>By Equipment Type</MenuItem>
                            <MenuItem value={8}>With Ots But No Weekly</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {accountStatusFilterIsVisible === true && (
                        <Grid item xs={12} md={6}>
                          <Select
                            fullWidth
                            size="small"
                            autoComplete="nope"
                            label="Account Status"
                            name="accountStatus"
                            value={accountStatusFilter}
                            onChange={e => {
                              setAccountStatusFilter(e.target.value as number);
                            }}
                          >
                            <MenuItem value={0}>Inactive</MenuItem>
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={2}>All</MenuItem>
                          </Select>
                        </Grid>
                      )}

                      {serviceDateFilterIsVisible === true && (
                        <Grid item xs={12} md={6}>
                          <DatePicker
                            label="Service Date"
                            format="MM/dd/yyyy"
                            onChange={(date: any) => {
                              setServiceDateFilter(date);
                              setFieldValue('serviceDate', date);
                            }}
                            disabled={false}
                            value={serviceDateFilter}
                            slotProps={{
                              textField: {
                                error: false,
                                size: 'small',
                                fullWidth: true,
                                required: true,
                              },
                            }}
                          />
                        </Grid>
                      )}

                      {technicianFilterIsVisible === true && (
                        <Grid item xs={12} md={6}>
                          <SelectAsync
                            name="technicianFilter"
                            label="Technician"
                            required
                            apiRequest={getAllTechnicians}
                            transformResponse={(response: IResponse<ITechnicianUser[]>) => {
                              return response.records.map(record => ({
                                label: record.userName,
                                value: record.userId,
                              }));
                            }}
                            value={technicianFilter}
                            onChange={e => {
                              setTechnicianFilter(e.target.value);
                              setFieldValue('technicianId', e.target.value);
                            }}
                          />
                        </Grid>
                      )}

                      {sanitizerFilterIsVisible === true && (
                        <Grid item xs={12} md={6}>
                          <SelectAsync
                            name="sanitizerFilter"
                            label="Sanitizer"
                            required
                            apiRequest={getPoolEquipmentSanitizers}
                            transformResponse={(response: IPoolEquipmentSanitizer[]) => {
                              return response.map(record => ({
                                label: record.sanitizer ? record.sanitizer : '',
                                value: record.sanitizer ? record.sanitizer : '',
                              }));
                            }}
                            value={sanitizerFilter}
                            onChange={e => {
                              setSanitizerFilter(e.target.value);
                            }}
                          />
                        </Grid>
                      )}

                      {rateFilterIsVisible === true && (
                        <Grid item xs={12} md={6}>
                          <SelectAsync
                            name="rateFilter"
                            label="Rate"
                            required
                            apiRequest={getRates}
                            transformResponse={(response: IResponse<IAccountRate[]>) => {
                              return response.records.map(record => ({
                                label: record.description ? record.description : '',
                                value: record.rateId ? record.rateId : '',
                              }));
                            }}
                            value={rateFilter}
                            onChange={e => {
                              setRateFilter(e.target.value);
                            }}
                          />
                        </Grid>
                      )}

                      {serviceTypeFilterIsVisible === true && (
                        <Grid item xs={12} md={6}>
                          <SelectAsync
                            name="serviceTypeFilter"
                            label="Service Type"
                            required
                            apiRequest={listServiceTypes}
                            transformResponse={(response: IServiceType[]) => {
                              return response.map(record => ({
                                label: record.description ? record.description : '',
                                value: record.serviceTypeId ? record.serviceTypeId : '',
                              }));
                            }}
                            value={serviceTypeFilter}
                            onChange={e => {
                              setServiceTypeFilter(e.target.value);
                            }}
                          />
                        </Grid>
                      )}

                      {equipmentTypeFilterIsVisible === true && (
                        <Grid item xs={12} md={6}>
                          <SelectAsync
                            name="equipmentTypeFilter"
                            label="Equipment Item"
                            required
                            apiRequest={getEquipmentTypes}
                            transformResponse={(response: IResponse<IEquipmentType[]>) => {
                              return response.records.map(record => ({
                                label: record.description ? record.description : '',
                                value: record.equipmentTypeId ? record.equipmentTypeId : '',
                              }));
                            }}
                            value={equipmentTypeFilter}
                            onChange={e => {
                              setEquipmentTypeFilter(e.target.value);
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} md={6}>
                        <Button
                          disabled={!selectedAudienceOption}
                          onClick={() => {
                            loadRecipientListByAudienceFilters(selectedAudienceOption as number);
                          }}
                        >
                          Load Recipients
                        </Button>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <label>
                          {potentialRecipients?.filter(item => item.isSelected === true)?.length} /{' '}
                          {potentialRecipientsCount} Selected / Showing{' '}
                          {currentPageOfRecipients.length}
                        </label>
                      </Grid>
                      {!isLoadingRecipients && currentPageOfRecipients.length > 0 && (
                        <Grid item xs={12} md={12}>
                          <Box mt={2}>
                            <PrimaryCard title="Recipients" icon={faUserGroup}>
                              <Stack
                                direction="row"
                                flexWrap="wrap"
                                gap={0}
                                justifyContent="space-between"
                              >
                                {currentPageOfRecipients.map(recipient => (
                                  <FormControlLabel
                                    sx={{
                                      width: '225px',
                                      overflow: 'hidden',
                                    }}
                                    key={recipient.id}
                                    control={
                                      <Checkbox
                                        checked={
                                          potentialRecipients.find(item => item.id === recipient.id)
                                            ?.isSelected
                                        }
                                      />
                                    }
                                    label={recipient.displayName}
                                    onChange={(_e, checked) =>
                                      handleRecipientSelection(recipient, checked)
                                    }
                                  />
                                ))}
                              </Stack>
                              <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handleRecipientsPageChange}
                                color="primary"
                              />
                            </PrimaryCard>
                          </Box>
                        </Grid>
                      )}
                    </Grid>

                    <SaveSection
                      handleCancel={() => {
                        history.push(Paths.newsManagementSetup.url);
                      }}
                      submitLabel="Send"
                    />
                  </CardTitle>
                </Card>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
