import { FC } from 'react';
import { ToggleButton } from '@mui/material';
import { DaysOfWeek } from '../../models';
import { ToggleButtonGroup } from './toggle-button-group';

interface IDaysAvailableSelectorProps {
  daysAvailable: DaysOfWeek[] | null;
  onChange: (e: React.MouseEvent<HTMLElement>, newValue: any | any[]) => void;
  fullWidth?: boolean;
  id?: string;
}

export const DaysAvailableSelector: FC<IDaysAvailableSelectorProps> = ({
  daysAvailable,
  onChange,
  fullWidth,
  id,
}) => {
  return (
    <>
      <ToggleButtonGroup
        fullWidth={fullWidth}
        id={id}
        label="Days Available"
        value={daysAvailable}
        onChange={onChange}
      >
        <ToggleButton value="Monday" aria-label="Monday" title="Monday">
          Mon
        </ToggleButton>
        <ToggleButton value="Tuesday" aria-label="Tuesday" title="Tuesday">
          Tue
        </ToggleButton>
        <ToggleButton value="Wednesday" aria-label="Wednesday" title="Wednesday">
          Wed
        </ToggleButton>
        <ToggleButton value="Thursday" aria-label="Thursday" title="Thursday">
          Thu
        </ToggleButton>
        <ToggleButton value="Friday" aria-label="Friday" title="Friday">
          Fri
        </ToggleButton>
        <ToggleButton value="Saturday" aria-label="Saturday" title="Saturday">
          Sat
        </ToggleButton>
        <ToggleButton value="Sunday" aria-label="Sunday" title="Sunday">
          Sun
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
