import { FC, useState, useEffect, useMemo } from 'react';
import { ConfirmPrompt, LineItemsModal } from '../../components';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { getInvoiceLineItem, createInvoiceLineItem, updateInvoiceLineItem } from '../../fetch';
import {
  IInvoiceLineItemPost,
  IInventory,
  IInvoiceLineItem,
  IFormLineItem,
  LINE_ITEMS_FORM_VALIDATION,
  InvoicelineItemInitialState,
} from '../../models';
import { convertToNumber, formatMoney } from '../../helpers';
import { defaultUnsavedChangesMessage } from '../../constants';

interface IInvoiceLinesModalProps {
  transactionId: string;
  invoiceLineItemId?: string;
  isOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  totalLineItems: number;
  invoiceLines: IInvoiceLineItem[];
}

export const InvoiceLinesModal: FC<IInvoiceLinesModalProps> = ({
  transactionId,
  invoiceLineItemId,
  isOpen,
  onClose,
  totalLineItems,
  invoiceLines,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const filteredLineItems = useMemo(() => {
    const filteredItems = invoiceLines.filter(
      item => item.tranCodeDescription !== 'Security Deposit' && item.tranCodeDescription !== 'Tax'
    );
    const lastItemNumber =
      filteredItems[filteredItems.length - 1]?.sortOrder ?? filteredItems.length;
    return lastItemNumber + 1;
  }, [invoiceLines]);

  const [lineItem, setLineItem] = useState<IFormLineItem>({
    sortOrder: `${filteredLineItems}`,
    ...InvoicelineItemInitialState,
  });
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setLineItem({
      sortOrder: `${filteredLineItems}`,
      ...InvoicelineItemInitialState,
    });
  }, [totalLineItems, filteredLineItems]);
  useEffect(() => {
    const fetchEstimateLineItem = async () => {
      if (!invoiceLineItemId) return;

      try {
        setIsLoading(true);
        const res = await getInvoiceLineItem(transactionId, invoiceLineItemId);
        setLineItem({
          sortOrder: res.sortOrder ? `${res.sortOrder}` : `${filteredLineItems}`,
          tranCodeId: res.tranCodeId,
          details: res.reference,
          serialNumber: res.serialNumber,
          rate: res?.rate ? formatMoney(res?.rate, 2) : '$0.00',
          lookupCode: res.itemLookupCode ? `${res.itemLookupCode}` : '',
          storeInventoryId: res.storeInventoryId ? `${res.storeInventoryId}` : '',
          externalPartId: res.externalPartId ?? null,
          quantity: res.quantity ? `${res.quantity}` : '',
          isSerialized: res.isSerialized,
          poolCommerceInventoryId: res.poolCommerceInventoryId ?? null,
        });
      } catch (err: any) {
        enqueueSnackbar(err?.Detail || `Error loading line items. Please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchEstimateLineItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, invoiceLineItemId, filteredLineItems]);
  const currentSortNumbers = useMemo(
    () => invoiceLines.map(line => line.sortOrder),
    [invoiceLines]
  );

  return (
    <Formik
      initialValues={{
        sortOrder: lineItem.sortOrder ?? `${filteredLineItems}`,
        tranCodeId: lineItem.tranCodeId ?? '',
        details: lineItem.details ?? '',
        serialNumber: lineItem.serialNumber ?? '',
        lookupCode: lineItem.lookupCode ?? '',
        rate: lineItem.rate ? formatMoney(lineItem.rate, 2) : '',
        quantity: lineItem.quantity ?? '',
        storeInventoryId: lineItem.storeInventoryId || null,
        externalPartId: lineItem?.externalPartId || null,
        poolCommerceInventoryId: lineItem?.poolCommerceInventoryId,
      }}
      enableReinitialize={true}
      validationSchema={LINE_ITEMS_FORM_VALIDATION}
      onSubmit={async values => {
        const payload: IInvoiceLineItemPost = {
          sortOrder: Number(values.sortOrder),
          tranCodeId: values.tranCodeId,
          rate: values.rate ? convertToNumber(values.rate) : null,
          quantity: Number(values.quantity),
          lookupCode: values.lookupCode,
          reference: values.details,
          serialNumber: values.serialNumber ?? '',
          storeInventoryId: values.storeInventoryId ? Number(values.storeInventoryId) : null,
          externalPartId: values.externalPartId ? Number(values.externalPartId) : null,
          poolCommerceInventoryId: values.poolCommerceInventoryId
            ? values.poolCommerceInventoryId
            : null,
        };
        try {
          invoiceLineItemId
            ? await updateInvoiceLineItem(transactionId, invoiceLineItemId, payload)
            : await createInvoiceLineItem(transactionId, payload);
          enqueueSnackbar('Line item saved!', { variant: 'success' });
          setLineItem({
            sortOrder: `${filteredLineItems}`,
            ...InvoicelineItemInitialState,
          });
          onClose(true);
        } catch (err: any) {
          enqueueSnackbar(err?.Detail || `Error saving line item. Please try again.`, {
            variant: 'error',
          });
        }
      }}
    >
      {({ isSubmitting, isValid, dirty, touched, resetForm, setTouched, validateForm, values }) => {
        const handleInventoryItemSelect = (inventoryItem: any, tranCodeId: string) => {
          setLineItem({
            ...lineItem,
            tranCodeId,
            lookupCode: inventoryItem.lookupCode ?? '',
            details: inventoryItem.productTitle ?? inventoryItem.description,
            rate: `${formatMoney(inventoryItem?.price, 2)}`,
            quantity: '1',
            storeInventoryId: inventoryItem.inventoryId ? `${inventoryItem.inventoryId}` : '',
            sortOrder: `${filteredLineItems}`,
            externalPartId: inventoryItem?.partId,
            isSerialized: inventoryItem.isSerialized,
            poolCommerceInventoryId: inventoryItem?.productId,
          });
        };
        return (
          <>
            <ConfirmPrompt
              when={dirty && !isSubmitting && Object.keys(touched).length > 0}
              message={defaultUnsavedChangesMessage}
            />
            <LineItemsModal
              onClose={() => {
                setLineItem({
                  sortOrder: `${filteredLineItems}`,
                  ...InvoicelineItemInitialState,
                });
                onClose();
              }}
              isLoading={isSubmitting || isLoading}
              isOpen={isOpen}
              currentLineNumbers={currentSortNumbers}
              isSaveDisabled={isLoading || isSubmitting || !isValid}
              handleInventoryItemSelect={(item: IInventory, tranCodeId: string) => {
                handleInventoryItemSelect(item, tranCodeId);
                setTimeout(() => {
                  setTouched({
                    ...touched,
                    tranCodeId: true,
                    lookupCode: true,
                    sortOrder: true,
                    details: true,
                    rate: true,
                    quantity: true,
                    serialNumber: true,
                  });
                  validateForm();
                }, 200);
              }}
              handleCancel={async () => {
                resetForm();
                setLineItem({
                  sortOrder: `${filteredLineItems}`,
                  ...InvoicelineItemInitialState,
                });
                onClose();
              }}
              itemId={invoiceLineItemId}
              lineItem={lineItem}
            />
          </>
        );
      }}
    </Formik>
  );
};
