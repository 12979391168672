import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';
import { RadioGroup } from '../../../components';

export const StyleGuideRadioGroup = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Radio Group">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Radio Group is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-radio-button/" target="_blank">
              Mui Radio Group
            </Link>{' '}
            component and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has built-in styles, and Formik props required for use with a Formik form and form
            validation.
          </Typography>
          <Typography mb={1}>
            Radio groups should be wrapped in a FormControl and should follow a FormLabel compoent.
          </Typography>
          <Typography mb={1}>
            Radio groups allow for any child to allow for more dynamic layouts, but the main child
            component of note should be a Radio component wrapped in a FormControlLabel component.
          </Typography>
          <code>
            Props:
            <br />- name: string
            <br />- children: ReactNode
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '',
          testField2: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ handleBlur }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box mb={1} mt={2}>
                    <FormControl>
                      <FormLabel id="example" color="primary">
                        Example
                      </FormLabel>
                      <RadioGroup name="testField" aria-labelledby="example">
                        <FormControlLabel value="option1" control={<Radio />} label="Option 1" />
                        <FormControlLabel value="option2" control={<Radio />} label="Option 2" />
                        <FormControlLabel value="option3" control={<Radio />} label="Option 3" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;FormControl&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;FormLabel id="example" color="primary"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Example
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/FormLabel&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;RadioGroup name="testField" aria-labelledby="example"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FormControlLabel value="option1"
                    control=&#123;&lt;Radio /&gt;&#125; label="Option 1" /&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FormControlLabel value="option2"
                    control=&#123;&lt;Radio /&gt;&#125; label="Option 2" /&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FormControlLabel value="option3"
                    control=&#123;&lt;Radio /&gt;&#125; label="Option 3" /&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/RadioGroup&gt;
                    <br />
                    &lt;/FormControl&gt;
                  </CodeBlock>
                </Grid>
                <Grid item xs={12}>
                  <Box mb={1} mt={2}>
                    <FormControl>
                      <FormLabel id="example2" color="primary">
                        Example 2
                      </FormLabel>
                      <RadioGroup
                        name="testField2"
                        aria-labelledby="example2"
                        sx={{ flexDirection: 'row' }}
                      >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;FormControl&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;FormLabel id="example2" color="primary"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Example 2
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/FormLabel&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;RadioGroup name="testField2" aria-labelledby="example2"
                    sx=&#123;&#123; flexDirection: 'row' &#125;&#125;&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FormControlLabel value="Yes"
                    control=&#123;&lt;Radio /&gt;&#125; label="Yes" /&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FormControlLabel value="No"
                    control=&#123;&lt;Radio /&gt;&#125; label="No" /&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/RadioGroup&gt;
                    <br />
                    &lt;/FormControl&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
