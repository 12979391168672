import { Box, Checkbox, FormControlLabel, DialogProps, styled } from '@mui/material';
import { FC, useState } from 'react';
import { IModal, Modal, ModalSaveSection, Loader } from '../../components';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface IConfirmCheckboxModal extends Omit<IModal, 'children'> {
  isOpen: boolean;
  handleClose: (isSubmit?: boolean) => void;
  handleSubmit: () => void;
  title: string;
  checkboxLabel?: string;
  additionalCheckboxLabel?: string;
  textAlign?: 'right' | 'center';
  customMessage?: JSX.Element;
  isSaveDisabled?: boolean;
  isLoading?: boolean;
}

export const ConfirmCheckboxModal: FC<IConfirmCheckboxModal & Omit<DialogProps, 'open'>> = ({
  isOpen,
  handleClose,
  handleSubmit,
  title,
  checkboxLabel,
  additionalCheckboxLabel,
  showCloseButton,
  textAlign = 'center',
  children,
  customMessage,
  isSaveDisabled,
  isLoading,
  ...dialogProps
}) => {

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [additonalCheckboxConrimed, setAdditionalCheckboxConfirmed] = useState(false);

  const onClose = (isSubmit?: boolean) => {
    handleClose(isSubmit);
    setIsConfirmed(false);
    setAdditionalCheckboxConfirmed(false);
  };
  return (
    <StyledModal
      {...dialogProps}
      open={isOpen}
      onClose={() => onClose()}
      title={title}
      maxWidth={dialogProps.maxWidth ?? 'sm'}
      showCloseButton={showCloseButton ?? false}
      sx={textAlign ? { textAlign: textAlign } : undefined}
    >
      {isLoading && <Loader type="overlay" position="centered" />}
      <Box p={1}>
        {customMessage}
        {checkboxLabel && (
          <FormControlLabel
            control={<Checkbox />}
            className={classes.checkbox}
            label={checkboxLabel}
            checked={isConfirmed}
            onChange={() => {
              setIsConfirmed(!isConfirmed);
            }}
          />
        )}
        {checkboxLabel && additionalCheckboxLabel && (
          <FormControlLabel
            control={<Checkbox />}
            className={classes.checkbox}
            sx={{ mt: 1 }}
            label={additionalCheckboxLabel}
            checked={additonalCheckboxConrimed}
            onChange={() => {
              setAdditionalCheckboxConfirmed(!additonalCheckboxConrimed);
            }}
          />
        )}
      </Box>
      <ModalSaveSection
        handleCancel={() => onClose()}
        handleSave={() => {
          handleSubmit();
          onClose(true);
        }}
        submitIcon={faCheck}
        submitLabel="Confirm"
        isSaveDisabled={
          !isConfirmed
          || (additionalCheckboxLabel && !additonalCheckboxConrimed)
          || isSaveDisabled
        }
      />
    </StyledModal>
  );
};

const PREFIX = 'ConfirmCheckboxModal';

const classes = {
  checkbox: `${PREFIX}-checkbox`
};

const StyledModal = styled(Modal)(() => {
  return {
    [`& .${classes.checkbox}`]: {
      alignItems: 'flex-start',
      textAlign: 'left',
      width: '100%',
      '& .MuiTypography-root': {
        position: 'relative',
        top: '.5rem',
      },
    },
  };
});