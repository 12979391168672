import { DisplayGroup, Loader, Modal } from '../../../components';
import { IOutboxMessageContent } from '../../../models';
import { FC, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Fade, Grid } from '@mui/material';
import { formatDate, formatMoney } from '../../../helpers';
import { useSnackbar } from 'notistack';
import { useConfirm } from '../../../hooks';
import { requeueOutboxMessage } from '../../../fetch';

interface IMessageDetails {
  isModalOpen?: boolean;
  handleModalClose?: () => void;
  message: any;
  messageId: string;
  refetchMessages: () => void;
}

export const MessageDetails: FC<IMessageDetails> = ({
  isModalOpen,
  handleModalClose,
  message,
  messageId,
  refetchMessages,
}) => {
  const parsedMessage: IOutboxMessageContent = JSON.parse(message);

  const [isRequeueLoading, setRequeueLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const handleRequeue = async (id: string) => {
    const result = await confirm('Are you sure you want to requeue this message?');
    if (result) {
      try {
        setRequeueLoading(true);
        await requeueOutboxMessage(id);
        handleModalClose?.();
        enqueueSnackbar('Message requeued', {
          variant: 'success',
        });
        refetchMessages();
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error requeuing message, please try again.`, {
          variant: 'error',
        });
      } finally {
        setRequeueLoading(false);
      }
    }
  };
  return (
    <Modal
      open={isModalOpen!}
      onClose={handleModalClose}
      maxWidth="md"
      title="Message Details"
      actions={
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleRequeue(messageId)}
            disabled={isRequeueLoading}
          >
            Requeue
          </Button>
        </>
      }
    >
      <Fade in={isModalOpen!}>
        <Box mt={1}>
          {isRequeueLoading && <Loader type="overlay" />}
          <Grid container spacing={2}>
            {parsedMessage.$type.includes('AdyenOnboarding') && (
              <>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Outbox Message Id">
                    {parsedMessage?.OutboxMessageId}
                  </DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Business Line Id">
                    {parsedMessage?.BusinessLineId}
                  </DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Office Id">{parsedMessage?.OfficeId}</DisplayGroup>
                </Grid>
              </>
            )}
            {(parsedMessage.$type.includes('Sms') ||
              parsedMessage.$type.includes('TestOutboxEvent')) && (
              <>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Message">{parsedMessage?.Message}</DisplayGroup>
                </Grid>
              </>
            )}
            {parsedMessage.$type.includes('QueueInvoiceEmailOutboxEvent') && (
              <>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Transaction Id">{parsedMessage?.TransactionId}</DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Account Id">{parsedMessage?.AccountId}</DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Subject">{parsedMessage?.Subject}</DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="To Address">
                    {parsedMessage?.ToAddress?.$values?.join(', ')}
                  </DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="User Id">{parsedMessage?.UserId}</DisplayGroup>
                </Grid>
              </>
            )}
            {parsedMessage.$type.includes('Transactions') && (
              <>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Transaction Id">{parsedMessage?.TransactionId}</DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Account Name">
                    {parsedMessage.Account?.AccountName}
                  </DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Account Id">{parsedMessage.Account?.AccountId}</DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Account Email">
                    {parsedMessage.Account?.Email?.includes(';')
                      ? parsedMessage.Account?.Email?.split(';').join(', ')
                      : parsedMessage.Account?.Email}
                  </DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Tran Code Description">
                    {parsedMessage.TranCodeDescription}
                  </DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Invoice Number">{parsedMessage?.InvoiceNumber}</DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Invoice Date">
                    {formatDate(parsedMessage.InvoiceDate)}
                  </DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Reference">
                    {formatDate(parsedMessage?.Reference)}
                  </DisplayGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DisplayGroup label="Amount">
                    {parsedMessage.Amount ? formatMoney(parsedMessage.Amount) : ''}
                  </DisplayGroup>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};
