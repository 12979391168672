import { FC, useCallback, useContext, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { faFilterCircleXmark, faFilter, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Loader,
  CardTitle,
  CardFiltersLayout,
  Card,
  GridDataFetcher,
  useDataGrid,
} from '../../components';
import { Permissions } from '../../constants';
import { IEstimate } from '../../models';
import { deleteEstimate, getEstimates } from '../../fetch';
import { UserContext } from '../../context';
import { EstimatesFilters } from './estimates-filters';
import { useConfirm } from '../../hooks';
import { EstimatesDataGrid } from './estimates-data-grid';
import { hasCorrectUserPermissions } from '../../helpers';

export interface IEstimatesCard {
  accountId?: string;
  isEditable?: boolean;
  repairId?: string;
  siteId?: string;
  isExpanded?: boolean;
  withExpand?: boolean;
  cardTitle?: string;
  shouldAddBeDisabled?: boolean;
  showFilters?: boolean;
  hidePagination?: boolean;
  redirect?: string;
  isOTSEstimates?: boolean;
  columnSorting?: boolean;
  refetchRepairStatus?: () => void;
  onNavigation?: (route: string) => unknown;
}

export const EstimatesCard: FC<IEstimatesCard> = ({
  repairId,
  siteId,
  accountId,
  isEditable = true,
  isExpanded,
  withExpand,
  cardTitle,
  shouldAddBeDisabled,
  showFilters = true,
  hidePagination = false,
  redirect,
  isOTSEstimates = false,
  columnSorting = true,
  refetchRepairStatus,
  onNavigation,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const confirm = useConfirm();
  const [isDeletingEstimate, setIsDeletingEstimate] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [filters, setFilters] = useState<{
    status?: string;
  } | null>({});

  const dataFetcher: GridDataFetcher<IEstimate> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        let res: { records: IEstimate[]; totalRecordCount: number } = {
          records: [],
          totalRecordCount: 0,
        };
        if (!!accountId && repairId !== 'new') {
          res = await getEstimates({
            ...filters,
            sortBy: sortColumn || 'whenCreated',
            sortDirection: sortDirection || 'desc',
            page: page + 1,
            perPage: hidePagination ? -1 : perPage,
            accountId,
            repairId,
            officeId: user?.officeId,
          });
        }
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading estimates, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  const {
    rows,
    isLoading: isLoadingEstimates,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchEstimates,
  } = useDataGrid<IEstimate>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      sortColumn: 'whenCreated',
      sortDirection: 'desc',
      gridKeyName: 'estimates-card-grid',
    },
    dataFetcher,
  });

  const handleDelete = async (estimateId: string | number) => {
    try {
      const result = await confirm('Are you sure you want to delete this estimate?');
      if (result) {
        setIsDeletingEstimate(true);
        await deleteEstimate(estimateId);
        enqueueSnackbar(`Estimate Deleted!`, {
          variant: 'success',
        });
        refetchEstimates();
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting estimate, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeletingEstimate(false);
    }
  };

  if (!hasCorrectUserPermissions(Permissions.ViewEstimates, user!)) {
    return null;
  }
  return (
    <>
      <Box>
        <Card>
          {isDeletingEstimate && <Loader position="centered" type="overlay" title="Deleting..." />}
          <CardTitle
            marginBottom={0}
            title={cardTitle ?? 'Estimates'}
            mobileWrap
            withExpand={withExpand}
            overrideExpand={isExpanded}
            action={
              <>
                {showFilters && (
                  <Button
                    onClick={() => setIsShowingFilters(!isShowingFilters)}
                    className={clsx('print--none')}
                    color="secondary"
                    size="small"
                    disabled={isLoadingEstimates}
                    startIcon={
                      <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                    }
                  >
                    Filters
                  </Button>
                )}
                {isEditable && (
                  <Button
                    onClick={() => {
                      if (!onNavigation) {
                        history.push(
                          `/estimates/new/${accountId}${redirect ? `?redirect=${redirect}` : ''}${
                            siteId ? `&siteId=${siteId}` : ''
                          }`
                        );
                      } else {
                        onNavigation(
                          `/estimates/new/${accountId}${redirect ? `?redirect=${redirect}` : ''}${
                            siteId ? `&siteId=${siteId}` : ''
                          }`
                        );
                      }
                    }}
                    color="secondary"
                    size="small"
                    disabled={
                      (isOTSEstimates && rows?.length === 1) ||
                      isLoadingEstimates ||
                      (isOTSEstimates && shouldAddBeDisabled && repairId === 'new')
                    }
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                    data-testid="add-estimate-button"
                  >
                    Add Estimate
                  </Button>
                )}
              </>
            }
          >
            <CardFiltersLayout isOpen={isShowingFilters}>
              <EstimatesFilters
                hasAppliedFilters={hasAppliedFilters}
                setHasAppliedFilters={setHasAppliedFilters}
                isLoading={isLoadingEstimates}
                applyFilters={(clearFilters?: boolean) => {
                  if (clearFilters) {
                    setFilters({
                      status: '',
                    });
                  } else {
                    onPageChange(0);
                    setFilters({
                      ...filters,
                      status: selectedStatus,
                    });
                  }
                }}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
            </CardFiltersLayout>
            <Box marginTop={showFilters ? '1rem' : 0}>
              <EstimatesDataGrid
                loading={isLoadingEstimates}
                rows={rows}
                rowCount={recordCount}
                page={page}
                pageSize={perPage}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
                refetch={refetchEstimates}
                redirect={redirect}
                isOTSEstimates={isOTSEstimates}
                accountId={accountId}
                isEditable={isEditable}
                handleDelete={handleDelete}
                hideGridFooter={hidePagination}
                columnSorting={columnSorting}
                refetchRepairStatus={refetchRepairStatus}
                onNavigation={onNavigation}
              />
            </Box>
          </CardTitle>
        </Card>
      </Box>
    </>
  );
};
