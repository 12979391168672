import { Box, Grid, Link, ToggleButton, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { ToggleButtonGroup } from '../../../components';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

export const StyleGuideToggleButtonGroup = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Toggle Button Group">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Toggle Button Group is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-toggle-button/" target="_blank">
              MUI Toggle Button Group
            </Link>{' '}
            component. It has built-in styles including a prop for growing to fullWidth, accepts all
            MUI Toggle Button Group Props, and only accepts MUI Toggle Button components as child
            elements.
          </Typography>

          <code>
            Props (Extends ToggleButtonGroupProps):
            <br />- value: any | null
            <br />- onChange: (e: React.MouseEvent&lt;HTMLElement&gt;, newValue: any | any[]) =&gt;
            void;
            <br />- fullWidth?: boolean
            <br />- id?: string
            <br />- children: React.ReactNode | React.ReactElement&lt;typeof ToggleButton&gt;[]
            <br />- label?: string
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: ['Monday', 'Wednesday', 'Friday'],
          testField2: ['Add'],
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ handleChange, values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <ToggleButtonGroup
                      fullWidth
                      id="toggle-button-group-example-1"
                      label="Example 1"
                      value={values.testField}
                      onChange={(e, val) => setFieldValue('testField', val)}
                    >
                      <ToggleButton value="Monday" aria-label="Monday" title="Monday">
                        Mon
                      </ToggleButton>
                      <ToggleButton value="Tuesday" aria-label="Tuesday" title="Tuesday">
                        Tue
                      </ToggleButton>
                      <ToggleButton value="Wednesday" aria-label="Wednesday" title="Wednesday">
                        Wed
                      </ToggleButton>
                      <ToggleButton value="Thursday" aria-label="Thursday" title="Thursday">
                        Thu
                      </ToggleButton>
                      <ToggleButton value="Friday" aria-label="Friday" title="Friday">
                        Fri
                      </ToggleButton>
                      <ToggleButton value="Saturday" aria-label="Saturday" title="Saturday">
                        Sat
                      </ToggleButton>
                      <ToggleButton value="Sunday" aria-label="Sunday" title="Sunday">
                        Sun
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;ToggleButtonGroup
                    <br />
                    &nbsp;&nbsp;&nbsp;fullWidth
                    <br />
                    &nbsp;&nbsp;&nbsp;id="toggle-button-group-example-1"
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Example 1"
                    <br />
                    &nbsp;&nbsp;&nbsp;value=&#123;values.testField&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;onChange=&#123;(e, val) =&gt; setFieldValue('testField',
                    val)&#125;
                    <br />
                    &gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;ToggleButton value="Monday" aria-label="Monday"
                    title="Monday"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mon
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/ToggleButton&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;ToggleButton value="Tuesday" aria-label="Tuesday"
                    title="Tuesday"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tue
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/ToggleButton&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp; &lt;ToggleButton value="Wednesday" aria-label="Wednesday"
                    title="Wednesday"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wed
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/ToggleButton&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;ToggleButton value="Thursday" aria-label="Thursday"
                    title="Thursday"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thu
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/ToggleButton&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;ToggleButton value="Friday" aria-label="Friday"
                    title="Friday"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fri
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/ToggleButton&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;ToggleButton value="Saturday" aria-label="Saturday"
                    title="Saturday"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sat
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/ToggleButton&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;ToggleButton value="Sunday" aria-label="Sunday"
                    title="Sunday"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sun
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/ToggleButton&gt;
                    <br />
                    &lt;/ToggleButtonGroup&gt;
                  </CodeBlock>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <ToggleButtonGroup
                      fullWidth
                      exclusive
                      size="small"
                      id="toggle-button-group-example-2"
                      value={values.testField2}
                      onChange={(e: React.MouseEvent<HTMLElement>, newValue: string) => {
                        setFieldValue('testField2', newValue);
                        handleChange(e);
                      }}
                      aria-label="Apply Rate Action Type"
                    >
                      <ToggleButton value="Add" aria-label="Add Rate(s)">
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          size="lg"
                          style={{ marginRight: '.5rem' }}
                        />
                        Add
                      </ToggleButton>
                      <ToggleButton value="Remove" aria-label="Remove Rate(s)">
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="lg"
                          style={{ marginRight: '.5rem' }}
                        />
                        Remove
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;ToggleButtonGroup
                    <br />
                    &nbsp;&nbsp;&nbsp;fullWidth
                    <br />
                    &nbsp;&nbsp;&nbsp;exclusive
                    <br />
                    &nbsp;&nbsp;&nbsp;size="small"
                    <br />
                    &nbsp;&nbsp;&nbsp;id="toggle-button-group-example-2"
                    <br />
                    &nbsp;&nbsp;&nbsp;value=&#123;values.testField2&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;onChange=&#123;(e: React.MouseEvent&lt;HTMLElement&gt;,
                    newValue: string) =&gt; &#123;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setFieldValue('testField2', newValue);
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;handleChange(e);
                    <br />
                    &nbsp;&nbsp;&nbsp;&#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;aria-label="Apply Rate Action Type"
                    <br />
                    &gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;ToggleButton value="Add" aria-label="Add Rate(s)"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FontAwesomeIcon
                    icon=&#123;faPlusCircle&#125; size="lg" style=&#123;&#123; marginRight: '.5rem'
                    &#125;&#125; /&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/ToggleButton&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;ToggleButton value="Remove" aria-label="Remove
                    Rate(s)"&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;FontAwesomeIcon icon=&#123;faTrash&#125;
                    size="lg" style=&#123;&#123; marginRight: '.5rem' &#125;&#125; /&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remove
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;/ToggleButton&gt;
                    <br />
                    &lt;/ToggleButtonGroup&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
