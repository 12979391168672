import { Box, Tooltip, Button, styled } from '@mui/material';
import { format } from 'date-fns';
import { FC, useEffect, useMemo, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { CardTitle, ExternalLink, Link, Card, SimpleDataGrid } from '../../components';
import { getRouteSuggestions } from '../../fetch';
import { IServiceSuggestion, ServiceSuggestionType } from '../../models';
import { UserContext } from '../../context';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { defaultDays } from '../../components/recurring-repeat-pattern';
import { useFormikContext } from 'formik';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

interface IServiceSuggestions {
  isRecurring?: boolean;
  recurringId?: string;
  scheduledServiceId?: string | null;
  setIsSuggestionTableToggled?: (val: boolean) => void;
}

export const ServiceSuggestions: FC<IServiceSuggestions> = ({
  isRecurring = false,
  recurringId,
  scheduledServiceId,
  setIsSuggestionTableToggled,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const { v2Customers } = useFlags();
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [isSelected, setSelected] = useState<number | null>(null);
  const [serviceSuggestions, setServiceSuggestions] = useState<IServiceSuggestion[] | null>([]);
  const [assignedTo, setAssignedTo] = useState<string>('');

  const { values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    if (assignedTo !== values.assignedTo) setSelected(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.assignedTo]);

  const fetchRouteSuggestions = async () => {
    setIsLoadingSuggestions(true);
    setServiceSuggestions(null);
    try {
      const res = await getRouteSuggestions({
        siteId: values.siteId ?? null,
        routeSuggestionType: (isRecurring ? 'RecurringService' : 'Single') as ServiceSuggestionType,
        startDate: isRecurring
          ? format(new Date(values.initialDate), 'yyyy-MM-dd')
          : format(new Date(values.serviceDate), 'yyyy-MM-dd'),
        officeId: user?.officeId,
        excludeScheduledServiceId: scheduledServiceId ?? null,
        excludeRecurringServiceId: recurringId ?? null,
      });
      const data: any = [];

      res.suggestions?.forEach((site, index) => {
        data.push({
          ...site,
          index,
          userId: site.userId || '',
          userName: site.username || '',
          numberOfStops: site.numberOfStops || 0,
          distance: Math.abs(site.distance), // make positive numbers
          duration: Math.abs(site.duration), // make positive numbers
        });
      });
      setServiceSuggestions(data);
    } catch (error: any) {
      enqueueSnackbar(
        error?.Detail ?? `Error loading service suggestions, please refresh to try again.`,
        {
          variant: 'error',
        }
      );
    } finally {
      setIsLoadingSuggestions(false);
    }
  };

  const showSuggestionTable = useMemo(() => {
    // check the input value for date contains a full year i.e. 12/12/3333 and not just 21/12/12
    const hasFullYearValue =
      (values?.initialDate && `${new Date(values.initialDate)?.getFullYear()}`?.length === 4) ||
      (values?.serviceDate && `${new Date(values.serviceDate)?.getFullYear()}`?.length === 4);

    const hasValidInitialDate = hasFullYearValue && isRecurring && values.initialDate;
    const hasValidServiceDate = hasFullYearValue && !isRecurring && values.serviceDate;

    const hasDate = hasValidServiceDate || hasValidInitialDate ? true : false;

    const showTable = !!values.siteId && hasDate ? true : false;
    return showTable;
  }, [values.siteId, values.serviceDate, values.initialDate, isRecurring]);
  useEffect(() => {
    if (showSuggestionTable) {
      fetchRouteSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.siteId, values.serviceDate, values.initialDate, isRecurring]);

  const handleSelect = (original: IServiceSuggestion) => {
    if (isRecurring) {
      if (values.recurPeriod === 'Weekly') {
        const dayOfWeek = defaultDays.filter(
          day => day.name === original?.dayOfWeek?.toLowerCase()
        )[0]?.interval;
        // kind of a hacky way to reset the values in the weekly.tsx component instead of adding days together
        // need to reset the selected weekly days
        setFieldValue('weeklyInterval2', `${dayOfWeek}-reset`);
      } else {
        setFieldValue('weeklyInterval2', 0); // Reset to initialValue
      }
    } else {
      setFieldValue('serviceDate', original.serviceDate);
    }
    setIsSuggestionTableToggled && setIsSuggestionTableToggled(false);
    setSelected(original.index!);
    setAssignedTo(original?.userId?.toString() ?? '');
    // Validation wasn't updating when assignedTo is the only thing updated via this button
    setTimeout(() => {
      setFieldValue('assignedTo', original?.userId?.toString() ?? '', true);
      setFieldValue('previousSiteId', original?.siteId?.toString() ?? '', true);
    }, 10);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'dayOfWeek',
        headerName: 'Day',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'userName',
        headerName: 'Assigned To',
        disableColumnMenu: true,
        flex: 1,
      },

      {
        field: 'numberOfStops',
        headerName: '# of Stops',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'siteName',
        headerName: 'Previous Site Name',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IServiceSuggestion>) => {
          const { row: original } = params;
          const childComponent = (
            <Tooltip placement="bottom" title="View site">
              <span>{original.siteName}</span>
            </Tooltip>
          );
          return v2Customers ? (
            <Link to={`/customers/${original.accountId}/sites?siteId=${original.siteId}`}>
              {childComponent}
            </Link>
          ) : (
            <ExternalLink
              to={`https://pcptest-pcp.azurewebsites.net/Office/Site/View/${original.siteId}`}
            >
              {childComponent}
            </ExternalLink>
          );
        },
      },
      {
        headerName: 'Distance',
        field: 'distance',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IServiceSuggestion>) => {
          const { row: original } = params;
          return `${Math.round((original!.distance! + Number.EPSILON) * 100) / 100} mi` || '--';
        },
      },
      {
        headerName: 'Est. Time',
        field: 'duration',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IServiceSuggestion>) => {
          const { row: original } = params;
          return `${Math.round(original.duration!)} min` || '--';
        },
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IServiceSuggestion>) => {
          const { row: original } = params;
          return (
            <div>
              <Button
                color="primary"
                size="small"
                disabled={isSelected === original.index}
                onClick={() => {
                  handleSelect(original);
                }}
              >
                Select
              </Button>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceSuggestions, isSelected]
  );

  if (showSuggestionTable) {
    return (
      <Box marginTop="1rem" marginBottom="1rem">
        <Card>
          <CardTitle title="Service Suggestions" />
          <StyledBox>
            <SimpleDataGrid
              getRowId={(row: IServiceSuggestion) => {
                return row.index!;
              }}
              rows={serviceSuggestions ?? []}
              columns={columns}
              disableRowSelectionOnClick
              columnHeaderHeight={36}
              hideFooter
              loading={isLoadingSuggestions}
              noResultsMessage="No Service Suggestions."
              hasMobileLayout
              mobileProps={{
                handleSelect: (val: IServiceSuggestion) => handleSelect(val),
                isSelectedIndex: isSelected,
              }}
            />
          </StyledBox>
        </Card>
      </Box>
    );
  }
  return null;
};

const StyledBox = styled(Box)(({ theme }) => ({
  maxHeight: '16.5rem',
  overflow: 'auto',
  '& td': {
    'vertical-align': 'middle',
  },
}));
