import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

type SetQueryParam = (params: { [key: string]: string | null | undefined }) => void;

export function useSearchParams(): [
  URLSearchParams,
  SetQueryParam,
  string,
  (value: React.SetStateAction<URLSearchParams>) => void
] {
  const location = useLocation();
  const history = useHistory();
  const [searchParams, setSearchParams] = useState<URLSearchParams>(
    new URLSearchParams(location.search)
  );

  useEffect(() => {
    setSearchParams(new URLSearchParams(location.search));
  }, [location.search]);

  const setQueryParam: SetQueryParam = params => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, value);
      }
    });

    history.push({ search: newSearchParams.toString() });
  };

  return [
    location.search?.length > 0 ? searchParams : new URLSearchParams(),
    setQueryParam,
    searchParams.toString(),
    setSearchParams,
  ];
}
