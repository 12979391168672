import { FC, useState, useEffect, useMemo } from 'react';
import { ConfirmPrompt, LineItemsModal } from '../../components';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { getEstimateLineItem, postEstimateLineItem, putEstimateLineItem } from '../../fetch';
import {
  IEstimateLineItemPost,
  IInventory,
  IEstimateLineItemDetail,
  IEstimateLineItem,
  ISelectOption,
  LINE_ITEMS_FORM_VALIDATION,
  EstimatelineItemInitialState,
} from '../../models';
import { convertToNumber, formatMoney } from '../../helpers';
import { defaultUnsavedChangesMessage } from '../../constants';

interface IEstimateLineItemModalProps {
  estimateId: string | null;
  estimateLineItemId: string;
  isOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  estimateLineItems: IEstimateLineItem[];
  setEstimateLineItems: (val: IEstimateLineItem[]) => void;
  totalLineItems: number;
  isEditable?: boolean;
  handleSalesTax: (lineItems: IEstimateLineItem[]) => Promise<IEstimateLineItem[]>;
}

export const EstimateLineItemModal: FC<IEstimateLineItemModalProps> = ({
  estimateId,
  estimateLineItemId,
  isOpen,
  onClose,
  setEstimateLineItems,
  estimateLineItems,
  totalLineItems,
  isEditable = true,
  handleSalesTax,
}) => {
  const filteredLineItems = useMemo(() => {
    const filteredItems = estimateLineItems.filter(
      item => item.tranCodeDescription !== 'Security Deposit' && item.tranCodeDescription !== 'Tax'
    );
    const lastItemNumber =
      filteredItems[filteredItems.length - 1]?.sortOrder ?? filteredItems.length;
    return lastItemNumber + 1;
  }, [estimateLineItems]);

  const [estimateLineItem, setEstimateLineItem] = useState<IEstimateLineItemDetail>({
    estimateLineItemId: '',
    sortOrder: `${filteredLineItems}`,
    ...EstimatelineItemInitialState,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [tranCodes, setTranCodes] = useState<ISelectOption[]>([]);

  useEffect(() => {
    setEstimateLineItem({
      estimateLineItemId: '',
      sortOrder: `${filteredLineItems}`,
      ...EstimatelineItemInitialState,
    });
  }, [estimateLineItems, totalLineItems, filteredLineItems]);

  useEffect(() => {
    if (isOpen && !estimateId && Number(estimateLineItemId) <= 0) {
      const selected = estimateLineItems.find(
        item => item.estimateLineItemId === estimateLineItemId
      );
      if (selected) {
        setEstimateLineItem({
          estimateLineItemId: selected.estimateLineItemId,
          sortOrder: selected.sortOrder ? selected.sortOrder : filteredLineItems,
          tranCodeId: selected.tranCodeId,
          tranCodeDescription: '',
          lookupCode: selected.lookupCode ?? '',
          details: selected.details ?? '',
          serialNumber: selected.serialNumber ?? '',
          rate: selected?.rate ? formatMoney(selected?.rate, 2) : '$0.00',
          quantity: selected.quantity,
          isSerialized: selected.isSerialized,
          storeInventoryId: selected.inventoryId ? Number(selected.inventoryId) : undefined,
          poolCommerceInventoryId: selected.poolCommerceInventoryId,
        });
      }
    }
    if (isOpen && estimateId) {
      const fetchEstimateLineItem = async () => {
        if (!estimateLineItemId) return;

        try {
          setIsLoading(true);
          const response = await getEstimateLineItem(estimateId, estimateLineItemId);
          setEstimateLineItem(response);
        } catch (err: any) {
          enqueueSnackbar(err?.Detail || `Error loading line items. Please try again.`, {
            variant: 'error',
          });
        } finally {
          setIsLoading(false);
        }
      };
      fetchEstimateLineItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateId, estimateLineItemId, isOpen, filteredLineItems]);
  const currentSortNumbers = useMemo(
    () => estimateLineItems.map(line => line.sortOrder),
    [estimateLineItems]
  );

  return (
    <Formik
      initialValues={{
        estimateLineItemId: estimateLineItem.estimateLineItemId ?? '',
        sortOrder: estimateLineItem.sortOrder ?? filteredLineItems,
        tranCodeId: estimateLineItem.tranCodeId ?? '',
        tranCodeDescription: estimateLineItem.tranCodeDescription ?? '',
        lookupCode: estimateLineItem.lookupCode ?? '',
        details: estimateLineItem.details ?? '',
        serialNumber: estimateLineItem.serialNumber ?? '',
        rate: estimateLineItem.rate ? formatMoney(estimateLineItem.rate, 2) : '',
        quantity: estimateLineItem.quantity ?? '',
        inventoryId: estimateLineItem.storeInventoryId ?? null,
        externalPartId: estimateLineItem.externalPartId || null,
        isSerialized: estimateLineItem.isSerialized,
        poolCommerceInventoryId: estimateLineItem.poolCommerceInventoryId || null,
      }}
      enableReinitialize={true}
      validationSchema={LINE_ITEMS_FORM_VALIDATION}
      onSubmit={async values => {
        if (!estimateId) {
          const selectedCode = tranCodes.find(code => code.value === values.tranCodeId);
          if (!estimateLineItemId) {
            const items = await handleSalesTax([
              ...estimateLineItems,
              {
                estimateLineItemId: `${-estimateLineItems.length}`,
                sortOrder: Number(values.sortOrder),
                tranCodeId: values.tranCodeId,
                rate: convertToNumber(values.rate),
                quantity: Number(values.quantity),
                details: values.details,
                tranCodeDescription: selectedCode?.label,
                serialNumber: values.serialNumber,
                lookupCode: values.lookupCode,
                amount:
                  values?.rate && values.quantity
                    ? convertToNumber(values.rate) * Number(values.quantity)
                    : 0,
                inventoryId: values.inventoryId ? Number(values.inventoryId) : null,
                externalPartId: values.externalPartId ? Number(values.externalPartId) : null,
                isSerialized: values.isSerialized,
                isLaborLineItem: false,
                poolCommerceInventoryId: values.poolCommerceInventoryId
                  ? values.poolCommerceInventoryId
                  : null,
              },
            ]);
            setEstimateLineItems(items);
          } else {
            const copy: IEstimateLineItem[] = JSON.parse(JSON.stringify(estimateLineItems));
            const currentIndex = copy.findIndex(c => c.estimateLineItemId === estimateLineItemId);
            copy[currentIndex] = {
              ...copy[currentIndex],
              sortOrder: Number(values.sortOrder),
              tranCodeId: values.tranCodeId,
              rate: convertToNumber(values.rate),
              quantity: Number(values.quantity),
              details: values.details,
              serialNumber: values.serialNumber,
              tranCodeDescription: selectedCode?.label,
              lookupCode: values.lookupCode,
              amount:
                values?.rate && values.quantity
                  ? convertToNumber(values.rate) * Number(values.quantity)
                  : 0,
              inventoryId: values.inventoryId ? Number(values.inventoryId) : null,
              externalPartId: values.externalPartId ? Number(values.externalPartId) : null,
              isSerialized: values.isSerialized,
              poolCommerceInventoryId: values.poolCommerceInventoryId
                ? values.poolCommerceInventoryId
                : null,
            };
            const items = await handleSalesTax(copy);
            setEstimateLineItems(items);
          }
          onClose(true);
        } else {
          // API saving
          const payload: IEstimateLineItemPost = {
            poolCommerceInventoryId: values.poolCommerceInventoryId ?? null,
            sortOrder: Number(values.sortOrder),
            tranCodeId: values.tranCodeId,
            rate: values.rate ? convertToNumber(values.rate) : 0,
            quantity: values.quantity,
            details: values.details,
            serialNumber: values.serialNumber,
            inventoryId: values.inventoryId ? Number(values.inventoryId) : null,
            externalPartId: values.externalPartId ? Number(values.externalPartId) : null,
          };
          try {
            values.estimateLineItemId
              ? await putEstimateLineItem(estimateId!, values.estimateLineItemId, payload)
              : await postEstimateLineItem(estimateId!, payload);
            enqueueSnackbar('Line item saved!', { variant: 'success' });
            setEstimateLineItem({
              estimateLineItemId: '',
              sortOrder: `${filteredLineItems}`,
              ...EstimatelineItemInitialState,
            });
            onClose(true);
          } catch (err: any) {
            enqueueSnackbar(err?.Detail || `Error saving line item. Please try again.`, {
              variant: 'error',
            });
          }
        }
      }}
    >
      {({ isSubmitting, isValid, dirty, touched, resetForm, setTouched, validateForm, values }) => {
        const handleInventoryItemSelect = (inventoryItem: any, tranCodeId: string) => {
          setEstimateLineItem({
            ...estimateLineItem,
            tranCodeId,
            lookupCode: inventoryItem.lookupCode,
            details: inventoryItem.productTitle ?? inventoryItem.description,
            rate: inventoryItem.price ? `${formatMoney(inventoryItem?.price, 2)}` : '$0.00',
            quantity: '1',
            storeInventoryId: inventoryItem?.inventoryId,
            externalPartId: inventoryItem?.partId,
            isSerialized: inventoryItem.isSerialized,
            poolCommerceInventoryId: inventoryItem?.productId,
          });
        };
        return (
          <>
            <ConfirmPrompt
              when={dirty && !isSubmitting && Object.keys(touched).length > 0}
              message={defaultUnsavedChangesMessage}
            />
            <LineItemsModal
              onClose={() => {
                setEstimateLineItem({
                  estimateLineItemId: '',
                  sortOrder: `${filteredLineItems}`,
                  ...EstimatelineItemInitialState,
                });
                onClose();
              }}
              isLoading={isSubmitting || isLoading}
              isOpen={isOpen}
              isSaveDisabled={isSubmitting || !isValid}
              isEditable={isEditable}
              currentLineNumbers={currentSortNumbers}
              handleInventoryItemSelect={(item: IInventory, tranCodeId) => {
                handleInventoryItemSelect(item, tranCodeId);
                setTimeout(() => {
                  setTouched({
                    ...touched,
                    sortOrder: true,
                    details: true,
                    lookupCode: true,
                    rate: true,
                    quantity: true,
                    serialNumber: true,
                  });
                  validateForm();
                }, 200);
              }}
              handleCancel={async () => {
                resetForm();
                setEstimateLineItem({
                  estimateLineItemId: '',
                  sortOrder: `${filteredLineItems}`,
                  ...EstimatelineItemInitialState,
                });
                onClose();
              }}
              setTranCodes={setTranCodes}
              itemId={estimateLineItemId}
              lineItem={{
                sortOrder: estimateLineItem.sortOrder,
                tranCodeId: estimateLineItem.tranCodeId,
                details: estimateLineItem.details,
                serialNumber: estimateLineItem.serialNumber,
                rate: estimateLineItem.rate,
                lookupCode: estimateLineItem.lookupCode,
                quantity: estimateLineItem.quantity,
                storeInventoryId: estimateLineItem.storeInventoryId,
                externalPartId: estimateLineItem.externalPartId,
                isSerialized: estimateLineItem.isSerialized,
                poolCommerceInventoryId: estimateLineItem.poolCommerceInventoryId,
              }}
              lineItemType={'estimate'}
            />
          </>
        );
      }}
    </Formik>
  );
};
