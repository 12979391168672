import { SelectAsync, TextField, Card } from '../../components';
import {
  Grid,
  TextField as MuiTextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { IEstimateForm, IOneTimeServiceDetail } from '../../models';
import { formatDate } from '../../helpers';
import { ILookupModel } from '../../models/util';
import { getEstimateStatuses } from '../../fetch';
import { useSnackbar } from 'notistack';
import { isBefore } from 'date-fns';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { FC } from 'react';

interface IEstimateDetailsCard {
  setFieldValue: (key: string, val: any) => void;
  values: any;
  estimate: IEstimateForm;
  errors: any;
  touched: any;
  isNewEstimate: boolean;
  ots?: IOneTimeServiceDetail | null;
}

export const EstimateDetailsCard: FC<IEstimateDetailsCard> = ({
  setFieldValue,
  values,
  estimate,
  errors,
  touched,
  isNewEstimate,
  ots,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return (
    <Card
      cardTitleProps={{
        title: `Estimate Details ${
          estimate?.serviceTypeDescription || ots?.serviceTypeDescription
            ? `| ${estimate.serviceTypeDescription || ots?.serviceTypeDescription}`
            : ''
        }`,
        action: values.repairId ? (
          <Button
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faEye} />}
            className={clsx('print--none')}
            onClick={() => {
              history.push(
                `/services/ots/${estimate.repairId}?redirect=/estimates/${estimate.estimateId}`
              );
            }}
            size="small"
          >
            View OTS
          </Button>
        ) : undefined,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={isNewEstimate ? 6 : 4}>
          <SelectAsync
            name="estimateStatus"
            label="Status"
            required
            apiRequest={getEstimateStatuses}
            transformResponse={(response: ILookupModel[]) => {
              return response.map(record => ({
                label: record.description,
                value: record.value,
              }));
            }}
            hasClear
          />
        </Grid>
        {(values.whenInvoiced || values.invoiceNumber) && !isNewEstimate && (
          <>
            {values.whenInvoiced && (
              <Grid item xs={12} md={4}>
                <MuiTextField
                  disabled
                  name="whenInvoiced"
                  label="When Invoiced"
                  fullWidth
                  size="small"
                  value={formatDate(values.whenInvoiced)}
                />
              </Grid>
            )}
            {values.invoiceNumber && (
              <Grid item xs={12} md={4}>
                <TextField disabled name="invoiceNumber" label="Invoice Number" />
              </Grid>
            )}
          </>
        )}
        <Grid
          item
          xs={12}
          md={isNewEstimate || values.whenInvoiced || values.invoiceNumber ? 6 : 4}
        >
          <DatePicker
            label="When Expires"
            format="MM/dd/yyyy"
            onChange={(date: any) => {
              if (estimate.hasEstimateAgreementBeenSigned) {
                if (isBefore(new Date(date), new Date(estimate.whenAgreementSigned))) {
                  return enqueueSnackbar(
                    `Estimate has been signed, expiration date can't be before signed date`,
                    {
                      variant: 'warning',
                    }
                  );
                }
              }
              setFieldValue('whenExpires', date);
            }}
            value={values.whenExpires ? new Date(values.whenExpires) : null}
            minDate={new Date()}
            slotProps={{
              textField: {
                name: 'whenExpires',
                error: errors?.whenExpires && touched?.whenExpires,
                required: true,
                size: 'small',
                fullWidth: true,
              },
            }}
          />
        </Grid>
        {!isNewEstimate && (
          <Grid item xs={12} md={values.whenInvoiced || values.invoiceNumber ? 6 : 4}>
            <FormControl fullWidth>
              <InputLabel id="userName">Created By</InputLabel>
              <Select
                labelId="userName"
                id="userName"
                value={values.userName}
                label="Created By"
                disabled
                size="small"
              >
                <MenuItem value={values.userName}>{values.userName}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TextField
            name="details"
            label="Estimate Details"
            multiline
            minRows={4}
            disabled={estimate.hasEstimateAgreementBeenSigned}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="serviceNotes" label="Service Notes" multiline minRows={4} />
        </Grid>
      </Grid>
    </Card>
  );
};
