import { Box, Button, Typography, Grid, Divider, Stack, Collapse } from '@mui/material';
import { Card, DisplayGroup, ExternalLink, Loader, ExpandMoreButton } from '../../../components';
import { FC, forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { createAdyenHostedOnboarding, getAdyenDetails } from '../../../fetch';
import { UserContext } from '../../../context';
import { capital } from 'case';
import { Paths } from '../../../constants';
import { AdyenVerificationStatus, PaymentProcessor } from '../../../models';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const AdyenOnboarding: FC = () => {
  const { user, paymentProcessor } = useContext(UserContext);
  const [open, setOpen] = useState<{ [label: string]: boolean }>({});
  const flags = useFlags();

  const { data: adyenDetails, isFetching } = useQuery(
    'adyenDetails-onboarding',
    () => getAdyenDetails(user?.officeId!),
    {
      enabled: !!(paymentProcessor?.paymentProcessor === PaymentProcessor.Adyen && flags?.adyen),
    }
  );

  const {
    data: onBoardingLink,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: () =>
      createAdyenHostedOnboarding({
        officeId: user?.officeId!,
        redirectPath: Paths.paymentSetup.url,
      }),
  });
  useEffect(() => {
    if (
      // make sure Ayden is the payment processor and onboarding connection is valid
      user &&
      paymentProcessor &&
      paymentProcessor.paymentProcessor === PaymentProcessor.Adyen &&
      adyenDetails?.isOnboarded &&
      flags?.adyen
    ) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, paymentProcessor, adyenDetails, flags.adyen]);

  const hasValidAdyenOnboarding = useMemo(
    () =>
      adyenDetails?.data.every(data => data.verificationStatus === AdyenVerificationStatus.Valid),
    [adyenDetails]
  );

  if (
    paymentProcessor &&
    paymentProcessor.paymentProcessor === PaymentProcessor.Adyen &&
    flags?.adyen
  ) {
    return (
      <Card
        cardTitleProps={{
          title: 'Adyen Onboarding',
        }}
      >
        {(isLoading || isFetching) && <Loader type="overlay" position="centered" />}
        <Stack gap={2}>
          {!isFetching && !adyenDetails?.isOnboarded && (
            <Typography>
              Initial Adyen onboarding configuration not yet complete. Please refresh this page to
              check the status after a few minutes.
            </Typography>
          )}
          {!isFetching && adyenDetails?.isOnboarded && (
            <>
              <Typography>
                Welcome, click the button below to{' '}
                {hasValidAdyenOnboarding
                  ? 'view Adyen configuration.'
                  : `${
                      adyenDetails?.isOnboarded ? 'finish' : 'start'
                    } the process of Adyen Hosted Onboading.`}
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  component={forwardRef((props: any, _ref) => {
                    return <ExternalLink {...props} type="white" />;
                  })}
                  to={onBoardingLink ?? ''}
                  color="primary"
                  size="large"
                >
                  {hasValidAdyenOnboarding
                    ? 'Adyen Configuration'
                    : `${adyenDetails?.isOnboarded ? 'Finish' : 'Start'} Adyen Onboarding`}
                </Button>
              </Box>

              <Divider />
              <Box>
                <Box mb={1}>
                  <Typography variant="h6">Details</Typography>
                </Box>
                <Grid container spacing={2}>
                  {adyenDetails.data.map(data => {
                    return (
                      <Grid item xs={12} sm={6} key={data.label}>
                        <DisplayGroup label={data.label} component="div">
                          <Typography>
                            <Typography sx={{ fontWeight: 'bold' }} component="span">
                              Enabled:
                            </Typography>{' '}
                            {data?.enabled ? 'Yes' : 'No'}
                          </Typography>
                          <Typography>
                            <Typography sx={{ fontWeight: 'bold' }} component="span">
                              Allowed:{' '}
                            </Typography>
                            {data?.allowed ? 'Yes' : 'No'}
                          </Typography>
                          <Typography>
                            <Typography sx={{ fontWeight: 'bold' }} component="span">
                              Verification Status:
                            </Typography>{' '}
                            {data?.verificationStatus ? (
                              <Typography
                                sx={{
                                  color: theme =>
                                    data?.verificationStatus === 'invalid'
                                      ? theme.palette.error.main
                                      : theme.palette.success.main,
                                }}
                                component="span"
                              >
                                {capital(data?.verificationStatus)}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Typography>
                          {data?.problems?.length > 0 && (
                            <Box>
                              <Typography sx={{ fontWeight: 'bold' }} component="span">
                                Problems
                                <ExpandMoreButton
                                  sx={{ marginLeft: '0.5rem', padding: 0 }}
                                  expand={open[data.label]}
                                  setExpanded={() =>
                                    setOpen({
                                      ...open,
                                      [data.label]: !open[data.label],
                                    })
                                  }
                                  className="print--none"
                                />
                              </Typography>{' '}
                              <Collapse in={open[data.label]} timeout="auto" unmountOnExit>
                                {data?.problems?.map((problem, index) => (
                                  <Typography
                                    key={problem.message}
                                    component="span"
                                    sx={{
                                      color: theme => theme.palette.error.main,
                                      mb: '0.5rem',
                                      display: 'block',
                                    }}
                                  >
                                    {problem.message}
                                  </Typography>
                                ))}
                              </Collapse>
                            </Box>
                          )}
                        </DisplayGroup>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </>
          )}
        </Stack>
      </Card>
    );
  }
  return null;
};
