import { Box, Button, Grid, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { AdyenDropIn } from './adyen-drop-in';
import { convertToNumber, generateUUID } from '../../helpers';
import { CancelIcon } from '../icon';

interface IAdyenDropInWrapperProps {
  isValid: boolean;
  handleClose: () => void;
  accountId: string;
  repairId?: string;
  transactionId?: string;
  amount: string | number;
  afterPaymentComplete?: (adyenReference: string) => void;
  handleReset?: () => void;
  isForSecurityDeposit?: boolean;
  isForAdditionalSecurityDeposit?: boolean;
}

export const AdyenDropInWrapper: FC<IAdyenDropInWrapperProps> = ({
  isValid,
  handleClose,
  accountId,
  repairId,
  amount,
  afterPaymentComplete,
  handleReset,
  isForSecurityDeposit,
  isForAdditionalSecurityDeposit,
  transactionId,
}) => {
  const [shouldCallAdyenSession, setShouldCallAdyenSession] = useState<boolean>(false);
  const [isShowingSession, setIsShowingSession] = useState<boolean>(false);
  const [guid, setGuid] = useState<string>('');

  return (
    <>
      <Stack
        gap={1}
        display={'flex'}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="flex-end"
        mt={1}
        mb={1}
        data-testid="AdyenDropInWrapper.Buttons"
      >
        {isShowingSession && (
          <Button
            type="button"
            onClick={() => {
              setShouldCallAdyenSession(false);
              setIsShowingSession(false);
              setGuid('');
              handleReset?.();
            }}
            color="inherit"
          >
            Reset
          </Button>
        )}
        <Button
          type="button"
          color="secondary"
          disabled={!isValid && amount !== '0'}
          onClick={() => {
            setShouldCallAdyenSession(true);
            setIsShowingSession(true);
            setGuid(generateUUID());
            setTimeout(() => setShouldCallAdyenSession(false), 1000);
          }}
        >
          {isShowingSession ? 'Update Payment' : 'Continue to Payment'}
        </Button>
      </Stack>
      <Grid container spacing={2} data-testid="AdyenDropInWrapper">
        <Grid item xs={12} key={guid}>
          <AdyenDropIn
            amount={convertToNumber(amount)}
            accountId={accountId}
            repairId={repairId}
            transactionId={transactionId}
            isForSecurityDeposit={isForSecurityDeposit}
            isForAdditionalSecurityDeposit={isForAdditionalSecurityDeposit}
            shouldCallAdyenSession={shouldCallAdyenSession}
            setIsShowingSession={setIsShowingSession}
            reference={guid}
            createAdyenTransRecord={false}
            afterPaymentComplete={() => {
              afterPaymentComplete?.(guid);
            }}
          />
        </Grid>
      </Grid>
      {isShowingSession && (
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={{ xs: 2, sm: 1 }}>
          <Button
            type="button"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
        </Box>
      )}
    </>
  );
};
