import { Box, Button, Grid, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useCallback, useContext } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import {
  Card,
  ServerSideDataGrid,
  useDataGrid,
  GridDataFetcher,
  CardFiltersLayout,
  UserAutocomplete,
  FilterButtons,
} from '../../components';
import { IValidateAddress, IValidateAddressRes, IListUser, IAccountDetail } from '../../models';
import { getAccount, getSite, getVerifyAddresses } from '../../fetch';
import { faFilter, faFilterCircleXmark, faEdit } from '@fortawesome/free-solid-svg-icons';
import { verifyAddressesColumns } from './verify-addresses-columns';
import { UserContext } from '../../context';
import { SiteAddressModal } from '../sites/site-address-modal';
import { checkForEnterKey, dateStringNull } from '../../helpers';

export const VerifyAddressesPageDetails = () => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedUser, setSelectedUser] = useState<IListUser | null>(null);
  const [selectedServiceDate, setSelectedServiceDate] = useState<dateStringNull>(null);
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState<any>(undefined);
  const [selectedAccount, setSelectedAccount] = useState<IAccountDetail | undefined>(undefined);
  const [isLoadingAccount, setLoadingAccount] = useState(false);
  const [filters, setFilters] = useState<{
    userId?: string | null;
    serviceDate?: string | null;
  } | null>({});

  const dataFetcher: GridDataFetcher<IValidateAddress> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res: IValidateAddressRes = await getVerifyAddresses({
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          userId: filters?.userId,
          officeId: user?.officeId,
          serviceDate: filters?.serviceDate,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading verified addresses, please try again.`, {
          variant: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );
  const {
    rows: items,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchVerifyAddress,
  } = useDataGrid<IValidateAddress>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'verify-address-grid',
      sortColumn: 'whenVerified',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const handleEdit = async (site: IValidateAddress) => {
    try {
      setLoadingAccount(true);
      setShowAddressModal(true);
      const siteRes = await getSite(site.siteId);
      const account = await getAccount(site.accountId);
      setSelectedSite(siteRes);
      setSelectedAccount(account);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error, please try again.`, {
        variant: 'error',
      });
    } finally {
      setLoadingAccount(false);
    }
  };

  return (
    <>
      <Card
        cardTitleProps={{
          title: '',
          action: (
            <>
              <Button
                onClick={() => setIsShowingFilters(!isShowingFilters)}
                className="print--none"
                size="small"
                color="secondary"
                disabled={isLoading}
                startIcon={
                  <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                }
              >
                Filters
              </Button>
            </>
          ),
          children: (
            <>
              <CardFiltersLayout isOpen={isShowingFilters}>
                <Grid 
                  container 
                  spacing={2}
                  onKeyDown={(e) => {
                    checkForEnterKey(e, () => {
                      setFilters({
                        userId: selectedUser?.userId ?? undefined,
                        serviceDate: selectedServiceDate
                          ? new Date(selectedServiceDate).toISOString()
                          : undefined,
                      });
                      onPageChange(0);
                      setHasAppliedFilters(true);
                    });
                  }}
                >
                  <Grid item xs={12} sm={6}>
                    <UserAutocomplete
                      setSelectedUser={val => setSelectedUser(val)}
                      selectedUser={selectedUser}
                      isRequired={false}
                      labelText="Technician"
                    />
                  </Grid>

                  <FilterButtons
                    hasAppliedFilters={hasAppliedFilters}
                    isDisabled={isLoading}
                    handleApplyFilters={() => {
                      setFilters({
                        userId: selectedUser?.userId ?? undefined,
                        serviceDate: selectedServiceDate
                          ? new Date(selectedServiceDate).toISOString()
                          : undefined,
                      });
                      onPageChange(0);
                      setHasAppliedFilters(true);
                    }}
                    handleResetFilters={() => {
                      onPageChange(0);
                      setFilters({});
                      setSelectedUser(null);
                      setSelectedServiceDate(null);
                      setHasAppliedFilters(false);
                    }}
                  />
                </Grid>
              </CardFiltersLayout>
            </>
          ),
        }}
      >
        <Box>
          <ServerSideDataGrid
            autoHeight
            getRowId={(row: IValidateAddress) => row.siteId}
            rows={items}
            columns={[
              ...verifyAddressesColumns,
              {
                field: 'actions',
                headerName: '',
                flex: 1,
                align: 'center',
                renderCell: (params: GridRenderCellParams<IValidateAddress>) => {
                  const { row: site } = params;
                  return site?.whenVerified ? null : (
                    <IconButton size="small" color="primary" onClick={() => handleEdit(site)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </IconButton>
                  );
                },
              },
            ]}
            loading={isLoading}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            disableColumnFilter
            hasMobileLayout
            mobileProps={{
              type: 'verifyAddresses',
              handleEdit: (val: IValidateAddress) => {
                handleEdit(val);
              },
            }}
          />
        </Box>
      </Card>
      <SiteAddressModal
        isOpen={showAddressModal}
        handleClose={(shouldUpdate?: boolean) => {
          setShowAddressModal(false);
          if (shouldUpdate) {
            refetchVerifyAddress();
          }
        }}
        site={selectedSite}
        accountId={selectedAccount?.accountId}
        accountAddress={selectedAccount?.address}
        isLoading={isLoadingAccount}
      />
    </>
  );
};
