import { FC, Dispatch, useContext, SetStateAction, useState, useMemo, useEffect } from 'react';
import {
  Alert,
  Box,
  TextField as MuiTextField,
  Grid,
  useMediaQuery,
  Button,
  Typography,
  Divider,
} from '@mui/material';
import { Form, useFormikContext } from 'formik';
import { UserContext } from '../../context';
import {
  Modal,
  Loader,
  Pool360Search,
  TextField,
  SelectAsync,
  ModalSaveSection,
  DisplayGroup,
  AmountInput,
} from '../../components';
import { getInventoryItemCost, getPoolCommerceRealTimePricing, getAllTranCodes } from '../../fetch';
import { ITranCode, ISelectOption, IFormLineItem } from '../../models';
import { DECIMAL_REGEX, formatMoney, convertToNumber } from '../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSnackbar } from 'notistack';
import { theme } from '../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface ILineItemsModalProps {
  isOpen: boolean;
  isSaveDisabled?: boolean;
  isEditable?: boolean;
  isLoading?: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  handleCancel: () => void;
  handleInventoryItemSelect: (item: any, tranCodeId: string) => void;
  setTranCodes?: Dispatch<SetStateAction<ISelectOption[]>>;
  isEstimate?: boolean;
  itemId?: string;
  currentLineNumbers?: number[];
  lineItem: IFormLineItem;
  lineItemType?: string;
}

export const LineItemsModal: FC<ILineItemsModalProps> = ({
  isOpen,
  onClose,
  isSaveDisabled,
  handleInventoryItemSelect,
  handleCancel,
  isLoading,
  setTranCodes,
  itemId,
  currentLineNumbers,
  lineItem,
  isEditable = true,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery('(max-width: 899px)');
  const [localCodes, setLocalCodes] = useState<ISelectOption[] | null>(null);
  const [hasSelectedItem, setHasSelectedItem] = useState(!!itemId ? true : false);
  const { user } = useContext(UserContext);
  const { errors, values, touched, setFieldValue, handleBlur } = useFormikContext<IFormLineItem>();
  const showAddtionalField =
    localCodes?.find(code => code.value === values.tranCodeId)?.label === 'Department';
  const departmentId = localCodes?.filter(val => val.label === 'Department')?.[0]?.value;
  const currentFormOrderNumber = Number(values.sortOrder);
  const currentOrderNumber = Number(lineItem.sortOrder);
  const hasSameOrderNumber = !itemId
    ? // if we are adding a new line item check its current form value with previous existing line item values
      currentLineNumbers?.includes(currentFormOrderNumber)
    : currentLineNumbers?.includes(currentFormOrderNumber) &&
      currentOrderNumber !== currentFormOrderNumber;

  const { v2InventoryReport } = useFlags();

  const isFieldDisabled = itemId ? false : values.tranCodeId === departmentId && !hasSelectedItem;
  const [isLoadingCost, setIsLoadingCost] = useState<boolean>(false);
  const [currentCost, setCurrentCost] = useState<number | null>(null);
  const getPoolCommerceCost = async (id: string | number) => {
    try {
      setIsLoadingCost(true);
      const res = await getPoolCommerceRealTimePricing({
        productPriceParameters: [
          {
            productId: id,
            unitOfMeasure: null,
          },
        ],
      });
      if (
        res.realTimePricingResults?.[0]?.unitNetPrice ||
        res.realTimePricingResults?.[0]?.unitNetPrice === 0
      ) {
        setCurrentCost(res.realTimePricingResults?.[0]?.unitNetPrice);
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail || `Error loading cost. Please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingCost(false);
    }
  };
  const getLocalInventoryCost = async (id: string | number) => {
    try {
      setIsLoadingCost(true);
      const res = await getInventoryItemCost(id);
      if (res || res === 0) {
        setCurrentCost(res);
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail || `Error loading cost. Please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingCost(false);
    }
  };
  const convertRate = (rate: string | number) => {
    const value = String(rate).replace('$', '').replace(',', '');
    // make sure it is number only
    if (value === '' || DECIMAL_REGEX.test(value)) {
      return Number(value);
    }
    return 0;
  };
  const calculatedMargin = useMemo(() => {
    const price = values.rate ? convertRate(values.rate) : 0;
    if (!!price && !!currentCost) {
      return Math.round(((price - currentCost) / price) * 100);
    } else {
      return undefined;
    }
  }, [values.rate, currentCost]);

  useEffect(() => {
    if (hasSelectedItem && !isOpen) {
      setHasSelectedItem(false);
    }
    !isOpen && setCurrentCost(null);
  }, [isOpen, hasSelectedItem]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        setHasSelectedItem(false);
        setCurrentCost(null);
      }}
      title={!!itemId && !isEditable ? 'View Item' : !!itemId ? 'Edit Item' : 'Add Item'}
      maxWidth="lg"
      fullHeight
      PaperProps={{
        style: {
          scrollbarGutter: 'stable',
        },
      }}
    >
      <Box mt={1}>
        <Grid container spacing={2}>
          {isEditable && v2InventoryReport && (
            <Grid item xs={12} md={6}>
              <Pool360Search
                onSelectInventoryItem={item => {
                  handleInventoryItemSelect(item, values.tranCodeId);
                  setHasSelectedItem(true);
                  setCurrentCost(null);
                }}
              />
            </Grid>
          )}
          {isMobile && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Form>
              {isLoading && <Loader position="centered" title="Loading" type="overlay" />}
              <>
                {isEditable && isFieldDisabled && (
                  <Alert severity="warning">
                    Search and select an item to enable the rest of the fields or add an inventory
                    item
                  </Alert>
                )}
                <Box my={2}>
                  <MuiTextField
                    fullWidth
                    name="sortOrder"
                    required
                    size="small"
                    label="Line"
                    type="number"
                    onKeyDown={event => {
                      if (
                        !/[\d]/.test(event.key) &&
                        !/Backspace|Delete|ArrowLeft|ArrowRight/.test(event.key)
                      ) {
                        event.preventDefault();
                      }
                    }}
                    value={values.sortOrder}
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue('sortOrder', e.target.value);
                    }}
                    disabled={isFieldDisabled || !isEditable}
                    error={hasSameOrderNumber ? true : touched.sortOrder && !!errors.sortOrder}
                    helperText={
                      hasSameOrderNumber
                        ? 'Line is already in use'
                        : touched.sortOrder && errors.sortOrder
                        ? errors.sortOrder
                        : ''
                    }
                    InputProps={
                      !isFieldDisabled || isEditable
                        ? {
                            onFocus: e => {
                              e.currentTarget.select();
                            },
                          }
                        : undefined
                    }
                  />
                </Box>
                <Box my={2}>
                  <SelectAsync
                    name="tranCodeId"
                    label="Tran Code"
                    required
                    value={values.tranCodeId}
                    apiRequest={() =>
                      getAllTranCodes({
                        officeId: user?.officeId,
                        perPage: -1,
                        tranCodeType: 'Invoice',
                      })
                    }
                    transformResponse={response =>
                      response.records.map((item: ITranCode) => ({
                        label: item.description,
                        value: item.tranCodeId,
                      }))
                    }
                    handleOptions={data => {
                      if (!itemId) {
                        // for pinch service default to department because there is both "Department" and "Monthly Pool Service"
                        const filteredDepartmentId = data.filter(
                          val => val.label === 'Department'
                        )?.[0]?.value;
                        // for pool service 360 default to mps since there is only one
                        const mpsId = data.filter(val => val.label === 'Monthly Pool Service')?.[0]
                          ?.value;
                        setFieldValue('tranCodeId', filteredDepartmentId || mpsId);
                      }
                      setTranCodes && setTranCodes(data);
                      setLocalCodes(data);
                    }}
                    onChange={e => {
                      if (e.target.value !== departmentId && !itemId) {
                        setFieldValue('details', 'Monthly Pool Service');
                        setFieldValue('quantity', '1');
                      }
                      setFieldValue('tranCodeId', e.target.value);
                      setFieldValue('lookupCode', '');
                      setFieldValue('serialNumber', '');
                    }}
                    disabled={!!itemId ? values.tranCodeId === departmentId : !!values.lookupCode}
                  />
                </Box>
                {showAddtionalField && (
                  <Box my={2}>
                    <TextField name="lookupCode" label="Lookup Code" disabled />
                  </Box>
                )}
                <Box my={2}>
                  <TextField
                    name="details"
                    label="Details"
                    required
                    disabled={isFieldDisabled || !isEditable}
                    autoSelectOnFocus={!isFieldDisabled || isEditable}
                  />
                </Box>
                {showAddtionalField && (
                  <Box my={2}>
                    <TextField
                      name="serialNumber"
                      label={lineItem.isSerialized ? 'Serial # required for invoicing' : 'Serial #'}
                      disabled={isFieldDisabled || !isEditable}
                      autoSelectOnFocus={!isFieldDisabled || isEditable}
                    />
                  </Box>
                )}
                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      flex={1}
                      xs={12}
                      sm={lineItem?.poolCommerceInventoryId || lineItem?.storeInventoryId ? 4 : 12}
                    >
                      <AmountInput
                        name="rate"
                        label="Price"
                        required
                        value={values.rate}
                        onChange={val => setFieldValue('rate', val)}
                        disabled={isFieldDisabled || !isEditable}
                        onBlur={handleBlur}
                        autoSelectOnFocus={!isFieldDisabled || isEditable}
                      />
                    </Grid>
                    {(lineItem?.poolCommerceInventoryId || lineItem?.storeInventoryId) && (
                      <>
                        <Grid container item sx={{ minWidth: '9.25rem' }} xs={12} sm={8} rowGap={2}>
                          <Grid
                            container
                            item
                            spacing={2.5}
                            xs={12}
                            sm={6}
                            justifyContent={{ xs: 'center' }}
                          >
                            {isLoadingCost && (
                              <Loader
                                position="centered"
                                type="inline"
                                style={{ paddingTop: theme.spacing(2) }}
                              />
                            )}
                            {!isLoadingCost && (
                              <>
                                <Grid item xs="auto">
                                  {(currentCost || currentCost === 0) && (
                                    <DisplayGroup
                                      label="Cost"
                                      wrapperStyles={{
                                        marginTop: '-.35rem',
                                        marginBottom: '-.35rem',
                                      }}
                                    >
                                      {formatMoney(currentCost, 2)}
                                    </DisplayGroup>
                                  )}
                                </Grid>
                                <Grid item xs="auto">
                                  {!!calculatedMargin && (
                                    <DisplayGroup
                                      label="Margin"
                                      wrapperStyles={{
                                        marginTop: '-.35rem',
                                        marginBottom: '-.35rem',
                                      }}
                                    >
                                      {`${calculatedMargin}%`}
                                    </DisplayGroup>
                                  )}
                                </Grid>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => {
                                if (currentCost || currentCost === 0) {
                                  setCurrentCost(null);
                                } else {
                                  if (lineItem?.poolCommerceInventoryId) {
                                    getPoolCommerceCost(lineItem.poolCommerceInventoryId);
                                  } else if (lineItem?.storeInventoryId) {
                                    getLocalInventoryCost(lineItem?.storeInventoryId);
                                  }
                                }
                              }}
                              size="large"
                              disabled={isLoadingCost}
                              sx={{ width: '100%', fontSize: '14px' }}
                              startIcon={
                                <FontAwesomeIcon
                                  style={{ fontSize: '16px' }}
                                  icon={currentCost || currentCost === 0 ? faEyeSlash : faEye}
                                />
                              }
                            >
                              <Typography
                                sx={{ whiteSpace: 'nowrap' }}
                                fontSize={theme.spacing(1.75)}
                              >
                                {currentCost || currentCost === 0 ? 'Hide Cost' : 'Show Cost'}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
                <Box my={2}>
                  <MuiTextField
                    fullWidth
                    size="small"
                    type="number"
                    name="quantity"
                    label="Quantity"
                    required
                    value={values.quantity}
                    onChange={e => {
                      setFieldValue('quantity', e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.quantity &&
                      lineItem.isSerialized &&
                      convertToNumber(values.quantity) !== 1 &&
                      convertToNumber(values.quantity) !== -1
                    }
                    helperText={
                      touched.quantity &&
                      lineItem.isSerialized &&
                      convertToNumber(values.quantity) !== 1 &&
                      convertToNumber(values.quantity) !== -1
                        ? 'Value must be 1 or -1 for serialized items'
                        : ''
                    }
                    disabled={isFieldDisabled || !isEditable}
                    InputProps={
                      !isFieldDisabled || isEditable
                        ? {
                            onFocus: e => {
                              e.currentTarget.select();
                            },
                          }
                        : undefined
                    }
                  />
                </Box>
              </>
              <ModalSaveSection
                cancelLabel="Cancel & Close"
                submitLabel="Save Line Item"
                isSaveDisabled={
                  isSaveDisabled ||
                  hasSameOrderNumber ||
                  !isEditable ||
                  isLoadingCost ||
                  (touched.quantity &&
                    lineItem.isSerialized &&
                    convertToNumber(values.quantity) !== 1 &&
                    convertToNumber(values.quantity) !== -1)
                }
                handleCancel={() => {
                  handleCancel();
                  setHasSelectedItem(false);
                  setCurrentCost(null);
                }}
              />
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
