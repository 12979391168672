import { MenuItem, TextField } from '@mui/material';
import { daysOfMonthPayment } from '../../helpers';
import { FC } from 'react';

interface IPaymentDayofMonth {
  onChange: (val: string | number) => void;
  value: string | number;
  disabled?: boolean;
}

export const PaymentDayofMonth: FC<IPaymentDayofMonth> = ({ onChange, value, disabled }) => {
  return (
    <TextField
      label="Day of Month"
      name="dayOfMonth"
      required
      size="small"
      fullWidth
      select
      onChange={(e: any) => {
        onChange(e.target.value);
      }}
      value={value}
      disabled={disabled}
    >
      {daysOfMonthPayment.map(day => (
        <MenuItem key={day} value={day}>
          {day === 0 ? 'Last' : day}
        </MenuItem>
      ))}
    </TextField>
  );
};
