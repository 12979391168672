import { FC, useMemo } from 'react';
import {
  ExternalLink,
  Link,
  ServerSideDataGrid,
  ServerSideDataGridProps,
  TableActionsMenu,
} from '../../../components';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { getLegacyUrl, formatDate, formatTime, hyphenSeparateTwoInputs } from '../../../helpers';
import { IRepair } from '../../../models';
import { format } from 'date-fns';
import { ListItem, ListItemButton, useMediaQuery } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface RepairVisitsDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IRepair[];
  refetch: () => Promise<void>;
  redirect?: string;
}

export const RepairVisitsDataGrid: FC<RepairVisitsDataGridProps> = ({
  rows,
  refetch,
  redirect,
  ...gridProps
}) => {
  const isMidMobile = useMediaQuery('(min-width: 960px) and (max-width: 1200px)');
  const { v2Customers } = useFlags();
  const legacyUrl = getLegacyUrl?.();

  const columns = useMemo(() => {
    return [
      {
        field: 'serviceDate',
        headerName: 'Date',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: visit } = params;
          return (
            <Link to={`/services/ots/${visit?.repairId}${redirect ? `?redirect=${redirect}` : ''}`}>
              {formatDate(visit.serviceDate)}
            </Link>
          );
        },
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: visit } = params;
          return (
            <Link
              to={`/customers/${visit.accountId}/sites?siteId=${visit.siteId}${
                redirect ? `?redirect=${redirect}` : ''
              }`}
            >
              {visit.siteName}
            </Link>
          );
        },
      },
      {
        field: 'serviceType',
        headerName: 'Type',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'assignedTo',
        headerName: 'Tech',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      !isMidMobile && {
        field: 'serviceTime',
        headerName: 'Service Window',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: visit } = params;
          if (!visit.startTime && !visit.endTime) {
            return <></>;
          }
          return (
            <>{`${format(new Date(visit.startTime), 'h:mma')} - ${format(
              new Date(visit.endTime),
              'h:mma'
            )}`}</>
          );
        },
      },
      {
        field: 'whenStarted',
        headerName: 'Started',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: original } = params;
          if (formatDate(original.serviceDate) === formatDate(original.whenStarted)) {
            return <span>{formatTime(original.whenStarted)}</span>;
          }
          return formatDate(original.whenStarted);
        },
      },
      {
        field: 'whenCompleted',
        headerName: 'Completed',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: original } = params;
          if (formatDate(original.serviceDate) === formatDate(original.whenCompleted)) {
            return <span>{formatTime(original.whenCompleted)}</span>;
          }
          return formatDate(original.whenCompleted);
        },
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IRepair>) => {
          const { row: visit } = params;
          const renderSiteListItem = (
            <ListItem disablePadding>
              <ListItemButton>Edit Site</ListItemButton>
            </ListItem>
          );
          return (
            <TableActionsMenu labelContext="OTS" id={`action-menu-${visit.repairId}`}>
              <Link
                to={`/services/ots/${visit?.repairId}${redirect ? `?redirect=${redirect}` : ''}`}
              >
                <ListItem disablePadding>
                  <ListItemButton>Edit OTS</ListItemButton>
                </ListItem>
              </Link>
              {v2Customers ? (
                <>
                  <Link
                    to={`/customers/${visit?.accountId}/sites?siteId=${visit.siteId}${
                      redirect ? `&redirect=${redirect}` : ''
                    }`}
                  >
                    <ListItem disablePadding>
                      <ListItemButton>View Site</ListItemButton>
                    </ListItem>
                  </Link>
                  <Link
                    to={`/customers/${visit.accountId}/sites?siteId=${visit.siteId}&isEdit=true${
                      redirect ? `&redirect=${redirect}` : ''
                    }`}
                  >
                    {renderSiteListItem}
                  </Link>
                </>
              ) : (
                <ExternalLink to={`${legacyUrl}/Office/Account/View/${visit?.accountId}`}>
                  {renderSiteListItem}
                </ExternalLink>
              )}
            </TableActionsMenu>
          );
        },
      },
    ].filter(Boolean) as GridColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, isMidMobile]);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: IRepair) => row.repairVisitId!}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      columnHeaderHeight={36}
      hasMobileLayout
      mobileProps={{
        mobileCustomDefaultAccessor: (row: IRepair) => {
          return hyphenSeparateTwoInputs(
            (row?.serviceDate && `${formatDate(row?.serviceDate)} ${row.serviceType}`) ?? '',
            row?.siteName ?? ''
          );
        },
        showHandleActions: true,
        truncateAccordionLabel: true,
      }}
      {...gridProps}
    />
  );
};
