import { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { useSnackbar } from 'notistack';
import { Box, Fade, Stack } from '@mui/material';
import { Loader } from '../loader';
import { IDocument, IDocumentPUT } from '../../models';
import { DisplayGroup, Modal, ModalSaveSection } from '..';
import { TextField } from '../formikMui';
import { DocumentUpload } from '../file/document-upload';
import { useConfirm } from '../../hooks';
import { defaultUnsavedChangesMessage } from '../../constants';

export interface IDocumentModal {
  isOpen: boolean;
  onClose: () => void;
  selectedDocument: IDocument | null;
  isLoading?: boolean;
  reloadList: () => void;
  title: string;
  postApiRequest: (
    id: string | number,
    payload: File | string,
    description?: string
  ) => Promise<any>;
  putApiRequest: (payload: IDocumentPUT, accountDocumentId: string | number) => Promise<any>;
  inputId: string;
  postFilterId: string | number;
}

const DocumentSchema = Yup.object().shape({
  file: Yup.mixed().required(
    'File is required. Accepted formats include PDF, DOC, DOCX, XLS, XLSX, and TIFF.'
  ),
  description: Yup.string().max(255, 'Max 255 characters').nullable(),
});

export const DocumentModal: FC<IDocumentModal> = ({
  isOpen,
  onClose,
  selectedDocument,
  isLoading,
  reloadList,
  title,
  postApiRequest,
  putApiRequest,
  inputId,
  postFilterId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          file: selectedDocument?.fileLocation ?? '',
          description: selectedDocument?.description ?? '',
        }}
        data-testid="DocumentModalForm"
        validationSchema={DocumentSchema}
        onSubmit={async (values, actions) => {
          try {
            selectedDocument
              ? await putApiRequest(
                  { description: values.description },
                  selectedDocument.accountDocumentId
                )
              : await postApiRequest(postFilterId, values.file, values.description);

            enqueueSnackbar(`Successfully ${!selectedDocument ? 'created' : 'updated'} document!`, {
              variant: 'success',
            });
            onClose();
            reloadList();
            actions.resetForm();
          } catch (err: any) {
            if (err?.data?.Detail) {
              enqueueSnackbar(err.data.Detail, { variant: 'error' });
            } else {
              enqueueSnackbar(JSON.stringify(err), { variant: 'error' });
            }
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          handleSubmit,
          dirty,
          isValid,
          errors,
          touched,
        }) => {
          const reset = async (resetForm: any) => {
            if (!deepEqual(initialValues, values)) {
              const result = await confirm(defaultUnsavedChangesMessage);
              if (result) {
                resetForm();
                onClose();
              } else {
                return;
              }
            } else {
              onClose();
              resetForm();
            }
          };

          return (
            <Modal
              open={isOpen}
              onClose={() => {
                reset(resetForm);
              }}
              maxWidth="sm"
              data-testid="DocumentModal"
              title={selectedDocument ? `Edit ${title}` : `Add ${title}`}
            >
              {(isSubmitting || isLoading) && (
                <Loader type="overlay" position="centered" title="Saving..." />
              )}
              <Fade in={isOpen}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box mt={1}>
                    <Stack gap={2}>
                      <Box>
                        {selectedDocument ? (
                          <DisplayGroup label="Document File" labelId="document-file">
                            {selectedDocument?.originalFileName}
                          </DisplayGroup>
                        ) : (
                          <>
                            <DocumentUpload
                              id={inputId}
                              label="Document File"
                              handleFileChange={async val => {
                                setFieldValue('file', val.file);
                              }}
                              disabled={isSubmitting}
                              isEditable={!selectedDocument}
                              isRequired
                            />
                          </>
                        )}
                      </Box>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        size="small"
                        autoComplete="nope"
                        label="Description"
                        name="description"
                      />
                    </Stack>
                    <ModalSaveSection
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                      submitLabel={selectedDocument ? 'Save' : `Add ${title}`}
                      handleCancel={() => {
                        reset(resetForm);
                      }}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
