import { Box, Stack, FormControlLabel, Checkbox, Button, styled } from '@mui/material';
import { TextField, Card, Signature } from '../../../components';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useState, FC, Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { IAgreementInitialValues } from './contract-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrandingContext } from '../../../context/branding-context';

interface ISignatureCard {
  hasSubmitted: boolean;
  values: IAgreementInitialValues;
  setFieldValue: (key: string, val: any) => void;
  isValid: boolean;
  isSubmitting: boolean;
  setSigPadInstance: Dispatch<SetStateAction<any>>;
  sigPadInstance: any;
  clearSigPadOnResize?: boolean;
  officeName?: string | null;
  handleAdyenAgreeAndContinue: () => void;
  display: boolean;
  isAdyen: boolean;
  isInvalidCreditCard: boolean;
}
export const SignatureCard: FC<ISignatureCard> = ({
  hasSubmitted,
  values,
  setFieldValue,
  isValid,
  isSubmitting,
  sigPadInstance,
  setSigPadInstance,
  clearSigPadOnResize = true,
  officeName,
  handleAdyenAgreeAndContinue,
  display = true,
  isAdyen,
  isInvalidCreditCard,
}) => {
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const { authorizeName, isPoolService } = useContext(BrandingContext);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    if (isInvalidCreditCard) {
      setFieldValue('hasConfirmedAuthorization', false);
      setSubmitButtonEnabled(false);
    }
    // TODO: Refactor this to be more readable
    if (
      (!values.creditCardRequired &&
        !isInvalidCreditCard &&
        values.hasConfirmedAuthorization &&
        values.cardNumber !== '') ||
      (values.creditCardRequired && !isInvalidCreditCard && values.hasConfirmedAuthorization) ||
      (!values.creditCardRequired &&
        values.cardNumber === '' &&
        !values.hasConfirmedAuthorization) ||
      (!values.creditCardRequired && values.cardNumber === '')
    ) {
      setSubmitButtonEnabled(true);
    } else {
      setFieldValue('hasConfirmedAuthorization', false);
      setSubmitButtonEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInvalidCreditCard,
    values.hasConfirmedAuthorization,
    values.creditCardRequired,
    values.cardNumber,
  ]);

  return (
    <Card
      cardTitleProps={{
        title: 'Signature',
      }}
      sxProps={{ display: display ? 'block' : 'none' }}
    >
      <Stack gap={2}>
        <TextField name="printedName" label="Printed Name" required disabled={hasSubmitted} />
        <Signature
          hasSubmitted={hasSubmitted}
          setSigPadInstance={setSigPadInstance}
          sigPadInstance={sigPadInstance}
          setIsSigned={setIsSigned}
          isSigned={isSigned}
          // when the window is resized, clear the signature pad when its visible in the UI otherwise keep the signature
          clearSigPadOnResize={display}
        />
        <StyledFormControlLabel
          control={<Checkbox name="aggreement" checked={values.hasConfirmedAgreement} />}
          label="BY SIGNING ABOVE, CUSTOMER AFFIRMS THAT CUSTOMER HAS READ AND UNDERSTANDS THE TERMS AND CONDITIONS OF THIS RESIDENTIAL SERVICE AGREEMENT, INCLUDING THE PARAGRAPHS RELATING TO WARRANTIES AND LIMITATION OF LIABILITY."
          sx={{ textTransform: 'uppercase' }}
          onChange={(_, checked) => setFieldValue('hasConfirmedAgreement', checked)}
          disabled={hasSubmitted}
          required
        />
        {(values.creditCardRequired || (values.cardNumber !== '' && !isInvalidCreditCard)) && (
          <StyledFormControlLabel
            control={<Checkbox name="authorization" checked={values.hasConfirmedAuthorization} />}
            label={`CUSTOMER AUTHORIZES ${
              isPoolService ? officeName : authorizeName
            } TO CHARGE THE LISTED CREDIT CARD FOR THE TOTAL SHOWN ABOVE`}
            sx={{ textTransform: 'uppercase' }}
            onChange={(_, checked) => setFieldValue('hasConfirmedAuthorization', checked)}
            disabled={hasSubmitted}
            required
          />
        )}
        {!hasSubmitted && (
          <Box>
            <Button
              color="primary"
              type={isAdyen ? 'button' : 'submit'}
              startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
              onClick={isAdyen ? handleAdyenAgreeAndContinue : undefined}
              disabled={
                !isSigned ||
                !isValid ||
                isSubmitting ||
                !values.hasConfirmedAgreement ||
                (values.creditCardRequired && !values.hasConfirmedAuthorization) ||
                !submitButtonEnabled
              }
            >
              {isAdyen ? 'Agree & Continue to Payment' : `Agree and Submit`}
            </Button>
          </Box>
        )}
      </Stack>
    </Card>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.pxToRem(12),
    },
  },
}));
