import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { deepEqual } from 'fast-equals';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Grid, FormControlLabel, Checkbox, Button, Stack, Alert } from '@mui/material';
import {
  CardTitle,
  TextField,
  Loader,
  SaveButton,
  Card,
  ModalSaveSection,
  CreditCardInputs,
  AdyenDropIn,
  PaymentDayofMonth,
  AmountInput,
} from '../../components';
import { convertToNumber, formatMoney, generateUUID } from '../../helpers';
import {
  IAccountDetail,
  IPaymentProcessorModel,
  IRecurringPayment,
  PaymentProcessor,
} from '../../models';
import {
  createPaymentCapture,
  postExternalInvoicePayment,
  postRecurringPayment,
  putRecurringPayments,
  postExternalRecurringPayment,
  postRecurringPaymentSignature,
} from '../../fetch';
import { usePaymentInputs } from 'react-payment-inputs';
import { useConfirm } from '../../hooks';
import { defaultUnsavedChangesMessage } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../context';

const CommonInfoSchema = {
  creditCardNumber: Yup.mixed().when(['adyen'], {
    is: (adyen: boolean) => !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  expirationMonth: Yup.mixed().when(['adyen'], {
    is: (adyen: boolean) => !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  expirationYear: Yup.mixed().when(['adyen'], {
    is: (adyen: boolean) => !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  cvv: Yup.mixed().when(['adyen'], {
    is: (adyen: boolean) => !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  street: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  adyen: Yup.boolean(),
};

const PaymentInfoSchema = Yup.object().shape({
  ...CommonInfoSchema,
  amount: Yup.mixed().when(['dayOfMonth'], {
    is: (dayOfMonth: string) => dayOfMonth && dayOfMonth !== '-1',
    then: Yup.string()
      .required('Required')
      .test('min', 'Must be positive', val => (val && convertToNumber(val) > 0 ? true : false)),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  invoiceNumber: Yup.string(),
  notes: Yup.string(),
  dayOfMonth: Yup.string(),
});
const RecurringPaymentInfoSchema = Yup.object().shape({
  ...CommonInfoSchema,
  payOutstandingBalance: Yup.boolean(),
  dayOfMonth: Yup.string().required('Required'),
  amount: Yup.mixed().when(['payOutstandingBalance'], {
    is: (payOutstandingBalance: boolean) => payOutstandingBalance,
    then: Yup.string().notRequired().nullable(),
    otherwise: Yup.string()
      .required('Required')
      .test('min', 'Must be positive', val => (val && convertToNumber(val) > 0 ? true : false)),
  }),
});

const UpdateRecurringPaymentInfoSchema = Yup.object().shape({
  payOutstandingBalance: Yup.boolean(),
  amount: Yup.mixed().when(['payOutstandingBalance'], {
    is: (payOutstandingBalance: boolean) => payOutstandingBalance,
    then: Yup.string().notRequired().nullable(),
    otherwise: Yup.string()
      .required('Required')
      .test('min', 'Must be positive', val => (val && convertToNumber(val) > 0 ? true : false)),
  }),
  dayOfMonth: Yup.string().required('Required'),
});

interface IPaymentInfoProps {
  currentCustomer: IAccountDetail;
  isRecurring?: boolean;
  closeModal?: (shouldReload?: boolean) => void;
  currentRecurringPayment?: IRecurringPayment | null;
  invoiceNumber?: string;
  defaultAmount?: string;
  defaultDayOfMonth?: string;
  isExternalPayment?: boolean;
  transactionId?: string;
  recurringPaymentId?: string;
  reloadExternalPayment?: () => void;
  authToken?: string; //used for posting external payment with JWT
  showCancelButton?: boolean;
  showNotesField?: boolean;
  adyen?: boolean;
  showCardTitle?: boolean;
  showPayBalence?: boolean;
  passedInPaymentProcessor?: IPaymentProcessorModel;
  isParentShouldCallAdyenSession?: boolean;
  showRecurringActionButton?: boolean;
  showRecurringCancelButton?: boolean;
  alertText?: string;
  isPayBalanceDisabled?: boolean;
  isExternalSaveDisabled?: boolean;
  showInvoiceInput?: boolean;
  sigPadInstance?: SignaturePad | null;
  showStoredPaymentMethods?: boolean;
}

export const PaymentInfo: FC<IPaymentInfoProps> = ({
  currentCustomer,
  isRecurring = false,
  closeModal,
  currentRecurringPayment,
  invoiceNumber,
  defaultAmount,
  isExternalPayment = false,
  transactionId,
  recurringPaymentId,
  reloadExternalPayment,
  authToken,
  showCancelButton = true,
  showNotesField = true,
  adyen,
  showCardTitle = true,
  defaultDayOfMonth,
  showPayBalence = true,
  passedInPaymentProcessor,
  isParentShouldCallAdyenSession = true,
  showRecurringActionButton = true,
  showRecurringCancelButton = true,
  alertText,
  isPayBalanceDisabled,
  isExternalSaveDisabled = false,
  showInvoiceInput = true,
  sigPadInstance,
  showStoredPaymentMethods,
}) => {
  const { paymentProcessor } = useContext(UserContext);
  const isAdyen = useMemo(() => {
    return adyen ?? paymentProcessor?.paymentProcessor === PaymentProcessor.Adyen;
  }, [adyen, paymentProcessor]);
  const { enqueueSnackbar } = useSnackbar();
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const confirm = useConfirm();
  const [shouldCallAdyenSession, setShouldCallAdyenSession] = useState<boolean>(false);
  const [isShowingSession, setIsShowingSession] = useState<boolean>(false);
  const [guid, setGuid] = useState<string>('');

  const [shouldCallAdyenRecurringSession, setShouldCallAdyenRecurringSession] =
    useState<boolean>(false);
  const [isShowingRecurringSession, setIsShowingRecurringSession] = useState<boolean>(false);
  const [guidForRecurring, setGuidForRecurring] = useState<string>('');

  const handleCallAdyenRecurringSession = () => {
    setShouldCallAdyenRecurringSession(true);
    setGuidForRecurring(generateUUID());
    setTimeout(() => setShouldCallAdyenRecurringSession(false), 1000);
  };

  const isEditingRecurringPayment = useMemo(
    () => !!currentRecurringPayment,
    [currentRecurringPayment]
  );
  const showCustomerInfo = useMemo(
    () => (isRecurring || isEditingRecurringPayment ? false : true),
    [isRecurring, isEditingRecurringPayment]
  );

  useEffect(() => {
    if (isRecurring && isParentShouldCallAdyenSession && isAdyen) {
      handleCallAdyenRecurringSession();
    }
  }, [isRecurring, isParentShouldCallAdyenSession, isAdyen]);

  const { meta } = usePaymentInputs();
  const commonData = useMemo(() => {
    return {
      creditCardNumber: '',
      expirationMonth: '',
      expirationYear: '',
      cvv: '',
      firstName: currentCustomer?.firstName ?? currentCustomer?.name?.split(' ')?.[0] ?? '',
      lastName: currentCustomer?.lastName ?? currentCustomer?.name?.split(' ')?.[1] ?? '',
      street: currentCustomer?.address?.street ?? '',
      city: currentCustomer?.address?.city ?? '',
      state: currentCustomer?.address?.state ?? '',
      postalCode: currentCustomer?.address?.postalCode ?? '',
      amount: currentRecurringPayment?.paymentAmount
        ? formatMoney(currentRecurringPayment?.paymentAmount)
        : defaultAmount
        ? formatMoney(defaultAmount)
        : isRecurring
        ? '$0.00'
        : '',
      adyen: isAdyen,
      payOutstandingBalance: false,
      dayOfMonth: '1',
      invoiceNumber: invoiceNumber ?? '',
      notes: '',
    };
  }, [
    currentRecurringPayment,
    currentCustomer,
    defaultAmount,
    isRecurring,
    isAdyen,
    invoiceNumber,
  ]);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        currentRecurringPayment
          ? {
              ...commonData,
              payOutstandingBalance:
                currentRecurringPayment?.payOutstandingBalance !== null
                  ? currentRecurringPayment?.payOutstandingBalance
                  : true,
              dayOfMonth: currentRecurringPayment?.dayOfMonth ?? '1',
            }
          : isRecurring
          ? {
              ...commonData,
              payOutstandingBalance: true,
              dayOfMonth: defaultDayOfMonth ?? '1',
            }
          : {
              ...commonData,
              invoiceNumber: invoiceNumber ?? '',
              notes: '',
              dayOfMonth: defaultDayOfMonth ?? '1',
            }
      }
      validationSchema={
        isEditingRecurringPayment
          ? UpdateRecurringPaymentInfoSchema
          : isRecurring
          ? RecurringPaymentInfoSchema
          : PaymentInfoSchema
      }
      onSubmit={async (values, actions) => {
        try {
          const commonRecurringData = {
            paymentAmount:
              values?.payOutstandingBalance === true ? 0 : convertToNumber(values.amount),
            payBalance:
              values.payOutstandingBalance !== null ? !!values.payOutstandingBalance : true,
            dayOfMonth: convertToNumber(values.dayOfMonth),
            payOutstandingBalance:
              values.payOutstandingBalance !== null ? !!values.payOutstandingBalance : true,
          };
          const commonCustomerInfo = {
            creditCardNumber: values.creditCardNumber,
            expirationMonth: Number(values.expirationMonth),
            expirationYear: Number(values.expirationYear),
            cvv: values.cvv,
            firstName: values.firstName,
            lastName: values.lastName,
            address: `${values?.street}, ${values?.city} ${values?.state}`,
            postalCode: values.postalCode,
          };
          if (isRecurring && !isEditingRecurringPayment && !isExternalPayment) {
            await postRecurringPayment(
              currentCustomer.accountId,
              isAdyen
                ? {
                    ...commonRecurringData,
                    reference: guidForRecurring,
                  }
                : {
                    ...commonRecurringData,
                    ...commonCustomerInfo,
                  }
            );
          } else if (isEditingRecurringPayment) {
            await putRecurringPayments(
              currentRecurringPayment?.recurringPaymentId!,
              commonRecurringData
            );
          } else if (isExternalPayment && (transactionId || recurringPaymentId) && authToken) {
            if (transactionId) {
              await postExternalInvoicePayment(authToken as string, transactionId, {
                recurringPaymentId: currentRecurringPayment?.recurringPaymentId ?? '',
                ...commonCustomerInfo,
              });
              // flow corresponds to this page, /external/recurring-payments
            } else if (recurringPaymentId) {
              if (sigPadInstance) {
                const formData = new FormData();
                const res = await fetch(sigPadInstance?.toDataURL() as string);
                const blob = await res.blob();
                formData.append('Body.Signature', blob);
                await postRecurringPaymentSignature(
                  authToken as string,
                  recurringPaymentId,
                  formData
                );
              }
              await postExternalRecurringPayment(
                authToken as string,
                recurringPaymentId,
                commonCustomerInfo
              );
            }

            reloadExternalPayment && reloadExternalPayment();
          } else {
            await createPaymentCapture({
              accountId: currentCustomer.accountId,
              ...commonCustomerInfo,
              amount: convertToNumber(values.amount),
              invoiceNumber: values.invoiceNumber ?? '',
              notes: values.notes ?? '',
            });
          }
          actions.resetForm();
          enqueueSnackbar(
            isRecurring
              ? `Successfully created recurring payment!`
              : `Successfully processed payment!`,
            {
              variant: 'success',
            }
          );
          if (isRecurring && closeModal) {
            closeModal(true);
          }
          if (isExternalPayment) {
            return;
          } else {
            history.push(`/customers/${currentCustomer.accountId}/transactions`);
          }
        } catch (error: any) {
          if (error.Detail.includes('invalid authentication values')) {
            enqueueSnackbar(
              'Payment could not be created because your office has invalid Authorize.Net credentials.',
              { variant: 'error' }
            );
          } else {
            enqueueSnackbar(
              error.Detail ||
                (isRecurring ? 'Error, creating recurring payment.' : 'Error, processing payment.'),
              { variant: 'error' }
            );
          }
        }
      }}
    >
      {({
        resetForm,
        isSubmitting,
        initialValues,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
        values,
        handleBlur,
        errors,
        touched,
      }) => {
        return (
          <>
            {isSubmitting && <Loader position="centered" type="overlay" />}
            <Form onSubmit={handleSubmit}>
              <Card>
                {showCardTitle && <CardTitle title="Payment Info" />}
                {!!alertText && <Alert severity="info">{alertText}</Alert>}
                <Grid container spacing={2} sx={{ marginTop: 0 }}>
                  {showCustomerInfo && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField label="First Name" name="firstName" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField label="Last Name" name="lastName" required />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField label="Street" name="street" required />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField label="City" name="city" required />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={2}>
                        <TextField label="State" name="state" required />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={2}>
                        <TextField label="Postal Code" name="postalCode" required />
                      </Grid>
                    </>
                  )}
                  {!isAdyen && !isEditingRecurringPayment && (
                    <Grid item xs={12} xl={isRecurring ? 12 : 7}>
                      <CreditCardInputs
                        isInline
                        numberValue={values.creditCardNumber}
                        cvvValue={values.cvv}
                        required
                        handleCreditCardNumberChange={val => {
                          setFieldValue('creditCardNumber', val);
                          // reset if you change the card number
                          setFieldValue('cvv', '');
                          setFieldValue('expirationMonth', '');
                          setFieldValue('expirationYear', '');
                        }}
                        resetOnCreditCardNumberChange
                        handleCvvChange={val => setFieldValue('cvv', val)}
                        handleBlur={handleBlur}
                      />
                    </Grid>
                  )}
                  {/* Per visit for recurring services then don't show payment amount input */}
                  {defaultDayOfMonth !== '-1' && (
                    <Grid
                      item
                      xs={12}
                      sm={isAdyen && !isRecurring ? 6 : isRecurring ? 3 : 5}
                      order={isRecurring ? 2 : undefined}
                    >
                      <AmountInput
                        name="amount"
                        value={values.amount}
                        disabled={
                          values.payOutstandingBalance ||
                          !!defaultAmount ||
                          (isEditingRecurringPayment && isAdyen)
                        }
                        onChange={(val: string) => setFieldValue('amount', val)}
                        onBlur={handleBlur}
                        error={!!touched.amount && !!errors?.amount}
                        helperText={!!touched.amount && !!errors?.amount ? errors.amount : ''}
                        label="Pay Amount"
                      />
                    </Grid>
                  )}
                  {!isRecurring && !isAdyen && (
                    <>
                      {showInvoiceInput && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Invoice Number"
                            name="invoiceNumber"
                            disabled={!!invoiceNumber}
                          />
                        </Grid>
                      )}
                      {showNotesField && (
                        <Grid item xs={12}>
                          <TextField label="Notes" name="notes" multiline rows={3} />
                        </Grid>
                      )}
                    </>
                  )}
                  {isRecurring && (
                    <>
                      <Grid item xs={12} sm={3} order={1}>
                        <PaymentDayofMonth
                          onChange={(value: string | number) => {
                            setFieldValue('dayOfMonth', value);
                          }}
                          value={values.dayOfMonth}
                          disabled={!!defaultDayOfMonth}
                        />
                      </Grid>
                      {showPayBalence && (
                        <Grid item xs={12} sm={isAdyen ? 3 : 6} order={3}>
                          <FormControlLabel
                            color="primary"
                            control={
                              <Checkbox
                                checked={values.payOutstandingBalance}
                                id="payOutstandingBalance"
                                color="primary"
                                onChange={(_, checked) => {
                                  if (checked) {
                                    setFieldValue('amount', '$0.00');
                                    if (isAdyen && isShowingRecurringSession) {
                                      handleCallAdyenRecurringSession();
                                    }
                                  }
                                  setFieldValue('payOutstandingBalance', checked);
                                }}
                                disabled={
                                  (isEditingRecurringPayment && isAdyen) || isPayBalanceDisabled
                                }
                              />
                            }
                            label="Pay Balance"
                          />
                        </Grid>
                      )}
                      {isAdyen && !isEditingRecurringPayment && (
                        <>
                          {showRecurringActionButton && (
                            <Grid item xs={12} sm={3} order={4}>
                              <Button
                                type="button"
                                color="secondary"
                                disabled={
                                  (!isValid && values.amount !== '0') ||
                                  (isShowingRecurringSession && values.payOutstandingBalance)
                                }
                                onClick={() => handleCallAdyenRecurringSession()}
                              >
                                {isShowingRecurringSession
                                  ? 'Update Amount'
                                  : 'Continue to Payment'}
                              </Button>
                            </Grid>
                          )}
                          <Grid container item xs={12} order={4}>
                            {isParentShouldCallAdyenSession && (
                              <Grid item xs={12}>
                                <AdyenDropIn
                                  amount={convertToNumber(values.amount)}
                                  accountId={currentCustomer.accountId!}
                                  shouldCallAdyenSession={shouldCallAdyenRecurringSession}
                                  setShouldCallAdyenSession={setShouldCallAdyenRecurringSession}
                                  isRecurring
                                  payBalance={values.payOutstandingBalance}
                                  setIsShowingSession={setIsShowingRecurringSession}
                                  reference={recurringPaymentId ?? guidForRecurring}
                                  afterPaymentComplete={
                                    recurringPaymentId && reloadExternalPayment
                                      ? () => {
                                          reloadExternalPayment();
                                          enqueueSnackbar(
                                            `Successfully created recurring payment!`,
                                            {
                                              variant: 'success',
                                            }
                                          );
                                        }
                                      : () => handleSubmit()
                                  }
                                  token={authToken}
                                  dropInContainerId="dropin-container-recurring"
                                  createAdyenTransRecord={false}
                                  showStoredPaymentMethods={
                                    isRecurring
                                      ? false
                                      : showStoredPaymentMethods === undefined
                                      ? true
                                      : showStoredPaymentMethods
                                  }
                                  adyenClientKey={passedInPaymentProcessor?.adyenConfig?.clientKey}
                                />
                              </Grid>
                            )}
                            {isShowingRecurringSession && showRecurringCancelButton && (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                                mt={2}
                              >
                                <Button
                                  type="button"
                                  color="inherit"
                                  onClick={async () => {
                                    if (!deepEqual(initialValues, values)) {
                                      const result = await confirm(defaultUnsavedChangesMessage);
                                      if (result) {
                                        resetForm();
                                        closeModal && closeModal(true);
                                      } else {
                                        return;
                                      }
                                    }

                                    closeModal && closeModal(true);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                {!isRecurring && (
                  <Box
                    margin="1rem 0"
                    display="flex"
                    gap={1}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {showCancelButton && !isShowingSession && (
                      <Button
                        type="button"
                        color="inherit"
                        onClick={async () => {
                          if (!deepEqual(initialValues, values)) {
                            const result = await confirm(defaultUnsavedChangesMessage);
                            if (result) {
                              resetForm();
                              return history.push(params.get('redirect') ?? '/customers');
                            } else {
                              return;
                            }
                          }

                          history.push(params.get('redirect') ?? '/customers');
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                    {isAdyen ? (
                      <Stack gap={1} display={'flex'} direction={{ xs: 'column', sm: 'row' }}>
                        {isShowingSession && !isExternalPayment && (
                          <Button
                            type="button"
                            onClick={() => {
                              setShouldCallAdyenSession(false);
                              setIsShowingSession(false);
                              setGuid('');
                            }}
                            color="inherit"
                            startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                          >
                            Back
                          </Button>
                        )}
                        <Button
                          type="button"
                          color="secondary"
                          disabled={
                            (!isValid && values.amount !== '0') ||
                            (isShowingSession && isExternalPayment)
                          }
                          onClick={() => {
                            setShouldCallAdyenSession(true);
                            setIsShowingSession(true);
                            setGuid(generateUUID());
                            setTimeout(() => setShouldCallAdyenSession(false), 1000);
                          }}
                        >
                          {isShowingSession && !isExternalPayment
                            ? 'Update Payment'
                            : 'Continue to Payment'}
                        </Button>
                      </Stack>
                    ) : (
                      <SaveButton
                        disabled={
                          !dirty ||
                          isSubmitting ||
                          !isValid ||
                          !!meta?.erroredInputs?.cvc ||
                          !!meta?.erroredInputs?.cardNumber ||
                          isExternalSaveDisabled
                        }
                      />
                    )}
                  </Box>
                )}
                {isAdyen && isShowingSession && (
                  <>
                    <Grid container spacing={2}>
                      {isParentShouldCallAdyenSession && (
                        <Grid item xs={12}>
                          <AdyenDropIn
                            amount={convertToNumber(values.amount)}
                            accountId={currentCustomer.accountId!}
                            shouldCallAdyenSession={shouldCallAdyenSession}
                            setIsShowingSession={setIsShowingSession}
                            setShouldCallAdyenSession={setShouldCallAdyenSession}
                            createAdyenTransRecord
                            reference={guid}
                            afterPaymentComplete={() => {
                              if (isExternalPayment) {
                                reloadExternalPayment?.();
                              } else {
                                history.push(
                                  `/customers/${currentCustomer.accountId}/transactions`
                                );
                              }
                            }}
                            transactionId={isExternalPayment ? transactionId : undefined}
                            token={isExternalPayment ? authToken : undefined}
                            adyenClientKey={passedInPaymentProcessor?.adyenConfig?.clientKey}
                            showStoredPaymentMethods={showStoredPaymentMethods}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {isShowingSession && !isExternalPayment && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        mt={{ xs: 2, sm: 1 }}
                      >
                        <Button
                          type="button"
                          color="inherit"
                          onClick={async () => {
                            if (!deepEqual(initialValues, values)) {
                              const result = await confirm(defaultUnsavedChangesMessage);
                              if (result) {
                                resetForm();
                                return history.push(params.get('redirect') ?? '/customers');
                              } else {
                                return;
                              }
                            }

                            history.push(params.get('redirect') ?? '/customers');
                          }}
                          sx={{ width: { xs: '100%', sm: 'auto' } }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Card>
              {/* Only show if editing a recurring payment or adding a new recurring payment with adyen store config */}
              {(isEditingRecurringPayment || (isRecurring && !isAdyen)) && (
                <ModalSaveSection
                  handleCancel={async () => {
                    if (!deepEqual(initialValues, values)) {
                      const result = await confirm(defaultUnsavedChangesMessage);
                      if (result) {
                        resetForm();
                        if (isRecurring && closeModal) {
                          return closeModal();
                        }
                      } else {
                        return;
                      }
                    }
                    if (isRecurring && closeModal) {
                      return closeModal();
                    }
                  }}
                  isSaveDisabled={
                    !dirty ||
                    isSubmitting ||
                    !isValid ||
                    (!currentCustomer &&
                      (!!meta?.erroredInputs?.cvc || !!meta?.erroredInputs?.cardNumber))
                  }
                />
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
