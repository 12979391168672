import { TextField, RadioGroup, FormControlLabel, Radio, MenuItem, Grid } from '@mui/material';
import { Dispatch, FC, useEffect, useState } from 'react';
import {
  getWeekLabels,
  getMonthlyNameOptions,
  getMonthlyDayOptions,
  getDaysOfWeek,
} from '../../helpers';

interface IYearly {
  setSchedule: Dispatch<{
    interval1: number;
    interval2: number;
    interval3: number;
    recurOption: boolean;
    recurPeriod: string;
  }>;
  interval1: number;
  interval2: number;
  interval3: number;
  recurOption: boolean;
}

export const Yearly: FC<IYearly> = ({
  setSchedule,
  interval1,
  interval2,
  interval3,
  recurOption: yearlyRecurOption,
}) => {
  // if true, we are selecting the 2nd list of radio options from below
  const [recurOption, setRecurOption] = useState<boolean>(yearlyRecurOption);
  const [recurVal1, setRecurInterval1] = useState<number>(interval1 ?? 1);
  const [recurVal2, setRecurInterval2] = useState<number>(interval2 ?? 1);
  const [nonRecurVal1, setNonRecurInterval1] = useState<number>(yearlyRecurOption ? interval1 : 1);
  const [nonRecurVal2, setNonRecurInterval2] = useState<number>(yearlyRecurOption ? interval2 : 0);
  const [nonRecurVal3, setNonRecurInterval3] = useState<number>(
    (yearlyRecurOption && interval3) || 1
  );

  useEffect(() => {
    setSchedule({
      recurPeriod: 'Yearly',
      interval1: !recurOption ? recurVal1 : nonRecurVal1,
      interval2: !recurOption ? recurVal2 : nonRecurVal2,
      // recurOption when true doesn't have a third interval so pass back what was given
      interval3: !recurOption ? interval3 : nonRecurVal3,
      recurOption,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurVal1, recurVal2, nonRecurVal1, nonRecurVal2, nonRecurVal3, recurOption]);

  return (
    <>
      <RadioGroup
        aria-labelledby="options"
        defaultValue={false}
        name="yearly-options"
        value={recurOption}
        onChange={(_, val) => setRecurOption(val === 'true' ? true : false)}
      >
        <>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={2.5} md={3} lg={2.5} xl={2}>
              <FormControlLabel value={false} control={<Radio />} label="On every" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label=""
                select
                onChange={(e: any) => {
                  setRecurInterval1(e.target.value);
                }}
                value={recurVal1}
                size="small"
              >
                {getMonthlyNameOptions().map((val, index) => (
                  <MenuItem key={`${index}`} value={val.value}>
                    {val.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label=""
                select
                onChange={(e: any) => {
                  setRecurInterval2(e.target.value);
                }}
                value={recurVal2}
                size="small"
              >
                {getMonthlyDayOptions().map((val, index) => (
                  <MenuItem key={`${index}`} value={val.value}>
                    {val.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center" marginTop="1rem">
            <Grid item xs={12} sm={1.5} md={2} xl={1}>
              <FormControlLabel value={true} control={<Radio />} label="The" />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label=""
                select
                onChange={(e: any) => {
                  setNonRecurInterval1(e.target.value);
                }}
                value={nonRecurVal1}
                size="small"
              >
                {getWeekLabels().map((val, index) => (
                  <MenuItem key={`${index}`} value={val.value}>
                    {val.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label=""
                select
                onChange={(e: any) => {
                  setNonRecurInterval2(e.target.value);
                }}
                value={nonRecurVal2}
                size="small"
              >
                {getDaysOfWeek().map((val, index) => (
                  <MenuItem key={`${index}`} value={val.value}>
                    {val.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <span>of</span>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label=""
                select
                onChange={(e: any) => {
                  setNonRecurInterval3(e.target.value);
                }}
                value={nonRecurVal3}
                size="small"
              >
                {getMonthlyNameOptions().map((val, index) => (
                  <MenuItem key={`${index}`} value={val.value}>
                    {val.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </>
      </RadioGroup>
    </>
  );
};
