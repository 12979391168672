import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FC } from 'react';
import { IFilterInputProps } from '../../../../models';
import { isValidDate } from '../../../../helpers';

export const DateFilter: FC<IFilterInputProps> = ({ filter, values, onChange }) => {
  const { name, label } = filter;

  const [value = ''] = values;

  const handleChange = (date: Date | null) => {
    if (isValidDate(date)) {
      const value = date ? date.toISOString() : '';
      onChange([value], filter);
    }
  };

  return (
    <DatePicker
      label={label}
      format="MM/dd/yyyy"
      onChange={handleChange}
      value={value ? new Date(value) : null}
      slotProps={{
        textField: {
          name,
          error: false,
          size: 'small',
          fullWidth: true,
        },
      }}
    />
  );
};
