import { Box } from "@mui/material";
import React, { FC, useMemo } from "react";
import { SimpleDataGrid } from "../../../components";
import { IAccountExportSummaryDetailModel } from "../../../models";
import { formatMoney, hyphenSeparateTwoInputs } from "../../../helpers";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";

interface IAccountingExportSummaryTable {
  rows: IAccountExportSummaryDetailModel[];
  isLoading: boolean;
};

export const AccountingExportSummaryTable: FC<IAccountingExportSummaryTable> = ({
  rows,
  isLoading,
}) => {
  const accountingExportColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'transactionType',
        headerName: 'Type',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1
      },
      {
        field: 'debitCount',
        headerName: 'Count',
        filterable: false,
        sortable: false,
        flex: 1,
        disableColumnMenu: true,
        headerAlign: 'right',
        align: 'right',
      },
      {
        field: 'debitTotal',
        headerName: 'Debits',
        filterable: false,
        sortable: false,
        flex: 1,
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
          return formatMoney(params.value, 2);
        },
        headerAlign: 'right',
        align: 'right',
      },
      {
        field: 'creditTotal',
        headerName: 'Credits',
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
          return formatMoney(params.value, 2);
        },
        headerAlign: 'right',
        align: 'right',
      },
    ],
    []
  );
  return (
    <>
      <Box mt={1} mb={1}>
        <SimpleDataGrid
          loading={isLoading}
          getRowId={(row: IAccountExportSummaryDetailModel) => `${row.transactionCode}_${row.transactionType}`}
          rows={rows}
          columns={accountingExportColumns}
          hasMobileLayout
          mobileProps={{
            mobileCustomDefaultAccessor: (row: IAccountExportSummaryDetailModel) => {
              return hyphenSeparateTwoInputs(row.transactionType, row.totalCount.toString());
            }
          }}
        />
      </Box>
    </>
  );
};