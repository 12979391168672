import { Loader, Modal, Page } from '../../../components';
import { useParams } from 'react-router-dom';
import { IFeedbackItem } from '../../../models';
import { useEffect, useState, FC } from 'react';
import { getSingleFeedbackItem } from '../../../fetch/feedback';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/system';
import { Stack, Typography, Fade } from '@mui/material';
import { formatDate } from '../../../helpers';

interface IFeedbackItemDetails {
  isModal?: boolean;
  currentFeedbackId?: string;
  isModalOpen?: boolean;
  handleModalClose?: () => void;
}

export const FeedbackItemDetails: FC<IFeedbackItemDetails> = ({
  isModal,
  currentFeedbackId,
  isModalOpen,
  handleModalClose,
}) => {
  const { feedbackId: paramId }: { feedbackId: string } = useParams();

  const feedbackId = paramId ?? currentFeedbackId;
  const [feedbackItem, setFeedbackItem] = useState<IFeedbackItem | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);

  const fetchFeedbackItem = async (feedbackId: string) => {
    try {
      setIsLoadingFeedback(true);
      const response = await getSingleFeedbackItem(feedbackId);
      setFeedbackItem(response);
    } catch (err: any) {
      enqueueSnackbar(
        err?.response?.data?.Detail || `Error loading feedback item. Please try again.`,
        {
          variant: 'error',
        }
      );
    } finally {
      setIsLoadingFeedback(false);
    }
  };

  useEffect(() => {
    if (feedbackId) {
      fetchFeedbackItem(feedbackId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackId]);

  const renderContent = () => {
    return (
      <div>
        {isLoadingFeedback && (
          <Box height="10rem">
            <Loader position="centered" type="inline" />
          </Box>
        )}
        {!isLoadingFeedback && (
          <Stack gap={1}>
            <div>
              <Typography fontWeight={'bold'} component={'span'}>
                {'Type of feedback: '}
              </Typography>
              {feedbackItem?.type ?? ''}
            </div>
            <div>
              <Typography fontWeight={'bold'} component={'span'}>
                {'Date created: '}
              </Typography>
              {feedbackItem?.whenCreated ? formatDate(feedbackItem.whenCreated) : ''}
            </div>
            <div>
              <Typography fontWeight={'bold'} component={'span'}>
                {'Sent response: '}
              </Typography>
              {feedbackItem?.sentResponse === true ? 'Yes' : 'No'}
            </div>
            <div>
              <Typography fontWeight={'bold'} component={'span'}>
                {'User: '}
              </Typography>
              {feedbackItem?.user?.userName ?? ''}
            </div>
            <div>
              <Typography fontWeight={'bold'} component={'span'}>
                {'Login name: '}
              </Typography>
              {feedbackItem?.user?.loginName ?? ''}
            </div>
            <div>
              <Typography fontWeight={'bold'} component={'span'}>
                {'Office: '}
              </Typography>
              {feedbackItem?.user?.office?.name ?? ''}
            </div>
            <div>
              <Typography fontWeight={'bold'}>{'Message:'}</Typography>
              <Typography>{feedbackItem?.message ?? ''}</Typography>
            </div>
          </Stack>
        )}
      </div>
    );
  };
  if (isModal) {
    return (
      <Modal open={isModalOpen!} onClose={handleModalClose} maxWidth="md" title="Feedback Item">
        <Fade in={isModalOpen!}>
          <Box mt={1}>{renderContent()}</Box>
        </Fade>
      </Modal>
    );
  }
  return (
    <Page
      title={`Feedback Managment`}
      breadcrumb={{
        text: 'Feedback Management',
        title: 'Back to Feedback',
        link: `/admin/feedback-management`,
      }}
    >
      {renderContent()}
    </Page>
  );
};
