import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Stack, Divider, Box, Button } from '@mui/material';
import { CardTitle, Loader, FormikCard, SaveButton, CancelIcon } from '../../components';
import { useQuery } from 'react-query';
import { FC, useContext, useState } from 'react';
import { createLead, getLead, updateLead } from '../../fetch';
import { ILeadDetail, ILeadPost } from '../../models';
import { phoneRegExp, formatInputPhoneNumber } from '../../helpers';
import { UserContext } from '../../context';
import { useHistory } from 'react-router-dom';
import { LeadDetailStatusStepper } from './lead-detail-status-stepper';
import { LeadDetailHeader } from './lead-detail-header';
import { LeadDetailFormFields } from './lead-detail-form-fields';

interface ILeadDetailForm {
  leadId: string;
  isNewLead: boolean;
}

export const LEAD_DETAIL_FORM_SCHEMA = Yup.object().shape({
  source: Yup.string().required('Required'),
  serviceType: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  customerFirstName: Yup.string().required('Required'),
  customerLastName: Yup.string().required('Required'),
  customerEmail: Yup.string().email('Invalid Email').required('Required'),
  customerPhone: Yup.string()
    .matches(phoneRegExp, {
      excludeEmptyString: true,
      message: 'Invalid phone number',
    })
    .required('Required'),
  userId: Yup.string(),
  addressModel: Yup.object().shape({
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
  }),
  createTask: Yup.boolean(),
});

export const LeadDetailForm: FC<ILeadDetailForm> = ({ leadId, isNewLead }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const history = useHistory();

  const [leadDetail, setLeadDetail] = useState<ILeadDetail | null>(null);
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const { isLoading, refetch: refetchLead } = useQuery<ILeadDetail, Error>(
    ['lead-detail', leadId],
    () => getLead(leadId),
    {
      onSuccess: d => setLeadDetail(d),
      enabled: !!leadId && leadId !== 'new',
    }
  );

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handleCopyClick = (copyText: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied({ [copyText]: true });
        setTimeout(() => {
          setIsCopied({ [copyText]: false });
        }, 1500);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleSave = async (values: ILeadPost) => {
    const data = {
      ...values,
      officeId: user?.officeId,
      date: leadDetail?.date ?? new Date().toISOString(),
      status: leadDetail?.status ?? 'New',
      // only send the phone number string with numbrs, i.e. (123) 123-1234 -> 1231231234
      customerPhone: values?.customerPhone.replace(/\D/g, ''),
    };
    if (!isNewLead) {
      await updateLead(leadId, data);
      enqueueSnackbar(`Successfully updated lead!`, {
        variant: 'success',
      });
    } else {
      await createLead(data);
      enqueueSnackbar(`Successfully created lead!`, {
        variant: 'success',
      });
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        source: leadDetail?.sourceType ?? '',
        serviceType: leadDetail?.serviceTypeValue ?? '',
        description: leadDetail?.description ?? '',
        customerFirstName: leadDetail?.customerName?.split(' ')?.[0] ?? '',
        customerLastName: leadDetail?.customerName?.split(' ')?.[1] ?? '',
        customerEmail: leadDetail?.customerEmail ?? '',
        customerPhone: (leadDetail && leadDetail.customerPhone
          ? formatInputPhoneNumber(leadDetail?.customerPhone ?? '')
          : '') as string,
        userId: leadDetail?.user?.userId ?? '',
        addressModel: {
          address: leadDetail?.customerAddress ?? '',
          city: leadDetail?.customerCity ?? '',
          state: leadDetail?.customerState ?? '',
          postalCode: leadDetail?.customerPostalCode ?? '',
        },
        createTask: false,
      }}
      validationSchema={LEAD_DETAIL_FORM_SCHEMA}
      onSubmit={async (values, actions) => {
        try {
          await handleSave(values as ILeadPost);
          history.push('/leads');
        } catch (err: any) {
          enqueueSnackbar(err?.Detail ?? 'Error saving lead, please try again.', {
            variant: 'error',
          });
        }
      }}
    >
      {({ isSubmitting, handleSubmit, dirty, isValid, values, setSubmitting }) => {
        return (
          <>
            {!isNewLead && leadDetail && (
              <LeadDetailHeader
                lead={leadDetail}
                handleCopyClick={handleCopyClick}
                isCopied={isCopied}
              />
            )}
            {!isNewLead && <LeadDetailStatusStepper lead={leadDetail} />}
            <FormikCard>
              {(isSubmitting || isLoading) && <Loader type="overlay" position="centered" />}
              <CardTitle title="Lead Details" />
              <Form onSubmit={handleSubmit}>
                <LeadDetailFormFields
                  isNewLead={isNewLead}
                  leadId={leadId}
                  leadDetail={leadDetail}
                  handleCopyClick={handleCopyClick}
                  isCopied={isCopied}
                  refetchLead={refetchLead}
                  handleSave={handleSave}
                />
                <Box my={3}>
                  <Divider />
                </Box>
                <Stack
                  sx={{ marginTop: '1rem' }}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  gap={1}
                >
                  <Button
                    color="inherit"
                    disabled={isSubmitting}
                    onClick={() => {
                      history.push('/leads');
                    }}
                    startIcon={<CancelIcon />}
                  >
                    Cancel
                  </Button>
                  <SaveButton disabled={!dirty || !isValid || isSubmitting} />
                </Stack>
              </Form>
            </FormikCard>
          </>
        );
      }}
    </Formik>
  );
};
