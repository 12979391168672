import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Card, CardActions, CardContent, CardHeader, lighten, styled } from '@mui/material';
import { FC } from 'react';

interface IPrimaryCardProps {
  children?: any;
  avatar?: JSX.Element;
  icon?: IconProp;
  title: string | null;
  subheader?: string | null;
  action?: JSX.Element;
}

export const PrimaryCard: FC<IPrimaryCardProps> = ({
  children,
  avatar,
  icon,
  title,
  subheader,
  action,
}) => {

  return (
    <StyledPrimaryCard>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          (avatar || icon) && (
            <Avatar
              sx={{
                bgcolor: theme => theme.palette.common.white,
                width: theme => theme.spacing(5),
                height: theme => theme.spacing(5),
                color: theme => theme.palette.secondary.main,
              }}
            >
              {avatar}
              {icon && <FontAwesomeIcon icon={icon} size="sm" />}
            </Avatar>
          )
        }
        title={title}
        subheader={subheader}
        titleTypographyProps={{ variant: 'body1' }}
        subheaderTypographyProps={{ color: theme => lighten(theme.palette.secondary.main, 0.6) }}
      />
      <CardContent
        sx={{
          backgroundColor: theme => theme.palette.grey[100],
          padding: '8px',
        }}
      >
        {children}
      </CardContent>
      {action && <CardActions sx={{ padding: theme => theme.spacing(1) }}>{action}</CardActions>}
    </StyledPrimaryCard>
  );
};

const PREFIX = 'PrimaryCard';

const classes = {
  cardHeader: `${PREFIX}-cardHeader`,
  primaryColorIcon: `${PREFIX}-primaryColorIcon`,
  secondaryColorIcon: `${PREFIX}-secondaryColorIcon`
};

const StyledPrimaryCard = styled(Card)(({ theme }) => ({
  [`& .${classes.cardHeader}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderBottom: `3px solid ${theme.palette.secondary.main}`,
    padding: '8px',
    '&& .MuiCardHeader-subheader': {
      color: lighten(theme.palette.secondary.main, 0.6),
    },
  },

  [`& .${classes.primaryColorIcon}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.secondaryColorIcon}`]: {
    color: theme.palette.secondary.main,
  }
}));