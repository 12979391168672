import { Button } from '@mui/material';
import { FC } from 'react';
import { useConfirm } from '../../hooks';
import { createTransactionBatch } from '../../fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { IUser } from '../../models';
import { faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

interface IAddNewBatchButton {
  setCreating: (value: boolean) => void;
  activeBatchId: number | null;
  handleClick: (batchId: string | number) => void;
  user?: IUser;
  isDisabled?: boolean;
}

export const AddNewBatchButton: FC<IAddNewBatchButton> = ({
  setCreating,
  activeBatchId,
  handleClick,
  user,
  isDisabled,
}) => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Button
      onClick={async () => {
        if (!activeBatchId) {
          const result = await confirm('Are you sure you want to create a new batch?');
          if (result) {
            try {
              setCreating(true);
              const batchId = await createTransactionBatch({
                officeId: user?.officeId!,
              });
              enqueueSnackbar('Batch created!', {
                variant: 'success',
              });
              handleClick(batchId);
            } catch (e: any) {
              enqueueSnackbar(e?.Detail ?? `Error creating batch, please try again.`, {
                variant: 'error',
              });
            } finally {
              setCreating(false);
            }
          }
        } else {
          handleClick(activeBatchId);
        }
      }}
      color="secondary"
      size="small"
      disabled={isDisabled}
      startIcon={<FontAwesomeIcon icon={!!activeBatchId ? faEye : faPlusCircle} />}
    >
      {!!activeBatchId ? 'View Active Batch' : 'Add New Batch'}
    </Button>
  );
};
