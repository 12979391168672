import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, Grid, Typography, IconButton, Stack, styled } from '@mui/material';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack';
import React, {
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
  Dispatch,
  useEffect,
} from 'react';
import { AzureMap, FloatingToolbar, Loader } from '../../components';
import { UserContext } from '../../context';
import { postRoutesToOptimize, updateServiceRoutes } from '../../fetch';
import {
  IFinalRoutePayload,
  IRouteUpdateMode,
  IServiceRouteDetail,
  ITechnician,
  IUpdateRoutesPayload,
  IService,
} from '../../models';
import {
  useRouteChanges,
  createDropResultChangeSet,
  Pod,
  TechnicianFilters,
  buildFlatTechnicianList,
  toChangeListPayload,
  isRouteStartOrEnd,
  buildChangeState,
  colorizeRoutes,
} from '../routes';
import { RouteDayPicker } from './route-day-picker';
import { InfoOutlined } from '@mui/icons-material';
import { uniqBy } from 'lodash';
import { ServiceButtons } from './service-buttons';
import { useGetOptimizationMethods } from '../routes/useGetOptimizationMethods';

interface IServiceRouteDetailContent {
  selectedTechs: ITechnician[];
  fetchServiceRouteDetail?: () => void;
  serviceRoutes: IServiceRouteDetail[];
  isLoadingSelectedRoute?: boolean;
  setSelectedTechs: Dispatch<SetStateAction<ITechnician[]>>;
  isModal?: boolean;
  handleClose?: (val?: IUpdateRoutesPayload) => void;
  setHasChanges?: (val: boolean) => void;
  currentServiceRouteId: string | number;
  setCurrentServiceRouteId: React.Dispatch<React.SetStateAction<string | number>>;
  confirmChangesLoss: () => Promise<boolean>;
  updateMode: IRouteUpdateMode;
  onUpdateModeChange: (val: IRouteUpdateMode) => void;
}

export const ServiceRouteDetailContent: FC<IServiceRouteDetailContent> = ({
  fetchServiceRouteDetail,
  serviceRoutes,
  isLoadingSelectedRoute,
  selectedTechs,
  setSelectedTechs,
  setHasChanges,
  currentServiceRouteId,
  setCurrentServiceRouteId,
  confirmChangesLoss,
  onUpdateModeChange,
  updateMode,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user, isOfficeAdmin, isSuperAdmin } = useContext(UserContext);
  const [, setPushPins] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isOptimizing, setIsOptimizing] = useState(false);
  const selectedUserIds = useMemo(() => selectedTechs.map(u => u.userId), [selectedTechs]);
  const [selectedMethod, setSelectedMethod] = useState<string>('Distance');
  const { data: optimizeMethods, isLoading: isLoadingOptimizationMethods } =
    useGetOptimizationMethods();
  const {
    updatedRoutes,
    hasChanges,
    changes,
    onServicesChange,
    setInitialRoutes,
    reset,
    setUpdatedRoutes,
    setChanges,
  } = useRouteChanges({
    serviceRoutes: serviceRoutes,
    updateMode: 'Single',
  });
  useEffect(() => {
    if (setHasChanges) {
      setHasChanges(hasChanges);
    }
  }, [hasChanges, setHasChanges]);

  const modifiedServiceRoute = useMemo(() => {
    if (updatedRoutes.length) {
      const updatedTechsIds = updatedRoutes?.[0]?.technicians?.map(t => t.userId);
      return {
        ...updatedRoutes?.[0],
        nextRouteId: serviceRoutes?.[0]?.nextRouteId,
        previousRouteId: serviceRoutes?.[0]?.previousRouteId,
        technicians: serviceRoutes?.[0]?.technicians?.map(t => {
          if (updatedTechsIds?.includes(t.userId)) {
            return updatedRoutes?.[0]?.technicians?.find(tech => tech.userId === t.userId);
          }
          return t;
        }),
      };
    }
  }, [updatedRoutes, serviceRoutes]);
  const selectedRoute = modifiedServiceRoute as IServiceRouteDetail;
  const routeTechFilterList = selectedRoute?.technicians.filter(t => t.isDisabled === false);

  const [selectedDraggableIds, setSelectedDraggableIds] = useState<string[]>([]);
  const [activeDraggableId, setActiveDraggableId] = useState<string | null>(null);

  const mapTechs: ITechnician[] = useMemo(() => {
    if (selectedRoute) {
      const updatedTechs = buildFlatTechnicianList([selectedRoute]);
      if (!selectedTechs.length) {
        return updatedTechs;
      }
      return updatedTechs.filter(t => selectedTechs.some(st => st.userId === t.userId));
    }
    return [];
  }, [selectedRoute, selectedTechs]);

  const handleSave = async () => {
    try {
      if (!selectedRoute) {
        return;
      }
      setIsSaving(true);

      const finalOrder: IFinalRoutePayload[] =
        updatedRoutes?.map(route => {
          return {
            serviceDate: route.serviceDate,
            routes: route.technicians.map(tech => {
              return {
                userId: tech.userId,
                scheduledServiceIds: tech.services.map(service => service.scheduledServiceId),
              };
            }),
          };
        }) || [];

      if (!finalOrder.length) {
        return;
      }
      const payload: IUpdateRoutesPayload = {
        updateMode,
        changes: toChangeListPayload(changes || {}),
        finalOrder,
      };

      await updateServiceRoutes(payload);
      fetchServiceRouteDetail && fetchServiceRouteDetail();
      setInitialRoutes(updatedRoutes);
      enqueueSnackbar(`Service route updated!`, {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error saving service route, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const [allSitesDraggableIds, setAllSitesDraggableIds] = useState<string[] | null>(null);

  const handleUpdateSelectedDraggableIds = (selectedDraggableIds: string[]) => {
    const startAndEndServiceId = '00000000-0000-0000-0000-000000000000';
    const filteredSelectedDraggableIds = selectedDraggableIds.filter(
      id => !id.includes(startAndEndServiceId)
    );
    setAllSitesDraggableIds(filteredSelectedDraggableIds);
    setSelectedDraggableIds(filteredSelectedDraggableIds);
  };

  const toggleSelection = (
    e: React.KeyboardEvent,
    checked: boolean,
    podId: string,
    techServices?: string[]
  ) => {
    if (checked) {
      handleCheckedState(podId, techServices);
    } else {
      handleUncheckedState(podId);
    }

    if (e.nativeEvent.shiftKey) {
      onShiftKeyPressed(techServices ?? [], podId);
    }
  };

  const handleCheckedState = (podId: string, techServices?: string[]) => {
    const techServiceIds = techServices ?? [];
    const idsFromDifferentTechService =
      allSitesDraggableIds?.filter(id => !techServiceIds.includes(id)) ?? [];

    //Uncheck checkboxes from a different tech service
    if (idsFromDifferentTechService.length > 0) {
      setAllSitesDraggableIds([]);
      setSelectedDraggableIds([]);
    }
    setAllSitesDraggableIds(prev => [...(prev ?? []), podId]);
    setSelectedDraggableIds(prev => [...(prev ?? []), podId]);
  };

  const handleUncheckedState = (podId: string) => {
    const serviceId = podId.split('_')[2];
    const updatedDraggableIds = (allSitesDraggableIds ?? []).filter(id => !id.includes(serviceId));
    setAllSitesDraggableIds(updatedDraggableIds);
    setSelectedDraggableIds(updatedDraggableIds);
  };

  const onShiftKeyPressed = (techServices: string[], podId: string) => {
    const shouldSelectRange = !allSitesDraggableIds || allSitesDraggableIds.length === 1;

    if (shouldSelectRange) {
      const startIndex = techServices.indexOf(selectedDraggableIds[0]);
      const endIndex = techServices.indexOf(podId);

      //Adjust the start and end index if needed.
      const adjustedStartIndex = Math.min(startIndex, endIndex);
      const adjustedEndIndex = Math.max(startIndex, endIndex);

      // Slice the techServices array to get the range of IDs to select
      const draggableIdListUpdated = techServices.slice(adjustedStartIndex, adjustedEndIndex + 1);

      setAllSitesDraggableIds(draggableIdListUpdated);
      setSelectedDraggableIds(draggableIdListUpdated);
    } else {
      setAllSitesDraggableIds([podId]);
      setSelectedDraggableIds([podId]);
    }
  };

  const onDragStart = (props: DragStart) => {
    if (selectedDraggableIds.includes(props.draggableId)) {
      setActiveDraggableId(props.draggableId);
    } else {
      setSelectedDraggableIds([]);
    }
  };

  const onDragEnd = (dropResult: DropResult) => {
    if (!onServicesChange) {
      return;
    }

    const changeSet = createDropResultChangeSet({
      dropResult,
      selectedDraggableIds,
      serviceRoutes,
      selectedTechs,
    });

    if (!changeSet.length) {
      setActiveDraggableId(null);
      return;
    }

    onServicesChange(changeSet);
    setActiveDraggableId(null);
    setSelectedDraggableIds([]);
  };

  // track unique options
  let unique: IService[] = [];
  const duplicateRoutes: IService[] = mapTechs
    .flatMap(tech => tech.services)
    // remove 0 options
    .filter(route => !!route.latitude || !!route.longitude)
    // remove start/end options
    .filter(route => !isRouteStartOrEnd(route))
    .filter(o => {
      if (
        unique.find(
          i => Number(i.longitude) === Number(o.longitude) && Number(i.latitude === o.latitude)
        )
      ) {
        return true;
      }

      unique.push(o);
      return false;
    });

  const optimizeRoutes = async () => {
    try {
      if (!selectedRoute?.serviceDate) {
        return;
      }
      setIsOptimizing(true);
      const res = await postRoutesToOptimize({
        startDate: selectedRoute?.serviceDate,
        endDate: selectedRoute?.serviceDate,
        optimizeMethod: selectedMethod,
        officeId: user?.officeId,
        userIds: selectedUserIds,
      });

      if (!res.modified.length) {
        enqueueSnackbar(
          'No routes found to optimize. Optimizing requires a minimum of 3 sites on the route.',
          {
            variant: 'info',
          }
        );
        setIsOptimizing(false);
        return;
      }
      const changes = buildChangeState(res.original, res.changes);
      setUpdatedRoutes(colorizeRoutes(res.modified));
      setChanges(changes);
      if (selectedUserIds.length) {
        const filteredTechs = buildFlatTechnicianList(res.original).filter(u =>
          selectedUserIds.some(id => id === u.userId)
        );
        const technicians = uniqBy(filteredTechs, u => u.userId);
        setSelectedTechs(technicians);
      }
      enqueueSnackbar('Routes optimized!', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(`Error optimizing routes, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsOptimizing(false);
    }
  };

  const hasInvalidRoutes = useMemo(
    () =>
      selectedTechs
        // Flat map for filtering
        .flatMap(tech => tech.services)
        // Will default to false if 0 (or any other falsey value)
        .filter(service => !service.latitude || !service.longitude).length > 0,
    [selectedTechs]
  );

  return (
    <>
      {(isOptimizing || isSaving) && <Loader type="overlay" position="centered" />}
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {selectedRoute && (
          <Box flex={1}>
            <RouteDayPicker
              hasChanges={hasChanges}
              confirmChangesLoss={confirmChangesLoss}
              isLoadingSelectedRoute={isLoadingSelectedRoute}
              setCurrentServiceRouteId={id => {
                // reset selected techs on day change since the techs are not the same
                setSelectedTechs([]);
                setCurrentServiceRouteId(id);
              }}
              selectedRoute={selectedRoute}
            />
          </Box>
        )}
        {selectedRoute && (
          <ServiceButtons
            updateMode={updateMode}
            onUpdateModeChange={onUpdateModeChange}
            handleSave={handleSave}
            isAdmin={isOfficeAdmin || isSuperAdmin}
            isDisabled={!hasChanges}
            isLoadingSelectedRoute={isLoadingSelectedRoute}
            confirmChangesLoss={confirmChangesLoss}
            hasChanges={hasChanges}
            onReset={reset}
            optimizeRoutes={optimizeRoutes}
            optimizeMethods={optimizeMethods || []}
            isLoadingOptimizationMethods={isLoadingOptimizationMethods}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
          />
        )}
      </Box>

      {!isLoadingSelectedRoute && selectedRoute && (
        <GridPrintContainer container spacing={2}>
          <GridContainer item xs={12} lg={4} xl={3}>
            <Stack gap={1}>
              {hasInvalidRoutes && (
                <StyledAlert severity="warning">
                  Some of these locations do not have the correct location data. The locations with
                  the warning icon will not be displayed on the map.
                </StyledAlert>
              )}
              <Typography color="primary" fontWeight="bold">
                {!isOfficeAdmin && user?.userName ? `${user?.userName}` : 'Filter by Technician'}
              </Typography>
              {isOfficeAdmin && (
                <TechnicianFilters
                  technicians={routeTechFilterList}
                  selectedTechs={selectedTechs}
                  setSelectedTechs={setSelectedTechs}
                  isLoading={false}
                />
              )}
              {!!selectedRoute && (
                <ServicesWrapper>
                  <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                    <Pod
                      updateMode={updateMode}
                      route={selectedRoute}
                      selectedTechs={mapTechs}
                      saving={isSaving}
                      hideEditButton
                      changes={changes}
                      showServiceIndex
                      serviceIndexStyle="inline"
                      isSingleViewMode
                      selectedDraggableIds={selectedDraggableIds}
                      activeDraggableId={activeDraggableId}
                      toggleSelection={toggleSelection}
                      colorizeSiteIndex={true}
                      serviceDate={selectedRoute.serviceDate}
                      handleUpdateSelectedDraggableIds={handleUpdateSelectedDraggableIds}
                    />
                  </DragDropContext>
                </ServicesWrapper>
              )}
            </Stack>
          </GridContainer>
          <Grid item xs={12} lg={8} xl={9}>
            {!isOptimizing && <AzureMap techs={mapTechs} setPushPins={setPushPins} />}
            {duplicateRoutes?.length > 0 && isOpen && (
              <FloatingToolbar>
                <Alert
                  color="info"
                  icon={<InfoOutlined />}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                  }
                >
                  There are multiple stops at the same address. On the map, this is depicted using a
                  + next to the stop number on the map pin (i.e. 3+)
                </Alert>
              </FloatingToolbar>
            )}
          </Grid>
        </GridPrintContainer>
      )}
    </>
  );
};

const GridContainer = styled(Grid)(({ theme }) => ({
  padding: 0,

  '@media print': {
    'page-break-after': 'always',
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  backgroundColor: 'rgba(211, 47, 47, .25)',
  color: theme.palette.common.black,
  '& .MuiAlert-icon': {
    color: theme.palette.error.main,
  },
}));

const ServicesWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up(992)]: {
    overflowY: 'auto',
    maxHeight: '31rem',
  },
}));

const GridPrintContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  '@media print': {
    display: 'block',
    '& > div': 'block',
  },
}));
