import { Page } from '../../components';
import { ScheduledServices } from './ScheduledServices';

export const ServicesPage = () => {
  return (
    <Page title="Maintenance Services">
      <ScheduledServices />
    </Page>
  );
};
