export interface ICommunicationSendPost {
  audienceId: number;
  officeId: string;
  bodyTemplate: string;
  subjectTemplate: string;  
  bccs?: string;
  recipients: string[];
  technicianId?: string;
  serviceDate?: Date;
}

export interface IRecipient {
  id: string;
  displayName: string;
  firstName: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  isSelected: boolean;
}
export interface IAudienceSelectionOption {
  value: string;
  description: string;
}

export enum ICommunicationAudience {
  None = 0,
  AllAccounts = 1,
  ByServiceDate = 2,
  ByServiceDateAndTechId = 3,
  BySanitizer = 4,
  ByRate = 5,
  ByServiceType = 6,
  ByEquipmentType = 7,
  WithOtsAndNoWeekly = 8,
}