import { FC } from 'react';
import { Page } from '../../../components';
import { Box } from '@mui/material';
import { AzureFunctionsDetails } from './azure-functions-details';

export const AzureFunctionsPage: FC = () => {
  return (
    <Page title="Azure Functions">
      <Box mt={2}>
        <AzureFunctionsDetails />
      </Box>
    </Page>
  );
};
