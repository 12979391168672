import { FC, useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {
  FilterForm,
  GridDataFetcher,
  useDataGrid,
  useFilters,
  ServerSideDataGrid,
  CardFiltersLayout,
  Card,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { IFilterLayout, IFeedback } from '../../../models';
import { faFilter, faFilterCircleXmark, faEye } from '@fortawesome/free-solid-svg-icons';
import { getFeedback, getFeedbackFilters } from '../../../fetch/feedback';
import { dateColFormatter, formatDate } from '../../../helpers';
import { FeedbackItemDetails } from './feedback-item-detail';

const filtersLayout: Record<string, IFilterLayout> = {
  Type: {
    sortOrder: 0,
    xs: 12,
    md: 6,
  },
  '': {
    sortOrder: 1,
    xs: 12,
    md: 6,
  },
  UserId: {
    sortOrder: 2,
    xs: 12,
    md: 6,
  },
  OfficeId: {
    sortOrder: 3,
    xs: 12,
    md: 6,
  },
};

export const FeedbackManagementTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [currentFeedbackId, setCurrentFeedbackId] = useState<string | null>(null);

  const handleManageFeedbackItem = (currentFeedbackItem: IFeedback) => {
    setIsShowingModal(true);
    setCurrentFeedbackId(currentFeedbackItem.feedbackId);
  };

  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset,
  } = useFilters({
    filterFetcher: useCallback(() => getFeedbackFilters(), []),
  });

  const dataFetcher: GridDataFetcher<IFeedback> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      if (!filtersInitialized) {
        return {
          continueLoading: true,
        };
      }

      try {
        const res = await getFeedback({
          sortDirection: sortDirection || 'desc',
          sortBy: sortColumn || 'whenCreated',
          perPage,
          page: page + 1,
          ...appliedFilters,
          startDate: Object.values(appliedFilters)[0]?.[0] ?? undefined,
          endDate: Object.values(appliedFilters)[0]?.[1] ?? undefined,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading feedback, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersInitialized, appliedFilters]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<IFeedback>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'feedback-management-grid',
      sortColumn: 'whenCreated',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'type',
        headerName: 'Regarding',
        type: 'string',
        flex: 1,
        maxWidth: 200,
        renderCell: (params: GridRenderCellParams<IFeedback>) => {
          return <>{params.row?.type}</>;
        },
      },
      {
        field: 'partialMessage',
        headerName: 'Feedback',
        type: 'string',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IFeedback>) => {
          return <>{params.row?.partialMessage}</>;
        },
      },
      {
        field: 'store',
        headerName: 'Office',
        type: 'string',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IFeedback>) => {
          return <>{params.row?.user?.office.name}</>;
        },
      },
      {
        field: 'user',
        headerName: 'User',
        type: 'string',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IFeedback>) => {
          return <>{params.row?.user?.userName}</>;
        },
      },
      {
        field: 'whenCreated',
        headerName: 'Date',
        type: 'string',
        valueFormatter: dateColFormatter,
        renderCell: (params: GridRenderCellParams<IFeedback>) => {
          return <>{params.row.whenCreated ? formatDate(params.row.whenCreated) : ''}</>;
        },
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        maxWidth: 100,
        renderCell: (params: GridRenderCellParams<IFeedback>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="View Feedback Item"
                onClick={() => {
                  handleManageFeedbackItem(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEye} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <>
      <Card
        cardTitleProps={{
          title: 'Feedback',
          action: (
            <>
              <Button
                onClick={onFilterToggle}
                startIcon={
                  <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                }
                className={clsx('print--none')}
                color="secondary"
                size="small"
                disabled={isLoading}
              >
                Filters
              </Button>
            </>
          ),
        }}
      >
        <CardFiltersLayout isOpen={isShowingFilters}>
          <FilterForm
            filters={filters}
            values={filterValues}
            filterLayouts={filtersLayout}
            defaultLayout={{ xs: 12 }}
            onSubmit={values => {
              onPageChange(0);
              onSubmitFilters(values);
            }}
            onChange={onFiltersChange}
            isSubmitting={isLoading}
            onReset={onReset}
          />
        </CardFiltersLayout>
        <ServerSideDataGrid
          autoHeight
          getRowId={(row: IFeedback) => row.feedbackId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
        />
      </Card>
      <FeedbackItemDetails
        isModal
        isModalOpen={isShowingModal}
        handleModalClose={() => {
          setIsShowingModal(false);
          setCurrentFeedbackId(null);
        }}
        currentFeedbackId={currentFeedbackId as string}
      />
    </>
  );
};
