import { FC } from 'react';
import { TextField as MuiTextField, BaseTextFieldProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { formatInputPhoneNumber } from '../../helpers/format';

interface PhoneTextFieldProps extends BaseTextFieldProps {
  name: string;
}

export const PhoneTextField: FC<PhoneTextFieldProps> = ({ name, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event: React.SyntheticEvent): void => {
    let target = event.target as HTMLInputElement;
    const { value } = target;
    setFieldTouched(name, true, true);
    setFieldValue(name, value);
  };

  const config = {
    fullWidth: true,
    size: 'small' as 'small',
    ...field,
    ...props,
    onChange: handleChange,
  };

  if (meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <MuiTextField
      {...config}
      onBlur={e => {
        props?.onBlur?.(e);
        setFieldValue(name, formatInputPhoneNumber(e.target.value));
      }}
    />
  );
};
