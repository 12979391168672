import { FC } from 'react';
import { Stack, Box, Checkbox, Chip, Tooltip } from '@mui/material';
import { LinkOff as LinkOffIcon } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { ICustomViewFormValues } from '../../../models/custom-view';

interface GroupingCheckboxProps {
  index: number;
  disabled?: boolean;
  groupingIndicator?: string;
}

export const GroupingCheckbox: FC<GroupingCheckboxProps> = ({
  index,
  disabled = false,
  groupingIndicator,
}) => {
  const { setFieldValue, values } = useFormikContext<ICustomViewFormValues>();

  const handleChange = (event: React.SyntheticEvent): void => {
    let target = event.target as HTMLInputElement;
    const { checked } = target;
    setFieldValue(`searchConditions.${index}.isPending`, Boolean(checked));
  };

  const ungroup = (groupId: number) => {
    values.searchConditions.forEach((condition, index) => {
      if (condition.groupId === groupId) {
        setFieldValue(`searchConditions.${index}.isGrouped`, false);
        setFieldValue(`searchConditions.${index}.groupId`, 0);
      }
    });
  };

  return (
    <Stack
      justifyContent={{ xs: 'flex-start', md: 'center' }}
      flexDirection={{ xs: 'row', md: 'column' }}
    >
      {values.searchConditions[index].isGrouped ? (
        <Box my={1}>
          <Tooltip title="Ungroup" placement="right">
            <Chip
              icon={<LinkOffIcon fontSize="small" sx={{ paddingLeft: 1 }} />}
              onClick={() => ungroup(values.searchConditions[index].groupId)}
              clickable
              label={`Group ${groupingIndicator}`}
              size="small"
              variant="outlined"
              data-testid="grouping-chip"
            />
          </Tooltip>
        </Box>
      ) : (
        <Tooltip title="Select row to be grouped" placement="right">
          <Checkbox disabled={disabled} data-testid="grouping-checkbox" onChange={handleChange} />
        </Tooltip>
      )}
      <input name={`searchConditions.${index}.isPending`} type="hidden" />
      <input name={`searchConditions.${index}.isGrouped`} type="hidden" />
      <input name={`searchConditions.${index}.groupId`} type="hidden" />
    </Stack>
  );
};
