import { Page } from '../../../components';
import { OTSLandingServices } from './ots-landing-services';
import { BrandingContext } from '../../../context/branding-context';
import { useContext } from 'react';

export const OTSLandingPage = () => {
  const { isPoolService } = useContext(BrandingContext);
  return (
    <Page title="One-Time Services">
      <OTSLandingServices shouldUseSearchParams={isPoolService} isMainLandingPage />
    </Page>
  );
};
