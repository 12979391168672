import { Autocomplete, TextField, Box } from '@mui/material';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../context';
import { IScheduledService } from '../../models';
import { getScheduledService, getScheduledServices } from '../../fetch';
import { useQuery } from 'react-query';
import { formatDate } from '../../helpers';
import { format } from 'date-fns';

interface IScheduledServiceAutocomplete {
  setSelectedScheduledService?: (val: IScheduledService | null) => void;
  isDisabled?: boolean;
  handleOptions?: (val: IScheduledService[]) => void;
  selectedScheduledService?: IScheduledService | null;
  hasError?: boolean;
  helperText?: string;
  handleBlur?: (e: any) => void;
  isRequired?: boolean;
  labelText?: string;
  placeholder?: string;
  shouldRefetch?: boolean;
  shouldFetch?: boolean;
  accountId?: string;
  siteId?: string;
  value: string | null;
  handleChange: (val: string) => void;
  id?: string;
  status?: string | string[];
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  startDate?: string;
  showSiteName?: boolean;
}

export const ScheduledServiceAutocomplete: FC<IScheduledServiceAutocomplete> = ({
  isDisabled,
  handleOptions,
  handleChange,
  hasError,
  helperText,
  selectedScheduledService,
  setSelectedScheduledService,
  handleBlur,
  isRequired,
  labelText,
  shouldRefetch,
  placeholder,
  accountId,
  siteId,
  value,
  id,
  status = 'O',
  sortBy = 'serviceDate',
  sortDirection,
  shouldFetch = true,
  startDate,
  showSiteName,
}) => {
  const { user } = useContext(UserContext);
  const [isOpen, setOpen] = useState(false);
  const [fetchOptionDetails, setFetchOptionDetails] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState<IScheduledService | null>(
    selectedScheduledService ?? null
  );

  const getStartDate = useMemo(() => {
    if (!!startDate) {
      return format(new Date(startDate), "yyyy-MM-dd'T'HH:mm:ss");
    }
    return undefined;
  }, [startDate]);

  const {
    isLoading: isLoadingScheduledServices,
    data: services,
    refetch,
  } = useQuery(
    ['getScheduledServices', user, shouldFetch, getStartDate],
    async () => {
      const res = await getScheduledServices({
        accountId: accountId,
        siteId: siteId,
        officeId: user?.officeId?.toString(),
        perPage: -1,
        status: status,
        sortBy: sortBy,
        sortDirection: sortDirection ? sortDirection : getStartDate ? 'asc' : 'desc',
        startDate: getStartDate,
      });
      return res.data;
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: shouldFetch,
      onSuccess: options => {
        handleOptions?.(options);
        if (!!value) {
          const existingOption = options.find(
            service => service.scheduledServiceId === value
          ) as IScheduledService;
          if (!!existingOption) {
            setAutocompleteValue(existingOption);
            setFetchOptionDetails(false);
          } else {
            setFetchOptionDetails(true);
          }
          return handleChange(value);
        }
        if (options?.length === 1) {
          setAutocompleteValue(options[0]);
          return handleChange(options?.[0].scheduledServiceId);
        }
      },
    }
  );

  const { isLoading: isLoadingScheduledService } = useQuery(
    ['getScheduledService', value],
    async () => {
      const res = await getScheduledService(value!);
      return res;
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!value && fetchOptionDetails,
      onSuccess: option => {
        setSelectedScheduledService?.(option);
        setAutocompleteValue(option);
      },
    }
  );

  useEffect(() => {
    if (shouldRefetch && isOpen && !isLoadingScheduledServices) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch, refetch, isOpen]);

  useEffect(() => {
    if (selectedScheduledService !== undefined) {
      setAutocompleteValue(selectedScheduledService);
    }
  }, [selectedScheduledService, accountId, siteId]);

  return (
    <>
      <Box display="flex" gap={1} alignItems="flex-start">
        <Autocomplete
          fullWidth
          selectOnFocus
          handleHomeEndKeys
          open={isOpen}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={autocompleteValue}
          disabled={isLoadingScheduledService || isLoadingScheduledServices || isDisabled}
          loading={isLoadingScheduledService || isLoadingScheduledServices}
          onChange={(e, newValue: IScheduledService | null, reason) => {
            setAutocompleteValue(newValue);
            if (reason === 'clear') {
              return handleChange('');
            }
            setSelectedScheduledService?.(newValue);
            handleChange(newValue?.scheduledServiceId as string);
          }}
          onBlur={handleBlur}
          options={services || []}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option;
            }
            const date = formatDate(option.serviceDate);
            const type = option.serviceType ? ` ${option.serviceType}` : '';
            return `${date} - ${option.siteName} - ${type}`;
          }}
          renderOption={(props: any, option: IScheduledService) => {
            const date = formatDate(option.serviceDate);
            const type = option.serviceType ? ` - ${option.serviceType}` : '';
            const siteName = showSiteName && option.siteName ? ` - ${option.siteName}` : '';
            return (
              <li {...props} key={option.scheduledServiceId}>
                {date}
                {siteName}
                {type}
              </li>
            );
          }}
          renderInput={params => {
            return (
              <TextField
                {...params}
                size="small"
                key={params.id}
                label={
                  isLoadingScheduledServices || isLoadingScheduledService
                    ? 'Loading...'
                    : labelText ?? 'Scheduled Service'
                }
                required={isRequired ?? true}
                fullWidth
                autoComplete="none"
                variant="outlined"
                placeholder={placeholder ?? 'Select a scheduled service'}
                error={hasError}
                helperText={helperText}
                name="scheduledServiceId"
                title={`${selectedScheduledService?.scheduledServiceId}`}
                id={`scheduled-service-autocomplete-${id ? id : ''}`}
              />
            );
          }}
        />
      </Box>
    </>
  );
};
