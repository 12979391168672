import { ReactNode, useCallback, useMemo } from 'react';
import { styled } from '@mui/material';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  ICalendarDateRange,
  ITimelessEventChange,
  IWeekday,
  ITimelessEvent,
} from '../../../../../models';
import { getEnumValues, parseCalendarDate, viewWeekDays } from '../../../../../helpers';
import Typography from '@mui/material/Typography';
import { orderBy } from 'lodash';
import { DroppableMonthDayCell } from './DroppableMonthDayCell';
import { addDays, eachWeekOfInterval } from 'date-fns';

export interface TimelessMonthViewProps<T> {
  events: ITimelessEvent<T>[];
  weekStartsOn?: IWeekday;
  renderEvent: (event: ITimelessEvent<T>) => ReactNode;
  dateRange: ICalendarDateRange;
  onDateChange?: (change: ITimelessEventChange<T>) => unknown;
  allowDragging?: boolean;
}

export function TimelessMonthView<T>({
  events,
  weekStartsOn = IWeekday.Monday,
  renderEvent,
  dateRange,
  onDateChange,
  allowDragging = true,
}: TimelessMonthViewProps<T>) {


  const eachWeekStart = useMemo(() => {
    return eachWeekOfInterval(
      {
        start: dateRange.startDate,
        end: dateRange.endDate,
      },
      { weekStartsOn }
    );
  }, [dateRange, weekStartsOn]);

  const eventsByDate = useMemo(() => {
    const rtn: Record<string, ITimelessEvent<T>[]> = {};
    if (!events.length) {
      return rtn;
    }
    events.forEach(item => {
      const day = item.date.toISOString();
      if (!rtn[day]) {
        rtn[day] = [];
      }
      rtn[day].push(item);
    });

    Object.entries(rtn).forEach(([day, eventItems]) => {
      rtn[+day] = orderBy(eventItems, detail => detail.index);
    });
    return rtn;
  }, [events]);

  const onDragEnd = useCallback(
    (dropResult: DropResult) => {
      if (!onDateChange) {
        return;
      }
      const { source, destination, draggableId } = dropResult;

      if (!destination) {
        return [];
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return [];
      }

      const calendarDate = destination.droppableId;
      const targetIndex = destination.index;

      const event = events.find(e => e.id === draggableId);

      if (!event) {
        return;
      }

      const change: ITimelessEventChange<T> = {
        event,
        targetCalendarDate: parseCalendarDate(calendarDate),
        targetIndex,
      };

      onDateChange(change);
    },
    [events, onDateChange]
  );

  const weekdays = getEnumValues(IWeekday) as IWeekday[];

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DnDInnerWrapper>
        <div className={classes.weekdayHeaders}>
          {viewWeekDays.map(weekday => {
            return (
              <StyledColumn key={weekday} className={classes.weekdayColumn}>
                <div>
                  <Typography className={classes.weekdayTitle}>{weekday}</Typography>
                </div>
              </StyledColumn>
            );
          })}
        </div>
        {eachWeekStart.map(weekStartDate => {
          return (
            <div key={weekStartDate.toISOString()} className={classes.weekRow}>
              {weekdays.map(weekday => {
                const date = addDays(weekStartDate, weekday);
                const dateISO = date.toISOString();
                return (
                  <div key={dateISO} className={classes.dayCell}>
                    <DroppableMonthDayCell date={date}>
                      {eventsByDate[dateISO]?.map((event, index) => (
                        <Draggable
                          key={event.id}
                          isDragDisabled={
                            // @ts-ignore
                            !allowDragging || event?.data?.event?.eventType === 'ScheduledTask'
                          }
                          index={index}
                          draggableId={event.id}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.draggbleContainer}
                            >
                              {renderEvent(event)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </DroppableMonthDayCell>
                  </div>
                );
              })}
            </div>
          );
        })}
      </DnDInnerWrapper>
    </DragDropContext>
  );
}

const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';

const PREFIX = 'MonthView';

const classes = {
  weekdayHeaders: `${PREFIX}-weekdayHeaders`,
  weekdayColumn: `${PREFIX}-weekdayColumn`,
  weekdayTitle: `${PREFIX}-weekdayTitle`,
  draggbleContainer: `${PREFIX}-draggbleContainer`,
  weekRow: `${PREFIX}-weekRow`,
  dayCell: `${PREFIX}-dayCell`,
  dayNumber: `${PREFIX}-dayNumber`
};

const DnDInnerWrapper = styled('div')(({ theme }) => ({
  [`& .${classes.weekdayHeaders}`]: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
    position: 'sticky',
    top: -1,
    zIndex: 10,
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    [MOBILE_MEDIA_QUERY]: {
      fontSize: 11,
    },
  },

  [`& .${classes.weekdayTitle}`]: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    [MOBILE_MEDIA_QUERY]: {
      fontSize: 11,
    },
  },

  [`& .${classes.draggbleContainer}`]: {
    width: '100%',
  },

  [`& .${classes.weekRow}`]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },

  [`& .${classes.dayCell}`]: {
    position: 'relative',
    minHeight: '100px',
    flex: '1 1 auto',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    '&:nth-child(odd)': {
      backgroundColor: '#EAEEF6',
    },
  },

  [`& .${classes.dayNumber}`]: {
    position: 'absolute',
    top: 0,
  }
}));

const StyledColumn = styled('div')(({ theme }) => ({

  [`&.${classes.weekdayColumn}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    flex: '1 1 auto',
    height: '100%',

    '&:nth-child(odd)': {
      backgroundColor: '#DEEAF4',
    },

    '&:nth-child(even)': {
      backgroundColor: '#EFFCFE',
    },
    '@media print': {
      width: 'auto',
    },
    '.print-calendar--stack &&': {
      '@media print': {
        width: '100%',
        borderRight: 'none',
      },
    },
  },

}));
