import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip, styled } from '@mui/material';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getSite, getAccount } from '../../fetch';
import { SiteAddressModal } from '../../pages/sites/site-address-modal';
import { useQuery } from 'react-query';
import { IAccountDetail, ISiteGetResponse } from '../../models';

interface IInvalidLocationIcon {
  accountId?: string | null;
  siteId?: string | null;
  tooltipText?: string;
  handleSiteChange?: () => void;
}

export const InvalidLocationIcon: FC<IInvalidLocationIcon> = ({
  accountId,
  siteId,
  tooltipText,
  handleSiteChange,
}) => {
  const [openSiteAddressModal, setOpenSiteAddressModal] = useState(false);

  const {
    isFetching: isFetchingSite,
    refetch: fetchSite,
    data: site,
  } = useQuery<ISiteGetResponse, Error>(['getSite', siteId], () => getSite(siteId!), {
    enabled: false,
  });

  const {
    isFetching: isFetchingAccount,
    refetch: fetchAccount,
    data: account,
  } = useQuery<IAccountDetail, Error>(['getAccount', accountId], () => getAccount(accountId!), {
    enabled: false,
  });

  const renderInvalidLocationIcon = (
    <Tooltip
      title={
        tooltipText ?? (accountId && siteId)
          ? 'Site address is invalid - click to fix'
          : 'Site address is invalid'
      }
    >
      <IconWrapper>
        <FontAwesomeIcon icon={faTriangleExclamation} size="lg" />
      </IconWrapper>
    </Tooltip>
  );
  if (!!siteId && !!accountId) {
    return (
      <>
        <IconButton
          sx={{ padding: 0, fontSize: theme => theme.spacing(2) }}
          onClick={() => {
            setOpenSiteAddressModal(true);
            fetchSite();
            fetchAccount();
          }}
        >
          {renderInvalidLocationIcon}
        </IconButton>
        {handleSiteChange && (
          <SiteAddressModal
            isOpen={openSiteAddressModal}
            handleClose={(shouldUpdate?: boolean) => {
              setOpenSiteAddressModal(false);
              if (shouldUpdate) {
                handleSiteChange?.();
              }
            }}
            site={site}
            accountAddress={account?.address}
            accountId={accountId!}
            isLoading={isFetchingSite || isFetchingAccount}
          />
        )}
      </>
    );
  }
  return renderInvalidLocationIcon;
};

const IconWrapper = styled('span')(({ theme }) => ({
  '& svg': {
    borderRadius: '50%',
    color: theme.palette.error.main,
  },
}));
