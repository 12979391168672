import { FC, useState } from 'react';
import { Alert, Grid, Button } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { generateAccessToken } from '../../../fetch';
import { DisplayGroup, CardButton } from '../../../components';
import { useConfirm } from '../../../hooks';
import { useSnackbar } from 'notistack';
import { formatShortFriendlyDateWithTime, copyTextToClipboard, truncate } from '../../../helpers';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';

export const AuthDetails: FC = () => {
  const confirm = useConfirm();
  const [data, setData] = useState<{ accessToken: string; expiresAt: string } | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyClick = async (copyText: string) => {
    // Asynchronously call copyTextToClipboard
    await copyTextToClipboard(copyText);
  };
  return (
    <>
      <Grid container spacing={2}>
        {data && (
          <Alert severity="success" sx={{ width: '100%' }}>
            <DisplayGroup label="API Access Token:">
              <span style={{ wordBreak: 'break-all' }}>{truncate(data?.accessToken, 80)}</span>
              <Button
                onClick={() => handleCopyClick(data.accessToken!)}
                title="Copy"
                color="secondary"
                startIcon={<CopyAll />}
                variant="text"
                sx={{ marginLeft: '10px', padding: 0 }}
              >
                Copy
              </Button>
            </DisplayGroup>
            {data?.expiresAt && (
              <DisplayGroup label="Expires At:">
                {data?.expiresAt ? formatShortFriendlyDateWithTime(data.expiresAt!) : ''}
              </DisplayGroup>
            )}
          </Alert>
        )}
        <Grid item xs={12} sm={4}>
          <CardButton
            title="Generate API Access Token"
            alignCenter
            onClick={async () => {
              const result = await confirm(
                'Are you sure you want to generate a new API access token?'
              );
              if (result) {
                try {
                  const res = await generateAccessToken();
                  setData(res);
                  await copyTextToClipboard(res.accessToken);
                  enqueueSnackbar('Access token generated and copied to clipboard!', {
                    variant: 'success',
                  });
                } catch (error: any) {
                  enqueueSnackbar(
                    error?.Detail ?? `Error generating api access token, please try again.`,
                    {
                      variant: 'error',
                    }
                  );
                }
              }
            }}
            icon={faLockOpen}
          />
        </Grid>
      </Grid>
    </>
  );
};
