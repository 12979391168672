import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Link, Typography, Button, Divider } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick, styleGuideClientName } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelIcon, ExpandMoreButton, SaveButton } from '../../../components';
import { StyleGuidePaths } from '../../../constants';

export const StyleGuideButtons = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  const [expanded, setExpanded] = useState(true);

  return (
    <StyleGuidePage title="Buttons">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            There are multiple button components that are built upon the{' '}
            <Link href="https://mui.com/material-ui/react-button/" target="_blank">
              Mui Button
            </Link>{' '}
            component.
          </Typography>
          <Typography mb={1}>
            See <Link href={StyleGuidePaths.uiUxPatterns.url}>UI/UX patterns</Link> for more details
            and implementation guidance (i.e. consistent icons, colors, size, etc.).
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Typography mb={0.5} variant="h5" component="h2" color="primary">
            Save Button
          </Typography>
          <Divider sx={{ marginBottom: theme => theme.spacing(2) }} />
          <Typography mb={1}>For use as the Save/submit button in forms.</Typography>
          <code>
            Props:
            <br />- text?: string
            <br />- icon?: any
            <br />- handleSave?: (e: React.MouseEvent) =&gt; void
            <br />- sx?: any
          </code>
          <Box mb={1} mt={2}>
            <SaveButton id="save-button" handleSave={() => {}} />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;SaveButton id=&quot;save-button&quot; handleSave=&#123;() =&gt; &#123;&#125;&#125;
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography mb={0.5} variant="h5" component="h2" color="primary">
            Expand Button
          </Typography>
          <Divider sx={{ marginBottom: theme => theme.spacing(2) }} />
          <Typography mb={1}>
            For use as in expand collapse situations, such as the Card Title and Credit Limit Alert
            components.
          </Typography>
          <code>
            Props:
            <br />- expand: boolean
            <br />- setExpanded: (val: boolean) =&gt; void;
          </code>
          <Box mb={1} mt={2}>
            <ExpandMoreButton expand={expanded} setExpanded={setExpanded} />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;ExpandMoreButton expand=&#123;expanded&#125; setExpanded=&#123;setExpanded&#125;
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12}>
          <Typography mb={0.5} variant="h5" component="h2" color="primary">
            MUI Button Variants
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            <strong>In most cases, the MUI Button will suffice.</strong> {styleGuideClientName} uses
            the <em>contained</em> variant for buttons. The most common colors for buttons used are{' '}
            <em>primary</em>, <em>secondary</em>, and <em>primary-invert</em>. Other possible colors
            include <em>error</em>, <em>success</em>, <em>tertiary</em>, <em>cancel</em>, and{' '}
            <em>white</em>.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Button id="primary-button" color="primary" variant="contained" href="/">
              Button
            </Button>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Button id="primary-button" color="primary" variant="contained" href="/"&gt; <br />
            &nbsp;&nbsp;&nbsp;Button <br />
            &lt;/Button&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Button id="secondary-button" color="secondary" variant="contained" href="/">
              Button
            </Button>
          </Box>

          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Button id="secondary-button" color="secondary" variant="contained" href="/"&gt;{' '}
            <br />
            &nbsp;&nbsp;&nbsp;Button <br />
            &lt;/Button&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Button id="error-button" color="error" variant="contained" href="/">
              Button
            </Button>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Button id="error-button" color="error" variant="contained" href="/"&gt; <br />
            &nbsp;&nbsp;&nbsp;Button <br />
            &lt;/Button&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Button id="success-button" color="success" variant="contained" href="/">
              Button
            </Button>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Button id="success-button" color="success" variant="contained" href="/"&gt; <br />
            &nbsp;&nbsp;&nbsp;Button <br />
            &lt;/Button&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Button
              id="test-button-8"
              color="inherit"
              variant="contained"
              href="/"
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Button id="test-button-8" color="cancel" variant="contained" href="/"
            startIcon=&#123;&lt;CancelIcon /&gt;&#125;&gt; <br />
            &nbsp;&nbsp;&nbsp;Cancel <br />
            &lt;/Button&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Button
              id="icon-button"
              color="secondary"
              variant="contained"
              size="small"
              href="/"
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              Add Button
            </Button>
          </Box>

          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Button id="icon-button" color="secondary" variant="contained" href="/"
            startIcon=&#123;faPlusCircle&#125;&gt; <br />
            &nbsp;&nbsp;&nbsp;Add Button <br />
            &lt;/Button&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Button id="small-button" color="primary" variant="outlined" href="/" size="small">
              Small Button
            </Button>
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Button id="small-button" color="primary" variant="outlined" href="/" size="sm"&gt;{' '}
            <br />
            &nbsp;&nbsp;&nbsp;Small Button <br />
            &lt;/Button&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
