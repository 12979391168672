import React, { FC } from 'react';
import { SxProps, styled } from '@mui/material';
import clsx from 'clsx';
import { Link as MLink } from 'react-router-dom';

interface ILink {
  className?: string;
  to: string;
  type?: 'primary' | 'white' | 'grey' | 'secondary' | 'error' | 'black';
  hasTextDecoration?: boolean;
  title?: string;
  target?: React.HTMLAttributeAnchorTarget;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  sx?: SxProps;
}

export const Link: FC<ILink> = ({
  to,
  children,
  hasTextDecoration,
  className,
  type = 'primary',
  title,
  target,
  onClick,
  sx,
  ...rest
}) => {
  return (
    <StyledLink
      onClick={onClick}
      to={to}
      target={target}
      className={clsx(classes[type], className)}
      title={title}
      hasTextDecoration={hasTextDecoration}
      sx={sx}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

const PREFIX = 'Link';

const classes = {
  white: `${PREFIX}-white`,
  grey: `${PREFIX}-grey`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  error: `${PREFIX}-error`,
  black: `${PREFIX}-black`,
};

const StyledLink = styled(MLink, {
  shouldForwardProp: prop => prop !== 'hasTextDecoration',
})<{ hasTextDecoration?: boolean }>(({ theme, hasTextDecoration }) => ({
  [`&.${classes.white}`]: {
    color: theme.palette.background.paper,
    textDecoration: hasTextDecoration ? 'underline' : 'none',
    '&:hover': {
      color: theme.palette.common.white,
    },
  },

  [`&.${classes.grey}`]: {
    color: theme.palette.grey[300],
    textDecoration: hasTextDecoration ? 'underline' : 'none',
    '&:hover': {
      color: theme.palette.grey[500],
    },
  },

  [`&.${classes.primary}`]: {
    color: theme.palette.primary.main,
    textDecoration: hasTextDecoration ? 'underline' : 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },

  [`&.${classes.secondary}`]: {
    color: theme.palette.secondary.main,
    textDecoration: hasTextDecoration ? 'underline' : 'none',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },

  [`&.${classes.error}`]: {
    color: theme.palette.error.main,
    textDecoration: hasTextDecoration ? 'underline' : 'none',
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },

  [`&.${classes.black}`]: {
    color: theme.palette.common.black,
    textDecoration: hasTextDecoration ? 'underline' : 'none',
    '&:hover': {
      color: theme.palette.common.black,
    },
  },
}));
