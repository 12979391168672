import { TextField } from '@mui/material';
import { FC } from 'react';
import { formatMoney } from '../../helpers';

interface IAmountInput {
  value: string | number;
  disabled?: boolean;
  onChange: (val: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: boolean;
  helperText?: string;
  label: string;
  name: string;
  required?: boolean;
  autoSelectOnFocus?: boolean;
}

export const AmountInput: FC<IAmountInput> = ({
  value,
  disabled = false,
  onChange,
  onBlur,
  helperText,
  error,
  label,
  name,
  required = true,
  autoSelectOnFocus,
}) => {
  return (
    <TextField
      InputProps={{
        onFocus: autoSelectOnFocus
          ? e => {
              e.currentTarget.select();
            }
          : undefined,
      }}
      required={required}
      placeholder="0.00"
      inputProps={{ step: '0.01' }}
      value={value}
      disabled={disabled}
      onChange={e => {
        onChange(e.target.value);
      }}
      name={name}
      label={label}
      size="small"
      fullWidth
      onBlur={(e: any) => {
        const newValue = formatMoney(e.target.value, 2);
        onBlur?.(e);
        onChange(newValue);
      }}
      error={error}
      helperText={helperText}
    />
  );
};
