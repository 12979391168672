import { FC, useContext, useState } from 'react';
import { Card, CardTitle, Loader, QuickBooksConnectButton } from '../../../components';
import { QuickBooksOptionsForm } from './quickbooks-options-form';
import { Box, Typography, Stack, Alert } from '@mui/material';
import { QBTranCodesTable } from './quickbooks-tran-codes-table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserContext } from '../../../context';
import { checkAccountingProviderDuplicates, disconnectAccountingProvider } from '../../../fetch';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { EAccountProvider, EAccountProviderEntryType } from '../../../models';

export const QuickBooksPage: FC = () => {
  const {
    isLoadingOffice,
    accountingProvider,
    user,
    reloadAccountingProvider,
    isLoadingAccountProvider,
  } = useContext(UserContext);
  const featureFlags = useFlags();
  const { enqueueSnackbar } = useSnackbar();
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const {
    isFetching: isCheckingDuplicateConnections,
    refetch: checkDuplicateConnections,
    data: hasDuplicateConnections,
  } = useQuery<boolean, Error>(
    ['checkAccountingProviderDuplicates', user?.officeId],
    () => checkAccountingProviderDuplicates(user?.officeId!),
    {
      enabled: !!user?.officeId && accountingProvider?.provider === EAccountProvider.QuickBooks,
    }
  );

  return (
    <>
      <Card>
        {(isLoadingAccountProvider ||
          isDisconnecting ||
          isCheckingDuplicateConnections ||
          isLoadingOffice) && <Loader type="fullscreen" position="centered" />}
        <CardTitle
          title="Quickbooks Options"
          mobileWrap
          action={
            !!accountingProvider ? (
              <Stack
                flexDirection={{
                  xs: 'column',
                  sm: 'row',
                }}
                gap={1}
              >
                <QuickBooksConnectButton
                  buttonText={`Connect to Quickbooks`}
                  color="secondary"
                  size="small"
                  buttonKey="connect"
                  afterClick={() => checkDuplicateConnections()}
                />
                <QuickBooksConnectButton
                  color="secondary"
                  size="small"
                  buttonText={`Refresh Quickbooks`}
                  buttonKey="refresh"
                  afterClick={() => checkDuplicateConnections()}
                />
                <QuickBooksConnectButton
                  size="small"
                  color="inherit"
                  buttonText="Disconnect Quickbooks"
                  buttonKey="disconnect"
                  confirmMessage={`Are you sure you want to disconnect Quickbooks? (NOTE: It may take a couple of minutes to disconnect.)`}
                  handleClick={async () => {
                    try {
                      setIsDisconnecting(true);
                      await disconnectAccountingProvider(user?.officeId as string);
                      reloadAccountingProvider();
                      enqueueSnackbar('Connection disconnected', {
                        variant: 'success',
                      });
                      checkDuplicateConnections();
                    } catch (error: any) {
                      enqueueSnackbar(error?.Detail ?? `Error disconnecting, please try again.`, {
                        variant: 'error',
                      });
                    } finally {
                      setIsDisconnecting(false);
                    }
                  }}
                />
              </Stack>
            ) : undefined
          }
        />
        {!accountingProvider && !isLoadingAccountProvider && !isLoadingOffice && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="10rem"
          >
            <Typography mb={2} align="center">
              There are no Quickbooks accounts configured. Please click the button below to connect
              to Quickbooks.
            </Typography>
            <QuickBooksConnectButton
              buttonKey="first-connect"
              buttonText={`Connect to Quickbooks`}
              afterClick={() => reloadAccountingProvider()}
            />
          </Box>
        )}
        {!!accountingProvider && (
          <>
            {hasDuplicateConnections && (
              <Alert severity="warning" sx={{ marginTop: -1, marginBottom: 2 }}>
                Another office is connected to the same Quickbooks account. Please contact support
                for assistance.
              </Alert>
            )}
            <QuickBooksOptionsForm />
          </>
        )}
      </Card>
      {accountingProvider?.provider === EAccountProvider.QuickBooks &&
        accountingProvider?.entryType === EAccountProviderEntryType.JournalEntry &&
        featureFlags.quickbooks && (
          <Box mt={2}>
            <QBTranCodesTable />
          </Box>
        )}
    </>
  );
};
