import { FC, useMemo, useState } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps } from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import {
  formatShortFriendlyDateWithTime,
  hyphenSeparateTwoInputs,
  unCamelCase,
} from '../../../helpers';
import { IOutboxMessage } from '../../../models';
import { Box, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { MessageDetails } from './message-detail';
import { requeueOutboxMessage } from '../../../fetch';
import { useConfirm } from '../../../hooks';
import { useSnackbar } from 'notistack';

interface IMessagesDataGrid extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IOutboxMessage[];
  refetch: () => Promise<void>;
}

export const MessagesDataGrid: FC<IMessagesDataGrid> = ({ rows, refetch, ...gridProps }) => {
  const [messageDetail, setMessageDetail] = useState<IOutboxMessage | null>(null);
  const [isRequeueLoading, setRequeueLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const handleRequeue = async (id: string) => {
    const result = await confirm('Are you sure you want to requeue this message?');
    if (result) {
      try {
        setRequeueLoading(true);
        await requeueOutboxMessage(id);
        await refetch();
        enqueueSnackbar('Message requeued', {
          variant: 'success',
        });
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error requeuing message, please try again.`, {
          variant: 'error',
        });
      } finally {
        setRequeueLoading(false);
      }
    }
  };
  const columns = useMemo(() => {
    return [
      {
        field: 'occurredOnUtc',
        headerName: 'Occurred On',
        filterable: false,
        sortable: true,
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IOutboxMessage>) => {
          const { row: logItem } = params;
          return logItem.occurredOnUtc
            ? formatShortFriendlyDateWithTime(logItem.occurredOnUtc)
            : '';
        },
      },
      {
        field: 'processedOnUtc',
        headerName: 'Processed On',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IOutboxMessage>) => {
          const { row: logItem } = params;
          return logItem.processedOnUtc
            ? formatShortFriendlyDateWithTime(logItem.processedOnUtc)
            : '';
        },
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IOutboxMessage>) => {
          const { row: logItem } = params;
          return unCamelCase(logItem.type);
        },
      },
      {
        field: 'error',
        headerName: 'Error',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'attempts',
        headerName: 'Attempts',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        maxWidth: 100,
        renderCell: (params: GridRenderCellParams<IOutboxMessage>) => {
          return (
            <Box display="flex" alignItems="center">
              <IconButton
                color="secondary"
                title="View Message"
                onClick={() => {
                  setMessageDetail(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEye} size="sm" />
              </IconButton>
              <IconButton
                color="secondary"
                title="Requeue Message"
                onClick={() => handleRequeue(params.row.id)}
              >
                <FontAwesomeIcon icon={faRefresh} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ] as GridColDef[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <>
      <ServerSideDataGrid
        isKeysetTable
        autoHeight
        getRowId={(row: IOutboxMessage) => row.id!}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        columnHeaderHeight={36}
        hasMobileLayout
        mobileProps={{
          mobileCustomDefaultAccessor: (row: IOutboxMessage) => {
            return hyphenSeparateTwoInputs(
              formatShortFriendlyDateWithTime(row?.occurredOnUtc) ?? '',
              unCamelCase(row.type)
            );
          },
          handleView: (row: IOutboxMessage) => {
            setMessageDetail(row);
          },
          handleRefresh: (row: IOutboxMessage) => {
            handleRequeue(row.id);
          },
        }}
        loading={isRequeueLoading || gridProps.loading}
        {...gridProps}
      />
      {!!messageDetail && (
        <MessageDetails
          isModalOpen={true}
          handleModalClose={() => setMessageDetail(null)}
          message={messageDetail?.content ?? ''}
          messageId={messageDetail?.id ?? ''}
          refetchMessages={refetch}
        />
      )}
    </>
  );
};
