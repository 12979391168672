import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalLink } from '../link';
import { Typography, styled } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';

interface ITopLevelNav {
  to: string;
  icon: IconProp;
  text: string;
  isExternal?: boolean;
  isVisible?: boolean;
}

export const TopLevelNav: FC<ITopLevelNav> = ({
  to,
  icon,
  text,
  isExternal = true,
  isVisible = true,
}) => {

  if (!isVisible) {
    return null;
  }
  if (isExternal) {
    return (
      <StyledExternalLink to={to} className={classes.linkButton}>
        <FontAwesomeIcon icon={icon} />
        <Typography className={classes.linkTitle}>{text}</Typography>
      </StyledExternalLink>
    );
  } else {
    return (
      <StyledLink to={to} className={classes.linkButton}>
        <FontAwesomeIcon icon={icon} />
        <Typography className={classes.linkTitle}>{text}</Typography>
      </StyledLink>
    );
  }
};

const PREFIX = 'TopLevelNav';

const classes = {
  linkTitle: `${PREFIX}-linkTitle`,
  linkButton: `${PREFIX}-linkButton`
};

const LinkStyles = (theme: any) => {
  return {
    [`& .${classes.linkTitle}`]: {
      marginLeft: '0.5rem',
    },
    [`&.${classes.linkButton}`]: {
      display: 'flex',
      padding: `0.375rem 0.5rem`,
      textDecoration: 'none',
      border: 0,
      color: theme.palette.common.white,
      height: 64,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      '&:after': {
        color: theme.palette.secondary.main,
        borderTopColor: theme.palette.secondary.main,
      },
      '&:hover': {
        color: theme.palette.secondary.main,
        backgroundColor: `rgba(255, 255, 255, 0.1)`,
        borderColor: `rgba(255, 255, 255, 0.1)`,
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      },
      '&:focus': {
        color: theme.palette.secondary.main,
        backgroundColor: `rgba(255, 255, 255, 0.1) !important`,
        borderColor: `rgba(255, 255, 255, 0.1) !important`,
        boxShadow: `none !important`,
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      },
    },
  }
};

const StyledExternalLink = styled(ExternalLink)(({ theme }) => {
  return LinkStyles(theme);
});

const StyledLink = styled(Link)(({ theme }) => {
  return LinkStyles(theme);
});
