import { FC } from 'react';
import { Page } from '../../../components';
import { SearchIndexDetails } from './search-index-details';

export const SearchIndexPage: FC = () => {
  return (
    <Page title="Search Index">
      <SearchIndexDetails />
    </Page>
  );
};
