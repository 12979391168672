import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Divider,
  ListItem,
  List,
  Button,
  useMediaQuery,
  styled,
  Theme,
} from '@mui/material';
import { faShop, faRightFromBracket, faUser, faClose } from '@fortawesome/free-solid-svg-icons';
import { Paths, ROLES } from '../../constants';
import { FC, useContext, useState } from 'react';
import { UserAvatar } from '../user-avatar';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { UserMenuItem } from './user-menu-item';
import { theme } from '../../styles';
import { UserContext } from '../../context';
import { unCamelCase } from '../../helpers';

interface IUserMenuProps {
  isSmMobile: boolean;
  isPoolService: boolean;
  v2AncillaryFeatures: boolean;
  v2HelpHeaderLink: boolean;
  isMobile: boolean;
}

export const UserMenu: FC<IUserMenuProps> = ({
  isSmMobile,
  isPoolService,
  v2AncillaryFeatures,
  v2HelpHeaderLink,
  isMobile,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { user, userAvatarUrl, setUser, isSuperAdmin, setAdyenDetails } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const displayUserGroups = () => {
    const roles = user?.userGroups;
    if (roles) {
      return roles.map(role => {
        return (
          <ListItem
            key={`${role.userGroupId}`}
            disablePadding
            sx={{
              paddingInline: theme.spacing(1),
            }}
          >
            {unCamelCase(role.userGroupName)}
          </ListItem>
        );
      });
    }
    return (
      <ListItem
        key="no-groups-found"
        disablePadding
        sx={{
          paddingInline: theme.spacing(1),
          fontStyle: 'italic',
        }}
      >
        No groups found.
      </ListItem>
    );
  };
  return (
    <>
      <StyledUserButton
        id="user-button"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-testid="user-button"
        endIcon={!!anchorEl ? <ExpandLess /> : <ExpandMore />}
      >
        <UserAvatar src={userAvatarUrl} userName={user?.userName} noMarginRight={!isDesktop} />
        {!isSmMobile && (
          <Box
            textAlign="left"
            marginRight="8px"
            mt={user?.officeCode || user?.officeName ? '8px' : 0}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', lineHeight: 0.5 }}>
              {user?.userName}
            </Typography>
            <Typography variant="caption" sx={{ fontSize: '12px' }}>
              {user?.userType === ROLES.Emulating ? 'Emulating ' : ''}
              {user?.officeCode || user?.officeName}
            </Typography>
          </Box>
        )}
      </StyledUserButton>
      <Menu
        id="user-menu"
        data-testid="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-button',
        }}
      >
        <div>
          {isSmMobile && (
            <MenuItem
              disableRipple
              disableTouchRipple
              divider
              sx={{
                backgroundColor: '#fff',
                pointerEvents: 'none',
              }}
            >
              <Box textAlign="left" width="100%">
                <Typography sx={{ fontSize: '14px', marginBottom: '-8px' }}>
                  {user?.userName}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '12px' }}>
                  {user?.userType === ROLES.Emulating ? 'Emulating ' : ''}
                  {user?.officeCode || user?.officeName}
                </Typography>
              </Box>
            </MenuItem>
          )}
          <StyledMenuItemUserIcon
            to="/my-account"
            icon={faUser}
            text="My Account"
            isExternal={false}
            isVisible={!isPoolService && v2AncillaryFeatures}
          />
          {user?.userType === ROLES.Emulating && (
            <>
              <StyledMenuItemUserIcon
                to={Paths.officeManagement.url}
                icon={faShop}
                text="Switch Office"
                isExternal={false}
                isVisible={true}
              />
              <StyledMenuItemUserIcon
                to={Paths.officeManagement.url}
                handleMenuClick={() => {
                  localStorage.removeItem('emulatingData');
                  setUser({
                    ...user,
                    officeId: null,
                    officeCode: null,
                    officeName: null,
                    userType: 'SuperAdmin',
                  });
                  setAdyenDetails?.(undefined);
                }}
                icon={faClose}
                text="Clear Office"
                isExternal={false}
                isVisible={true}
              />
            </>
          )}
          {/* Mobile Pool 360 Specific Links */}
          {!isSuperAdmin && (
            <>
              <UserMenuItem
                to={Paths.news.url}
                icon={Paths.news.icon}
                text={Paths.news.label}
                isExternal={false}
                isVisible={isMobile && isPoolService && v2AncillaryFeatures}
              />
              <UserMenuItem
                to={Paths.feedback.url}
                icon={Paths.feedback.icon}
                text={Paths.feedback.label}
                isExternal={false}
                isVisible={isMobile && isPoolService && v2AncillaryFeatures}
              />
              <UserMenuItem
                to={Paths.help.url}
                icon={Paths.help.icon}
                text={Paths.help.label}
                isExternal={false}
                isVisible={isMobile && isPoolService && v2HelpHeaderLink}
              />
            </>
          )}
          {/* Mobile Pinch Specific Links */}
          <UserMenuItem
            to={v2AncillaryFeatures ? Paths.news.url : Paths.news.legacyUrl!}
            icon={Paths.news.icon}
            text={Paths.news.label}
            isExternal={true}
            isVisible={isMobile && !isPoolService}
          />
          <UserMenuItem
            to={v2AncillaryFeatures ? Paths.feedback.url : Paths.feedback.legacyUrl!}
            icon={Paths.feedback.icon}
            text={Paths.feedback.label}
            isExternal={v2AncillaryFeatures ? false : true}
            isVisible={isMobile && !isPoolService && false}
          />
          <UserMenuItem
            to={v2HelpHeaderLink ? Paths.help.url : Paths.help.legacyUrl!}
            icon={Paths.help.icon}
            text={Paths.help.label}
            isExternal={v2HelpHeaderLink ? false : true}
            isVisible={isMobile && !isPoolService && false}
          />
          <StyledMenuItemUserIconRed
            to="/logout"
            icon={faRightFromBracket}
            text="Logout"
            isExternal={false}
          />
          {user?.userGroups && user?.userGroups.length > 0 && (
            <>
              <Divider
                component={'li'}
                sx={{
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(2),
                }}
              />
              <Box component={'li'} paddingLeft={2} paddingRight={2}>
                <Typography
                  variant="body2"
                  fontWeight={theme.typography.fontWeightBold}
                  color={theme.palette.primary.main}
                >
                  Assigned Groups
                </Typography>
                <List disablePadding sx={{ fontSize: theme.typography.fontSize }}>
                  {displayUserGroups()}
                </List>
              </Box>
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

const StyledUserButton = styled(Button)(({ theme }) => {
  return {
    display: 'flex',
    height: 64,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    alignItems: 'center',
    borderRadius: 0,
    boxShadow: 'none',
    padding: `0.375rem 0.5rem`,
    '&:after': {
      borderTopColor: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
      borderColor: `rgba(255, 255, 255, 0.1)`,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    '&:focus': {
      backgroundColor: `rgba(255, 255, 255, 0.1) !important`,
      borderColor: `rgba(255, 255, 255, 0.1) !important`,
      boxShadow: `none !important`,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
  };
});

const StyledMenuItemUserIcon = styled(UserMenuItem)(({ theme }) => {
  return {
    '& path': {
      fill: theme.palette.primary.main,
    },
  };
});

const StyledMenuItemUserIconRed = styled(UserMenuItem)(({ theme }) => {
  return {
    '& span': {
      color: 'red',
    },
    '& svg': {
      color: 'red',
    },
  };
});
