import { Avatar, styled } from '@mui/material';
import { FC } from 'react';

interface IUserAvatarProps {
  src?: string;
  userName?: string | null;
  noMarginRight?: boolean;
  backgroundColor?: string;
}

export const getInitials = (userName: string | undefined | null) => {
  if (!userName?.trim().length) {
    return '?';
  }
  const nameParts = userName.split(' ').filter(s => !!s.trim());
  const initials = [];
  initials.push(nameParts[0][0]);
  if (nameParts.length > 1) {
    initials.push(nameParts[nameParts.length - 1][0]);
  }
  return initials.join('').toUpperCase();
};

export const UserAvatar: FC<IUserAvatarProps> = ({
  src,
  userName,
  noMarginRight,
  backgroundColor,
  ...props
}) => {
  return (
    <StyledAvatar
      alt={userName || ''}
      src={src || undefined}
      data-testid="UserAvatar"
      noMarginRight={noMarginRight}
      backgroundColor={backgroundColor}
      {...props}
    >
      {getInitials(userName)}
    </StyledAvatar>
  );
};

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'noMarginRight' && prop !== 'backgroundColor',
})<{ noMarginRight?: boolean; backgroundColor?: string }>(
  ({ theme, noMarginRight, backgroundColor }) => ({
    backgroundColor: backgroundColor,
    marginRight: noMarginRight ? 0 : 12,
    fontSize: 14,
    borderColor: theme.palette.secondary.main,
    height: 30,
    width: 30,
    '@media print': {
      display: 'none',
    },
  })
);
