import { FC } from 'react';
import { Stack } from '@mui/material';
import { CommissionTypesTable } from './commission-types-table';
import { CommissionsRulesListDetails } from './commission-rules-list-details';

export const CommissionsSetupPage: FC = () => {
  return (
    <Stack gap={2}>
      <CommissionsRulesListDetails />
      <CommissionTypesTable />
    </Stack>
  );
};
