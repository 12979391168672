import { FC } from 'react';
import { Page } from '../../../components';
import { OfficeManagementTable } from './office-management-table';

export const OfficeManagement: FC = () => {
  return (
    <Page title="Office Management">
      <OfficeManagementTable />
    </Page>
  );
};
