import { FC, useState } from 'react';
import { Box, Fade, Grid, Stack, TextField } from '@mui/material';
import { CardButton, Loader, ModalSaveSection, Modal } from '../../../components';
import { RecurringServicesModal } from './recurring-service-modal';
import { RecurringPaymentModal } from './recurring-payments-modal';
import { faLockOpen, faMailBulk, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { processOutboxMessages, processInboxMessages } from '../../../fetch';
import { useSnackbar } from 'notistack';

export const AzureFunctionsDetails: FC = () => {
  const [isShowingServicesModal, setIsShowingServicesModal] = useState(false);
  const [isShowingPaymentsModal, setIsShowingPaymentsModal] = useState(false);
  const [isShowingOutboxModal, setIsShowingOutboxModal] = useState(false);
  const [isShowingInboxModal, setIsShowingInboxModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [outBoxMessageId, setOutBoxMessageId] = useState('');
  const [inBoxMessageId, setInBoxMessageId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <CardButton
            title="Auto Extend Recurring Services"
            alignCenter
            onClick={() => {
              setIsShowingServicesModal(true);
            }}
            icon={faRefresh}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <CardButton
            title="Process Recurring Payments"
            alignCenter
            onClick={() => {
              setIsShowingPaymentsModal(true);
            }}
            icon={faLockOpen}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <CardButton
            title="Process Outbox Messages"
            alignCenter
            onClick={() => {
              setIsShowingOutboxModal(true);
            }}
            icon={faMailBulk}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <CardButton
            title="Process Inbox Messages"
            alignCenter
            onClick={() => {
              setIsShowingInboxModal(true);
            }}
            icon={faMailBulk}
          />
        </Grid>
      </Grid>
      <RecurringServicesModal
        isOpen={isShowingServicesModal}
        onClose={() => {
          setIsShowingServicesModal(false);
        }}
      />
      <RecurringPaymentModal
        isOpen={isShowingPaymentsModal}
        onClose={() => {
          setIsShowingPaymentsModal(false);
        }}
      />
      <Modal
        open={isShowingOutboxModal || isShowingInboxModal}
        maxWidth="sm"
        title={isShowingOutboxModal ? 'Process Outbox Messages' : 'Process Inbox Messages'}
        onClose={() => {
          setIsShowingInboxModal(false);
          setIsShowingOutboxModal(false);
        }}
      >
        <Fade in={isShowingOutboxModal || isShowingInboxModal}>
          <Box mt={2}>
            {isLoading && <Loader type="overlay" />}
            <Stack spacing={2}>
              <TextField
                size="small"
                label={isShowingOutboxModal ? 'Outbox Message Id' : 'Inbox Message Id'}
                value={isShowingOutboxModal ? outBoxMessageId : inBoxMessageId}
                onChange={e =>
                  isShowingOutboxModal
                    ? setOutBoxMessageId(e.target.value)
                    : setInBoxMessageId(e.target.value)
                }
              />
            </Stack>
            <ModalSaveSection
              handleCancel={() => {
                setIsShowingInboxModal(false);
                setIsShowingOutboxModal(false);
              }}
              isSaveDisabled={!outBoxMessageId && !inBoxMessageId}
              handleSave={async () => {
                setIsLoading(true);
                try {
                  isShowingOutboxModal
                    ? await processOutboxMessages(outBoxMessageId)
                    : await processInboxMessages(inBoxMessageId);
                  setIsShowingInboxModal(false);
                  setIsShowingOutboxModal(false);
                  enqueueSnackbar(`Success!`, {
                    variant: 'success',
                  });
                } catch (error: any) {
                  enqueueSnackbar(error?.Detail ?? `Error processing messages, please try again.`, {
                    variant: 'error',
                  });
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
