import { axios } from '../helpers';
import qs from 'querystring';
import { IDocument, IDocumentPUT, IErrorResponse, IResponse } from '../models';
import { userSignal } from '../signals';

export const getAccountDocuments = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | number;
  accountId?: string | number;
  siteId?: string | number;
}): Promise<IResponse<IDocument[]>> => {
  try {
    const { data } = await axios.get(`/api/account-documents`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAccountDocuments = async (
  id: string | number,
  payload: File | string,
  description?: string,
  officeId?: string | null
): Promise<IErrorResponse> => {
  const formData = new FormData();
  formData.append('AccountId', id.toString());
  formData.append('File', payload ?? '');
  formData.append('Description', description ?? '');
  try {
    const { data } = await axios.post(`/api/account-documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};

export const updateAccountDocument = async (
  payload: IDocumentPUT,
  accountDocumentId: string | number,
  officeId?: string | null
): Promise<IErrorResponse> => {
  try {
    const { data } = await axios.put(`/api/account-documents/${accountDocumentId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};

export const deleteAccountDocument = async (accountDocumentId: string | number): Promise<void> => {
  try {
    const { data } = await axios.delete(`/api/account-documents/${accountDocumentId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createSiteDocuments = async (
  id: string | number,
  payload: File | string,
  description?: string,
  officeId?: string | null
): Promise<IErrorResponse> => {
  const formData = new FormData();
  formData.append('File', payload ?? '');
  formData.append('officeId', officeId ?? userSignal.value?.officeId ?? '');
  try {
    const { data } = await axios.post(
      `/api/sites/${id}/documents?Description=${description ?? null}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};

export const createOfficeDocuments = async (
  id: string | number,
  payload: File | string,
  description?: string,
  officeId?: string | null
): Promise<IErrorResponse> => {
  const formData = new FormData();
  formData.append('File', payload ?? '');
  formData.append('officeId', officeId ?? userSignal.value?.officeId ?? '');
  try {
    const { data } = await axios.post(
      `/api/offices/${id}/documents?Description=${description ?? null}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};
