import { FC } from 'react';
import clsx from 'clsx';
import { useMediaQuery, Grid, styled } from '@mui/material';
import CurvedEdgeDesktop from '../../assets/login/login-curved-edge-lg.png';
import CurvedEdgeMobile from '../../assets/login/login-curved-edge-md.png';
import BackgroundDesktop from '../../assets/login/login-background-lg.jpg';
import BackgroundTablet from '../../assets/login/login-background-md.jpg';
import BackgroundMobile from '../../assets/login/login-background-sm.jpg';

interface IFullPageImageLayoutProps {
  desktopImage?: string;
  tabletImage?: string;
  mobileImage?: string;
  verticalAlign?: 'center' | 'normal';
}

export const FullPageImageLayout: FC<IFullPageImageLayoutProps> = ({
  children,
  desktopImage = BackgroundDesktop,
  tabletImage = BackgroundTablet,
  mobileImage = BackgroundMobile,
  verticalAlign = 'center',
}) => {
  const isTablet = useMediaQuery('(min-width: 568px) and (max-width: 1199px)');
  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const setBackgroundImage = () => {
    if (isDesktop) {
      return desktopImage;
    }
    if (isTablet) {
      return tabletImage;
    }
    return mobileImage;
  };

  const setCurvedEdgeImage = () => {
    if (isDesktop) {
      return CurvedEdgeDesktop;
    }
    return CurvedEdgeMobile;
  };

  return (
    <StyledGrid container className={classes.fullPageImageLayout}>
      <Grid
        item
        xs={12}
        lg={6}
        xl={5}
        className={clsx(
          classes.contentCol,
          verticalAlign === 'center' ? classes.centerAlign : undefined
        )}
      >
        <Grid container className={verticalAlign === 'center' ? classes.centerAlign : undefined}>
          <Grid
            item
            xs={12}
            md={10}
            lg={12}
            className={verticalAlign === 'center' ? classes.centerAlign : undefined}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        xl={7}
        className={clsx('p-0', classes.imageCol)}
        style={{ backgroundImage: `url(${setBackgroundImage()})` }}
      >
        <div className={classes.curvedEdgeContainer}>
          <img src={setCurvedEdgeImage()} alt="" className={clsx(classes.curvedEdge)} />
        </div>
      </Grid>
    </StyledGrid>
  );
};

const PREFIX = 'FullPageImageLayout';

const classes = {
  centerAlign: `${PREFIX}-centerAlign`,
  fullPageImageLayout: `${PREFIX}-fullPageImageLayout`,
  contentCol: `${PREFIX}-contentCol`,
  imageCol: `${PREFIX}-imageCol`,
  curvedEdgeContainer: `${PREFIX}-curvedEdgeContainer`,
  curvedEdge: `${PREFIX}-curvedEdge`
};

const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    [`& .${classes.centerAlign}`]: {
      [theme.breakpoints.up(568)]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    [`&.${classes.fullPageImageLayout}`]: {
      height: '100%',
      '&& .row': {
        width: '100%',
      },
    },
    [`& .${classes.contentCol}`]: {
      display: 'flex',
      padding: theme.spacing(4, 6),
      border: '2px solid #eff2fb',
      backgroundColor: '#eff2fb',
      marginBottom: '-1px',
      zIndex: 2,
      [theme.breakpoints.up(1200)]: {
        padding: theme.spacing(8, 0, 8, 10),
        marginRight: '-1px',
        marginBottom: 0,
      },
      [theme.breakpoints.up(1400)]: {
        padding: theme.spacing(8, 10),
      },
    },
    [`& .${classes.imageCol}`]: {
      zIndex: 1,
      backgroundSize: 'cover',
      backgroundPosition: 'center -40px',
      alignSelf: 'flex-end',
      height: '312px',
      [theme.breakpoints.up(568)]: {
        backgroundPosition: 'center -80px',
      },
      [theme.breakpoints.up(1200)]: {
        backgroundPosition: '80px 70%',
        height: '100%',
      },

      '&:after': {
        content: '""',
        display: 'none',
        height: '550px',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        background: `linear-gradient(to bottom, rgba(32, 40, 49, 0%) 0%, rgba(32, 40, 49, 100%) 90%);`,
        [theme.breakpoints.up(1200)]: {
          display: 'block',
          height: '625px',
        },
      },
    },

    [`& .${classes.curvedEdgeContainer}`]: {
      height: '100%',
      width: 'auto',
      overflow: 'hidden',
      position: 'relative',
      zIndex: 2,
    },
    [`& .${classes.curvedEdge}`]: {
      position: 'absolute',
      maxWidth: 'none',
      width: '175%',
      [theme.breakpoints.up(568)]: {
        width: '120%',
      },
      [theme.breakpoints.up(1200)]: {
        maxWidth: '300px',
        width: '40%',
      },
    },
  };
});