import { Alert, Box, Grid, IconButton, Typography, styled } from '@mui/material';
import { FC, useContext, useMemo, useState } from 'react';
import {
  ICalendarDateRange,
  ICalendarView,
  IListUser,
  IRepair,
  IResponse,
  ITechnician,
} from '../../../../models';
import { endOfDay, startOfDay } from 'date-fns';
import { useQuery } from 'react-query';
import { getRepairVisits } from '../../../../fetch';
import { UserContext } from '../../../../context';
import { makeColor } from '../../../../helpers';
import { TechnicianFilters, isVisitStartOrEnd } from '../../../routes';
import { AzureMap, DateRangeNavigator, FloatingToolbar } from '../../../../components';
import { InfoOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { RepairPod } from './repair-pod';

interface IOTSSchedulerMapTabProps {
  selectedTab?: string;
  techs: IListUser[];
  initialDateRange: ICalendarDateRange | undefined;
  deletedVisits: string[];
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
}

export const OTSSchedulerMapTab: FC<IOTSSchedulerMapTabProps> = ({
  selectedTab = 'scheduler',
  techs,
  initialDateRange,
  deletedVisits,
  isLoading = true,
  setIsLoading,
}) => {
  const { user } = useContext(UserContext);

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const [selectedDateRange, setSelectedDateRange] = useState<ICalendarDateRange | undefined>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [iniitalMapVisits, setInitialMapVisits] = useState<IRepair[]>([]);
  const [selectedMapTechs, setSelectedMapTechs] = useState<ITechnician[]>([]);

  const { isLoading: isLoadingMapVisits } = useQuery<IResponse<IRepair[]>, Error>(
    ['getRepairVisits', selectedDateRange],
    () => {
      setIsLoading(true);
      return getRepairVisits({
        perPage: -1,
        officeId: user?.officeId,
        sortDirection: 'desc',
        includeCancelledRepairs: false,
        serviceStartDate: selectedDateRange?.startDate?.toISOString(),
        serviceEndDate: selectedDateRange?.startDate?.toISOString(),
        includeStartAndEnd: true,
      });
    },
    {
      onSuccess: d => {
        setInitialMapVisits(
          // remove duplicates, so we maintain if the user created any visits and then changed the date range and then came back
          [...d.records].filter(
            (value, index, self) =>
              index === self.findIndex(t => t.repairVisitId === value.repairVisitId)
          )
        );
      },
      onSettled: d => {
        setIsLoading(false);
      },
      enabled: !isLoading && selectedTab === 'map',
    }
  );

  const mapVisits: IRepair[] = useMemo(() => {
    const filterVisits = (visits: IRepair[]) => {
      const filteredAssignedToArray = selectedMapTechs.length
        ? visits.filter(t => {
            return selectedMapTechs.find(st => st.userId === t.userId);
          })
        : visits;
      const filteredDeletedToArray = deletedVisits.length
        ? filteredAssignedToArray.filter(t => {
            return deletedVisits.find(st => st !== t.repairVisitId);
          })
        : filteredAssignedToArray;
      return filteredDeletedToArray;
    };
    const filteredVisits = filterVisits(iniitalMapVisits);
    return filteredVisits ?? [];
  }, [iniitalMapVisits, selectedMapTechs, deletedVisits]);

  const mapTechs: ITechnician[] = useMemo(() => {
    return techs.map((tech, index) => {
      return {
        userId: tech.userId,
        userName: tech.userName,
        isDisabled: tech.isDisabled,
        visits: mapVisits.filter(v => v.userId === tech.userId) ?? [],
        services: [],
        color: makeColor(index, techs.length),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [techs, selectedMapTechs, mapVisits]);

  const duplicateVisits: IRepair[] = useMemo(() => {
    if (mapTechs) {
      let unique: IRepair[] = [];
      return (
        (mapTechs
          .flatMap(tech => tech.visits)
          // remove 0 options
          .filter(visit => !!visit?.siteAddress?.latitude || !!visit?.siteAddress?.longitude)
          // remove start/end options
          .filter(visit => !isVisitStartOrEnd(visit!))
          .filter(o => {
            if (
              unique.find(
                i =>
                  Number(i?.siteAddress?.longitude) === Number(o?.siteAddress?.longitude) &&
                  Number(i?.siteAddress?.latitude === o?.siteAddress?.latitude)
              )
            ) {
              return true;
            }

            unique.push(o!);
            return false;
          }) ?? []) as IRepair[]
      );
    }
    return [];
  }, [mapTechs]);
  const hasValidVisits = useMemo(() => {
    return (
      mapVisits.length > 0 &&
      mapVisits.map(visit => visit?.siteAddress).filter(Boolean).length > 0 &&
      mapVisits.filter(visit => !!visit?.siteAddress?.latitude || !!visit?.siteAddress?.longitude)
        .length > 0
    );
  }, [mapVisits]);
  const hasVisits = useMemo(() => {
    return mapVisits.length > 0 && mapTechs.length > 0;
  }, [mapVisits, mapTechs]);
  return (
    <Wrapper>
      <Grid container spacing={2} mt={2} className={classes.printContainer}>
        <Grid item xs={12} lg={4} xl={3} className={classes.container}>
          <Box width={{ xs: '100%', sm: 'auto' }} mb={2}>
            <DateRangeNavigator
              view={ICalendarView.Day}
              dateRange={selectedDateRange!}
              onChange={date => {
                // reset selected techs on day change since the techs are not the same
                setSelectedMapTechs([]);
                setSelectedDateRange({
                  startDate: startOfDay(date.startDate),
                  endDate: endOfDay(date.startDate),
                });
              }}
              hasBorder
            />
          </Box>
          <Box mb={2}>
            <Typography color="primary" fontWeight="bold">
              Filter by Technician
            </Typography>
            <TechnicianFilters
              technicians={mapTechs}
              selectedTechs={selectedMapTechs}
              setSelectedTechs={setSelectedMapTechs}
              isLoading={false}
              inputSize="small"
              disabled={isLoadingMapVisits}
            />
          </Box>
          {!isLoading && hasVisits && hasValidVisits && (
            <Box className={classes.servicesWrapper}>
              <RepairPod
                showIndex
                colorizeIndex
                techs={mapTechs}
                visitDate={selectedDateRange?.startDate!}
              />
            </Box>
          )}
          {!isLoading && (!hasVisits || !hasValidVisits) && (
            <Alert severity={!hasVisits ? 'info' : 'error'}>
              {!hasVisits
                ? 'There are no scheduled visits for the selected date and/or technician(s).'
                : 'Selected tech has invalid site address(es).'}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          {!isLoading && hasVisits && hasValidVisits && (
            <AzureMap techs={mapTechs} isRoutes={false} />
          )}
          {duplicateVisits?.length > 0 && isOpen && (
            <FloatingToolbar>
              <Alert
                color="info"
                icon={<InfoOutlined />}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </IconButton>
                }
              >
                There are multiple stops at the same address. On the map, this is depicted using a +
                next to the stop number on the map pin (i.e. 3+)
              </Alert>
            </FloatingToolbar>
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const PREFIX = 'OTSSchedulerMapTab';

const classes = {
  container: `${PREFIX}-container`,
  servicesWrapper: `${PREFIX}-servicesWrapper`,
  printContainer: `${PREFIX}-printContainer`,
};

const Wrapper = styled(Box)(({ theme }) => ({
  [`& .${classes.container}`]: {
    padding: 0,

    '@media print': {
      'page-break-after': 'always',
    },
  },

  [`& .${classes.servicesWrapper}`]: {
    [theme.breakpoints.up(992)]: {
      overflowY: 'auto',
      maxHeight: '31rem',
    },
  },

  [`& .${classes.printContainer}`]: {
    '@media print': {
      display: 'block',
      '& > div': 'block',
    },
  },
}));
