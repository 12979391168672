import { Button, Menu, MenuItem, Typography, Box, useMediaQuery, styled } from '@mui/material';
import { FC, ReactNode, useState, useMemo } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarWeek,
  faChevronDown,
  faChevronUp,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { Loader, ILoaderExtended } from '../loader';
import { ICalendarView, ICalendarViewOption } from '../../models';
import { DEFAULT_VIEW_OPTIONS } from './config';
import CheckIcon from '@mui/icons-material/Check';

export interface CalendarShellProps {
  isSaving?: boolean;
  isLoading?: boolean;
  viewOptions?: ICalendarViewOption[];
  view?: ICalendarView;
  onViewChange: (viewOptionValue: ICalendarView) => unknown;
  children: ReactNode;
  navigationControl?: ReactNode;
  footerControl?: ReactNode;
  loaderProps?: ILoaderExtended;
  onSelectedTimeStep?: (val: number) => unknown;
  selectedTimeStep: number;
  showStepPicker?: boolean;
}

export const CalendarShell: FC<CalendarShellProps> = ({
  isSaving,
  isLoading,
  children,
  view,
  viewOptions = DEFAULT_VIEW_OPTIONS,
  onViewChange,
  navigationControl,
  footerControl,
  loaderProps = {
    type: 'fullscreen',
    position: 'centered',
    // position on top of scheduler component
    zIndex: 11,
  },
  onSelectedTimeStep,
  selectedTimeStep,
  showStepPicker = true,
}) => {

  const isSmMobile = useMediaQuery(`(max-width: 800px)`);
  const [anchorEl, setAnchorEl] = useState<{ [key: string]: null | HTMLElement }>({});

  const isTimeOpen = Boolean(anchorEl['time']);
  const isWorkOpen = Boolean(anchorEl['work']);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
    setAnchorEl({ [key]: event.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl({});
  };

  const handleChange = (option: ICalendarViewOption, key: string) => {
    setAnchorEl({});
    if (key === 'time') {
      onSelectedTimeStep?.(Number(option.value));
    } else {
      onViewChange?.(option.value);
    }
  };

  const selectedOption = useMemo(
    () => viewOptions?.find(o => o.value === view),
    [view, viewOptions]
  );

  return (
    <StyledCalendar className={clsx(classes.calendarShell)}>
      {isLoading && <Loader {...loaderProps} title="Loading..." />}
      {isSaving && <Loader {...loaderProps} title="Saving..." />}
      <div className={classes.calendarHeader}>
        {navigationControl}
        <div className="print--none">
          <Box display="flex" gap={2}>
            {showStepPicker && (
              <>
                <Button
                  onClick={e => handleClick(e, 'time')}
                  className={clsx('menuButton', classes.headerButton)}
                  startIcon={<FontAwesomeIcon icon={faClock} className={classes.barIcon} />}
                  endIcon={
                    <FontAwesomeIcon
                      icon={isTimeOpen ? faChevronUp : faChevronDown}
                      size="lg"
                      className={classes.barIcon}
                    />
                  }
                >
                  <Typography className={classes.barLabel}>
                    {selectedTimeStep === 60 ? `1 hour` : `${selectedTimeStep} min`}
                  </Typography>
                </Button>
                <Menu anchorEl={anchorEl?.['time']} open={isTimeOpen} onClose={handleClose}>
                  {[
                    { label: '15 minutes', value: 15 },
                    { label: '30 minutes', value: 30 },
                    { label: '1 hour', value: 60 },
                  ].map(o => (
                    <MenuItem
                      key={o.value}
                      value={o.value}
                      onClick={() => {
                        handleChange(o as any, 'time');
                      }}
                    >
                      {o.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
            <Button
              onClick={e => handleClick(e, 'work')}
              className={clsx('menuButton', classes.headerButton)}
              startIcon={<FontAwesomeIcon icon={faCalendarWeek} className={classes.barIcon} />}
              disableRipple
              endIcon={
                <FontAwesomeIcon
                  icon={isWorkOpen ? faChevronUp : faChevronDown}
                  className={classes.barIcon}
                />
              }
            >
              <Typography className={classes.barLabel}>{selectedOption?.label}</Typography>
            </Button>
            <Menu anchorEl={anchorEl?.['work']} open={isWorkOpen} onClose={handleClose}>
              {viewOptions
                // only get the "Day" option on mobile
                ?.filter(option => (isSmMobile ? option.value === view : true))
                .map(o => (
                  <MenuItem
                    key={o.value}
                    value={o.value}
                    selected={o.value === view}
                    onClick={() => handleChange(o, 'work')}
                  >
                    <StyledMenuItem className={classes.viewMenuItem}>
                      <div className={classes.viewMenuItemText}>{o.label}</div>
                      {o.value === view && (
                        <div className={classes.viewMenuItemIcon}>
                          <CheckIcon />
                        </div>
                      )}
                    </StyledMenuItem>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        </div>
      </div>
      {children}
      {footerControl}
    </StyledCalendar>
  );
};

const PREFIX = 'CalendarShell';

const classes = {
  calendarShell: `${PREFIX}-calendarShell`,
  calendarHeader: `${PREFIX}-calendarHeader`,
  barLabel: `${PREFIX}-barLabel`,
  barIcon: `${PREFIX}-barIcon`,
  viewMenuItem: `${PREFIX}-viewMenuItem`,
  viewMenuItemText: `${PREFIX}-viewMenuItemText`,
  viewMenuItemIcon: `${PREFIX}-viewMenuItemIcon`,
  headerButton: `${PREFIX}-headerButton`
};

const StyledCalendar = styled('div')(({ theme }) => ({
  [`&.${classes.calendarShell}`]: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 5,
    backgroundColor: 'white',
    '@media print': {
      display: 'table',
      border: 'none',
      width: '100%',
    },
    '.print-calendar--stack &&': {
      '@media print': {
        display: 'block',
      },
    },
  },

  [`& .${classes.calendarHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
    padding: 8,
    '@media print': {
      backgroundColor: 'transparent',
      padding: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: 'table-caption',
      width: '100%',
    },
    '&&.print-calendar--stack': {
      '@media print': {
        display: 'block',
      },
    },
  },

  [`& .${classes.barLabel}`]: {
    fontSize: 14,
  },

  [`& .${classes.barIcon}`]: {
    fontSize: '1rem',
  },

  [`& .${classes.viewMenuItem}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },

  [`& .${classes.viewMenuItemText}`]: {
    flex: '1 1 auto',
  },

  [`& .${classes.viewMenuItemIcon}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.headerButton}`]: {
    color: 'white',
  }
}));

const StyledMenuItem = styled('div')(({ theme }) => ({
  [`&.${classes.viewMenuItem}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },

  [`& .${classes.viewMenuItemText}`]: {
    flex: '1 1 auto',
  },

  [`& .${classes.viewMenuItemIcon}`]: {
    color: theme.palette.primary.main,
  },

}));