import { FC, useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection, Select } from '../../../components';
import { Box, Fade, Typography, Grid, Divider, MenuItem } from '@mui/material';

// fetch
import { updatePaymentSetup, createPaymentSetup, getSinglePaymentSetup } from '../../../fetch';
import {
  IPaymentSetup,
  IPaymentSetupCreate,
  IPaymentSetupUpdate,
  PaymentProcessor,
} from '../../../models';
import { getEnvironment } from '../../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IAddEditPaymentSetupModal {
  open: boolean;
  onClose: () => void;
  currentPaymentSetupId?: string | null;
  fetchPaymentSetups: () => void;
}

const Schema = Yup.object().shape({
  systemSettingId: Yup.string(),
  paymentProcessor: Yup.mixed().oneOf(Object.values(PaymentProcessor)).required('Required'),
  authNetConfig: Yup.lazy(value =>
    value
      ? Yup.object().shape({
          apiLoginId: Yup.string().required('Required'),
          transactionKey: Yup.string().required('Required'),
          testMode: Yup.boolean(),
        })
      : Yup.mixed().nullable(true)
  ),
  adyenConfig: Yup.lazy(value =>
    value
      ? Yup.object().shape({
          apiKey: Yup.string().required('Required'),
          hmacKey: Yup.string().required('Required'),
          clientKey: Yup.string().required('Required'),
        })
      : Yup.mixed().nullable(true)
  ),
});

export const AddEditPaymentSetupModal: FC<IAddEditPaymentSetupModal> = ({
  open,
  onClose,
  currentPaymentSetupId,
  fetchPaymentSetups,
}) => {
  const { user, updatePaymentProcessorData } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const isProd = getEnvironment() === 'prod';
  const isCreate = currentPaymentSetupId === null;
  const { adyen } = useFlags();
  const [currentPaymentSetup, setCurrentPaymentSetup] = useState<IPaymentSetup | null>(null);

  const filterAdyen = ([key, value]: [string, PaymentProcessor]) => {
    // If the Adyen feature flag is not enabled, return false
    if (value === PaymentProcessor.Adyen && !adyen) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getSinglePaymentSetup(currentPaymentSetupId!);
      setCurrentPaymentSetup(data);
    }

    if (currentPaymentSetupId) {
      fetchData();
    }
  }, [currentPaymentSetupId]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          systemSettingId: currentPaymentSetup?.systemSettingId ?? '',
          officeId: user?.officeId ?? '',
          paymentProcessor: currentPaymentSetup?.paymentProcessor ?? PaymentProcessor.AuthorizeNet,
          authNetConfig: currentPaymentSetup?.authNetConfig,
          adyenConfig: currentPaymentSetup?.adyenConfig,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          try {
            isCreate
              ? await createPaymentSetup({
                  officeId: values.officeId,
                  paymentProcessor: values.paymentProcessor,
                  authNetConfig:
                    values.paymentProcessor !== PaymentProcessor.AuthorizeNet
                      ? undefined
                      : {
                          ...values.authNetConfig,
                          testMode: !isProd,
                          acceptBankAccountSales: true,
                        },
                  adyenConfig:
                    values.paymentProcessor !== PaymentProcessor.Adyen
                      ? undefined
                      : values.adyenConfig,
                } as IPaymentSetupCreate)
              : await updatePaymentSetup(values.systemSettingId, {
                  systemSettingId: values.systemSettingId,
                  paymentProcessor: values.paymentProcessor,
                  authNetConfig:
                    values.paymentProcessor !== PaymentProcessor.AuthorizeNet
                      ? undefined
                      : values.authNetConfig,
                  adyenConfig:
                    values.paymentProcessor !== PaymentProcessor.Adyen
                      ? undefined
                      : values.adyenConfig,
                } as IPaymentSetupUpdate);
            enqueueSnackbar(
              `Successfully ${currentPaymentSetup ? 'updated' : 'created'} payment setup!`,
              {
                variant: 'success',
              }
            );
            onClose();
            fetchPaymentSetups();
            updatePaymentProcessorData(); // Refresh user context after updating payment processor info
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving payment setup, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          handleChange,
          handleSubmit,
          dirty,
          isValid,
          values,
          setFieldValue,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {!isCreate ? 'Edit Payment Setup' : 'Add Payment Setup'}
                    </Typography>
                    <Divider />
                  </Box>

                  <Box>
                    <Box paddingBottom={'15px'}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Select
                            fullWidth
                            required
                            size="small"
                            autoComplete="nope"
                            label="Payment Processor"
                            name="paymentProcessor"
                            onChange={(e: any) => {
                              handleChange(e);
                              if (isCreate) {
                                setFieldValue('adyenConfig', null);
                                setFieldValue('authNetConfig', null);
                              }
                            }}
                          >
                            {Object.entries(PaymentProcessor)
                              .filter(processor => processor[0] !== PaymentProcessor.Invalid)
                              .filter(filterAdyen)
                              .map(([key, value]) => (
                                <MenuItem key={key} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Box>

                    {values?.paymentProcessor === PaymentProcessor.Adyen && (
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField name="adyenConfig.apiKey" label="API Key" required />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField name="adyenConfig.hmacKey" label="HMAC Key" required />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField name="adyenConfig.clientKey" label="Client Key" required />
                          </Grid>
                        </Grid>
                      </Box>
                    )}

                    {values?.paymentProcessor === PaymentProcessor.AuthorizeNet && (
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="authNetConfig.apiLoginId"
                              label="API Login ID"
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="authNetConfig.transactionKey"
                              label="Transaction Key"
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}

                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
