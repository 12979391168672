import { TimePicker as MuiTimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { FC, useContext } from 'react';
import { UserContext } from '../../context';

interface ITimePickerProps extends TimePickerProps<any> {
  name?: string;
  error?: boolean;
  textFieldClass?: string;
  textFieldHelperText?: string;
}

export const TimePicker: FC<ITimePickerProps> = ({
  error,
  textFieldClass,
  textFieldHelperText,
  ...props
}) => {
  const { timezone } = useContext(UserContext);
  return (
    <MuiTimePicker
      {...props}
      timezone={timezone}
      slotProps={{
        textField: {
          error,
          size: 'small',
          fullWidth: true,
          classes: { root: textFieldClass },
          helperText: textFieldHelperText ?? '',
        },
      }}
      viewRenderers={{
        hours: null,
        minutes: null,
        seconds: null,
      }}
    />
  );
};
