import { FC, useState } from 'react';
import { Grid, Box, Fade, Stack } from '@mui/material';
import { refreshOtsStatus, processServiceTypesFromEstimate, getOffices } from '../../../fetch';
import { faBarChart, faMailForward, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { CardButton, Loader, Modal, ModalSaveSection, SelectAsyncInput } from '../../../components';
import { IOffice, IResponse } from '../../../models';

export const OtsDetails: FC = () => {
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false);
  const [isProcessModalOpen, setIsProcessModalOpen] = useState(false);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CardButton
            title="Refresh Status"
            alignCenter
            onClick={() => {
              setIsRefreshModalOpen(true);
            }}
            icon={faRefresh}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardButton
            title="Process Service Types from Estimates"
            alignCenter
            onClick={() => {
              setIsProcessModalOpen(true);
            }}
            icon={faBarChart}
          />
        </Grid>
      </Grid>
      <OtsModal
        isOpen={isRefreshModalOpen || isProcessModalOpen}
        onClose={() => {
          setIsRefreshModalOpen(false);
          setIsProcessModalOpen(false);
        }}
        type={isProcessModalOpen ? 'process' : 'refresh'}
      />
    </>
  );
};

const OtsModal = ({
  isOpen,
  onClose,
  type,
}: {
  isOpen: boolean;
  onClose: () => void;
  type: string;
}) => {
  const [isSending, setIsSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOffices, setSelectedOffices] = useState<string[]>([]);

  return (
    <Modal
      open={isOpen}
      maxWidth="sm"
      title={`${
        type === 'refresh'
          ? 'Refresh OTS Process for Office(s)'
          : 'Process Service Types from Estimates'
      }`}
      onClose={() => {
        setSelectedOffices([]);
        onClose();
      }}
    >
      <Fade in={isOpen}>
        <Box mt={2}>
          {isSending && <Loader type="overlay" />}
          <Stack spacing={2}>
            <SelectAsyncInput
              label="Office"
              required
              name="office"
              multiple
              value={selectedOffices}
              handleChange={value => {
                setSelectedOffices(value);
              }}
              apiRequest={() => {
                return getOffices({
                  perPage: -1,
                  sortBy: 'officeName',
                });
              }}
              hasClear={selectedOffices.length > 0}
              onClear={() => setSelectedOffices([])}
              transformResponse={(res: IResponse<IOffice[]>) => {
                return res.records.map((office: IOffice) => ({
                  label: office.officeName,
                  value: office.officeId,
                }));
              }}
            />
          </Stack>
          <ModalSaveSection
            handleCancel={() => {
              setSelectedOffices([]);
              onClose();
            }}
            submitIcon={faMailForward}
            submitLabel="Send"
            handleSave={async () => {
              setIsSending(true);
              try {
                await (type === 'refresh'
                  ? refreshOtsStatus(selectedOffices)
                  : processServiceTypesFromEstimate(selectedOffices));
                onClose();
                setSelectedOffices([]);
                enqueueSnackbar(`Success!`, {
                  variant: 'success',
                });
              } catch (error: any) {
                enqueueSnackbar(error?.Detail ?? `Error, please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsSending(false);
              }
            }}
          />
        </Box>
      </Fade>
    </Modal>
  );
};
