import { Alert, Box, Grid, Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';
import { CreditCardInputs } from '../../../components';

export const StyleGuideCreditCardInputs = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Credit Card Inputs">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Credit Card Inputs is an organism component built upon the{' '}
            <Link href="https://mui.com/material-ui/react-text-field/#select" target="_blank">
              MUI Text Field
            </Link>{' '}
            components, the{' '}
            <Link href="https://medipass.github.io/react-payment-inputs/" target="_blank">
              react-payment-inputs
            </Link>{' '}
            library, and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has built-in styles, credit card input validation and dynamic icons, and Formik
            props required for use with a Formik form and form validation.
          </Typography>
          <Typography mb={1}>
            This component is designed to be used either as a stacked form or inline with other
            inputs. Ensure that the responsive layout lines up with intentions when implementing in
            forms.
          </Typography>
          <Box mb={1}>
            <Alert severity="info">
              NOTE: It is important to keep form field names the same as those used in this
              component not only for consistency but also to properly wire-up this component to the
              form.
            </Alert>
          </Box>
          <Grid container spacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={'auto'}>
              <code>
                Props:
                <br />- numberValue: string
                <br />- cvvValue: string
                <br />- handleCreditCardNumberChange: (val: string) =&gt; void
                <br />- handleCvvChange: (val: string) =&gt; void
                <br />- resetOnCreditCardNumberChange?: boolean
                <br />- handleBlur: any
                <br />- isInline?: boolean
                <br />- required?: boolean
              </code>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <code>
                Field Names:
                <br />- creditCardNumber
                <br />- expirationMonth
                <br />- expirationYear
                <br />- cvv
              </code>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          cardNumber: '',
          expirationMonth: '',
          expirationYear: '',
          cvv: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ handleBlur, values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box mb={1} mt={2}>
                    <CreditCardInputs
                      numberValue={values.cardNumber}
                      cvvValue={values.cvv}
                      required
                      handleCreditCardNumberChange={val => setFieldValue('cardNumber', val)}
                      handleCvvChange={val => setFieldValue('cvv', val)}
                      handleBlur={handleBlur}
                      isInline
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;CreditCardInputs
                    <br />
                    &nbsp;&nbsp;&nbsp;numberValue=&#123;values.cardNumber&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;cvvValue=&#123;values.cvv&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;required
                    <br />
                    &nbsp;&nbsp;&nbsp;handleCreditCardNumberChange=&#123;val =&gt;
                    setFieldValue('cardNumber', val)&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;handleCvvChange=&#123;val =&gt; setFieldValue('cvv',
                    val)&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;handleBlur=&#123;handleBlur&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;isInline
                    <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
                <Grid item xs={12}>
                  <Box mb={1} mt={2}>
                    <Stack gap={2}>
                      <CreditCardInputs
                        numberValue={values.cardNumber}
                        cvvValue={values.cvv}
                        required
                        handleCreditCardNumberChange={val => setFieldValue('cardNumber', val)}
                        handleCvvChange={val => setFieldValue('cvv', val)}
                        handleBlur={handleBlur}
                      />
                    </Stack>
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;Stack gap={2}&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&lt;CreditCardInputs
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;numberValue=&#123;values.cardNumber&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cvvValue=&#123;values.cvv&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;required
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;handleCreditCardNumberChange=&#123;val =&gt;
                    setFieldValue('cardNumber', val)&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;handleCvvChange=&#123;val =&gt;
                    setFieldValue('cvv', val)&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;handleBlur=&#123;handleBlur&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;/&gt;
                    <br />
                    &lt;/Stack gap={2}&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
