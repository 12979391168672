import { FC, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AccountDetailsTab } from './AccountDetailsTab';
import { SitesTab } from './SitesTab';
import { Tasks } from '../../scheduling/tasks';
import { ConfirmPrompt, Documents, Tabs, ITab } from '../../../components';
import { IAccountDetail } from '../../../models';
import { useConfirm } from '../../../hooks';
import { RatesTab } from './RatesTab';
import { TransactionsTab } from './transactions-tab';
import {
  createAccountDocuments,
  getAccountDocuments,
  deleteAccountDocument,
  updateAccountDocument,
  getLeads,
} from '../../../fetch';
import { CustomerStatementTab } from './CustomerStatementTab';
import { EstimatesTab } from './estimates-tab';
import { UserContext } from '../../../context';
import { Permissions, defaultUnsavedChangesMessage } from '../../../constants';
import { hasCorrectUserPermissions } from '../../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

interface ICustomersDetailDisplay {
  accountId: string;
  currentCustomer: IAccountDetail | null;
  isLoadingCustomer: boolean;
}

export const CustomersDetailDisplay: FC<ICustomersDetailDisplay> = ({
  accountId,
  currentCustomer,
  isLoadingCustomer,
}) => {
  const { user } = useContext(UserContext);
  const urlParams = new URLSearchParams(window.location.search);
  const [isTabDirty, setIsTabDirty] = useState(false);
  const [hasSalesLeads, setHasSalesLeads] = useState(false);
  const confirm = useConfirm();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { v2Ots } = useFlags();
  const splitPathLast = history.location.pathname.split('/').pop();
  // If the last part of the path is a query string, remove it and only use the path
  const selectedTab = !splitPathLast?.includes('?')
    ? splitPathLast
    : splitPathLast?.includes('?')
    ? splitPathLast.split('?')?.[0]
    : 'details';
  // If there is a redirect query string, pass it to each tab on route change
  const activeParams = urlParams.get('redirect')
    ? `?redirect=${encodeURIComponent(urlParams.get('redirect')!)}`
    : '';

  useEffect(() => {
    // Check to see if there are any leads before displaying the Sales tab
    const fetchCustomerLeads = async () => {
      try {
        const res = await getLeads({
          sortBy: 'whenCreated',
          sortDirection: 'Desc',
          perPage: 1,
          accountId,
          officeId: user?.officeId,
        });
        if (res.records?.length !== 0) {
          setHasSalesLeads(true);
        } else {
          setHasSalesLeads(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    // Only perform check, if permissions are OFF
    if (!hasCorrectUserPermissions(Permissions.ViewEstimates, user!)) {
      fetchCustomerLeads();
    }
  }, [accountId, user]);

  if (currentCustomer?.accountId && !isLoadingCustomer) {
    return (
      <>
        <ConfirmPrompt when={isTabDirty} message={defaultUnsavedChangesMessage} />
        <Switch>
          <Redirect
            exact
            from={'/customers/:paramsAccountId'}
            to={`/customers/:paramsAccountId/details`}
          />
          <Tabs
            id="customer-details"
            isSticky
            color="secondary"
            size="lg"
            backgroundColor="grey"
            selectedTab={selectedTab ?? 'details'}
            setSelectedTab={async val => {
              if (isTabDirty && val !== selectedTab) {
                const result = await confirm(defaultUnsavedChangesMessage);
                if (result) {
                  setIsTabDirty(false);
                  history.push(`/customers/${accountId}/${val}${activeParams}`);
                }
                setIsTabDirty(false);
                return;
              }
              history.push(`/customers/${accountId}/${val}${activeParams}`);
            }}
            tabs={
              [
                {
                  key: 'details',
                  title: 'Details',
                  children: (
                    <Route path={`${path}/details`}>
                      <AccountDetailsTab currentCustomer={currentCustomer} isEditable />
                    </Route>
                  ),
                },
                {
                  key: 'sites',
                  title: 'Sites',
                  children: (
                    <Route path={`${path}/sites`}>
                      <SitesTab
                        accountId={accountId}
                        currentCustomer={currentCustomer}
                        setIsTabDirty={setIsTabDirty}
                      />
                    </Route>
                  ),
                },
                {
                  key: 'rates',
                  title: 'Rates',
                  children: (
                    <Route path={`${path}/rates`}>
                      <RatesTab accountId={accountId} setIsTabDirty={setIsTabDirty} isEditable />
                    </Route>
                  ),
                },
                hasCorrectUserPermissions(Permissions.ViewTransactions, user!) && {
                  key: 'transactions',
                  title: 'Transactions',
                  children: (
                    <Route path={`${path}/transactions`}>
                      <TransactionsTab
                        accountId={accountId}
                        currentCustomer={currentCustomer}
                        isEditable
                      />
                    </Route>
                  ),
                },
                {
                  key: 'statement',
                  title: 'Statement',
                  children: (
                    <Route path={`${path}/statement`}>
                      <CustomerStatementTab
                        accountCode={currentCustomer.accountCode || ''}
                        accountId={accountId}
                        accountEmails={currentCustomer.emails}
                      />
                    </Route>
                  ),
                },
                {
                  key: 'tasks',
                  title: 'Scheduled Tasks',
                  children: (
                    <Route path={`${path}/tasks`}>
                      <Box marginTop="1rem">
                        <Tasks
                          cardTitle="Scheduled Tasks"
                          accountId={accountId}
                          currentCustomer={currentCustomer}
                          showFilters
                          redirect={`/customers/${currentCustomer?.accountId}/tasks`}
                          hasQueryParamFiltering={false}
                          excludeFilters={['Customer']}
                          isEditable
                        />
                      </Box>
                    </Route>
                  ),
                },
                v2Ots &&
                  (hasCorrectUserPermissions(Permissions.ViewEstimates, user!) ||
                    hasSalesLeads) && {
                    key: 'estimates',
                    title: 'Estimates',
                    children: (
                      <Route path={`${path}/estimates`}>
                        <EstimatesTab
                          accountId={accountId}
                          currentCustomer={currentCustomer}
                          isEditable
                        />
                      </Route>
                    ),
                  },
                {
                  key: 'documents',
                  title: 'Documents',
                  children: (
                    <Route path={`${path}/documents`}>
                      <Documents
                        gridKeyName="customer-documents-grid"
                        labelContext="Customer"
                        getApiRequest={getAccountDocuments}
                        deleteApiRequest={deleteAccountDocument}
                        postApiRequest={createAccountDocuments}
                        putApiRequest={updateAccountDocument}
                        getFilters={{ accountId: accountId }}
                        postFilterId={accountId}
                        cardTitle=""
                        marginTop={2}
                        isEditable
                        disableDelete={item => {
                          return !!item.repairId ? true : false; // If repairId, disable delete button
                        }}
                      />
                    </Route>
                  ),
                },
              ].filter(Boolean) as ITab[]
            }
          />
        </Switch>
      </>
    );
  }
  return null;
};
