import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, ITranCode, ITranCodesQBAccountUpdate } from '../models';
import { userSignal } from '../signals';

export const getAllTranCodes = async ({
  ...otherParams
}: {
  officeId?: string | null;
  tranCodeType?: string | null;
  sortBy?: string;
  thenSortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IResponse<ITranCode[]>> => {
  try {
    const { data } = await axios.get(`api/tran-codes`, {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleTranCode = async (
  id: string | number,
  officeId?: string | null
): Promise<ITranCode> => {
  try {
    const { data } = await axios.get(`/api/tran-codes/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createTranCode = async (
  body: ITranCode,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/tran-codes`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateTranCode = async (
  body: ITranCode,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/tran-codes/${body.tranCodeId}`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteTranCode = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/tran-codes/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateTranCodeQBAccounts = async (
  body: ITranCodesQBAccountUpdate
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/tran-codes/quick-books-accounts`, {
      ...body,
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
