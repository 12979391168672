import { axios } from '../helpers';
import { IServicePhoto } from '../models/photos';
import { userSignal } from '../signals';
import qs from 'querystring';

export const getServicePhotos = async (
  id: string | number,
  officeId?: string | null
): Promise<IServicePhoto[]> => {
  try {
    const { data } = await axios.get(`/api/scheduled-services/${id}/service-photos`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createServicePhotos = async (
  id: string | number,
  payload: File,
  meta?: IServicePhoto,
  officeId?: string | null
): Promise<void> => {
  const formData = new FormData();
  formData.append('File', payload ?? '');
  formData.append('officeId', officeId ?? userSignal.value?.officeId ?? '');
  try {
    const { data } = await axios.post(
      `/api/scheduled-services/${id}/service-photos?Title=${meta?.title ?? null}&Description=${
        meta?.displayName ?? null
      }`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteServicePhoto = async (
  scheduledServiceId: string | number,
  servicePhotoId: string
): Promise<void> => {
  try {
    const { data } = await axios.delete(
      `/api/scheduled-services/${scheduledServiceId}/service-photos/${servicePhotoId}`
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
