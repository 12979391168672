import clsx from 'clsx';
import { FC, useContext } from 'react';
import { BrandingContext } from '../../context/branding-context';
import { styled } from '@mui/material';

interface ILogo {
  className?: string;
}

export const Logo: FC<ILogo> = ({ className }) => {
  const { appLongName, fullLogo, isPoolService } = useContext(BrandingContext);

  return (
    <StyledImage
      src={fullLogo} alt={`${appLongName} Logo`}
      className={clsx(classes.appLogo, className)}
      isPoolService={isPoolService}
    />
  );
};

const PREFIX = 'Logo';

const classes = {
  appLogo: `${PREFIX}-appLogo`
};

const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isPoolService'
})<{ isPoolService: boolean; }>(({ isPoolService }) => ({
  [`&.${classes.appLogo}`]: {
    margin: 'auto 0',
    width: isPoolService ? 160 : 170,
    marginTop: isPoolService ? 3 : 0,
  }
}));