import { FC, useCallback, useState } from 'react';
import {
  Card,
  CardFiltersLayout,
  CardTitle,
  FilterButtons,
  GridDataFetcher,
  SelectAsyncInput,
  useDataGrid,
} from '../../../components';
import { getOutboxMessages, getOutboxMessagesStatuses } from '../../../fetch';
import { IDateRange, ILookupModel, IOutboxMessage } from '../../../models';
import { useSnackbar } from 'notistack';
import { MessagesDataGrid } from './messages-data-grid';
import { Box, Button, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { checkForEnterKey } from '../../../helpers';
import { DatePicker } from '@mui/x-date-pickers';

const initialDateRange: IDateRange = {
  startDate: null,
  endDate: null,
  key: 'selection',
  inputValue: '',
};

export const OutboxQueueDetails: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [appliedSelectedStatus, setAppliedSelectedStatus] = useState<string>('');
  const [selectedOccurredDateRange, setSelectedOccurredDateRange] =
    useState<IDateRange>(initialDateRange);
  const [appliedOccurredDateRange, setAppliedOccurredDateRange] =
    useState<IDateRange>(initialDateRange);
  const [selectedProcessedDateRange, setSelectedProcessedDateRange] =
    useState<IDateRange>(initialDateRange);
  const [appliedProcessedDateRange, setAppliedProcessedDateRange] =
    useState<IDateRange>(initialDateRange);

  const dataFetcher: GridDataFetcher<IOutboxMessage> = useCallback(
    async ({
      perPage,
      sortColumn,
      sortDirection,
      beforeItemId,
      afterItemId,
      isFirstPage,
      isLastPage,
    }) => {
      const params = {
        sortDirection: sortDirection || 'Desc',
        sortBy: sortColumn,
        perPage,
      };
      if (beforeItemId) {
        //@ts-ignore
        params.before = beforeItemId;
      }
      if (afterItemId) {
        // @ts-ignore
        params.after = afterItemId;
      }
      try {
        const res = await getOutboxMessages({
          ...params,
          first: isFirstPage ?? false,
          last: isLastPage ?? false,
          statuses: appliedSelectedStatus ? [appliedSelectedStatus] : undefined,
          occurredOnStartDate: appliedOccurredDateRange?.startDate?.toISOString(),
          occurredOnEndDate: appliedOccurredDateRange?.endDate?.toISOString(),
          processedOnStartDate: appliedProcessedDateRange?.startDate?.toISOString(),
          processedOnEndDate: appliedProcessedDateRange?.endDate?.toISOString(),
        });
        return {
          rows: res.data,
          rowCount: res.totalCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading outbox messages, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appliedSelectedStatus, appliedOccurredDateRange, appliedProcessedDateRange]
  );
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageSizeChange,
    onSortModelChange,
    refetch: fetchOutboxMessages,
    onKeysetPageChange,
  } = useDataGrid<IOutboxMessage>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      sortColumn: 'occurredOnUtc',
      sortDirection: 'desc',
      gridKeyName: 'billing-log-grid',
    },
    keysetPagingKey: 'id',
    dataFetcher,
  });
  return (
    <Card>
      <CardTitle
        title="Messages"
        marginBottom={0}
        action={
          <Box display="flex" alignItems="center" gap={1}>
            <Button
              onClick={() => setIsShowingFilters(!isShowingFilters)}
              startIcon={
                <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
              }
              className={'print--none'}
              color="secondary"
              size="small"
              disabled={isLoading}
            >
              Filters
            </Button>
          </Box>
        }
      >
        <CardFiltersLayout isOpen={isShowingFilters}>
          <Grid
            container
            spacing={2}
            className="print--none"
            onKeyDown={e => {
              checkForEnterKey(e, () => {});
            }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                label="Occurred Start Date"
                value={selectedOccurredDateRange?.startDate}
                maxDate={selectedOccurredDateRange?.endDate}
                onChange={newValue => {
                  setSelectedOccurredDateRange({
                    ...selectedOccurredDateRange,
                    startDate: newValue as any,
                  } as IDateRange);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                label="Occurred End Date"
                value={selectedOccurredDateRange?.endDate}
                minDate={selectedOccurredDateRange?.startDate}
                onChange={newValue => {
                  setSelectedOccurredDateRange({
                    ...selectedOccurredDateRange,
                    endDate: newValue as any,
                  } as IDateRange);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectAsyncInput
                label="Status"
                name="category"
                required
                handleChange={val => {
                  setSelectedStatus(val);
                }}
                value={selectedStatus}
                apiRequest={() => getOutboxMessagesStatuses()}
                transformResponse={(res: ILookupModel[]) =>
                  res.map(r => ({
                    label: r.description,
                    value: r.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                label="Processed Start Date"
                value={selectedProcessedDateRange?.startDate}
                maxDate={selectedProcessedDateRange?.endDate}
                onChange={newValue => {
                  setSelectedProcessedDateRange({
                    ...selectedProcessedDateRange,
                    startDate: newValue as any,
                  } as IDateRange);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                label="Processed End Date"
                value={selectedProcessedDateRange?.endDate}
                minDate={selectedProcessedDateRange?.startDate}
                onChange={newValue => {
                  setSelectedProcessedDateRange({
                    ...selectedProcessedDateRange,
                    endDate: newValue as any,
                  } as IDateRange);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>

            <FilterButtons
              hasAppliedFilters={
                !!appliedSelectedStatus || !!appliedOccurredDateRange || !!appliedProcessedDateRange
              }
              isDisabled={isLoading}
              handleApplyFilters={() => {
                setAppliedSelectedStatus(selectedStatus);
                setAppliedOccurredDateRange(selectedOccurredDateRange);
                setAppliedProcessedDateRange(selectedProcessedDateRange);
              }}
              handleResetFilters={() => {
                setSelectedStatus('');
                setAppliedSelectedStatus('');
                setAppliedOccurredDateRange(initialDateRange);
                setAppliedProcessedDateRange(initialDateRange);
                setSelectedOccurredDateRange(initialDateRange);
                setSelectedProcessedDateRange(initialDateRange);
              }}
            />
          </Grid>
        </CardFiltersLayout>
      </CardTitle>
      <Box marginTop="1rem">
        <MessagesDataGrid
          rows={rows}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          loading={isLoading}
          onPageChange={onKeysetPageChange}
          onPageSizeChange={onPageSizeChange}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          refetch={fetchOutboxMessages}
        />
      </Box>
    </Card>
  );
};
