export interface IPaymentSetup {
  systemSettingId: string;
  officeId: string;
  paymentProcessor: PaymentProcessor;

  // nullable, only one will be populated, depending on paymentProcessor
  authNetConfig?: IPaymentSetupAuthorizeNetConfig | null;
  adyenConfig?: IPaymentSetupAdyenConfig | null;
}

export interface IPaymentSetupAuthorizeNetConfig {
  apiLoginId: string;
  transactionKey: string;
  testMode: boolean;
  acceptBankAccountSales: boolean;
}

export interface IPaymentSetupAdyenConfig {
  apiKey: string;
  hmacKey: string;
  clientKey: string;
}

export interface IPaymentSetupCreate {
  officeId: string;
  paymentProcessor: PaymentProcessor;

  authNetConfig?: IPaymentSetupAuthorizeNetConfig | null;
  adyenConfig?: IPaymentSetupAdyenConfig | null;
}

export interface IPaymentSetupUpdate {
  systemSettingId: string;

  paymentProcessor: PaymentProcessor;

  authNetConfig?: IPaymentSetupAuthorizeNetConfig | null;
  adyenConfig?: IPaymentSetupAdyenConfig | null;
}

export interface IPaymentProcessorModel {
  paymentProcessor: PaymentProcessor | null;
  adyenConfig?: IPaymentSetupAdyenConfig | null;
}

export enum PaymentProcessor {
  AuthorizeNet = 'AuthorizeNet',
  Adyen = 'Adyen',
  Invalid = 'Invalid',
}
