import { FC } from 'react';
import { CardTitle, Card } from '../../../components';
import { ServiceRecommendationsDetails } from './service-recommendations-details';

export interface ServiceRecommendationsGridProps {
  serviceId: string;
  isExpanded?: boolean;
  showNoResults?: boolean;
  isCardLayout?: boolean;
  siteId?: string;
  withTreatments?: boolean;
}

export const ServiceRecommendations: FC<ServiceRecommendationsGridProps> = ({
  serviceId,
  isExpanded,
  showNoResults = true,
  isCardLayout = true,
  siteId,
  withTreatments,
}) => {
  if (!isCardLayout) {
    return (
      <ServiceRecommendationsDetails
        serviceId={serviceId}
        showNoResults={showNoResults}
        isCardLayout={isCardLayout}
        siteId={siteId}
        withTreatments={withTreatments}
      />
    );
  }

  return (
    <Card>
      <CardTitle
        title="Recommendations"
        withExpand
        initialExpand={false}
        overrideExpand={isExpanded}
        unmountOnExit
      >
        <ServiceRecommendationsDetails
          serviceId={serviceId}
          showNoResults={showNoResults}
          isCardLayout={isCardLayout}
          siteId={siteId}
          withTreatments={withTreatments}
        />
      </CardTitle>
    </Card>
  );
};
