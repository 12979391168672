import React, { FC, useState, useEffect, useContext } from 'react';
import { AppBar, Box, Typography, Grid, Divider, useMediaQuery, Link, styled } from '@mui/material';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { APP_VERSION } from '../../../helpers';
import { Modal } from '../../modal';
import { PrivacyStatement } from './privacy-statement';
import { TermsOfUse } from './terms';
import { ExternalLink } from '../../link';
import { BrandingContext } from '../../../context/branding-context';

interface IFooter {
  copyRight: string;
  height: number;
  showFooterLinks?: boolean;
}

export const Footer: FC<IFooter> = ({ copyRight, height, showFooterLinks = true }) => {
  const { isPoolService } = useContext(BrandingContext);

  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width: 767px)`);
  const [version, setVersion] = useState('');
  const currentYear = new Date().getFullYear();
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');

  useEffect(() => {
    const LSVersion = localStorage.getItem(APP_VERSION);
    if (LSVersion) {
      setVersion(LSVersion);
    }
  }, [location]);

  return (
    (<FooterWrapper>
      <AppBar
        component="footer"
        position="relative"
        className={clsx(classes.footerContainer, 'print--none')}
        color="primary"
        sx={{
          width: '100%',
          height: { xs: 'auto', md: height },
        }}
      >
        <Box display="flex" alignItems="center" flexDirection={isMobile ? 'column' : 'row'}>
          <Typography sx={{ flex: 1 }}>
            <Typography
              component="span"
              variant="caption"
              sx={{ color: theme => theme.palette.common.white }}
            >
              Copyright © {copyRight} {currentYear}. All Rights Reserved.
            </Typography>
          </Typography>
          {showFooterLinks && (
            <Box display="flex" alignItems="center" className="print--none">
              <Grid container spacing={1}>
                {version && (
                  <>
                    <Grid item>
                      <Typography
                        component="span"
                        variant="caption"
                        sx={{ color: theme => theme.palette.common.white }}
                      >
                        Version: {version}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Divider orientation="vertical" sx={{ height: `27px !important` }} />
                    </Grid>
                  </>
                )}
                <Grid item>
                  <Typography variant="caption">
                    {isPoolService ? (
                      <ExternalLink
                        target="_blank"
                        to={`https://www.poolcorp.com/privacy-policy/`}
                        type="white"
                      >
                        Privacy Statement
                      </ExternalLink>
                    ) : (
                      <Link
                        component={'button'}
                        onClick={() => {
                          setCopyModalOpen(true);
                          setModalType('privacy');
                        }}
                        style={{ color: 'inherit' }}
                      >
                        Privacy Statement
                      </Link>
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" sx={{ height: `27px !important` }} />
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    {isPoolService ? (
                      <ExternalLink
                        target="_blank"
                        to={`https://www.poolcorp.com/terms-conditions/`}
                        type="white"
                      >
                        Terms of Use
                      </ExternalLink>
                    ) : (
                      <Link
                        component={'button'}
                        onClick={() => {
                          setCopyModalOpen(true);
                          setModalType('terms');
                        }}
                        style={{ color: 'inherit' }}
                      >
                        Terms of Use
                      </Link>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </AppBar>
      {showFooterLinks && (
        <Modal
          maxWidth="md"
          open={copyModalOpen}
          title={
            modalType === 'privacy'
              ? 'Privacy Statement'
              : modalType === 'terms'
                ? 'Terms & Conditions'
                : 'Copyright Information'
          }
          onClose={() => {
            setModalType('');
            setCopyModalOpen(false);
          }}
        >
          {modalType === 'privacy' && <PrivacyStatement />}
          {modalType === 'terms' && <TermsOfUse />}
        </Modal>
      )}
    </FooterWrapper>)
  );
};

const PREFIX = 'Footer';

const classes = {
  footerContainer: `${PREFIX}-footerContainer`
};

const FooterWrapper = styled('div')(({ theme }) => {
  return {
    [`& .${classes.footerContainer}`]: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.grey[300],
      '@media (max-width: 767px)': {
        padding: theme.spacing(1),
      },
      '@media print': {
        backgroundColor: '#ffffff',
        boxShadow: 'none',
      },
    },
  };
});