import { FC, ReactNode } from 'react';
import { styled } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import {
  IDayInfo,
} from '../../../../../models';

interface DroppableWeekDayColumnProps {
  dayInfo: IDayInfo;
  children: ReactNode;
}

export const DroppableWeekdayColumn: FC<DroppableWeekDayColumnProps> = ({ dayInfo, children }) => {

  return (
    <Droppable droppableId={dayInfo.calendarDate} type="droppableItem">
      {provided => (
        <ColumnWrapper
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={classes.weekdayColumn}
        >
          {children}
          {provided.placeholder}
        </ColumnWrapper>
      )}
    </Droppable>
  );
};

const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';
const PREFIX = 'DroppableWeekdayColumn';

const classes = {
  weekdayColumn: `${PREFIX}-weekdayColumn`
};

const ColumnWrapper = styled('div')(() => ({
  [`&.${classes.weekdayColumn}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    gap: 20,
    padding: 10,
    [MOBILE_MEDIA_QUERY]: {
      padding: 3,
    },
    '@media print': {
      display: 'block',
    },
  }
}));