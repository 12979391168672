import { axios } from '../helpers';
import qs from 'querystring';
import { IRecentItem, IResponse } from '../models';
import { userSignal } from '../signals';

export const getRecentItems = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  officeId?: string | null;
}): Promise<IResponse<IRecentItem[]>> => {
  try {
    const { data } = await axios.get(`/api/recent-items`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
