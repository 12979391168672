import {
  TextField,
  FormikCard,
  ConfirmPrompt,
  SiteAutocomplete,
  AccountAutocomplete,
  CardTitle,
} from '../../components';
import { Grid, Autocomplete, Divider } from '@mui/material';
import { IDropdownResponse, IBillingGroup, IAccountSimple, ISalesTax } from '../../models';
import { LeadDetailFormFields } from '../leads/lead-detail-form-fields';
import { NewCustomerForm } from '../customers';
import { FC, useState, useEffect } from 'react';
import { defaultUnsavedChangesMessage } from '../../constants';

interface IContactDetails {
  dirty: boolean;
  isSubmitting: boolean;
  isLeadEstimate: boolean;
  isNewEstimate: boolean;
  values: any;
  isLoadingLeads: boolean;
  v2Leads: boolean;
  handleChange: any;
  handleTypeChange: any;
  setFieldValue: (key: string, value: any) => void;
  selectedLead: IDropdownResponse | null;
  billingGroups: any;
  isLoadingBillingGroups: boolean;
  salesTaxes?: ISalesTax[];
  isLoadingSalesTaxes: boolean;
  setSelectedLead: any;
  leadsOptions: IDropdownResponse[];
  accountId?: string;
  repairId?: string | null;
  siteId?: string | null;
  isAnEstimate?: boolean;
}

export const ContactDetails: FC<IContactDetails> = ({
  dirty,
  isSubmitting,
  isNewEstimate,
  values,
  isLoadingLeads,
  handleTypeChange,
  setFieldValue,
  selectedLead,
  billingGroups,
  isLoadingBillingGroups,
  salesTaxes,
  isLoadingSalesTaxes,
  setSelectedLead,
  leadsOptions,
  accountId,
  repairId,
  siteId,
  isAnEstimate = false,
}) => {
  const [selectedAccount, setSelectedAccount] = useState<IAccountSimple | null>(null);
  const [accounts, setAccounts] = useState<IAccountSimple[] | null>(null);

  useEffect(() => {
    if (!!accountId && accounts) {
      const account = accounts?.find(account => account.accountId === accountId);
      if (account) {
        setSelectedAccount(account);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, accounts]);
  return (
    <FormikCard>
      <CardTitle title="Contact Details" />
      <ConfirmPrompt when={dirty && !isSubmitting} message={defaultUnsavedChangesMessage} />
      <Grid container spacing={2} mb={2}>
        {values.estimateType === 'Customer' && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <AccountAutocomplete
                  isFreeSolo
                  setSelectedAccount={val => {
                    if (val && !val?.accountId && val?.accountName) {
                      return handleTypeChange('NewCustomer', val);
                    }
                    setFieldValue('accountId', val?.accountId);
                    setFieldValue('siteId', '');
                    setSelectedAccount(val);
                  }}
                  handleOptions={data => setAccounts(data)}
                  labelText={!!accountId ? 'Customer' : 'Search for Customer'}
                  selectedAccount={selectedAccount}
                  isDisabled={
                    Boolean(repairId) ||
                    Boolean(values.repairId) ||
                    (isAnEstimate && !isNewEstimate)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SiteAutocomplete
                  isDisabled={
                    Boolean(repairId) ||
                    Boolean(values.repairId) ||
                    !values.accountId ||
                    (isAnEstimate && !isNewEstimate)
                  }
                  isRequired
                  accountId={values.accountId!}
                  siteId={siteId}
                  handleChange={val => {
                    setFieldValue('siteId', val);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {values.estimateType === 'ExistingLead' && (
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={selectedLead}
              onChange={(event, newValue: IDropdownResponse | null) => {
                if (!!newValue) {
                  setSelectedLead(newValue);
                  setFieldValue('leadId', newValue.value);
                } else {
                  setSelectedLead(null);
                  setFieldValue('leadId', '');
                }
              }}
              disabled={isLoadingLeads}
              selectOnFocus
              handleHomeEndKeys
              loading={isLoadingLeads}
              id="leadId"
              options={leadsOptions || []}
              getOptionLabel={(option: IDropdownResponse) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option.description;
              }}
              renderOption={(props, option: IDropdownResponse) => {
                return (
                  <li {...props} key={option.value}>
                    {option.description}
                  </li>
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Select Lead"
                  key={params.id}
                  size="small"
                  autoComplete="on"
                  label={isLoadingLeads ? 'Loading...' : 'Lead'}
                  variant="outlined"
                  name="leadId"
                  required
                />
              )}
            />
          </Grid>
        )}
        {(values.estimateType === 'NewLead' || values.estimateType === 'NewCustomer') && (
          <Grid item xs={12}>
            <Divider
              sx={{
                borderColor: theme => theme.palette.dividers.grey,
                marginBottom: theme => theme.spacing(2),
              }}
            />
            {values.estimateType === 'NewLead' && <LeadDetailFormFields fieldContext="newLead" />}
            {values.estimateType === 'NewCustomer' && isNewEstimate && (
              <NewCustomerForm
                billingGroups={billingGroups as IBillingGroup[]}
                isLoadingBillingGroups={isLoadingBillingGroups}
                salesTaxes={salesTaxes}
                isLoadingSalesTaxes={isLoadingSalesTaxes}
              />
            )}
          </Grid>
        )}
      </Grid>
    </FormikCard>
  );
};
