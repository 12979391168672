import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { AzureMap } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { ITechnician } from '../../../models';

export const StyleGuideMaps = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const techs: ITechnician[] = [
    {
      userId: '2c27530b-8076-4079-b5c0-778fe5fbbffb',
      userName: 'Automation',
      isDisabled: false,
      visits: [
        {
          repairVisitId: '98b3dd48-d082-4d98-a55d-4454f2e517d1',
          repairId: '1ba9f048-0fc4-48b7-82bd-887d6cf75c17',
          serviceDate: '2024-08-29T00:00:00-04:00',
          whenReported: undefined,
          status: 'Open',
          siteId: 'ba02f646-38fe-4908-8470-0925bb34633c',
          siteName: 'Route Start',
          problemDescription: undefined,
          startTime: '2024-08-29T04:00:00+00:00',
          endTime: '',
          whenStarted: undefined,
          whenCompleted: undefined,
          repairProgress: '',
          assignedTo: 'Automation',
          repairNotes: undefined,
          accountId: undefined,
          siteAddress: {
            addressId: '7efb09f0-9c38-4b00-9d70-468d8377ecb3',
            addressName: 'POOL DEALER 001',
            street: '400 N Dale Mabry Hwy',
            city: 'Tampa',
            state: 'FL',
            postalCode: '33609',
            latitude: 27.947,
            longitude: -82.504,
          },
          accountPhoneNumber: undefined,
          userId: '2c27530b-8076-4079-b5c0-778fe5fbbffb',
          serviceType: null,
          hasApprovalOverride: false,
          approvalDate: '',
          expirationDate: '',
        },
        {
          repairVisitId: '16f1e37e-36ad-4e78-a811-3e03fb8c149c',
          repairId: '55a2df28-cab0-4930-813e-f63f47683088',
          serviceDate: '2024-08-29T00:00:00-04:00',
          whenReported: '2024-08-26T20:28:35.99951+00:00',
          status: 'Open',
          siteId: 'ba02f646-38fe-4908-8470-0925bb34633c',
          siteName: 'Peter Glack',
          problemDescription: '',
          startTime: '2024-08-29T14:00:00+00:00',
          endTime: '2024-08-29T16:30:00+00:00',
          whenStarted: undefined,
          whenCompleted: undefined,
          repairProgress: 'WorkOrder',
          assignedTo: 'Automation',
          repairNotes: undefined,
          accountId: 'b94ba5fe-7b41-49a1-b6b0-2e96644de9ef',
          siteAddress: {
            addressId: '4c60f381-66fc-4733-a44c-cb87d27ab421',
            addressName: 'Peter Glack',
            street: '824 W Lowry Ln',
            city: 'Tampa',
            state: 'FL',
            postalCode: '33604',
            latitude: 28.016994,
            longitude: -82.467651,
          },
          accountPhoneNumber: {
            accountPhoneNumberId: 'a12766ac-5416-4a9f-af3b-d446b5a2023d',
            phoneNumberType: 'Primary',
            phoneNumber: '(875) 222-1111',
          },
          userId: '2c27530b-8076-4079-b5c0-778fe5fbbffb',
          serviceType: 'Landscape Lighting',
          hasApprovalOverride: false,
          approvalDate: '',
          expirationDate: '',
        },
        {
          repairVisitId: '561a8850-053d-412a-8a60-35679f223051',
          repairId: '6172fc2c-1cbe-4345-a4b5-3a2e61eb90c1',
          serviceDate: '2024-08-29T00:00:00-04:00',
          whenReported: undefined,
          status: 'Open',
          siteId: 'ba02f646-38fe-4908-8470-0925bb34633c',
          siteName: 'Route End',
          problemDescription: undefined,
          startTime: '2024-08-30T04:00:00+00:00',
          endTime: '',
          whenStarted: undefined,
          whenCompleted: undefined,
          repairProgress: '',
          assignedTo: 'Automation',
          repairNotes: undefined,
          accountId: undefined,
          siteAddress: {
            addressId: '7efb09f0-9c38-4b00-9d70-468d8377ecb3',
            addressName: 'POOL DEALER 001',
            street: '400 N Dale Mabry Hwy',
            city: 'Tampa',
            state: 'FL',
            postalCode: '33609',
            latitude: 27.947,
            longitude: -82.504,
          },
          accountPhoneNumber: undefined,
          userId: '2c27530b-8076-4079-b5c0-778fe5fbbffb',
          serviceType: null,
          hasApprovalOverride: false,
          approvalDate: '',
          expirationDate: '',
        },
      ],
      services: [],
      color: 'hsl(0, 80%, 50%)',
    },
  ];

  return (
    <StyleGuidePage title="Maps">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            This section covers Azure map component overview and implementation details. Azure Maps
            is the primary map integration used in the application to display map interfaces.
          </Typography>
          <Typography mb={1}>
            Maps are currently used for manual address entry, for service routes, and for OTS
            visits. Certain map capabilities include clicking on a map to place a pin and capture
            address information, like coordinates. Additionally, maps are used to chart out
            sequential and non-sequential map pins.
          </Typography>
          <Typography mb={1}>
            When implementing the map component, techs object prop is a required prop but the rest
            are optional.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item mb={2} xs={12} md={9}>
          <code>
            Props:
            <br />- techs: ITechnician{'[]'}
            <br />- setPushPins?: Dispatch{'<SetStateAction<any>>'}
            <br />- keyId?: string
            <br />- isRoutes?: boolean
            <br />- reload?: boolean
          </code>
        </Grid>
        <Grid item xs={12} md={9}>
          <AzureMap techs={techs} isRoutes={false} />
        </Grid>
        <Grid item mb={2} xs={12} md={9}>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;AzureMap
            <br />
            &nbsp;&nbsp;&nbsp;techs=&#123;techs&#125;
            <br />
            &nbsp;&nbsp;&nbsp;isRoutes=&#123;false&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
