import { Button } from '@mui/material';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CommissionsRulesDataGrid } from './commission-rules-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useContext, useState } from 'react';
import { GridDataFetcher, useDataGrid, CardTitle, Card } from '../../../components';
import { getCommissionRules } from '../../../fetch';
import { ICommissionRule } from '../../../models';
import { UserContext } from '../../../context';
import { AddEditCommissionRuleModal } from '../commissions/add-edit-commission-rule-modal'

export const CommissionsRulesListDetails: FC = () => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setDeleting] = useState(false);
  const [isShowingModal, showModal] = useState(false);
  const [currentCommissionRule, setCurrentCommissionRule] = useState<ICommissionRule | null>(null);
  const dataFetcher: GridDataFetcher<ICommissionRule> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          officeId: user?.officeId,
        };

        const res = await getCommissionRules(options);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading commission rules, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ICommissionRule>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'commission-rules-grid',
      sortColumn: 'serviceType',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  return (
    <Card>
      <CardTitle
        title="Commission Rules"
        marginBottom={0}
        action={
          <Button
            onClick={() => showModal(true)}
            color="secondary"
            size="small"
            disabled={isLoading}
            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
          >
            Add Commission Rule
          </Button>
        }
      />
      <CommissionsRulesDataGrid
        loading={isLoading || isDeleting}
        rows={rows}
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        refetch={refetch}
        setDeleting={setDeleting}
        handleEdit={showModal}
        setCurrentCommissionRule={setCurrentCommissionRule}
      />
      <AddEditCommissionRuleModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentCommissionRule(null);
        }}
        currentCommissionRule={currentCommissionRule}
        fetchCommissionRules={() => refetch()}
      />
    </Card>

  );
};
