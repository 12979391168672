import { FC } from 'react';
import { Box } from '@mui/material';
import { Modal } from '../../components';
import { IAccountDetail, IRecurringPayment } from '../../models';
import { PaymentInfo } from './payment-info';

interface IRecurringPaymentModal {
  isOpen: boolean;
  onClose: (shouldReload?: boolean) => void;
  currentCustomer: IAccountDetail;
  currentRecurringPayment: IRecurringPayment | null;
}

export const RecurringPaymentModal: FC<IRecurringPaymentModal> = ({
  isOpen,
  onClose,
  currentCustomer,
  currentRecurringPayment,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="md"
      title="Recurring Payment Info"
    >
      <Box mt={2}>
        <PaymentInfo
          currentCustomer={currentCustomer}
          currentRecurringPayment={currentRecurringPayment}
          isRecurring
          showCardTitle={false}
          closeModal={onClose}
        />
      </Box>
    </Modal>
  );
};
