import React, {
  FC,
  Fragment,
  Dispatch,
  useState,
  SetStateAction,
  useMemo,
  useContext,
} from 'react';
import { useQuery } from 'react-query';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';
import { deepEqual } from 'fast-equals';
import { format } from 'date-fns';
import { Modal, Loader, SaveButton, CancelIcon } from '..';
import { Grid, Box, Fade, Typography, Button } from '@mui/material';
// fetch
import { getTreatmentTypes, createTreatment } from '../../fetch';
import { ITreatmentType, IResponse, ICreateTreatment } from '../../models';
import { useConfirm } from '../../hooks';
import { defaultUnsavedChangesMessage } from '../../constants';
import { UserContext } from '../../context';
import { TreatmentSlider } from './treatment-slider';
import { dateStringNull } from '../../helpers';

interface ITreatmentModal {
  open: boolean;
  onClose: () => void;
  selectedTreatments: ICreateTreatment[];
  getTreatments: () => void;
  siteName?: string;
  siteId: string;
  serviceId?: string;
  setSelectedTreatments: Dispatch<SetStateAction<ICreateTreatment[]>>;
  whenAdded: dateStringNull;
  setWhenAdded: (val: Date) => void;
  defaultTreatments: ICreateTreatment[];
  setInitialTreatments: Dispatch<SetStateAction<ICreateTreatment[]>>;
  setTreatmentsForDate: (val: string | Date) => void;
  isLoadingAllTreatments: boolean;
  hasTreatments: boolean;
}
export const TreatmentModal: FC<ITreatmentModal> = ({
  open,
  onClose,
  siteName,
  selectedTreatments,
  setSelectedTreatments,
  siteId,
  serviceId,
  getTreatments,
  setWhenAdded,
  whenAdded,
  defaultTreatments,
  setInitialTreatments,
  setTreatmentsForDate,
  isLoadingAllTreatments,
  hasTreatments,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setSaving] = useState(false);
  const confirm = useConfirm();
  const { office } = useContext(UserContext);

  const isBillableTreatments = useMemo(() => office?.billForTreatments ?? false, [office]);
  const { data: treatmentTypesData } = useQuery<IResponse<ITreatmentType[]>, Error>(
    ['treatment-types'],
    () => getTreatmentTypes({ perPage: -1 }),
    {
      onSuccess: d => {
        const formatTreatments = d.records.map(t => ({
          treatmentTypeId: t.treatmentTypeId,
          serviceId,
          unitsAdded: 0,
          isBillable: false,
        }));
        setSelectedTreatments(formatTreatments);
        setInitialTreatments(formatTreatments);
        return d;
      },
      notifyOnChangeProps: 'tracked',
    }
  );

  const types: ITreatmentType[] = useMemo(
    () => treatmentTypesData?.records ?? [],
    [treatmentTypesData]
  );
  return (
    <Modal
      open={open}
      onClose={async () => {
        if (!deepEqual(defaultTreatments, selectedTreatments)) {
          const result = await confirm(defaultUnsavedChangesMessage);
          if (result) {
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
        }
      }}
      maxWidth="sm"
      title={`${hasTreatments ? 'Edit' : 'Add'} Treatments`}
    >
      {(isSaving || isLoadingAllTreatments) && <Loader type="overlay" />}
      <Fade in={open}>
        <div>
          <Box mt={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom>Site Name: {siteName}</Typography>
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="When Added"
                  format="MM/dd/yyyy"
                  onChange={(date: any) => {
                    setWhenAdded(date);
                    setTreatmentsForDate(date);
                  }}
                  value={whenAdded ? new Date(whenAdded) : null}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Box maxHeight="30rem" overflow="auto" width="100%">
                {types.map((type, index) => {
                  const selected = selectedTreatments.find(
                    treatment => treatment.treatmentTypeId === type.treatmentTypeId
                  );
                  const selectedIndex = selectedTreatments.findIndex(
                    treatment => treatment.treatmentTypeId === type.treatmentTypeId
                  );
                  return (
                    <Fragment key={`${index}`}>
                      <TreatmentSlider
                        label={type.description}
                        value={selected?.unitsAdded}
                        setFieldValue={val => {
                          const copy = JSON.parse(JSON.stringify(selectedTreatments));
                          // if we are updating a pre-existing treament
                          if (copy?.[selectedIndex]) {
                            copy[selectedIndex].unitsAdded = val;
                            setSelectedTreatments(copy);
                          } else {
                            // adding a new selected treatment
                            setSelectedTreatments([
                              ...selectedTreatments,
                              {
                                treatmentTypeId: type.treatmentTypeId,
                                serviceId,
                                unitsAdded: val,
                                isBillable: selected?.isBillable ?? false,
                              } as ICreateTreatment,
                            ]);
                          }
                        }}
                        max={type.highestToAdd}
                        measure={type.unitDescription.toLowerCase()}
                        id={type.code}
                        step={type.unitIncrement}
                        isBillable={selected?.isBillable}
                        setBillable={val => {
                          const copy = JSON.parse(JSON.stringify(selectedTreatments));

                          if (copy?.[selectedIndex]) {
                            copy[selectedIndex].isBillable = val;
                            setSelectedTreatments(copy);
                          } else {
                            // adding a new selected treatment
                            setSelectedTreatments([
                              ...selectedTreatments,
                              {
                                treatmentTypeId: type.treatmentTypeId,
                                serviceId,
                                unitsAdded: selected?.unitsAdded ?? 0,
                                isBillable: val ?? false,
                              } as ICreateTreatment,
                            ]);
                          }
                        }}
                        showBillable={isBillableTreatments}
                      />
                    </Fragment>
                  );
                })}
              </Box>
            </Grid>

            <Box mt={1} display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
              <Button
                type="button"
                color="inherit"
                onClick={async () => {
                  if (!deepEqual(defaultTreatments, selectedTreatments)) {
                    const result = await confirm(defaultUnsavedChangesMessage);
                    if (result) {
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
              <SaveButton
                disabled={deepEqual(defaultTreatments, selectedTreatments)}
                handleSave={async () => {
                  try {
                    setSaving(true);
                    await createTreatment(
                      siteId,
                      whenAdded ? format(new Date(whenAdded), 'yyyy-L-d') : '',
                      {
                        treatments: selectedTreatments,
                      },
                      serviceId
                    );
                    enqueueSnackbar('Treatment saved!', {
                      variant: 'success',
                    });
                    getTreatments();
                    onClose();
                  } catch (error: any) {
                    enqueueSnackbar(error?.data?.Detail || 'Error saving treatment.', {
                      variant: 'error',
                    });
                  } finally {
                    setSaving(false);
                  }
                }}
              />
            </Box>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};
