import { FC, useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { GridDataFetcher, useDataGrid, ServerSideDataGrid, SearchField } from '../../../components';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { getUsers, getUserUpgradeData } from '../../../fetch';
import { IListUser, ISuperAdminUserData } from '../../../models';
import { Box, Button } from '@mui/material';
import { UsersTableModalData } from './users-table-modal-data';

export const UsersTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IListUser | null>(null);
  const [currentUserUpdateData, setCurrentUserUpdateData] = useState<ISuperAdminUserData | null>(
    null
  );
  const [isLoadingData, setLoadingData] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedValue, setSearchedValue] = useState<string>('');

  const handleEdit = async (item: IListUser) => {
    setIsShowingModal(true);
    try {
      setLoadingData(true);
      const res = await getUserUpgradeData(item.userId);
      setCurrentUserUpdateData(res);
      setCurrentUser(item);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error loading users, please try again.`, {
        variant: 'error',
      });
    } finally {
      setLoadingData(false);
    }
  };

  const dataFetcher: GridDataFetcher<IListUser> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const res = await getUsers({
          sortDirection: sortDirection || 'asc',
          sortBy: sortColumn || 'userName',
          perPage,
          page: page + 1,
          excludeSuperAdmins: true,
          search: searchedValue,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading users, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchedValue]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: fetchUsers,
  } = useDataGrid<IListUser>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'users-management-grid',
      sortColumn: 'userName',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'userName',
        headerName: 'User',
        type: 'string',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IListUser>) => {
          return (
            <Button
              color="primary"
              variant="text"
              sx={{
                p: 0,
              }}
              onClick={() => {
                handleEdit(params.row);
              }}
            >
              {params.row.userName}
            </Button>
          );
        },
      },
      {
        field: 'loginName',
        headerName: 'Login Name',
        type: 'string',
        flex: 1,
      },
      {
        field: 'officeName',
        headerName: 'Office',
        type: 'string',
        flex: 1,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <>
      <Box mt={2}>
        <Box
          width={{
            xs: '100%',
            sm: '50%',
          }}
          mb={{
            xs: 2,
            sm: 0,
          }}
        >
          <SearchField
            placeholder="Search for users..."
            handleSearch={() => {
              setSearchedValue(searchValue);
            }}
            handleClearSearch={() => {
              setSearchedValue('');
              setSearchValue('');
            }}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isDisabled={isLoading}
          />
        </Box>
      </Box>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: IListUser) => row.userId}
        rows={rows}
        columns={columns}
        loading={isLoading}
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        sortModel={sortModel}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        hasMobileLayout
      />
      <UsersTableModalData
        isShowingModal={isShowingModal}
        setIsShowingModal={setIsShowingModal}
        currentUserUpdateData={currentUserUpdateData}
        currentUser={currentUser}
        isLoadingData={isLoadingData}
        setCurrentUser={setCurrentUser}
        fetchUsers={fetchUsers}
      />
    </>
  );
};
