import { FC, useEffect, useState, useContext, Dispatch, SetStateAction, useMemo } from 'react';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Autocomplete,
  Button,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  useMediaQuery,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { UserContext } from '../../../context/user';
import {
  faEdit,
  faTrash,
  faFloppyDisk,
  faCancel,
  faGripVertical,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader, Card, CardTitle } from '../../../components';
import { IAccountRate, IAccountRateEditable } from '../../../models';
import {
  deleteAccountRate,
  getAccountRates,
  createAccountRate,
  updateAccountRate,
  getRates,
} from '../../../fetch';
import { formatMoney } from '../../../helpers';
import { deepEqual } from 'fast-equals';
import { useConfirm } from '../../../hooks';

export interface IRatesTab {
  accountId: string;
  setIsTabDirty: Dispatch<SetStateAction<boolean>>;
  isEditable?: boolean;
}

export const RatesTab: FC<IRatesTab> = ({ accountId, setIsTabDirty, isEditable = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const confirm = useConfirm();

  const [isLoadingRates, setLoadingRates] = useState(true);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [isSortOrderDirty, setIsSortOrderDirty] = useState(false);
  const [isSavingSortOrder, setIsSavingSortOrder] = useState(false);
  const [customerRates, setCustomerRates] = useState<IAccountRateEditable[]>([]);
  const [isLoadingOfficeRates, setLoadingOfficeRates] = useState(true);
  const [officeRates, setOfficeRates] = useState<IAccountRate[]>([]);

  const isEditingRates = useMemo(
    () =>
      customerRates &&
      customerRates.length > 0 &&
      customerRates.filter(rate => rate.isEditing)?.length > 0,
    [customerRates]
  );

  // Refresh rates on mount and when setsNeedsRefresh is set to true
  useEffect(() => {
    const fetchRates = async () => {
      setLoadingRates(true);
      if (accountId) {
        try {
          const customerRates = await getAccountRates(accountId, {
            sortBy: 'SortOrder',
            sortDirection: 'Asc',
            perPage: -1,
          });
          setCustomerRates(customerRates.records);
        } catch (error) {
          enqueueSnackbar(`Error loading customer rates, please try again.`, {
            variant: 'error',
          });
        } finally {
          setLoadingRates(false);
          setNeedsRefresh(false);
        }
      }
    };

    fetchRates();
  }, [accountId, enqueueSnackbar, user?.officeId, needsRefresh]);

  useEffect(() => {
    const fetchOfficeRates = async () => {
      setLoadingOfficeRates(true);
      if (accountId) {
        try {
          const officeRates = await getRates({
            officeId: user?.officeId ?? '',
            sortBy: 'Description',
            sortDirection: 'Asc',
            perPage: -1,
          });
          setOfficeRates(officeRates.records);
        } catch (error) {
          enqueueSnackbar(`Error loading office rates, please try again.`, {
            variant: 'error',
          });
        } finally {
          setLoadingOfficeRates(false);
        }
      }
    };

    if (isEditingRates) {
      fetchOfficeRates();
    }
  }, [isEditingRates, accountId, enqueueSnackbar, user?.officeId]);

  // Update the sort order for all rows when it isSortOrderDirty is set to true
  useEffect(() => {
    const saveSortOrder = async () => {
      if (isSortOrderDirty) {
        setIsSavingSortOrder(true);
        setIsSortOrderDirty(false);
        try {
          const promises = customerRates
            .filter(rate => Boolean(rate.accountRateId))
            .map((rate, index) => {
              return updateAccountRate(accountId, rate.accountRateId, {
                rateId: rate.pendingRateId || rate.rateId,
                sortOrder: index,
              });
            });
          const responseArray = await Promise.all(promises);
          const failed = responseArray.filter(response => response.status !== 200);
          if (failed.length > 0) {
            enqueueSnackbar('Error saving sort order. Please refresh the page', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Sort order saved!', { variant: 'success', autoHideDuration: 2000 });
          }
        } catch (err) {
          enqueueSnackbar('Error saving sort order. Please refresh the page', { variant: 'error' });
        } finally {
          setIsSavingSortOrder(false);
        }
      }
    };
    saveSortOrder();
  }, [isSortOrderDirty, customerRates, accountId, enqueueSnackbar]);

  const handleAddRate = () => {
    setCustomerRates([
      ...customerRates,
      {
        ...officeRates[0],
        rateId: '',
        accountRateId: '',
        rate: 0,
        description: null,
        code: null,
        isEditing: true,
        isNew: true,
        pendingRateId: '',
      },
    ]);
  };

  const handleEditRate = (rateId: string) => {
    const newRates = customerRates.map(rate => {
      if (rate.rateId === rateId) {
        rate.isEditing = true;
      }
      return rate;
    });
    setCustomerRates(newRates);
  };

  const handleCancelEditRate = (rateId: string) => {
    const newRates = customerRates
      .filter(rate => rate.isNew !== true)
      .map(rate => {
        if (rate.rateId === rateId) {
          rate.isEditing = false;
          delete rate.pendingRateId;
        }
        return rate;
      });
    setCustomerRates(newRates);
  };

  const handleSaveRate = async (rate: IAccountRateEditable) => {
    try {
      setLoadingRates(true);
      rate.isEditing = false;
      let response;
      if (rate.isNew) {
        // zero based sort order, check the length of items added to figure out the sortOrder
        const sortOrder = customerRates.length === 1 ? 0 : customerRates.length - 1;
        response = await createAccountRate(accountId, {
          rateId: rate.pendingRateId || rate.rateId,
          sortOrder,
        });
      } else {
        response = await updateAccountRate(accountId, rate.accountRateId, {
          rateId: rate.pendingRateId || rate.rateId,
          sortOrder: rate.sortOrder,
        });
      }

      if (response.Detail) {
        enqueueSnackbar(response.Detail, { variant: 'error' });
      } else {
        enqueueSnackbar(`Rate Saved!`, { variant: 'success' });
      }
      setIsTabDirty(false);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.Detail || JSON.stringify(err), { variant: 'error' });
    } finally {
      setNeedsRefresh(true);
      setLoadingRates(false);
    }
  };

  const handleDeleteRate = async (rateId: string) => {
    try {
      const result = await confirm('Are you sure you want to delete this rate?');
      if (result) {
        setLoadingRates(true);
        await deleteAccountRate(rateId, accountId);
        enqueueSnackbar(`Rate Deleted!`, {
          variant: 'success',
        });
      }
      setNeedsRefresh(true);
    } catch (error) {
      enqueueSnackbar(`Error deleting rate, please try again.`, {
        variant: 'error',
      });
    } finally {
      setLoadingRates(false);
    }
  };

  const handleRateChange = (newValue: string, rateId: string): void => {
    const newRates = customerRates.map(rate => {
      if (rate.rateId === rateId) {
        rate.pendingRateId = newValue;
      }
      return rate;
    });
    if (deepEqual(officeRates, newRates)) {
      setIsTabDirty(false);
    } else {
      setIsTabDirty(true);
    }
    setCustomerRates(newRates);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newCustomerRates = [...customerRates];
    const [removed] = newCustomerRates.splice(result.source.index, 1);
    newCustomerRates.splice(result.destination.index, 0, removed);

    setCustomerRates(newCustomerRates);
    setIsSortOrderDirty(true);
  };

  const isEditing = Boolean(customerRates.find(rate => rate.isEditing));
  const isDragDisabled = isEditing || isSavingSortOrder || !isEditable;

  return (
    <Box marginTop="1rem">
      <Card>
        <CardTitle
          title="Rates"
          mobileWrap
          action={
            <>
              {isEditable && (
                <Button
                  onClick={handleAddRate}
                  color="secondary"
                  size="small"
                  startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                  disabled={isEditing}
                >
                  Add Rate
                </Button>
              )}
            </>
          }
        />
        {isLoadingRates && (
          <Box py={3}>
            <Loader position="centered" type="overlay" />
          </Box>
        )}
        {!isLoadingRates && customerRates && customerRates?.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            {isSavingSortOrder && (
              <Loader position="centered" title="Saving Sort Order" type="overlay" />
            )}
            <TableContainer>
              <StyledTable sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <StyledHeader
                      component="th"
                      sx={{
                        width: '8%',
                      }}
                    />
                    <StyledHeader
                      component="th"
                      sx={{
                        width: '20%',
                      }}
                    >
                      Rate
                    </StyledHeader>
                    <StyledHeader
                      component="th"
                      sx={{
                        width: '32%',
                      }}
                    >
                      Description
                    </StyledHeader>
                    <StyledHeader
                      component="th"
                      align="right"
                      sx={{
                        width: '20%',
                      }}
                    >
                      Amount
                    </StyledHeader>
                    {!isDragDisabled && (
                      <StyledHeader
                        component="th"
                        align="right"
                        sx={{
                          width: '20%',
                        }}
                      />
                    )}
                  </TableRow>
                </TableHead>
                <Droppable droppableId="table">
                  {(droppableProvided: DroppableProvided) => (
                    <tbody
                      ref={ref => {
                        droppableProvided.innerRef(ref);
                      }}
                      {...droppableProvided.droppableProps}
                    >
                      {customerRates.map((rate, index) => {
                        if (rate.isEditing) {
                          const pendingRate = officeRates.find(
                            officeRate => officeRate.rateId === rate.pendingRateId
                          );
                          return (
                            <StyledRow
                              key={`${rate.accountRateId}-editing}`}
                              style={{ verticalAlign: 'middle' }}
                              isMobile={isMobile}
                            >
                              <StyledCell sx={{ padding: '1rem .65rem' }} isMobile={isMobile} />
                              <StyledCell sx={{ padding: '.65rem' }} isMobile={isMobile}>
                                <Autocomplete
                                  value={pendingRate || rate}
                                  onChange={(event, newValue: any) => {
                                    if (newValue) {
                                      handleRateChange(newValue.rateId, rate.rateId);
                                    }
                                  }}
                                  disabled={isLoadingRates || isLoadingOfficeRates}
                                  selectOnFocus
                                  handleHomeEndKeys
                                  loading={isLoadingRates || isLoadingOfficeRates}
                                  options={officeRates || []}
                                  getOptionLabel={(option: IAccountRate) => {
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    return option.code ?? '';
                                  }}
                                  renderOption={(props, option: IAccountRate) => {
                                    return (
                                      <li {...props} key={option.rateId}>
                                        {option.code} - {option.description}
                                      </li>
                                    );
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                      placeholder="Select Rate"
                                      key={params.id}
                                      size="small"
                                      autoComplete="on"
                                      label={
                                        isLoadingRates || isLoadingOfficeRates
                                          ? 'Loading...'
                                          : 'Code'
                                      }
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </StyledCell>
                              <StyledCell sx={{ padding: '1rem .65rem' }} isMobile={isMobile}>
                                {pendingRate?.description || rate.description}
                              </StyledCell>
                              <StyledCell
                                align="right"
                                sx={{ padding: '1rem .65rem' }}
                                isMobile={isMobile}
                              >
                                {formatMoney(pendingRate?.rate || rate.rate)}
                              </StyledCell>
                              {isEditable && (
                                <StyledCell
                                  align="right"
                                  sx={{ padding: '1rem .65rem' }}
                                  isMobile={isMobile}
                                >
                                  <IconButton
                                    color="primary"
                                    title="Save Rate"
                                    sx={{ marginRight: theme => theme.spacing(1) }}
                                    onClick={() => handleSaveRate(rate)}
                                    disabled={Boolean(!pendingRate)}
                                  >
                                    <FontAwesomeIcon icon={faFloppyDisk} size="sm" />
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    title="Cancel Edit"
                                    onClick={() => handleCancelEditRate(rate.rateId)}
                                  >
                                    <FontAwesomeIcon icon={faCancel} size="sm" />
                                  </IconButton>
                                </StyledCell>
                              )}
                            </StyledRow>
                          );
                        }

                        return (
                          <Draggable
                            draggableId={rate.rateId}
                            index={index}
                            key={rate.accountRateId}
                            isDragDisabled={isDragDisabled}
                          >
                            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  backgroundColor: theme =>
                                    snapshot.isDragging ? theme.palette.secondary.light : undefined,
                                  '&:hover': {
                                    backgroundColor: theme =>
                                      isEditing ? 'inherit' : theme.palette.action.hover,
                                  },
                                  display: snapshot.isDragging ? 'table' : undefined,
                                }}
                              >
                                <StyledCell isMobile={isMobile}>
                                  <Box pl={1}>
                                    <FontAwesomeIcon
                                      style={{
                                        visibility: isDragDisabled ? 'hidden' : 'visible',
                                      }}
                                      icon={faGripVertical}
                                      size="sm"
                                      title="Drag to reorder"
                                    />
                                  </Box>
                                </StyledCell>
                                <StyledCell isMobile={isMobile}>{rate.code}</StyledCell>
                                <StyledCell isMobile={isMobile}>{rate.description}</StyledCell>
                                <StyledCell align="right" isMobile={isMobile}>
                                  {formatMoney(rate.rate)}
                                </StyledCell>
                                {isEditable && (
                                  <StyledCell align="right" isMobile={isMobile}>
                                    <IconButton
                                      color="primary"
                                      title="Edit Rate"
                                      sx={{ marginRight: theme => theme.spacing(1) }}
                                      onClick={() => handleEditRate(rate.rateId)}
                                    >
                                      <FontAwesomeIcon icon={faEdit} size="sm" />
                                    </IconButton>
                                    <IconButton
                                      color="error"
                                      title="Delete Rate"
                                      onClick={() => handleDeleteRate(rate.rateId)}
                                    >
                                      <FontAwesomeIcon icon={faTrash} size="sm" />
                                    </IconButton>
                                  </StyledCell>
                                )}
                              </TableRow>
                            )}
                          </Draggable>
                        );
                      })}
                      {droppableProvided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </StyledTable>
            </TableContainer>
          </DragDropContext>
        )}
        {!isLoadingRates && (customerRates?.length === 0 || !customerRates) && (
          <Box pt={3} pb={4}>
            <Typography textAlign="center">There are no rates to display.</Typography>
          </Box>
        )}
      </Card>
    </Box>
  );
};

const StyledTable = styled(MuiTable)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: 'none',
}));

const StyledHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.typography.tableHeaders,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 'bold',
  border: 'none',
  padding: '0 .65rem',
}));

const StyledCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'bold',
  padding: isMobile ? '1rem .65rem' : '0 .65rem',
  color: theme.palette.text.primary,
}));

const StyledRow = styled(TableRow, {
  shouldForwardProp: prop => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  '& .MuiTableCell-root': {
    padding: isMobile ? '1rem .65rem' : '.65rem',
  },
}));
