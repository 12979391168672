import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { AmountInput } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';

export const StyleGuideAmountInput = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Amount Input">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Amount Input component is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-text-field/" target="_blank">
              MUI Text Field
            </Link>{' '}
            component. It has a built-in styles, and currency formatting.
          </Typography>
          <code>
            Props:
            <br />- value: string | number
            <br />- disabled?: boolean
            <br />- onChange: (val: string) =&gt; void
            <br />- onBlur?: React.FocusEventHandler&lt;HTMLInputElement | HTMLTextAreaElement&gt;
            <br />- error?: boolean
            <br />- helperText?: string
            <br />- label: string
            <br />- name: string
            <br />- required?: boolean
            <br />- autoSelectOnFocus?: boolean
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ setFieldValue, errors, values, touched }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <AmountInput
                      value={values.testField}
                      onChange={(val: string) => {
                        setFieldValue('testField', val);
                      }}
                      label="Example Price"
                      name="testField"
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;AmountInput
                    <br />
                    &nbsp;&nbsp;&nbsp;value=&#123;values.testField&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;onChange=&#123;(val: string) =&gt; &#123;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setFieldValue('testField', val);
                    <br />
                    &nbsp;&nbsp;&nbsp;&#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Example Price"
                    <br />
                    &nbsp;&nbsp;&nbsp;name="testField"
                    <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
