export interface IAdyenSessionRes {
  amount: { currency: string; value: number };
  countryCode: string;
  expiresAt: string;
  id: string;
  merchantAccount: string;
  mode: string;
  reference: string;
  returnUrl: string;
  sessionData: string;
  shopperEmail: string;
  shopperLocale: string;
  shopperReference: string;
  splitCardFundingSources: boolean;
  threeDSAuthenticationOnly: boolean;
}
export interface IAdyenDetails {
  isOnboarded: boolean;
  data: IAdyenDetailsData[];
}

export interface IAdyenDetailsData {
  enabled: boolean;
  allowed: boolean;
  verificationStatus: string;
  problems: IAdyenDetailsProblems[];
  label: string;
}

export interface IAdyenDetailsProblems {
  message: string;
  remediatingActions: string[];
}

export interface IAdyenTransactionSession {
  id: string;
  token: string;
}

export enum AdyenVerificationStatus {
  Valid = 'valid',
  Invalid = 'invalid',
  Pending = 'pending',
  Rejected = 'rejected',
}
