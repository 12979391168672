import { FC } from 'react';
import {
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  styled,
  Box,
  ToggleButtonGroupProps,
} from '@mui/material';
import { Label } from '../label';

interface IToggleButtonGroupProps extends ToggleButtonGroupProps {
  value: any | null;
  onChange: (e: React.MouseEvent<HTMLElement>, newValue: any | any[]) => void;
  fullWidth?: boolean;
  id?: string;
  children: React.ReactNode | React.ReactElement<typeof ToggleButton>[];
  label?: string;
}

interface IToggleButtonGroupStyles {
  fullWidth?: boolean;
}

export const ToggleButtonGroup: FC<IToggleButtonGroupProps> = ({
  value,
  label,
  onChange,
  fullWidth,
  id,
  children,
  ...props
}) => {
  return (
    <Box>
      {label && id && (
        <Label labelFor={id} sx={{ position: 'relative', top: fullWidth ? '-.15rem' : 0 }}>
          {label}
        </Label>
      )}
      <StyledToggleButtonGroup
        value={value}
        color="primary"
        sx={{ padding: 0.1, width: fullWidth ? '100%' : 'auto', flexWrap: 'wrap' }}
        aria-label={label}
        onChange={(e: React.MouseEvent<HTMLElement>, newValue: any[]) => {
          onChange(e, newValue);
        }}
        id={id}
        fullWidth={fullWidth}
        {...props}
      >
        {children}
      </StyledToggleButtonGroup>
    </Box>
  );
};

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup, {
  shouldForwardProp: prop => prop !== 'fullWidth',
})<IToggleButtonGroupStyles>(({ theme, fullWidth }) => ({
  [`& .MuiToggleButton-root`]: {
    flex: fullWidth ? 1 : undefined,
    [theme.breakpoints.down('sm')]: {
      borderRadius: fullWidth ? 0 : undefined,
      borderLeftColor: fullWidth ? 'rgba(0,0,0,.172)' : undefined,
    },
  },
}));
