// Standard React
import React, { FC, Fragment, useContext } from 'react';
import clsx from 'clsx';
// Router
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ExternalLink } from '../link';
// Components
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  darken,
  styled
} from '@mui/material';
// Hooks
import { useFlags } from 'launchdarkly-react-client-sdk';
// Types
import { IRoute, ISubRoute, IUser } from '../../models';
// Utilities
import { getRelativeLink, hasNavAccess, hasNavActiveFlags, hasNavPermissions } from '../../helpers';
//Material UI
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { UserContext } from '../../context';
import { ROLES } from '../../constants';

interface ISubNavStyles {
  isNavOpen: boolean;
  isMobile: boolean;
  isSubNavOpen: any;
};

/**
 * Checks to see if there are any active subnav links with the correct access and permissions and feature flags
 * @param subNav
 * @param featureFlags
 * @param user
 * @returns
 */
const filterSubNav = (subNav: ISubRoute[] | null | undefined, featureFlags: any, user?: IUser) =>
  subNav
    ?.filter(link => hasNavAccess(link, user!))
    .filter(link => hasNavPermissions(link, user!))
    .filter(link => {
      // check here if the link has a property `featureFlagCheck` then check it is active for FF
      // i.e. v2InventoryReport page
      if (link?.featureFlagCheck === undefined || link?.featureFlagCheck === null) {
        return true; // include link by default
      }
      if (link?.featureFlagCheck) {
        return hasNavActiveFlags(link, featureFlags); // include if flag is true, otherwise exclude altogether
      }
      return !hasNavActiveFlags(link, featureFlags); // include if flag is false, otherwise exclude altogether
    });

export const SubNavMenu: FC<{
  link: IRoute;
  isNavOpen: boolean;
  showText: boolean;
  isSubNavOpen?: any;
  setSubNavOpen?: any;
  isMobile: boolean;
  setShowNav: (b: boolean) => void;
}> = ({ link, isNavOpen, showText, isSubNavOpen, setSubNavOpen, isMobile, setShowNav }) => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const history = useHistory();

  const featureFlags = useFlags();
  const hasFFOff =
    link.legacyUrl &&
    link.featureFlags &&
    !hasNavActiveFlags(link, featureFlags) &&
    user?.userType !== ROLES.Emulating;
  const hasEmptySubLinks = link?.subNav
    ? filterSubNav(link?.subNav, featureFlags, user)?.length === 0
    : false;

  if (hasEmptySubLinks) {
    return null;
  }
  return (
    <SubNavWrapper
      isNavOpen={isNavOpen}
      isMobile={isMobile}
      isSubNavOpen={isSubNavOpen[link.label]}
      key={link.label}
    >
      <Tooltip title={!isNavOpen ? link.label : ''} placement="right-end">
        <ListItemButton
          component="li"
          onClick={() => {
            setSubNavOpen({ [link.label]: !isSubNavOpen[link.label] });
          }}
          className={classes.listItem}
        >
          {link.icon && (
            <ListItemIcon
              onClick={() => history.push(link?.link)}
              className={clsx(pathname === link.link && classes.activeIcon)}
              classes={{ root: classes.listItemRootTopLevel }}
            >
              {link.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <>
                {hasFFOff && (
                  <ExternalLink
                    className={clsx(classes.drawerLink, pathname === link.link && 'active')}
                    to={link?.legacyUrl ?? ''}
                  >
                    {showText ? link.label : ''}
                  </ExternalLink>
                )}
                {!hasFFOff && link.link && (
                  <Link
                    to={getRelativeLink(link.link)}
                    className={clsx(classes.drawerLink, pathname === link.link && 'active')}
                  >
                    {showText ? link.label : ''}
                  </Link>
                )}
                {!link.link && !hasFFOff && <>{showText ? link.label : ''}</>}
              </>
            }
            className={classes.listItemTextTopLevel}
          />
          {!hasFFOff && <>{isSubNavOpen[link.label] ? <ExpandLess /> : <ExpandMore />}</>}
        </ListItemButton>
      </Tooltip>
      <Collapse in={isSubNavOpen[link.label]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {link?.subNav &&
            filterSubNav(link?.subNav, featureFlags, user)?.map((subLink, index) => {
              const isLegacy =
                (subLink.legacyUrl &&
                  subLink.featureFlags &&
                  !hasNavActiveFlags(subLink, featureFlags)
                  ? true
                  : false) ||
                (subLink.legacyUrl && !subLink.link);
              let subLinkUrl = isLegacy && subLink.legacyUrl ? subLink.legacyUrl : subLink.link;
              const ToolTipListItem = () => (
                <Tooltip title={!isNavOpen ? subLink.label : ''} placement="right-end">
                  <ListItemButton component="li" className={classes.subNested}>
                    <ListItemIcon
                      className={clsx(
                        pathname === subLink.link && classes.activeIcon
                      )}
                      classes={{ root: classes.listItemIconRoot }}
                    >
                      {subLink.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={showText ? subLink.label : ''}
                      className={classes.subListItemText}
                    />
                  </ListItemButton>
                </Tooltip>
              );
              if (subLink.link.startsWith('https') || isLegacy) {
                return (
                  <Fragment key={`${index}`}>
                    <ExternalLink to={subLinkUrl} target={subLink?.isNewTab ? '_blank' : undefined}>
                      <ToolTipListItem />
                    </ExternalLink>
                  </Fragment>
                );
              }
              if (subLink.link) {
                return (
                  <Fragment key={`${index}`}>
                    <Link
                      to={getRelativeLink(subLinkUrl)}
                      className={clsx(classes.drawerLink, pathname === subLink.link && 'active')}
                      onClick={() => {
                        // on mobile close the side nav so user can see
                        if (isMobile) {
                          setShowNav(false);
                        }
                      }}
                    >
                      <ToolTipListItem />
                    </Link>
                  </Fragment>
                );
              }
              return null;
            })}
        </List>
      </Collapse>
    </SubNavWrapper>
  );
};

const PREFIX = 'SubNavMenu';

const classes = {
  subNested: `${PREFIX}-subNested`,
  listItem: `${PREFIX}-listItem`,
  listItemText: `${PREFIX}-listItemText`,
  listItemTextTopLevel: `${PREFIX}-listItemTextTopLevel`,
  subListItemText: `${PREFIX}-subListItemText`,
  drawerLink: `${PREFIX}-drawerLink`,
  listItemRootTopLevel: `${PREFIX}-listItemRootTopLevel`,
  listItemIconRoot: `${PREFIX}-listItemIconRoot`,
  activeIcon: `${PREFIX}-activeIcon`
};

const SubNavWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isNavOpen' && prop !== 'isSubNavOpen' && prop !== 'isMobile'
})<ISubNavStyles>(({ theme, isNavOpen, isMobile, isSubNavOpen }) => ({
  [`& .${classes.subNested}`]: {
    paddingLeft: 30,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
      color: theme.palette.secondary.main,
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
      '& svg': {
        color: theme.palette.secondary.main,
      },
      '& span': {
        color: theme.palette.secondary.main,
      },
    },
  },

  [`& .${classes.listItem}`]: {
    color: theme.palette.common.white,
    padding: '8px 24px 8px 16px',
    '&:hover': {
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
      color: theme.palette.secondary.main,
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
      '& svg': {
        color: theme.palette.secondary.main,
      },
      '& a': {
        color: theme.palette.secondary.main,
      },
      '& > div > span': {
        color: theme.palette.secondary.main,
      },
    },
  },

  [`& .${classes.listItemText}`]: {
    marginLeft: theme.spacing(1),
    color: 'white',
    '& .MuiListItemText-primary': {
      fontSize: 16,
      display: (isNavOpen ? 'inline-block' : 'none'),
    },
  },

  [`& .${classes.listItemTextTopLevel}`]: {
    whiteSpace: (isNavOpen ? 'normal' : 'nowrap'),
    '& span': {
      fontSize: 13,
      fontWeight: 600,
      color:
        isSubNavOpen ? theme.palette.secondary.main : theme.palette.common.white,
    },
    '& .MuiListItemText-primary': {
      display: isNavOpen ? 'inline-block' : 'none',
    },
  },

  [`& .${classes.subListItemText}`]: {
    '& span': { fontSize: 13, fontWeight: 600, color: theme.palette.common.white },
    '& .MuiListItemText-primary': {
      whiteSpace: (isNavOpen ? 'normal' : 'nowrap'),
      display: isNavOpen ? 'inline-block' : 'none',
    },
  },

  [`& .${classes.drawerLink}`]: {
    display: 'flex',
    color: theme.palette.common.white,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
      '& > div > span': {
        color: theme.palette.secondary.main,
      },
    },
    '&.active': {
      color: darken(theme.palette.primary.main, 0.3),
      fontWeight: 'bold',
      '& span': {
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
      },
    },
  },

  [`& .${classes.listItemRootTopLevel}`]: {
    minWidth: !isNavOpen && !isMobile ? 20 : 35,
    color:
      isSubNavOpen ? theme.palette.secondary.main : theme.palette.common.white,
  },

  [`& .${classes.listItemIconRoot}`]: {
    minWidth: !isNavOpen && !isMobile ? 20 : 35,
    color: theme.palette.common.white,
  },

  [`& .${classes.activeIcon}`]: {
    color: theme.palette.secondary.main,
  }
}));