import { FC } from 'react';
import { styled } from '@mui/material';
import {
  ICalendarEventDetail,
  ICalendarDateRange,
  ITimelessEventChange,
  ICalendarChanges,
  IScheduledWorkDragChangeDetails,
  ICalendarView,
  IDayViewSettings,
  IMonthViewSettings,
  IWeekViewSettings,
} from '../../../../models';
import { UnscheduledWork } from './UnscheduledWork';
import { ScheduledWork } from './ScheduledWork';
import { IColorSetMap } from '../../../../models/colors';

export interface DetailedWorkViewProps {
  userColorMap?: IColorSetMap;
  scheduledWorkList: ICalendarEventDetail[];
  unscheduledWorkList: ICalendarEventDetail[];
  onUnscheduledWorkChange?: (change: ITimelessEventChange<ICalendarEventDetail>) => unknown;
  onScheduledWorkChange?: (change: IScheduledWorkDragChangeDetails) => unknown;
  changes?: ICalendarChanges;
  dateRange: ICalendarDateRange;
  view: ICalendarView;
  daySettings?: IDayViewSettings;
  workWeekSettings?: IWeekViewSettings;
  weekSettings?: IWeekViewSettings;
  monthSettings?: IMonthViewSettings;
}

export const DetailedWorkView: FC<DetailedWorkViewProps> = ({
  userColorMap,
  scheduledWorkList,
  unscheduledWorkList,
  dateRange,
  onUnscheduledWorkChange,
  onScheduledWorkChange,
  changes,
  view,
  daySettings,
  workWeekSettings,
  weekSettings,
  monthSettings,
}) => {

  const hasUnscheduledWork = !!unscheduledWorkList.length;
  const hasScheduledWork = !!scheduledWorkList.length;

  return (
    (<>
      {hasScheduledWork && (
        <div style={{ width: '100%' }}>
          <ScheduledWork
            userColorMap={userColorMap}
            dateRange={dateRange}
            scheduledWorkItems={scheduledWorkList}
            changes={changes}
            onChange={onScheduledWorkChange}
            view={view}
            daySettings={daySettings}
            monthSettings={monthSettings}
            workWeekSettings={workWeekSettings}
            weekSettings={weekSettings}
          />
        </div>
      )}
      {hasUnscheduledWork && (
        <UnscheduledWorkContainer>
          {hasScheduledWork && <div className={classes.unscheduledWorkStrip}>Unscheduled Work</div>}
          <UnscheduledWork
            userColorMap={userColorMap}
            dateRange={dateRange}
            unscheduledWorkItems={unscheduledWorkList}
            changes={changes}
            onDateChange={onUnscheduledWorkChange}
            view={view}
            daySettings={daySettings}
            monthSettings={monthSettings}
            workWeekSettings={workWeekSettings}
            weekSettings={weekSettings}
          />
        </UnscheduledWorkContainer>
      )}
    </>)
  );
};


const PREFIX = 'DetailedWorkView';

const classes = {
  employeeUnscheduledWorkContainer: `${PREFIX}-employeeUnscheduledWorkContainer`,
  unscheduledWorkStrip: `${PREFIX}-unscheduledWorkStrip`
};

const UnscheduledWorkContainer = styled('div')(({ theme }) => ({

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '@media print': {
    display: 'table',
    width: '100%',
  },
  '.print-calendar--stack &&': {
    '@media print': {
      display: 'block',
    },
  },

  [`& .${classes.unscheduledWorkStrip}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    fontWeight: 600,
    padding: 8,
    color: 'white',
    boxShadow: '0px 3px 5px 0px grey',
    '@media print': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      display: 'table-caption',
      width: '100%',
    },
  }
}));