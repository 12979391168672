import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, useMediaQuery, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { Loader, SimpleDataGrid, Card, CardTitle } from '../../components';
import { ImageModal } from '../../components/file/image-modal';
import { MultiImageUpload } from '../../components/file/multi-image-upload';
import {
  createServicePhotos,
  deleteServicePhoto,
  getServicePhotos,
} from '../../fetch/service-photos';
import { formatDate } from '../../helpers';
import { IScheduledServiceDetail, IServicePhoto } from '../../models';
import clsx from 'clsx';
import { useConfirm } from '../../hooks';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

interface IServiceImages {
  scheduledServiceId: string | number;
  service?: IScheduledServiceDetail | null;
  isAllExpanded?: boolean;
  readOnly?: boolean;
}

export const ServiceImages: FC<IServiceImages> = ({
  scheduledServiceId,
  service,
  isAllExpanded,
  readOnly,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isLoadingServicePhotos, setIsLoadingServicePhotos] = useState(false);
  const [source, setSource] = useState<string>('');
  const [servicePhotos, setServicePhotos] = useState<IServicePhoto[]>([]);

  const fetchServicePhotos = async () => {
    setIsLoadingServicePhotos(true);
    try {
      const res = await getServicePhotos(scheduledServiceId);
      setServicePhotos(res);
    } catch (error) {
      enqueueSnackbar(`Error loading service photos, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingServicePhotos(false);
    }
  };

  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const uploadServicePhoto = async (payload: File, photo: IServicePhoto) => {
    setIsUploadingPhoto(true);
    try {
      await createServicePhotos(scheduledServiceId, payload, photo);
      enqueueSnackbar(`Service photo uploaded!`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Error uploading service photos, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsUploadingPhoto(false);
    }
  };
  useEffect(() => {
    fetchServicePhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isDeletingPhoto, setIsDeletingPhoto] = useState(false);
  const removeServicePhoto = async (servicePhotoId: string) => {
    setIsDeletingPhoto(true);
    try {
      await deleteServicePhoto(scheduledServiceId, servicePhotoId);
      enqueueSnackbar(`Service photo deleted!`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Error uploading service photos, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeletingPhoto(false);
      await fetchServicePhotos();
    }
  };

  const thumbnailButton = (val: IServicePhoto, imgStyles?: React.CSSProperties | undefined) => {
    return (
      <StyledImgbutton
        onClick={() => {
          setSource(val.urlPath);
          setisModalOpen(true);
        }}
        variant="text"
      >
        <img src={val.urlPath} alt={val.title} style={{ width: '100%', ...imgStyles }} />
      </StyledImgbutton>
    );
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: '',
        field: 'thumbnail',
        disableColumnMenu: true,
        flex: 1,
        maxWidth: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IServicePhoto>) => {
          const { row: original } = params;
          return thumbnailButton(original);
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IServicePhoto>) => {
          const { row: photo } = params;

          return (
            (
              <Button
                variant="text"
                disabled={isUploadingPhoto || isLoadingServicePhotos || isDeletingPhoto}
                onClick={() => {
                  setSource(photo.urlPath);
                  setisModalOpen(true);
                }}
                sx={{ padding: 0 }}
              >
                {photo.title}
              </Button>
            ) || '--'
          );
        },
      },
      {
        headerName: 'Upload Date',
        field: 'whenTaken',
        disableColumnMenu: true,
        minWidth: 125,
        renderCell: (params: GridRenderCellParams<IServicePhoto>) => {
          const { row: original } = params;
          return !!original.whenTaken ? <div>{formatDate(original.whenTaken)}</div> : <Loader />;
        },
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        maxWidth: 300,
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IServicePhoto>) => {
          const { row: original } = params;
          return (
            service?.status !== 'Closed' &&
            !readOnly && (
              <div className={clsx('print--none')}>
                <IconButton
                  color="error"
                  title="Delete Photo"
                  disabled={
                    isUploadingPhoto ||
                    isLoadingServicePhotos ||
                    isDeletingPhoto ||
                    servicePhotos.some(photo => !photo.whenTaken)
                  }
                  onClick={async () => {
                    const result = await confirm('Are you sure you want to delete this?');
                    if (result) {
                      removeServicePhoto(original.servicePhotoId);
                    } else {
                      return;
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
              </div>
            )
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [servicePhotos, isLoadingServicePhotos, readOnly]
  );

  return (
    <Card>
      <CardTitle
        title="Service Photo"
        withExpand
        mobileWrap
        initialExpand={false}
        overrideExpand={isAllExpanded}
        action={
          !readOnly ? (
            <MultiImageUpload
              buttonText="Upload Image"
              handleFileChange={async val => {
                setServicePhotos(prev => [...prev, ...val]);
                await uploadServicePhoto(val?.[0]?.file, val?.[0]);
                await fetchServicePhotos();
              }}
              images={servicePhotos.map(photo => photo.urlPath)}
              disabled={
                isUploadingPhoto ||
                isLoadingServicePhotos ||
                isDeletingPhoto ||
                servicePhotos?.length === 1
              }
              buttonSize="small"
              buttonColor="secondary"
              isButtonFullWidth={isMobile}
            />
          ) : undefined
        }
      >
        <div className="print--only">
          <label className="print--shrink-label MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled css-ahtaft-MuiFormLabel-root-MuiInputLabel-root">
            Service Photo
          </label>
        </div>
        <SimpleDataGrid
          columns={columns}
          rows={servicePhotos}
          getRowId={(row: IServicePhoto) => row.servicePhotoId}
          columnHeaderHeight={36}
          hasMobileLayout
          mobileProps={{
            mobileCustomDefaultAccessor: (val: IServicePhoto) => {
              return thumbnailButton(val, { height: '45px' });
            },
            handleDelete:
              service?.status !== 'Closed' && !readOnly
                ? async (val: IServicePhoto) => {
                    const result = await confirm('Are you sure you want to delete this?');
                    if (result) {
                      removeServicePhoto(val.servicePhotoId);
                    } else {
                      return;
                    }
                  }
                : undefined,
            handleDeleteDisabled:
              isUploadingPhoto ||
              isLoadingServicePhotos ||
              isDeletingPhoto ||
              servicePhotos.some(photo => !photo.whenTaken),
          }}
        />
        <ImageModal isOpen={isModalOpen} onClose={() => setisModalOpen(false)} source={source} />
      </CardTitle>
    </Card>
  );
};

const StyledImgbutton = styled(Button)(({ theme }) => ({
  padding: 0,
  height: 80,
  maxWidth: 80,
  overflow: 'hidden',
  position: 'relative',
  margin: 0,
  borderRadius: 0,
}));
