import { Grid, Box, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';
import { Form, useFormikContext } from 'formik';
import { FC } from 'react';
import {
  TextField,
  ModalSaveSection,
  ConfirmPrompt,
  Loader,
  DatePicker,
  AmountInput,
} from '../../../components';
import { formatMoney, isInvalidDateRange } from '../../../helpers';
import { useConfirm } from '../../../hooks';
import { defaultUnsavedChangesMessage } from '../../../constants';
import { MultipleStringInput } from '../../../components/multiple-string-input';
import { InventoryCoreFields } from './inventory-core-fields';

interface IInventoryModalDetails {
  handleClose: (shouldUpdate?: boolean) => void;
}

export const InventoryModalDetails: FC<IInventoryModalDetails> = ({ handleClose }) => {
  const {
    isSubmitting,
    handleSubmit,
    handleBlur,
    isValid,
    dirty,
    values,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
  } = useFormikContext<any>();
  const confirm = useConfirm();
  return (
    <Box mt={1}>
      <ConfirmPrompt
        when={dirty && !isSubmitting && Object.keys(touched).length > 0}
        message={defaultUnsavedChangesMessage}
      />
      <Form onSubmit={handleSubmit}>
        {isSubmitting && <Loader position="centered" type="overlay" />}
        <Grid container spacing={2}>
          <InventoryCoreFields isDisabled={!!values.poolCommerceInventoryId} />
          <Grid item xs={12} md={4}>
            <MultipleStringInput
              fullWidth
              size="small"
              label="Aliases"
              value={values.aliases}
              hasError={touched.aliases && !!errors.aliases}
              helperText={touched.aliases && errors.aliases ? errors.aliases : ''}
              onChange={(_e, newAliasesValue) => {
                setFieldValue('aliases', newAliasesValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="cost"
              label="Cost"
              placeholder="$0.00"
              onBlur={e => {
                handleBlur(e);
                setFieldValue('cost', formatMoney(e.target.value, 2));
              }}
              disabled={!!values.poolCommerceInventoryId}
              required={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AmountInput
              name="price"
              label="Price"
              value={values.price}
              onChange={val => {
                setFieldValue('price', val);
              }}
              required={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <DatePicker
              label="Sale Start Date"
              value={values.saleStartDate ? new Date(values.saleStartDate) : null}
              onChange={(date: any) => {
                setFieldValue('saleStartDate', date);
                setFieldTouched('saleStartDate', true);
              }}
              maxDate={values.saleEndDate ? new Date(values.saleEndDate) : undefined}
              slotProps={{
                textField: {
                  error:
                    !!errors.saleStartDate ||
                    isInvalidDateRange(values.saleEndDate, values.saleStartDate, false),
                  size: 'small',
                  fullWidth: true,
                },
              }}
            />
            {errors.saleStartDate && touched.saleStartDate && (
              <FormHelperText error>{errors.saleStartDate}</FormHelperText>
            )}
            {isInvalidDateRange(values.saleEndDate, values.saleStartDate, false) && (
              <FormHelperText error>
                Invalid Date Range. Start Date must be after End Date.
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              label="Sale End Date"
              value={values.saleEndDate ? new Date(values.saleEndDate) : null}
              onChange={(date: any) => {
                setFieldValue('saleEndDate', date);
                setFieldTouched('saleEndDate', true);
              }}
              error={
                !!errors.saleEndDate ||
                isInvalidDateRange(values.saleEndDate, values.saleStartDate, false)
              }
              minDate={values.saleStartDate ? new Date(values.saleStartDate) : undefined}
              slotProps={{
                textField: {
                  error:
                    !!errors.saleStartDate ||
                    isInvalidDateRange(values.saleEndDate, values.saleStartDate, false),
                  size: 'small',
                  fullWidth: true,
                },
              }}
            />
            {errors.saleEndDate && touched.saleEndDate && (
              <FormHelperText error>{errors.saleEndDate}</FormHelperText>
            )}
            {isInvalidDateRange(values.saleEndDate, values.saleStartDate, false) && (
              <FormHelperText error>
                Invalid Date Range. Start Date must be after End Date.
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <AmountInput
              name="salePrice"
              label="Sale Price"
              value={values.salePrice}
              onChange={val => {
                setFieldValue('salePrice', val);
              }}
              required={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={<Checkbox checked={values.isTaxable} />}
              label="Taxable"
              onChange={(_e, checked) => setFieldValue('isTaxable', checked)}
            />
          </Grid>
        </Grid>
        <ModalSaveSection
          handleCancel={async () => {
            if (dirty && Object.keys(touched).length > 0) {
              const result = await confirm(defaultUnsavedChangesMessage);
              if (result) {
                return handleClose();
              }
              return;
            }
            handleClose();
          }}
          isSaveDisabled={
            !isValid ||
            !dirty ||
            isSubmitting ||
            isInvalidDateRange(values.saleEndDate, values.saleStartDate, false)
          }
        />
      </Form>
    </Box>
  );
};
