import { axios } from '../helpers';
import { IAdyenSessionRes, IAdyenDetails, IAdyenTransactionSession } from '../models';
import { userSignal } from '../signals';
import qs from 'querystring';

export const getAdyenPaymentSession = async (
  payload: {
    amount: number;
    accountId: string;
    repairId: string;
    reference: string;
    transactionId: string | null;
    isForSecurityDeposit?: boolean;
    isForAdditionalSecurityDeposit?: boolean;
  },
  token?: string
): Promise<IAdyenSessionRes> => {
  try {
    const { data } = await axios.post(`/api/adyen/payment-session`, payload, {
      headers: !!token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAdyenRecurringPaymentSession = async (
  payload: {
    accountId: string;
    amount: number;
    reference: string;
    payBalance: boolean;
    showRemovePaymentMethodButton: boolean;
  },
  token?: string
): Promise<IAdyenSessionRes> => {
  try {
    const { data } = await axios.post(`/api/adyen/recurring-payment-session`, payload, {
      headers: !!token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createAdyenPayment = async (
  payload: {
    amount: number;
    accountId: string;
    transactionId?: string;
    reference: string;
  },
  token?: string
): Promise<any> => {
  try {
    const { data } = await axios.post(`/api/adyen/payment`, payload, {
      headers: !!token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteAdyenSavedPaymentMethod = async (
  accountId: string,
  storedPaymentMethodId: string
): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/adyen/recurring-payment`, {
      data: {
        accountId,
        storedPaymentMethodId,
      },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAdyenHostedOnboarding = async (payload: {
  officeId: string;
  redirectPath?: string;
}): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/adyen/create-onboarding-link`, payload);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAdyenDetails = async (officeId: string): Promise<IAdyenDetails> => {
  try {
    const { data } = await axios.get(`/api/adyen/details`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getAdyenTransactionSession = async (payload: {
  officeId: string;
}): Promise<IAdyenTransactionSession> => {
  try {
    const { data } = await axios.post(`/api/adyen/transaction-session?OfficeId=${payload.officeId}`);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
