import { axios } from '../helpers';
import qs from 'querystring';
import { IServiceDefinitionChecklist, IResponse } from '../models';
import { userSignal } from '../signals';

export const getServiceDefinitionChecklist = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  serviceDefId: string | null;
  officeId?: string | null;
}): Promise<IResponse<IServiceDefinitionChecklist[]>> => {
  try {
    const { data } = await axios.get(`/api/service-definition-checklists`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleServiceDefinitionChecklistItem = async (
  id: string | number,
  officeId?: string | null
): Promise<IServiceDefinitionChecklist> => {
  try {
    const { data } = await axios.get(`/api/service-definition-checklists/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createServiceDefinitionChecklistItem = async (
  body: IServiceDefinitionChecklist,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/service-definition-checklists`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateServiceDefinitionChecklistItem = async (
  body: IServiceDefinitionChecklist,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(
      `/api/service-definition-checklists/${body.serviceDefChecklistItemId}`,
      {
        ...body,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      }
    );

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteServiceDefinitionChecklistItem = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/service-definition-checklists/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
