import { Box, FormHelperText, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { differenceInDays, endOfDay, startOfDay } from 'date-fns';
import { FC, useState } from 'react';
import { IFilterInputProps } from '../../../../models';
import { isValidDate } from '../../../../helpers';

export const DateRangeFilter: FC<IFilterInputProps> = ({ filter, values, onChange, filters }) => {
  const { name, label } = filter;
  const [isInvalidStartDate, setIsInvalidStartDate] = useState(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState(false);
  const [startDate = '', endDate = ''] = values;

  const hasMultipleDateRanges =
    (filters && filters?.filter(f => f.type === 'DateRange')?.length > 1) ?? false;

  const isSameDate = (start: string, end: string) => {
    const diff = differenceInDays(new Date(end), new Date(start));
    return diff === 0;
  };

  const handleStartDateChange = (date: Date | null) => {
    if (isValidDate(date)) {
      setIsInvalidStartDate(false);
      date!.setHours(0, 0, 0, 0);
      const value = date ? date.toISOString() : '';

      if (isSameDate(value, endDate)) {
        onChange(
          [startOfDay(new Date(value)).toISOString(), endOfDay(new Date(value)).toISOString()],
          filter
        );
      } else {
        onChange([value, endDate], filter);
      }
    } else {
      if (date === null) {
        onChange(['', endDate], filter);
        setIsInvalidStartDate(false);
      } else {
        setIsInvalidStartDate(true);
      }
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (isValidDate(date)) {
      setIsInvalidEndDate(false);
      date!.setHours(0, 0, 0, 0);
      const value = date ? date.toISOString() : '';

      if (isSameDate(startDate, value)) {
        onChange(
          [startOfDay(new Date(value)).toISOString(), endOfDay(new Date(value)).toISOString()],
          filter
        );
      } else {
        onChange([startDate, value], filter);
      }
    } else {
      if (date === null) {
        onChange([startDate, ''], filter);
        setIsInvalidEndDate(false);
      } else {
        setIsInvalidEndDate(true);
      }
    }
  };

  let minEndDate: Date | undefined = undefined;

  let invalidRange = false;

  if (startDate && endDate) {
    const diff = differenceInDays(new Date(endDate), new Date(startDate));
    invalidRange = diff < 0;
  }

  return (
    <StyledBox data-testid="DateRangeFilter">
      <Box className={classes.datePickerSection}>
        <DatePicker
          label={hasMultipleDateRanges ? `${label} Start Date` : `Start Date`}
          format="MM/dd/yyyy"
          onChange={handleStartDateChange}
          value={startDate ? new Date(startDate) : null}
          slotProps={{
            textField: {
              inputProps: {
                'data-testid': 'start-date-field',
              },
              name,
              size: 'small',
              error: invalidRange || isInvalidStartDate,
              InputLabelProps: { shrink: true },
              fullWidth: true,
            },
          }}
        />
        {invalidRange && (
          <FormHelperText error>
            Invalid Date Range. Start Date must be after End Date.
          </FormHelperText>
        )}
        {isInvalidStartDate && <FormHelperText error>Invalid Start Date.</FormHelperText>}
        <Box>to</Box>
        <DatePicker
          label={hasMultipleDateRanges ? `${label} End Date` : `End Date`}
          minDate={minEndDate}
          format="MM/dd/yyyy"
          onChange={handleEndDateChange}
          value={endDate ? new Date(endDate) : null}
          slotProps={{
            textField: {
              inputProps: {
                'data-testid': 'end-date-field',
              },
              name,
              size: 'small',
              error: invalidRange || isInvalidEndDate,
              InputLabelProps: { shrink: true },
              fullWidth: true,
            },
          }}
        />
        {isInvalidEndDate && <FormHelperText error>Invalid End Date.</FormHelperText>}
      </Box>
    </StyledBox>
  );
};

const PREFIX = 'DateRangeFilter';

const classes = {
  datePickerSection: `${PREFIX}-datePickerSection`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.datePickerSection}`]: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}));
