import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { SendEmailModal } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuideSendEmailModal = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <StyleGuidePage title="Send Email Modal">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={2}>
            The Send Email Modal is a multipurpose component that can be used to send different
            types emails. Those emails can include an agreement for OTS, payment link and/or custom
            text with the built in WYSIWYG editor. The modal is built on our custom Modal componeunt
            which uses the{' '}
            <Link href="https://mui.com/material-ui/react-dialog/" target="_blank">
              MUI Dialog component
            </Link>
            .
          </Typography>
          <Box mb={2}>
            <code>
              Props:
              <br />- open: boolean
              <br />- onClose: () ={'>'} void
              <br />- onSubmit: (payload: IInitialEmailFormValues) ={'>'} Promise{'<'}unknown{'>'}
              <br />- modalTitle?: string
              <br />- subtitle?: string
              <br />- defaultToAddress?: string[]
              <br />- defaultSubject?: string
              <br />- defaultBody?: string
              <br />- showSendAsAgreementToggle?: boolean
              <br />- sendAsFormalAgreement?: boolean
              <br />- isSendFormalAgreementDisabled?: boolean
              <br />- showPaymentLink?: boolean
              <br />- showCCAddress?: boolean
              <br />- children?: React.ReactNode
              <br />- alertText?: string
            </code>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box mb={2}>
            <Button onClick={() => setModalIsOpen(true)}>Open Send Email Modal</Button>
            <SendEmailModal
              showPaymentLink
              open={modalIsOpen}
              onClose={() => setModalIsOpen(false)}
              modalTitle="Send Email Modal"
              onSubmit={() => Promise.resolve()}
              defaultToAddress={[]}
              showSendAsAgreementToggle={false}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;SendEmailModal
            <br />
            &nbsp;&nbsp;&nbsp;showPaymentLink
            <br />
            &nbsp;&nbsp;&nbsp;open=&#123;modalIsOpen&#125;
            <br />
            &nbsp;&nbsp;&nbsp;onClose=&#123;() =&gt; setModalIsOpen(false)&#125;
            <br />
            &nbsp;&nbsp;&nbsp;modalTitle="Send Email Modal"
            <br />
            &nbsp;&nbsp;&nbsp;onSubmit=&#123;() =&gt; Promise.resolve()&#125;
            <br />
            &nbsp;&nbsp;&nbsp;defaultToAddress=&#123;[]&#125;
            <br />
            &nbsp;&nbsp;&nbsp;showSendAsAgreementToggle=&#123;false&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
