import { stringNumNull } from '../helpers/util';

export interface IEmailTemplateRes {
  records: IEmailTemplate[];
  totalRecordCount: number;
}

export interface IEmailTemplate {
  emailTemplateId: string;
  emailTemplateName: string;
  sortOrder: number;
  emailTemplateEventDescription: string;
  subject: string;
  emailBccs: string [];
}

export interface IEmailTemplateDetailCreate {
  templateName: string;
  emailSubject: string;
  officeId: string;
  emailBccs: string[];
  emailBody: string;
  sortOrder: stringNumNull;
  emailEvent: string;
  campaignId: string;
  emailCampaignId: string;
}

export interface IEmailTemplateDetail {
  emailTemplateName: string;
  subject: string;
  officeId: string;
  emailBccs: string[];
  emailTemplateBody: string;
  sortOrder: string | number;
  emailTemplateEvent: string;
  emailTemplateEventDescription: string;
  emailTemplateId: string;
  isSuppressed: boolean;
  emailTemplateParams: string[];
  campaignId: string;
  emailCampaignId: string;
}

export interface IEmailTemplateFollowUpNote {
  emailTemplateInsertId: string;
  insertName: string | null;
  insertText: string | null;
  sortOrder: number | string;
}

export interface IEmailTemplateFollowUpNotePayload {
  insertName: string | null;
  insertText: string | null;
  sortOrder: number | string;
}

export enum EMAIL_EVENTS {
  PaymentReceipt = 'A',
  PaymentDeclined = 'D',
}
