import {
  FC,
  useState,
  useContext,
  useEffect,
  useCallback,
  forwardRef,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  SendEmailModal,
  SaveButton,
  Loader,
  Card,
  GridDataFetcher,
  useDataGrid,
  Link,
  CancelIcon,
} from '../../components';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faCopy,
  faTrash,
  faPrint,
  faEnvelope,
  faChevronLeft,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { Box, Stack, Button, styled } from '@mui/material';
import { EstimateLineItems } from './estimate-line-items';
import { UserContext } from '../../context';
import {
  IEstimateForm,
  IEstimatePost,
  IAccountDetail,
  IDropdownResponse,
  IEstimateLineItem,
  IEstimateLineItemPost,
  ILeadPost,
  IBillingGroup,
  ISalesTax,
  IUser,
  IAccountSimple,
  IEstimateLaborLineItem,
  IBreadcrumb,
  IOneTimeServiceDetail,
} from '../../models';
import {
  downloadFile,
  scrollToTop,
  formatMoney,
  alphaSort,
  convertToNumber,
  phoneRegExp,
  getCustomerDetailRoute,
} from '../../helpers';
import {
  getEstimate,
  putEstimate,
  postEstimate,
  deleteEstimate,
  getNextAvailableEstimateNumber,
  copyEstimate,
  createInvoiceFromEstimate,
  sendEstimateEmail,
  getEstimateLeadOptions,
  getEstimateLineItems,
  postEstimateLineItem,
  createLead,
  getBillingGroups,
  getSalesTaxes,
  downloadEstimateAgreement,
  createEstimateLaborLineItem,
  getOneTimeService,
} from '../../fetch';
import { LEAD_DETAIL_FORM_SCHEMA } from '../leads/lead-detail-form';
import { useConfirm } from '../../hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  handleCreateNewCustomer,
  handleGetNewCustomerSite,
  getDefaultSalesTaxId,
} from '../customers';
import { addDays } from 'date-fns';
import { ContactDetails } from './contact-details';
import { EstimateDetailsCard } from './estimate-details-card';
import { BrandingContext } from '../../context/branding-context';
import { EstimateTermsConditions } from './estimate-terms-conditions';
import { EstimateAgreementDetails } from './estimate-agreement-details';

const estimateInitialValues = {
  estimateStatus: 'InProgress',
  userId: '',
  siteId: '',
  details: '',
  whenExpires: '',
  whenCreated: '',
  whenInvoiced: '',
  whenAgreementSigned: '',
  invoiceNumber: '',
  serviceTypeId: null,
  leadId: '',
  customAgreementId: null,
  serviceNotes: '',
  securityDeposit: '',
  isCreditCardRequired: true,
  isItemized: false,
  userName: '',
  accountName: '',
  emails: [],
  siteName: '',
  leadCustomerName: '',
  serviceTypeDescription: '',
  customAgreementDescription: '',
  isRefundable: false,
  isVoidable: false,
  additionalTermsAndConditions: '',
  promptForLaborRate: false,
  transactionId: null,
  approvalDate: '',
  invoiceView: '',
  officeId: '',
  accountEmail: '',
  paymentMethodId: '',
  invoiceCreated: false,
  signature: '',
  hasApprovalOverride: false,
  officeName: '',
  poolCommerceInventoryId: '',
};

const CUSTOMER_DETAIL_FORM_SCHEMA = Yup.object().shape({
  //General Info
  accountName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  firstName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  lastName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  //Contact Info
  phone: Yup.string().matches(phoneRegExp, {
    excludeEmptyString: true,
    message: 'Invalid phone number',
  }),
  email: Yup.string().max(255, 'Max 255 characters').email('Email address invalid'),
  addressName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  street: Yup.string().max(255, 'Max 255 characters').required('Required'),
  city: Yup.string().max(255, 'Max 255 characters').required('Required'),
  state: Yup.string().max(255, 'Max 255 characters').required('Required'),
  postalCode: Yup.string().max(255, 'Max 255 characters').required('Required'),
  latitude: Yup.string(),
  longitude: Yup.string(),
  //Billing Info
  billingGroupId: Yup.string().required('Required'),
  salesTaxId: Yup.string().required('Required'),
  status: Yup.string(),
});

const FORM_VALIDATION = Yup.object().shape({
  estimateType: Yup.string(),
  leadId: Yup.mixed().when('estimateType', {
    is: (val: string) => val !== 'ExistingLead',
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Required'),
  }),
  estimateStatus: Yup.string().required('Required'),
  accountId: Yup.mixed().when('estimateType', {
    is: (val: string) => val !== 'Customer',
    then: Yup.string().notRequired().nullable(),
    otherwise: Yup.string().required('Required'),
  }),
  siteId: Yup.mixed().when('estimateType', {
    is: (val: string) => val !== 'Customer',
    then: Yup.string().notRequired().nullable(),
    otherwise: Yup.string().required('Required'),
  }),
  userId: Yup.string().required('Required'),
  whenExpires: Yup.string().required('Required'),
  newLead: Yup.mixed().when('estimateType', {
    is: (val: string) => val !== 'NewLead',
    then: Yup.string().notRequired().nullable(),
    otherwise: LEAD_DETAIL_FORM_SCHEMA,
  }),
  serviceTypeId: Yup.string().nullable(),
  customAgreementId: Yup.string().nullable(),
  serviceNotes: Yup.string().nullable(),
  securityDeposit: Yup.string().nullable(),
  isItemized: Yup.boolean(),
  isCreditCardRequired: Yup.boolean(),
  newCustomer: Yup.mixed().when('estimateType', {
    is: (val: string) => val !== 'NewCustomer',
    then: Yup.string().notRequired().nullable(),
    otherwise: CUSTOMER_DETAIL_FORM_SCHEMA,
  }),
});

interface IEstimateDetailProps {
  currentCustomer?: IAccountDetail | null;
  accountId?: string;
  isCustomerLoading?: boolean;
  redirect: string;
  repairId?: string | null;
  setPageBreadCrumb?: (val: IBreadcrumb | undefined) => void;
  setEstimateNumber?: Dispatch<SetStateAction<string | null | undefined>>;
}

export const EstimateDetail: FC<IEstimateDetailProps> = ({
  isCustomerLoading,
  currentCustomer,
  accountId,
  redirect,
  repairId,
  setPageBreadCrumb,
  setEstimateNumber,
}) => {
  const today = new Date();

  const { defaultEstimateEmailSubject, defaultEstimateEmailBody, agreementName, isPoolService } =
    useContext(BrandingContext);
  const { estimateId }: { estimateId: string } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const leadId = searchParams.get('leadId');
  const siteId = searchParams.get('siteId');
  const correctRepairId = searchParams.get('repairId') || repairId;
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { v2Leads } = useFlags();
  const { user, hasQBInvoiceEntryType } = useContext(UserContext);
  const token = searchParams.get('token');
  const history = useHistory();
  const isNewEstimate = estimateId === 'new';
  const isLeadEstimate = !!leadId || pathname.includes('estimates/');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isCopying, setIsCopying] = useState<boolean>(false);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState<boolean>(false);
  const [isPrinting, setPrinting] = useState(false);
  const [estimateLineItems, setEstimateLineItems] = useState<IEstimateLineItem[]>([]);
  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);
  const [nextAvailableEstimateNumber, setNextAvailableEstimateNumber] = useState<number>();
  const [isLoadingLeads, setIsLoadingLeads] = useState(false);
  const [leadsOptions, setLeadsOptions] = useState<IDropdownResponse[]>([]);
  const [selectedLead, setSelectedLead] = useState<IDropdownResponse | null>(null);
  const [isLoadingEstimate, setIsLoadingEstimate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [shouldSaveEstimate, setShouldSaveEstimate] = useState(false);
  const [estimate, setEstimate] = useState<IEstimateForm>({
    ...estimateInitialValues,
    accountId: accountId ?? '',
  });
  const [ots, setOts] = useState<IOneTimeServiceDetail | null>(null);
  const [isLoadingOts, setIsLoadingOts] = useState(false);
  const handleDelete = async () => {
    const result = await confirm('Are you sure you want to delete this estimate?');
    if (!result) return;

    try {
      setIsDeleting(true);
      await deleteEstimate(estimateId);
      enqueueSnackbar(`Estimate Deleted!`, {
        variant: 'success',
      });
      history.push(redirect);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting estimate, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  };
  const handlePrint = async (estimateId: string) => {
    try {
      setPrinting(true);
      const res = await downloadEstimateAgreement(
        estimateId,
        token as string,
        user?.officeId ?? ''
      );
      let contentType = res.headers['content-type'];

      if (res.data && contentType === 'application/pdf') {
        return downloadFile(
          res.data,
          currentCustomer?.name
            ? `${currentCustomer?.name} - Estimate ${estimate.estimateNumber}.pdf`
            : `Estimate ${estimate.estimateNumber}.pdf`
        );
      }
    } catch (error: any) {
      enqueueSnackbar(
        error?.Detail ?? 'An error occurred while printing the estimate, please try again.',
        {
          variant: 'error',
        }
      );
    } finally {
      setPrinting(false);
    }
  };

  const handleCreateInvoice = async () => {
    try {
      setIsCreatingInvoice(true);
      const invoiceId = await createInvoiceFromEstimate(estimateId);
      history.push(`/billing/invoices/${invoiceId}`);
      enqueueSnackbar('Invoice Created!', {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error creating invoice, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsCreatingInvoice(false);
    }
  };

  const handleSaveLineItems = async (estimateId: string, estimatePayload: IEstimatePost) => {
    const hasNewItems =
      estimateLineItems.filter(val => Number(val.estimateLineItemId) <= 0)?.length > 0;
    if (hasNewItems && estimateId) {
      const laborLineItems = estimateLineItems.filter(item => item.isLaborLineItem);
      const standardLineItems = estimateLineItems.filter(item => !item.isLaborLineItem);
      if (laborLineItems?.length > 0) {
        for (const item of laborLineItems) {
          const payload: IEstimateLaborLineItem = {
            sortOrder: convertToNumber(item.sortOrder),
            laborFeeType: item.laborFeeType ?? null,
            details: item.details,
            fixedLaborRate: convertToNumber(item.fixedLaborRate),
            initialLaborFees: convertToNumber(item.initialLaborFees),
            additionalLaborFees: item.additionalLaborFees
              ? convertToNumber(item.additionalLaborFees)
              : null,
            initialLaborFeesDuration: item.initialLaborFeesDuration ?? '',
            estimatedLaborDuration: item.estimatedLaborDuration
              ? convertToNumber(item.estimatedLaborDuration)
              : null,
            postLaborChargesAs: item.postLaborChargesAs
              ? convertToNumber(item.postLaborChargesAs)
              : null,
          };
          try {
            await createEstimateLaborLineItem(estimateId!, payload);
          } catch (err: any) {
            enqueueSnackbar(
              err?.Detail || `Error saving estimate line item(s). Please try again.`,
              {
                variant: 'error',
              }
            );
          }
        }
      }
      if (standardLineItems?.length > 0) {
        for (const item of standardLineItems) {
          if (item.tranCodeDescription !== 'Tax') {
            const payload: IEstimateLineItemPost = {
              poolCommerceInventoryId: item.poolCommerceInventoryId ?? null,
              sortOrder: Number(item.sortOrder),
              tranCodeId: item.tranCodeId,
              rate: convertToNumber(item.rate),
              quantity: item.quantity,
              details: item.details,
              serialNumber: item.serialNumber,
              inventoryId: item.inventoryId ? Number(item.inventoryId) : null,
            };
            try {
              await postEstimateLineItem(estimateId!, payload);
            } catch (err: any) {
              enqueueSnackbar(
                err?.Detail || `Error saving estimate line item(s). Please try again.`,
                {
                  variant: 'error',
                }
              );
            }
          }
        }
      }
    }
  };

  const handleCreateNewLead = async (newLead: ILeadPost) => {
    const data = {
      ...newLead,
      officeId: user?.officeId,
      date: new Date().toISOString(),
      status: 'New',
      // only send the phone number string with numbrs, i.e. (123) 123-1234 -> 1231231234
      customerPhone: newLead.customerPhone.replace(/\D/g, ''),
    };
    const newLeadId = await createLead(data);
    enqueueSnackbar(`Successfully created lead!`, {
      variant: 'success',
    });
    return newLeadId;
  };

  useEffect(() => {
    if (setPageBreadCrumb) {
      if (Boolean(currentCustomer?.accountId)) {
        // If specific to customer/account
        setPageBreadCrumb({
          text:
            currentCustomer?.name || `${currentCustomer?.firstName} ${currentCustomer?.lastName}`,
          title: 'Back to customer details',
          link: `${getCustomerDetailRoute(currentCustomer?.accountId!)}`,
        });
      }
      // If specific to lead
      if (Boolean(leadId)) {
        return setPageBreadCrumb({
          text: 'Lead',
          title: 'Back to Lead Detail',
          link: `/leads/${leadId}`,
        });
      }
      return setPageBreadCrumb({
        text: 'Estimates',
        title: 'Back to Estimates',
        link: redirect ?? '/estimates',
      });
    }
  }, [currentCustomer, leadId, setPageBreadCrumb, accountId, redirect]);

  const fetchEstimate = async (newEstimateId?: string, showLoading?: boolean) => {
    try {
      if (showLoading) {
        setIsLoadingEstimate(true);
      }
      const response = await getEstimate(newEstimateId ?? estimateId);
      setEstimate(response);
      setEstimateNumber?.(response?.estimateNumber);
      if (response?.leadId) {
        fetchLeadsOptions(response?.leadId as number);
      }
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.Detail || `Error loading estimate. Please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingEstimate(false);
    }
  };

  const fetchNextAvailableEstimateNumber = async () => {
    try {
      const response = await getNextAvailableEstimateNumber(user?.officeId ?? '');
      setNextAvailableEstimateNumber(response);
    } catch (err: any) {
      enqueueSnackbar(
        err?.response?.data?.Detail ||
          `Error loading next available estimate number. Please try again.`,
        { variant: 'error' }
      );
    }
  };

  const fetchLeadsOptions = async (id?: number | null) => {
    setIsLoadingLeads(true);
    try {
      const response = await getEstimateLeadOptions(user?.officeId);
      const formattedOptions =
        response.length > 0
          ? response.map(option => {
              return {
                description: option.text,
                value: option.value,
                shorthand: option.value,
              };
            })
          : [];
      const selectedFromLeadId = formattedOptions.find(item => item.value === leadId?.toString());
      const selectedFromEstimate = formattedOptions.find(
        item => item.value === estimate?.leadId?.toString() || item.value === id?.toString()
      );
      const selected = selectedFromLeadId ?? selectedFromEstimate ?? null;
      setLeadsOptions(formattedOptions);
      setSelectedLead(selected ?? null);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.Detail || `Error loading leads. Please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingLeads(false);
    }
  };

  const dataFetcher: GridDataFetcher<IEstimateLineItem> = useCallback(
    async ({ sortColumn, sortDirection }) => {
      if (!estimateId || estimateId === 'new') return;
      try {
        const res = await getEstimateLineItems(estimateId, {
          perPage: -1,
          page: 1,
          sortBy: sortColumn || '',
          sortDirection: sortDirection || '',
        });

        setEstimateLineItems(res.records);
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading estimate line items, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [estimateId]
  );

  const {
    isLoading: isLoadingLineItems,
    rowCount: recordCount,
    refetch: refetchLineItems,
    page,
    pageSize,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<IEstimateLineItem>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      sortColumn: 'sortOrder',
      sortDirection: 'asc',
      gridKeyName: 'estimate-line-items',
    },
    dataFetcher,
  });

  const { isLoading: isLoadingBillingGroups, data: billingGroups } = useQuery<IBillingGroup[]>(
    ['getBillingGroups', user],
    async () => {
      const res = await getBillingGroups({ perPage: -1, officeId: user?.officeId });
      return alphaSort(res.records, 'description');
    }
  );

  const { isLoading: isLoadingSalesTaxes, data: salesTaxes } = useQuery<ISalesTax[]>(
    ['getSalesTaxes', user],
    async () => {
      const res = await getSalesTaxes({ perPage: -1, officeId: user?.officeId });
      return alphaSort(res.records, 'description');
    }
  );

  useEffect(() => {
    if (!isNewEstimate) {
      fetchEstimate(undefined, true);
    }
    // Only get lead options now if is a new lead estimate (no details loaded)
    // Otherwise, the fetch will be run in fetchEstimate
    if (isLeadEstimate && isNewEstimate) {
      fetchLeadsOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.officeId) {
      fetchNextAvailableEstimateNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.officeId]);

  const handleSave = async (
    values: IEstimateForm,
    shouldRedirect?: boolean,
    actions?: FormikHelpers<IEstimateForm>,
    shouldIgnoreCreatingAgreement?: boolean
  ) => {
    let newEstimateId = null;
    let newLeadId: string | null = null;
    setIsSaving(true);
    try {
      const payload: IEstimatePost = {
        estimateNumber: estimate?.estimateNumber
          ? Number(estimate?.estimateNumber)
          : Number(nextAvailableEstimateNumber),
        estimateStatus: values.estimateStatus,
        userId: values.userId,
        leadId: !!values.leadId ? Number(values.leadId) : null,
        accountId: !!values.accountId ? values.accountId : null,
        siteId: !!values.siteId ? values.siteId : null,
        details: values.details,
        additionalTermsAndConditions: values.additionalTermsAndConditions ?? '',
        whenExpires: new Date(values.whenExpires).toISOString(),
        serviceTypeId: values.serviceTypeId,
        customAgreementId: values.customAgreementId,
        serviceNotes: values.serviceNotes,
        securityDeposit: convertToNumber(values.securityDeposit),
        isCreditCardRequired: values.isCreditCardRequired,
        isItemized: values.isItemized,
        repairId: correctRepairId ?? '',
        ignoreAgreementRequirement:
          shouldIgnoreCreatingAgreement !== undefined ? shouldIgnoreCreatingAgreement : true,
      };
      if (!!values.newCustomer && values.estimateType === 'NewCustomer' && isNewEstimate) {
        const newAccountId = await handleCreateNewCustomer(
          values.newCustomer,
          enqueueSnackbar,
          user as IUser
        );
        const newSiteId = await handleGetNewCustomerSite(newAccountId, user as IUser);

        newEstimateId = await postEstimate({
          ...payload,
          siteId: newSiteId,
          accountId: newAccountId,
        });
        // make sure the warning prompt doesn't fire when creating a new estimate with line items
        if (!shouldRedirect) {
          actions?.resetForm();
          fetchNextAvailableEstimateNumber();
          fetchEstimate(newEstimateId, true);
          history.push(`/estimates/${newEstimateId}`);
        } else if (shouldRedirect) {
          history.push(redirect ?? `/estimates/${newEstimateId}`);
        }
      }
      // If creating New Lead
      else if (values.estimateType === 'NewLead' && !!values.newLead) {
        const newLead: ILeadPost = values.newLead;
        newLeadId = await handleCreateNewLead(newLead);

        if (!isNewEstimate && estimate.estimateId) {
          await putEstimate(estimate.estimateId, {
            ...payload,
            leadId: Number(newLeadId),
          });
        } else {
          newEstimateId = await postEstimate({ ...payload, leadId: Number(newLeadId) });
          await handleSaveLineItems(newEstimateId, payload);
          // make sure the warning prompt doesn't fire when creating a new estimate with line items
          if (!shouldRedirect) {
            actions?.resetForm();
            fetchNextAvailableEstimateNumber();
            fetchEstimate(newEstimateId, true);
          } else if (shouldRedirect) {
            history.push(redirect ?? `/estimates/${newEstimateId}`);
          }
        }
      } else {
        if (!isNewEstimate && estimate.estimateId) {
          await putEstimate(estimate.estimateId, payload);

          if (shouldRedirect) {
            history.push(redirect ?? `/estimates/${newEstimateId}`);
          }
          refetchLineItems();
          fetchEstimate(undefined, true);
        } else {
          newEstimateId = await postEstimate(payload);
          await handleSaveLineItems(newEstimateId, payload);
          // make sure the warning prompt doesn't fire when creating a new estimate with line items
          if (!shouldRedirect) {
            history.push(
              `/estimates/${newEstimateId}/${values.accountId}${
                redirect ? `?redirect=${redirect}` : ''
              }${repairId ? `&repairId=${repairId}` : ''}`
            );
            fetchEstimate(newEstimateId, true);
          } else if (shouldRedirect) {
            history.push(redirect ?? `/estimates/${newEstimateId}`);
          }
        }
      }
      enqueueSnackbar('Estimate saved!', { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err?.Detail || `Error saving estimate. Please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const fetchOts = async () => {
    try {
      setIsLoadingOts(true);
      const res = await getOneTimeService(correctRepairId as string);
      setOts(res);
    } catch (error) {
    } finally {
      setIsLoadingOts(false);
    }
  };
  useEffect(() => {
    if (correctRepairId && isNewEstimate) {
      fetchOts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correctRepairId, isNewEstimate]);

  const isOtsEstimate = useMemo(() => !!estimate?.repairId || !!repairId, [estimate, repairId]);

  return (
    <>
      {isLoadingEstimate && <Loader type="inline" position="centered" />}

      {!isLoadingEstimate && (estimate || nextAvailableEstimateNumber) && (
        <Formik
          initialValues={{
            ...estimate,
            leadId: estimate.leadId ? estimate.leadId : leadId ? leadId : '',
            estimateType: !!leadId ? 'ExistingLead' : 'Customer',
            userId: estimate.userId || user?.userId || '',
            invoiceNumber: estimate.invoiceNumber ?? '',
            whenInvoiced: estimate.whenInvoiced ?? '',
            additionalTermsAndConditions: estimate.additionalTermsAndConditions ?? '',
            newLead: null,
            newCustomer: null,
            siteId: siteId || estimate?.siteId || '',
            serviceTypeId: estimate?.serviceTypeId ?? '',
            customAgreementId: estimate?.customAgreementId ?? '',
            serviceNotes: estimate?.serviceNotes ?? '',
            securityDeposit: estimate?.securityDeposit
              ? formatMoney(estimate?.securityDeposit, 2)
              : '',
            isCreditCardRequired: hasQBInvoiceEntryType
              ? false
              : !!estimate?.repairId || !!repairId
              ? estimate?.isCreditCardRequired ?? true
              : false,
            isItemized: estimate?.isItemized ?? false,
            whenExpires: isNewEstimate
              ? String(addDays(today, 15))
              : estimate?.whenExpires
              ? estimate?.whenExpires
              : '',
          }}
          enableReinitialize={!isNewEstimate}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values, actions) => handleSave(values, false, actions as any)}
        >
          {({
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            const isSaveDisabled =
              !isValid ||
              isSubmitting ||
              isLoadingEstimate ||
              // custom new customer validation check since yup isn't firing
              (values.estimateType === 'NewCustomer' &&
              values.newCustomer &&
              // check the required values are filled out
              Object.values(values.newCustomer).filter(Boolean).length !==
                Object.values(values.newCustomer).length
                ? true
                : false);

            const handleTypeChange = (type: string, account: IAccountSimple | null) => {
              setFieldValue('leadId', '');
              setSelectedLead(null);
              setFieldValue('estimateType', type);
              if (type === 'NewLead') {
                setFieldValue('newLead', {
                  source: '',
                  serviceType: '',
                  description: '',
                  customerFirstName: '',
                  customerLastName: '',
                  customerEmail: '',
                  customerPhone: '',
                  userId: '',
                  addressModel: {
                    address: '',
                    city: '',
                    state: '',
                    postalCode: '',
                  },
                });
              }
              if (type === 'NewCustomer') {
                setFieldValue('newCustomer', {
                  firstName: account?.inputValue?.split(' ')[0],
                  lastName: account?.inputValue?.split(' ')?.[1],
                  accountName: account?.inputValue ?? '',
                  phone: '',
                  email: '',
                  addressName: account?.inputValue ?? '',
                  street: '',
                  city: '',
                  state: '',
                  postalCode: '',
                  billingGroupId:
                    billingGroups?.length === 1 ? billingGroups?.[0].billingGroupId : '',
                  status: 'Inactive',
                  salesTaxId: getDefaultSalesTaxId(salesTaxes),
                });
              } else {
                setFieldValue('newCustomer', null);
              }
            };

            return (
              <Form style={{ height: '100%' }}>
                {(isCustomerLoading ||
                  isDeleting ||
                  isSubmitting ||
                  isSaving ||
                  isCopying ||
                  isCreatingInvoice ||
                  isPrinting ||
                  isLoadingOts) && <Loader position="centered" type="overlay" />}

                {/* Estimate Detail Form */}
                {(!!values.estimateType || !v2Leads) && (
                  <>
                    <Stack gap={2}>
                      <ContactDetails
                        dirty={dirty}
                        isSubmitting={isSubmitting || isSaving}
                        isLeadEstimate={isLeadEstimate}
                        isNewEstimate={isNewEstimate}
                        values={values}
                        isLoadingLeads={isLoadingLeads}
                        v2Leads={v2Leads}
                        handleChange={handleChange}
                        handleTypeChange={handleTypeChange}
                        setFieldValue={setFieldValue}
                        selectedLead={selectedLead}
                        billingGroups={billingGroups}
                        isLoadingBillingGroups={isLoadingBillingGroups}
                        salesTaxes={salesTaxes}
                        isLoadingSalesTaxes={isLoadingSalesTaxes}
                        setSelectedLead={setSelectedLead}
                        leadsOptions={leadsOptions}
                        accountId={accountId ?? estimate?.accountId}
                        repairId={repairId}
                        siteId={siteId ?? values.siteId}
                        isAnEstimate={true}
                      />
                      <EstimateDetailsCard
                        setFieldValue={setFieldValue}
                        values={values}
                        estimate={estimate}
                        errors={errors}
                        touched={touched}
                        isNewEstimate={isNewEstimate}
                        ots={ots}
                      />
                      {/* only shown on ots estimate */}
                      {isOtsEstimate && (
                        <EstimateTermsConditions
                          setFieldValue={setFieldValue}
                          values={values}
                          ots={ots}
                          hasEstimateAgreementBeenSigned={
                            estimate.hasEstimateAgreementBeenSigned ?? false
                          }
                        />
                      )}
                      <EstimateAgreementDetails
                        setFieldValue={setFieldValue}
                        values={values}
                        hasEstimateAgreementBeenSigned={estimate.hasEstimateAgreementBeenSigned}
                        showOtsFields={!!estimate?.repairId || !!repairId}
                        whenInvoiced={estimate.whenInvoiced}
                        handleBlur={handleBlur}
                        agreementStatus={estimate?.agreementStatus}
                        showCreateEstimateButton={
                          !isNewEstimate &&
                          isOtsEstimate &&
                          !estimate.hasEstimateAgreementBeenCreated
                        }
                        isNewEstimate={isNewEstimate}
                        fetchEstimate={() => fetchEstimate(undefined, true)}
                        hasQBInvoiceEntryType={hasQBInvoiceEntryType}
                      />
                      <Card noPaddingBottom>
                        <EstimateLineItems
                          estimateId={isNewEstimate ? null : estimateId}
                          estimateLineItems={estimateLineItems}
                          setEstimateLineItems={setEstimateLineItems}
                          fetchEstimateLineItems={refetchLineItems}
                          isLoading={isLoadingLineItems}
                          recordCount={recordCount}
                          page={page}
                          onPageChange={onPageChange}
                          pageSize={pageSize}
                          onSortModelChange={onSortModelChange}
                          sortModel={sortModel}
                          onPageSizeChange={onPageSizeChange}
                          estimate={estimate}
                          accountId={values.accountId ?? estimate?.accountId}
                          isPoolService={isPoolService}
                        />
                      </Card>
                    </Stack>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection={{
                        xs: 'column',
                        md: 'row',
                      }}
                      justifyContent={{
                        xs: 'center',
                        md: 'space-between',
                      }}
                      className="print--none"
                      mt={2}
                      gap={1}
                    >
                      <Stack
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        gap={1}
                        width={{
                          xs: '100%',
                          sm: 'auto',
                        }}
                      >
                        {estimateId !== 'new' && (
                          <>
                            <StyledButton
                              onClick={async () => {
                                handlePrint(estimateId);
                              }}
                              color="primary"
                              size="small"
                              variant="outlined"
                              startIcon={<FontAwesomeIcon icon={faPrint} size="lg" />}
                              disabled={dirty}
                            >
                              Print
                            </StyledButton>
                            <StyledButton
                              onClick={async () => {
                                setEmailModalIsOpen(true);
                              }}
                              color="primary"
                              size="small"
                              variant="outlined"
                              startIcon={<FontAwesomeIcon icon={faEnvelope} size="lg" />}
                              disabled={!recordCount || dirty}
                            >
                              Email
                            </StyledButton>
                            {/* only show for non OTS Estimates */}
                            {!estimate?.repairId && (
                              <>
                                <StyledButton
                                  onClick={
                                    !!estimate?.transactionId
                                      ? undefined
                                      : () => {
                                          handleCreateInvoice();
                                        }
                                  }
                                  // @ts-ignore
                                  component={
                                    !!estimate?.transactionId
                                      ? forwardRef((props: any, _ref) => {
                                          return <Link {...props} />;
                                        })
                                      : undefined
                                  }
                                  color="primary"
                                  size="small"
                                  to={
                                    !!estimate?.transactionId
                                      ? `/billing/invoices/${estimate?.transactionId}?redirect=/estimates/${estimate.estimateId}`
                                      : undefined
                                  }
                                  variant="outlined"
                                  startIcon={
                                    !!estimate?.transactionId ? (
                                      <FontAwesomeIcon icon={faEye} size="lg" />
                                    ) : (
                                      <FontAwesomeIcon icon={faAdd} size="lg" />
                                    )
                                  }
                                  disabled={!!estimate?.transactionId ? false : dirty}
                                >
                                  {estimate?.transactionId ? 'View' : 'Create'} Invoice
                                </StyledButton>
                                <StyledButton
                                  startIcon={<FontAwesomeIcon icon={faCopy} size="lg" />}
                                  onClick={async () => {
                                    try {
                                      setIsCopying(true);
                                      const newEstimateId = await copyEstimate(estimateId);
                                      history.push(
                                        redirect.includes('/customers')
                                          ? `/customers/${
                                              accountId ?? estimate?.accountId
                                            }/estimates/${newEstimateId}`
                                          : redirect.includes('/leads')
                                          ? `/estimates/${newEstimateId}`
                                          : `${redirect}/${newEstimateId}`
                                      );
                                      await fetchEstimate(newEstimateId);
                                      await fetchNextAvailableEstimateNumber();
                                      enqueueSnackbar('Estimate Copied!', {
                                        variant: 'success',
                                      });
                                      scrollToTop();
                                    } catch (error: any) {
                                      enqueueSnackbar(
                                        error?.Detail ??
                                          `Error copying estimate, please try again.`,
                                        {
                                          variant: 'error',
                                        }
                                      );
                                    } finally {
                                      setIsCopying(false);
                                    }
                                  }}
                                  color="primary"
                                  size="small"
                                  variant="outlined"
                                  disabled={dirty}
                                >
                                  Copy
                                </StyledButton>
                              </>
                            )}
                            {!estimate?.hasEstimateAgreementBeenSigned && (
                              <StyledButton
                                color="error"
                                type="button"
                                onClick={handleDelete}
                                title="Delete Estimate"
                              >
                                <FontAwesomeIcon icon={faTrash} size="lg" />
                                <Box pl={1} component="span">
                                  Delete
                                </Box>
                              </StyledButton>
                            )}
                          </>
                        )}
                      </Stack>
                      <Stack
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        gap={1}
                        width={{
                          xs: '100%',
                          sm: 'auto',
                        }}
                      >
                        <Button
                          color="inherit"
                          startIcon={
                            dirty ? <CancelIcon /> : <FontAwesomeIcon icon={faChevronLeft} />
                          }
                          onClick={() => {
                            history.push(redirect);
                          }}
                        >
                          {dirty ? 'Cancel' : 'Back'}
                        </Button>
                        <SaveButton
                          handleSave={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleSubmit(e as any);
                          }}
                          disabled={isSaveDisabled}
                        />
                        <SaveButton
                          disabled={isSaveDisabled}
                          handleSave={async () => {
                            if (
                              (estimate.agreementRequired || ots?.agreementRequired) &&
                              !estimate.hasEstimateAgreementBeenCreated
                            ) {
                              const result = await confirm('Create and Send Agreement?', {
                                cancellationText: 'Save Without Agreement',
                                cancellationButtonProps: {
                                  color: 'primary',
                                },
                                confirmationText: 'Accept & Save',
                                confirmationButtonProps: {
                                  color: 'secondary',
                                },
                              });
                              if (result) {
                                // pass flag to not ignore the create and sending of agreement
                                return await handleSave(values, true, undefined, false);
                              }
                            }
                            // need to check the agreement is created and in that state and they have line items before we pop the modal
                            if (
                              estimate.hasEstimateAgreementBeenCreated &&
                              estimate?.agreementStatus === 'Created' &&
                              !!recordCount
                            ) {
                              const result = await confirm('Email agreement to Customer?');

                              if (result) {
                                setShouldSaveEstimate(true);
                                return setEmailModalIsOpen(true);
                              }
                            }
                            await handleSave(values, true);
                          }}
                          text={`Save & Close`}
                        />
                      </Stack>
                    </Box>
                    <SendEmailModal
                      open={emailModalIsOpen}
                      sendAsFormalAgreement={
                        estimate?.hasEstimateAgreementBeenCreated
                          ? true
                          : (estimate?.serviceTypeId && Number(estimate?.serviceTypeId) !== 1) ||
                            !!estimate?.customAgreementId ||
                            !!estimate?.repairId
                      }
                      isSendFormalAgreementDisabled={estimate?.hasEstimateAgreementBeenCreated}
                      onClose={() => setEmailModalIsOpen(false)}
                      modalTitle="Send Estimate Email"
                      onSubmit={async formValues => {
                        await sendEstimateEmail(estimateId, {
                          ...formValues,
                          officeId: user?.officeId ?? '',
                          accountId: accountId ?? estimate?.accountId,
                        });
                        // if the estimate modal is triggered via the save and close, then save the estimate after they submit
                        if (shouldSaveEstimate) {
                          handleSave(values, true);
                        }
                        fetchEstimate(undefined, true);
                      }}
                      defaultToAddress={estimate?.emails ?? []}
                      defaultSubject={defaultEstimateEmailSubject.replace(
                        '[AgreementName]',
                        agreementName
                      )}
                      defaultBody={`<p>${defaultEstimateEmailBody
                        .replace(
                          '[AgreementName]',
                          isPoolService ? user?.officeName! : agreementName
                        )
                        .replace(
                          '[ServiceType]',
                          estimate?.serviceTypeDescription
                            ? estimate.serviceTypeDescription
                            : 'Pool Service'
                        )}</p>`}
                    />
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));
