import { FC, useState } from 'react';
import { Card, ITab, Tabs } from '../../../components';
import { UsersTable } from './users-table';
import { Box } from '@mui/material';
import { SuperAdminUsersTable } from './super-admin-users-table';

export const UsersDetail: FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('users');
  return (
    <Card
      cardTitleProps={{
        title: 'Manage Users',
      }}
    >
      <Box mt={-1}>
        <Tabs
          id="super-admin-users"
          isSticky
          color="secondary"
          size="lg"
          backgroundColor="white"
          selectedTab={selectedTab}
          setSelectedTab={val => {
            setSelectedTab(val);
          }}
          tabs={
            [
              {
                key: 'users',
                title: 'Users',
                children: <UsersTable />,
              },
              {
                key: 'super-admin',
                title: 'Super Admins',
                children: <SuperAdminUsersTable />,
              },
            ].filter(Boolean) as ITab[]
          }
        />
      </Box>
    </Card>
  );
};
