import React, { createContext, FC } from 'react';
import { useSearchParams } from '../hooks';

interface ISearchParamsContext {
  searchParams: URLSearchParams | undefined;
  setSearchParams: (params: { [key: string]: string | null | undefined }) => void;
  resetSearchParams: (value: React.SetStateAction<URLSearchParams>) => void;
  queryParams: string;
  paramSiteId: string | null;
  paramSort: string | null;
  paramServiceDefId: string | null;
  paramSortDirection: string | null;
  paramStatus: string | null;
  paramStartDate: string | null;
  paramEndDate: string | null;
  paramUserIds: string | null;
  paramUserGroupId: string | null;
  paramTab: string | null;
  hasSearchParamValues: boolean;
  paramUserId: string | null;
  paramAccountId: string | null;
  paramPriority: string | null;
  paramTaskType: string | null;
  paramCreatedBy: string | null;
  paramWhenCreatedStart: string | null;
  paramWhenCreatedEnd: string | null;
  paramAgreementId: string | null;
  paramServiceTypeId: string | null;
  paramSuppressZeroDollar: string | null;
  paramEstimateType: string | null;
  paramAgreementStatus: string | null;
  paramInvoiceStatus: string | null;
  paramProgress: string | null;
  paramVisitStatus: string | null;
  paramFilterLetter: string | null;
  paramSearchValue: string | null;
  paramCustomView: string | null;
  paramPaymentReference: string | null;
  paramEvent: string | null;
  paramCommissionTypeId: string | null;
  paramDateType: string | null;
  paramName: string | null;
  paramInactive: string | null;
  paramCode: string | null;
  paramStatuses: string | null;
}

interface ISearchParamsContextHandlerProps {
  children: React.ReactNode;
}

export const SearchParamsContext = createContext<ISearchParamsContext>({
  searchParams: undefined,
  setSearchParams: () => {},
  resetSearchParams: () => {},
  queryParams: '',
  paramSiteId: null,
  paramSort: null,
  paramServiceDefId: null,
  paramSortDirection: null,
  paramStatus: null,
  paramStartDate: null,
  paramEndDate: null,
  paramUserIds: null,
  paramUserGroupId: null,
  paramTab: null,
  hasSearchParamValues: false,
  paramUserId: null,
  paramAccountId: null,
  paramPriority: null,
  paramTaskType: null,
  paramCreatedBy: null,
  paramWhenCreatedStart: null,
  paramWhenCreatedEnd: null,
  paramAgreementId: null,
  paramServiceTypeId: null,
  paramSuppressZeroDollar: null,
  paramEstimateType: null,
  paramAgreementStatus: null,
  paramInvoiceStatus: null,
  paramProgress: null,
  paramVisitStatus: null,
  paramFilterLetter: null,
  paramSearchValue: null,
  paramCustomView: null,
  paramPaymentReference: null,
  paramEvent: null,
  paramCommissionTypeId: null,
  paramDateType: null,
  paramName: null,
  paramInactive: null,
  paramCode: null,
  paramStatuses: null,
});

export enum ESearchParams {
  siteId = 'sId',
  sort = 'sort',
  serviceDefId = 'sDefId',
  sortDirection = 'dir',
  status = 'stat',
  startDate = 'sDate',
  endDate = 'eDate',
  userIds = 'uIds',
  userGroupId = 'ugId',
  tab = 'tab',
  userId = 'uId',
  accountId = 'aId',
  priority = 'pri',
  taskType = 'tType',
  createdBy = 'cBy',
  agreementId = 'agId',
  estimateType = 'eType',
  serviceTypeId = 'stId',
  suppressZeroDollar = 'szd',
  whenCreatedStart = 'wCrS',
  whenCreatedEnd = 'wCrE',
  agreementStatus = 'agStat',
  invoiceStatus = 'iStat',
  progress = 'prog',
  visitStatus = 'vStat',
  filterLetter = 'letter',
  searchValue = 'search',
  customView = 'view',
  paymentReference = 'pRef',
  emailTemplateEvent = 'eTEvent',
  commissionTypeId = 'cTypeId',
  dateType = 'dType',
  name = 'name',
  inactive = 'inactive',
  code = 'code',
  statuses = 'statuses',
}

export const SearchParamsContextHandler: FC<ISearchParamsContextHandlerProps> = ({ children }) => {
  const [searchParams, setSearchParams, queryParams, resetSearchParams] = useSearchParams();

  // convert the search params to an array of values
  const arrSearchParamsValues = Array.from(searchParams.keys()).filter(
    p => !p.includes('redirect')
  );
  // Get the value of a specific search param
  const getParamValue = (keyValue: string) => {
    const val = Array.from(searchParams.entries()).find(([key]) => key === keyValue)?.[1];
    return val ?? null;
  };
  return (
    <SearchParamsContext.Provider
      value={{
        searchParams,
        resetSearchParams,
        setSearchParams,
        queryParams,
        // service specific filters
        paramSiteId: getParamValue(ESearchParams.siteId),
        paramServiceDefId: getParamValue(ESearchParams.serviceDefId),
        paramStatus: getParamValue(ESearchParams.status),
        paramUserIds: getParamValue(ESearchParams.userIds),
        paramUserGroupId: getParamValue(ESearchParams.userGroupId),
        // task specific filters
        paramUserId: getParamValue(ESearchParams.userId),
        paramAccountId: getParamValue(ESearchParams.accountId),
        paramPriority: getParamValue(ESearchParams.priority),
        paramTaskType: getParamValue(ESearchParams.taskType),
        // estimates filters
        paramCreatedBy: getParamValue(ESearchParams.createdBy),
        paramWhenCreatedStart: getParamValue(ESearchParams.whenCreatedStart),
        paramWhenCreatedEnd: getParamValue(ESearchParams.whenCreatedEnd),
        paramAgreementId: getParamValue(ESearchParams.agreementId),
        paramServiceTypeId: getParamValue(ESearchParams.serviceTypeId),
        paramSuppressZeroDollar: getParamValue(ESearchParams.suppressZeroDollar),
        paramEstimateType: getParamValue(ESearchParams.estimateType),
        // ots filters
        paramAgreementStatus: getParamValue(ESearchParams.agreementStatus),
        paramInvoiceStatus: getParamValue(ESearchParams.invoiceStatus),
        paramProgress: getParamValue(ESearchParams.progress),
        paramVisitStatus: getParamValue(ESearchParams.visitStatus),
        paramStatuses: getParamValue(ESearchParams.statuses),
        // customer filters
        paramFilterLetter: getParamValue(ESearchParams.filterLetter),
        paramSearchValue: getParamValue(ESearchParams.searchValue),
        paramCustomView: getParamValue(ESearchParams.customView),
        // payments filters
        paramPaymentReference: getParamValue(ESearchParams.paymentReference),
        // email templates filters
        paramEvent: getParamValue(ESearchParams.emailTemplateEvent),
        // commission filters
        paramCommissionTypeId: getParamValue(ESearchParams.commissionTypeId),
        paramDateType: getParamValue(ESearchParams.dateType),
        // store admin
        paramName: getParamValue(ESearchParams.name),
        paramInactive: getParamValue(ESearchParams.inactive),
        paramCode: getParamValue(ESearchParams.code),
        // common filters
        paramTab: getParamValue(ESearchParams.tab),
        paramSort: getParamValue(ESearchParams.sort),
        paramStartDate: getParamValue(ESearchParams.startDate),
        paramEndDate: getParamValue(ESearchParams.endDate),
        paramSortDirection: getParamValue(ESearchParams.sortDirection),
        hasSearchParamValues: arrSearchParamsValues.filter(Boolean).length > 0,
      }}
    >
      {children}
    </SearchParamsContext.Provider>
  );
};
