import { FC } from 'react';
import { Page } from '../../../components';
import { OutboxQueueDetails } from './outbox-messages-details';

export const OutboxMessagesPage: FC = () => {
  return (
    <Page title="Outbox Messages">
      <OutboxQueueDetails />
    </Page>
  );
};
