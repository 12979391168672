import { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Box,
  ButtonBase,
  FormControl,
  FormGroup,
  Grid,
  TextField,
  Typography,
  Button,
  Stack,
  styled,
  ButtonBaseProps,
} from '@mui/material';
import { useFormik } from 'formik';
import { CardTitle, UserAvatar, Card, SaveButton, ResetIcon } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../context/user';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { getBase64FromFile, formatInputPhoneNumber, phoneRegExp } from '../../helpers';
import { getUserFullPhoneNumber } from './utils';

interface IAccountProps {}

interface IAccountValues {
  name: string;
  email: string;
  phone: string;
}

const AccountFormSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email address').required('Required'),
  name: Yup.string().label('Name').required(),
  phone: Yup.string().matches(phoneRegExp, {
    excludeEmptyString: true,
    message: 'Invalid phone number',
  }),
});

export const AccountDetailsForm: FC<IAccountProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, updateCurrentUser, updateCurrentUserAvatar, userAvatarUrl } =
    useContext(UserContext);
  const [newAvatarUrl, setNewAvatarUrl] = useState<string>(userAvatarUrl ?? '');
  const [newAvatarFile, setNewAvatarFile] = useState<File>();
  const avatarInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setNewAvatarUrl(userAvatarUrl ?? '');
  }, [setNewAvatarUrl, userAvatarUrl]);

  const reset = (
    values: IAccountValues = {
      name: user?.userName || '',
      email: user?.loginName || '',
      phone: getUserFullPhoneNumber(user),
    }
  ) => {
    resetForm({
      values,
    });
    setNewAvatarFile(undefined);
    if (avatarInputRef.current) {
      avatarInputRef.current.value = '';
    }
  };

  useEffect(() => {
    reset({
      name: user?.userName || '',
      email: user?.loginName || '',
      phone: getUserFullPhoneNumber(user),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const {
    isSubmitting,
    handleSubmit,
    values,
    isValid,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    resetForm,
    dirty,
  } = useFormik({
    validationSchema: AccountFormSchema,
    initialValues: {
      name: user?.userName || '',
      email: user?.loginName || '',
      phone: formatInputPhoneNumber(getUserFullPhoneNumber(user)) ?? '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values: IAccountValues) => {
      const areaCode = values?.phone.slice(1, 4);
      const phoneNumber = values?.phone.slice(6);

      if (dirty) {
        try {
          await updateCurrentUser?.({
            userName: values.name,
            loginName: values.email,
            areaCode,
            phoneNumber,
          });
        } catch (e) {
          enqueueSnackbar('Error updating account, please try again', {
            variant: 'error',
          });
          throw e;
        }
      }

      if (newAvatarFile) {
        try {
          await updateCurrentUserAvatar?.(newAvatarFile);
        } catch (e) {
          enqueueSnackbar('Error updating avatar, please try again', {
            variant: 'error',
          });
          throw e;
        }
      }

      enqueueSnackbar('Account updated successfully', {
        variant: 'success',
      });

      reset({
        name: values.name,
        email: values.email,
        phone: values.phone,
      });
    },
  });

  const onFileSelection = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    if (!files?.length) {
      return;
    }
    const uploadedFile = files[0];
    const dataUrl = await getBase64FromFile(uploadedFile);
    setNewAvatarUrl(dataUrl);
    setNewAvatarFile(uploadedFile);
  };

  const isDirty = !!(dirty || newAvatarFile);
  return (
    <Card>
      <CardTitle title="Account Details" />
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={'auto'}>
              <StyledButtonBase component="label" disableRipple>
                <input
                  ref={avatarInputRef}
                  type="file"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  onChange={onFileSelection}
                />
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <StyledBox>
                      <FontAwesomeIcon icon={faCamera} size="2x" />
                    </StyledBox>
                  }
                >
                  <StyledUserAvatar src={newAvatarUrl} userName={values.name} />
                </Badge>
              </StyledButtonBase>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack gap={1}>
                <FormControl margin="normal">
                  <TextField
                    name="name"
                    type="text"
                    label="Name"
                    size="small"
                    fullWidth
                    value={values.name}
                    onChange={e => setFieldValue('name', e.target.value)}
                    onBlur={handleBlur}
                    error={touched.name && errors.name ? true : false}
                    helperText={touched.name && errors.name}
                    required
                  />
                </FormControl>
                <FormControl margin="normal">
                  <TextField
                    name="email"
                    type="email"
                    label="Email"
                    size="small"
                    fullWidth
                    value={values.email}
                    onChange={e => setFieldValue('email', e.target.value)}
                    required
                    onBlur={handleBlur}
                    error={touched.email && errors.email ? true : false}
                    helperText={touched.email && errors.email}
                  />
                  <Typography variant="caption" align="left">
                    If you change your email address this will also change your login username to
                    the new email address.
                  </Typography>
                </FormControl>
                <FormControl margin="normal">
                  <TextField
                    name="phone"
                    label="Phone number"
                    size="small"
                    fullWidth
                    value={values.phone}
                    onChange={e => setFieldValue('phone', formatInputPhoneNumber(e.target.value))}
                    onBlur={handleBlur}
                    error={touched.phone && errors.phone ? true : false}
                    helperText={touched.phone && errors.phone}
                  />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>

          <Box
            margin="1rem 0 0"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            <Button
              color="inherit"
              onClick={() => reset()}
              disabled={!dirty || isSubmitting}
              startIcon={<ResetIcon />}
            >
              Reset
            </Button>
            <SaveButton
              disabled={!isDirty || !isValid || isSubmitting}
              text={isSubmitting ? 'Updating...' : 'Update'}
            />
          </Box>
        </FormGroup>
      </form>
    </Card>
  );
};

const StyledButtonBase = styled(ButtonBase)<ButtonBaseProps>(({ theme }) => ({
  '@media (max-width: 728px)': {
    display: 'flex',
    alignSelf: ' center',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  height: '40px',
  width: '40px',
  background: 'rgb(29, 49, 80)',
  border: '2px solid white',
  borderRadius: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&& path': {
    fill: 'white',
  },
}));

const StyledUserAvatar = styled(UserAvatar)(({ theme }) => ({
  backgroundSize: 'cover',
  height: '180px',
  width: '180px',
  border: '3px solid #20419A',
}));
