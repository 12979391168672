import { Box, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { CardTitle, Card } from '../../components';

interface IPSISectionProps {
  previousPSI: string | number;
  currentPSI: string | number;
  isExpanded?: boolean;
}

export const PSISection: FC<IPSISectionProps> = ({ previousPSI, currentPSI, isExpanded }) => {
  return (
    <Card className="print--avoid-break">
      <CardTitle
        title="PSI Information"
        withExpand
        initialExpand={false}
        overrideExpand={isExpanded}
      >
        <Box>
          <Typography>
            <StyledLabel>Previous PSI</StyledLabel> <span>{previousPSI}</span>
          </Typography>
          <Typography>
            <StyledLabel>PSI</StyledLabel> <span>{currentPSI}</span>
          </Typography>
        </Box>
      </CardTitle>
    </Card>
  );
};

const StyledLabel = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  textAlign: 'right',
  marginRight: '1rem',
  display: 'inline-block',
  width: '7rem',
}));
