import clsx from 'clsx';
import { CircularProgress, styled } from '@mui/material';
import { FC } from 'react';

interface ICircleProgress {
  className?: string;
  size?: string;
  color?: ICircleProgressStyles["color"];
}

interface ICircleProgressStyles {
  color: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit" | undefined;
};

export const CircleProgress: FC<ICircleProgress> = ({ className, color, size }) => {
  return (
    <StyledCircularProgress
      className={clsx(classes.progress, className)}
      size={size === 'large' ? 18 : size === 'small' ? 10 : 14}
      variant="indeterminate"
      data-testid="circle-progress"
      color={color}
    />
  );
};

const PREFIX = 'CircleProgress';

const classes = {
  progress: `${PREFIX}-progress`,
};

const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'color'
})<ICircleProgressStyles>(({ theme, color }) => ({
  [`&.${classes.progress}`]: {
    color:
      color === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main,
    margin: theme.spacing(0, 1, 0, 0),
  },
}));