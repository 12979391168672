import { Error } from '@mui/icons-material';
import Slide from '@mui/material/Slide';
import { InteractionType } from '@azure/msal-browser';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react';
import { FC, useEffect, useContext } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from './components/skeleton/PrivateRoute';
import { tokenRequest } from './services/msal-config';
import { Document360Widget } from './components/document360-widget';
// @ts-ignore
import { Helmet } from 'react-helmet';
import {
  UserContextHandler,
  UserContext,
  ServiceRoutesContextHandler,
  SearchContextHandler,
  SideNavHandler,
  SearchParamsContextHandler,
} from './context';
import {
  ServiceRouteDetailPage,
  ServiceDetailPage,
  Home,
  NotFound,
  ServiceRoutes,
  ServicesPage,
  RecurringService,
  CustomersListPage,
  CustomViewPage,
  CustomersDetail,
  OnlinePayments,
  AddEditEstimate,
  BillingPage,
  InvoiceDetailPage,
  ReportPage,
  SearchPage,
  InventoryListPage,
  LeadsPage,
  EstimateListPage,
  EstimatesDetailsPage,
  LeadDetailPage,
  TasksPage,
  MyAccountPage,
  FieldReportPage,
  CalendarPage,
  NewsManagementPage,
  NewsPage,
  TasksDetailPage,
  AlertsPage,
  FeedbackPage,
  FeedbackManagementPage,
  HelpPage,
  CommissionsListPage,
  CommissionDetail,
  BatchesList,
  PostPaymentPage,
  OfficeManagement,
  FeedbackItemDetails,
  TransactionBatchPage,
  TransactionPage,
  ContractPage,
  OTSDetailPage,
  OTSLandingPage,
  VerifyAddresses,
  Logout,
  AccountingExportPage,
  Unauthorized,
  InvoiceExternalPaymentPage,
  SetupPage,
  RecurringExternalPaymentPage,
  OfficeManagementDetailsPage,
  Users,
  HealthChecksPage,
  OutboxMessagesPage,
  SearchIndexPage,
  QueueEmailsPage,
  SMSPage,
  AuthPage,
  AzureFunctionsPage,
  DeveloperTechsPage,
} from './pages';
import { theme } from './styles';
import { ConfirmProvider } from 'material-ui-confirm';
import {
  defaultConfirmOptions,
  FeatureFlag,
  HelpWidgetApiKeyPool360,
  Paths,
  Permissions,
  ROLES,
} from './constants';
import { VersionWrapper } from './context/version-wrapper';
import locale from 'date-fns/locale/en-US';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorBoundary, Loader } from './components';
// @ts-ignore
import favicon360 from './styles/images/360/favicon.ico';
import { BrandingContextHandler } from './context/branding-context';
import { styleGuideRoutes } from './constants/style-guide-routes';
import { OtsPage } from './pages/developer/ots';

declare global {
  interface Window {
    ga: any;
  }
}
function ErrorComponent({ error }: any) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <Loader position="centered" type="overlay" />;
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0, // no caching of api calls,
      retry: false, // no retry on failed api calls
      retryOnMount: false, // the query will not be retried on mount if it contains an error
      staleTime: Infinity, // no caching of api calls
      refetchOnMount: false, // the query will not refetch on mount.
      refetchOnWindowFocus: false, // the query will not refetch on window focus.
    },
  },
});
// used to send the logged in user to LD so targeting works
const LDWrapper = ({ children }: { children: any }) => {
  const ldClient = useLDClient();
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (user) {
      ldClient?.identify({
        key: user?.loginName?.toLowerCase(),
        custom: {
          store: user?.officeName ?? '',
          storeCode: user?.officeCode ?? '',
          userName: user?.userName ?? '',
          storeId: user?.officeId ?? '',
          loginType: user?.loginType ?? '',
          userType: user?.userType ?? '',
          isOfficeAdmin: user?.isOfficeAdmin ?? false,
        },
      });
    } else {
      ldClient?.identify({ key: 'anonymous', anonymous: true }); // Reset to anonymous flag detection
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return children;
};

// Start Calendar Day of Week on Monday, instead of Sunday
if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

export const App: FC = () => {
  return (
    <SnackbarProvider
      iconVariant={{
        error: <Error style={{ marginRight: '5px' }} />,
      }}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      TransitionComponent={Slide}
      dense={false}
    >
      <Helmet>
        <link rel="icon" href={favicon360} />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <BrandingContextHandler>
              <Router>
                <SearchParamsContextHandler>
                  <Switch>
                    {/* These routes live outside the msal auth context */}
                    <Route exact path="/unauthorized" component={Unauthorized} />
                    <Route
                      exact
                      path="/external/estimates/:estimateId/agreement"
                      component={ContractPage}
                    />
                    <Route
                      exact
                      path="/external/services/ots/:repairId/agreement"
                      component={ContractPage}
                    />
                    <Route
                      exact
                      path="/external/invoices/:transactionId/pay"
                      component={InvoiceExternalPaymentPage}
                    />
                    <Route
                      exact
                      path="/external/recurring-payments/:recurringPaymentId/pay"
                      component={RecurringExternalPaymentPage}
                    />
                    <Redirect
                      exact
                      from="/invoices/:transactionId/pay"
                      to={{
                        pathname: '/external/invoices/:transactionId/pay',
                        search: window.location.search,
                      }}
                    />
                    <Redirect
                      exact
                      from="/estimates/:estimateId/agreement"
                      to={{
                        pathname: '/external/estimates/:estimateId/agreement',
                        search: window.location.search,
                      }}
                    />
                    <Redirect
                      exact
                      from="/services/ots/:repairId/agreement"
                      to={{
                        pathname: '/external/services/ots/:repairId/agreement',
                        search: window.location.search,
                      }}
                    />
                    <MsalAuthenticationTemplate
                      interactionType={InteractionType.Redirect}
                      authenticationRequest={tokenRequest}
                      errorComponent={ErrorComponent}
                      loadingComponent={LoadingComponent}
                    >
                      <AuthenticatedTemplate>
                        <UserContextHandler>
                          <VersionWrapper>
                            <SearchContextHandler>
                              <SideNavHandler>
                                <ErrorBoundary>
                                  <LDWrapper>
                                    <Document360Widget apiKeyWidget={HelpWidgetApiKeyPool360} />
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                      adapterLocale={locale}
                                    >
                                      <ConfirmProvider defaultOptions={defaultConfirmOptions}>
                                        <Route exact path="/logout" component={Logout} />
                                        {/* Home */}
                                        <PrivateRoute
                                          exact
                                          path="/"
                                          featureFlag={FeatureFlag.v2HomePage}
                                          role={ROLES.Office}
                                        >
                                          <Home />
                                        </PrivateRoute>
                                        {/* Routes */}
                                        <PrivateRoute
                                          exact
                                          path="/routes"
                                          featureFlag={FeatureFlag.v2Routes}
                                          permission={Permissions.ViewRoutes}
                                        >
                                          <ServiceRoutesContextHandler>
                                            <ServiceRoutes />
                                          </ServiceRoutesContextHandler>
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/routes/:serviceRouteId"
                                          featureFlag={FeatureFlag.v2Routes}
                                          permission={Permissions.ViewRoutes}
                                        >
                                          <ServiceRoutesContextHandler>
                                            <ServiceRouteDetailPage />
                                          </ServiceRoutesContextHandler>
                                        </PrivateRoute>
                                        {/* Services */}
                                        <PrivateRoute
                                          exact
                                          path="/services/maintenance"
                                          featureFlag={FeatureFlag.v2Services}
                                          permission={Permissions.ViewScheduledServices}
                                        >
                                          <ServicesPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/services/field-reports"
                                          featureFlag={FeatureFlag.v2FieldReport}
                                          permission={Permissions.ViewPayments}
                                        >
                                          <FieldReportPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/services/maintenance/:serviceId"
                                          featureFlag={FeatureFlag.v2Services}
                                          permission={Permissions.ViewScheduledServices}
                                        >
                                          <ServiceDetailPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/services/maintenance/recurring/:recurringId"
                                          featureFlag={FeatureFlag.v2Services}
                                          permission={Permissions.ViewScheduledServices}
                                        >
                                          <RecurringService />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/services/ots"
                                          featureFlag={FeatureFlag.v2Ots}
                                        >
                                          <OTSLandingPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/services/ots/:repairId"
                                          featureFlag={FeatureFlag.v2Ots}
                                        >
                                          <OTSDetailPage />
                                        </PrivateRoute>
                                        {/* Customers */}
                                        <PrivateRoute
                                          exact
                                          path="/customers"
                                          featureFlag={FeatureFlag.v2Customers}
                                          permission={Permissions.ViewCustomers}
                                        >
                                          <CustomersListPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          path="/customers/:paramsAccountId"
                                          featureFlag={FeatureFlag.v2Customers}
                                          permission={Permissions.ViewCustomers}
                                        >
                                          <CustomersDetail />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/customer-view/:viewId"
                                          featureFlag={FeatureFlag.v2Customers}
                                          permission={Permissions.ViewCustomers}
                                        >
                                          <CustomViewPage />
                                        </PrivateRoute>
                                        {/* Payments */}
                                        <PrivateRoute
                                          exact
                                          path={Paths.payments.url}
                                          featureFlag={FeatureFlag.v2Payments}
                                          permission={Permissions.ViewPayments}
                                        >
                                          <BatchesList />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={`${Paths.payments.url}/online/:transactionBatchId`}
                                          featureFlag={FeatureFlag.v2Payments}
                                          permission={Permissions.ViewPayments}
                                        >
                                          <TransactionBatchPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={`${Paths.payments.url}/payment/:transactionBatchId`}
                                          featureFlag={FeatureFlag.v2Payments}
                                          permission={Permissions.ViewPayments}
                                        >
                                          <PostPaymentPage />
                                        </PrivateRoute>
                                        <PrivateRoute exact path="/online-payments/:accountId">
                                          <OnlinePayments />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/online-payments/:accountId/recurring/new"
                                        >
                                          <OnlinePayments isNewPayment isRecurring />
                                        </PrivateRoute>
                                        {/* Billing */}
                                        <PrivateRoute
                                          exact
                                          path={Paths.billing.url}
                                          featureFlag={FeatureFlag.v2Billing}
                                          permission={Permissions.ViewBilling}
                                        >
                                          <BillingPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={`${Paths.billing.url}/invoices/:invoiceId`}
                                          featureFlag={FeatureFlag.v2Billing}
                                          permission={Permissions.ViewBilling}
                                        >
                                          <InvoiceDetailPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={`${Paths.billing.url}/transactions/:transactionId`}
                                          featureFlag={FeatureFlag.v2Billing}
                                          permission={Permissions.ViewBilling}
                                        >
                                          <TransactionPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={`${Paths.billing.url}/export`}
                                          featureFlag={FeatureFlag.accountingExport}
                                          permission={Permissions.ViewBilling}
                                        >
                                          <AccountingExportPage />
                                        </PrivateRoute>
                                        {/* Leads */}
                                        <PrivateRoute
                                          exact
                                          path="/leads"
                                          featureFlag={FeatureFlag.v2Leads}
                                        >
                                          <LeadsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/leads/:leadId"
                                          featureFlag={FeatureFlag.v2Leads}
                                        >
                                          <LeadDetailPage />
                                        </PrivateRoute>
                                        {/* Reports */}
                                        <PrivateRoute
                                          exact
                                          path={Paths.reports.url}
                                          permission={Permissions.ViewReports}
                                          featureFlag={FeatureFlag.v2Reports}
                                        >
                                          <ReportPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={Paths.inventory.url}
                                          permission={Permissions.Inventory}
                                          featureFlag={FeatureFlag.v2InventoryReport}
                                        >
                                          <InventoryListPage />
                                        </PrivateRoute>
                                        {/* Search */}
                                        <PrivateRoute
                                          exact
                                          path="/search"
                                          featureFlag={FeatureFlag.v2Customers}
                                        >
                                          <SearchPage />
                                        </PrivateRoute>
                                        {/* Sales - Estimates */}
                                        <PrivateRoute
                                          exact
                                          path="/estimates"
                                          permission={Permissions.ViewEstimates}
                                          featureFlag={FeatureFlag.v2Ots}
                                        >
                                          <EstimateListPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/estimates/:estimateId"
                                          permission={Permissions.ViewEstimates}
                                          featureFlag={FeatureFlag.v2Ots}
                                        >
                                          <EstimatesDetailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/estimates/:estimateId/:accountId"
                                        >
                                          <AddEditEstimate />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/scheduling/calendar"
                                          featureFlag={FeatureFlag.v2Scheduling}
                                          permission={Permissions.ViewScheduledServices}
                                        >
                                          <CalendarPage />
                                        </PrivateRoute>
                                        {/* My Account */}
                                        <PrivateRoute exact path="/my-account">
                                          <MyAccountPage />
                                        </PrivateRoute>
                                        {/* Tasks */}
                                        <PrivateRoute
                                          exact
                                          path="/scheduling/tasks"
                                          featureFlag={FeatureFlag.v2Scheduling}
                                          permission={Permissions.ViewScheduledServices}
                                        >
                                          <TasksPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/scheduling/tasks/:taskId"
                                          featureFlag={FeatureFlag.v2Scheduling}
                                          permission={Permissions.ViewScheduledServices}
                                        >
                                          <TasksDetailPage />
                                        </PrivateRoute>
                                        {/** Setup */}
                                        <PrivateRoute path="/setup">
                                          <SetupPage />
                                        </PrivateRoute>
                                        {/** Admin */}
                                        <PrivateRoute
                                          exact
                                          path={Paths.adminUsersManagement.url}
                                          role={ROLES.SuperAdmin}
                                        >
                                          <Users />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={Paths.officeManagement.url}
                                          role={ROLES.SuperAdmin}
                                        >
                                          <OfficeManagement />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={`${Paths.officeManagement.url}/:officeId`}
                                          role={ROLES.SuperAdmin}
                                        >
                                          <OfficeManagementDetailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={Paths.newsManagementAdmin.url}
                                          role={ROLES.SuperAdmin}
                                        >
                                          <NewsManagementPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={Paths.feedbackManagement.url}
                                          role={ROLES.SuperAdmin}
                                        >
                                          <FeedbackManagementPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={`${Paths.feedbackManagement.url}/:feedbackId`}
                                          role={ROLES.SuperAdmin}
                                        >
                                          <FeedbackItemDetails />
                                        </PrivateRoute>
                                        {/** News */}
                                        <PrivateRoute
                                          exact
                                          path="/news"
                                          featureFlag={FeatureFlag.v2AncillaryFeatures}
                                        >
                                          <NewsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/alerts"
                                          featureFlag={FeatureFlag.v2AncillaryFeatures}
                                        >
                                          <AlertsPage />
                                        </PrivateRoute>
                                        {/** Feedback */}
                                        <PrivateRoute
                                          exact
                                          path="/feedback"
                                          featureFlag={FeatureFlag.v2AncillaryFeatures}
                                        >
                                          <FeedbackPage />
                                        </PrivateRoute>
                                        {/** Commissions */}
                                        <PrivateRoute
                                          exact
                                          path="/commissions"
                                          featureFlag={FeatureFlag.v2Commissions}
                                          permission={Permissions.EditViewCommissions}
                                        >
                                          <CommissionsListPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path="/commissions/:commissionId"
                                          featureFlag={FeatureFlag.v2Commissions}
                                          permission={Permissions.EditViewCommissions}
                                        >
                                          <CommissionDetail />
                                        </PrivateRoute>
                                        {/** Help */}
                                        <PrivateRoute
                                          exact
                                          path="/help"
                                          featureFlag={FeatureFlag.v2AncillaryFeatures}
                                        >
                                          <HelpPage />
                                        </PrivateRoute>
                                        {/* Verify Addresses */}
                                        <PrivateRoute
                                          exact
                                          path={Paths.verifyAddresses.url}
                                          featureFlag={FeatureFlag.v2Customers}
                                        >
                                          <VerifyAddresses />
                                        </PrivateRoute>
                                        {/** Kitchen Sink */}
                                        {styleGuideRoutes?.map((route, index) => (
                                          <PrivateRoute
                                            key={index}
                                            exact
                                            path={route.path}
                                            role={ROLES.SuperAdmin}
                                          >
                                            {route.child}
                                          </PrivateRoute>
                                        ))}
                                        <PrivateRoute
                                          exact
                                          path={Paths.healthChecks.url}
                                          role={ROLES.SuperAdmin}
                                        >
                                          <HealthChecksPage />
                                        </PrivateRoute>
                                        {/** Developer */}
                                        <PrivateRoute
                                          exact
                                          path={Paths.outboxQueue.url}
                                          role={[ROLES.SuperAdmin, ROLES.Developer]}
                                        >
                                          <OutboxMessagesPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          exact
                                          path={Paths.searchIndex.url}
                                          role={[ROLES.SuperAdmin, ROLES.Developer]}
                                        >
                                          <SearchIndexPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          path={Paths.queueEmails.url}
                                          role={[ROLES.SuperAdmin, ROLES.Developer]}
                                        >
                                          <QueueEmailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          path={Paths.sms.url}
                                          role={[ROLES.SuperAdmin, ROLES.Developer]}
                                        >
                                          <SMSPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          path={Paths.authentication.url}
                                          role={[ROLES.SuperAdmin, ROLES.Developer]}
                                        >
                                          <AuthPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          path={Paths.azureFunctions.url}
                                          role={[ROLES.SuperAdmin, ROLES.Developer]}
                                        >
                                          <AzureFunctionsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          path={Paths.developerOts.url}
                                          role={[ROLES.SuperAdmin, ROLES.Developer]}
                                        >
                                          <OtsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                          path={Paths.developerTechs.url}
                                          role={[ROLES.SuperAdmin, ROLES.Developer]}
                                        >
                                          <DeveloperTechsPage />
                                        </PrivateRoute>
                                      </ConfirmProvider>
                                    </LocalizationProvider>
                                  </LDWrapper>
                                </ErrorBoundary>
                              </SideNavHandler>
                            </SearchContextHandler>
                          </VersionWrapper>
                        </UserContextHandler>
                      </AuthenticatedTemplate>
                    </MsalAuthenticationTemplate>
                    <Route exact component={NotFound} />
                  </Switch>
                </SearchParamsContextHandler>
              </Router>
            </BrandingContextHandler>
          </QueryClientProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
  );
};
