import React, { FC, useContext } from 'react';
import clsx from 'clsx';
import { Box, Button, Divider, IconButton, styled } from '@mui/material';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import { Loader } from '../loader';
import { INews } from '../../models';
import { SideNavContext } from '../../context';
import { Link } from 'react-router-dom';

export interface IAlertsProps {}

export const Alerts: FC<IAlertsProps> = React.memo(() => {
  const { showNav, setShowNav, isLoadingMessages, messages } = useContext(SideNavContext);
  const open = showNav;

  return (
    <StyledAlerts open={open} data-testid="Alerts">
      {!open && (
        <IconButton
          onClick={() => {
            setShowNav(!open);
          }}
          title={`View Alerts`}
          className={classes.messageToggle}
        >
          <NotificationsNoneOutlined />
        </IconButton>
      )}
      {open && (
        <>
          <Box className={clsx(classes.sectionTitle, classes.displayTransition)}>
            <div className={classes.sectionTitleIcon}>
              <NotificationsNoneOutlined />
            </div>{' '}
            Alerts
          </Box>
          {isLoadingMessages && (
            <Box
              className={clsx(classes.innerWrapper, classes.messageItem, classes.displayTransition)}
            >
              <Box className={classes.messageBody}>
                <Loader position="centered" type="inline" />
              </Box>
            </Box>
          )}
          {!isLoadingMessages && !messages && (
            <Box
              className={clsx(classes.innerWrapper, classes.messageItem, classes.displayTransition)}
            >
              <Box className={clsx(classes.messageBody, classes.emptyState)}>
                There are no messages or alerts to display.
              </Box>
            </Box>
          )}
          {!isLoadingMessages && messages && (
            <>
              {!!messages.alerts && messages.alerts.length > 0 && (
                <AlertItem
                  item={messages.alerts[0]}
                  open={open}
                  showAllAlerts={messages.alerts.length > 1}
                />
              )}

              {!!messages.tip && (
                <Box
                  className={clsx(
                    classes.innerWrapper,
                    classes.messageItem,
                    classes.displayTransition
                  )}
                >
                  <Box className={classes.messageTitle}>
                    <strong>{`TIP #${messages.tip.tipsNumber}`}</strong>
                  </Box>
                  <Box className={classes.messageBody}>{messages.tip.description}</Box>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </StyledAlerts>
  );
});

const AlertItem: FC<{ item: INews; open: boolean; showAllAlerts: boolean }> = ({
  item,
  open,
  showAllAlerts,
}) => {
  return (
    <Box className={clsx(classes.innerWrapper, classes.messageItem, classes.displayTransition)}>
      <Box className={classes.messageTitle}>
        <strong>{item.subject}</strong>
      </Box>
      <Box className={classes.messageBody}>
        {item.message && <StyledAlertMessage dangerouslySetInnerHTML={{ __html: item.message }} />}
      </Box>
      {showAllAlerts && (
        <>
          <Divider className={classes.divider} />
          <Button
            variant="text"
            component={Link}
            to="/alerts"
            color="secondary"
            sx={{ padding: 0 }}
          >
            All Alerts
          </Button>
        </>
      )}
    </Box>
  );
};

const PREFIX = 'Alerts';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  innerWrapper: `${PREFIX}-innerWrapper`,
  displayTransition: `${PREFIX}-displayTransition`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  sectionTitleIcon: `${PREFIX}-sectionTitleIcon`,
  messageItem: `${PREFIX}-messageItem`,
  messageTitle: `${PREFIX}-messageTitle`,
  messageBody: `${PREFIX}-messageBody`,
  messageToggle: `${PREFIX}-messageToggle`,
  emptyState: `${PREFIX}-emptyState`,
  alertList: `${PREFIX}-alertList`,
  divider: `${PREFIX}-divider`,
  alertMessage: `${PREFIX}-alertMessage`,
};

const StyledAlerts = styled(Box)<{ open: boolean }>(({ theme, open }) => ({
  backgroundColor: `rgba(255, 255, 255, 0.1)`,
  padding: theme.spacing(1.5, 1.5),
  '&& > * + *': {
    marginTop: theme.spacing(2),
  },

  // small screen sizes
  '@media (max-width: 400px)': {
    marginBottom: '5rem',
  },

  [`& .${classes.innerWrapper}`]: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '6px',
    padding: theme.spacing(2),
    '&& > * + *': {
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.displayTransition}`]: {
    opacity: open ? 1 : 0,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },

  [`& .${classes.sectionTitle}`]: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: '600',
  },

  [`& .${classes.sectionTitleIcon}`]: {
    marginRight: open ? theme.spacing(1) : 0,
    alignItems: 'center',
    display: 'flex',
    '&& svg': {
      width: 25,
      height: 25,
    },
  },

  [`& .${classes.messageItem}`]: {
    '&&, && *': {
      fontSize: '.85rem',
    },
  },

  [`& .${classes.messageTitle}`]: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },

  [`& .${classes.messageBody}`]: {
    wordBreak: 'break-word',
  },

  [`& .${classes.messageToggle}`]: {
    padding: 0,
    color: theme.palette.common.white,
  },

  [`& .${classes.emptyState}`]: {
    color: theme.palette.grey[600],
    textAlign: 'center',
  },

  [`& .${classes.alertList}`]: {
    '&& li': {
      padding: theme.spacing(0.5, 0, 0),
      marginTop: theme.spacing(0.5),
      borderTop: `1px solid ${theme.palette.grey[200]}`,

      '&:first-child': {
        paddingTop: 0,
        marginTop: 0,
        borderTop: 'none',
      },
    },
  },

  [`& .${classes.divider}`]: {
    borderColor: theme.palette.grey[200],
  },
}));

const StyledAlertMessage = styled('div')(({ theme }) => ({
  [`&.${classes.alertMessage}`]: {
    fontSize: theme.typography.body1.fontSize,
  },
}));
