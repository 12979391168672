import { useState } from 'react';
import { Fade, Stack, Box, TextField } from '@mui/material';
import {
  AccountAutocomplete,
  Loader,
  Modal,
  ModalSaveSection,
  SelectAsyncInput,
} from '../../../components';
import { useSnackbar } from 'notistack';
import { autoExtendRecurringServices, getRecurringServices, getUsers } from '../../../fetch';
import { IAccountSimple, IListUser, IRecurringService, IResponse } from '../../../models';
import { convertToNumber, formatDate } from '../../../helpers';

export const RecurringServicesModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: any }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAccount, setSelectedAccount] = useState<IAccountSimple | null>(null);
  const [selectedRecurringServiceId, setSelectedRecurringServiceId] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [daysForward, setDaysForward] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    setDaysForward('');
    setSelectedUserId('');
    setSelectedRecurringServiceId('');
    setSelectedAccount(null);
    onClose();
  };
  return (
    <Modal
      title="Auto Extend Recurring Service"
      open={isOpen}
      maxWidth="sm"
      onClose={() => {
        handleClose();
      }}
    >
      <Fade in={isOpen}>
        <Box mt={2}>
          {isLoading && <Loader position="centered" type="overlay" />}
          <Stack gap={2}>
            <AccountAutocomplete
              setSelectedAccount={val => {
                setSelectedAccount(val);
              }}
              labelText="Customer"
              isRequired
              selectedAccount={selectedAccount}
            />
            {selectedAccount && (
              <SelectAsyncInput
                label="Recurring Service"
                required
                name="recurringService"
                value={selectedRecurringServiceId}
                handleChange={value => {
                  setSelectedRecurringServiceId(value);
                }}
                apiRequest={() => {
                  return getRecurringServices({
                    perPage: -1,
                    sortBy: 'officeName',
                    accountId: selectedAccount?.accountId ?? '',
                  });
                }}
                transformResponse={(res: IResponse<IRecurringService[]>) => {
                  return res.records.map((service: IRecurringService) => ({
                    label: `${formatDate(service.initialDate)} - ${service.serviceType}`,
                    value: service.recurringServiceId,
                  }));
                }}
              />
            )}
            <SelectAsyncInput
              name="userId"
              label="User"
              required
              value={selectedUserId}
              apiRequest={() => getUsers({ perPage: -1 })}
              transformResponse={(response: IResponse<IListUser[]>) => {
                return response.records.map(record => ({
                  label: record.userName,
                  value: record.userId,
                }));
              }}
              handleChange={value => {
                setSelectedUserId(value);
              }}
            />
            <TextField
              label="Days Forward to Rechedule"
              type="number"
              required
              value={daysForward}
              size="small"
              fullWidth
              onChange={e => {
                setDaysForward(e.target.value);
              }}
            />
          </Stack>
          <ModalSaveSection
            handleCancel={() => {
              handleClose();
            }}
            handleSave={async () => {
              setIsLoading(true);
              try {
                await autoExtendRecurringServices({
                  accountId: selectedAccount?.accountId ?? '',
                  recurringServiceId: selectedRecurringServiceId,
                  userId: selectedUserId,
                  daysForwardToReschedule: convertToNumber(daysForward),
                });
                handleClose();
                enqueueSnackbar('Auto extended recurring service.', {
                  variant: 'success',
                });
              } catch (error: any) {
                enqueueSnackbar(
                  error?.Detail ?? `Error processing recurring services, please try again.`,
                  {
                    variant: 'error',
                  }
                );
              } finally {
                setIsLoading(false);
              }
            }}
            submitLabel="Submit"
            isSaveDisabled={
              !selectedAccount || !selectedRecurringServiceId || !selectedUserId || !daysForward
            }
          />
        </Box>
      </Fade>
    </Modal>
  );
};
