import { Button } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import {
  getAccountingProviderConnectUrl,
  storeAccountingProviderTokens,
  temporaryProviderChange,
} from '../../../fetch';
import { UserContext } from '../../../context';
import { useSnackbar } from 'notistack';
import { Loader } from '../../loader';
import { useConfirm, useSearchParams } from '../../../hooks';
import { QuickBooksIcon } from './quickbooks-icon';

interface IQuickBooksConnectButton {
  size?: 'large' | 'medium' | 'small';
  color?: 'primary' | 'secondary' | 'inherit';
  buttonText?: string;
  buttonKey: string;
  handleClick?: () => void;
  showConfirm?: boolean;
  confirmMessage?: string;
  afterClick?: () => void;
}

export const QuickBooksConnectButton: FC<IQuickBooksConnectButton> = ({
  size = 'large',
  color = 'primary',
  buttonText = 'Connect to Quickbooks',
  buttonKey,
  handleClick,
  showConfirm = true,
  confirmMessage = 'The connection may take a couple of minutes to complete.',
  afterClick,
}) => {
  const confirm = useConfirm();
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');
  const realmId = searchParams.get('realmId');

  const fetchQuickBooksConnectLink = async () => {
    setIsLoadingLink(true);
    try {
      const res = await getAccountingProviderConnectUrl(user?.officeId?.toString()!);
      window.location.href = res;
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error loading accounting provider url, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingLink(false);
    }
  };

  const storeQuickBooksTokens = async () => {
    if (localStorage.getItem('quickbooks-type-button') === buttonKey) {
      try {
        const res = await storeAccountingProviderTokens(user?.officeId?.toString()!, {
          code,
          companyId: realmId,
        });
        if (res.storedTemporaryConnectionId) {
          const confirmRes = await confirm(
            'A new Quickbooks account has been detected. If wanting to connect to a new Quickbooks account, all current transcode mappings will be cleared. If selecting "No", existing connection and mappings will be retained. Are you sure you want to proceed?',
            { confirmationText: 'Yes', cancellationText: 'No' }
          );

          await temporaryProviderChange(
            user?.officeId?.toString()!,
            res.storedTemporaryConnectionId,
            { isPermanent: confirmRes }
          );

          if (confirmRes) {
            enqueueSnackbar(`QuickBooks connected successfully!`, {
              variant: 'success',
            });
          }
        } else {
          enqueueSnackbar(`QuickBooks connected successfully!`, {
            variant: 'success',
          });
        }

        localStorage.setItem('quickbooks-type-button', '');
        if (afterClick) {
          afterClick();
        }
      } catch (error: any) {
        enqueueSnackbar(
          error?.Detail ?? `Error storing accounting provider tokens, please try again.`,
          {
            variant: 'error',
          }
        );
      } finally {
        setSearchParams({ code: null, realmId: null, state: null });
      }
    }
  };

  useEffect(() => {
    if (code && realmId) {
      storeQuickBooksTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, realmId]);

  return (
    <Button
      onClick={async () => {
        const result = showConfirm
          ? await confirm(confirmMessage, {
              hideCancelButton: buttonKey !== 'disconnect',
            })
          : true;
        if (result) {
          if (handleClick) {
            return handleClick();
          }
          fetchQuickBooksConnectLink();
          localStorage.setItem('quickbooks-type-button', buttonKey);
        }
      }}
      variant="contained"
      color={color}
      size={size}
      disabled={isLoadingLink}
      startIcon={
        isLoadingLink ? (
          <Loader position="centered" type="inline" title="" color="inherit" />
        ) : (
          <QuickBooksIcon />
        )
      }
    >
      {buttonText}
    </Button>
  );
};
