import { Grid, IconButton, Box, styled } from '@mui/material';
import { Dispatch, FC, useCallback, useEffect, useState } from 'react';
import { ICalendarDateRange, ICalendarView, IDateRange } from '../../models';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  startOfWeek,
  endOfWeek,
  subWeeks,
  addWeeks,
  addDays,
  subDays,
  format,
  endOfDay,
  nextSunday,
  nextMonday,
} from 'date-fns';
import { DateRangePicker } from '../../components';
import { parseCalendarDate } from '../../helpers';
import { useConfirm } from '../../hooks';
import { defaultUnsavedChangesMessage } from '../../constants';

export interface IDateRangeFilterProps {
  selectedDateRange?: ICalendarDateRange | null;
  setSelectedDateRange: (range: ICalendarDateRange) => void;
  view?: ICalendarView | null;
  setView?: Dispatch<React.SetStateAction<ICalendarView | undefined>>;
  allowNavigation?: boolean;
  showView?: boolean;
  hasChanges?: boolean;
  singleViewServiceDate?: string;
  isSingleViewMode?: boolean;
  setIsSingleViewMode?: React.Dispatch<React.SetStateAction<boolean>>;
  willHandleDateChange?: boolean;
  isSmall?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isPreviousDisabled?: boolean;
  isBorderless?: boolean;
  isRoutesDateRange?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const DateRangeFilter: FC<IDateRangeFilterProps> = ({
  selectedDateRange,
  setSelectedDateRange,
  allowNavigation = true,
  hasChanges,
  view = ICalendarView.WorkWeek,
  setView,
  singleViewServiceDate,
  isSingleViewMode = false,
  setIsSingleViewMode,
  willHandleDateChange,
  isSmall,
  minDate,
  maxDate,
  isPreviousDisabled,
  isBorderless,
  isRoutesDateRange,
  fullWidth,
  disabled,
}) => {
  const confirm = useConfirm();
  const params = new URLSearchParams(window.location.search);
  const today = new Date();
  const paramDate = params.get('date');
  const [singleViewDate, setSingleViewDate] = useState<Date>(
    singleViewServiceDate
      ? parseCalendarDate(singleViewServiceDate)
      : paramDate
      ? parseCalendarDate(paramDate)
      : today
  );
  const [currenSelectionLabel, setCurrentSelectionLabel] = useState('Select a date range');

  const confirmChangesLoss = async () => {
    return await confirm(defaultUnsavedChangesMessage);
  };

  const handleFreeDateRangeChange = async (value: IDateRange) => {
    if (!value.startDate || !value.endDate) {
      return;
    }
    if (hasChanges) {
      const result = await confirmChangesLoss();
      if (!result) {
        return;
      }
    }
    setSelectedDateRange({
      startDate: value.startDate,
      endDate: value.endDate,
    });
  };

  const handleChange = (dateSelected: Date | null) => {
    if (!dateSelected) {
      return;
    }
    if (isSingleViewMode) {
      setSingleViewDate(dateSelected);
      setSelectedDateRange({
        startDate: dateSelected,
        endDate: dateSelected,
      });
    } else {
      switch (view) {
        case ICalendarView.WorkWeek:
          setIsSingleViewMode?.(false);
          const workWeekStart = startOfWeek(dateSelected, { weekStartsOn: 1 });
          setSelectedDateRange({
            startDate: workWeekStart,
            endDate: endOfWeek(workWeekStart, { weekStartsOn: 6 }),
          });
          break;
        case ICalendarView.Week:
          setIsSingleViewMode?.(false);
          if (willHandleDateChange) {
            setSelectedDateRange({
              startDate: dateSelected,
              endDate: dateSelected,
            });
          } else {
            const weekStart = endOfDay(nextMonday(startOfWeek(dateSelected)));
            const weekEnd = endOfDay(nextSunday(weekStart));
            setSelectedDateRange({
              startDate: weekStart,
              endDate: weekEnd,
            });
          }
          break;
      }
    }
  };

  const onPrevious = () => {
    if (!selectedDateRange) {
      return;
    }
    if (isSingleViewMode) {
      const newDate = subDays(singleViewDate, 1);
      const newDateRange: ICalendarDateRange = {
        startDate: subDays(singleViewDate, 1),
        endDate: subDays(singleViewDate, 1),
      };
      setSingleViewDate(newDate);
      setSelectedDateRange(newDateRange);
    } else {
      const newDateRange: ICalendarDateRange = {
        startDate: subWeeks(selectedDateRange.startDate, 1),
        endDate: subWeeks(selectedDateRange.endDate, 1),
      };
      setSelectedDateRange(newDateRange);
    }
  };

  const onNext = () => {
    if (!selectedDateRange) {
      return;
    }
    if (isSingleViewMode) {
      const newDate = addDays(singleViewDate, 1);
      const newDateRange: ICalendarDateRange = {
        startDate: addDays(singleViewDate, 1),
        endDate: addDays(singleViewDate, 1),
      };
      setSingleViewDate(newDate);
      setSelectedDateRange(newDateRange);
    } else {
      const newDateRange: ICalendarDateRange = {
        startDate: addWeeks(selectedDateRange.startDate, 1),
        endDate: addWeeks(selectedDateRange.endDate, 1),
      };
      setSelectedDateRange(newDateRange);
    }
  };

  const shouldDisableDate = (date: Date) => {
    if (view === ICalendarView.WorkWeek) {
      return date.getDay() === 0 || date.getDay() === 6;
    }
    return false;
  };

  const singleViewServiceDateChange = useCallback(async () => {
    if (!setView || !singleViewServiceDate) {
      return;
    }
    if (hasChanges) {
      const result = await confirmChangesLoss();
      if (!result) {
        return;
      }
    }
    const date = parseCalendarDate(singleViewServiceDate);
    const newDateRange: ICalendarDateRange = {
      startDate: date,
      endDate: date,
    };
    setSingleViewDate(date);
    setSelectedDateRange(newDateRange);
    setIsSingleViewMode?.(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleViewServiceDate, hasChanges, setView, setSelectedDateRange, setIsSingleViewMode]);

  useEffect(() => {
    let formattedStartDate = '';
    let formattedEndDate = '';
    let formattedSingleDate = '';

    if (selectedDateRange) {
      if (isSingleViewMode) {
        formattedSingleDate = format(singleViewDate, 'M/d/yyyy');
        setCurrentSelectionLabel(`${formattedSingleDate}`);
      } else {
        formattedStartDate = format(selectedDateRange.startDate, 'M/d/yyyy');
        formattedEndDate = format(selectedDateRange.endDate, 'M/d/yyyy');
        setCurrentSelectionLabel(`${formattedStartDate} - ${formattedEndDate}`);
      }
    }
  }, [selectedDateRange, isSingleViewMode, singleViewDate, currenSelectionLabel]);

  useEffect(() => {
    // Handle single pod date click to single view.
    singleViewServiceDateChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleViewServiceDate]);
  return (
    <Wrapper sx={{ width: fullWidth ? '100%' : 'auto' }}>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs={12}>
          <CalendarSection>
            <CalendarNavigation allowNavigation={allowNavigation}>
              {allowNavigation && (
                <IconButton
                  onClick={onPrevious}
                  disabled={isPreviousDisabled || disabled}
                  sx={{ padding: '6.5px' }}
                >
                  <NavigateBeforeIcon />
                </IconButton>
              )}
              <DateRangePickerWrap>
                <DateRangePicker
                  id="filterDateRange"
                  value={{
                    key: 'selection',
                    startDate: selectedDateRange?.startDate || null,
                    endDate: selectedDateRange?.endDate || null,
                    inputValue: currenSelectionLabel,
                  }}
                  onChange={handleFreeDateRangeChange}
                  inputSize="medium"
                  placeholder="Select a Date Range"
                  showClear={false}
                  isBorderless={isBorderless}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabledDay={isRoutesDateRange ? date => shouldDisableDate(date) : undefined}
                  isStatic={isSingleViewMode || view !== ICalendarView.DateRange}
                  customOnChange={handleChange}
                  isSmall={isSmall}
                  hasMaxandMin={isRoutesDateRange && view === ICalendarView.DateRange}
                  fullWidth
                />
              </DateRangePickerWrap>
              {allowNavigation && (
                <IconButton onClick={onNext} sx={{ padding: '6.5px' }} disabled={disabled}>
                  <NavigateNextIcon />
                </IconButton>
              )}
            </CalendarNavigation>
          </CalendarSection>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const DateRangePickerWrap = styled('div')(({ theme }) => ({
  width: '100%',
  '&& .MuiTextField-root': {
    borderRadius: theme.spacing(0.5),
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  overflow: 'hidden',
  '&& .MuiInputBase-root': {
    width: '100%',
  },
}));

const CalendarSection = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
}));

const CalendarNavigation = styled(Box, {
  shouldForwardProp: prop => prop !== 'allowNavigation',
})<{ allowNavigation?: boolean }>(({ theme, allowNavigation }) => ({
  display: 'flex',
  alignItems: 'center',
  border: !allowNavigation ? 'none' : `1px solid rgba(0, 0, 0, 0.23)`,
  background: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
}));
