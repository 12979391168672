import { Box, FormLabel, Button, styled } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useWindowSize } from 'react-use';

interface ISignature {
  setSigPadInstance: Dispatch<SetStateAction<any>>;
  sigPadInstance: any;
  hasSubmitted: boolean;
  setIsSigned: Dispatch<SetStateAction<boolean>>;
  isSigned: boolean;
  clearSigPadOnResize?: boolean;
}

export const Signature: FC<ISignature> = ({
  hasSubmitted,
  setSigPadInstance,
  sigPadInstance,
  setIsSigned,
  isSigned,
  clearSigPadOnResize = true,
}) => {
  const { width } = useWindowSize();
  useEffect(() => {
    // when the window is resized, clear the signature pad
    // if you don't clear it, the signature won't be accurate if try and sign after resizing
    setIsSigned(false);
  }, [width, setIsSigned]);
  return (
    <>
      <Box>
        <FormLabel required>Sign Below</FormLabel>
        <SigContainer>
          <SignaturePad
            onEnd={() => {
              setIsSigned(true);
            }}
            ref={ref => {
              setSigPadInstance(ref);
            }}
            clearOnResize={clearSigPadOnResize}
          />
        </SigContainer>
      </Box>
      {isSigned && !hasSubmitted && (
        <Box>
          <Button
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faRefresh} />}
            onClick={() => {
              sigPadInstance?.clear();
              setIsSigned(false);
            }}
          >
            Clear
          </Button>
        </Box>
      )}
    </>
  );
};

const SigContainer = styled(Box)(({ theme }) => ({
  width: '250px',
  height: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[400]}`,
  //setting several breakpoints for this to account for clearOnResize={false}
  [theme.breakpoints.up('lg')]: {
    width: '300px',
  },
  [theme.breakpoints.up(1400)]: {
    width: '400px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '700px',
  },
  [theme.breakpoints.down('md')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '400px',
  },
  [theme.breakpoints.down(500)]: {
    width: '300px',
  },
  [theme.breakpoints.down(400)]: {
    width: '250px',
  },
  [theme.breakpoints.down(350)]: {
    width: '200px',
  },

  '& canvas': {
    width: '100%',
    height: '150px',
  },
}));
