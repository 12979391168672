import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { DateTimePicker } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';

export const StyleGuideDateTimePicker = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Date Time Picker">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Date Time Picker component is built upon the{' '}
            <Link href="https://mui.com/x/react-date-pickers/date-time-picker/" target="_blank">
              Mui Date Time Picker
            </Link>{' '}
            component. It has a built-in styles, a calendar picker, and manual time entry.
          </Typography>
          <code>
            Props:
            <br />- name?: string
            <br />- error?: boolean
            <br />- textFieldClass?: string
            <br />- textFieldHelperText?: string
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ setFieldValue, errors, values }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <DateTimePicker
                      label="Example Date/Time"
                      value={values.testField}
                      onChange={(date: any) => {
                        setFieldValue('testField', date);
                      }}
                      error={!!errors.testField}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;DateTimePicker
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Example Date/Time"
                    <br />
                    &nbsp;&nbsp;&nbsp;value=&#123;values.testField&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;onChange=&#123;(date: any) =&gt; &#123;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setFieldValue('testField', date);
                    <br />
                    &nbsp;&nbsp;&nbsp;&#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;error=&#123;!!errors.testField&#125;
                    <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
