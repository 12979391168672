import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { useSnackbar } from 'notistack';
import { Modal, Loader, ModalSaveSection } from '../../components';
import { Box, Fade, TextField } from '@mui/material';
import { createScheduledServiceServiceLog } from '../../fetch';
import { useConfirm } from '../../hooks';
import { defaultUnsavedChangesMessage } from '../../constants';

interface IServiceLogModal {
  open: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  serviceId: string | number;
}

const ServiceLogSchema = Yup.object().shape({
  description: Yup.string().required('Required'),
});

export const ServiceLogModal: FC<IServiceLogModal> = ({ open, onClose, serviceId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          description: '',
        }}
        validationSchema={ServiceLogSchema}
        onSubmit={async (values, actions) => {
          try {
            await createScheduledServiceServiceLog(serviceId, values.description || '');
            enqueueSnackbar('Successfully created log!', {
              variant: 'success',
            });
            onClose(true);
            actions.resetForm();
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
        }) => {
          return (
            <Modal
              open={open}
              onClose={async () => {
                if (!deepEqual(initialValues, values)) {
                  const result = await confirm(defaultUnsavedChangesMessage);
                  if (result) {
                    resetForm();
                    onClose();
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  resetForm();
                }
              }}
              maxWidth="xs"
              title="Add Service Log"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      autoComplete="nope"
                      label="Log Entry"
                      name="description"
                      value={values.description}
                      onBlur={handleBlur}
                      multiline
                      rows={3}
                      size="small"
                      onChange={(e: any) => setFieldValue('description', e.target.value)}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                    <ModalSaveSection
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                      handleCancel={async () => {
                        if (!deepEqual(initialValues, values)) {
                          const result = await confirm(defaultUnsavedChangesMessage);
                          if (result) {
                            resetForm();
                            onClose();
                          } else {
                            return;
                          }
                        } else {
                          onClose();
                        }
                      }}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
