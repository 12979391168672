import { useContext } from 'react';
import { Page } from '../../components';
import { BatchesListDetails } from './batches-list-details';
import { BrandingContext } from '../../context/branding-context';

export const BatchesList = () => {
  const { isPoolService } = useContext(BrandingContext);
  return (
    <Page title="Payments">
      <BatchesListDetails shouldUseSearchParams={isPoolService} />
    </Page>
  );
};
