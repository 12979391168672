import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, styled } from '@mui/material';
import { FC } from 'react';
import { IFilterInputProps } from '../../../../models';

export const SelectListMultiFilter: FC<IFilterInputProps> = ({ filter, values, onChange }) => {

  const { name, label } = filter;

  return (
    <StyledFormControl size="small" fullWidth>
      <InputLabel className={classes.selectLabel} shrink={true}>
        {label}
      </InputLabel>
      <Select
        name={name}
        value={values}
        displayEmpty
        multiple
        renderValue={selected => {
          const optionLabels = selected
            .map(s => {
              const option = filter.selectListItems!.find(i => i.value === s);
              if (option) {
                return option!.text;
              }
              return '';
            })
            .filter(Boolean);

          return optionLabels.join(', ');
        }}
        onChange={e => {
          onChange(e.target.value as string[], filter);
        }}
      >
        {filter.selectListItems?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={values.indexOf(option.value) !== -1} />
            <ListItemText primary={option.text} />
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

const PREFIX = 'SelectListMultiFilter';

const classes = {
  marginBottom: `${PREFIX}-marginBottom`,
  content: `${PREFIX}-content`,
  selectLabel: `${PREFIX}-selectLabel`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.marginBottom}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.content}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.selectLabel}`]: {
    background: 'white',
  }
}));