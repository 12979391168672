import { FC } from 'react';
import { Page } from '../../../components';
import { Box } from '@mui/material';
import { QueueEmailsDetails } from './queue-emails-details';

export const QueueEmailsPage: FC = () => {
  return (
    <Page title="Queue Emails Page">
      <Box mt={2}>
        <QueueEmailsDetails />
      </Box>
    </Page>
  );
};
