import { filesize } from 'filesize';
import {
  Fade,
  FormControlLabel,
  Grid,
  Stack,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { useConfirm } from '../../../hooks';
import {
  createSearchIndexes,
  deleteSearchIndex,
  deleteSearchIndexDocuments,
  getSearchIndexStatus,
} from '../../../fetch';
import { useSnackbar } from 'notistack';
import { CardButton, DisplayGroup, Loader, Modal, ModalSaveSection } from '../../../components';
import { faMagnifyingGlass, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { unCamelCase } from '../../../helpers';
import { ISearchIndexStatus } from '../../../models';

export const SearchIndexDetails: FC = () => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isDeleteIndexModal, setIsDeleteIndexModal] = useState(false);
  const [isDeleteDocumentIndexModal, setIsDeleteDocumentIndexModal] = useState(false);
  const [isSearchIndexStatusModal, setIsSearchIndexStatusModal] = useState(false);
  const [removeExistingIndexes, setRemoveExistingIndexes] = useState(false);
  const [removeExistingIndexers, setRemoveExistingIndexers] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [documents, setDocuments] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [statusData, setStatusData] = useState<ISearchIndexStatus | null>(null);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <CardButton
            title="Create Search Index"
            alignCenter
            onClick={() => {
              setIsCreateModal(true);
            }}
            icon={faSearch}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <CardButton
            title="Delete Search Index"
            alignCenter
            onClick={() => setIsDeleteIndexModal(true)}
            icon={faTrash}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <CardButton
            title="Delete Search Index Documents"
            alignCenter
            onClick={() => {
              setIsDeleteDocumentIndexModal(true);
            }}
            icon={faTrash}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <CardButton
            title="Search Index Status"
            alignCenter
            onClick={() => {
              setIsSearchIndexStatusModal(true);
            }}
            icon={faMagnifyingGlass}
          />
        </Grid>
      </Grid>
      <SearchModal
        open={isCreateModal}
        onClose={() => {
          setIsCreateModal(false);
          setSelectedIndex('');
        }}
        title="Create Search Index"
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        isLoading={isLoading}
      >
        <>
          <FormControlLabel
            label="Remove Existing Indexes"
            onChange={() => {
              setRemoveExistingIndexes(true);
            }}
            value={removeExistingIndexes}
            control={<Checkbox />}
          />
          <FormControlLabel
            label="Remove Existing Indexers"
            onChange={() => {
              setRemoveExistingIndexers(true);
            }}
            value={removeExistingIndexers}
            control={<Checkbox />}
          />
          <ModalSaveSection
            handleCancel={() => {
              setIsCreateModal(false);
              setSelectedIndex('');
              setRemoveExistingIndexers(false);
              setRemoveExistingIndexes(false);
            }}
            handleSave={async () => {
              const result = await confirm('Are you sure you want to create the search indexes?');
              if (result) {
                setIsLoading(true);
                try {
                  await createSearchIndexes({
                    removeExistingIndexers,
                    removeExistingIndexes,
                    index: selectedIndex as any,
                  });
                  enqueueSnackbar('Indexes created!', {
                    variant: 'success',
                  });
                  setIsCreateModal(false);
                  setSelectedIndex('');
                  setRemoveExistingIndexers(false);
                  setRemoveExistingIndexes(false);
                } catch (error: any) {
                  enqueueSnackbar(
                    error?.Detail ?? 'An error occurred when creating indexes, please try again.',
                    {
                      variant: 'error',
                    }
                  );
                } finally {
                  setIsLoading(false);
                }
              }
            }}
          />
        </>
      </SearchModal>
      <SearchModal
        open={isDeleteDocumentIndexModal}
        onClose={() => {
          setIsDeleteDocumentIndexModal(false);
          setSelectedIndex('');
          setDocuments('');
        }}
        title="Delete Search Index Documents"
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        isLoading={isLoading}
      >
        <>
          <TextField
            label="Documents"
            value={documents}
            size="small"
            onChange={e => setDocuments(e.target.value)}
            helperText="Enter comma seperated keys you want to delete"
          />
          <ModalSaveSection
            handleCancel={() => {
              setIsDeleteDocumentIndexModal(false);
              setSelectedIndex('');
              setDocuments('');
            }}
            handleSave={async () => {
              const result = await confirm(
                'Are you sure you want to delete the selected search index documents?'
              );
              if (result) {
                setIsLoading(true);
                try {
                  await deleteSearchIndexDocuments(selectedIndex, documents.split(','));
                  enqueueSnackbar('Index documents deleted successfully!', {
                    variant: 'success',
                  });
                  setIsDeleteDocumentIndexModal(false);
                  setSelectedIndex('');
                  setDocuments('');
                } catch (error: any) {
                  enqueueSnackbar(
                    error?.Detail ??
                      'An error occurred when deleting the search index documents, please try again.',
                    {
                      variant: 'error',
                    }
                  );
                } finally {
                  setIsLoading(false);
                }
              }
            }}
          />
        </>
      </SearchModal>
      <SearchModal
        open={isDeleteIndexModal}
        onClose={() => {
          setIsDeleteIndexModal(false);
          setSelectedIndex('');
        }}
        title="Delete Search index"
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        isLoading={isLoading}
      >
        <ModalSaveSection
          handleCancel={() => {
            setIsDeleteIndexModal(false);
            setSelectedIndex('');
          }}
          handleSave={async () => {
            const result = await confirm('Are you sure you want to delete this search index?');
            if (result) {
              setIsLoading(true);
              try {
                await deleteSearchIndex(selectedIndex);
                enqueueSnackbar('Index deleted successfully!', {
                  variant: 'success',
                });
                setIsDeleteIndexModal(false);
                setSelectedIndex('');
              } catch (error: any) {
                enqueueSnackbar(
                  error?.data?.Detail ??
                    'An error occurred while deleting search index, please try again.',
                  {
                    variant: 'error',
                  }
                );
              } finally {
                setIsLoading(false);
              }
            }
          }}
        />
      </SearchModal>
      <SearchModal
        open={isSearchIndexStatusModal}
        onClose={() => {
          setIsSearchIndexStatusModal(false);
          setSelectedIndex('');
          setStatusData(null);
        }}
        title="Search Index Status"
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        isLoading={isLoading}
      >
        {statusData && (
          <>
            <DisplayGroup label="Document Count">
              {statusData?.indexStatus?.documentCount ?? '0'}
            </DisplayGroup>
            <DisplayGroup label="Storage Size">
              {statusData?.indexStatus?.storageSize
                ? filesize(statusData?.indexStatus?.storageSize, { base: 2, standard: 'jedec' })
                : '0'}
            </DisplayGroup>
            <DisplayGroup label="Vector Index Size">
              {statusData?.indexStatus?.vectorIndexSize ?? '0'}
            </DisplayGroup>
          </>
        )}
        <ModalSaveSection
          handleCancel={() => {
            setIsSearchIndexStatusModal(false);
            setSelectedIndex('');
            setStatusData(null);
          }}
          handleSave={async () => {
            setIsLoading(true);
            const data = await getSearchIndexStatus(selectedIndex);
            setStatusData(data?.[0]);
            setIsLoading(false);
          }}
          submitLabel="Get Status"
        />
      </SearchModal>
    </>
  );
};

const SearchModal = ({
  open,
  children,
  onClose,
  title,
  selectedIndex,
  setSelectedIndex,
  isLoading,
}: {
  open: boolean;
  children: any;
  onClose: () => void;
  title: string;
  selectedIndex: string;
  setSelectedIndex: (index: string) => void;
  isLoading: boolean;
}) => {
  return (
    <Modal maxWidth="xs" open={open} onClose={onClose} title={title}>
      <Fade in={open}>
        <Stack mt={1.5} gap={1}>
          {isLoading && <Loader type="overlay" />}
          <SearchIndexDropdown
            value={selectedIndex}
            onChange={e => setSelectedIndex(e.target.value)}
          />
          {children}
        </Stack>
      </Fade>
    </Modal>
  );
};

const SearchIndexDropdown = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (e: SelectChangeEvent) => void;
}) => {
  return (
    <FormControl size="small" fullWidth>
      <InputLabel sx={{ background: 'white' }}>Index</InputLabel>
      <Select
        fullWidth
        size="small"
        autoComplete="nope"
        name="index"
        value={value}
        onChange={onChange}
      >
        {[
          'Accounts',
          'Inventory',
          'Sites',
          'WaterTestAccounts',
          'Repairs',
          'ScheduledServices',
          'Tasks',
        ].map(item => (
          <MenuItem key={item} value={item}>
            {unCamelCase(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
