import { FC, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import clsx from 'clsx';
import 'react-quill/dist/quill.snow.css';
import { FormControl, FormLabel, styled } from '@mui/material';
import { isWysiwygEmpty } from '../../helpers';
import { useInterval } from 'react-use';
import { useDebounce } from '../../hooks';

interface IWysiwygProps {
  className?: string;
  classNameWrapper?: string;
  onChange: (html: string) => void;
  onBlur?: () => void;
  readOnly?: boolean;
  value: string;
  editorFormats?: Array<string>;
  editorOptions?: Array<any>;
  placeholder?: string;
  label?: string;
  required?: boolean;
}

export const Wysiwyg: FC<IWysiwygProps> = ({
  className,
  onChange,
  onBlur,
  readOnly,
  value,
  editorFormats,
  editorOptions,
  placeholder,
  label,
  required = false,
  classNameWrapper,  
}) => {
  const defaultOptions = [
    [{ header: [false, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: null }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['link'],
    ['clean'],
  ];

  const options = editorOptions ? editorOptions : defaultOptions;
  //For anyone wanting to customize this: https://quilljs.com/docs/formats/
  const formats = editorFormats ? editorFormats : undefined;

  const quillContainerRef = useRef<HTMLDivElement>(null);

  const [isScanningForEditor, togglerIsScanningForEditor] = useState(true);
  const [newValue, setNewValue] = useState<string>('');

  // React Quill allows us to disable the tab index for most format controls
  // by creating a custom toolbar but they have a custom control "ql-picker"
  // that does not provide a way to disable its tab index.
  // This is the reason for the interval check seen below which disables
  // the ql-pickers and all the buttons as well
  useInterval(
    () => {
      const container = quillContainerRef.current;
      if (!container) {
        return;
      }
      const toolbar = container.querySelector('.ql-toolbar');
      if (!toolbar) {
        return;
      }
      const pickerLabels = toolbar.querySelectorAll<HTMLSpanElement>('.ql-picker-label');
      pickerLabels.forEach(pickerLabel => {
        pickerLabel.tabIndex = -1;
      });

      const toolbarButtons = toolbar.querySelectorAll<HTMLButtonElement>('button');
      toolbarButtons.forEach(button => {
        button.tabIndex = -1;
      });
      togglerIsScanningForEditor(false);
    },
    isScanningForEditor ? 500 : null
  );

  useDebounce(
    () => {
      // Allows for delayed update to the form so it doesn't re-render on every change
      onChange(newValue);
    },
    750,
    [newValue]
  );

  useMemo(() => {
    value && setNewValue(value);
  }, [value]);

  return (
    <StyledFormControl
      required={required}
      fullWidth
      className={classNameWrapper}
      ref={quillContainerRef}
      data-testid="Wysiwyg"
    >
      {label && <FormLabel>{label}</FormLabel>}
      <ReactQuill
        className={clsx(classes.wysiwyg, className)}
        readOnly={readOnly}
        value={newValue}
        formats={formats}
        defaultValue={''}
        modules={{
          clipboard: { matchVisual: false },
          toolbar: options,
        }}
        onChange={(value, deleta, source, editor) => {
          if (!isWysiwygEmpty(value)) {
            return setNewValue(value);
          }
          setNewValue('');
        }}
        onBlur={onBlur}
        theme="snow"
        placeholder={placeholder}
      />
    </StyledFormControl>
  );
};

const PREFIX = 'Wysiwyg';

const classes = {
  wysiwyg: `${PREFIX}-wysiwyg`
};

const StyledFormControl = styled(FormControl)(() => ({
  [`& .${classes.wysiwyg}`]: {
    '& .ql-editor': {
      minHeight: 100,
    },
  }
}));