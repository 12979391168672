import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
  SaveButton,
  ConfirmPrompt,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { IBillingGroup, IResponse } from '../../../models';
import { deleteBillingGroup, getAllBillingGroups, updateDefaultBillingGroup } from '../../../fetch';
import { faPlusCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AddEditBillingGroupModal } from './add-edit-billing-group-modal';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { useQuery } from 'react-query';
import { defaultUnsavedChangesMessage } from '../../../constants';

export const BillingGroupsTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentBillingGroup, setCurrentBillingGroup] = useState<IBillingGroup | null>(null);
  const confirm = useConfirm();
  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<IBillingGroup> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };

        const resGroups = await getAllBillingGroups(options);

        return {
          rows: resGroups.records,
          rowCount: resGroups.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading billing groups, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchBillingGroups,
  } = useDataGrid<IBillingGroup>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'billing-groups-grid',
      sortColumn: 'description',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'billAsInvoice',
        headerName: 'Bill As Invoice',
        flex: 1,
        type: 'boolean',
        sortable: false,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams<IBillingGroup>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Billing Group"
                onClick={() => {
                  showModal(true);
                  setCurrentBillingGroup(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Billing Group"
                onClick={async () => {
                  try {
                    const result = await confirm(
                      'Are you sure you want to delete this billing group?'
                    );
                    if (result) {
                      setDeleting(true);
                      await deleteBillingGroup(params.row.billingGroupId);
                      await refetchBillingGroups();
                      await refetchBillingGroupsForDefault();
                      enqueueSnackbar(`Billing Group Deleted!`, {
                        variant: 'success',
                      });
                    }
                  } catch (error) {
                    enqueueSnackbar(`Error deleting billing group, please try again.`, {
                      variant: 'error',
                    });
                  } finally {
                    setDeleting(false);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isDeleting]);

  const [selectedDefaultBillingGroup, setSelectedDefaultBillingGroup] = useState<string | null>();
  const [currentDefaultBillingGroup, setCurrentDefaultBillingGroup] = useState<string | null>();
  const [isSaving, setIsSaving] = useState(false);

  const {
    isFetching: isFetchingBillingGroups,
    refetch: refetchBillingGroupsForDefault,
    data: billingGroups,
  } = useQuery<IResponse<IBillingGroup[]>, Error>(
    ['getBillingGroupsForDefault'],
    () => getAllBillingGroups({ officeId: user?.officeId, perPage: -1 }),
    {
      enabled: !!user?.officeId,
      onSuccess: data =>
        setCurrentDefaultBillingGroup(
          data?.records?.find(group => group.isImportDefault)?.billingGroupId ?? null
        ),
    }
  );

  const handleDefaultSave = async () => {
    try {
      setIsSaving(true);
      await updateDefaultBillingGroup(selectedDefaultBillingGroup!, user?.officeId);
      await refetchBillingGroups();
      enqueueSnackbar('Default Billing Group updated!', {
        variant: 'success',
      });
    } catch (err: any) {
      enqueueSnackbar(err?.Detail ?? 'Error saving Default Billing Group.', {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Card>
        <CardTitle
          title="Billing Groups"
          mobileWrap
          action={
            <>
              <Button
                onClick={() => showModal(true)}
                color="secondary"
                size="small"
                disabled={isLoading}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add Billing Group
              </Button>
            </>
          }
        />
        <ServerSideDataGrid
          autoHeight
          disableColumnFilter
          disableColumnMenu
          getRowId={(row: IBillingGroup) => row.billingGroupId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
        />
      </Card>
      <AddEditBillingGroupModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentBillingGroup(null);
        }}
        currentBillingGroup={currentBillingGroup}
        fetchBillingGroups={() => {
          refetchBillingGroups();
          refetchBillingGroupsForDefault();
        }}
      />
      <ConfirmPrompt when={!!selectedDefaultBillingGroup} message={defaultUnsavedChangesMessage} />
      <Card sxProps={{ mt: 2 }}>
        <CardTitle title="Default Billing Group" mobileWrap />
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <FormControl fullWidth size="small" required>
              <InputLabel>Billing Group</InputLabel>
              <Select
                fullWidth
                size="small"
                autoComplete="nope"
                label="Billing Group"
                name="billingGroup"
                value={selectedDefaultBillingGroup ?? currentDefaultBillingGroup ?? ''}
                onChange={(e: any) => {
                  setSelectedDefaultBillingGroup(e.target.value);
                }}
              >
                {billingGroups?.records &&
                  billingGroups.records.map(group => (
                    <MenuItem key={group.billingGroupId} value={group.billingGroupId}>
                      {group.description}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <SaveButton
              disabled={isFetchingBillingGroups || isSaving || !selectedDefaultBillingGroup}
              handleSave={() => handleDefaultSave()}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
