import { useState } from 'react';
import { Fade, Stack, Box } from '@mui/material';
import {
  AccountAutocomplete,
  Loader,
  Modal,
  ModalSaveSection,
  SelectAsyncInput,
} from '../../../components';
import { useSnackbar } from 'notistack';
import { getRecurringPayments, processRecurringPayments } from '../../../fetch';
import { IAccountSimple, IRecurringPayment, IResponse } from '../../../models';

export const RecurringPaymentModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: any }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOffice, setSelectedOffice] = useState<string>('');
  const [selectedAccount, setSelectedAccount] = useState<IAccountSimple | null>(null);
  const [selectedRecurringPayment, setselectedRecurringPayment] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    setselectedRecurringPayment('');
    setSelectedOffice('');
    setSelectedAccount(null);
    onClose();
  };
  return (
    <Modal
      title="Process Recurring Payments"
      open={isOpen}
      maxWidth="sm"
      onClose={() => {
        handleClose();
      }}
    >
      <Fade in={isOpen}>
        <Box mt={2}>
          {isLoading && <Loader position="centered" type="overlay" />}
          <Stack gap={2}>
            <AccountAutocomplete
              setSelectedAccount={val => {
                setSelectedAccount(val);
              }}
              labelText="Customer"
              isRequired
              selectedAccount={selectedAccount}
            />
            {selectedAccount && (
              <SelectAsyncInput
                label="Recurring Payment"
                required
                name="recurring-payment"
                value={selectedRecurringPayment}
                handleChange={value => {
                  setselectedRecurringPayment(value);
                }}
                apiRequest={() => {
                  return getRecurringPayments(selectedAccount?.accountId ?? '');
                }}
                transformResponse={(res: IResponse<IRecurringPayment[]>) => {
                  return res.records.map((payment: IRecurringPayment) => ({
                    label: `${payment.dayOfMonth} - ${payment.paymentAmount} - ${payment.status}`,
                    value: payment.recurringPaymentId,
                  }));
                }}
              />
            )}
          </Stack>
          <ModalSaveSection
            handleCancel={() => {
              handleClose();
            }}
            handleSave={async () => {
              setIsLoading(true);
              try {
                await processRecurringPayments({
                  accountId: selectedAccount?.accountId ?? '',
                  recurringPaymentId: selectedRecurringPayment,
                });
                handleClose();
                enqueueSnackbar('Processed Recurring Payment', {
                  variant: 'success',
                });
              } catch (error: any) {
                enqueueSnackbar(
                  error?.Detail ?? `Error processing recurring payment, please try again.`,
                  {
                    variant: 'error',
                  }
                );
              } finally {
                setIsLoading(false);
              }
            }}
            submitLabel="Submit"
            isSaveDisabled={!selectedAccount || !selectedOffice || !selectedRecurringPayment}
          />
        </Box>
      </Fade>
    </Modal>
  );
};
