import { FC } from 'react';
import { Page } from '../../../components';
import { Box } from '@mui/material';
import { SmsDetails } from './sms-details';

export const SMSPage: FC = () => {
  return (
    <Page title="SMS">
      <Box mt={2}>
        <SmsDetails />
      </Box>
    </Page>
  );
};
