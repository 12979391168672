import { Alert, Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';
import { EditAddressInformation } from '../../../components';

export const StyleGuideEditAddressInfo = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const [pushPinLocation, setPushPinLocation] = useState<Microsoft.Maps.Location | null>(null);

  return (
    <StyleGuidePage title="Edit Address Information">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Edit Address Information is an organism component built upon the{' '}
            <Link href="https://mui.com/material-ui/react-text-field/#select" target="_blank">
              MUI Text Field
            </Link>{' '}
            component,{' '}
            <Link href="https://mui.com/material-ui/react-autocomplete/" target="_blank">
              Mui Autocomplete
            </Link>{' '}
            component, the{' '}
            <Link href="https://learn.microsoft.com/en-us/bingmaps/" target="_blank">
              Bing Maps API
            </Link>{' '}
            library, and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has built-in styles, map coordinate validation and display, address verification
            functionality, and Formik props required for use with a Formik form and form validation.
          </Typography>
          <Typography mb={1}>
            This component is designed to be used for capturing address information for either new
            customers/accounts, sites, or services. Some labels are customizeable to accommodate the
            different address types. A custom state prop is available for implementing a custom
            "dirty" validation state, should multiple Formik forms be implemented on the same page.
          </Typography>
          <Box mb={1}>
            <Alert severity="info">
              NOTE: It is important to keep form field names the same as those used in this
              component not only for consistency but also to properly wire-up this component to the
              form.
            </Alert>
          </Box>
          <Grid container spacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={'auto'}>
              <code>
                Props:
                <br />- accountAddress?: IAddress | null
                <br />- handleFormChange?: (val?: any, isDirty?: boolean) =&gt; void
                <br />- pushPinLocation: Microsoft.Maps.Location | null
                <br />- setPushPinLocation: (val: Microsoft.Maps.Location | null) =&gt; void
                <br />- site?: ISiteGetResponse
                <br />- showAccountAddressButton?: boolean
                <br />- addressNameLabel?: string
                <br />- isNewCustomer?: boolean
                <br />- setGlobalFormDirty?: (val: boolean) =&gt; void
                <br />- isOfficeAddress?: boolean
              </code>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <code>
                Field Names:
                <br />- newCustomer.addressName or addressName
                <br />- newCustomer.street or street
                <br />- newCustomer.state or state
                <br />- newCustomer.postalCode or postalCode
                <br />- newCustomer.latitude or latitude
                <br />- newCustomer.longitude or longitude
                <br />- whenVerified
                <br />- manualLocation
              </code>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          addressName: '',
          street: '',
          city: '',
          state: '',
          postalCode: '',
          latitude: 0,
          longitude: 0,
          whenVerified: '',
          manualLocation: false,
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ handleBlur, values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box mb={1} mt={2}>
                    <EditAddressInformation
                      pushPinLocation={pushPinLocation}
                      setPushPinLocation={setPushPinLocation}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;EditAddressInformation
                    <br />
                    &nbsp;&nbsp;&nbsp;pushPinLocation=&#123;pushPinLocation&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;setPushPinLocation=&#123;setPushPinLocation&#125;
                    <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
