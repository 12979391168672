import { Fade, Box } from '@mui/material';
import { FC } from 'react';
import { Modal } from '../modal';
import { UploadedImage } from './uploaded-image';

interface IImageModal {
  isOpen: boolean;
  onClose: () => void;
  source: string;
  isLoading?: boolean;
  title?: string;
}

export const ImageModal: FC<IImageModal> = ({ isOpen, onClose, source, isLoading, title }) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="xl"
      title={title ?? 'View Image'}
    >
      <Fade in={isOpen}>
        <Box mt={1}>
          <UploadedImage source={source} isLoading={isLoading} />
        </Box>
      </Fade>
    </Modal>
  );
};
