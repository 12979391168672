import { FC, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { ICustomViewFormValues, ISearchCondition } from '../../../models/custom-view';

export const GroupingButton: FC = () => {
  const [groupCount, setGroupCount] = useState(0);
  const { setFieldValue, values } = useFormikContext<ICustomViewFormValues>();

  useEffect(() => {
    let max = 0;
    values.searchConditions.forEach(condition => {
      if (condition.groupId > max) {
        max = condition.groupId;
      }
    });
    setGroupCount(max);
  }, [values.searchConditions]);

  const isGroupable = (): boolean => {
    // Store all search condition row indexes that are checked
    const pendingGroupIndexes = values.searchConditions.flatMap((condition, index) =>
      condition.isPending ? index : []
    );

    // At least two checkboxes must be selected to be groupable
    if (pendingGroupIndexes.length < 2) return false;

    // All selected rows must be next to each other to be grouped
    for (let i = 1; i < pendingGroupIndexes.length; i++) {
      if (pendingGroupIndexes[i] - pendingGroupIndexes[i - 1] !== 1) return false;
    }

    return true;
  };

  const handleGrouping = (): void => {
    const groupId = groupCount + 1;

    values.searchConditions.forEach((searchCondition: ISearchCondition, index: number) => {
      if (searchCondition.isPending) {
        setFieldValue(`searchConditions.${index}.isPending`, false);
        setFieldValue(`searchConditions.${index}.isGrouped`, true);
        setFieldValue(`searchConditions.${index}.groupId`, groupId);
      }
    });

    setGroupCount(groupCount);
  };

  return (
    <Button
      color="primary"
      variant="outlined"
      size="small"
      disabled={!isGroupable()}
      onClick={handleGrouping}
      startIcon={<LinkIcon fontSize="small" />}
    >
      Group Conditionals
    </Button>
  );
};
