import React from 'react';
import { Page } from '../../components';
import SetupPageDetail from './setup-page-detail';

export const SetupPage = () => {
  return (
    <Page title="Setup">
      <SetupPageDetail />
    </Page>
  );
};
