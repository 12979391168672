import { Typography, styled, alpha } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { FC } from 'react';
import { CircleProgress } from './circle-progress';

type LoaderPosition = 'centered' | 'left' | 'top-center';
type LoaderSize = 'small' | 'medium' | 'large';
type LoaderType = 'fullscreen' | 'inline' | 'overlay';

export interface ILoaderExtended {
  loaderWrapperClassName?: string;
  position?: LoaderPosition;
  size?: LoaderSize;
  subtitle?: string;
  title?: string;
  topOffset?: number;
  type?: LoaderType;
  zIndex?: number;
  style?: React.CSSProperties;
}

type ILoader = ILoaderExtended & Omit<CircularProgressProps, 'variant'>;

/**
 * A presentational CircularProgress indicator.
 *
 * @usage
 * ```TypeScript
 * const Component: FC = () => {
 *   const [isLoading, setIsLoading] = useState(true);
 *   const [resources, setResources] = useState<Resource[]>([]);
 *   useEffect(() => {
 *     fetchResource().then(x => setResource(x)).finally(() => setIsLoading(false));
 *   }, []);
 *   if (isLoading) return <Loader title="Loading xyz data..." />;
 *   return <div> ... </div>;
 * };
 * ```
 */
export const Loader: FC<ILoader> = ({
  children,
  loaderWrapperClassName = '',
  subtitle,
  title = 'Loading...',
  zIndex,
  topOffset,
  style,
  ...props
}) => {
  return (
    <LoaderWrapper
      className={clsx(classes.loader, loaderWrapperClassName)}
      data-testid={props.id}
      role="alert"
      style={style}
      {...(props as ILoaderExtended)}
    >
      <div className={classes.inner}>
        <CircleProgress color={props.color} size={props.size} className={props.className} />
        <div className={classes.text}>
          {children}
          {!children && (
            <>
              <Typography className={classes.title} variant="body1">
                {title}
              </Typography>
              {subtitle && (
                <Typography className={classes.subtitle} variant="subtitle1">
                  {subtitle}
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </LoaderWrapper>
  );
};

const PREFIX = 'Loader';

const classes = {
  loader: `${PREFIX}-loader`,
  inner: `${PREFIX}-inner`,
  text: `${PREFIX}-text`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
};

const LoaderWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'topOffset'
    && prop !== 'zIndex'
    && prop !== 'position'
    && prop !== 'size'
    && prop !== 'type'
    && prop !== 'color'
    && prop !== 'style'
    && prop !== 'subtitle'
    && prop !== 'title'
    && prop !== 'topOffset'
})<ILoaderExtended>(({
  theme,
  position,
  size,
  type,
  zIndex,
  topOffset,
  color,
}) => ({
  [`&.${classes.loader}`]: {
    alignItems: position === 'left' ? 'flex-start' : 'center',
    backgroundColor:
      type === 'fullscreen' || type === 'overlay'
        ? alpha(theme.palette.background.paper, 0.75)
        : '',
    bottom: 0,
    display: 'flex',
    fontSize:
      size === 'large' ? '1.25rem' : size === 'small' ? '0.875rem' : '1rem',
    height: (position === 'left' ? 'auto' : '100%'),
    left: 0,
    position:
      type === 'fullscreen' ? 'fixed' : type === 'overlay' ? 'absolute' : 'static',
    right: 0,
    top: 0,
    width: '100%',
    zIndex:
      zIndex ?? (type === 'fullscreen' ? theme.zIndex.tooltip + 1 : 1),
  },
  [`& .${classes.inner}`]: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 1,
    flexWrap: 'wrap',
    justifyContent: (position === 'left' ? 'flex-start' : 'center'),
    marginTop: topOffset && topOffset + '%',
    width: '100%',
  },
  [`& .${classes.subtitle}`]: {
    color: theme.palette.grey[800],
    fontSize:
      size === 'large' ? '0.875rem' : size === 'small' ? '0.625rem' : '0.75rem',
    margin: theme.spacing(0.25, 0, 0),
    textTransform: 'uppercase',
  },
  [`& .${classes.text}`]: {
    maxWidth: 230, // this is needed for long subtitles (to force that text to wrap)
  },
  [`& .${classes.title}`]: {
    color:
      color === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main,
    fontSize:
      size === 'large' ? '1.25rem' : size === 'small' ? '0.875rem' : '1rem',
    margin: 0,
  },
}));