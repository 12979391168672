export enum FeatureFlag {
  v2Payments = 'v2Payments',
  v2AncillaryFeatures = 'v2AncillaryFeatures',
  v2Commissions = 'v2Commissions',
  v2Billing = 'v2Billing',
  v2Reports = 'v2Reports',
  v2Scheduling = 'v2Scheduling',
  v2Routes = 'v2Routes',
  v2FieldReport = 'v2FieldReport',
  v2Services = 'v2Services',
  v2Customers = 'v2Customers',
  v2Login = 'v2Login',
  v2UserManagement = 'v2UserManagement',
  v2Ots = 'v2Ots',
  v2InventoryReport = 'v2InventoryReport',
  v2Leads = 'v2Leads',
  v2Setup = 'v2Setup',
  v2HomePage = 'v2HomePage',
  entraId = 'entraId',
  accountingExport = 'accountingExport',
  waterTestSearch = 'waterTestSearch',
  v2HelpHeaderLink = 'v2HelpHeaderLink',
  v2HelpWidget = 'v2HelpWidget',
  pool360CartIntegration = 'pool360CartIntegration',
  technicianManagement = 'technicianManagement',
  quickbooks = 'quickbooks',
  quickbooksInvoicing = 'quickbooksInvoicing',
  recurringPaymentsCollectCC = 'recurring-payments-collect-cc',
  customerRates = 'customer-rates',
  adyen = 'adyen',
}

// This list will be used for both Pinch and Pool Service.
// Please ensure that any flags referenced exist in both LaunchDarkly projects.
// Any differences need to be defined in the project specific variables (i.e. poolServiceFeatureFlags variable below). NOTE: a pinch specific variable has yet to be implemented.
export const sharedFeatureFlags = {
  'v-2-inventory-report': false,
  'v-2-ots': false,
  'v-2-user-management': false,
  'v-2-payments': false,
  'v-2-commissions': false,
  'v-2-ancillary-features': false,
  'v-2-field-report': false,
  'v-2-scheduling': false,
  'v-2-reports': false,
  'v-2-routes': false,
  'v-2-services': false,
  'v-2-billing': false,
  'v-2-customers': false,
  'v-2-login': false,
  'v-2-leads': false,
  'v-2-setup': false,
  'show-pool360-logo': false,
  'v-2-home-page': false,
  'allow-manual-item-entry': false,
  'entra-id': false,
  'accounting-export': false,
  'v-2-help-header-link': false,
  'v-2-help-widget': false,
  'technician-management': false,
  quickbooks: false,
  'customer-rates': false,
  'quickbooks-invoicing': false,
};

// Feature Flags specific to pool service / 360
export const poolServiceFeatureFlags = {
  ...sharedFeatureFlags,
  'water-test-search': false,
  'pool-360-cart-integration': false,
  'recurring-payments-collect-cc': false,
  adyen: false,
};
