import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { useConfirm } from '../../../hooks';

export const StyleGuideConfirmDialog = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  const confirm = useConfirm();

  return (
    <StyleGuidePage title="Confirm Dialog">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Confirm Dialog component is a custom component typically used on the app by our
            custom useConfirm hook which is built upon the{' '}
            <Link href="https://github.com/jonatanklosko/material-ui-confirm" target="_blank">
              Material-UI confirm package
            </Link>{' '}
            .
          </Typography>
          <Typography mb={1}>
            This particular component is meant to be used when you need to confirm an action with
            the user before proceeding. It is a modal that will display a message to the user and
            give them the option to confirm or cancel the action.
          </Typography>
          <Typography mb={1}>
            To use the useConfirm hook, you can import it from the hooks folder, setup a const like
            "const confirm = useConfirm();", and then call it with the message you want to display
            to the user. The confirm function takes in a message, an options object, and a boolean
            to determine if the close button should be present on the modal.
          </Typography>
          <code>
            Props:
            <br />- message: string
            <br />- options?: ConfirmOptions
            <br />- showCloseButton?: boolean
          </code>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={1} p={0.5}>
            <Button
              children="Open Prompt"
              onClick={() => confirm('Are you sure you want to do this?', {}, true)}
            />
          </Box>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;Button
            <br />
            &nbsp;&nbsp;&nbsp;children="Open Prompt" <br />
            &nbsp;&nbsp;&nbsp;onClick=&#123;() =&gt; confirm('Are you sure you want to do this?',
            &#123;&#125; , true)&#125;
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
