import clsx from 'clsx';
import { Divider, Grid, TextField, Typography} from '@mui/material';
import { CardTitle, FormikCard, SelectAsync} from '../../../components';
import { useState, FC, ChangeEvent } from 'react';
import { getRepairStatuses, listServiceTypes } from '../../../fetch';
import { NewCustomerForm } from '../../customers';
import {
  IOneTimeServiceDetail,
  IBillingGroup,
  IDropdownResponse,
  IServiceType,
  ISalesTax,
} from '../../../models';
import { formatDate } from '../../../helpers';
import { useDebounce } from '../../../hooks';

interface IOTSServiceDetails {
  values: any;
  setFieldValue: (key: string, val: any) => void;
  isNewService: boolean;
  touched: any;
  errors: any;
  handleBlur: (e: any) => void;
  isAllExpanded?: boolean;
  service: IOneTimeServiceDetail | null;
  billingGroups: IBillingGroup[];
  isLoadingBillingGroups: boolean;
  salesTaxes: ISalesTax[];
  isLoadingSalesTaxes: boolean;
  showConfirm?: boolean;
  shouldUnload?: boolean;
}

export const OTSServiceDetails: FC<IOTSServiceDetails> = ({
  values,
  setFieldValue,
  isNewService,
  touched,
  errors,
  handleBlur,
  isAllExpanded,
  service,
  billingGroups,
  isLoadingBillingGroups,
  salesTaxes,
  isLoadingSalesTaxes,
  showConfirm,
  shouldUnload,
}) => {
  const [problemDescription, setProblemDescription] = useState(values?.problemDescription ?? '');
  const [repairNotes, setRepairNotes] = useState(values?.repairNotes ?? '');
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);

  const handleServiceTypeChange = async (event: ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value;
    const selectedServiceType = serviceTypes.find(
      s => Number(s.serviceTypeId) === Number(id) || s.customAgreementId === id
    );
    if (selectedServiceType) {
      setFieldValue('serviceTypeId', selectedServiceType.serviceTypeId);
      setFieldValue('customAgreementId', selectedServiceType.customAgreementId);
    }
  };

  useDebounce(
    () => {
      // Allows for delayed update to the form so it doesn't re-render on every change
      if (setFieldValue && problemDescription !== values.problemDescription) {
        setFieldValue('problemDescription', problemDescription);
      }
      if (repairNotes && setFieldValue && repairNotes !== values.repairNotes) {
        setFieldValue('repairNotes', repairNotes);
      }
    },
    750,
    [problemDescription, setFieldValue, repairNotes]
  );

  return (
    <FormikCard showConfirm={showConfirm} shouldUnload={shouldUnload}>
      <CardTitle title="Service Details" withExpand overrideExpand={isAllExpanded}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={isNewService ? 12 : 4}>
            <SelectAsync
              fullWidth
              size="small"
              autoComplete="off"
              label="Service Type"
              name="serviceTypeId"
              disabled={!!values.signature}
              required
              value={values.serviceTypeId || values.customAgreementId}
              apiRequest={() => listServiceTypes()}
              handleResponseOptions={(data: IServiceType[]) => setServiceTypes(data)}
              transformResponse={(res: IServiceType[]) =>
                res.map(r => ({
                  label: r.description,
                  value:
                    (r.serviceTypeId ? `${r.serviceTypeId}` : '') ||
                    (r.customAgreementId ? `${r.customAgreementId}` : '') ||
                    '',
                }))
              }
              onChange={handleServiceTypeChange}
              inputProps={{
                'data-testid': 'service-type-select'
              }}
            />
          </Grid>

          {values.customerType === 'NewCustomer' && isNewService && (
            <>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    borderColor: theme => theme.palette.dividers.grey,
                    marginBottom: theme => theme.spacing(2),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <NewCustomerForm
                  billingGroups={billingGroups}
                  isLoadingBillingGroups={isLoadingBillingGroups}
                  salesTaxes={salesTaxes}
                  isLoadingSalesTaxes={isLoadingSalesTaxes}
                  isEmailRequired={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    borderColor: theme => theme.palette.dividers.grey,
                    marginBottom: theme => theme.spacing(2),
                  }}
                />
              </Grid>
            </>
          )}

          {service?.whenCreated && (
            <Grid item xs={6} md={4}>
              <SelectAsync
                name="repairStatus"
                label="Status"
                required
                apiRequest={() => getRepairStatuses()}
                transformResponse={(response: IDropdownResponse[]) => {
                  return response.map(record => ({
                    label: record.description ?? '',
                    value: record.value ?? '',
                  }));
                }}
                hasClear={!!values.repairStatus}
                inputProps={{
                'data-testid': 'status-select'
              }}
              />
            </Grid>
          )}

          {service?.whenCreated && (
            <Grid item xs={6} md={3}>
              <Typography>
                Created on{' '}
                <Typography color="secondary" component="span">
                  {service?.whenCreated ? formatDate(new Date(service?.whenCreated)) : ''}{' '}
                </Typography>
                by {service?.createdBy}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} xl={6}>
            <TextField
              fullWidth
              multiline
              placeholder={'Description'}
              label={'Service Description'}
              rows={4}
              name={'problemDescription'}
              onBlur={handleBlur}
              value={problemDescription}
              className={clsx('print--none')}
              size="small"
              onChange={e => setProblemDescription(e.target.value)}
              error={touched?.problemDescription && errors?.problemDescription ? true : false}
              helperText={touched?.problemDescription && errors?.problemDescription}
              inputProps={{
                'data-testid': 'service-description-text'
              }}
            />
            {/* Used for the print screen only */}
            {values.problemDescription && (
              <div className={clsx('print--only')}>
                <label className="print--shrink-label MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled css-ahtaft-MuiFormLabel-root-MuiInputLabel-root">
                  Description
                </label>
                {values.problemDescription}
              </div>
            )}
          </Grid>
          <Grid item xs={12} xl={6}>
            <TextField
              fullWidth
              multiline
              label="Technician Notes"
              rows={4}
              name={'repairNotes'}
              onBlur={handleBlur}
              value={repairNotes}
              className={clsx('print--none')}
              size="small"
              onChange={e => setRepairNotes(e.target.value)}
              error={touched?.repairNotes && errors?.repairNotes ? true : false}
              helperText={touched?.repairNotes && errors?.repairNotes}
              inputProps={{
                'data-testid': 'technician-notes-text'
              }}
            />
            {/* Used for the print screen only */}
            {values.repairNotes && (
              <div className={clsx('print--only')}>
                <label className="print--shrink-label MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled css-ahtaft-MuiFormLabel-root-MuiInputLabel-root">
                  Notes
                </label>
                {values.repairNotes}
              </div>
            )}
          </Grid>
        </Grid>
      </CardTitle>
    </FormikCard>
  );
};
