import { FC, useCallback, useContext, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Box, Button, styled } from '@mui/material';
import { faFilterCircleXmark, faFilter, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {
  CardTitle,
  FilterForm,
  CardFiltersLayout,
  Card,
  GridDataFetcher,
  useDataGrid,
  useFilters,
} from '../../components';
import { CommissionsDataGrid } from './CommissionsDataGrid';
import { IFilterLayout, ICommission } from '../../models';
import { useSnackbar } from 'notistack';
import { getCommissions, getCommissionsFilters } from '../../fetch';
import { ESearchParams, SearchParamsContext, UserContext } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrandingContext } from '../../context/branding-context';

interface CommissionsListDetailsProps {}

const filtersLayout: Record<string, IFilterLayout> = {
  DateType: {
    sortOrder: 0,
    xs: 12,
    sm: 4,
  },
  StartDate: {
    sortOrder: 1,
    xs: 12,
    sm: 4,
  },
  EndDate: {
    sortOrder: 2,
    xs: 12,
    sm: 4,
  },
  CommissionTypeId: {
    sortOrder: 3,
    xs: 12,
    sm: 12,
    md: 6,
  },
  AccountId: {
    sortOrder: 4,
    xs: 12,
    md: 6,
  },
  UserId: {
    sortOrder: 5,
    xs: 12,
    md: 6,
  },
  ServiceDefId: {
    sortOrder: 6,
    xs: 12,
    md: 6,
  },
};

export const CommissionsListDetails: FC<CommissionsListDetailsProps> = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { isPoolService } = useContext(BrandingContext);
  const { queryParams, setSearchParams } = useContext(SearchParamsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setDeleting] = useState(false);

  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset,
    isLoading: isLoadingFilters,
  } = useFilters({
    filterFetcher: useCallback(() => getCommissionsFilters(), []),
    shouldUseSearchParams: isPoolService,
  });

  const dataFetcher: GridDataFetcher<ICommission> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection, searchParams }) => {
      try {
        if (!filtersInitialized) {
          return {
            continueLoading: true,
          };
        }
        // don't call the api when this query param is present or on services page
        if (queryParams && queryParams.includes('redirect')) {
          return {
            continueLoading: true,
          };
        }
        const res = await getCommissions({
          sortBy: searchParams?.get(ESearchParams.sort)
            ? searchParams?.get(ESearchParams.sort)!
            : sortColumn,
          sortDirection: searchParams?.get(ESearchParams.sortDirection)
            ? searchParams.get(ESearchParams.sortDirection)!
            : sortDirection ?? 'asc',
          page: page + 1,
          perPage,
          officeId: user?.officeId,
          filters: appliedFilters,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading commissions, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filtersInitialized,
      appliedFilters,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isPoolService ? (queryParams.includes('sort'), queryParams.includes('dir')) : undefined,
    ]
  );
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ICommission>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'commission-grid',
      sortColumn: 'commissionDate',
      sortDirection: 'desc',
    },
    dataFetcher,
    shouldUseSearchParams: isPoolService,
  });

  return (
    <Card>
      <CardTitle
        title="Commissions"
        mobileWrap
        marginBottom={0}
        action={
          <>
            <Button
              onClick={onFilterToggle}
              className={clsx('print--none')}
              color="secondary"
              size="small"
              disabled={isLoading}
              startIcon={
                <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
              }
            >
              Filters
            </Button>
            <Button
              onClick={() =>
                history.push(
                  `/commissions/new?redirect=${encodeURIComponent(
                    `/commissions${queryParams ? `?${queryParams}` : ''}`
                  )}`
                )
              }
              color="secondary"
              size="small"
              disabled={isLoading}
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              Add New Commission
            </Button>
          </>
        }
      >
        <FiltersWrapper>
          <CardFiltersLayout isOpen={isShowingFilters}>
            <FilterForm
              filters={filters}
              values={filterValues}
              isLoading={isLoadingFilters}
              filterLayouts={filtersLayout}
              defaultLayout={{ xs: 12 }}
              onSubmit={values => {
                if (Object.values(appliedFilters).length > 0) {
                  onPageChange(0);
                }
                onSubmitFilters(values, isPoolService);
              }}
              onChange={onFiltersChange}
              isSubmitting={isLoading}
              onReset={values => {
                onReset(values, isPoolService);
              }}
            />
          </CardFiltersLayout>
        </FiltersWrapper>
      </CardTitle>
      <Box mt={1}>
        <CommissionsDataGrid
          loading={isLoading || isDeleting}
          rows={rows}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          sortModel={sortModel}
          onSortModelChange={(model, details) => {
            if (isPoolService) {
              setSearchParams({
                [ESearchParams.sort]: model[0]?.field,
                [ESearchParams.sortDirection]: model[0]?.sort,
              });
            } else {
              onSortModelChange(model);
            }
          }}
          refetch={refetch}
          setDeleting={setDeleting}
          redirect="/commissions"
        />
      </Box>
    </Card>
  );
};

const FiltersWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    '& [role=radiogroup]': {
      flexDirection: 'row',
    },
  },
}));
