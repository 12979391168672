import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { SelectAsyncInput } from '../../../components';
import { getNews } from '../../../fetch';
import { INews, IResponse, ISelectOption } from '../../../models';
import { Form, Formik } from 'formik';
import { StyleGuideMultiselectAlert } from '../multiselect-alert';

export const StyleGuideSelectAsync = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});
  const [exampleItemsResponse, setExampleItemsResponse] = useState<INews[]>([]);
  const [exampleItems, setExampleItems] = useState<ISelectOption[]>([]);

  return (
    <StyleGuidePage title="Select Async">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Select Async is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-text-field/#select" target="_blank">
              MUI Text Field
            </Link>{' '}
            component and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has built-in styles, props for making an API call to get options, and Formik props
            required for use with a Formik form and form validation.
          </Typography>
          <Typography mb={1}>
            Async Select fields help reduce page/component clutter by grouping non-dependent calls
            and loading states for dropdown options within the field itself. For example, if a
            dropdown has no need to be updated based on the selection from another filter/field, use
            Async Select component to bundle up GET calls and loading states.
          </Typography>
          <StyleGuideMultiselectAlert />
          <code>
            Props:
            <br />- name: string
            <br />- apiRequest: () =&gt; Promise&lt;any&gt;
            <br />- transformResponse: (response: any) =&gt; ISelectOption[]
            <br />- hasClear?: boolean
            <br />- onClear?: () =&gt; void
            <br />- handleOptions?: (val: ISelectOption[]) =&gt; void
            <br />- handleResponseOptions?: (val: any) =&gt; void
            <br />- handleChange: (val: string) =&gt; void
            <br />- value: any
            <br />- shouldRefetch?: boolean
            <br />- multiple?: boolean
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '',
          testField2: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ handleBlur, values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <SelectAsyncInput
                      label="Example"
                      name="testField"
                      value={values.testField}
                      handleChange={val => setFieldValue?.('testField', val)}
                      apiRequest={() =>
                        getNews({
                          perPage: 30,
                          page: 1,
                          sortBy: 'siteName',
                          sortDirection: 'asc',
                        })
                      }
                      transformResponse={(res: IResponse<INews[]>) =>
                        res.records.map(r => ({
                          label: `Option ${r.newsItemId.toString().slice(0, 4)}`,
                          value: r.newsItemId.toString(),
                        }))
                      }
                      hasClear={!!values.testField}
                      onClear={() => {
                        setFieldValue?.('testField', '');
                      }}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;SelectAsyncInput
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Example"
                    <br />
                    &nbsp;&nbsp;&nbsp;name="testField"
                    <br />
                    &nbsp;&nbsp;&nbsp;value=&#123;values.testField&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;handleChange=&#123;val =&gt; setFieldValue?.('testField',
                    val)&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;apiRequest=&#123;() =&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;getNews(&#123; perPage: 30, page: 1, sortBy:
                    'siteName', sortDirection: 'asc', &#125;)
                    <br />
                    &nbsp;&nbsp;&nbsp;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;transformResponse=&#123;(res: IResponse&lt;INews[]&gt;) =&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;res.records.map(r =&gt; (&#123;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;label: `Option
                    $&#123;r.newsItemId.toString().slice(0, 4)&#125;`,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;value:
                    r.newsItemId.toString(),
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;))
                    <br />
                    &nbsp;&nbsp;&nbsp;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;hasClear=&#123;!!values.testField&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;onClear=&#123;() =&gt; &#123; setFieldValue?.('testField',
                    ''); &#125;&#125;
                    <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box mb={1} mt={2}>
                    <SelectAsyncInput
                      label="Example 2"
                      name="testField2"
                      value={values.testField}
                      handleChange={val => setFieldValue?.('testField2', val)}
                      apiRequest={() =>
                        getNews({
                          perPage: 30,
                          page: 1,
                          sortBy: 'siteName',
                          sortDirection: 'asc',
                        })
                      }
                      transformResponse={(res: IResponse<INews[]>) =>
                        res.records.map(r => ({
                          label: `Option ${r.newsItemId.toString().slice(0, 4)}`,
                          value: r.newsItemId.toString(),
                        }))
                      }
                      hasClear={!!values.testField2}
                      onClear={() => {
                        setFieldValue?.('testField2', '');
                      }}
                      handleResponseOptions={(response: IResponse<INews[]>) => {
                        setExampleItemsResponse(response?.records);
                        console.log('exampleItemsResponse', exampleItemsResponse);
                      }}
                      handleOptions={data => {
                        setExampleItems(data);
                        console.log('exampleItems', exampleItems);
                      }}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;SelectAsyncInput
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Example 2"
                    <br />
                    &nbsp;&nbsp;&nbsp;name="testField2"
                    <br />
                    &nbsp;&nbsp;&nbsp;value=&#123;values.testField2&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;handleChange=&#123;val =&gt; setFieldValue?.('testField2',
                    val)&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;apiRequest=&#123;() =&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;getNews(&#123; perPage: 30, page: 1, sortBy:
                    'siteName', sortDirection: 'asc', &#125;)
                    <br />
                    &nbsp;&nbsp;&nbsp;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;transformResponse=&#123;(res: IResponse&lt;INews[]&gt;) =&gt;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;res.records.map(r =&gt; (&#123;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;label: `Option
                    $&#123;r.newsItemId.toString().slice(0, 4)&#125;`,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;value:
                    r.newsItemId.toString(),
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;))
                    <br />
                    &nbsp;&nbsp;&nbsp;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;hasClear=&#123;!!values.testField2&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;onClear=&#123;() =&gt; &#123; setFieldValue?.('testField2',
                    ''); &#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;handleResponseOptions=&#123;(response:
                    IResponse&lt;INews[]&gt;) =&gt; &#123;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setExampleItemsResponse(response?.records);
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;console.log('exampleItemsResponse',
                    response?.records);
                    <br />
                    &nbsp;&nbsp;&nbsp;&#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;handleOptions=&#123;data =&gt; &#123;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setExampleItems(data);
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;console.log('exampleItems', data);
                    <br />
                    &nbsp;&nbsp;&nbsp;&#125;&#125;
                    <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
