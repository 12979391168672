import { FC, useContext } from 'react';
import { Page } from '../../components';
import { EstimateListPageDetails } from './estimates-list-details';
import { BrandingContext } from '../../context/branding-context';

export const EstimateListPage: FC = () => {
  const { isPoolService } = useContext(BrandingContext);
  return (
    <Page title="Estimates">
      <EstimateListPageDetails shouldUseSearchParams={isPoolService} />
    </Page>
  );
};
