import { FC, useContext, Fragment, useState } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { RateDetailsTable } from './rate-details-table';
// Components
import {
  Loader,
  TextField,
  ModalSaveSection as SaveButtonSection,
  Card,
} from '../../../components';
import {
  Box,
  Grid,
  FormControl,
  FormGroup,
  Checkbox,
  FormLabel,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { updateRate, createRate, getSingleRate } from '../../../fetch';
import { IRateItem } from '../../../models';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Paths } from '../../../constants';
import { RatesCustomersList } from './rates-customers-list';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { deepEqual } from 'fast-equals';
import { useConfirm } from '../../../hooks';

interface IAddEditRatePage {}

const Schema = Yup.object().shape({
  rateId: Yup.string(),
  monthsApply: Yup.string(),
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

const months = [
  { title: 'January', label: 'Jan', value: 'jan' },
  { title: 'February', label: 'Feb', value: 'feb' },
  { title: 'March', label: 'Mar', value: 'mar' },
  { title: 'April', label: 'Apr', value: 'apr' },
  { title: 'May', label: 'May', value: 'may' },
  { title: 'June', label: 'Jun', value: 'jun' },
  { title: 'July', label: 'Jul', value: 'jul' },
  { title: 'August', label: 'Aug', value: 'aug' },
  { title: 'September', label: 'Sep', value: 'sep' },
  { title: 'October', label: 'Oct', value: 'oct' },
  { title: 'November', label: 'Nov', value: 'nov' },
  { title: 'December', label: 'Dec', value: 'dec' },
];

export const AddEditRatePage: FC<IAddEditRatePage> = () => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rateId }: { rateId: string } = useParams();
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');
  const isNewRate = rateId === 'new';
  const { customerRates } = useFlags();
  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [hasSelectedAll, setHasSelectedAll] = useState(false);
  const confirm = useConfirm();

  const { isLoading, data: currentRate } = useQuery<IRateItem, Error>(
    ['getRateDetail', rateId],
    () => getSingleRate(rateId!),
    {
      enabled: !!rateId && rateId !== 'new',
      onSuccess: data => {
        if (data?.accountIds?.length) {
          setAccountIds(data?.accountIds);
        }
      },
    }
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          rateId: currentRate?.rateId ?? '',
          officeId: user?.officeId ?? '',
          code: currentRate?.code ?? '',
          description: currentRate?.description ?? '',
          monthsApply: currentRate?.monthsApply ?? '',
          jan: currentRate?.jan ?? true,
          feb: currentRate?.feb ?? true,
          mar: currentRate?.mar ?? true,
          apr: currentRate?.apr ?? true,
          may: currentRate?.may ?? true,
          jun: currentRate?.jun ?? true,
          jul: currentRate?.jul ?? true,
          aug: currentRate?.aug ?? true,
          sep: currentRate?.sep ?? true,
          oct: currentRate?.oct ?? true,
          nov: currentRate?.nov ?? true,
          dec: currentRate?.dec ?? true,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          // Assign Y/N values based on month checkboxes
          // The result will go into the database in that format
          let monthsApply = '';
          monthsApply += values.jan ? 'Y' : 'N';
          monthsApply += values.feb ? 'Y' : 'N';
          monthsApply += values.mar ? 'Y' : 'N';
          monthsApply += values.apr ? 'Y' : 'N';
          monthsApply += values.may ? 'Y' : 'N';
          monthsApply += values.jun ? 'Y' : 'N';
          monthsApply += values.jul ? 'Y' : 'N';
          monthsApply += values.aug ? 'Y' : 'N';
          monthsApply += values.sep ? 'Y' : 'N';
          monthsApply += values.oct ? 'Y' : 'N';
          monthsApply += values.nov ? 'Y' : 'N';
          monthsApply += values.dec ? 'Y' : 'N';

          if (currentRate) {
            currentRate.monthsApply = monthsApply;
          }

          const data = {
            code: values.code,
            description: values.description,
            accountIds,
            monthsApply,
          };
          try {
            if (currentRate) {
              await updateRate(currentRate?.rateId!, data);
              history.replace(redirect ?? Paths.rates.url);
            } else {
              const id = await createRate(data);
              enqueueSnackbar('Please add details for this new rate.', {
                variant: 'info',
              });
              history.replace(`${Paths.rates.url}/${id}`);
            }
            enqueueSnackbar(
              currentRate ? 'Successfully updated rate!' : 'Successfully created rate!',
              {
                variant: 'success',
              }
            );
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving rate, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ isSubmitting, handleSubmit, handleChange, isValid, values }) => {
          return (
            <Form onSubmit={handleSubmit} autoComplete="none">
              <Stack spacing={2}>
                <Card
                  cardTitleProps={{
                    title: currentRate ? 'Edit Rate' : 'Add Rate',
                  }}
                >
                  {(isSubmitting || isLoading) && <Loader type="fullscreen" position="centered" />}
                  <Box mt={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="code"
                          label="Code"
                          inputProps={{ maxLength: 10 }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="description"
                          label="Description"
                          inputProps={{ maxLength: 60 }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl component="fieldset" variant="standard">
                          <FormLabel component="legend">Months (select all that apply)</FormLabel>
                          <FormGroup
                            sx={{
                              position: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'left',
                              alignItems: 'left',
                            }}
                          >
                            {months.map(m => {
                              return (
                                <Fragment key={m.label}>
                                  <FormControlLabel
                                    title={m.title}
                                    control={
                                      <Checkbox
                                        checked={!!values[m.value as keyof typeof values]}
                                        onChange={handleChange}
                                        name={m.value}
                                      />
                                    }
                                    label={m.label}
                                  />
                                </Fragment>
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
                {!isNewRate && <RateDetailsTable rateId={currentRate?.rateId} />}
                {customerRates && (
                  <RatesCustomersList
                    accountIds={accountIds}
                    setAccountIds={setAccountIds}
                    hasSelectedAll={hasSelectedAll}
                    setHasSelectedAll={setHasSelectedAll}
                  />
                )}
              </Stack>
              <SaveButtonSection
                handleCancel={() => {
                  history.push(redirect ?? Paths.rates.url);
                }}
                isSaveDisabled={isSubmitting || !isValid}
                handleSave={async (e: any) => {
                  if (accountIds?.length > 0 && !deepEqual(accountIds, currentRate?.accountIds)) {
                    const result = await confirm(
                      `Are you sure you would like to apply rate code ${
                        currentRate?.code ?? values.code
                      } to ${accountIds.length} customer${
                        accountIds.length > 1 || accountIds.length === 0 ? 's' : ''
                      }?`
                    );
                    if (result) {
                      handleSubmit(e);
                    }
                  } else {
                    handleSubmit(e);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
