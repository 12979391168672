import { ExpandMore } from '@mui/icons-material';
import { IconButton, IconButtonProps, styled } from '@mui/material';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  setExpanded: (val: boolean) => void;
}

export const ExpandMoreButton = styled((props: ExpandMoreProps) => {
  const { expand, setExpanded, ...other } = props;
  return (
    <IconButton
      {...other}
      onClick={() => setExpanded(!expand)}
      aria-expanded={expand}
      aria-label="show more"
      data-testid="Expand.Button"
      style={expand ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
    >
      <ExpandMore />
    </IconButton>
  );
})(({ theme, expand }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
