import { GridSortDirection } from '@mui/x-data-grid';
import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse, INews, IGetFiltersResponse, INewsPost, ILookupModel } from '../models';
import { userSignal } from '../signals';

export const getNews = async (filters?: {
  sortBy?: string;
  sortDirection?: GridSortDirection | string;
  page?: number;
  perPage?: number | string;
  category?: string;
  officeId?: string;
  subjectSearch?: string;
  messageSearch?: string;
  displayDateStartDate?: string;
  displayDateEndDate?: string;
  takeDownDateStartDate?: string;
  takeDownDateEndDate?: string;
  createdDateStartDate?: string;
  createdDateEndDate?: string;
  onlyActive?: boolean;
}): Promise<IResponse<INews[]>> => {
  try {
    const { data } = await axios.get(`/api/news`, {
      params: {
        ...filters,
        officeId: filters?.officeId,
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getNewsFilters = async (officeId?: string | null) => {
  const { data } = await axios.get<IGetFiltersResponse>('/api/news/filters', {
    params: {
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    },
    paramsSerializer: params => qs.stringify(params),
  });
  const { filters } = data;
  return filters;
};

export const getSingleNewsItem = async (
  id: string | number,
  officeId?: string | null
): Promise<INews> => {
  try {
    const { data } = await axios.get(`/api/news/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createNewsItem = async (
  body: INewsPost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`/api/news`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateNewsItem = async (
  id: string | number,
  body: INewsPost,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`/api/news/${id}`, {
      ...body,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteNewsItem = async (id: string | number): Promise<any> => {
  try {
    const { data } = await axios.delete(`/api/news/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNewsCategories = async (officeId?: string | null): Promise<ILookupModel[]> => {
  try {
    const { data } = await axios.get(`/api/news/categories`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
