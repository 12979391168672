import { axios, getEnvCookieKey, getEnvironment } from '../helpers';
import { Cookies } from 'react-cookie';
import qs from 'querystring';
import {
  IForgotPasswordPost,
  ILoginPost,
  IResetPasswordPost,
  IUser,
  IChangePasswordPost,
  ILoginResponse,
} from '../models';
import { getUser } from './users';
import { addDays, addHours } from 'date-fns';
import { ROLES } from '../constants';

/**
 * calls context user
 * @returns user
 */
export const getMe = async (): Promise<IUser> => {
  try {
    const res = await axios.get('/api/me');
    const { data }: { data: IUser } = res;
    const user = await getUser(data.userId);
    return {
      ...user,
      ...data,
    };
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

/**
 * auths a user on login
 * @param data ILoginPost
 * @returns user
 */
export const login = async (data: ILoginPost): Promise<IUser> => {
  try {
    const res = await axios.post<ILoginResponse>('/api/authentication/login', data);
    if (res && res.status === 200) {
      const { data } = res;

      //update this with pinch domain after testing
      const domain = getEnvironment() !== 'localhost' ? '.pinchapenny.com' : 'localhost';

      const cookies = new Cookies();
      cookies.set(getEnvCookieKey(), data.accessToken, {
        secure: true,
        sameSite: 'none',
        domain: domain,
        expires:
          data.user.loginType === ROLES.Administrator
            ? addDays(new Date(), 1) // one day
            : addHours(new Date(), 6), // 6 hours
      });

      const user = await getUser(data.user.userId);
      return {
        ...user,
        ...data.user,
        accessToken: data.accessToken,
      };
    }
    const error = {
      Detail: 'Error logging in. Please try again.',
      status_code: 500,
    };
    return Promise.reject(error);
  } catch (error: any) {
    return error?.response?.data;
  }
};

/**
 * Send reset link
 * @param email string
 * @returns IForgotPasswordPost
 */
export const sendResetLink = async (data: IForgotPasswordPost): Promise<boolean> => {
  try {
    const res = await axios.post('/api/authentication/forgot-password', data);
    return res.data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};
/**
 * POST password reset
 * @param email string
 * @returns IResetPasswordPost
 */
export const resetPassword = async (data: IResetPasswordPost) => {
  try {
    const res = await axios.post('/api/authentication/reset-password', data);
    if (res && res.status === 200) {
      return true;
    } else {
      return res.data;
    }
  } catch (error: any) {
    return error.response.data;
  }
};

export const changePassword = async (data: IChangePasswordPost) => {
  try {
    const res = await axios.put('/api/authentication/password', data);
    return res.data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getPasswordHint = async (filters?: { loginName?: string }) => {
  try {
    const res = await axios.get('/api/authentication/password-hint', {
      params: filters,
      paramsSerializer: params => qs.stringify(params),
    });
    return res.data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const generateAccessToken = async (): Promise<{
  accessToken: string;
  expiresAt: string;
}> => {
  try {
    const { data } = await axios.get(`/api/authentication/generate-api-access-token`);

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
