import { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { AccountingExportTable } from './export-transactions-table';
import { AccountingExportParameters } from './accounting-export-parameters';
import { PriorExportBatchesTable } from './prior-export-batches-table';
import { IAccountExportSummary } from '../../../models';

export const AccountingExportDetails = () => {
  const [accountingExportData, setAccountingExportData] = useState<IAccountExportSummary | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [refetchExportBatches, setRefetchExportBatches] = useState<boolean>(false);
  const [dates, setDates] = useState<{ startDate: string | Date; endDate: string | Date }>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [hasProcessed, setHasProcessed] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack gap={2}>
          <AccountingExportParameters
            setAccountingExportData={setAccountingExportData}
            setIsSubmitting={setIsSubmitting}
            setDates={setDates}
            handleReset={() => {
              setAccountingExportData(null);
              setHasProcessed(false);
            }}
            dates={dates}
            hasProcessed={hasProcessed}
            setHasProcessed={setHasProcessed}
          />

          {((accountingExportData && accountingExportData?.transactions) || isSubmitting) && (
            <AccountingExportTable
              isSubmitting={isSubmitting}
              accountingExportData={accountingExportData}
              dates={dates}
              setRefetchExportBatches={setRefetchExportBatches}
              handleReset={() => {
                setAccountingExportData(null);
                setHasProcessed(false);
              }}
            />
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PriorExportBatchesTable
          setRefetchExportBatches={setRefetchExportBatches}
          refetchExportBatches={refetchExportBatches}
        />
      </Grid>
    </Grid>
  );
}