import { FC } from 'react';
import { Grid } from '@mui/material';
import { TextField } from '../../../components';

interface IInventoryCoreFields {
  isDisabled: boolean;
}

export const InventoryCoreFields: FC<IInventoryCoreFields> = ({ isDisabled }) => {
  return (
    <>
      <Grid item xs={12} md={4}>
        <TextField name="itemId" label="Item ID" required type="number" disabled={isDisabled} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField name="itemLookupCode" label="Lookup Code" required disabled={isDisabled} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="manufacturerNumber"
          label="Manufacturer Number"
          required
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField name="description" label="Description" minRows={3} multiline required />
      </Grid>
    </>
  );
};
