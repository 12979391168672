import {
  StyleGuide,
  StyleGuideButtons,
  StyleGuideCardButton,
  StyleGuideCardDisplayGroup,
  StyleGuideCardFloatingToolbar,
  StyleGuideCardTitle,
  StyleGuideCards,
  StyleGuideDateRangePicker,
  StyleGuideGridsOverview,
  StyleGuideIconPageHeading,
  StyleGuideLoader,
  StyleGuideModals,
  StyleGuidePageComponent,
  StyleGuidePageTitle,
  StyleGuidePagination,
  StyleGuideServerSideDataGrid,
  StyleGuideTableLegend,
  StyleGuideTabs,
  StyleGuideUIUXPatterns,
  StyleGuideDateRangeNavigator,
  StyleGuideTextField,
  StyleGuidePhoneTextField,
  StyleGuideWYSIWYG,
  StyleGuideSelectField,
  StyleGuideAutocompleteAsync,
  StyleGuideSwitch,
  StyleGuideSelectChip,
  StyleGuideRadioGroup,
  StyleGuideCheckbox,
  StyleGuideDateTimePicker,
  StyleGuideTimePicker,
  StyleGuideAmountInput,
  StyleGuideSearchField,
  StyleGuideSelectAsync,
  StyleGuideCreditCardInputs,
  StyleGuideEditAddressInfo,
  StyleGuideToggleButtonGroup,
} from '../pages';
import { StyleGuideConfirmDialog } from '../pages/style-guide/components/confirm-dialog';
import { StyleGuideUploads } from '../pages/style-guide/components/file-upload';
import { StyleGuideMaps } from '../pages/style-guide/components/maps';
import { StyleGuideSendEmailModal } from '../pages/style-guide/components/send-email-modal';
import { StyleGuideSignature } from '../pages/style-guide/components/signature';
import { StyleGuidePaths } from './style-guide-links';
// This file is intended to make sharing style guide routes easier across App and App.360
export const styleGuideRoutes = [
  { path: StyleGuidePaths.kitchenSink.url, child: <StyleGuide /> },
  { path: StyleGuidePaths.buttons.url, child: <StyleGuideButtons /> },
  { path: StyleGuidePaths.cardsPrimary.url, child: <StyleGuideCards /> },
  { path: StyleGuidePaths.cardButton.url, child: <StyleGuideCardButton /> },
  { path: StyleGuidePaths.cardTitle.url, child: <StyleGuideCardTitle /> },
  { path: StyleGuidePaths.grids.url, child: <StyleGuideGridsOverview /> },
  { path: StyleGuidePaths.dataGrid.url, child: <StyleGuideServerSideDataGrid /> },
  { path: StyleGuidePaths.tableLegend.url, child: <StyleGuideTableLegend /> },
  { path: StyleGuidePaths.pagination.url, child: <StyleGuidePagination /> },
  { path: StyleGuidePaths.textField.url, child: <StyleGuideTextField /> },
  { path: StyleGuidePaths.phoneTextField.url, child: <StyleGuidePhoneTextField /> },
  { path: StyleGuidePaths.amountInput.url, child: <StyleGuideAmountInput /> },
  { path: StyleGuidePaths.selectField.url, child: <StyleGuideSelectField /> },
  { path: StyleGuidePaths.selectChip.url, child: <StyleGuideSelectChip /> },
  { path: StyleGuidePaths.switch.url, child: <StyleGuideSwitch /> },
  { path: StyleGuidePaths.autocompleteAsync.url, child: <StyleGuideAutocompleteAsync /> },
  { path: StyleGuidePaths.selectAsync.url, child: <StyleGuideSelectAsync /> },
  { path: StyleGuidePaths.searchField.url, child: <StyleGuideSearchField /> },
  { path: StyleGuidePaths.radioGroup.url, child: <StyleGuideRadioGroup /> },
  { path: StyleGuidePaths.checkbox.url, child: <StyleGuideCheckbox /> },
  { path: StyleGuidePaths.wysiwyg.url, child: <StyleGuideWYSIWYG /> },
  { path: StyleGuidePaths.dateTimePicker.url, child: <StyleGuideDateTimePicker /> },
  { path: StyleGuidePaths.timePicker.url, child: <StyleGuideTimePicker /> },
  { path: StyleGuidePaths.dateRangePicker.url, child: <StyleGuideDateRangePicker /> },
  { path: StyleGuidePaths.dateRangeNavigator.url, child: <StyleGuideDateRangeNavigator /> },
  { path: StyleGuidePaths.toggleButtonGroup.url, child: <StyleGuideToggleButtonGroup /> },
  { path: StyleGuidePaths.creditCardInputs.url, child: <StyleGuideCreditCardInputs /> },
  { path: StyleGuidePaths.editAddressInfo.url, child: <StyleGuideEditAddressInfo /> },
  { path: StyleGuidePaths.displayGroup.url, child: <StyleGuideCardDisplayGroup /> },
  { path: StyleGuidePaths.loader.url, child: <StyleGuideLoader /> },
  { path: StyleGuidePaths.modals.url, child: <StyleGuideModals /> },
  { path: StyleGuidePaths.page.url, child: <StyleGuidePageComponent /> },
  { path: StyleGuidePaths.pageTitle.url, child: <StyleGuidePageTitle /> },
  { path: StyleGuidePaths.iconPageHeading.url, child: <StyleGuideIconPageHeading /> },
  { path: StyleGuidePaths.tabs.url, child: <StyleGuideTabs /> },
  { path: StyleGuidePaths.floatingToolbar.url, child: <StyleGuideCardFloatingToolbar /> },
  { path: StyleGuidePaths.uiUxPatterns.url, child: <StyleGuideUIUXPatterns /> },
  { path: StyleGuidePaths.uploadComponents.url, child: <StyleGuideUploads /> },
  { path: StyleGuidePaths.confirmDialog.url, child: <StyleGuideConfirmDialog /> },
  { path: StyleGuidePaths.sendEmailModal.url, child: <StyleGuideSendEmailModal /> },
  { path: StyleGuidePaths.signature.url, child: <StyleGuideSignature /> },
  { path: StyleGuidePaths.maps.url, child: <StyleGuideMaps /> },
];
