import { Grid, TextField as MuiTextField, Box, Autocomplete } from '@mui/material';
import {
  SelectAsync,
  Modal,
  ModalSaveSection,
  TextField,
  Loader,
  AmountInput,
} from '../../components';
import { FC, useState, useMemo, Dispatch, SetStateAction, useEffect } from 'react';
import {
  getEstimateLaborFeeTypes,
  getEstimateLaborDurations,
  getInventory,
  calculateLaborRate,
} from '../../fetch';
import {
  IDropdownResponse,
  IInventory,
  IResponse,
  IEstimateLineItem,
  IEstimateLineItemDetail,
} from '../../models';
import { Form, FormikErrors, useFormikContext, FormikTouched } from 'formik';
import { formatMoney, convertToNumber } from '../../helpers';
import { useDebounce } from 'react-use';
import { useQuery } from 'react-query';

interface IEstimateLaborRateModalDetails {
  lineItemId: string | null;
  estimateLineItems: IEstimateLineItem[];
  isOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  isSubmitting: boolean;
  isValid: boolean;
  isLoadingLineItem: boolean;
  lineItem?: IEstimateLineItemDetail;
  setPostLaborOptions: Dispatch<SetStateAction<IResponse<IInventory[]> | null>>;
  isEditable?: boolean;
}

const TOTAL_CHARGES_DEBOUNCE = 2000;

export const EstimateLaborRateModalDetails: FC<IEstimateLaborRateModalDetails> = ({
  lineItemId,
  isLoadingLineItem,
  estimateLineItems,
  isOpen,
  onClose,
  isSubmitting,
  isValid,
  lineItem,
  setPostLaborOptions,
  isEditable = true,
}) => {
  const {
    values,
    setFieldValue,
    touched,
    errors,
    dirty,
    handleBlur,
  }: {
    dirty: boolean;
    handleBlur: any;
    values: any;
    setFieldValue: (key: string, value: any) => void;
    touched: FormikTouched<any>;
    errors: FormikErrors<any>;
  } = useFormikContext();
  const [totalCharges, setTotalCharges] = useState('');
  const currentSortNumbers = useMemo(
    () => estimateLineItems.map(line => line.sortOrder),
    [estimateLineItems]
  );

  const loadTotalCharges = async () => {
    try {
      setTotalCharges('Loading...');
      if (convertToNumber(values.initialLaborFees) > 0 && values.initialLaborFeesDuration) {
        const laborRate = await calculateLaborRate({
          laborFeeType: values.laborFeeType,
          fixedLaborRate: convertToNumber(values.fixedLaborRate),
          initialLaborfees: convertToNumber(values.initialLaborFees),
          additionalLaborFees: convertToNumber(values.additionalLaborFees),
          initialLaborFeesDuration: values.initialLaborFeesDuration,
          estimatedLaborDuration: convertToNumber(values.estimatedLaborDuration),
        });
        setTotalCharges(formatMoney(laborRate, 2));
        setFieldValue('totalCharges', laborRate);
      } else {
        setTotalCharges('');
      }
    } catch {
      setTotalCharges('');
    }
  };

  useDebounce(
    () => {
      loadTotalCharges();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    TOTAL_CHARGES_DEBOUNCE,
    [
      values.laborFeeType,
      values.fixedLaborRate,
      values.initialLaborFees,
      values.additionalLaborFees,
      values.initialLaborFeesDuration,
      values.estimatedLaborDuration,
    ]
  );
  const currentFormOrderNumber = Number(values.sortOrder);
  const currentOrderNumber = Number(lineItem?.sortOrder!);
  const hasCurrentOrderNumber = currentSortNumbers?.includes(currentFormOrderNumber);
  const hasSameOrderNumber = !lineItemId
    ? // if we are adding a new line item check its current form value with previous existing line item values
      hasCurrentOrderNumber
    : hasCurrentOrderNumber && currentOrderNumber !== currentFormOrderNumber;

  const { isLoading: isLoadingLaborRates, data: laborRates } = useQuery(
    ['getInventory'],
    async () => {
      const res = await getInventory({
        perPage: -1,
        isForLabor: true,
        sortBy: 'LookupCode',
      });
      return res;
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: isOpen,
      onSuccess: data => {
        setPostLaborOptions(data);
      },
    }
  );
  const [selectedLaborRate, setSelectedLaborRate] = useState<IInventory | null>(null);
  useEffect(() => {
    if (lineItem && !!lineItem?.storeInventoryId && laborRates) {
      // If editing labor item, update selectedLaborRate to reflect the current lineItem postLaborChargesAs value (aka storeInventoryId)
      const selected = laborRates?.records?.filter(
        option => option.inventoryId === lineItem?.storeInventoryId
      );
      setSelectedLaborRate((selected && selected[0]) ?? null);
    }
  }, [laborRates, lineItem]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        setSelectedLaborRate(null);
      }}
      title={`${lineItemId && !isEditable ? 'View' : lineItemId ? 'Edit' : 'Add'} Labor Rate`}
      maxWidth="md"
    >
      <Form>
        <Box mt={2}>
          {(isLoadingLineItem || isSubmitting) && <Loader type="overlay" position="centered" />}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <MuiTextField
                fullWidth
                name="sortOrder"
                required
                size="small"
                label="Line"
                type="number"
                value={values.sortOrder}
                onBlur={handleBlur}
                onKeyDown={event => {
                  if (
                    !/[\d]/.test(event.key) &&
                    !/Backspace|Delete|ArrowLeft|ArrowRight/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={e => {
                  setFieldValue('sortOrder', e.target.value);
                }}
                error={hasSameOrderNumber ? true : touched.sortOrder && !!errors.sortOrder}
                helperText={
                  hasSameOrderNumber
                    ? 'Line is already in use'
                    : touched.sortOrder && errors.sortOrder
                    ? errors.sortOrder
                    : ''
                }
                disabled={!isEditable}
                InputProps={
                  isEditable
                    ? {
                        onFocus: e => {
                          e.currentTarget.select();
                        },
                      }
                    : undefined
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Autocomplete
                fullWidth
                value={selectedLaborRate}
                disabled={!isEditable || isLoadingLaborRates}
                onChange={(e, newValue: any) => {
                  if (newValue && newValue?.inventoryId) {
                    setSelectedLaborRate(newValue);
                    setFieldValue('postLaborChargesAs', +newValue?.inventoryId);
                    if (!values.description) {
                      setFieldValue('details', newValue?.description);
                    }
                    // set the initial price when the post labor charges as changes...
                    if (values.laborFeeType === 'Fixed') {
                      setFieldValue(
                        'fixedLaborRate',
                        newValue?.price! > 0 ? formatMoney(newValue?.price!) : '$0.00'
                      );
                    }
                    // set the initial price when the post labor charges as changes...
                    if (values.laborFeeType === 'Variable') {
                      setFieldValue(
                        'initialLaborFees',
                        newValue?.price! > 0 ? formatMoney(newValue?.price!) : '$0.00'
                      );
                    }
                  } else {
                    setSelectedLaborRate(null);
                    setFieldValue('postLaborChargesAs', '');
                    setFieldValue('details', '');
                    setFieldValue('description', '');
                    if (values.laborFeeType === 'Fixed') {
                      setFieldValue('fixedLaborRate', '$0.00');
                    }
                    if (values.laborFeeType === 'Variable') {
                      setFieldValue('initialLaborFees', '$0.00');
                    }
                  }
                }}
                options={laborRates?.records ?? []}
                getOptionLabel={option => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return `${option.lookupCode} (${option.description})`;
                }}
                renderOption={(props: any, option: IInventory) => {
                  return (
                    <li {...props} key={option.inventoryId}>
                      {`${option.lookupCode} (${option.description})`}
                    </li>
                  );
                }}
                renderInput={params => {
                  return (
                    <MuiTextField
                      {...params}
                      size="small"
                      label={isLoadingLaborRates ? 'Loading...' : 'Post Labor Charges as'}
                      required
                      fullWidth
                      autoComplete="none"
                      variant="outlined"
                      name="postLaborChargesAs"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                name="details"
                label="Description"
                disabled={!isEditable}
                autoSelectOnFocus={isEditable}
                clearValue={!values.details}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectAsync
                name="laborFeeType"
                label="Labor Fees Type"
                required
                apiRequest={() => getEstimateLaborFeeTypes()}
                transformResponse={(response: IDropdownResponse[]) => {
                  return response.map(record => ({
                    label: record.description,
                    value: record.value,
                  }));
                }}
                hasClear
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue('laborFeeType', e.target.value);
                  if (e.target.value === 'Variable') {
                    setFieldValue('initialLaborFees', values.fixedLaborRate);
                  }
                  if (e.target.value === 'Fixed') {
                    setFieldValue('fixedLaborRate', values.initialLaborFees);
                    setFieldValue('initialLaborFeesDuration', '');
                    setFieldValue('additionalLaborFees', '');
                    setFieldValue('estimatedLaborDuration', '');
                    setFieldValue('chargedLaborDuration', '');
                  }
                }}
                disabled={!isEditable}
              />
            </Grid>
            {values.laborFeeType === 'Fixed' && (
              <Grid item xs={12} sm={6} md={4}>
                <AmountInput
                  name="fixedLaborRate"
                  label="Fixed Labor Fees"
                  required={values.laborFeeType === 'Fixed'}
                  disabled={!isEditable}
                  autoSelectOnFocus={isEditable}
                  onChange={val => setFieldValue('fixedLaborRate', val)}
                  value={values.fixedLaborRate}
                />
              </Grid>
            )}
            {values.laborFeeType === 'Variable' && (
              <>
                <Grid item xs={12} sm={6} lg={4}>
                  <AmountInput
                    name="initialLaborFees"
                    label="Services shall be performed at an initial rate of..."
                    onBlur={e => {
                      setFieldValue('initialLaborFees', formatMoney(e.target.value, 2));
                    }}
                    disabled={!isEditable}
                    autoSelectOnFocus={isEditable}
                    required={values.laborFeeType === 'Variable'}
                    onChange={val => setFieldValue('initialLaborFees', val)}
                    value={values.initialLaborFees}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <SelectAsync
                    name="initialLaborFeesDuration"
                    label="Which includes the first..."
                    required={values.laborFeeType === 'Variable'}
                    apiRequest={() => getEstimateLaborDurations()}
                    transformResponse={(response: IDropdownResponse[]) => {
                      return response.map(record => ({
                        label: record.description,
                        value: record.value,
                      }));
                    }}
                    hasClear
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <AmountInput
                    name="additionalLaborFees"
                    label="Thereafter billed this much in 15 minute increments"
                    onBlur={e => {
                      setFieldValue('additionalLaborFees', formatMoney(e.target.value, 2));
                    }}
                    disabled={!isEditable}
                    autoSelectOnFocus={isEditable}
                    required={values.laborFeeType === 'Variable'}
                    onChange={val => setFieldValue('additionalLaborFees', val)}
                    value={values.additionalLaborFees}
                  />
                </Grid>
              </>
            )}

            {values.laborFeeType === 'Variable' && (
              <>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    required={values.laborFeeType === 'Variable'}
                    name="estimatedLaborDuration"
                    label="Est. Labor Duration"
                    type="number"
                    disabled={!isEditable}
                    autoSelectOnFocus={isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <MuiTextField
                    fullWidth
                    size="small"
                    label="Total Charges"
                    value={totalCharges}
                    disabled
                    InputLabelProps={{ shrink: !!totalCharges }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <ModalSaveSection
          isSaveDisabled={
            !isEditable ||
            isSubmitting ||
            !isValid ||
            !dirty ||
            (values.laborFeeType === 'Variable' && !totalCharges && totalCharges !== 'Loading...')
          }
          handleCancel={() => {
            onClose();
            setSelectedLaborRate(null);
          }}
        />
      </Form>
    </Modal>
  );
};
