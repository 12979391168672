import { FC } from 'react';
import { Page } from '../../../components';
import { Box } from '@mui/material';
import { OtsDetails } from './ots-details';

export const OtsPage: FC = () => {
  return (
    <Page title="One-time Services">
      <Box mt={2}>
        <OtsDetails />
      </Box>
    </Page>
  );
};
