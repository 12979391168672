import { FC, useContext } from 'react';
import { useFormikContext } from 'formik';
import { Grid, InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { TextField, SelectAsync } from '../../components/formikMui';
import { IListUser, IAccount, IResponse } from '../../models';
import { UserContext } from '../../context/user';
import { getAccounts, getUsers } from '../../fetch';

interface EditSiteInformationProps {
  handleFormChange: (val?: any, isDirty?: boolean) => void;
}

export const EditSiteInformation: FC<EditSiteInformationProps> = ({ handleFormChange }) => {
  const { setFieldValue, handleBlur, values } = useFormikContext<any>();
  const { user } = useContext(UserContext);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={12}>
        <TextField
          name="siteDescription"
          label="Site Description"
          required
          onBlur={e => {
            handleBlur(e);
            handleFormChange();
          }}
          InputLabelProps={{ shrink: !!values.siteDescription }}
        />
      </Grid>
      {user?.officeId && (
        <Grid item xs={12} sm={6} md={12}>
          <SelectAsync
            name="mgrAccountId"
            label="Management Company"
            apiRequest={() =>
              getAccounts({
                officeId: user.officeId,
                accountType: 'ManagementCompany',
                perPage: -1,
              })
            }
            transformResponse={(response: IResponse<IAccount[]>) => {
              return response.records.map(record => ({
                label: record.accountName,
                value: record.accountId,
              }));
            }}
            InputProps={{
              endAdornment: !!values.mgrAccountId && (
                <InputAdornment
                  position="end"
                  sx={{
                    position: 'absolute',
                    right: 35,
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    title="Clear"
                    onClick={() => {
                      setFieldValue('mgrAccountId', '');
                      handleFormChange({ ...values, mgrAccountId: '' }, true);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            onBlur={e => {
              handleBlur(e);
              handleFormChange();
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={12}>
        <SelectAsync
          name="userId"
          label="Site Supervisor"
          apiRequest={() => getUsers({ perPage: -1 })}
          transformResponse={(response: IResponse<IListUser[]>) => {
            return response.records.map(record => ({
              label: `${record.userName} ${record.isDisabled ? '(Disabled)' : ''}`,
              value: record.userId,
              disabled: record.isDisabled,
            }));
          }}
          InputProps={{
            endAdornment: !!values.userId && (
              <InputAdornment
                position="end"
                sx={{
                  position: 'absolute',
                  right: 35,
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  title="Clear"
                  onClick={() => {
                    setFieldValue('userId', '');
                    handleFormChange({ ...values, userId: '' }, true);
                  }}
                />
              </InputAdornment>
            ),
          }}
          onBlur={e => {
            handleBlur(e);
            handleFormChange();
          }}
        />
      </Grid>
    </Grid>
  );
};
