import { Box, FormControlLabel, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';
import { Checkbox } from '../../../components';

export const StyleGuideCheckbox = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Checkbox">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Checkbox is built upon the{' '}
            <Link href="https://mui.com/material-ui/react-checkbox/" target="_blank">
              Mui Checkbox
            </Link>{' '}
            component and{' '}
            <Link href="https://formik.org/docs/overview" target="_blank">
              Formik
            </Link>
            . It has built-in styles, and Formik props required for use with a Formik form and form
            validation.
          </Typography>
          <Typography mb={1}>
            Checboxes should be wrapped in a FormControlLabel compoent. The most common use-case for
            this component is a single checkbox indicating whether an option is available/active or
            not.
          </Typography>

          <code>
            Props:
            <br />- name: string
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '',
          testField2: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ handleBlur }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box mb={1} mt={2}>
                    <FormControlLabel
                      control={<Checkbox name="testField" />}
                      label={<Typography variant="body2">Has Example?</Typography>}
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;FormControlLabel control=&#123;&lt;Checkbox name="testField" /&gt;&#125;
                    label=&#123;&lt;Typography variant="body2"&gt;Has
                    Example?&lt;/Typography&gt;&#125; /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
