import { Box, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';
import { Form, Formik } from 'formik';
import { SearchField } from '../../../components';

export const StyleGuideSearchField = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="Search Field">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            The Search Field component is a custom build upon the{' '}
            <Link href="https://mui.com/material-ui/react-text-field/#select" target="_blank">
              MUI Text Field
            </Link>{' '}
            component. It has a built-in styles, an input adornment, a clear button, and keypress
            functionality.
          </Typography>
          <code>
            Props:
            <br />- searchValue: string
            <br />- handleSearch?: (val: string) =&gt; void
            <br />- isDisabled?: boolean
            <br />- setSearchValue: (val: string) =&gt; void
            <br />- handleSearchIcon?: (val?: boolean) =&gt; void
            <br />- handleClearSearch?: () =&gt; void
            <br />- placeholder?: string
            <br />- className?: string
            <br />- label?: string
            <br />- shouldAutoFocus?: boolean
          </code>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          testField: '',
        }}
        onSubmit={values => {
          // Do Nothing
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box mb={1} p={0.5}>
                    <SearchField
                      label="Example Search"
                      handleSearch={val => {}}
                      setSearchValue={val => {
                        setFieldValue('testField', val);
                      }}
                      placeholder="Search..."
                      searchValue={values.testField}
                      shouldAutoFocus
                    />
                  </Box>
                  <CodeBlock
                    handleCopyClick={e => handleCopyClick(setIsCopied, e)}
                    isCopied={isCopied}
                  >
                    &lt;SearchField
                    <br />
                    &nbsp;&nbsp;&nbsp;label="Example Search"
                    <br />
                    &nbsp;&nbsp;&nbsp;handleSearch=&#123;val =&gt; &#123;&#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;setSearchValue=&#123;val =&gt; &#123;
                    setFieldValue('testField', val); &#125;&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;placeholder="Search..."
                    <br />
                    &nbsp;&nbsp;&nbsp;searchValue=&#123;values.testField&#125;
                    <br />
                    &nbsp;&nbsp;&nbsp;shouldAutoFocus
                    <br />
                    /&gt;
                  </CodeBlock>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StyleGuidePage>
  );
};
