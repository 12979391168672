import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip, Typography, Box, styled } from '@mui/material';
import { InvalidLocationIcon } from '../../../components';
import { ICalendarView, IService, ITechnician } from '../../../models';
import { FC } from 'react';
import {
  faBroom,
  faFileInvoice,
  faFlask,
  faExclamationCircle,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { isRouteStartOrEnd } from '../utils';
import clsx from 'clsx';

interface NonOfficeItemProps {
  service: IService;
  allowOptimization: boolean;
  handleSiteChange: (id: string | number) => void;
  routeId: string | number;
  tech: ITechnician;
  handleOptimizationClick: (e: any, tech: ITechnician) => void;
  view: ICalendarView;
}

export const NonOfficeItem: FC<NonOfficeItemProps> = ({
  service,
  allowOptimization,
  handleSiteChange,
  handleOptimizationClick,
  routeId,
  tech,
  view = ICalendarView.Day,
}) => {
  const isStartOrEnd = isRouteStartOrEnd(service);
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 188, 231, .25)',
        borderBottom: service.siteName === 'Route Start' ? '1px solid black' : 0,
        borderTop: service.siteName === 'Route End' ? '1px solid black' : 0,
        borderRadius: isStartOrEnd ? 0 : '6px',
        margin: isStartOrEnd ? 0 : '8px 0',
        padding: '8px 5px 8px 5px',
      }}
      className={clsx(`view-${view}`)}
    >
      <ServiceName>{service.siteName}</ServiceName>
      <Box flex={1} display="flex" alignItems="center">
        {service.recurringServiceId && (
          <Tooltip title="This is a recurring service">
            <span>
              <StyledIcon icon={faRefresh} size="lg" />
            </span>
          </Tooltip>
        )}
        {service.showBeakerIcon && (
          <Tooltip title="Service">
            <span>
              <StyledIcon icon={faFlask} size="lg" />
            </span>
          </Tooltip>
        )}
        {service.showBrushIcon && (
          <Tooltip title="Cleaning">
            <span>
              <StyledIcon icon={faBroom} size="lg" />
            </span>
          </Tooltip>
        )}
        {service.showWrenchIcon && (
          <Tooltip title="Repair">
            <span>
              <StyledIcon icon={faFileInvoice} size="lg" />
            </span>
          </Tooltip>
        )}
        {(!service.latitude || !service.longitude) && (
          <InvalidLocationIcon
            accountId={service.accountId}
            siteId={service.siteId}
            handleSiteChange={() => handleSiteChange(routeId)}
          />
        )}
        {!service.isSorted && (
          <Tooltip title="This service has not been sorted">
            <span>
              <IconButton
                disableRipple={allowOptimization}
                onClick={e => handleOptimizationClick(e, tech)}
                sx={{ padding: 0 }}
                component="div"
              >
                <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const ServiceName = styled(Typography)(({ theme }) => ({
  fontSize: '0.85rem',
  fontWeight: 'bold',
  lineHeight: 1,
}));

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  borderRadius: '50%',
  fontSize: 16,
  marginRight: 5,
  color: theme.palette.primary.light,
}));
