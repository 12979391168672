import {
  AdyenPlatformExperience,
  TransactionsOverview,
} from '@adyen/adyen-platform-experience-web';
import '@adyen/adyen-platform-experience-web/dist/style.css';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { getAdyenDetails, getAdyenTransactionSession } from '../../../fetch';
import { Card, Loader } from '../../../components';
import { UserContext } from '../../../context';
import { styled, useMediaQuery } from '@mui/material';
import { AdyenVerificationStatus, PaymentProcessor } from '../../../models';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-query';
const { REACT_APP_AYDEN_ENV } = require('../../../buildSettings.json');

interface IAdyenTransactionsProps {
  containerId?: string;
}

export const AdyenTransactions: FC<IAdyenTransactionsProps> = ({ containerId }) => {
  const { user, paymentProcessor } = useContext(UserContext);
  const [isLoadingSession, setIsLoadingSession] = useState<boolean>(false);
  const isMobile = useMediaQuery(`(max-width: 767px)`);
  const flags = useFlags();

  const { data: adyenDetails } = useQuery(
    'adyenDetails-transactions',
    () => getAdyenDetails(user?.officeId!),
    {
      enabled: !!(paymentProcessor?.paymentProcessor === PaymentProcessor.Adyen && flags?.adyen),
    }
  );
  const hasValidAdyenOnboarding = useMemo(
    () =>
      adyenDetails?.data.every(data => data.verificationStatus === AdyenVerificationStatus.Valid),
    [adyenDetails]
  );

  useEffect(() => {
    const setupAdyenPlatformExperience = async () => {
      const core = await AdyenPlatformExperience({
        environment: REACT_APP_AYDEN_ENV,
        async onSessionCreate() {
          setIsLoadingSession(true);
          const res = await getAdyenTransactionSession({
            officeId: user?.officeId ?? '',
          });
          setIsLoadingSession(false);
          return res;
        },
      });
      const transactionsOverview = new TransactionsOverview({ core });

      transactionsOverview.mount('#transactions-overview-container');
    };
    if (
      paymentProcessor &&
      paymentProcessor.paymentProcessor === PaymentProcessor.Adyen &&
      adyenDetails?.isOnboarded &&
      hasValidAdyenOnboarding
    ) {
      setupAdyenPlatformExperience();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentProcessor, adyenDetails, hasValidAdyenOnboarding]);

  if (
    paymentProcessor &&
    paymentProcessor.paymentProcessor === PaymentProcessor.Adyen &&
    hasValidAdyenOnboarding &&
    adyenDetails?.isOnboarded &&
    flags?.adyen
  ) {
    return (
      <Card
        cardTitleProps={{
          title: 'Adyen Transactions',
          marginBottom: isMobile ? undefined : 0,
        }}
      >
        {isLoadingSession && <Loader type="overlay" />}
        <StyledOverviewContainer id={containerId ?? 'transactions-overview-container'} />
      </Card>
    );
  }
  return <></>;
};

const StyledOverviewContainer = styled('div')(({ theme }) => ({
  [`& .adyen-pe-modal-wrapper`]: {
    top: '64px',
  },
  '.adyen-pe-component': {
    border: 'none',
    '--adyen-pe-content-padding': '0',
    padding: '0',
  },
  '& .adyen-pe-transactions-overview > .adyen-pe-typography--title-m': {
    display: 'none',
  },
  '& .adyen-pe-pagination__controls': {
    marginRight: theme.spacing(4),
  },
}));
