import { FC, Fragment, useEffect, useState } from 'react';
import {
  IListUser,
  ILookupModel,
  IServiceLocation,
  ITechnicianDayOff,
  ITechnicianRole,
  ITechnicianUpdatedDayOff,
  ITechnicianUserDetail,
} from '../../../models';
import { useFormikContext } from 'formik';
import {
  DaysAvailableSelector,
  Label,
  UserAutocomplete,
  ServiceLocationAutocomplete,
  Loader,
  DatePickerPopover,
} from '../../../components';
import { Stack, FormControlLabel, Checkbox, Box, Grid, Button } from '@mui/material';
import { useQuery } from 'react-query';
import { getTechnicianRoles, techDateHasServices } from '../../../fetch';
import { DaysOffList } from './days-off-list';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate, formatRawDate } from '../../../helpers';

interface ITechManagementAddEditModalContentProps {
  selectedTech?: string | null;
  daysOff: ITechnicianDayOff[];
  setDaysOff: (daysOff: ITechnicianDayOff[]) => void;
  deletedDaysOff: string[];
  setDeletedDaysOff: (deletedDaysOff: string[]) => void;
  updatedDaysOff: ITechnicianUpdatedDayOff[];
  setUpdatedDaysOff: (updatedDaysOff: ITechnicianUpdatedDayOff[]) => void;
  addedDaysOff: { dayOff: string; hasServices: boolean }[];
  setAddedDaysOff: React.Dispatch<
    React.SetStateAction<
      {
        dayOff: string;
        hasServices: boolean;
      }[]
    >
  >;
}
export const AddEditTechManagementModalContent: FC<ITechManagementAddEditModalContentProps> = ({
  selectedTech,
  daysOff,
  setDaysOff,
  deletedDaysOff,
  setDeletedDaysOff,
  updatedDaysOff,
  setUpdatedDaysOff,
  addedDaysOff,
  setAddedDaysOff,
}) => {
  const { data, isLoading } = useQuery<ILookupModel[], Error>('getTechRoles', () =>
    getTechnicianRoles()
  );

  const { values, setFieldValue, errors, touched, handleBlur } =
    useFormikContext<ITechnicianUserDetail>();

  const [users, setUsers] = useState<IListUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IListUser | null>(null);
  const [locations, setLocations] = useState<IServiceLocation[] | null>(null);
  const [selectedStartingLocation, setSelectedStartingLocation] = useState<IServiceLocation | null>(
    null
  );
  const [disabledDates, setDisabledDates] = useState<string[]>([]);
  const [hasAssignedServices, setHasAssignedServices] = useState<boolean>(false);

  useEffect(() => {
    // If editing technician, update autocomplete states to reflect the current values
    const userIdValue = users.find(option => option.userId === values.userId) ?? null;
    setSelectedUser(userIdValue);

    const startingLocationAddressValue =
      locations?.find(option => option.serviceLocationId === values.serviceLocationId) ?? null;
    setSelectedStartingLocation(startingLocationAddressValue);
  }, [users, values.userId, locations, values.serviceLocationId]);

  const [calPopOverAnchorEl, setCalPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleDatePickerClose = () => {
    setCalPopoverAnchorEl(null);
  };

  const handleAddDaysOff = async (dayOff: string | Date) => {
    const newDay = formatDate(dayOff) ?? '';
    const rawDate = formatRawDate(new Date(dayOff)) ?? '';

    if ((await techDateHasServices(values.userId, rawDate)) === true) {
      setAddedDaysOff([...addedDaysOff, { dayOff: newDay, hasServices: true }]);
    } else {
      setAddedDaysOff([...addedDaysOff, { dayOff: newDay, hasServices: false }]);
    }
    setCalPopoverAnchorEl(null);
  };

  useEffect(() => {
    let disabledDaysOff = daysOff?.map(day => formatDate(day.dayOff)) as string[];
    let disabledAddedDaysOff = addedDaysOff?.map(day => formatDate(day.dayOff)) as string[];
    let disabledUpdatedDaysOff = updatedDaysOff?.map(day => formatDate(day.dayOff)) as string[];

    setDisabledDates([...disabledDaysOff, ...disabledAddedDaysOff, ...disabledUpdatedDaysOff]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysOff, addedDaysOff, updatedDaysOff]);

  return (
    <>
      <Stack gap={2} mt={1}>
        {!selectedTech && (
          <UserAutocomplete
            setSelectedUser={val => {
              setSelectedUser(val);
              setFieldValue('userId', val?.userId ?? '');
            }}
            selectedUser={selectedUser}
            handleOptions={val => setUsers(val)}
            isRequired={true}
            labelText="User"
            hasError={!!errors?.userId && !!touched?.userId}
            helperText={!!touched?.userId && !!errors?.userId ? errors?.userId : ''}
            handleBlur={handleBlur}
            filters={{ perPage: -1, excludeTechnicians: true }}
          />
        )}
        <DaysAvailableSelector
          fullWidth
          id="daysAvailable"
          daysAvailable={values.daysAvailable}
          onChange={(e, val) => setFieldValue('daysAvailable', val)}
        />
        {(selectedUser || selectedTech) && (
          <Box mt={2}>
            <Grid container>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <Label labelFor="daysOff" sx={{ position: 'relative', top: '-.15rem' }}>
                    Days Off
                  </Label>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                  <Button
                    onClick={e => setCalPopoverAnchorEl(e.currentTarget)}
                    variant="outlined"
                    color="primary"
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                  >
                    Add Day Off
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'right' }}>
                <DaysOffList
                  daysOff={daysOff}
                  setDaysOff={setDaysOff}
                  deletedDaysOff={deletedDaysOff}
                  setDeletedDaysOff={setDeletedDaysOff}
                  updatedDaysOff={updatedDaysOff}
                  setUpdatedDaysOff={setUpdatedDaysOff}
                  addedDaysOff={addedDaysOff}
                  setAddedDaysOff={setAddedDaysOff}
                  hasAssignedServices={hasAssignedServices}
                  setHasAssignedServices={setHasAssignedServices}
                  disabledDates={disabledDates}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box mt={1}>
          <ServiceLocationAutocomplete
            setSelectedServiceLocation={val => {
              setSelectedStartingLocation(val);
              setFieldValue('serviceLocationId', val?.serviceLocationId ?? null);
            }}
            selectedServiceLocation={selectedStartingLocation}
            labelText="Starting Location"
            helperText="If unset, the office's address will be used."
            isRequired={false}
            handleBlur={handleBlur}
            handleOptions={val => setLocations(val)}
          />
        </Box>
        <Box>
          {isLoading && <Loader type="inline" position="centered" />}
          {data?.map((role, index) => {
            return (
              <Fragment key={role.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={role.description}
                      checked={(values.roles as ITechnicianRole[]).includes(
                        role.value as ITechnicianRole
                      )}
                    />
                  }
                  label={role.description}
                  onChange={(_, checked) => {
                    if (checked) {
                      setFieldValue('roles', [...values.roles, role.value]);
                    } else {
                      setFieldValue(
                        'roles',
                        values.roles.filter(value => value !== role.value)
                      );
                    }
                  }}
                />
              </Fragment>
            );
          })}
        </Box>
        <DatePickerPopover
          onChange={date => handleAddDaysOff(date!)}
          handleClose={handleDatePickerClose}
          anchorEl={calPopOverAnchorEl}
          disabledDates={disabledDates}
        />
      </Stack>
    </>
  );
};
