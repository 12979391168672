import { Alert, Link } from '@mui/material';
import { FC } from 'react';

interface IStyleGuideMultiselectAlertProps {
  showMore?: boolean;
}

export const StyleGuideMultiselectAlert: FC<IStyleGuideMultiselectAlertProps> = ({
  showMore = true,
}) => {
  return (
    <Alert severity="warning" sx={{ marginBottom: 1 }}>
      NOTE: Though there are multiple ways to implement a multi-select field, the prominant
      implementation for the application is by using a{' '}
      <Link href="https://mui.com/material-ui/react-autocomplete/" target="_blank">
        Mui Autocomplete
      </Link>{' '}
      selector with Chips.{' '}
      {showMore && (
        <>
          See the Components documentation for more information on available Autocomplete
          components.
        </>
      )}
    </Alert>
  );
};
