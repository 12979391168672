import { styled } from '@mui/material';
import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import { FC, useContext } from 'react';
import { CardTitle, Card } from '../../components';
import { IListUser } from '../../models';
import { BrandingContext } from '../../context/branding-context';
import { BillingCommissionInfoDetails } from './billing-commision-info-details';

interface IBillingCommissionInfo {
  handleBlur: any;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  values: any;
  isLoadingUsers: boolean;
  users: IListUser[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  dirty: boolean;
  isExpanded?: boolean;
  isRecurring: boolean;
}

export const BillingCommissionInfo: FC<IBillingCommissionInfo> = ({
  errors,
  touched,
  values,
  handleBlur,
  isLoadingUsers,
  users,
  setFieldValue,
  dirty,
  isExpanded,
  isRecurring,
}) => {
  const { isPoolService } = useContext(BrandingContext);

  return (
    <StyledCard className={clsx('print--avoid-break')}>
      <CardTitle
        title={isPoolService ? 'Per Visit Billing' : 'Billing/Commission Information'}
        withExpand
        initialExpand={false}
        overrideExpand={isExpanded}
        shouldNoWrap={false}
        unmountOnExit
      >
        <BillingCommissionInfoDetails
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          values={values}
          isLoadingUsers={isLoadingUsers}
          users={users}
          setFieldValue={setFieldValue}
          dirty={dirty}
          isRecurring={isRecurring}
        />
      </CardTitle>
    </StyledCard>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '@media print': { marginBottom: '3rem' },
}));
