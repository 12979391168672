import { FC, useMemo, useCallback, useContext, useState } from 'react';
import { Card, ServerSideDataGrid, GridDataFetcher, useDataGrid, TableActionsMenu, Modal, Loader } from '../../../components';
import { GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { downloadFile, formatDate, formatShortFriendlyDateWithTime, hyphenSeparateTwoInputs } from '../../../helpers';
import { IAccountExportPrior } from '../../../models';
import { downloadAccountingExportBatch, getAccountingExportBatch, getPriorExportBatches, voidPriorTransactionBatch } from '../../../fetch';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../context';
import { useConfirm } from '../../../hooks';
import { Button, ListItem, ListItemButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import { AccountingExportSummaryTable } from './accounting-export-summary-table';

interface IPriorExportBatchesTableProps {
  setRefetchExportBatches: (val: boolean) => void;
  refetchExportBatches: boolean;
}

export const PriorExportBatchesTable: FC<IPriorExportBatchesTableProps> = ({
  setRefetchExportBatches,
  refetchExportBatches,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [isVoidingBatch, setIsVoidingBatch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedExportedBatch, setSelectedExportedBatch] = useState<IAccountExportPrior | null>(null);
  const [isDownloading, setDownloading] = useState(false);
  const confirm = useConfirm();
  const dataFetcher: GridDataFetcher<IAccountExportPrior> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      if (user?.officeId) {
        try {
          const options: any = {
            sortBy: 'batchDate',
            sortDirection: sortDirection || 'asc',
            perPage,
            page: page + 1,
          };

          const res = await getPriorExportBatches(user?.officeId!, options);
          setRefetchExportBatches(false);
          return {
            rows: res.records,
            rowCount: res.totalRecordCount,
          };
        } catch (error: any) {
          enqueueSnackbar(
            error?.Detail ?? `Error loading prior export batches, please try again.`,
            {
              variant: 'error',
            }
          );
          throw error;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetchExportBatches]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<IAccountExportPrior>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'prior-export-batches',
      sortColumn: 'exportDate',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  const handleViewSummary = (batch: IAccountExportPrior) => {
    setSelectedExportedBatch(batch);
    setIsOpen(true);
  };

  const handleBatchDownload = async (batch: IAccountExportPrior) => {
    try {
      setDownloading(true);
      const res = await downloadAccountingExportBatch(batch.exportBatchId!);

      if (res.data && res.data.type.includes('text/csv')) {
        const fileName = `AccountingExport_${formatShortFriendlyDateWithTime(batch.exportDate)?.replace(/ /g, "_")}.csv`;
        const fileData = res.data;
        downloadFile(fileData, fileName);
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'An error occurred while downloading batch.', {
        variant: 'error',
      });
    } finally {
      setDownloading(false);
    }
  };

  const dataColumns = useMemo(
    () => [
      {
        field: 'exportDate',
        headerName: 'Batch Date/Time',
        flex: 1,
        sortable: false,
        valueGetter: (params: GridValueGetterParams<IAccountExportPrior>) => {
          return formatShortFriendlyDateWithTime(params.value);
        },
      },
      {
        field: 'transactionCount',
        headerName: 'Transaction Count',
        flex: 1,
        type: 'number',
        sortable: false,
      },
      {
        field: 'exportStatus',
        headerName: 'Status',
        flex: 1,
        sortable: false,
      },
      {
        field: 'actions',
        headerName: '',
        width: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IAccountExportPrior>) => {
          const { row: batch } = params;
          return batch.exportStatus !== 'Voided' ? (
            <TableActionsMenu
              labelContext="Prior Export Batch"
              id={`action-menu-${batch.exportBatchId}`}
            >
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ color: theme => theme.palette.primary.main }}
                  onClick={() => handleViewSummary(batch)}
                >
                  View Summary
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ color: theme => theme.palette.primary.main }}
                  onClick={() => handleBatchDownload(batch)}
                >
                  Download
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ color: theme => theme.palette.primary.main }}
                  onClick={async () => {
                    const confirmed = await confirm('Are you sure you want to void this batch?');
                    if (!confirmed) {
                      return;
                    }
                    try {
                      setIsVoidingBatch(true);
                      await voidPriorTransactionBatch(
                        batch.exportBatchId,
                        'Voided',
                        user?.officeId!
                      );
                      enqueueSnackbar('Batch voided!', {
                        variant: 'success',
                      });
                      refetch();
                    } catch (error: any) {
                      enqueueSnackbar(error?.Detail ?? `Error voiding batch, please try again.`, {
                        variant: 'error',
                      });
                    } finally {
                      setIsVoidingBatch(false);
                    }
                  }}
                >
                  Void Batch
                </ListItemButton>
              </ListItem>
            </TableActionsMenu>
          ) : (
            <></>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch]
  );

  const {
    isLoading: isLoadingExportBatch,
    data: accountingExportBatch,
  } = useQuery(
    ['getAccountingExportBatch', selectedExportedBatch],
    async () => {
      const accountRes = await getAccountingExportBatch(selectedExportedBatch?.exportBatchId!);
      return accountRes;
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: isOpen && !!selectedExportedBatch,
    }
  );

  return (
    <>
      <Card
        cardTitleProps={{
          title: 'Prior Export Batches',
          action: (
            <Button
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={refetch}
              disabled={isVoidingBatch || isLoading}
              size="small"
            >
              Refresh
            </Button>
          ),
        }}
      >
        {isDownloading && <Loader position="centered" type="overlay" />}
        <ServerSideDataGrid
          autoHeight
          getRowId={(row: IAccountExportPrior) => row.exportBatchId}
          rows={rows}
          columns={dataColumns}
          loading={isVoidingBatch || isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          disableColumnFilter
          disableColumnMenu
          hasMobileLayout
          mobileProps={{
            mobileCustomDefaultAccessor: (row: IAccountExportPrior) => {
              return hyphenSeparateTwoInputs(formatDate(row?.exportDate) ?? '', row.exportStatus);
            },
            showHandleActions: true
          }}
        />
      </Card>
      <Modal
        open={isOpen}
        onClose={() => {
          setSelectedExportedBatch(null);
          setIsOpen(false);
        }}
        title={'Exported Batch Summary'}
        maxWidth="md"
      >
        <AccountingExportSummaryTable
          rows={accountingExportBatch ?? []}
          isLoading={isLoadingExportBatch}
        />
      </Modal>
    </>
  );
};
