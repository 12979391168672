import { FC, useMemo, useContext } from 'react';
import { ServerSideDataGrid, ServerSideDataGridProps, Link, ExternalLink } from '../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import {
  formatMoney,
  formatDate,
  getLegacyUrl,
  hasCorrectUserPermissions,
  hyphenSeparateTwoInputs,
  getCustomerDetailRoute,
} from '../../helpers';
import { IInvoice } from '../../models';
import { Permissions } from '../../constants';
import { Tooltip } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserContext } from '../../context';
import { isAfter, isBefore, isSameDay } from 'date-fns';

interface PendingInvoicesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: IInvoice[];
}

export const PendingInvoicesDataGrid: FC<PendingInvoicesDataGridProps> = ({
  rows,
  ...gridProps
}) => {
  const { user } = useContext(UserContext);
  const { v2Customers } = useFlags();
  const hasTransactionSameDayPermission = hasCorrectUserPermissions(
    Permissions.TransactionEditSameDay,
    user!
  );
  const hasTransactionPriorDayPermission = hasCorrectUserPermissions(
    Permissions.TransactionEditPriorDay,
    user!
  );
  const columns = useMemo(() => {
    return [
      {
        field: 'invoiceDate',
        headerName: 'Date',
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IInvoice>) => {
          const { row: original } = params;
          // the date for the transaction is greater than or equal to today
          const dateIsSameOrAfter =
            isSameDay(new Date(original.invoiceDate), new Date()) ||
            isAfter(new Date(original.invoiceDate), new Date());
          // the date for the transaction is less than to today
          const dateIsBefore = isBefore(new Date(original.invoiceDate), new Date());

          const showLink: boolean =
            (hasTransactionSameDayPermission && dateIsSameOrAfter) ||
            (hasTransactionPriorDayPermission && dateIsBefore);
          if (showLink) {
            return (
              <Link to={`/billing/invoices/${original.transactionId}?redirect=/billing`}>
                <Tooltip title={`Edit invoice`} placement="bottom">
                  <span>{formatDate(original.invoiceDate)}</span>
                </Tooltip>
              </Link>
            );
          }
          return <>{original.invoiceDate ? formatDate(original.invoiceDate) : ''}</>;
        },
      },
      {
        field: 'accountName',
        headerName: 'Customer',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IInvoice>) => {
          const { row: original } = params;
          return (
            <>
              {v2Customers ? (
                <Link to={`${getCustomerDetailRoute(original?.accountId!)}`}>
                  {original.accountName}
                </Link>
              ) : (
                <ExternalLink to={`${getLegacyUrl()}/Office/Account/View/${original?.accountId}`}>
                  {original.accountName}
                </ExternalLink>
              )}
            </>
          );
        },
      },
      {
        field: 'reference',
        headerName: 'Description',
        flex: 1,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice',
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IInvoice>) => {
          const { row: original } = params;
          return (
            <Tooltip title={original.invoiceNumber} placement="bottom">
              <span style={{ maxWidth: 80, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {original.invoiceNumber}
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        type: 'number',
        renderCell: (params: GridRenderCellParams<IInvoice>) => {
          const { row: original } = params;
          return <>{formatMoney(original.amount)}</>;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: IInvoice) => row.transactionId!}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      columnHeaderHeight={36}
      hasMobileLayout
      mobileProps={{
        mobileCustomDefaultAccessor: (row: IInvoice) => {
          return hyphenSeparateTwoInputs(
            `#${row?.invoiceNumber}` ?? '',
            formatMoney(row?.amount) ?? ''
          );
        },
      }}
      {...gridProps}
    />
  );
};
