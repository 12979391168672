import { FC, useState } from 'react';
import {
  PaymentDayofMonth,
  AmountInput,
  SendEmailModal,
  RecurringServicesAutoComplete,
} from '../../components';
import { IAccountDetail } from '../../models';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Alert,
  Typography,
} from '@mui/material';
import { convertToNumber } from '../../helpers';
import { sendRecurringPaymentEmail } from '../../fetch';

interface ICollectCCModal {
  isOpen: boolean;
  onClose: (shouldReload?: boolean) => void;
  currentCustomer: IAccountDetail;
}

export const CollectCCModal: FC<ICollectCCModal> = ({ isOpen, onClose, currentCustomer }) => {
  const [dayOfMonth, setDayOfMonth] = useState<string | number>('1');
  const [amount, setAmount] = useState<string | number>('$0.00');
  const [hasPayBalence, setHasPayBalence] = useState<boolean>(true);
  const [recurringServiceIds, setRecurringServiceIds] = useState<string[]>([]);
  const [selectedPayOption, setSelectedPayOption] = useState<string>('');

  const handleClose = () => {
    setAmount('');
    setDayOfMonth('1');
    setSelectedPayOption('');
    setRecurringServiceIds([]);
    onClose();
  };
  return (
    <SendEmailModal
      modalTitle="Send Email to Collect Credit Card Info"
      onClose={handleClose}
      onSubmit={async formValues => {
        await sendRecurringPaymentEmail(currentCustomer?.accountId, {
          ...formValues,
          payOutstandingBalance: hasPayBalence,
          paymentAmount: convertToNumber(amount),
          dayOfMonth: convertToNumber(dayOfMonth),
          recurringServiceIds,
          payOption: selectedPayOption,
        });
        handleClose();
      }}
      open={isOpen}
      defaultToAddress={currentCustomer?.emails ?? []}
      showSendAsAgreementToggle={false}
      alertText={`An email will be sent to ${currentCustomer?.firstName} ${currentCustomer?.lastName} requesting credit card information for a recurring payment.`}
      showCCAddress={false}
    >
      <>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="pay-option" required>
              Pay Option
            </FormLabel>
            <RadioGroup
              aria-labelledby="pay-option"
              name="pay-option-group"
              sx={{
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
              value={selectedPayOption}
              onChange={e => {
                if (e.target.value === 'perVisit') {
                  setDayOfMonth('-1');
                } else {
                  setDayOfMonth('1');
                }
                setSelectedPayOption(e.target.value);
              }}
            >
              <FormControlLabel value="perVisit" control={<Radio />} label="Per Visit" />
              <FormControlLabel
                value="monthlyBilling"
                control={<Radio />}
                label="Monthly Billing"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            <Typography variant="body2">
              <Typography fontWeight="bold" fontSize="0.85rem" component="span">
                Per Visit:
              </Typography>{' '}
              Card will be charged every time a service is completed
            </Typography>
            <Typography variant="body2">
              <Typography fontWeight="bold" fontSize="0.85rem" component="span">
                Monthly:
              </Typography>{' '}
              Card will be charged once per month for the account in general
            </Typography>
          </Alert>
        </Grid>
        {selectedPayOption === 'perVisit' && (
          <Grid item xs={12}>
            <RecurringServicesAutoComplete
              handleChange={val => setRecurringServiceIds(val)}
              apiFilters={{
                perPage: -1,
                accountId: currentCustomer.accountId,
                onlyIncludeActiveServices: true,
                onlyIncludeServicesWithNoRecurringPayment: true,
              }}
            />
          </Grid>
        )}

        {selectedPayOption === 'monthlyBilling' && (
          <>
            <Grid item xs={12} sm={6}>
              <PaymentDayofMonth
                onChange={(value: string | number) => {
                  setDayOfMonth(value);
                }}
                value={dayOfMonth}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AmountInput
                value={amount}
                onChange={(val: string) => setAmount(val)}
                disabled={hasPayBalence}
                label="Pay Amount"
                name="pay-amount"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                color="primary"
                control={
                  <Checkbox
                    checked={hasPayBalence}
                    id="payOutstandingBalance"
                    color="primary"
                    onChange={(_, checked) => {
                      setHasPayBalence(checked);
                      if (checked) {
                        setAmount('$0.00');
                      }
                    }}
                  />
                }
                label="Pay Balance"
              />
            </Grid>
          </>
        )}
      </>
    </SendEmailModal>
  );
};
