import { Page } from '../../../components';
import { FC } from 'react';
import { AccountingExportDetails } from './account-export-page-details';

export const AccountingExportPage: FC = () => {

  return (
    <Page
      title="Accounting Export"
      breadcrumb={{
        text: 'Billing',
        link: '/billing',
      }}
    >
      <AccountingExportDetails />
    </Page>
  );
};
