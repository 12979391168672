import { Typography, Card, Box, Button, darken, styled } from '@mui/material';
import { FC } from 'react';
import { UserAvatar } from '../../../user-avatar';
import { ICalendarItemUser, ICalendarView } from '../../../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck, faRepeat, faWrench } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { IColorSet } from '../../../../models/colors';

interface EmployeeStatusCardProps {
  calendarItemUser: ICalendarItemUser;
  calendarDate: string;
  colorSet?: IColorSet;
  onNameClick?: () => unknown;
  onViewClick?: () => unknown;
  view: ICalendarView;
}

interface StyledCardProps {
  backgroundColor: string;
  foregroundColor: string;
  view: ICalendarView;
};

export const EmployeeStatusCard: FC<EmployeeStatusCardProps> = ({
  calendarItemUser,
  colorSet,
  onNameClick,
  onViewClick,
  view,
}) => {
  const { userInformation, scheduledServiceCount, repairCount, scheduledTaskCount } =
    calendarItemUser;

  const { username, avatarUrl, backgroundColor, foregroundColor } = userInformation;

  return (
    <StyledCard
      className={clsx(classes.employeeStatusCard, 'print--avoid-break')}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      view={view}
    >
      <Box className={classes.employeeStatusCardInfo}>
        <StyledUserAvatar src={avatarUrl || undefined} userName={username} view={view} />
        <Button className={classes.employeeName} onClick={onNameClick} variant="text">
          {username}
        </Button>
      </Box>
      <Box className={classes.employeeStatusCardContent}>
        <Box>
          {!!scheduledServiceCount && (
            <Typography className={classes.serviceStyles}>
              <FontAwesomeIcon icon={faRepeat} size="sm" />
              {`${scheduledServiceCount} Service${scheduledServiceCount > 1 ? 's' : ''}`}
            </Typography>
          )}
          {!!repairCount && (
            <Typography className={classes.serviceStyles}>
              <FontAwesomeIcon icon={faWrench} size="sm" />
              {`${repairCount} Visit${repairCount > 1 ? 's' : ''}`}
            </Typography>
          )}
          {!!scheduledTaskCount && (
            <Typography className={classes.serviceStyles}>
              <FontAwesomeIcon icon={faListCheck} size="sm" />
              {`${scheduledTaskCount} Task${scheduledTaskCount > 1 ? 's' : ''}`}
            </Typography>
          )}
        </Box>
        <Button className={clsx(classes.viewButton, 'print--none')} onClick={onViewClick}>
          VIEW
        </Button>
      </Box>
    </StyledCard>
  );
};

const LAPTOP_L_MEDIA_QUERY = '@media (max-width: 1440px)';
const LAPTOP_MEDIA_QUERY = '@media (max-width: 1250px)';
const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';

const StyledUserAvatar = styled(UserAvatar, {
  shouldForwardProp: prop => prop !== 'view',
})<{ view: ICalendarView }>(({ theme, view }) => ({
  height: '45px',
  width: '45px',
  fontSize: '14px',
  margin: 0,
  '@media print': {
    display: 'none',
  },
  [LAPTOP_L_MEDIA_QUERY]: {
    height:
      view === ICalendarView.Week 
      || view === ICalendarView.Month 
      || view === ICalendarView.WorkWeek
        ? '35px'
        : 'inherit',
    width:
      view === ICalendarView.Week 
      || view === ICalendarView.Month 
      || view === ICalendarView.WorkWeek
        ? '35px'
        : 'inherit',
  },
  [LAPTOP_MEDIA_QUERY]: {
    height:
      view === ICalendarView.Week 
      || view === ICalendarView.Month 
      || view === ICalendarView.WorkWeek
      || view === ICalendarView.Day
        ? '25px'
        : 'inherit',
    width:
      view === ICalendarView.Week 
      || view === ICalendarView.Month 
      || view === ICalendarView.WorkWeek
      || view === ICalendarView.Day
        ? '25px'
        : 'inherit',
  },
}));

const PREFIX = 'EmployeeStatusCard';

const classes = {
  employeeStatusCard: `${PREFIX}-employeeStatusCard`,
  employeeStatusCardInfo: `${PREFIX}-employeeStatusCardInfo`,
  employeeName: `${PREFIX}-employeeName`,
  userAvatar: `${PREFIX}-userAvatar`,
  employeeStatusCardContent: `${PREFIX}-employeeStatusCardContent`,
  serviceStyles: `${PREFIX}-serviceStyles`,
  viewButton: `${PREFIX}-viewButton`
};

const StyledCard = styled(Card, {
  shouldForwardProp: prop =>
    prop !== 'backgroundColor'
    && prop !== 'foregroundColor'
    && prop !== 'view',
})<StyledCardProps>((
  {
    theme, backgroundColor, foregroundColor, view
  }) => ({
    [`&.${classes.employeeStatusCard}`]: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      height: 'fit-content',
      gap: 5,
      borderStyle: 'solid',
      borderWidth: '0px 0px 0px 8px',
      borderRadius: 6,
      borderColor: backgroundColor,
      [LAPTOP_MEDIA_QUERY]: {
        borderWidth: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
              return '1px 1px 1px 1px';
            default:
              return;
          }
        },
      },
      [MOBILE_MEDIA_QUERY]: {
        borderWidth: () => {
          switch (view) {
            case ICalendarView.WorkWeek:
              return '1px 1px 1px 1px';
            default:
              return;
          }
        },
      },
      '@media print': {
        boxShadow: 'none !important',
        marginBottom: theme.spacing(1),
        borderWidth: '1px 1px 1px 8px',
      },
    },

    [`& .${classes.employeeStatusCardInfo}`]: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      gap: 5,
      padding: 10,
      [LAPTOP_MEDIA_QUERY]: {
        flexDirection: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 'column';
            default:
              return;
          }
        },
        padding: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 5;
            default:
              return;
          }
        },
      },
      [MOBILE_MEDIA_QUERY]: {
        textAlign: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 'center';
            default:
              return;
          }
        },
      },
      '@media print': {
        boxShadow: 'none !important',
      },
    },

    [`& .${classes.employeeName}`]: {
      fontSize: 14,
      fontWeight: 600,
      textDecoration: 'underline',
      color: backgroundColor,
      lineHeight: 1.2,
      textAlign: 'left',
      [LAPTOP_MEDIA_QUERY]: {
        textAlign: 'center',
      },
      [MOBILE_MEDIA_QUERY]: {
        fontSize: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 12;
            default:
              return;
          }
        },
      },

      '@media print': {
        position: 'static',
        color: `${theme.palette.common.black} !important`,
      },
    },

    [`& .${classes.userAvatar}`]: {},

    [`& .${classes.employeeStatusCardContent}`]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: 5,
      gap: 5,
      backgroundColor: '#F2F2F2',
      [LAPTOP_MEDIA_QUERY]: {
        flexDirection: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 'column';
            default:
              return;
          }
        },
      },
      [MOBILE_MEDIA_QUERY]: {
        textAlign: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 'center';
            default:
              return;
          }
        },
      },
    },

    [`& .${classes.serviceStyles}`]: {
      fontSize: 13,
      paddingLeft: 3,
      color: theme.palette.primary.main,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      gap: 5,

      [LAPTOP_MEDIA_QUERY]: {
        fontSize: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 12;
            default:
              return;
          }
        },
      },
      [MOBILE_MEDIA_QUERY]: {
        textAlign: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 'center';
            default:
              return;
          }
        },
      },
    },

    [`& .${classes.viewButton}`]: {
      fontSize: 12,
      backgroundColor: backgroundColor,
      borderRadius: theme.shape.borderRadius,
      fontWeight: 600,
      color: foregroundColor,
      width: '70px',
      height: '25px',
      '&:hover': {
        backgroundColor: darken(backgroundColor, 0.2),
        color: foregroundColor,
      },
      [LAPTOP_L_MEDIA_QUERY]: {
        width: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 'auto';
            default:
              return;
          }
        },
      },
      [MOBILE_MEDIA_QUERY]: {
        fontSize: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 10;
            default:
              return;
          }
        },
        height: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 20;
            default:
              return;
          }
        },
        minWidth: () => {
          switch (view) {
            case ICalendarView.Week:
            case ICalendarView.Month:
            case ICalendarView.WorkWeek:
              return 45;
            default:
              return;
          }
        },
      },
    }
  }));