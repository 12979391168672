import { Box, Button, Typography, Divider, Grid, useMediaQuery, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, Fragment, useState, useContext, useEffect } from 'react';
import { AmountInput, SearchField } from '../inputs';
import { Tabs } from '../tabs';
import * as Yup from 'yup';
import { IInventory, IPoolCommerceInventory, IInventoryItemForm } from '../../models';
import { getPoolCommerceInventory, getInventory, postInventoryItem } from '../../fetch';
import { InventoryItem } from './inventory-item';
import { Loader } from '../loader';
import { Pagination } from '../table';
import { Formik, Form } from 'formik';
import { InventoryCoreFields } from '../../pages/inventory/360/inventory-core-fields';
import { UserContext } from '../../context';
import { ModalSaveSection } from '../modal';
import { useConfirm } from '../../hooks';
import { defaultUnsavedChangesMessage } from '../../constants';
import { CardTitle } from '../card';
import { convertToNumber } from '../../helpers';
import { Select } from '../../components';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const Schema = Yup.object().shape({
  itemId: Yup.number()
    .required('Required')
    .test('Is positive?', "Must be greater than '0'", (value: any) => value > 0)
    .test('len', 'Number is too large', (val: any) => val < 2147483647),
  itemLookupCode: Yup.string().required('Required').nullable(),
  manufacturerNumber: Yup.string().max(255, 'Max 255 characters').required('Required').nullable(),
  description: Yup.string().max(255, 'Max 255 characters').required('Required'),
  cost: Yup.string().required('Required'),
  price: Yup.string().required('Required'),
});

interface IPool360Search {
  onSelectInventoryItem: (item: any) => void;
}

interface IPerPageValue {
  [key: number]: string;
}

export const Pool360Search: FC<IPool360Search> = ({ onSelectInventoryItem }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedPool360Value, setSearchedPool360Value] = useState<string>('');
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('local');
  const [isItemsSearching, setIsItemsSearching] = useState<boolean>(false);
  const [isPoolSearching, setIsPoolSearching] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [inventory, setInventory] = useState<IInventory[]>([]);
  const [pool360Inventory, setPool360Inventory] = useState<IPoolCommerceInventory[]>([]);
  const [page, setPage] = useState<number>(0);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [pool360Page, setPool360Page] = useState<number>(0);
  const [recordPool360Count, setPool360RecordCount] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [pool360PerPage, setPool360RowsPerPage] = useState<number>(8);
  const [isShowingInventoryForm, setIsShowingInventoryForm] = useState<boolean>(false);

  const isMobile = useMediaQuery(`(max-width: 567px)`);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [sortMethod, setSortMethod] = useState<string>('');
  const sortMethods = {
    description_desc: 'Description (Desc)',
    description_asc: 'Description (Asc)',
    price_desc: 'Price (Desc)',
    price_asc: 'Price (Asc)',
  };

  const handleInventorySearch = async (customPage: number, customPerPage: number) => {
    setHasSearched(false);
    try {
      setIsItemsSearching(true);
      const response = await getInventory({
        search: searchValue,
        perPage: customPerPage,
        page: customPage + 1,
        isForLabor: false,
        sortBy: (sortMethod !== 'none' && sortMethod.split('_')[0]) || '',
        sortDirection: (sortMethod !== 'none' && sortMethod.split('_')[1]) || '',
      });
      // if there are no results on the previous items tab, auto switch and search the pool 360 tab
      if (response.records.length === 0) {
        // set a slight delay to allow the user to see the no results message
        setTimeout(() => {
          setPool360Inventory([]);
          setSelectedTab('360');
          initialPool360Search(0, 8);
        }, 750);
      }
      setInventory(response.records);
      setRecordCount(response.totalRecordCount);
      setHasSearched(true);
      setSearchedValue(searchValue);
    } catch (err: any) {
      enqueueSnackbar(
        err?.response?.data?.Detail || `Error searching inventory. Please try again.`,
        { variant: 'error' }
      );
    } finally {
      setIsItemsSearching(false);
    }
  };

  const initialSearch = () => {
    setPage(0);
    setRowsPerPage(10);
    handleInventorySearch(0, 10);
  };

  const handlePool360Search = async (customPage: number, customPerPage: number) => {
    setHasSearched(false);

    const getPerPageValue: IPerPageValue = {
      8: 'Eight',
      16: 'Sixteen',
      24: 'TwentyFour',
      32: 'ThirtyTwo',
    };
    try {
      setIsPoolSearching(true);
      const response = await getPoolCommerceInventory({
        search: searchValue,
        pageSize: getPerPageValue[customPerPage],
        page: customPage + 1,
      });
      setPool360Inventory(response.records);
      setPool360RecordCount(response.totalRecordCount);
      setHasSearched(true);
      setSearchedPool360Value(searchValue);
    } catch (err: any) {
      enqueueSnackbar(err?.Detail || `Error searching inventory. Please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsPoolSearching(false);
    }
  };

  const initialPool360Search = (customPage: number, customPerPage: number) => {
    setPool360Page(customPage);
    setPool360RowsPerPage(customPerPage);
    handlePool360Search(customPage, customPerPage);
  };

  const handleFilterChange = () => {
    handleInventorySearch(0, perPage);
    setPage(0);
  };

  useEffect(() => {
    if (sortMethod && sortMethod !== 'none') {
      handleFilterChange();
    }
    if (sortMethod === 'none') {
      setSortMethod('');
      handleFilterChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortMethod]);

  return (
    <Box mt={1.5}>
      {!isShowingInventoryForm && (
        <form
          onSubmit={e => {
            e.preventDefault();
            selectedTab === 'local' ? initialSearch() : initialPool360Search(0, 8);
          }}
        >
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={1}>
            <Box flex={1}>
              <SearchField
                label="Find Inventory Item"
                handleSearch={val => {}}
                setSearchValue={val => {
                  setSearchValue(val);
                }}
                placeholder=""
                searchValue={searchValue}
                shouldAutoFocus
              />
            </Box>
            <Button
              type="submit"
              color="secondary"
              onClick={() =>
                selectedTab === 'local' ? initialSearch() : initialPool360Search(0, 8)
              }
              disabled={isItemsSearching || isPoolSearching || !Boolean(searchValue)}
            >
              Search
            </Button>
            {!isMobile && <Divider orientation="vertical" flexItem />}
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setIsShowingInventoryForm(true);
              }}
              disabled={isItemsSearching || isPoolSearching}
            >
              Add Inventory Item
            </Button>
          </Box>
        </form>
      )}
      {isShowingInventoryForm && (
        <Box mt={2}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              itemId: '',
              itemLookupCode: '',
              manufacturerNumber: '',
              description: '',
              price: '',
              cost: '',
            }}
            validationSchema={Schema}
            onSubmit={async (values, actions) => {
              const payload: IInventoryItemForm = {
                itemId: Number(values.itemId),
                itemLookupCode: values.itemLookupCode,
                manufacturerNumber: values.manufacturerNumber ?? '',
                description: values.description ?? null,
                officeId: user?.officeId ?? '',
                price: values?.price ? convertToNumber(values?.price) : 0,
                cost: values?.cost ? convertToNumber(values?.cost) : 0,
              };
              try {
                const inventoryId = await postInventoryItem(payload);

                onSelectInventoryItem({
                  ...values,
                  inventoryId,
                  description: values.description,
                  lookupCode: values.itemLookupCode,
                });
                setIsShowingInventoryForm(false);
                actions.resetForm();
                enqueueSnackbar('Inventory item saved!', { variant: 'success' });
              } catch (err: any) {
                enqueueSnackbar(err?.Detail || `Error saving inventory item. Please try again.`, {
                  variant: 'error',
                });
              }
            }}
          >
            {({
              handleSubmit,
              touched,
              dirty,
              isSubmitting,
              isValid,
              setFieldValue,
              handleBlur,
              values,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <CardTitle title="Add Inventory Item" />
                  {isSubmitting && <Loader position="centered" type="overlay" />}
                  <Grid container spacing={2}>
                    <InventoryCoreFields isDisabled={false} />
                    <Grid item xs={12} md={6}>
                      <AmountInput
                        name="cost"
                        label="Cost"
                        value={values.cost}
                        onBlur={handleBlur}
                        onChange={val => setFieldValue('cost', val)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AmountInput
                        name="price"
                        label="Price"
                        value={values.price}
                        onBlur={handleBlur}
                        onChange={val => setFieldValue('price', val)}
                      />
                    </Grid>
                  </Grid>
                  <ModalSaveSection
                    cancelLabel="Cancel & Reset"
                    submitLabel="Save Inventory Item"
                    handleCancel={async () => {
                      if (dirty && Object.keys(touched).length > 0) {
                        const result = await confirm(defaultUnsavedChangesMessage);
                        if (result) {
                          return setIsShowingInventoryForm(false);
                        }
                        return;
                      }
                      return setIsShowingInventoryForm(false);
                    }}
                    isSaveDisabled={!isValid || !dirty || isSubmitting}
                  />
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}
      {!isShowingInventoryForm && (
        <Tabs
          id="pool-360-search"
          color="secondary"
          backgroundColor="white"
          selectedTab={selectedTab}
          setSelectedTab={val => {
            if (searchValue && searchedValue !== searchValue && val === 'local') {
              initialSearch();
            } else if (searchValue && searchedPool360Value !== searchValue && val === '360') {
              initialPool360Search(0, 8);
            }
            setSelectedTab(val);
          }}
          allowScrollButtonsMobile={false}
          tabs={[
            {
              key: 'local',
              title: 'Previous Items',
              mobileTitle: 'Prev. Items',
              disabled: isItemsSearching,
              children: (
                <Box position="relative" mt={2}>
                  {isItemsSearching && (
                    <Box minHeight={inventory.length === 0 ? '3rem' : 'auto'}>
                      <Loader position="centered" type="overlay" />
                    </Box>
                  )}
                  {inventory.length === 0 && hasSearched && (
                    <Typography align="center">No Results</Typography>
                  )}
                  {inventory.length > 0 && (
                    <>
                      <Box mb={2} pt={1.5}>
                        <Select
                          fullWidth
                          size="small"
                          autoComplete="nope"
                          label="Sort By"
                          name="sortby"
                          value={sortMethod}
                          onChange={(e: any) => {
                            setSortMethod(e.target.value);
                          }}
                          hasClear={!!sortMethod && sortMethod !== 'none'}
                          onClear={() => {
                            setSortMethod('none');
                          }}
                        >
                          {Object.entries(sortMethods).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box
                        sx={{
                          maxHeight: 'calc(60vh - 52px)',
                          overflowY: 'auto',
                        }}
                      >
                        <Box pl={2} pr={2}>
                          {inventory.map((item, index) => {
                            return (
                              <Fragment key={`${index}`}>
                                <InventoryItem
                                  isSearching={isItemsSearching}
                                  item={{
                                    productId: item.productId,
                                    description: item.description,
                                    manufacturerNumber: item.manufacturerNumber,
                                    lookupCode: item.lookupCode,
                                    price: item.price,
                                  }}
                                  onSelectInventoryItem={() => onSelectInventoryItem(item)}
                                />
                              </Fragment>
                            );
                          })}
                        </Box>
                        <Pagination
                          component="div"
                          page={page}
                          count={recordCount}
                          rowsPerPage={perPage}
                          setPage={newPage => {
                            setPage(newPage);
                            // check here so it doesn't fire again when you change the rows per page
                            if (newPage !== page) {
                              handleInventorySearch(newPage, perPage);
                            }
                          }}
                          setRowsPerPage={rows => {
                            setRowsPerPage(rows);
                            handleInventorySearch(page, rows);
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              ),
            },
            {
              key: '360',
              title: 'Search Pool 360',
              mobileTitle: 'Pool 360',
              disabled: isPoolSearching,
              icon: isMobile ? faMagnifyingGlass : undefined,
              children: (
                <Box position="relative" mt={2}>
                  {isPoolSearching && (
                    <Box minHeight={pool360Inventory.length === 0 ? '3rem' : 'auto'}>
                      <Loader position="centered" type="overlay" />
                    </Box>
                  )}
                  {pool360Inventory.length === 0 && hasSearched && (
                    <Typography align="center">No Results</Typography>
                  )}
                  {pool360Inventory.length > 0 && (
                    <Box
                      sx={{
                        maxHeight: '60vh',
                        overflowY: 'auto',
                      }}
                    >
                      <Box pl={2} pr={2}>
                        {pool360Inventory.map((item, index) => {
                          return (
                            <Fragment key={`${index}`}>
                              <InventoryItem
                                isSearching={isPoolSearching}
                                item={{
                                  description: item.productTitle,
                                  manufacturerNumber: item.manufacturerItem,
                                  price: item.price,
                                  lookupCode: item.productNumber,
                                }}
                                onSelectInventoryItem={() =>
                                  onSelectInventoryItem({
                                    ...item,
                                    description: item.productTitle,
                                    lookupCode: item.productNumber,
                                  })
                                }
                              />
                            </Fragment>
                          );
                        })}
                      </Box>
                      <Box>
                        <Pagination
                          component="div"
                          page={pool360Page}
                          count={recordPool360Count}
                          rowsPerPage={pool360PerPage}
                          rowsPerPageOptions={[8, 16, 24, 32]}
                          setPage={newPage => {
                            // make sure this only gets called when the page changes
                            if (newPage !== pool360Page) {
                              initialPool360Search(newPage, pool360PerPage);
                            }
                          }}
                          setRowsPerPage={rows => {
                            initialPool360Search(0, rows);
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};
