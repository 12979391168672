import { Box, Divider, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { DocumentUpload, ImageUpload } from '../../../components';
import { CodeBlock } from '../code-block';
import { handleCopyClick } from '../constants';
import { StyleGuidePage } from '../style-guide-page';

export const StyleGuideUploads = () => {
  const [isCopied, setIsCopied] = useState<{
    [val: string]: boolean;
  }>({});

  return (
    <StyleGuidePage title="File Uploads">
      <Grid container mb={3}>
        <Grid item xs={12} lg={8}>
          <Typography mb={1}>
            In various portions of the app, there are places where you can upload different types of
            content. Some of these types can include files and images.
          </Typography>
          <Typography mb={1}>
            For the image upload component, one feature is the ability to preview the image before
            uploading it. Once you have uploaded the image, if the "useImagePreview" prop is passed,
            you can see an image preview and click on it to open in a modal which utilizes the
            ImageModal component. This is helpful as the modal offers zoom control of of the image
            for a closer look.
          </Typography>
          <Typography mb={1}>
            When implementing these upload components, be sure to provide meaningful help text,
            specifically where requirements are concerned. For instance, we should include what file
            types can be uploaded, file size limitations, and file dimension limitations and/or
            requirements.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography mb={0.5} variant="h5" component="h2" color="primary">
            Document Upload
          </Typography>
          <Divider sx={{ marginBottom: theme => theme.spacing(2) }} />
          <Typography mb={2}>
            Current supported document file types to upload for this component are: pdf, tiff, xlsx,
            xls, doc and docx
          </Typography>
          <Grid item mb={2}>
            <DocumentUpload
              id={'document-upload'}
              label="Document File"
              handleFileChange={() => {}}
              isRequired
            />
          </Grid>
          <Grid item mb={2}>
            <code>
              Props:
              <br />- handleFileChange: (val: any, file?: File) ={'>'} void
              <br />- disabled?: boolean
              <br />- isRequired?: boolean
              <br />- showSource?: boolean
              <br />- handleUploadClick?: () ={'>'} void
              <br />- label?: string
              <br />- isEditable?: boolean
              <br />- id: string
              <br />- buttonLabel?: string
            </code>
          </Grid>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;DocumentUpload
            <br />
            &nbsp;&nbsp;&nbsp;id=&#123;'document-upload'&#125;
            <br />
            &nbsp;&nbsp;&nbsp;label=&#123;'Document File'&#125;
            <br />
            &nbsp;&nbsp;&nbsp;handleFileChange=&#123;() =&gt; &#123;&#125;&#125; <br />
            &nbsp;&nbsp;&nbsp;isRequired
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Typography mb={0.5} variant="h5" component="h2" color="primary">
              Image Upload
            </Typography>
            <Divider sx={{ marginBottom: theme => theme.spacing(2) }} />
            <Typography mb={2}>
              This component accepts all image file formats utilizing the html input accept
              parameter of "image/*".
            </Typography>
          </Box>
          <Grid item mb={2}>
            <ImageUpload
              imageKey={`office-logo`}
              showLabel={false}
              handleFileChange={() => {}}
              useImagePreview
              image={''}
              canOverride
              helpText="Upload a new logo for the office. The logo should be a PNG file with a transparent background."
            />
          </Grid>
          <Grid item mb={2}>
            <code>
              Props:
              <br />- handleFileChange: (val: string, file?: File) ={'>'} void
              <br />- image?: string
              <br />- disabled?: boolean;
              <br />- isRequired?: boolean;
              <br />- showSource?: boolean;
              <br />- labelClassName?: string;
              <br />- imageKey?: string;
              <br />- showLabel?: boolean;
              <br />- canRemoveImage?: boolean;
              <br />- canOverride: boolean;
              <br />- helpText: string;
              <br />- useImagePreview?: boolean;
            </code>
          </Grid>
          <CodeBlock handleCopyClick={e => handleCopyClick(setIsCopied, e)} isCopied={isCopied}>
            &lt;ImageUpload
            <br />
            &nbsp;&nbsp;&nbsp;imageKey="office-logo"
            <br />
            &nbsp;&nbsp;&nbsp;showLabel=&#123;false&#125;
            <br />
            &nbsp;&nbsp;&nbsp;handleFileChange=&#123;() =&gt; &#123;&#125;&#125; <br />
            &nbsp;&nbsp;&nbsp;image=&#123;""&#125; <br />
            &nbsp;&nbsp;&nbsp;canOverride
            <br />
            &nbsp;&nbsp;&nbsp;helpText="Upload a new logo for the office. The logo should be a PNG
            file with a transparent background."
            <br />
            /&gt;
          </CodeBlock>
        </Grid>
      </Grid>
    </StyleGuidePage>
  );
};
